import CloseIcon from '@mui/icons-material/Close';
import { createTheme, FormControl, FormHelperText, InputAdornment, ThemeProvider } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomIconButton from 'components/Button/CustomIconButton';

/**
 * The Custom Select Field, that display customized select tag.
 * 
 * @property {string} id the text field id
 * @property {string} labelID the text field label id
 * @property {string} name the input field name
 * @property {string} label Applies the theme typography styles.
 * @property {array} defaultValue the text field default value
 * @property {boolean} required if the value is required at this text field 
 * @property {boolean} isMultiple if multiple selection is enabled
 * @property {function} onChange the function that handles the select input change
 * @property {array} options the different option list
 * @property {string} helperText a helper text
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomSelect({ id, labelID, variant, name = "", label, defaultValue, value, required = true, isMultiple=undefined, onChange, options, helperText = "", sx, onClearSelect, width = "100%", error }) {
   const { t } = useTranslation();

   function getMuiTheme(sx) {
      return createTheme({
         components: {
            MuiInputBase: {
               styleOverrides: {
                  root: {
                     borderRadius: "30px",
                     width: width,
                     ...sx
                  }
               }
            }
         }
      })
   }

   function body() {
      return <>
         {label !== undefined && <InputLabel id={labelID}>{label}</InputLabel>}
         <ThemeProvider theme={getMuiTheme(sx)}>
            <Select
               variant={variant}
               labelId={labelID}
               multiple={isMultiple}
               onChange={onChange}
               input={<OutlinedInput id={id} name={name} />}
               endAdornment={(
                  onClearSelect !== undefined ?
                     <InputAdornment position="end" >
                        <CustomIconButton onClick={() => onClearSelect()} icon={<CloseIcon style={{ marginRight: "0px" }} />} className="icon-wrapper tr link" iconSx={{ marginRight: "0px!important" }} sx={{ backgroundColor: "white", borderRadius: "50px", padding: "8px 0px", minWidth: "40px", marginRight: "10px", marginTop: "-5px" }}></CustomIconButton>
                     </InputAdornment>
                     : <></>
               )}
               required={required}
               defaultValue={defaultValue}
               value={value}
               label={label}
               error={error}
            >
               {options.map((name) => (
                  <MenuItem
                     key={name}
                     value={name === "all" ? "" : name}
                  >
                     {name === "all" ? t("search.all") : name}
                  </MenuItem>
               ))}
            </Select>
         </ThemeProvider>
         {helperText !== "" && <FormHelperText>{helperText}</FormHelperText>}
      </>
   }

   return (
      <>
         {label !== undefined &&
            <FormControl sx={{ m: 1, minWidth: 120, margin: "0 8px" }}>
               {body()}
            </FormControl>
         }{label === undefined &&
            body()
         }
      </>
   );
}

export default CustomSelect;