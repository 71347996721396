import { Add as AddIcon, Save as SaveIcon } from '@mui/icons-material';
import { Divider, Fab, FormHelperText, Grid, InputLabel } from '@mui/material';
import { default as subscriptionApi } from "api/subscription";
import CustomAddEditBreadcrumb from 'components/Breadcrumb/CustomAddEditBreadcrumb';
import CustomDatePicker from "components/DatePicker/CustomDatePicker";
import CustomGrid from 'components/Grid/CustomGrid';
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomObjectSelect from 'components/Select/CustomObjectSelect';
import CustomSelect from 'components/Select/CustomSelect';
import CustomSnackbar from "components/Snackbar/CustomSnackbar";
import CustomSubscriptionTimeline from 'components/Timeline/CustomSubscriptionTimeline';
import CustomTypography from 'components/Typography/CustomTypography';
import { getDefaultSubscription } from 'constants/defaultObjects';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { hasRoleAdmin } from "utils/auth";
import { formIsValid, formValChangeWithParentElementWithNameAndValue, onSelectChange } from "utils/form-validation";
import { equalsIgnoreCase, findCurrency, isValidEmail } from 'utils/functions';
import { getLanguageFromURL } from "utils/language";
import useDocumentTitle from "utils/useDocumentTitle";
import { fabStyle, formSaveButton, mainArea, mainTag, mainTagBreadcrumb, selectError, subscriptionDetailsHeaderStyle, subscriptionDetailsLabelStyle, subscriptionHistoryCardStyle, subscriptionHistoryDetailsLabelStyle, subscriptionHistoryDetailsTextPriceStyle, subscriptionHistoryDetailsTextStyle } from 'themes/defaultThemes';
import SubscriptionPendingChargesInstanceOutletCharge from 'components/Grid/subscription/SubscriptionPendingChargesInstanceOutletCharge';
import CustomChipEmailInput from 'components/Chip/CustomChipEmailInput';


/**
 * The SubscriptionAddEditComponent that triggers the creation of a 
 * new subscription or the modification of an existing one.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function SubscriptionAddEditComponent() {
   const { t } = useTranslation();
   const { subscriptionID } = useParams();
   const navigate = useNavigate();

   /**
    * @type {object}
    * @property {boolean} isAdd to define if requested an edit or an add of a subscription plan
    * @property {string} error holdes the error message of a failed rest api call
    * @property {object} userAuth the authenticated user infromation
    * @property {string} subscriptionPlanID tha subscription plan id to get subscription plan current information for edit subscription plan page
    * @property {object} subscriptionPlan an empty object if add or the selected subscription plan to be editted
    * @property {object} subscription an empty object if add or the selected subscription to be editted
    * @property {*} icon the legend fa icon
    * @property {array} statusOptions the different status options
    * @property {array} subscriptionPlans the subscription options to select from
    * @property {array} organizations the organization options to select from
    * @property {object} isError list of indication of empty form required field 
    *                           after submit to present error message to the
    *                           fields/attributes with true value
    */
   const [data, setData] = useState({
      error: null,
      userAuth: null,
      isAdd: subscriptionID === undefined,
      subscriptionID: subscriptionID,
      subscription: null,
      organization: null,
      subscriptionPlan: null,
      icon: AddIcon,
      menuItemProviderOptions: [],
      statusOptions: [],
      subscriptionPlans: [],
      organizations: [],
      recurrentStatuses: [],
      // error messages per field
      isError: {
         organizationID: "",
         planID: "",
         status: ""
      },
      currency: "€",
   });

   // if true the menu item is clicked and a redirect to page should perform
   const [redirect, setRedirect] = useState(false);
   // if true the menu item is clicked and a redirect to page should perform
   const [isLoaded, setIsLoaded] = useState(false);
   // snackbar details
   const [alertBox, setAlertBox] = useState({
      isOpen: false,
      message: "",
      backgroundColor: "#a71313"
   });

   //change document title
   useDocumentTitle(`Usee | ${t('subscription.pageTitle')} | ${data.isAdd ? t('actions.add') : t('actions.edit')}`);

   useEffect(() => {
      getSubscription();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   /**
    * The rest endpoint to get the subscription default (add) or current (edit) information.
    */
   function getSubscription() {
      if (data.isAdd) {
         subscriptionApi.fetchNew().then((r) => {
            setData({
               ...data,
               userAuth: r.data.returnobject.userAuth,
               subscription: {
                  ...getDefaultSubscription()
               },
               statusOptions: r.data.returnobject.statusOptions,
               subscriptionPlans: r.data.returnobject.subscriptionPlans,
               organizations: r.data.returnobject.organizations,
               recurrentStatuses: r.data.returnobject.recurrentStatuses,
               outletBranches: []
            });
            setIsLoaded(true);
         }).catch((e) => {
            // console.log(e);
         })
      } else {
         subscriptionApi.fetchOne(data.subscriptionID).then((r) => {
            setData({
               ...data,
               userAuth: r.data.returnobject.userAuth,
               subscription: r.data.returnobject.subscription,
               statusOptions: r.data.returnobject.statusOptions,
               subscriptionPlans: r.data.returnobject.subscriptionPlans,
               organizations: r.data.returnobject.organizations,
               outletBranches: r.data.returnobject.outletBranches,
               recurrentStatuses: r.data.returnobject.recurrentStatuses,
               currency: findCurrency(r.data.returnobject.subscriptionPlans?.find((subscriptionPlan) => subscriptionPlan?.id === r.data.returnobject?.subscription?.planID).prices[0]?.currencyString)
            });
            setIsLoaded(true);
         }).catch((e) => {
            // console.log(e);
         });
      }
   }

   /**
    * Gets called when the subscription clicks on the save button, and triggers 
    * the creation of the new subscription.
    */
   function addSubscription() {
      if (formValidation()) {
         subscriptionApi.create(data.subscription).then((r) => {
            setAlertBox({
               ...alertBox,
               isOpen: true,
               message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
               backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
            setRedirect((hasRoleAdmin(data.userAuth.roles) && r.data.code === "SUCCESS" ? true : false));
         }).catch((e) => {
            // console.log(e)
         });
      }
   }

   /**
    * Gets called when the subscription clicks on the save button, and triggers 
    * the edit of the selected subscription.
    */
   function editSubscription() {
      if (formValidation()) {
         subscriptionApi.update(data.subscription).then((r) => {
            setAlertBox({
               ...alertBox,
               isOpen: true,
               message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
               backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
            setRedirect((hasRoleAdmin(data.userAuth.roles) && r.data.code === "SUCCESS" ? true : false));
         }).catch((e) => {
            // console.log(e)
         });
      }
   }

   /**
    * Function that triggers form validation and print out if the form is valid or not.
    * @returns true if form is Valid
    */
   function formValidation() {
      let isError = createErrorMessages();
      if (formIsValid(isError)) {
         return true;
      } else {
         return false;
      }
   }

   /**
    * Function that create error messages for each required field that are not filled out.
    * @returns object containing the error messages for each form field
    */
   function createErrorMessages() {
      let isError = { ...data.isError };
      isError.organizationID = data.subscription.organizationID.length < 1 ? t('table.valueReuired') : "";
      isError.planID = data.subscription.planID.length < 1 ? t('table.valueReuired') : "";
      isError.status = data.subscription.status.length < 1 ? t('table.valueReuired') : "";
      setData({
         ...data,
         isError
      });
      return isError;
   }

   /**
    * Function that handles the snackbar open or close state.
    * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
    */
   function handleSnackbarState(isOpen) {
      setAlertBox({
         ...alertBox,
         isOpen: isOpen
      });
   }

   /**
    * Calculates the total amount based on the service usage counters and the service charges.
    *
    * This function iterates over the service counters in the provided data object and
    * multiplies each counter with its corresponding service charge defined in the
    * subscription plan. The total amount is the sum of these individual amounts.
    *
    * @param {Object} data - Object containing service usage counters.
    * @param {Object} subscriptionPlan - Object containing service charges.
    * @param {number} data.smsCounter - Number of SMS sent.
    * @param {number} data.emailCounter - Number of emails sent.
    * @param {number} data.ticketCounter - Number of tickets used.
    * @param {number} data.receiptTicketCounter - Number of receipt tickets used.
    * @param {Object[]} subscriptionPlan.services - Array of service objects with key-value pairs.
    * @returns {number} The calculated total amount based on the service usage and charges.
    */
   function calculateAmount(data, subscriptionPlan) {
      let amount = 0;

      // Mapping service keys to their charges
      const serviceCharges = subscriptionPlan?.services?.reduce((charges, service) => {
         charges[service.key] = parseFloat(service.value);
         return charges;
      }, {});

      // Calculating the amount
      if (data.smsCounter) amount += data.smsCounter * (serviceCharges.SMS || 0);
      if (data.emailCounter) amount += data.emailCounter * (serviceCharges.EMAIL || 0);
      if (data.ticketCounter) amount += data.ticketCounter * (serviceCharges.PRINTER_TICKET || 0);
      if (data.receiptTicketCounter) amount += data.receiptTicketCounter * (serviceCharges.RECEIPT_TICKET || 0);

      return amount;
   }

   /**
    * Calculates the total amount for a list of data objects, adds a double of a fixed fee to the total.
    *
    * This function iterates over each data object in the provided list, calculates the amount
    * for each using the calculateAmount function, sums these amounts, and then adds double
    * the value of a provided fixed fee to this sum.
    *
    * @returns {number} The total amount including the double of the fixed fee.
    */
   function calculateTotalWithFee() {
      //List of data objects containing service usage counters.
      let dataList = data?.outletBranches;
      //Object containing service charges.
      let subscriptionPlan = data?.subscriptionPlans?.find((subscriptionPlan) => subscriptionPlan?.id === data.subscription?.planID);
      // The fixed organization fee value to be doubled and added to the total amount.
      let fee = isRenewalDueSoon(data.subscription) ? data?.subscriptionPlans?.find((subscriptionPlan) => subscriptionPlan?.id === data.subscription?.planID)?.prices[0].price : 0;
      let totalAmount = 0;

      dataList?.forEach(data => {
         totalAmount += calculateAmount(data, subscriptionPlan);
      });
      return totalAmount + fee;
   }

   /**
    * Checks if the renewal of a subscription is due within 11 days.
    *
    * This function first searches for the last "RENEWED" status in the actionsHistory.
    * If not found, it searches for the last "ACTIVE" status. It then calculates if the 
    * renewal of the subscription plan is due in less than 11 days based on the
    * subscription plan's type.
    *
    * @param {Object} subscription - The subscription object containing actionsHistory and subscriptionPlan.
    * @returns {boolean} Returns true if the renewal is due within 11 days, otherwise false.
    */
   function isRenewalDueSoon(subscription) {
      const actionsHistory = subscription?.actionsHistory;
      const subscriptionPlan = data?.subscriptionPlans?.find((subscriptionPlan) => subscriptionPlan?.id === subscription?.planID);
      if (actionsHistory) {
         let lastAction = [...actionsHistory]?.reverse().find(action => action.status === 'RENEWED');
         if (!lastAction) {
            // If no RENEWED status is found, look for the last ACTIVE status
            lastAction = [...actionsHistory]?.reverse().find(action => action.status === 'ACTIVE');
         }

         // If neither RENEWED nor ACTIVE status is found, return false
         if (!lastAction) return false;

         const lastActionDate = new Date(lastAction.dateTime);
         let nextRenewalDate = new Date(lastActionDate);

         switch (subscriptionPlan?.type) {
            case 'MONTHLY':
               nextRenewalDate.setMonth(lastActionDate.getMonth() + 1);
               break;
            case 'YEARLY':
               nextRenewalDate.setFullYear(lastActionDate.getFullYear() + 1);
               break;
            case 'HALF_YEAR':
               nextRenewalDate.setMonth(lastActionDate.getMonth() + 6);
               break;
            case 'TWO_YEARS':
               nextRenewalDate.setFullYear(lastActionDate.getFullYear() + 2);
               break;
            case 'QUARTER':
               nextRenewalDate.setMonth(lastActionDate.getMonth() + 3);
               break;
            default:
               return false; // Unrecognized subscription type
         }

         const currentDate = new Date();
         const timeDiff = nextRenewalDate - currentDate;
         const daysRemaining = timeDiff / (1000 * 60 * 60 * 24);

         return daysRemaining < 11;
      } else {
         return false;
      }
   }


   // ===================================== EMAILS

   function handleEmailsFieldChange(value) {
      let newEmails = value.map(email => ({ email, isEnabled: true }))

      formValChangeWithParentElementWithNameAndValue("subscription.emails", newEmails, data, setData);
   }

   // ===============================================================================RENDER

   if (redirect) {
      navigate(`/${getLanguageFromURL()}/subscription`)
   } else if ((!isLoaded && !data.isAdd) || data.subscription === null) {
      return <LoadingSkeleton lines={9} />;
   } else {

      let defaultOrganizationSelected = "";
      let defaultSubscriptionPlanSelected = "";
      let defaultStatusSelected = "";
      if (data.subscription !== null && !data.isAdd) {
         if (data.organizations.length !== 0) defaultOrganizationSelected = data.organizations.find(org => equalsIgnoreCase(org.id, data.subscription.organizationID)).id;
         if (data.subscriptionPlans.length !== 0) defaultSubscriptionPlanSelected = data.subscriptionPlans.find(plan => equalsIgnoreCase(plan.id, data.subscription.planID)).id;
         if (data.statusOptions.length !== 0) defaultStatusSelected = data.statusOptions.find(status => equalsIgnoreCase(status, data.subscription.status));
      }

      return (
         <CustomGrid role="main" id={"subscription-" + data.isAdd ? "add" : "edit"} sx={mainTag()}>
            <CustomGrid sx={mainTagBreadcrumb()}>
               <CustomAddEditBreadcrumb
                  isAdd={data.isAdd}
                  parentName={t('subscription.pageTitle')}
                  parentUrl={`/${getLanguageFromURL()}/subscription`}
                  instanceName={(data.subscription !== null && !data.isAdd) ? data.subscription.id : ""}
               />
            </CustomGrid>

            <CustomGrid sx={mainArea()} container={false}>
               <form id="subscription" onSubmit={(e) => { return false; }}>
                  {data.isAdd &&
                     <input type="hidden" />
                  }
                  <Grid container spacing={2}>
                     <Grid item xs={12} md={6}>
                        <InputLabel required={true} htmlFor="organizationID">{t('org.pageTitle')}</InputLabel>
                        <CustomObjectSelect
                           name="subscription.organizationID"
                           required={true}
                           defaultValue={defaultOrganizationSelected}
                           // labelID="organizationID" label={t('org.pageTitle')}
                           options={data.organizations}
                           onChange={(event) => onSelectChange(event, data, setData)}
                           field={"name"}
                           sx={{ borderRadius: "30px" }}
                           error={data.isError.organizationID.length > 0}
                        />
                        {data.isError.organizationID.length > 0 &&
                           <FormHelperText sx={selectError()}>
                              {data.isError.organizationID}
                           </FormHelperText>
                        }
                     </Grid>

                     <Grid item xs={12} md={6}>
                        <InputLabel required={true} htmlFor="subscriptionPlan">{t('subscriptionPlan.pageTitle')}</InputLabel>
                        <CustomObjectSelect
                           name="subscription.planID"
                           required={true}
                           defaultValue={defaultSubscriptionPlanSelected}
                           // labelID="planID" label={t('subscriptionPlan.pageTitle')}
                           options={data.subscriptionPlans}
                           onChange={(event) => onSelectChange(event, data, setData)}
                           field={"name"}
                           sx={{ borderRadius: "30px" }}
                           error={data.isError.planID.length > 0}
                        />
                        {data.isError.planID.length > 0 &&
                           <FormHelperText sx={selectError()}>
                              {data.isError.planID}
                           </FormHelperText>
                        }
                     </Grid>

                     <Grid item xs={12} md={6}>
                        <InputLabel required={true} htmlFor="status">{t('label.status')}</InputLabel>
                        <CustomSelect
                           name="subscription.status"
                           required={false}
                           defaultValue={defaultStatusSelected}
                           // labelID="status" label={t('label.status')}
                           options={data.statusOptions}
                           onChange={(event) => onSelectChange(event, data, setData)}
                           sx={{ borderRadius: "30px" }}
                           error={data.isError.status.length > 0}
                        />
                        {data.isError.status.length > 0 &&
                           <FormHelperText sx={selectError()}>
                              {data.isError.status}
                           </FormHelperText>
                        }
                     </Grid>

                     {!data.isAdd &&
                        <Grid item xs={12} md={6}>
                           <InputLabel required={true} htmlFor="dueDate">{t('label.dueDate')}</InputLabel>
                           <CustomDatePicker
                              name="subscription.dueDate"
                              inputFormat="DD/MM/YYYY"
                              value={data.subscription.dueDate}
                              onChange={(newValue) => {
                                 setData({ ...data, subscription: { ...data.subscription, dueDate: newValue } });
                              }}
                           />
                        </Grid>
                     }
                     <Grid item xs={12} md={6}>
                        <InputLabel required={true} htmlFor="recurrentStatus">{t('subscription.recurrentStatus.label')}</InputLabel>
                        <CustomSelect
                           name="subscription.recurrentStatus.label"
                           required={true}
                           value={data.subscription.recurrentStatus}
                           options={data.recurrentStatuses}
                           sx={{ maxHeight: "62px" }}
                           onChange={(event) => formValChangeWithParentElementWithNameAndValue("subscription.recurrentStatus", event.target.value, data, setData)}
                        // label={t('subscription.recurrentStatus')}

                        />
                     </Grid>

                     <Grid item xs={12} md={12}>
                        <InputLabel required={true} htmlFor="recurrentStatus">{t('subscription.emails')}</InputLabel>
                        <CustomChipEmailInput
                           id="emails"
                           name="subscription.emails"
                           // label={t("subscription.emails")}
                           allowDuplicates={false}
                           value={data.subscription.emails}
                           validateChip={isValidEmail}
                           onChange={(event) => handleEmailsFieldChange(event)}
                        />
                     </Grid>

                     {!data.isAdd &&
                        <>
                           {data.outletBranches.length > 0 &&
                              <Grid item xs={12} md={6}>
                                 <CustomTypography variant="body2" text={t('subscription.organization.pendingCharges')} sx={{ ...subscriptionDetailsHeaderStyle(), marginBottom: "5px" }} />
                                 {data.outletBranches?.map((outletBranch) =>
                                    <SubscriptionPendingChargesInstanceOutletCharge currency={data.currency} outletBranch={outletBranch} subscriptionPlan={data.subscriptionPlans?.find((subscriptionPlan) => subscriptionPlan?.id === data.subscription?.planID)} />
                                 )}

                                 <Grid container sx={{ marginLeft: "20px" }}>
                                    {isRenewalDueSoon(data.subscription) &&
                                       <>
                                          <Grid item xs={7} md={7}>
                                             <CustomTypography variant="body2" text={t('subscription.organization.history.organizationCharges')} sx={subscriptionHistoryDetailsLabelStyle()} />
                                          </Grid>
                                          <Grid item xs={5} md={5}>
                                             {/* <CustomTypography variant="body2" text={data.subscriptionPlans?.find((subscriptionPlan) => (subscriptionPlan?.id === data.subscription?.subscriptionPlan?.id)).prices?[0].price} sx={subscriptionHistoryDetailsTextStyle()} /> */}
                                          </Grid>
                                       </>
                                    }
                                    <Grid item xs={12} md={12}>
                                       <Divider light flexItem sx={{ borderRightWidth: 5, width: "100%", marginBottom: "5px", marginTop: "15px" }} />
                                    </Grid>
                                    <Grid item xs={7} md={7}>
                                       <CustomTypography variant="body2" text={t('subscription.organization.history.totalAmount')} sx={subscriptionHistoryDetailsLabelStyle()} />
                                    </Grid>
                                    <Grid item xs={5} md={5}>
                                       <CustomTypography variant="body2" text={`${calculateTotalWithFee()} ${data.currency}`} sx={subscriptionHistoryDetailsTextPriceStyle()} />
                                    </Grid>
                                 </Grid>
                              </Grid>
                           }

                           <Grid item xs={12} md={6}>
                              <CustomTypography variant="body2" text={t('subscription.history')} sx={subscriptionDetailsHeaderStyle()} />
                              <CustomSubscriptionTimeline timelineDetails={data?.subscription?.actionsHistory} subscriptionPlans={data?.subscriptionPlans} outletBranches={data.outletBranches} />
                           </Grid>

                        </>
                     }
                  </Grid>

                  {/* <CustomIconButton variant="outlined" keyValue="save" onClick={data.isAdd ? addSubscription : editSubscription} label={t('actions.save')} sx={formSaveButton()} /> */}
               </form>

               <Fab color="primary"
                  aria-label="add"
                  sx={fabStyle()}
                  onClick={data.isAdd ? addSubscription : editSubscription}
               >
                  <SaveIcon />
               </Fab>
            </CustomGrid>


            {alertBox.isOpen &&
               <CustomSnackbar
                  isOpen={alertBox.isOpen}
                  autoHideDuration={3000}
                  message={alertBox.message}
                  backgroundColor={alertBox.backgroundColor}
                  handleSnackbarOpen={handleSnackbarState} />

            }
         </CustomGrid>
      );
   }
}

export default SubscriptionAddEditComponent;