import { faMoneyBill, fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Autocomplete,
    Box,
    Checkbox,
    CircularProgress,
    Divider,
    Fab,
    Grid,
    InputLabel,
    Popper,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';
import requestApi from 'api/request';
import { CheckBoxIcon, CheckBoxOutlineBlankIcon, NotesIcon, SendIcon, ShoppingCartIcon } from 'assets/mui/MuiIcons';
import { CleanTableIcon } from 'assets/svg/SvgIcons';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomTextArea from 'components/Text/CustomTextArea';
import { getDefaultRequestObject, getTranslationDefaultObject } from 'constants/defaultObjects';
import { getRequestButton, getRequestButtonIcon } from 'constants/fieldProperties';
import { requestTextFieldLimit } from 'constants/textFieldLimit';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { customerRequestTextStyle, requestButtonFontAwesomeIcon, requestButtonIcon, requestButtonSvgIcon, requestFabButtonStyle, requestTextareaBoxStyle } from 'themes/defaultThemes';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';
import { getLanguageFromURL } from 'utils/language';
import CustomDialogBasedOnScreenChildren from '../CustomDialogBasedOnScreenChildren';

/**
 * The EditPrintNodeItemDialog, that display a customized dialog to edit a print node.
 * 
 * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
 * @property {string} id the dialog id
 * @property {string} title the dialog title
 * @property {string} cancelLabel the dialog cancel label
 * @property {string} actionLabel the dialog action label
 * @property {string} field the field of the outer object to be edited.
 * @property {function} action the function that handles the dialog action button click
 * @property {function} handleOpen the function that handles the dialog open state
 * @property {objectName} printer the printer object details
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomAddRequestDialog({ isOpen = false, id = "", title, cancelLabel, actionLabel, field,
    action, handleClose, equipmentID, organizationID, outletID, setAlertBox, equipmentOptions = [], getCustomerRequests, getPDADisplay }) {

    const [loadingBtn, setLoadingBtn] = useState(false);

    const isMobile = useMediaQuery('(max-width:468px)');
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const PopperMy = function (props) {
        const anchorEl = document.getElementById('dialog-content');
        return (<Popper {...props}
            modifiers={[
                {
                    name: 'flip',
                    enabled: false, // Disable flipping if necessary
                },
                {
                    name: 'preventOverflow',
                    enabled: true, // Disable overflow prevention if necessary
                    options: {
                        altAxis: true, // Adjust alternate axis behavior if necessary
                        tether: false, // Disable tethering if necessary
                    },
                },
            ]}
            // anchorEl={anchorEl}
            style={{ zIndex: 100000, position: "relative", width: isMobile ? "100%" : "50%", margin: "20px", height: anchorEl?.clientHeight }}
            placement='bottom'
            keepMounted={false}
        />)
    }

    // @property {boolean} if the values is `true`, the modal should be open and visible.
    const { t } = useTranslation();

    const [data, setData] = useState({
        userAuth: null,
        types: [],
        statuses: [],
        requestExtraCustomButtons: [],
        request: getDefaultRequestObject()
    });

    function handleAction() {
        action(data.request, field);
    }
    const [disableSendButton, setDisableSendButton] = useState(true);

    useEffect(() => {
        getRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (data.request.type !== "") setDisableSendButton(false);
    }, [data.request.type])


    function getRequest() {
        requestApi.fetchNew().then((r) => {
            setData({
                ...data,
                userAuth: r.data.returnobject.userAuth,
                request: getDefaultRequestObject(organizationID, outletID, equipmentID),
                types: r.data.returnobject.types,
                requestExtraCustomButtons: r.data.returnobject.requestExtraCustomButtons
            });
        }).catch((e) => {
            // console.log(e);
        });
    }

    function createRequest() {
        setDisableSendButton(true);
        let request = data.request;
        // console.log(request)
        // request.equipmentID = request?.equipment?.id;
        setLoadingBtn(true);
        requestApi.create(request).then((r) => {
            setAlertBox({
                isOpen: true,
                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
            if (r.data.code === "SUCCESS") {
                getCustomerRequests();
                handleClose();
            }
            setDisableSendButton(false);
            setLoadingBtn(false);
            if (getPDADisplay !== undefined) getPDADisplay();
        }).catch((r) => {
        });
    }


    function body() {
        return <>
            <Grid container spacing={2} justifyContent={"center"} justifyItems={"center"}
                sx={{
                    width: "100%",
                    marginBottom: "60px",
                    alignItems: "center",
                    padding: "10px"
                }}>
                {!equipmentID &&
                    <Grid item xs={12}>
                        <Autocomplete
                            key="equipment"
                            id="equipment-select"
                            options={equipmentOptions}
                            autoHighlight
                            // disableCloseOnSelect
                            multiple={false}
                            getOptionLabel={(option) => option.label}
                            noOptionsText={t('equipment.emptyEquipmentsSearch')}
                            onChange={(event, newValue) => {
                                formValChangeWithParentElementWithNameAndValue("request.equipmentID", newValue.id, data, setData);
                            }}
                            PopperComponent={PopperMy}
                            renderOption={(props, option, { selected }) => {
                                return <>
                                    <li key={option.id} {...props} style={{ borderBottom: (equipmentOptions[equipmentOptions.length - 1].id !== option.id) ? "1px solid darkgrey" : "none", paddingTop: "20px" }}>
                                        <div key={option.id + "-1"} style={{ display: "contents" }}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                checked={selected}
                                            />
                                        </div>
                                        <div key={option.id + "-2"} style={{ display: "block", width: "90%" }}>
                                            <Typography variant="inherit" sx={{ fontWeight: 600, fontSize: "15px" }}>{option.label}</Typography>
                                        </div>
                                    </li>
                                </>
                            }}

                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={data.equipmentID}
                                    variant='filled'
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                    </Grid>
                }
                {data.types?.includes("ORDER") &&
                    <Grid item xs={6}>
                        <CustomIconButton
                            {...getRequestButton(data.request.type, "order", "ORDER", t('outlet.settings.request.allowedRequestTypes.MENU'), true)}
                            icon={<ShoppingCartIcon {...getRequestButtonIcon()} sx={requestButtonIcon()} />}
                            onClick={() => formValChangeWithParentElementWithNameAndValue("request", { ...data.request, type: "ORDER", icon: null, label: getTranslationDefaultObject() }, data, setData)}
                        />
                    </Grid>
                }

                {data.types?.includes("PAYMENT") &&
                    <Grid item xs={6}>
                        <CustomIconButton
                            {...getRequestButton(data.request.type, "pay", "PAYMENT", t('outlet.settings.request.allowedRequestTypes.PAYMENT'), true)}
                            icon={<FontAwesomeIcon icon={faMoneyBill} style={requestButtonFontAwesomeIcon()} />}
                            onClick={() => formValChangeWithParentElementWithNameAndValue("request", { ...data.request, type: "PAYMENT", icon: null, label: getTranslationDefaultObject() }, data, setData)}
                        />
                    </Grid>
                }

                {data.types?.includes("CLEAN_TABLE") &&
                    <Grid item xs={6}>
                        <CustomIconButton
                            {...getRequestButton(data.request.type, "request", "CLEAN_TABLE", t('outlet.settings.request.allowedRequestTypes.CLEAN_TABLE'), true)}
                            icon={<CleanTableIcon  {...requestButtonSvgIcon()} />}
                            onClick={() => formValChangeWithParentElementWithNameAndValue("request", { ...data.request, type: "CLEAN_TABLE", icon: null, label: getTranslationDefaultObject() }, data, setData)}
                        />
                    </Grid>
                }

                {(data.types?.includes("CUSTOM") && data.requestExtraCustomButtons?.length > 0) &&
                    <>
                        {data.requestExtraCustomButtons?.map((requestExtraCustomButton) => (
                            <Grid item xs={6}>
                                <CustomIconButton
                                    {...getRequestButton(data.request.label[getLanguageFromURL()], requestExtraCustomButton?.label[getLanguageFromURL()], requestExtraCustomButton?.label[getLanguageFromURL()], requestExtraCustomButton?.label[getLanguageFromURL()], true)}
                                    icon={<FontAwesomeIcon icon={fas[requestExtraCustomButton.icon]} style={requestButtonFontAwesomeIcon()} />}
                                    onClick={() => formValChangeWithParentElementWithNameAndValue("request", { ...data.request, type: "CUSTOM", icon: requestExtraCustomButton?.icon, label: requestExtraCustomButton?.label }, data, setData)}
                                />
                            </Grid>
                        ))}
                    </>
                }

                {data.types?.includes("OTHER") &&
                    <Grid item xs={6} sx={{ height: "100%" }}>
                        <CustomIconButton
                            {...getRequestButton(data.request.type, "other", "OTHER", t('outlet.settings.request.allowedRequestTypes.OTHER'), true)}
                            icon={<NotesIcon {...getRequestButtonIcon()} sx={requestButtonIcon()} />}
                            onClick={() => formValChangeWithParentElementWithNameAndValue("request.type", "OTHER", data, setData)}
                        />
                    </Grid>
                }

                {data.request.type === "OTHER" &&
                    <Grid container spacing={2} sx={{ marginTop: "20px", marginLeft: "10px" }}>
                        <Grid item xs={12} sm={12} sx={{ textAlign: "-webkit-left" }}>
                            <InputLabel required={false} htmlFor="request" sx={customerRequestTextStyle}>{t('request.request')}</InputLabel>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <CustomTextArea
                                placeholder={t('request.requestPlaceholder')}
                                value={data.request.request}
                                inputProps={{ maxLength: 100 }}
                                maxLength={100}
                                onChange={(event) => {
                                    let newValue = event.target.value.slice(0, 100);
                                    formValChangeWithParentElementWithNameAndValue("request.request", newValue, data, setData);
                                }}
                                variant="outlined"
                                sx={{ borderColor: "lightgrey" }}
                                endDecorator={
                                    <Box sx={requestTextareaBoxStyle}>
                                        <Divider flexItem={true} />
                                        <Typography level="span" sx={{ ml: 'auto', color: data.request.request.length === 100 ? "red" : "initial" }}>
                                            {`${data.request.request.length} ${data.request.request.length === 1 ? t('request.requestCharLimit') : t('request.requestCharsLimit')} /${requestTextFieldLimit}`}
                                        </Typography>
                                    </Box>
                                }
                            />
                        </Grid>
                    </Grid>
                }
            </Grid>

            <Box sx={{ m: 1, position: isMobile ? 'relative' : "initial", float: "right" }}>
                    <Fab
                        aria-label="add"
                        variant="extended"
                        sx={requestFabButtonStyle(loadingBtn, isMobile ? "fixed" : "absolute")}
                        onClick={createRequest}
                        disabled={disableSendButton}
                    >
                        {loadingBtn && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: "47a6d2",
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                        <SendIcon sx={{ mr: 1 }} />
                        {t('request.btns.send')}
                </Fab>
            </Box>
        </>
    }
    // =============================== RENDER

    return (
        <>
            <CustomDialogBasedOnScreenChildren
                isOpen={isOpen}
                handleClose={() => handleClose(false)}
                handleOpen={() => handleClose(true)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                cancelLabel={undefined}
                actionLabel={undefined}
                handleAction={handleAction}
                children={body()}
                includeActions={false}
            />
        </>
    );
}

export default CustomAddRequestDialog;