import QrCode2Icon from '@mui/icons-material/QrCode2';
import { Button, Grid, Stack, SvgIcon } from "@mui/material";
import equipmentApi from "api/equipment";
import CustomBreadcrumbMultiParent from "components/Breadcrumb/CustomBreadcrumbMultiParent";
import CustomIconButton from "components/Button/CustomIconButton";
import CustomRefreshButton from 'components/Button/CustomRefreshButton';
import CustomDialog from 'components/Dialogs/CustomDialog';
import ExportQrDialog from "components/Dialogs/qrcode/ExportQrDialog";
import CustomGrid from "components/Grid/CustomGrid";
import ListPageSkeleton from 'components/Loading/ListPageSkeleton';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';
import CustomPageableTable from "components/Table/CustomPageableTable";
import CustomTitle from "components/Title/CustomTitle";
import { getDefaultSortDirectionData } from "constants/sortDirection";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import { mainAreaTable, mainTag, mainTagBreadcrumb, mainTagSearch, mainTagTitle, pageAddButton } from "themes/defaultThemes";
import { hasRoleAdmin } from "utils/auth";
import { formValChangeWithParentElementWithNameAndValue } from "utils/form-validation";
import { prepareTablePaginationSortParams } from "utils/functions";
import { getLanguageFromURL } from "utils/language";
import { actionFormatterEquipment, dateFormatter, fieldFormatter, iconFormatter, idFormatterLinkWithParent } from "utils/table";
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The Equipment List component to view the equipments of the outlet.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function EquipmentListComponent() {
    const { organizationID } = useParams();
    const { outletID } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    //change document title
    useDocumentTitle(`Usee ${organizationID !== undefined ? " | " + t('org.pageTitle') : ""} ${outletID !== undefined ? " | " + t('outlet.pageTitle') : ""} ${t('equipment.title')}`)

    let path = `/${getLanguageFromURL()}`
    if (organizationID !== undefined) path += `/organization/${organizationID}`;
    if (outletID !== undefined) path += `/outlet/${outletID}`;
    path += `/equipment`;

    /**
     * @type {object}
     * @property {object} userAuth the authenticated user infromation
     * @property {object} equipments the equipments (pageable)
     * @property {object} organization the outlet's organization details
     * @property {object} outlet tha outlet basic details (id and name, isActive)
     * @property {object} pagination includes all pageable details (page, size, sort, direction)
     */
    const [data, setData] = useState({
        userAuth: null,
        equipments: null,
        organization: {
            id: organizationID,
            name: ""
        },
        outlet: {
            id: outletID,
            name: ""
        },
        pagination: {
            count: -1,
            size: 10,
            page: 0,
            ...getDefaultSortDirectionData
        },
        equipmentsList: [],
        qrCodeTemplates: [],
        logo: '',
        isOutletLogo: false
    });

    // to perform initial request
    const [isFirstTime, setIsFirstTime] = useState(true);
    // if true the menu item is clicked and a redirect to page should perform
    const [isLoaded, setIsLoaded] = useState(false);
    // for search
    const [search, setSearch] = useState({
        field: "label",
        organizationID: organizationID,
        outletID: outletID,
        type: "",
        floor: "",
        section: "",
        label: ""
    });
    // pop dialog for delete
    const [dialog, setDialog] = useState({
        id: "",
        isOpen: false,
        equipmentID: "",
        label: ""
    });
    // pop dialog for eportQr
    const [exportQrDialog, setExportQrDialog] = useState({
        id: "",
        isOpen: false,
        equipment: {},
        label: "",
    });
    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313"
    });

    useEffect(() => {
        getEquipments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getEquipments();
        setIsFirstTime(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const identifier = setTimeout(() => {
            if (!isFirstTime) getEquipments();
        }, 700);

        return () => {
            clearTimeout(identifier);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, data.pagination.size, data.pagination.page, data.pagination.sort, data.pagination.direction])

    /**
     * The rest endpoint to get the equipments list.
     * @property {number} page the list page number
     */
    function getEquipments(page) {
        equipmentApi.fetchAll(prepareTablePaginationSortParams(data.pagination), search).then((r) => {
            Promise.resolve()
                .then(() => {
                    setData({
                        ...data,
                        userAuth: r.data.returnobject.userAuth,
                        equipments: r.data.returnobject.page,
                        equipmentTypeOptions: r.data.returnobject.equipmentTypeOptions,
                        organization: r.data.returnobject.organization,
                        outlet: r.data.returnobject.outlet,
                        qrCodeTemplates: r.data.returnobject.qrCodeTemplates,
                        equipmentsList: r.data.returnobject.equipments,
                        logo: r.data.returnobject.logo,
                        isOutletLogo: r.data.returnobject.outletLogo,
                        pagination: {
                            ...data.pagination,
                            count: r.data.returnobject.page.totalPages,
                            size: r.data.returnobject.page.size,
                            page: r.data.returnobject.page.number,
                        }
                    });
                })
                .then(() => {
                    setIsLoaded(true);
                })
        }).catch((e) => {
            // console.log(e);
        })
    }

    /**
     * Gets called to activate the selected equipment
     * @param {*} event
     * @param {string} id the id of the equipment
     */
    function activateEquipment(event, id) {
        equipmentApi.activate(id).then((r) => {
            setAlertBox({
                isOpen: true,
                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
            // setRedirect((r.data.code === "SUCCESS" ? true : false));
            getEquipments();
        }).catch((e) => {
            // console.log(e);
        })
    }

    /**
     * Gets called to activate the selected equipment
     * @param {*} event
     * @param {string} id the id of the equipment 
     */
    function disableEquipment(event, id) {
        equipmentApi.disable(id).then((r) => {
            setAlertBox({
                isOpen: true,
                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
            // setRedirect((r.data.code === "SUCCESS" ? true : false));
            getEquipments();
        }).catch((e) => {
            // console.log(e);
        })
    }


    /**
     * Function that handles the delete modal open or close data.
     * @property {boolean} isOpen The state of the dialog open status.
     * @property {string} equipmentID The id of the selected equipment to be removed.
     */
    function handleDialogState(isOpen, equipmentID = "", label = "") {
        setDialog({
            ...dialog,
            isOpen: isOpen,
            equipmentID: equipmentID,
            label: label
        });
    }

    /**
     * Function that handles the delete modal open or close data.
     * @property {boolean} isOpen The state of the dialog open status.
     * @property {string} equipmentID The id of the selected equipment to be removed.
     */
    function handleExportQrDialogState(isOpen, equipment = {}, label = "") {
        setExportQrDialog({
            ...exportQrDialog,
            isOpen: isOpen,
            equipment: equipment,
            label: label
        });
    }

    /**
     * 
     * Function that triggers the removal of the selected equipmentID.
     * @property {*} event
     * @property {string} equipmentID The id of the selected equipmentID to be removed.
     */
    function deleteEquipment(event, equipmentID, label = "") {
        handleDialogState(true, equipmentID, label);
    }


    function exportQRcodeForEquipment(event, equipmentID, label = "") {
        handleExportQrDialogState(true, data.equipments.content.find(equipment => equipment.id === equipmentID), label);
    }

    function exportQRcodeForEquipments(event) {
        handleExportQrDialogState(true, {}, "");
    }

    /**
     * Gets called to remove the selected equipment
     */
    function deleteE() {
        equipmentApi.deleteById(dialog.equipmentID).then((r) => {
            handleDialogState(false);
            setAlertBox({
                isOpen: true,
                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
            // setRedirect((r.data.code === "SUCCESS" ? true : false));
            getEquipments();
        }).catch((e) => {
            // console.log(e);
        })
    }

    /**
     * Function that handles the snackbar open or close data.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }

    /**
     * Function that handles the pagination information.
     * @property {int} page the page to be redirected
     */
    function handlePaginationChange(page) {
        formValChangeWithParentElementWithNameAndValue("pagination.page", page, data, setData);
    }

    /**
     * Function that handles the pagination information.
     * @property {int} size the size the new numberOfRows to be requested
     */
    function handleRowsPerPageChange(size) {
        let pagination = data.pagination;
        pagination.size = size;
        pagination.page = 0;
        formValChangeWithParentElementWithNameAndValue("pagination", pagination, data, setData);
    }

    /**
     * Function that handles the sort information.
     * @property {int} size the size the new numberOfRows to be requested
     */
    function handleColumnSortChange(changedColumn, direction) {
        let pagination = data.pagination;
        pagination.sort = changedColumn;
        pagination.direction = direction;
        formValChangeWithParentElementWithNameAndValue("pagination", pagination, data, setData);
    }

    /**
     * Function that triggers the multi select input value change.
     * @param {*} event 
     */
    /* eslint-disable no-unused-vars */
    function onSelectSearchChange(event) {
        let oldValue = search[search.field];
        let field = event.target.value;

        setSearch({
            ...search,
            field: field,
            type: "",
            floor: "",
            section: "",
            label: "",
            [field]: oldValue
        });
    }
    /* eslint-enable no-unused-vars */

    // ===============================================================================RENDER

    if (!isLoaded) {
        return <CustomGrid role="main" id="equipment-list" sx={mainTag()}>
            <ListPageSkeleton />
        </CustomGrid>
    } else {
        /* eslint-disable no-unused-vars */
        let defaultTypeSelected = "";
        if (search.type !== "") {
            defaultTypeSelected = search.type;
        }
        /* eslint-enable no-unused-vars */

        /**
         * datatable columns.
         */
        const table_columns = [
            {
                name: "active",
                label: t('label.isActive'),
                options: {
                    filter: false,
                    sort: false,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return iconFormatter(value,
                            tableMeta.tableData[tableMeta.rowIndex],
                            tableMeta.rowIndex, {
                            enable: (event, id) => activateEquipment(event, id),
                            disable: (event, id) => disableEquipment(event, id),
                            positionOfId: 1
                        });
                    }
                }
            },
            {
                name: "id",
                label: t('label.id'),
                options: {
                    viewColumns: data.userAuth !== null && hasRoleAdmin(data.userAuth.roles) ? true : false,
                    filter: false,
                    sort: false,
                    empty: false,
                    display: data.userAuth !== null && hasRoleAdmin(data.userAuth.roles) ? true : false,
                    customBodyRender: (value, tableMeta) => {
                        return idFormatterLinkWithParent(value, tableMeta.tableData[tableMeta.rowIndex], path);
                    }
                }
            },
            {
                name: "label",
                label: t('label.label'),
                options: {
                    filter: true,
                    filterType: 'textField',
                    customFilterListRender: v => `${t('label.label')}: ${v}`,
                    sort: true,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return fieldFormatter(value);
                    }
                }
            },
            // {
            //     name: "section",
            //     label: t('label.section'),
            //     options: {
            //         filter: false,
            //         sort: true,
            //         empty: false,
            //         customBodyRender: (value, tableMeta) => {
            //             return fieldFormatter(tableMeta.tableData[tableMeta.rowIndex].section);
            //         }
            //     }
            // },
            {
                name: "floor",
                label: t('label.floor'),
                options: {
                    filter: true,
                    filterType: 'textField',
                    customFilterListRender: v => `${t('label.floor')}: ${v}`,
                    sort: true,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return fieldFormatter(value);
                    }
                }
            },
            {
                name: "dateCreated",
                label: t('label.dateCreated'),
                options: {
                    filter: false,
                    sort: false,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return dateFormatter(value, tableMeta.tableData[tableMeta.rowIndex], null, "DD/MM/yyyy");
                    }
                }
            },
            {
                name: "",
                label: t('actions.label'),
                options: {
                    filter: false,
                    sort: false,
                    empty: true,
                    customBodyRender: (value, tableMeta) => {
                        return actionFormatterEquipment(value,
                            tableMeta.tableData[tableMeta.rowIndex],
                            tableMeta.rowIndex,
                            1,
                            2,
                            path,
                            data.showLabel ? t('actions.edit') : undefined,
                            (event, equipmentID, label) => deleteEquipment(event, equipmentID, label),
                            data.showLabel ? t('actions.delete') : undefined,
                            (event, equipmentID, label) => exportQRcodeForEquipment(event, equipmentID, label),
                            data.showLabel ? t('actions.exportQR') : undefined,
                            t('actions.exportQR.title')
                        )
                    },
                    setCellProps: () => ({ className: "click action" })
                }
            },
        ];

        return (
            <CustomGrid role="main" id="equipment-list" sx={mainTag()}>
                {outletID !== undefined && organizationID !== undefined &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                                { name: t('outlet.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet` },
                                { name: data.outlet.name, url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet/${outletID}` },
                            ]}
                            instanceName={t('equipment.pageTitle')}
                        />
                    </CustomGrid>
                }
                <Grid container spacing={2} sx={{ alignItems: "center" }}>
                    <Grid item xs={"auto"}>
                        <CustomRefreshButton onClick={getEquipments} marginTop="-1%" />
                    </Grid>

                    <Grid item xs={11} sm={3}>
                        <CustomGrid sx={mainTagTitle()}>
                            <CustomTitle
                                title={t('equipment.title')}
                                subtitle={t('equipment.subtitle')}
                            />
                        </CustomGrid>

                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Stack direction="row" sx={mainTagSearch()} >
                            <CustomGrid sx={mainTagSearch()} direction="column" container={false} spacing={2}>
                                {/*
                                <CustomSelect
                                    name="type"
                                    required={true}
                                    defaultValue={defaultTypeSelected}
                                    labelID="type" label={t('label.type')}
                                    options={["all", ...data.equipmentTypeOptions]}
                                    onChange={(event) => onSelectChange(event, search, setSearch)}
                                    sx={{ marginRight: "15px", maxHeight: "50px", minWidth: "150px", width: "170px", marginTop: "0%" }}
                                />

                                <CustomObjectSelect
                                    name="field"
                                    required={false}
                                    defaultValue={search.field}
                                    options={[{ id: "label", field: t("label.label") }, { id: "section", field: t("label.section") }, { id: "floor", field: t("label.floor") }]}
                                    onChange={onSelectSearchChange}
                                    sx={{ width: "170px", marginRight: "15px", maxHeight: "50px" }}
                                    field="field"
                                />

                                <CustomTextField
                                    type="search"
                                    name={search.field}
                                    id={search.field}
                                    variant="filled" label={t('actions.search') + " by " + t(`label.${search.field}`)}
                                    defaultValue={search[search.field] !== "" ? search[search.field] : ""}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end" >
                                                <CustomSearchButton onClick={getEquipments} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    required={false}
                                    sx={{ width: "300px", marginRight: "15px", maxHeight: "50px" }}
                                    onChange={(event) => formValChangeWithParentElementSelect(event, search, setSearch, t('table.valueReuired'))}
                                /> */}


                                <Button variant="text"
                                    onClick={() => navigate(`${path}/add`)}
                                    sx={{ ...pageAddButton(), margin: "15px" }}
                                >
                                    {t('actions.add') + " " + t('equipment.pageTitle')}
                                </Button>

                                <Button variant="text"
                                    onClick={() => navigate(`${path}/bulk-add`)}
                                    sx={{ ...pageAddButton(), margin: "15px" }}
                                >
                                    {t('equipment.bulkAddLabel')}
                                </Button>

                                <CustomIconButton
                                    keyValue={`exportPdf`}
                                    onClick={exportQRcodeForEquipments}
                                    variant='outlined'
                                    backgroundColor="#dcf1f7"
                                    label={""}
                                    tooltipTitle={""}
                                    icon={<SvgIcon component={QrCode2Icon} width={60} height={60} style={{ background: "transparent", color: "#54b1d2" }} />}
                                />
                            </CustomGrid>
                        </Stack>
                    </Grid>
                </Grid>

                <CustomGrid sx={mainAreaTable()} container={false}>
                    <CustomPageableTable
                        instances={data.equipments}
                        emptyMessage={t('equipment.detailsEmpty')}
                        table_columns={table_columns}
                        keyField="id"
                        defaultSorted={{ name: data.pagination.sort, direction: data.pagination.direction }}
                        pagination={data.pagination}
                        handlePaginationChange={handlePaginationChange}
                        handleRowsPerPageChange={handleRowsPerPageChange}
                        handleColumnSortChange={handleColumnSortChange}
                        filter={true}
                        filterType={"textField"}
                        onFilterChange={(event) => (event !== null) ? formValChangeWithParentElementWithNameAndValue(event.field, event.value, search, setSearch) : setSearch({
                            ...search,
                            type: "",
                            floor: "",
                            section: "",
                            label: ""
                        })}
                    />
                </CustomGrid>

                {alertBox.isOpen &&
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={3000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        handleSnackbarOpen={handleSnackbarState} />
                }

                {dialog.isOpen &&
                    <CustomDialog
                        isOpen={dialog.isOpen}
                        id="delete"
                        title={t('actions.deleteModal.title')}
                        actionLabel={t("actions.deleteModal.message")}
                        cancelLabel={t("actions.cancel")}
                        message={`${t('actions.deleteModal.notice')} ${t('actions.deleteModal.confirm')} ${t('equipment.pageTitle')}: `}
                        boldMessage={<span style={{ fontWeight: "bold" }}>{dialog.label}</span>}
                        action={deleteE}
                        handleOpen={handleDialogState}
                        actionColor="#e91e1e"
                    />
                }
                {exportQrDialog.isOpen &&
                    <ExportQrDialog
                        isOpen={exportQrDialog.isOpen}
                        id="delete"
                        title={`${t('actions.exportQR.title')} ${exportQrDialog.label}`}
                        actionLabel={t("actions.exportQR.actionButton")}
                        cancelLabel={t("actions.cancel")}
                        message={`${t('actions.deleteModal.notice')} ${t('actions.deleteModal.confirm')} ${t('equipment.pageTitle')}: `}
                        boldMessage={<span style={{ fontWeight: "bold" }}>{exportQrDialog.label}</span>}
                        action={exportQRcodeForEquipment}
                        handleOpen={handleExportQrDialogState}
                        actionColor="#54b1d2"
                        equipments={data.equipmentsList}
                        qrCodeTemplates={data.qrCodeTemplates}
                        isOutletLogo={data.isOutletLogo}
                        logo={data.logo}
                        organizationID={data.organization.id}
                        outletID={data.outlet?.id}
                        equipment={exportQrDialog.equipment}
                    />
                }
            </CustomGrid>
        );
    }
}

export default EquipmentListComponent;