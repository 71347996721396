import { getEyeShapeGrid, getFrameGrid } from "themes/qrcode/defaultTheme"
import { getDefaultQRcodes, getQrcodeEyeTabsIcon } from "./qrCodeDefault"
import { cropperBasicButtonIcon, cropperModeButtonActiveIcon, cropperModeButtonIcon, requestButton } from "themes/defaultThemes"

// ORGANIZATION
export const getOrganizationButtonProperties = () => {
    return {
        variant: 'outlined',
        backgroundColor: "#dcf1f7",
        sx: {
            width: "30px",
            height: "40px",
            marginRight: "0 !important"
        },
        label: undefined
    }
}

export const getOrganizationButtonIconProperties = () => {
    return {
        width: 30,
        height: 30,
        style: { background: "transparent", marginRight: 0 },
        fill: "black",
    }
}


// MODIFIER LISTS
export const getModifierListMinModifiers = () => {
    return {
        step: 1,
        min: 0,
        max: null,
        length: -1, //not defined
        isFloat: false
    }
}
export const getModifierListMaxModifiers = () => {
    return {
        step: 1,
        // min: 1,//based on min
        // max: null, //based on modifiers length
        length: -1, //not defined
        isFloat: false
    }
}

// PRINTER
export const getPrinterNodeId = () => {
    return {
        step: 1,
        min: "00000000",
        max: 99999999,
        length: 8,
        isFloat: false
    }
}

// LOYALTY
export const getLoyaltyCardTargetPoints = () => {
    return {
        step: 1,
        min: 0,
        max: 9999999999,
        length: 10,
        isFloat: false
    }
}

export const getLoyaltyCardDiscountPercentage = () => {
    return {
        step: "0.50",
        min: 0,
        max: 100.00,
        length: 3,
        isFloat: true
    }
}

export const getLoyaltyCardCouponValue = () => {
    return {
        step: "0.50",
        min: 0,
        max: 999.00,
        length: 3,
        isFloat: true
    }
}

export const getLoyaltyCardBarcodeHeight = () => {
    return {
        step: "0.50",
        min: 0,
        max: 110,
        length: 3,
        isFloat: true
    }
}

export const getLoyaltyCardBarcodeMarginLeft = () => {
    return {
        step: "1",
        min: 0,
        max: 10,
        length: 2,
        isFloat: false
    }
}

export const getLoyaltyCardBarcodeMarginRight = () => {
    return {
        step: "1",
        min: 0,
        max: 10,
        length: 2,
        isFloat: false
    }
}

export const getLoyaltyCardBarcodeMarginTop = () => {
    return {
        step: "1",
        min: 0,
        max: 10,
        length: 2,
        isFloat: false
    }
}

export const getLoyaltyCardBarcodeMarginBottom = () => {
    return {
        step: "1",
        min: 0,
        max: 10,
        length: 2,
        isFloat: false
    }
}

export const getLoyaltyCardBarcodeFontSize = () => {
    return {
        step: "1",
        min: 5,
        max: 20,
        length: 2,
        isFloat: false
    }
}

// COUPON

export const getCouponCardBarcodeHeight = () => {
    return {
        step: "0.50",
        min: 0,
        max: 110,
        length: 3,
        isFloat: true
    }
}

export const getCouponCardBarcodeMarginLeft = () => {
    return {
        step: "1",
        min: 0,
        max: 10,
        length: 2,
        isFloat: false
    }
}

export const getCouponCardBarcodeMarginRight = () => {
    return {
        step: "1",
        min: 0,
        max: 10,
        length: 2,
        isFloat: false
    }
}

export const getCouponCardBarcodeMarginTop = () => {
    return {
        step: "1",
        min: 0,
        max: 10,
        length: 2,
        isFloat: false
    }
}

export const getCouponCardBarcodeMarginBottom = () => {
    return {
        step: "1",
        min: 0,
        max: 10,
        length: 2,
        isFloat: false
    }
}

export const getCouponCardBarcodeFontSize = () => {
    return {
        step: "1",
        min: 5,
        max: 20,
        length: 2,
        isFloat: false
    }
}

// ITEM MODIFIER
export const geItemDiscountPercentage = () => {
    return {
        step: "0.50",
        min: 0,
        max: 100.00,
        length: 3,
        isFloat: true
    }
}

export const getItemPrice = () => {
    return {
        step: "0.50",
        min: 0,
        max: 99999999999999999999.99,
        length: 20,
        isFloat: true
    }
}

export const getItemLoyaltyPoints = () => {
    return {
        step: "1",
        min: 0,
        max: 999999999999999,
        length: 15,
        isFloat: false
    }
}

export const getItemPvcRecyclingTax = () => {
    return {
        step: "0.10",
        min: 0,
        max: 9999999999.99,
        length: 10,
        isFloat: true
    }
}

export const getItemNutrient = () => {
    return {
        step: "1",
        min: 0,
        isFloat: false
    }
}

export const getItemNutrientDetails = () => {
    return {
        step: "0.5",
        min: 0,
        length: -1,
        isFloat: true
    }
}

export const getItemButtonIcon = () => {
    return {
        width: 40,
        height: 40,
        fill: "#54b1d2"
    }
}

export const getItemOfferPercentage = () => {
    return {
        step: "0.50",
        min: 0,
        max: 100.00,
        length: 3,
        isFloat: true
    }
}


// ITEM INVENTORY
export const getItemInventorySortage = () => {
    return {
        step: 1,
        min: -1,
        max: null,
        length: -1,
        isFloat: false
    }
}

// QRCODE
export const getQrcodeFrameFontSize = () => {
    return {
        step: 1,
        min: 7,
        max: 20,
    }
}

export const getQrcodeLogoWidth = () => {
    return {
        step: 1,
        min: 25,
        max: 40,
    }
}

export const getQrcodeLogoHeight = () => {
    return {
        step: 1,
        min: 25,
        max: 40,
    }
}

export const getQrcodeLogoOpacity = () => {
    return {
        step: 0.1,
        min: 0,
        max: 1,
    }
}

export const getQrcodeLogoPadding = () => {
    return {
        step: 1,
        min: 2,
        max: 8,
    }
}

export const getQrcodeQuietZone = () => {
    return {
        step: 1,
        min: 0,
        max: 30,
    }
}

export const getQrcodeEyeTabsIconProperties = (index) => {
    return {
        size: 50,
        quietZone: 2,
        bgColor: "#fff",
        fgColor: "lightgrey",
        qrStyle: "squares",
        eyeRadiusTopLeftInner: "square",
        eyeRadiusTopRightInner: "square",
        eyeRadiusBottomLeftInner: "square",
        eyeRadiusTopLeftOuter: "square",
        eyeRadiusTopRightOuter: "square",
        eyeRadiusBottomLeftOuter: "square",
        eyeColor: getQrcodeEyeTabsIcon()[index].eyeColor
    }
}

export const getQrcodeEyeColorProperties = (index) => {
    return {
        size: 50,
        quietZone: 2,
        bgColor: "#fff",
        fgColor: "lightgrey",
        qrStyle: "squares",
        eyeRadiusTopLeftInner: "square",
        eyeRadiusTopRightInner: "square",
        eyeRadiusBottomLeftInner: "square",
        eyeRadiusTopLeftOuter: "square",
        eyeRadiusTopRightOuter: "square",
        eyeRadiusBottomLeftOuter: "square",
        eyeColor: getDefaultQRcodes()[index].eyeColor
    }
}


export const getQrcodeFrameButtonProperties = () => {
    return {
        variant: 'outlined',
        backgroundColor: "#dcf1f7",
        sx: getFrameGrid(),
        label: undefined
    }
}


export const getQrcodeEyeRadiusButtonProperties = () => {
    return {
        variant: 'outlined',
        backgroundColor: "#dcf1f7",
        sx: getEyeShapeGrid(),
        label: undefined
    }
}

export const getQrcodeEyeRadiusButtonIconProperties = () => {
    return {
        width: 20,
        height: 20,
        style: { background: "transparent", marginRight: 0 },
        fill: "black"
    }
}

export const getQrcodeFrameButtonIconProperties = () => {
    return {
        style: { fill: "black", background: "transparent", marginRight: 0 },
        fill: "black"
    }
}

export const getQrcodeFrameSelectedProperties = (fill) => {
    return {
        ...getQrcodeFrameButtonIconProperties(),
        // width: width,
        // height: height,
        style: {
            background: "transparent",
            // position: "absolute",
            color: fill
        }
    }
}

export const getQrcodeFrameSelectedSxProperties = (frameSelected, qrSize, scaleFactor = 1) => {
    switch (frameSelected) {
        case "none":
            return {};
        case "SimpleTop":
            return {
                width: (qrSize + 65) * scaleFactor,
                height: (qrSize + 65) * scaleFactor,
                qr: {
                    top: 40 * scaleFactor + "px"
                },
                frame: {},
                text: {
                    top: 2 * scaleFactor + 'px'
                }
            };
        case "SimpleBottom":
            return {
                width: (qrSize + 65) * scaleFactor,
                height: (qrSize + 65) * scaleFactor,
                qr: {
                    top: 8 * scaleFactor + 'px'
                },
                frame: {},
                text: {
                    bottom: 15 * scaleFactor + 'px'
                }
            };
        case "D1Label":
            return {
                width: (qrSize + 190) * scaleFactor,
                height: (qrSize + 190) * scaleFactor,
                qr: {
                    top: 78 * scaleFactor + 'px'
                }
            };
        case "BalloonTop":
            return {
                width: (qrSize + 100) * scaleFactor,
                height: (qrSize + 100) * scaleFactor,
                qr: {
                    top: 70 * scaleFactor + 'px'
                },
                frame: {},
                text: {
                    top: 10 * scaleFactor + 'px'
                }
            };
        case "BalloonBottom":
            return {
                width: (qrSize + 100) * scaleFactor,
                height: (qrSize + 100) * scaleFactor,
                qr: {
                    top: 8 * scaleFactor + 'px'
                },
                frame: {},
                text: {
                    bottom: 30 * scaleFactor + 'px'
                }
            };
        case "RibbonTop":
            return {
                width: (qrSize + 80) * scaleFactor,
                height: (qrSize + 80) * scaleFactor,
                qr: {
                    top: 44 * scaleFactor + 'px'
                },
                frame: {},
                text: {
                    top: 8 * scaleFactor + 'px'
                }
            };
        case "RibbonBottom":
            return {
                width: (qrSize + 80) * scaleFactor,
                height: (qrSize + 80) * scaleFactor,
                qr: {
                    top: 17 * scaleFactor + 'px'
                },
                frame: {},
                text: {
                    bottom: 25 * scaleFactor + 'px'
                }
            };
        case "Top":
            return {
                width: (qrSize + 80) * scaleFactor,
                height: (qrSize + 80) * scaleFactor,
                qr: {
                    top: 50 * scaleFactor + 'px'
                },
                frame: {},
                text: {
                    top: 10 * scaleFactor + 'px'
                }
            };
        case "Bottom":
            return {
                width: (qrSize + 80) * scaleFactor,
                height: (qrSize + 80) * scaleFactor,
                qr: {
                    top: 10 * scaleFactor + 'px'
                },
                frame: {},
                text: {
                    bottom: 25 * scaleFactor + 'px'
                }
            };
        case "Border10LabelFlat":
            return {
                width: (qrSize + 73) * scaleFactor,
                height: (qrSize + 73) * scaleFactor,
                qr: {
                    marginTop: '-' + 15 * scaleFactor + 'px'
                }
            }
        case "Hexagon10Label":
            return {
                width: (qrSize + 255) * scaleFactor,
                height: (qrSize + 255) * scaleFactor,
                qr: {
                    top: 83 * scaleFactor + 'px'
                },
                frame: {},
                text: {
                    bottom: 50 * scaleFactor + 'px'
                }
            };
        case "Cinema":
            return {
                width: (qrSize + 130) * scaleFactor,
                height: (qrSize + 130) * scaleFactor,
                qr: {
                    top: 60 * scaleFactor + 'px'
                },
                frame: {},
                text: {
                    bottom: 33 * scaleFactor + 'px'
                }
            }
        case "Phone":
            return {
                width: (qrSize + 155) * scaleFactor,
                height: (qrSize + 155) * scaleFactor,
                qr: {
                    top: 60 * scaleFactor + 'px'
                },
                frame: {},
                text: {
                    bottom: 55 * scaleFactor + 'px'
                }
            }
        case "RoundBorder":
            return {
                width: (qrSize + 105) * scaleFactor,
                height: (qrSize + 105) * scaleFactor,
                qr: {
                    top: 16 * scaleFactor + 'px'
                },
                frame: {},
                text: {
                    bottom: 15 * scaleFactor + 'px'
                }
            }
        case "CircleLabel":
            return {
                width: (qrSize + 230) * scaleFactor,
                height: (qrSize + 230) * scaleFactor,
                qr: {
                    top: 65 * scaleFactor + 'px'
                },
                frame: {},
                text: {
                    bottom: 52 * scaleFactor + 'px'
                }
            }
        case "CircleThinFlat":
            return {
                width: (qrSize + 200) * scaleFactor,
                height: (qrSize + 200) * scaleFactor,
                qr: {
                    top: 55 * scaleFactor + 'px'
                },
                frame: {},
                text: {
                    bottom: 40 * scaleFactor + 'px'
                }
            }
        case "CornersLabelFlat":
            return {
                width: (qrSize + 160) * scaleFactor,
                height: (qrSize + 160) * scaleFactor,
                qr: {
                    top: 35 * scaleFactor + 'px'
                },
                frame: {},
                text: {
                    bottom: 80 * scaleFactor + 'px'
                }
            }
        case "Corners":
            return {
                width: (qrSize + 100) * scaleFactor,
                height: (qrSize + 100) * scaleFactor,
                qr: {
                    top: 45 * scaleFactor + 'px'
                },
                frame: {},
                text: {}
            }
        case "DonitDisturb":
            return {
                width: (qrSize + 260) * scaleFactor,
                height: (qrSize + 260) * scaleFactor,
                qr: {
                    top: 77 * scaleFactor + 'px'
                },
                frame: {},
                text: {
                    bottom: 55 * scaleFactor + 'px'
                }
            }
        // case "LockScreen":
        //     return {
        //         width: (qrSize + 160)* scaleFactor,
        //         height:( qrSize + 160)* scaleFactor,
        //         qr: {
        //             marginTop: 16*scaleFactor+ 'px'
        //         }
        //     }
        case "LockscreenCircle":
            return {
                width: (qrSize + 500) * scaleFactor,
                height: (qrSize + 500) * scaleFactor,
                qr: {
                    top: 392 * scaleFactor + 'px',
                    left: "73.5%"
                },
                frame: {},
                text: {
                    top: 15 * scaleFactor + 'px',
                    left: "74%"
                }
            }
        case "LockscreenRounded":
            return {
                width: (qrSize + 222) * scaleFactor,
                height: (qrSize + 222) * scaleFactor,
                qr: {
                    top: 207 * scaleFactor + 'px'
                },
                frame: {},
                text: {
                    top: 15 * scaleFactor + 'px'
                }
            }
        case "RoundedBorder10LabelFlat":
            return {
                width: (qrSize + 110) * scaleFactor,
                height: (qrSize + 110) * scaleFactor,
                qr: {
                    top: 16 * scaleFactor + 'px'
                },
                frame: {},
                text: {
                    bottom: 17 * scaleFactor + 'px'
                }
            }
        case "RoundedCorners":
            return {
                width: (qrSize + 100) * scaleFactor,
                height: (qrSize + 100) * scaleFactor,
                qr: {
                    top: 30 * scaleFactor + 'px'
                },
                frame: {},
                text: {
                    bottom: 30 * scaleFactor + 'px'
                }
            }
        case "RoundedCornersFlat":
            return {
                width: (qrSize + 160) * scaleFactor,
                height: (qrSize + 160) * scaleFactor,
                qr: {
                    top: 38 * scaleFactor + 'px'
                },
                frame: {},
                text: {
                    bottom: 50 * scaleFactor + 'px'
                }
            }
        default:
            return {};
    }
}

// ================== CROPPER


export const cropperButtonProperties = {
    variant: 'outlined',
    size: 'small',
    sx: cropperBasicButtonIcon()
}

export const cropperModeButtonProperties = (mode, setMode, activeMode) => {
    return {
        variant: 'outlined',
        size: 'small',
        sx: (mode === activeMode) ? cropperModeButtonActiveIcon() : cropperModeButtonIcon(),
        keyValue: `btn-${mode}`,
        backgroundColor: "#54b1d273",
        isFocused: (mode === activeMode),
        onClick: () => setMode(mode)
    }
}


// =========== REQUESTS
export const MORE_REQUESTS_ADD_SIZE = 10;

// =========== ORDER
export const MORE_ORDER_ADD_SIZE = 10;

// =========== RATING
export const MORE_RATING_ADD_SIZE = 10;


// REQUESTS

export const getRequestButtonIcon = () => {
    return {
        width: 100,
        height: 100,
        fill: "#54b1d2"
    }
}
export const getRequestButton = (selectedType, key, type, label, showLabel = false) => {
    return {
        keyValue: `btn-${key}`,
        backgroundColor: "#dcf1f7",
        label: showLabel ? label : "",
        sx: { ...requestButton(), height: showLabel ? "90px" : "iniitial" },
        variant: 'outlined',
        isFocused: selectedType === type
    }
}


// =========== SUBSCRIPTION PLAN SERVICE

export const geSubscriptionPlanService = () => {
    return {
        step: "0.0100",
        min: 0,
        max: 500.0000,
        length: 9,
        isFloat: true,
        fixedPointForFloat: 4
    }
}

export const geSubscriptionPlanPrice = () => {
    return {
        step: "0.01",
        min: 0,
        max: 1000.00,
        length: 4,
        isFloat: true
    }
}


// ORDER PAYMENT ITEM QUANTITY
export const getOrderPaymentActionItemQuantity = (max) => {
    return {
        step: 1,
        min: 0,
        max: max,
        length: -1,
        isFloat: false
    }
}

export const getOutletPayClose = () => {
    return {
        style: { color: "#0000008a" },
        fill: "#0000008a",
        width: 40,
        height: 40,
    }
}

// RATING OUTLET 
export const getMinOutletRatingGoogle = () => {
    return {
        step: 1,
        min: 1,
        max: 5,
        length: -1, //not defined
        isFloat: false
    }
}

// =========== GLOBAL
export const getFontSize = () => {
    return {
        step: 1,
        min: 0,
        max: 40,
        length: 2,
        isFloat: false
    }
}

export const getMenuSvgIconProperties = () => {
    return {
        style: { color: "#0000008a" },
        fill: "#0000008a",
        width: 25,
        height: 25,
    }
}

export const getProfileMenuSvgIconProperties = () => {
    return {
        style: { color: "#0000008a" },
        fill: "#0000008a",
        width: 15,
        height: 15,
    }
}


// MARKETING
export const getRecipientAge = () => {
    return {
        step: 1,
        min: "13",
        max: 100,
        length: 3,
        isFloat: false
    }
}

// EQUIPMENT
export const getEquipmentCapacity = () => {
    return {
        step: 1,
        min: 0,//based on min
        max: null,
        length: -1, //not defined
        isFloat: false
    }
}