import { Save as SaveIcon } from '@mui/icons-material';
import { Fab, Grid, InputLabel } from "@mui/material";
import sectionApi from 'api/section';
import userApi from 'api/user';
import { ArrowBackIcon } from 'assets/mui/MuiIcons';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomGrid from 'components/Grid/CustomGrid';
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomSelectObjectWithChipAndIcon from 'components/Select/CustomSelectObjectWithChipAndIcon';
import CustomSnackbar from "components/Snackbar/CustomSnackbar";
import CustomTitle from 'components/Title/CustomTitle';
import CustomTypography from 'components/Typography/CustomTypography';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { fabStyle, linkStyle, mainArea, mainTag, mySectionsButtonAll, orderBackBtnStyle } from 'themes/defaultThemes';
import { hasRoleAdminOrDirectorOrManager } from 'utils/auth';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';
import { getLanguageFromURL } from 'utils/language';
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The MySectionComponent that triggers the update of user my-sections.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function MySectionComponent() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    /**
     * @type {object}  
     * 
     * @property {array} selectedSections user selected/active sections
     * @property {array} sections available sections
     */
    const [data, setData] = useState({
        userAuth: null,
        selectedSections: [],
        sections: []
    });

    //change document title
    useDocumentTitle(`Usee  | ${t('mysection.label')}`);

    // to render DOM based on rest api call status, if true the rest api call completed
    const [isLoaded, setIsLoaded] = useState(false);

    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313"
    });

    useEffect(() => {
        getSection();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * The rest endpoint to get the section default (add) or current (edit) information.
     */
    function getSection() {
        userApi.fetchMySections().then((r) => {
            setData({
                ...data,
                userAuth: r.data.returnobject.userAuth,
                selectedSections: r.data.returnobject?.selectedSections !== null ? r.data.returnobject.selectedSections : [],
                sections: r.data.returnobject?.sections !== null ? r.data.returnobject.sections : []
            });
            setIsLoaded(true);
        }).catch((e) => {
            // console.log(e);
        })
    }

    /**
     * Gets called when the user clicks on the save button, and triggers 
     * the edit of the selected feeedback form.
     */
    function editMySections() {
        let section = data.selectedSections;
        sectionApi.updateMySections(section).then((r) => {
            setAlertBox({
                isOpen: true,
                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
        }).catch((e) => {
            // console.log(e)
        });
    }

    /**
     * Function that handles the snackbar open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }

    /**
     * Function that triggers the removal of an outlet section.
     * @param {*} outletID 
     */
    function removeOutletSection(event, sectionIDToRemove, outletID) {
        // Assuming data.selectedSections is the array you're working with
        let selectedSections = data.selectedSections;

        if (!Array.isArray(selectedSections)) {
            return; // Nothing to remove if selectedSections is not an array
        }
        selectedSections = selectedSections?.map((section) => {
            let newSections = section.selectedSections;

            const index = newSections.indexOf(sectionIDToRemove);
            // only splice array when item is found
            if (index > -1) newSections.splice(index, 1);


            if (section.outletID === outletID) {
                return { ...section, selectedSections: newSections };
            }
            return section;
        });
        console.log(selectedSections)

        formValChangeWithParentElementWithNameAndValue("selectedSections", selectedSections, data, setData);
    }

    /**
     * Function that triggers the removal of an outlet section.
     * @param {*} outletID 
     */
    function addOutletSection(event, outletID) {
        let newSections = event.target.value;
        let selectedSections = data.selectedSections;

        if (!Array.isArray(selectedSections)) {
            selectedSections = []; // Initialize as an empty array if not already an array
        }

        selectedSections = selectedSections?.map((section) => {
            if (section.outletID === outletID) {
                return { ...section, selectedSections: newSections };
            }
            return section;
        });

        if (!selectedSections.some(section => section.outletID === outletID)) {
            selectedSections.push({ outletID: outletID, selectedSections: newSections });
        }

        formValChangeWithParentElementWithNameAndValue("selectedSections", selectedSections, data, setData);
    }


    /**
     * Function that triggers the add of all sections - outlet.
     * @param {*} outletID 
     */
    function addAllOutletSections(outletID, newSelectedSections) {
        newSelectedSections = newSelectedSections.map((newSection) => newSection.id);
        let selectedSections = data.selectedSections;

        selectedSections = selectedSections?.map((section) => {
            if (section.outletID === outletID) {
                return { ...section, selectedSections: newSelectedSections };
            }
            return section;
        });

        if (!selectedSections?.some(section => section.outletID === outletID)) {
            selectedSections.push({ outletID: outletID, selectedSections: newSelectedSections });
        }

        formValChangeWithParentElementWithNameAndValue("selectedSections", selectedSections, data, setData);
    }

    // ===============================================================================RENDER

    if (!isLoaded) {
        return (
            <LoadingSkeleton lines={9} />
        );
    } else {
        return (
            <CustomGrid role="main" id={"mysections"} sx={mainTag()}>
                <CustomTitle
                    title={`${t('menu.mysections')}`}
                    subtitle={' '}
                />

                <Grid item xs={12} md={12} sx={{ textAlign: "left" }}>
                    <CustomIconButton
                        icon={<ArrowBackIcon />}
                        sx={orderBackBtnStyle()}
                        onClick={() =>
                            navigate(`/${getLanguageFromURL()}/pda`)}
                        label={t('pda.backButton')}
                    />
                </Grid>

                <CustomGrid sx={mainArea()} container={false}>
                    <form id="section" onSubmit={(e) => { e.preventDefault(); }}>
                        <Grid container gap={0.2}>
                            {data.sections?.length === 0 &&
                                <>
                                    <CustomTypography text={t('table.noData')} />
                                    {hasRoleAdminOrDirectorOrManager(data.userAuth) &&
                                        <a className="link" style={linkStyle()} href={`/${getLanguageFromURL()}/section`}>{t('actions.clickHere')}</a>
                                    }
                                </>
                            }

                            {data.sections?.map((outletSections) => {
                                let selectedOutlet = data?.selectedSections?.find((selectedOutlet) => selectedOutlet.outletID === outletSections.outlet.id);

                                return (
                                    <>
                                        <Grid item xs={9} md={4} key={outletSections.outlet.id}>
                                            <>
                                                <InputLabel required={false} htmlFor={outletSections.outlet.name}>{outletSections.outlet.name}</InputLabel>
                                                <CustomSelectObjectWithChipAndIcon
                                                    id={`outletSections-${outletSections.outlet.name}`}
                                                    required={true}
                                                    value={selectedOutlet?.selectedSections}
                                                    options={outletSections.sections}
                                                    onChange={(event) => addOutletSection(event, outletSections.outlet.id)}
                                                    onDelete={(event, id) => removeOutletSection(event, id, outletSections.outlet.id)}
                                                    sx={{ borderRadius: "30px" }}
                                                    field="name"
                                                />
                                            </>
                                        </Grid>
                                        <Grid item xs={2} md={1} sx={{ marginTop: '25px' }}>
                                            <CustomIconButton
                                                id="btn-all-outlet-sections"
                                                variant="outlined"
                                                keyValue="all"
                                                onClick={() => addAllOutletSections(outletSections.outlet.id, outletSections.sections)}
                                                label={t('actions.all')}
                                                sx={mySectionsButtonAll()} />
                                        </Grid>
                                    </>
                                );
                            })}
                        </Grid>
                    </form>

                    <Fab color="primary"
                        aria-label="add"
                        sx={fabStyle()}
                        onClick={editMySections}
                    >
                        <SaveIcon />
                    </Fab>
                </CustomGrid>

                {alertBox.isOpen &&
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={3000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        handleSnackbarOpen={handleSnackbarState} />
                }
            </CustomGrid>
        );
    }
}

export default MySectionComponent;