import { request } from 'constants/alias';
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from 'utils/functions';
import updateFieldIfValid from 'utils/functions/updateFieldIfValid';

/**
 * The rest request which attempts to get the email campaigns list from the database.
 * @param {*} search The filters for search.
 * 
 * @returns an object with an attribute containing the email campaigns list
 */
export async function fetchAll(search) {
    let data = {};
    updateFieldIfValid(data, "organizationID", search.organizationID);
    updateFieldIfValid(data, "outletID", search.outletID);
    updateFieldIfValid(data, "body", search.body);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}marketing/campaign/email`, data));
    else return await request.get(includeParamsToUrl(`${API}marketing/campaign/email`));
}

/**
 * The rest request which return required information to be presented on add new email campaign page.
 * 
 * @param {*} organizationID The id of email campaign to be fetched
 * @returns an object with an attribute containing the requested information to be presented on the email campaign page
 */
export async function fetchNew(organizationID) {
    let data = {};
    updateFieldIfValid(data, "organizationID", organizationID);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}marketing/campaign/email/add`, data));
    else return await request.get(includeParamsToUrl(`${API}marketing/campaign/email/add`));
}

/**
 * The rest request which attempts to get an existing email campaign form information.
 * 
 * @param {*} organizationID The id of the email campaign form form organization id
 * @param {*} emailCampaignID The id of the email campaign form to be fetched
 * 
 * @returns an object with an attribute containing the current infromation of the email campaign
 */
export async function fetchOne(organizationID, outletID, emailCampaignID) {
    let data = {};
    updateFieldIfValid(data, "organizationID", organizationID);
    updateFieldIfValid(data, "outletID", outletID);
    
    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}marketing/campaign/email/${emailCampaignID}`, data));
    else return await request.get(includeParamsToUrl(`${API}marketing/campaign/email/${emailCampaignID}`));
}

/**
 * The rest request which attempts to create a new email campaign.
 * @param {*} data A JSON object which contains new email campaign infromation
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function create(data) {
    return await request.post(`${API}marketing/campaign/email`, data);
}

/**
 * The rest request which attempts to update an existing email campaign.
 * @param {*} data A JSON object which contains the updated infromation of the email campaign
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function update(data) {
    return await request.put(`${API}marketing/campaign/email`, data);
}

/**
 * The rest request to delete a specific email campaign.
 * @param {*} id The id of the email campaign to be deleted
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function deleteById(id) {
    return await request.delete(`${API}marketing/campaign/email/${id}`);
}

const emailCampaignApi = {
    fetchAll,
    fetchNew,
    fetchOne,
    create,
    update,
    deleteById,
}

export default emailCampaignApi;