import {
    Article as ArticleIcon,
    Email,
    FeaturedPlayList as FeaturedPlayListIcon,
    FormatListNumbered,
    GroupWork,
    List as ListIcon,
    SmartScreen as SmartScreenIcon,
    Sms,
    TableRestaurant as TableRestaurantIcon
} from '@mui/icons-material';

import { WavingHandIcon, CampaignIcon, NotesIcon } from 'assets/mui/MuiIcons';
import {
    AnalyticsIcon,
    BasketIcon,
    ChatIcon,
    DiscountIcon,
    DisplayIcon,
    HelpIcon,
    InventoryIcon,
    KitchenDisplayIcon,
    LoyaltyIcon,
    OrganizationIcon,
    OutletIcon,
    PickupDisplayIcon,
    PosIcon,
    PrinterIcon,
    ReviewIcon,
    SectionsIcon,
    SubscriptionIcon,
    SubscriptionPlanIcon,
    UserIcon
} from 'assets/svg/SvgIcons';
import { getRoleOptions } from 'constants/roleOptions';
import { getLanguageFromURL } from 'utils/language';
import { getMenuSvgIconProperties } from './fieldProperties';

export const getMenuItems = () => {
    return [
        {
            label: "orders",
            icon: <BasketIcon {...getMenuSvgIconProperties()} />,
            link: "/" + getLanguageFromURL() + "/order",
            roles: ["DIRECTOR", "MANAGER"],
            hasDivider: "false"
        },
        {
            label: "stats",
            icon: <AnalyticsIcon {...getMenuSvgIconProperties()} />,
            link: "/" + getLanguageFromURL() + "/stats",
            roles: ["SERVER"],
            hasDivider: "false"
        },
        {
            label: "analytics",
            icon: <AnalyticsIcon {...getMenuSvgIconProperties()} />,
            link: "/" + getLanguageFromURL() + "/analytics",
            roles: ["DIRECTOR", "MANAGER"],
            hasDivider: "false"
        },
        // {
        //     label: "feedback",
        //     icon: <ReviewIcon {...getMenuSvgIconProperties()} />,
        //     link: "/" + getLanguageFromURL() + "/feedback",
        //     roles: ["DIRECTOR", "MANAGER"],
        //     hasDivider: "false"
        // },
        {
            label: "rating",
            icon: <ReviewIcon {...getMenuSvgIconProperties()} />,
            link: "/" + getLanguageFromURL() + "/rating",
            roles: ["RATING_VIEWER"],
            hasDivider: "false"
        },
        {
            label: "requests",
            icon: <WavingHandIcon />,
            link: "/" + getLanguageFromURL() + "/request",
            roles: ["DIRECTOR", "MANAGER", "SERVER", "BUSSER"],
            hasDivider: "false"
        },
        {
            label: "organizations",
            icon: <OrganizationIcon {...getMenuSvgIconProperties()} />,
            link: "/" + getLanguageFromURL() + "/organization",
            roles: ["ADMIN"],
            hasDivider: "false"
        },
        {
            label: "mysections",
            icon: <SectionsIcon {...getMenuSvgIconProperties()} />,
            link: "/" + getLanguageFromURL() + "/mysections",
            roles: ["SERVER"],
            hasDivider: "false"
        },
        {
            label: "loyalty",
            icon: <LoyaltyIcon {...getMenuSvgIconProperties()} />,
            link: "/" + getLanguageFromURL() + "/loyalty/category",
            roles: ["DIRECTOR"],
            hasDivider: "false"
        },
        {
            label: "outlets",
            icon: <OutletIcon {...getMenuSvgIconProperties()} />,
            roles: ["DIRECTOR", "MANAGER"],
            children: [
                {
                    label: "organization",
                    icon: <OrganizationIcon {...getMenuSvgIconProperties()} />,
                    link: "/" + getLanguageFromURL() + "/organization",
                    roles: ["DIRECTOR"],
                    hasDivider: "false"
                },
                {
                    label: "outlets",
                    icon: <OutletIcon {...getMenuSvgIconProperties()} />,
                    link: "/" + getLanguageFromURL() + "/outlet",
                    roles: ["DIRECTOR", "MANAGER"]
                },
                {
                    label: "equipment",
                    icon: <TableRestaurantIcon />,
                    link: "/" + getLanguageFromURL() + "/equipment",
                    roles: ["DIRECTOR", "MANAGER"],
                    hasDivider: "false"
                },
                {
                    label: "section",
                    icon: <SectionsIcon {...getMenuSvgIconProperties()} />,
                    link: "/" + getLanguageFromURL() + "/section",
                    roles: ["DIRECTOR", "MANAGER"],
                    hasDivider: "false"
                },
                {
                    label: "printers",
                    icon: <PrinterIcon {...getMenuSvgIconProperties()} />,
                    link: "/" + getLanguageFromURL() + "/printer",
                    roles: ["DIRECTOR", "MANAGER"]
                },
                {
                    label: "pos",
                    icon: <PosIcon {...getMenuSvgIconProperties()} />,
                    link: "/" + getLanguageFromURL() + "/pos",
                    roles: ["DIRECTOR", "MANAGER"]
                },
            ],
            hasDivider: "false"
        },
        {
            label: "menus",
            icon: <ArticleIcon />,
            roles: ["DIRECTOR", "MANAGER"],
            children: [
                {
                    label: "menus",
                    icon: <ArticleIcon />,
                    link: "/" + getLanguageFromURL() + "/menu",
                    roles: ["DIRECTOR", "MANAGER"],
                    hasDivider: "false"
                },
                {
                    label: "itemsAndModifiers",
                    icon: <FeaturedPlayListIcon />,
                    link: "/" + getLanguageFromURL() + "/item-or-modifier",
                    roles: ["DIRECTOR"],
                    hasDivider: "false"
                },
                {
                    label: "modifierLists",
                    icon: <ListIcon />,
                    link: "/" + getLanguageFromURL() + "/modifier-list",
                    roles: ["DIRECTOR"],
                    hasDivider: "false"
                },
                {
                    label: "offers",
                    icon: <DiscountIcon {...getMenuSvgIconProperties()} />,
                    link: "/" + getLanguageFromURL() + "/offer",
                    roles: ["DIRECTOR", "MANAGER"],
                    hasDivider: "false"
                },
                {
                    label: "inventory",
                    icon: <InventoryIcon {...getMenuSvgIconProperties()} />,
                    link: "/" + getLanguageFromURL() + "/inventory",
                    roles: ["DIRECTOR", "MANAGER"],
                    hasDivider: "false"
                },
            ],
            hasDivider: "false"
        },
        {
            label: "marketing",
            icon: <CampaignIcon />,
            roles: ["ADMIN", "MESSAGING"],
            children: [
                {
                    label: "emailTemplates",
                    icon: <NotesIcon />,
                    link: "/" + getLanguageFromURL() + "/marketing/email/template",
                    roles: ["ADMIN", "MESSAGING"],
                    hasDivider: "false"
                },
                {
                    label: "recipients",
                    icon: <UserIcon {...getMenuSvgIconProperties()} />,
                    link: "/" + getLanguageFromURL() + "/marketing/recipient",
                    roles: ["ADMIN", "MESSAGING"],
                    hasDivider: "false"
                },
                {
                    label: "groups",
                    icon: <GroupWork />,
                    link: "/" + getLanguageFromURL() + "/marketing/recipient/group",
                    roles: ["ADMIN", "MESSAGING"],
                    hasDivider: "false"
                },
                {
                    label: "campaign.sms",
                    icon: <Sms />,
                    link: "/" + getLanguageFromURL() + "/marketing/campaign/sms",
                    roles: ["ADMIN", "MESSAGING"],
                    hasDivider: "false"
                },
                {
                    label: "campaign.email",
                    icon: <Email />,
                    link: "/" + getLanguageFromURL() + "/marketing/campaign/email",
                    roles: ["ADMIN", "MESSAGING"],
                    hasDivider: "false"
                },
            ],
            hasDivider: "false",
        },
        {
            label: "menus",
            icon: <ArticleIcon />,
            link: "/" + getLanguageFromURL() + "/menu",
            roles: ["ADMIN_MENU"],
            hasDivider: "false"
        },
        {
            label: "itemsAndModifiers",
            icon: <FeaturedPlayListIcon />,
            link: "/" + getLanguageFromURL() + "/item-or-modifier",
            roles: ["ADMIN_MENU"],
            hasDivider: "false"
        },
        {
            label: "modifierLists",
            icon: <ListIcon />,
            link: "/" + getLanguageFromURL() + "/modifier-list",
            roles: ["ADMIN_MENU"],
            hasDivider: "false"
        },
        {
            label: "offers",
            icon: <DiscountIcon {...getMenuSvgIconProperties()} />,
            link: "/" + getLanguageFromURL() + "/offer",
            roles: ["ADMIN_MENU"],
            hasDivider: "false"
        },
        {
            label: "inventory",
            icon: <InventoryIcon {...getMenuSvgIconProperties()} />,
            link: "/" + getLanguageFromURL() + "/inventory",
            roles: ["ADMIN_MENU"],
            hasDivider: "false"
        },
        {
            label: "displays",
            icon: <DisplayIcon {...getMenuSvgIconProperties()} />,
            roles: ["DIRECTOR", "MANAGER", "SERVER", "RUNNER", "BUSSER"],
            children: [
                {
                    label: "pda",
                    icon: <SmartScreenIcon />,
                    link: "/" + getLanguageFromURL() + "/pda",
                    roles: ["DIRECTOR", "MANAGER", "SERVER", "RUNNER", "BUSSER", "KITCHEN"],
                },
                {
                    label: "kitchenDisplay",
                    icon: <KitchenDisplayIcon {...getMenuSvgIconProperties()} />,
                    link: "/" + getLanguageFromURL() + "/display/kitchen",
                    roles: ["DIRECTOR", "MANAGER", "KITCHEN"],
                },
                {
                    label: "pickUpOrderDisplay",
                    icon: <PickupDisplayIcon {...getMenuSvgIconProperties()} />,
                    link: "/" + getLanguageFromURL() + "/display/pickup",
                    roles: ["DIRECTOR", "MANAGER"],
                },
                {
                    label: "waitlist",
                    icon: <FormatListNumbered {...getMenuSvgIconProperties()} />,
                    link: "/" + getLanguageFromURL() + "/waitlist",
                    roles: ["ADMIN", "ADMIN_ORG", "FLOOR_SUPERVISOR"],
                },
            ],
            hasDivider: "false"
        },
        {
            label: "users",
            icon: <UserIcon {...getMenuSvgIconProperties()} />,
            roles: ["ADMIN", "DIRECTOR", "MANAGER"],
            children: [
                {
                    label: "users",
                    link: "/" + getLanguageFromURL() + "/user",
                    roles: ["ADMIN"]
                },
                {
                    label: "employees",
                    link: "/" + getLanguageFromURL() + "/employee",
                    roles: ["ADMIN", "DIRECTOR", "MANAGER"]
                },
                {
                    label: "customers",
                    link: "/" + getLanguageFromURL() + "/customer",
                    roles: ["ADMIN", "DIRECTOR", "MANAGER"]
                }
            ],
            hasDivider: "false"
        },
        {
            label: "subscription",
            icon: <SubscriptionIcon {...getMenuSvgIconProperties()} />,
            roles: ["DIRECTOR"],
            link: "/" + getLanguageFromURL() + "/subscription",
            hasDivider: "false"
        },
        {
            label: "subscriptions",
            icon: <SubscriptionIcon {...getMenuSvgIconProperties()} />,
            roles: ["ADMIN"],
            children: [
                {
                    label: "subscriptionPlans",
                    icon: <SubscriptionPlanIcon {...getMenuSvgIconProperties()} />,
                    link: "/" + getLanguageFromURL() + "/subscription-plan",
                    roles: ["ADMIN"]
                },
                {
                    label: "subscriptions",
                    icon: <SubscriptionIcon {...getMenuSvgIconProperties()} />,
                    link: "/" + getLanguageFromURL() + "/subscription",
                    roles: ["ADMIN"]
                }
            ],
            hasDivider: "false"
        },
        {
            label: "chat",
            icon: <ChatIcon {...getMenuSvgIconProperties()} />,
            link: "/" + getLanguageFromURL() + "/chat",
            // roles: getRoleOptions(),
            roles: ["ADMIN"],
            hasDivider: "true"
        },
        {
            label: "help",
            icon: <HelpIcon {...getMenuSvgIconProperties()} />,
            link: "/" + getLanguageFromURL() + "/help",
            roles: getRoleOptions(),
            hasDivider: "false"
        }
    ];
}