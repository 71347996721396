const EL = {
    ρegister: "Εγγραφή",
    registerSuccess: "Έχετε εγγραφεί με επιτυχία.",
    signIn: "Είσοδος",
    signUp: "Εγγραφή",
    login: "Σύνδεση",
    logout: "Αποσύνδεση",
    signUpHere: "Εγγραφείτε εδώ!",
    forgotPassword: "Ξεχάσατε τον κωδικό;",
    noAccount: "Δεν έχετε λογαριασμό;",
    haveAccount: "Έχετε ήδη λογαριασμό;",
    backToSignIn: "Πίσω στην Είσοδο",
    username: "Όνομα χρήστη",
    usernameRequired: "Το όνομα χρήστη απαιτείται",
    usernameRule:
        "Το όνομα χρήστη πρέπει να αποτελείται από τουλάχιστον 4 χαρακτήρες και να περιέχει μόνο γράμματα, αριθμούς, τον χαρακτήρα '_' είναι προαιρετικός",
    password: "Κωδικός",
    aboutLabel: "Σχετικά",
    privacyPolicyLabel: "Πολιτική Απορρήτου",
    termsOfUseLabel: "Όροι Χρήσης",
    aboutURLPath: "https://usee.gr",
    privacyPolicyURLPath: "/πολιτική-απορρήτου",
    termsOfUseURLPath: "/όροι-και-προϋποθέσεις",
    signInURLPath: "/en/είσοδος",
    forgotPasswordURLPath: "/en/ξεχάσατε-κωδικό",
    errorPages: {
        pageNotFound: "Η σελίδα που ψάχνετε δεν βρέθηκε!",
        accessIsForbidden: "Δεν σας επιτρέπεται η πρόσβαση σε αυτήν τη σελίδα!",
        authorizationFailed: "Δεν έχετε εξουσιοδότηση να αποκτήσετε πρόσβαση σε αυτήν τη σελίδα!",
        somethingWentWrong:
            "Ωχ! Κάτι πήγε στραβά εδώ. Παρακαλώ προσπαθήστε ξανά σε πέντε λεπτά.",
        // "Ωχ! Κάτι πήγε στραβά εδώ. Παρακαλώ επικοινωνήστε με το διαχειριστή του συστήματος.",
    },
    error: {
        username: "Το όνομα χρήστη χρησιμοποιείται ήδη για άλλο λογαριασμό",
        email: "Το email χρησιμοποιείται ήδη για άλλο λογαριασμό",
        phone: "Ο αριθμός τηλεφώνου χρησιμοποιείται ήδη για άλλο λογαριασμό",
        valueRequired: "Το πεδίο είναι υποχρεωτικό",
        defaultOutletRequired: "Πρέπει να επιλέξετε το προεπιλεγμένο κατάστημα",
        vatNumberFormatError: "Ο ΦΠΑ δεν έχει σωστή μορφή",
    },
    auth: {
        username: "Όνομα χρήστη ή email",
        code: "Κωδικός OTP",
        enterVerfCode: "Θα λάβετε έναν κωδικό επιβεβαίωσης 8 ψηφίων στο email σας.",
        verifyCode: "Επαλήθευση ονόματος χρήστη ή email",
        codeIsSentTo: "Ο κωδικός αποστάλθηκε σε",
        didNotReceive: "Δεν λάβατε κωδικό;",
        newPassword: "Νέος κωδικός",
        resendNewCode: "Αποστολή νέου κωδικού",
        resetPassword: "Επαναφορά κωδικού πρόσβασης",
        newPasswordLength: "Ο κωδικός πρέπει να έχει τουλάχιστον 8 χαρακτήρες.",
        passwordsUnMatch: "Ο νέος κωδικός και ο κωδικός επιβεβαίωσης δεν ταιριάζουν.",
    },
    button: {
        sendCode: "Αποστολή κωδικού",
        next: "Επόμενο",
        resetPassword: "Επαναφορά κωδικού",
    },
    actions: {
        label: "Ενέργειες",
        back: "Πίσω",
        all: "Όλα",
        add: "Προσθήκη",
        cancel: "Ακύρωση",
        clearAll: "Εκκαθάριση Όλων",
        create: "Δημιουργία",
        clickHere: "εδώ",
        delete: "Διαγραφή",
        remove: "Αφαίρεση",
        edit: "Επεξεργασία",
        new: "Νέο",
        reload: "Ανανέωση",
        newItem: "Νέο",
        overview: "Επισκόπηση",
        save: "Αποθήκευση",
        saveAndCreateNew: "Αποθήκευση και δημιουργία νέου",
        view: "Προβολή",
        drag: "Σύρετε για να αλλάξετε τη σειρά.",
        duplicate: "Αντιγραφή",
        tableCreateNewLink: "Δημιουργία νέου ",
        tableCreateNewLinkBulk: "Δημιουργία νέων ",
        selectOption: "Επιλέξτε μια επιλογή",
        sync: "Συγχρονισμός",
        search: "Αναζήτηση",
        searchFor: "Αναζήτηση για",
        upload: "Μεταφόρτωση",
        download: "Λήψη",
        pay: "Πληρωμή",
        hide: "Απόκρυψη",
        undo: "Αναίρεση",
        cms: "CMS",
        for: " για ",
        powerOff: "Απενεργοποίηση",
        powerOn: "Ενεργοποίηση",
        send: "Αποστολή",
        update: "Ενημέρωση",
        export: "Εξαγωγή",
        import: "Εισαγωγή",
        copy: "Αντιγραφή",
        uploadBtn: "Ανέβασμα αρχείου",
        testPrint: "Δοκιμαστική εκτύπωση",
        exportQR: {
            titleEq: "Εξαγωγή QRcode για",
            title: "Εξαγωγή QRcode",
            titleEqs: "Εξαγωγή QRcodes",
            selectEquipments: "Επιλέξτε τα εξαρτήματα που θέλετε να εξάγετε τα QRcodes τους.",
            selectTemplateEq: "Επιλέξτε το πρότυπο που θέλετε να διαμορφώσετε το QRcode για τον εξοπλισμό: ",
            selectTemplate: "Επιλέξτε το πρότυπο που θέλετε να διαμορφώσετε τα QRcodes .",
            selectTemplateEmpty: "Δεν υπάρχει πρότυπο qrcode. Κάντε κλικ για να προσθέσετε ένα.",
            viewMultiplePagePdfPromt: "Κάνοντας κλικ στο κουμπί `Δημιουργία PDF`, κάντε κλικ σε ένα από τα πρότυπα qrcode για να δείτε το παραγόμενο pdf.",
            success: "Επιτυχής εξαγωγή",
            actionButton: "Δημιουργία PDF"
        },
        error: {
            addFailed: 'Συμπληρώστε όλες τις λεπτομέρειες και κάντε κλικ στο κουμπί "προσθήκη"',
        },
        deleteModal: {
            title: "Επιβεβαίωση Διαγραφής",
            message: "Διαγραφή",
            notice:
                "Η ενέργεια αυτή δεν μπορεί να αναιρεθεί και δεν θα είστε σε θέση να ανακτήσετε καμία δεδομένη. ",
            confirm: "Είστε βέβαιος ότι θέλετε να διαγράψετε ",
            confirmClear: "Είστε βέβαιος ότι θέλετε να διαγράψετε όλα;",
            success: "Διαγράφηκε με επιτυχία",
            from: "από"
        },
        removeModal: {
            title: "Επιβεβαίωση Αφαίρεσης",
            message: "Αφαίρεση",
            notice:
                "Η ενέργεια αυτή δεν μπορεί να αναιρεθεί και δεν θα είστε σε θέση να ανακτήσετε καμία δεδομένη. ",
            confirm: "Είστε βέβαιος ότι θέλετε να αφαιρέσετε ",
            confirmClear: "Είστε βέβαιος ότι θέλετε να διαγράψετε όλα;",
            success: "Αφαιρέθηκε με επιτυχία",
            from: "από"
        },
        translationModal: {
            title: "Διαμόρφωση Μεταφράσεων",
        },
        cancel: "Ακύρωση",
        submit: "Υποβολή",
        saveSuccessfully: "αποθηκεύτηκε με επιτυχία",
        createSuccessfully: "δημιουργήθηκε με επιτυχία",
        ok: "Ok"
    },
    table: {
        noData: "Δεν υπάρχουν διαθέσιμα δεδομένα.",
        valueReuired: "Το πεδίο είναι υποχρεωτικό.",
        rowsPerPage: "Γραμμές ανά σελίδα:",
        denseTable: "Πυκνός πίνακας",
        labels: {
            body: {
                noMatch: 'Λυπούμαστε, δεν βρέθηκαν αντιστοιχίσεις',
                toolTip: 'Ταξινόμηση',
            },
            pagination: {
                next: 'Επόμενη Σελίδα',
                previous: 'Προηγούμενη Σελίδα',
                rowsPerPage: 'Γραμμές ανά σελίδα:',
                displayRows: 'από',
                jumpToPage: 'Μετάβαση στη Σελίδα:',
            },
            toolbar: {
                search: 'Αναζήτηση',
                downloadCsv: 'Λήψη CSV',
                print: 'Εκτύπωση',
                viewColumns: 'Προβολή Στηλών',
                filterTable: 'Φιλτράρισμα Πίνακα',
            },
            filter: {
                all: 'Όλα',
                title: 'ΦΙΛΤΡΑ',
                reset: 'ΕΠΑΝΑΦΟΡΑ',
            },
            viewColumns: {
                title: 'Εμφάνιση Στηλών',
                titleAria: 'Εμφάνιση/Απόκρυψη Στηλών Πίνακα',
            },
            selectedRows: {
                text: 'επιλεγμένη(ες) γραμμή(ες)',
                delete: 'Διαγραφή',
                deleteAria: 'Διαγραφή Επιλεγμένων Γραμμών',
            },
        }
    },
    label: {
        all: "Όλα",
        profile: "Προφίλ",
        id: "Αναγνωριστικό",
        name: "Όνομα",
        displayName: "Εμφανιζόμενο Όνομα",
        username: "Όνομα Χρήστη",
        password: "Κωδικός",
        firstName: "Όνομα",
        lastName: "Επώνυμο",
        fatherName: "Πατρώνυμο",
        email: "Email",
        emailConfigurations: "Ρυθμίσεις Email",
        emails: "Emails",
        address: "Διεύθυνση",
        city: "Πόλη",
        postalCode: "Ταχυδρομικός Κώδικας",
        state: "Πολιτεία",
        country: "Χώρα",
        phone: "Κινητό",
        telephone: "Τηλέφωνο",
        roles: "Ρόλοι",
        dateCreated: "Δημιουργήθηκε στις",
        lastActivity: "Τελευταία Δραστηριότητα",
        error: "Σφάλμα",
        currentPassword: "Τρέχων Κωδικός",
        newPassword: "Νέος Κωδικός",
        verifyPassword: "Επαλήθευση Νέου Κωδικού",
        description: "Περιγραφή",
        type: "Τύπος",
        currency: "Νόμισμα",
        organizationID: "ID Οργανισμού",
        planID: "ID Πλάνου",
        dueDate: "Ημερομηνία λήξης",
        status: "Κατάσταση",
        service: "Υπηρεσία",
        services: "Υπηρεσίες",
        details: "Λεπτομέρειες",
        detail: "Λεπτομέρεια",
        price: "Τιμή",
        reason: "Λόγος",
        namePlaceholder: "Προσθέστε τουλάχιστον 4 χαρακτήρες",
        labelPlaceholder: " ",
        key: "Κλειδί",
        value: "Αξία",
        isAvailable: "Διαθέσιμο",
        isEnabled: "Ενεργοποιημένο",
        isDisabled: "Απενεργοποιημένο",
        isActive: "Ενεργό",
        isNotActive: "Ανενεργό",
        notificationWebEnabled: "Ειδοποιήσεις περιηγητή",
        notificationEmailEnabled: "Ειδοποιήσεις email",
        createPassword: "Δημιουργία Κωδικού",
        changePassword: "Αλλαγή Κωδικού",
        jobDescription: "Περιγραφή Εργασίας",
        invocationAddress: "Διεύθυνση Πρόσκλησης",
        configureTranslations: "Διαμόρφωση Μεταφράσεων",
        outletId: "ID Καταστήματος",
        outletIds: "ID Καταστημάτων",
        outletName: "Όνομα Καταστήματος",
        section: "Τμήμα",
        floor: "Όροφος",
        label: "Ετικέτα",
        tags: "Ετικέτες",
        na: "Μη Διαθέσιμο",
        subscriptionExpirationDate: "Ημερομηνία λήξης ενεργής συνδρομής",
        smsCounter: "Μετρητής SMS",
        emailCounter: "Μετρητής Email",
        orderCounter: "Μετρητής Παραγγελιών",
        smss: "SMSs",
        emails: "Emails",
        ticketCounter: "Εισιτήρια",
        receiptTicketCounter: "Αποδείξεις",
        vatNumber: "Αριθμός ΦΠΑ",
        socialProfile: "Κοινωνικό Προφίλ",
        action: "Ενέργεια",
        actions: "Ενέργειες",
        notificationActions: "Ενέργειες Ειδοποίησης",
        chipListEditHelperText: "Κάντε διπλό κλικ για επεξεργασία της τιμής",
        subscriptionPlan: "Σχέδιο Συνδρομής",
        single: "Μονός",
        multiple: "Πολλαπλός",
        duration: "Διάρκεια",
        days: " ημέρες απομένουν",
        csv: "CSV",
        json: "JSON",
        downloadCsvTemplate: "Κατέβασμα CSV προτύπου",
        url: "Url"
    },
    page: {
        users: "Χρήστες",
        employees: "Υπάλληλοι",
    },
    form: {
        requiredField: "Το πεδίο είναι υποχρεωτικό",
    },
    search: {
        all: "Όλα",
    },
    organization: {
        logo: {
            type: {
                SQUARE: "SQUARE",
                RECTANGULAR: "RECTANGULAR"
            }
        }
    },
    menu: {
        dashboard: "Πίνακας ελέγχου",
        stats: "Στατιστικά",
        analytics: "Στατιστικά",
        rating: "Αξιολογήσεις",
        organizations: "Οργανισμοί",
        organization: "Οργανισμός",
        outlets: "Καταστήματα",
        subscriptions: "Συνδρομές",
        subscription: "Συνδρομή",
        subscriptionPlans: "Σχέδια",
        menus: "Μενού",
        itemsAndModifiers: "Προϊόντα & Τροποποιητές",
        offers: "Προσφορές",
        modifierLists: "Λίστες Τροποποιητών",
        bookings: "Κρατήσεις",
        feedback: "Φόρμες Αξιολόγησης",
        loyalty: "Ρυθμίσεις Πιστότητας",
        kitchenDisplay: "Οθόνη Κουζίνας",
        customers: "Πελάτες",
        employees: "Υπάλληλοι",
        users: "Χρήστες",
        pickUpOrderDisplay: "Οθόνη Παραλαβής Παραγγελίας",
        settings: "Ρυθμίσεις",
        orders: "Παραγγελίες",
        equipment: "Εξοπλισμός",
        chat: "Υποστήριξη Συνομιλίας",
        help: "Βοήθεια",
        mysections: "Τα τμήματα μου",
        printers: "Εκτυπωτές",
        pos: "Pos",
        section: "Τμήματα",
        requests: "Αιτήματα",
        inventory: "Αποθήκη",
        pda: "PDA",
        displays: "Οθόνες",
        campaign: "Καμπάνιες",
        autoSave: "Αυτόματη αποθήκευση αλλαγών",
        active: "Ενεργό",
        deactive: "Ανενεργό",
        marketing: "Marketing",
        emailTemplates: "Πρότυαπα Email",
        recipients: "Παραλήπτες",
        groups: "Ομάδες",
        waitlist: "Λίστα Αναμονής",
        campaign: {
            sms: "Καμπάνια Μηνυμάτων",
            email: "Καμπάνια Email"
        }
    },
    org: {
        title: "Οργανισμοί",
        pageTitle: "Οργανισμός",
        subtitle: "Προβολή Οργανισμών",
        emptyMessage: "Δεν υπάρχουν διαθέσιμοι οργανισμοί.",
        addOrganizationLabel: "Προσθήκη Οργανισμού",
        socialProfilesEmpty: "Δεν έχουν προστεθεί κοινωνικά προφίλ",
        emailsEmpty: "Δεν έχουν προστεθεί emails",
        tags: {
            ENABLE_RE_USE_OF_MENU: "Ενεργοποίηση επαναχρήσης του μενού",
            ENABLE_RE_USE_OF_ITEM: "Ενεργοποίηση επαναχρήσης του προϊόντος",
            ENABLE_SHARE_VAT_NUMBER_BETWEEN_OUTLETS: "Ενεργοποίηση κοινής χρήσης αριθμού ΦΠΑ μεταξύ των καταστημάτων"
        },
        monthsPassedWithoutOrderToMarkAsReturning: "Μήνες που έχουν περάσει χωρίς παραγγελία για να επισημανθεί ως επιστρεφόμενος πελάτης",
        daysPassedFromLastOrderToMarkAsRecent: "Ημέρες που έχουν περάσει από την τελευταία παραγγελία για να επισημανθεί ως πρόσφατη",
        monthsPassedWithoutOrderToMarkAsReturningAfterLabel: "μήνες",
        daysPassedFromLastOrderToMarkAsRecentAfterLabel: "ημέρες",
        tabs: {
            details: "Λεπτομέρειες",
            socialProfiles: {
                label: "Κοινωνικά Προφίλ",
                info: "Εισάγετε τα πλήρη url των κοινωνικών σας προφίλ εδώ"
            },
            integrations: {
                label: "Ενσωματώσεις"
            },
            settings: {
                label: "Ρυθμίσεις"
            }
        },
        vat: {
            label: "VAT"
        }
    },
    outlet: {
        title: "Καταστήματα",
        pageTitle: "Κατάστημα",
        subtitle: "Διαχείριση Καταστημάτων",
        emptyMessage: "Δεν υπάρχουν διαθέσιμα καταστήματα.",
        addOutletLabel: "Προσθήκη Καταστήματος",
        extraNote: "Επιπλέον Σημείωση",
        photos: "Φωτογραφίες",
        url: "Διεύθυνση URL",
        logo: "Λογότυπο",
        email: "Ηλεκτρονικό Ταχυδρομείο",
        inheritFromOrganization: "Ίδιο με τον οργανισμό",
        thirdPartyPosId: "ID τρίτου συστήματος POS",
        hasCustomTicket: "Προσαρμοσμένο Εισιτήριο",
        printNodeIds: "IDs Κόμβου Εκτύπωσης",
        printNodeId: "ID Κόμβου Εκτύπωσης",
        equipmentIds: "IDs Εξοπλισμού",
        employeeIds: "IDs Υπαλλήλων",
        emptyPrintNodeIds: "Το κατάστημα δεν έχει κόμβους εκτύπωσης που είναι συνδεδεμένοι",
        labelBegginingText: "Κείμενο Έναρξης Ετικέτας",
        startNumber: "Από",
        endNumber: "Μέχρι",
        googleReview: {
            locationIDInfo: {
                "step1": "1. Συνδεθείτε στο Google My Business",
                "step1Details": "Επισκεφθείτε τον ιστότοπο του Google My Business και συνδεθείτε χρησιμοποιώντας τα στοιχεία σύνδεσής σας στο λογαριασμό Google.",
                "step2": "2. Πλοηγηθείτε στις Τοποθεσίες σας",
                "step2Details": "Αφού συνδεθείτε, κάντε κλικ στην καρτέλα 'Τοποθεσίες' στο αριστερό μέρος της οθόνης.",
                "step3": "3. Επιλέξτε την Τοποθεσία",
                "step3Details": "Κάντε κλικ στο όνομα ή το προφίλ της τοποθεσίας για την οποία θέλετε να βρείτε το αναγνωριστικό τοποθεσίας.",
                "step4": "4. Προβολή της διεύθυνσης URL",
                "step4Details": "Κοιτάξτε τη διεύθυνση URL στη γραμμή διεύθυνσης του προγράμματος περιήγησής σας. Το αναγνωριστικό τοποθεσίας είναι μέρος της διεύθυνσης URL.",
                "step5": "5. Αντιγραφή του αναγνωριστικού τοποθεσίας",
                "step5Details": "Απλά αντιγράψτε το αναγνωριστικό τοποθεσίας από τη διεύθυνση URL."
            }
        },
        tags: {
            allowReservations: "Επιτρέπει Κρατήσεις",
            disableReservations: "Απενεργοποίηση Κρατήσεων",
            allowComments: "Επιτρέπει Σχόλια",
            disableComments: "Απενεργοποίηση Σχολίων",
            allowReviews: "Επιτρέπει Κριτικές",
            disableReviews: "Απενεργοποίηση Κριτικών",
            hasAddress: "Έχει διεύθυνση",
            hasCart: "Έχει Καλάθι",
            hasTable: "Έχει Τραπέζι",
            hasUmbrella: "Έχει Ομπρέλα",
        },
        tabs: {
            details: "Λεπτομέρειες",
            socialProfiles: {
                label: "Κοινωνικά Προφίλ",
                info: "Εισαγάγετε το πλήρες URL του κοινωνικού σας προφίλ εδώ",
                copyFromOrg: "Αντιγραφή από τον οργανισμό"
            },
            ticketConfiguration: {
                label: "Διαμόρφωση Email",
                ORDER: "Παραγγελία",
                ACCOUNT: "Λογαριασμός",
                ANALYTIC: "Αναλυτικά",
                INVENTORY: "Αποθήκη",
                FEEDBACK: "Αξιολόγηση",
                REPORT: "Αναφορά",
                RATING_CUSTOMER: "Αξιολόγηση (Πελάτη)",
                startText: "Κείμενο Έναρξης",
                endText: "Κείμενο Λήξης",
                replicas: "Αντίγραφα",
                field: {
                    labels: "Πεδία",
                    ORDER_NUMBER: "Αριθμός Παραγγελίας",
                    OUTLET_NAME: "Όνομα Καταστήματος",
                    TIMESTAMP: "Χρονοσφραγίδα",
                    WAITER_OR_USER: "Σερβιτόρος ή Χρήστης",
                    CUSTOMER_NAME: "Όνομα Πελάτη",
                    EQUIPMENT_FLOOR: "Όροφος Εξοπλισμού",
                    EQUIPMENT_LABEL: "Ετικέτα Εξοπλισμού",
                    EQUIPMENT_SECTION: "Τμήμα Εξοπλισμού",
                    PAYMENT_METHOD: "Μέθοδος πληρωμής",
                    COMMENTS: "Σχόλια",
                    ITEM_COMMENTS: "Σχόλια Προϊόντος",
                    ITEM_NOTES: "Σημειώσεις Προϊόντος",
                    DELIVERY_TIME: "Χρόνος Παράδοσης",
                    CART_WITH_PRICES: "Καλάθι με τιμές",
                    CART_WITH_NO_PRICES: "Καλάθι χωρίς τιμές",
                    CART_TOTAL: "Σύνολο Καλαθιού",
                    QR_CODE: "Κωδικός QR",
                    BAR_CODE: "Γραμμικός Κωδικός",
                    CUSTOMER_NAME: "Όνομα Πελάτη",
                    SCORE: "Βαθμολογία",
                    EMPLOYEE_OR_USER: "Τύπος Χρήστη",
                    EMPLOYEE_NAME: "Όνομα Υπαλλήλου",
                    REMAINING_QUANTITY: "Υπολειπόμενη Ποσότητα",
                    REVIEW: "Κείμενο Αξιολόγησης"
                }
            },
            integrations: {
                label: "Ενσωματώσεις",
                vivaWalletConfiguration: {
                    label: "Viva Wallet",
                    appSourceCode: "App source code",
                    clientId: "Client ID",
                    // hashed like password --sensitive
                    clientSecret: "CLient secret",
                    merchantId: "Merchant ID",
                    apiKey: "API key"
                },
                vivaPosConfiguration: {
                    label: "Viva POS",
                    clientId: "Client ID",
                    clientSecret: "CLient secret",
                },
                googleReview: {
                    locationID: "locationId"
                },
                oxygenConfiguration: {
                    label: "Oxygen",
                    token: "Token"
                }
            }
        },
        settings: {
            label: "Ρυθμίσεις",
            outletStyle: "Κατάστημα",
            reviewsStyle: "Κριτικές",
            bookingsStyle: "Κρατήσεις",
            orderStyle: "Παραγγελία",
            bookings: "Κρατήσεις",
            orders: "Παραγγελίες",
            workingHours: "Ώρες Λειτουργίας",
            splashscreen: "Οθόνη Καλωσορίσματος",
            details: "Λεπτομέρειες",
            qrcode: "Κωδικοί QR",
            splashScreenConfiguration: {
                showLogo: "Εμφάνιση Λογοτύπου",
                showDescription: "Εμφάνιση Περιγραφής",
                description: "Περιγραφή",
                backgroundColor: "Χρώμα Φόντου",
                descriptionColor: "Χρώμα Περιγραφής",
                durationInSeconds: "Διάρκεια",
                durationInSecondsAfterText: "δευτερόλεπτα",
                enable: "Ενεργό"
            },
            hours: {
                label: "Ώρες Λειτουργίας",
                labelPlain: "Ώρες",
                settings: "Ρυθμίσεις",
                timezone: "Ζώνη Ώρας",
                timeFormat: {
                    label: "Μορφή Ώρας",
                    TWELVE_HOURS: "Μορφή 12 ωρών",
                    TWENTY_FOUR_HOUR: "Μορφή 24 ωρών",
                },
                indicator: {
                    label: "Δείκτες",
                    nowOpenMessage: "Μήνυμα Ανοικτό Τώρα",
                    nowCloseMessage: "Μήνυμα Κλειστό Τώρα",
                    includeTime: "Συμπερίληψη Ώρας",
                    includeTimeInfo: "Συμπερίληψη της τρέχουσας ώρας στην έξοδο",
                    includeDay: "Συμπερίληψη Ημέρας",
                    includeDayInfo: "Συμπερίληψη της τρέχουσας ημέρας στην έξοδο",
                    showOpeningClosingSoonWarning: "Προειδοποίηση Άνοιγμα/Κλείσιμο σύντομα",
                    showOpeningClosingSoonWarningInfo:
                        "Όταν πλησιάζει η ώρα ανοίγματος ή κλεισίματος, εμφάνισε διαφορετικό μήνυμα.",
                    openingSoonMessage: "Μήνυμα Άνοιγμα Σύντομα",
                    closingSoonMessage: "Μήνυμα Κλείσιμο Σύντομα",
                    openSoonWarning: "Προειδοποίηση 'Άνοιγμα Σύντομα'",
                    closeSoonWarning: "Προειδοποίηση 'Κλείσιμο Σύντομα'",
                    openSoonWarningStart: "Εμφάνιση προειδοποίησης 'άνοιγμα σύντομα'",
                    closeSoonWarningStart: "Εμφάνιση προειδοποίησης 'κλείσιμο σύντομα'",
                    minutesInAdvance: "λεπτά πριν.",
                    includeHolidays: "Συμπερίληψη Αργιών",
                    includeHolidaysInfo: "Προσθήκη αργιών στον πίνακα ωρών",
                    includeVacations: "Συμπερίληψη Διακοπών",
                    includeVacationsInfo: "Προσθήκη διακοπών στον πίνακα ωρών",
                    displayMode: {
                        label: "Λειτουργία Εμφάνισης",
                        NORMAL: "Κανονική",
                        CONSOLIDATED: "Συγκεντρωμένη",
                    },
                    output: {
                        label: "Έξοδος",
                        TABLE: "Πίνακας",
                        INLINE: "Ενσωματωμένος",
                    },
                },
            },
            showLogo: "Εμφάνιση Λογοτύπου",
            showCover: "Εμφάνιση Κάλυψης",
            showPhotos: "Εμφάνιση Φωτογραφιών",
            allowReviews: "Επιτρέπει Κριτικές",
            showReviewsAverageScore: "Εμφάνιση Μέσου Σκορ Κριτικών",
            showReviews: "Εμφάνιση Κριτικών",
            allowReservations: "Επιτρέπει Κρατήσεις",
            allowComments: "Επιτρέπει Σχόλια",
            enableCart: "Ενεργοποίηση Καλαθιού",
            enableOrder: "Ενεργοποίηση παραγγελιοληψίας",
            automaticallyProduceReceiptForCashPayments: "Αυτόματη Παραγωγή Αποδείξεων για Πληρωμές με Μετρητά",
            payment: {
                paymentMethods: "Μέθοδοι Πληρωμής",
                card: "Κάρτα",
                paypal: "Paypal",
                cash: "Μετρητά",
            },
            currency: {
                label: "Νόμισμα",
                EUR: "Ευρώ",
                USD: "USD",
            },
            tax: {
                numberPercentage: "Φόρος",
                "%": "%",
                type: "Τύπος Φόρου",
                INCLUSIVE: "Συμπεριλαμβανόμενος",
                EXCLUSIVE: "Αποκλειστικός",
            },
            invoice: {
                label: "Απόδειξη/Τιμολόγιο",
                series: "Series",
                number: "Number",
                invoiceDOY: "ΔΟΥ",
                invoiceBusiness: "Business"
            },
            request: {
                label: "Αίτηση",
                allowedActions: "Επιτρεπόμενες Ενέργειες",
                allowedRequestTypes: {
                    PAYMENT: "Πληρωμή",
                    MENU: "Παραγγελία",
                    CLEAN_TABLE: "Καθάρισμα Τραπεζιού",
                    ORDER: "Παραγγελία",
                    OTHER: "Άλλο"
                }
            },
            afterQrDispay: {
                label: "Μετά την Προβολή του Κωδικού QR",
                allowActionFromAuthenticatedUserOnly: "Επιτρέπει ενέργειες μόνο από εγγεγραμμένους χρήστες",
                allowOrdesFromAuthenticatedUsersOnly: "Επιτρέπει παραγγελίες μόνο από εγγεγραμμένους χρήστες",
                allowRequestsFromAuthenticatedUserOnly: "Επιτρέπει αιτήσεις μόνο από εγγεγραμμένους χρήστες",
                allowRatingsFromAuthenticatedUserOnly: "Επιτρέπει αξιολογήσεις μόνο από εγγεγραμμένους χρήστες",
                allowWaitlistFromAuthenticatedUserOnly: "Επιτρέπει προσθήκη στη λίστα αναμονής μόνο από εγγεγραμμένους χρήστες",
                afterScanLandOnMenuPage: "Μετά το σάρωμα να φορτώνει στην σελίδα του μενού",
                afterScanLandOnMenuPageButtonOptions: {
                    label: "Ενεργοποίηση κουμπιών μετά το σάρωμα",
                    MENU: "Παραγγελία",
                    CUSTOMER_REQUEST: "Κάλεσμα σερβιτόρου",
                    PAYMENT: "Πληρωμή",
                    WIFI: "Wifi",
                    RATING: "Αξιολόγηση",
                    WAITLIST: "Λίστα Αναμονής",
                    CUSTOM: "Προσαρμοσμένες Ενέργειες"
                }
            },
            ratings: {
                label: "Αξιολόγησεις",
                minRatingNumberToGoogle: "Ελάχιστη αξιολόγηγη για δημοσιευση στα Google Reviews"
            },
            wifi: {
                label: "Wifi",
                ssid: "SSID",
                password: "Κωδικός",
                enableQRcode: "Ενεργοποίηση QRcode",
                addWifi: "Προσθήκη Εγγραφής Wifi",
                editWifi: "Επεξεργάσια  Εγγραφής Wifi",
                enabled: "Ενεργό",
                locationName: "Όνομα Τοποθεσίας",
                encryption: {
                    label: "Κρυπτογράφηση",
                    nopass: "nopass",
                    WPA: "WPA/WPA2",
                    WEP: "WEP"
                }
            },
            extraOutletActionButtons: {
                label: "Επιπρόσθετα Κουμπιά Ενεργειών"
            },
            style: {
                backgroundColor: "Χρώμα Φόντου",
                headerFont: "Γραμματοσειρά Κεφαλίδας",
                headerColor: "Χρώμα Κεφαλίδας",
                headerFontSize: "Μέγεθος Γραμματοσειράς Κεφαλίδας",
                textFont: "Γραμματοσειρά Κειμένου",
                textColor: "Χρώμα Κειμένου",
                textFontSize: "Μέγεθος Γραμματοσειράς Κειμένου",
                textInfoFont: "Γραμματοσειρά Κειμένου Ενημέρωσης",
                textInfoColor: "Χρώμα Κειμένου Ενημέρωσης",
                textInfoFontSize: "Μέγεθος Γραμματοσειράς Κειμένου Ενημέρωσης",
                buttonFont: "Γραμματοσειρά Κουμπιού",
                buttonColor: "Χρώμα Κουμπιού",
                buttonBackgroundColor: "Χρώμα Φόντου Κουμπιού",
                buttonFontSize: "Μέγεθος Γραμματοσειράς Κουμπιού",
                backgroundColor: "Χρώμα Φόντου",
                chipTextColor: "Χρώμα Κειμένου Ετικέτας",
                chipPercentageTextColor: "Χρώμα Κειμένου Ετικέτας Ποσοστού",
                chip1plus1TextColor: "Χρώμα Κειμένου Ετικέτας 1+1",
                chips: "Ετικέτες",
                buttons: "Κουμπιά"
            },
            waitlist: {
                style: "Style",
                minutes: "λεπτά",
                waitingTime1Min: "Waiting Time 1st",
                waitingTime: "Αναμονή ",
                waitingTime1ChipTextColor: "Αναμονή <",
                waitingTime2ChipTextColor: "Αναμονή <",
                waitingTime3ChipTextColor: "Αναμονή >",
                estimatedTimeChipTextColor: "Εκτιμώμενος Χρόνος",
                waitlistSquareChipTextColor: "Εικονίδιο Λίστας Αναμονής",
                waitlistPositionSquareChipTextColor: "Θέση Λίστας Αναμονής",
                showWaitlistGroupInfo: "Show "
            }
        },
    },
    mysection: {
        label: "Οι Τομείς μου"
    },
    request: {
        title: "Αιτήσεις",
        pageTitle: "Αίτημα",
        subtitle: "",
        request: "Κείμενο Αιτήματος",
        btns: {
            list: "Λίστα",
            table: "Πίνακας",
            send: "Αποστολή",
        },
        employeeName: "Όνομα Υπαλλήλου",
        customerName: "Όνομα Πελάτη",
        status: "Κατάσταση",
        requestCharLimit: "χαρ",
        requestCharsLimit: "χαρακτήρες",
        requestPlaceholder: "Πληκτρολογήστε εδώ…",
        moreRequests: "Περισσότερα Αιτήματα",
        empty: "Φαίνεται ότι δεν υπάρχουν αιτήματα πελατών",
        type: {
            label: "Τύπος",
            name: "Όνομα",
            PAYMENT: "Πληρωμή",
            CLEAN_TABLE: "Καθαρισμός Τραπεζιού",
            ORDER: "Παραγγελία",
            OTHER: "Άλλο",
            CUSTOM: "Ειδικό"
        },
        status: {
            label: "Κατάσταση",
            DONE: "Ολοκληρώθηκε",
            PENDING: "Εκκρεμής"
        },
        actions: {
            done: "Έγινε",
            all: "Όλα",
            type: {
                payment: "Πληρωμή",
                clean_table: "Καθαρισμός Τραπεζιού",
                order: "Παραγγελία",
                other: "Άλλο",
                custom: "Ειδικό"
            },
            status: {
                done: "Ολοκληρώθηκε",
                pending: "Εκκρεμής"
            }
        },
    },
    user: {
        title: "Χρήστες",
        pageTitle: "Χρήστης",
        subtitle: "Διαχείριση Χρηστών",
        manageAccount: "Διαχείριση Λογαριασμού",
        personalDetails: "Προσωπικά Στοιχεία",
        notifications: "Ειδοποιήσεις",
        workingHours: "Ώρες Εργασίας",
        notificationMobileEnabled: "Ειδοποιήσεις Κινητού",
        notitification: {
            ORDER: "Παραγγελία",
            CANCEL_ORDER: "Ακύρωση Παραγγελίας",
            BOOKING: "Κράτηση",
            REQUEST: "Αίτημα Πελάτη",
            PAYMENT: "Πληρωμή",
            NEW_ORDER: "Νέα Παραγγελία",
            NEW_BOOK: "Νέα Κράτηση",
            BOOK_CANCELLATION: "Ακύρωση Κράτησης",
            ACCOUNT: "Λογαριασμός",
            ANALYTIC: "Αναλυτικά",
            INVENTORY: "Αποθήκη",
            FEEDBACK: "Ανατροφοδότηση",
            REPORT: "Αναφορά",
            ERROR: "Σφάλμα"
        },
        outletsIdsDefaultOutletTooltip: "Προεπιλεγμένο κατάστημα",
        clickToMakeDefaultOutletTooltip: "Κάντε κλικ για να ορίσετε το προεπιλεγμένο κατάστημα",
        preferedLanguage: "Προτιμώμενη Γλώσσα",
        deactivateAccount: {
            label: "Απενεργοποίηση λογαριασμού",
            desc: "Προσωρινή απενεργοποίηση του λογαριασμού σας.",
            info: "Σημειώστε ότι η απενεργοποίηση του λογαριασμού σας θα έχει τις παρακάτω συνέπειες:",
            info1: "Δεν θα έχετε πλέον πρόσβαση στον λογαριασμό σας",
            info2: "Θα διαγράψουμε όλα τα δεδομένα που σχετίζονται με τον λογαριασμό σας. Θα διατηρήσουμε τα δεδομένα που έχουμε νομική υποχρέωση να αποθηκεύσουμε.",
            info3: "Δεν θα μπορείτε να αναιρέσετε την απενεργοποίηση.",
            actionInfo: "Είστε σίγουρος ότι θέλετε να προχωρήσετε;",
            confirmByTypingCodeBefore: "Επιβεβαιώστε πληκτρολογώντας",
            confirmByTypingCodeAfter: "παρακάτω.",
            btn: "Απενεργοποίηση",
            codesNotMatching: "Ο κωδικός που εισαγάγατε δεν ταιριάζει με τον δοθέντα. Προσπαθήστε ξανά.",
            successTitle: "Ο Λογαριασμός Απενεργοποιήθηκε",
            success: "Τώρα έχουμε απενεργοποιήσει προσωρινά τον λογαριασμό σας. Λυπούμαστε που σας βλέπουμε να φεύγετε. Είστε πάντα ευπρόσδεκτοι να ενεργοποιήσετε ξανά τον λογαριασμό σας!"
        },
        permissions: {
            label: "Δικαιώματα",
            allowOrderPaymentAsTreat: "Επιτρέπεται το κέρασμα"
        }
    },
    employee: {
        title: "Υπάλληλοι",
        pageTitle: "Υπάλληλος",
        subtitle: "Διαχείριση Υπαλλήλων",
        defaultOutletIDError: "Το προεπιλεγμένο κατάστημα απαιτείται. Διπλό κλικ για να κάνετε την επιλογή σας."
    },
    subscription: {
        title: "Συνδρομές",
        pageTitle: "Συνδρομή",
        subtitle: "Διαχείριση Συνδρομών",
        paymentSuccessful: "Η ανανέωση του σχεδίου συνδρομής σας ήταν επιτυχής.",
        paymentFailure: "Η ανανέωση του σχεδίου συνδρομής σας απέτυχε.",
        cancelReccurentPaymentBtnTooltip: "Ακύρωση επαναλαμβανόμενης πληρωμής",
        recurrentStatus: {
            label: "Επαναλαμβανόμενη Πληρωμή",
            ACTIVE: "ΕΝΕΡΓΟ",
            INACTIVE: "ΑΝΕΝΕΡΓΟ"
        },
        organization: {
            label: "Λεπτομέρειες Σχεδίου",
            currentPlan: "Τρέχον Σχέδιο:",
            payPeriod: "Περίοδος Πληρωμής:",
            memberSince: "Μέλος από:",
            dueDate: "Ημερομηνία Λήξης:",
            pendingCharges: "Εκκρεμείς Χρεώσεις",
            pay: "Πληρωμή",
            renew: "Ανανέωση",
            history: {
                smsCounter: "Μετρητής ΣΜΣ: ",
                emailCounter: "Μετρητής Email: ",
                ticketCounter: "Μετρητής Εισιτηρίων: ",
                receiptTicketCounter: "Μετρητής Αποδείξεων: ",
                organizationCharges: "Χρεώσεις Οργανισμού: ",
                totalAmount: "Σύνολο: ",
                outlet: "Κατάστημα: ",
                amount: "Σύνολο: "
            },
            payment: {
                fail: {
                    label: "Αποτυχημένη Πληρωμή",
                    backButton: "Συνδρομή",
                    infoPayment: "Η πληρωμή σας απέτυχε. Παρακαλώ προσπαθήστε ξανά."
                },
                success: {
                    label: "Επιτυχημένη Πληρωμή",
                    backButton: "Συνδρομή",
                    infoPayment: "Η πληρωμή σας ολοκληρώθηκε επιτυχώς."
                }
            }
        },
        status: {
            PENDING: "Εκκρεμής",
            FAILED: "Απέτυχε",
            ACTIVE: "Ενεργή",
            COMPLETED: "Ολοκληρώθηκε",
            EXPIRED: "Ληγμένη",
            CANCELED: "Ακυρωμένη",
            UPGRADED: "Αναβαθμισμένη",
            DOWNGRADED: "Υποβαθμισμένη",
            EXCEPTION: 'Εξαίρεση',
            REFUSED: "Αρνήθηκε",
            UNKNOWN: "Άγνωστη",
            UPDATED: "Ενημερωμένη",
            RENEWED: "Ανανεωμένη"
        },
        history: "Ιστορικό Πληρωμών",
        emails: "Emails"
    },
    subscriptionPlan: {
        label: "Σχέδιο: ",
        title: "Σχέδια Συνδρομής",
        pageTitle: "Σχέδιο Συνδρομής",
        subtitle: "Διαχείριση Σχεδίου Συνδρομής",
        planPricesEmpty: "Δεν έχουν προστεθεί τιμές",
        planServicesEmpty: "Δεν έχουν προστεθεί υπηρεσίες",
        planDetailsEmpty: "Δεν έχουν προστεθεί λεπτομέρειες",
        visibleOnlyToAdmin: "Ορατό μόνο για Διαχειριστή και ανατεθειμένους οργανισμούς",
        type: {
            MONTHLY: "Μηνιαία",
            YEARLY: "Ετήσια",
            HALF_YEAR: "6 Μήνες",
            TWO_YEARS: "2 Χρόνια",
            QUARTER: "4 Μήνες"
        },
        serviceType: {
            SMS: "SMS",
            SMS_MANAGEMENT: "Διαχείριση SMS",
            EMAIL: "Email",
            EMAIL_MANAGEMENT: "Διαχείριση Email",
            PRINTER_TICKET: "Εκτυπωτής Εισιτηρίων",
            RECEIPT_TICKET: "Εκτυπωτής Αποδείξεων",
            TICKET_MANAGEMENT: "Διαχείριση Αποδείξεων/Εισιτηρίων",
            USERS_MANAGMENT: "Διαχείριση Χρηστών",
            ORDERING: "Παραγγελιοληψίας",
            ANALYTICS: "Στατιστικά",
            MENU_CMS: "Menu CMS",
            INVENTORY: "Διαχείριση Αποθήκης",
            FEEDBACK: "Αξιολογήσεις",
            KITCHEN_DISPLAY: "Οθόνη Κουζίνας",
            PICKUP_DISPLAY: "Οθόνη Παραλαβής",
            PRINTER_DEVICE: "Συσκευή Εκτυπωτή",
            PRINTERS_MANAGMENT: "Διαχείριση Εκτυπωτών",
            BOOKING: "Κρατήσεις",
            WAITLIST: "Λίστα αναμονής",
            REQUESTS: "Αιτήσεις",
            OFFERS: "Προσφορές",
            PDA: "PDA",
            QRCODES_MANAGMENT: "Διαχείριση QRcodes",
            QRCODES_PRINT: "Εκτύπωση QRcodes",
            LOYALTY: "Πιστότητα",
            SPLASH_SCREEN: "Οθόνη εκκίνησης",
            BRANDING: "Branding"
        },
        service: {
            oneTimePayment: "Εφάπαξ πληρωμή"
        }
    },
    notification: {
        title: "Ειδοποιήσεις",
        markAllAsRead: "Σήμανση όλων ως Διαβασμένα",
    },
    equipment: {
        title: "Εξοπλισμός",
        pageTitle: "Εξοπλισμός",
        subtitle: " ",
        addLabel: "Προσθήκη Εξοπλισμού",
        bulkAddLabel: "Προσθήκη Εξοπλισμών Μαζικά",
        detailsEmpty: "Δεν έχουν προστεθεί λεπτομέρειες",
        labelBegginingText: "Κείμενο Ετικέτας Έναρξης",
        startNumber: "Από Αριθμό",
        endNumber: "Μέχρι Αριθμό",
        attachEquipment: "εξοπλισμό",
        attachEquipments: "εξοπλισμούς",
        emptyEquipmentsSearch: "Δεν υπάρχει εξοπλισμός που ταιριάζει με την αναζήτησή σας.",
        to: "σε",
        maxLessThanMinErrorMessage: "Η τιμή του τέλους πρέπει να είναι μεγαλύτερη από ή ίση με την τιμή της αρχής.",
        capacity: "Χωρητικότητα"
    },
    itemOrModifier: {
        title: "Προϊόντα ή Τροποποιητές",
        titleDelete: "Προϊόντο/Τροποποιητής",
        subtitle: "Διαχείριση Αντικειμένων/Τροποποιητών για ",
        pageTitleItem: "Προϊόντο",
        pageTitleModifier: "Τροποποιητής",
        extraNote: "Επιπρόσθετη Σημείωση",
        remark: "Σημείωση",
        provider: "Πάροχος",
        modifierList: "Λίστα Τροποποιητή",
        prices: "Τιμές",
        itemPricesEmpty: "Δεν έχουν προστεθεί τιμές",
        emptyMessage: "Δεν έχουν προστεθεί προϊόντα/τροποποιητές",
        price: "Τιμή",
        discountPercent: "Ποσοστό Έκπτωσης",
        priceType: "Τύπος",
        priceListName: "Όνομα Λίστας Τιμών",
        isModifier: "Είναι Τροποποιητής",
        enabled: "Ενεργοποιημένος",
        status: "Κατάσταση",
        addLabel: "Προσθήκη Προϊόντος/Τροποποιητή",
        item: "Προϊόντο",
        modifier: "Τροποποιητής",
        viewPrice: "Προβολή Τιμών",
        loyaltyPoints: "Πόντοι Πιστότητας",
        points: "πόντοι",
        pvcRecyclingTax: "Φόρος Ανακύκλωσης PVC",
        default: "Προεπιλογή",
        details: "Λεπτομέρειες",
        photos: "Φωτογραφίες",
        advanced: "Προχωρημένα",
        STANDARD: "Τυπικό",
        allergies: {
            label: "Αλλεργίες",
            eggs: "Αυγά",
            wheat: "Σιτάρι/Γλουτένη",
            fish: "Ψάρι",
            mustard: "Μουστάρδα",
            celery: "Σέλινο",
            nuts: "Ξηροί καρποί",
            milk: "Γάλα",
            molluscs: "Μαλάκια",
            crustaceans: "Ασπράδια",
            soya: "Σόγια",
            lupin: "Λούπιν",
            seasame: "Σησαμέλαιο"
        },
        extraIcons: {
            label: "Επιπρόσθετα Εικονίδια",
            vegan: "Χορτοφάγος",
            vegetarian: "Φυτοφάγος",
            spicy: "Πικάντικο",
            new: "Νέο"
        },
        nutrients: {
            label: "Θρεπτικά",
            grams: "Γραμμάρια",
            gramsAfterLabel: "γρ",
            energy: "Ενέργεια",
            enrgeyAfterLabel: "MJ",
            calories: "Θερμίδες",
            caloriesAfterLabel: "kcal",
            protein: "Πρωτεΐνη",
            proteinAfterLabel: "γρ",
            carb: "Υδατάνθρακες",
            carbAfterLabel: "γρ",
            sugars: "Ζάχαρη",
            sugarsAfterLabel: "γρ",
            fat: "Λίπος",
            fatAfterLabel: "γρ",
            sat_fat: "Κορεσμένα λιπαρά",
            sat_fatAfterLabel: "γρ",
            trans_fat: "Τρανς λιπαρά",
            trans_fatAfterLabel: "γρ",
            fiber: "Ινες",
            fiberAfterLabel: "γρ",
            sodium: "Νάτριο",
            sodiumAfterLabel: "γρ",
            vitaminA: "Βιταμίνη Α",
            vitaminAAfterLabel: "mg",
            vitaminC: "Βιταμίνη C",
            vitaminCAfterLabel: "mg",
            calcium: "Κάλτσιο",
            calciumAfterLabel: "mg",
            iron: "Σίδηρος",
            ironAfterLabel: "mg",
            salt: "Αλάτι",
            saltAfterLabel: "γρ",
        },
        ingredients: {
            label: "Υλικά",
            addLabel: "Προσθήκη Υλικών",
            emptyIngredientsList: "Δεν υπάρχουν υλικά που είναι επιλεγμένα.",
            name: "Όνομα",
            quantityToBeUsed: "Ποσότητα",
            dialog: {
                noOptions: "Δεν υπάρχουν διαθέσιμα υλικά για επιλογή.",
                attachIngredient: "υλικό",
                attachIngredients: "υλικά",
            },
        },
        export: {
            title: "Εξαγωγή Προϊόντων",
            viewer: "Viewer",
            editor: "Editor",
            copy: "Επιλέξτε το κείμενο ή τοποθετήστε το δρομέα στην γραμμή για αντιγραφή.",
            includePhotos: "Προσθήκη φωτογραφιών?"
        },
        import: {
            title: "Εισαγωγή Προϊόντων"
        }
    },
    itemCategories: {
        label: "Κατηγορίες Προϊόντος",
        FOOD: "Φαγητό",
        DRINKS: "Ποτά",
        OTHER: "Άλλο",
        CUSTOM: "Προσαρμοσμένο",
        INGREDIENT: "Υλικό"
    },
    itemVatTypes: {
        label: "Τύποι ΦΠΑ Προϊόντος",
        labelSingle: "Τύπος ΦΠΑ",
        NONE: "Κανένας",
        SIX_PERCENT: "6%",
        THIRTEEN_PERCENT: "13%",
        TWENTY_FOUR_PERCENT: "24%"
    },
    modifierList: {
        title: "Λίστες Τροποποιητών",
        subtitle: "Διαχείριση Λίστας Τροποποιητών για ",
        pageTitle: "Λίστα Τροποποιητών",
        enabled: "Ενεργοποιημένο",
        allowMultipleSelection: "Επιτρέπεται πολλαπλή επιλογή;",
        minModifiers: "Ελάχιστο",
        maxModifiers: "Μέγιστο",
        addLabel: "Προσθήκη Λίστας Τροποποιητών",
        empty: "Δεν έχουν προστεθεί Λίστες Τροποποιητών",
        modifiersAttached: "Επισυναπτόμενοι Τροποποιητές",
        minSelection: "Ελάχιστη Επιλογή",
        maxSelection: "Μέγιστη Επιλογή",
        items: "Προϊόντα",
        modifiers: "Τροποποιητές",
        addItem: "Προσθήκη Προϊόντος",
        addModifier: "Προσθήκη Τροποποιητή",
        emptyItemList: "Δεν υπάρχουν προϊόντα που είναι επιλεγμένα",
        emptymodifierList: "Δεν υπάρχουν τροποποιητές που είναι επισυνδεδεμένοι",
        emptyModifierListSearch: "Δεν υπάρχουν λίστες τροποποιητών που ταιριάζουν με την αναζήτησή σας.",
        emptyItemsSearch: "Δεν υπάρχουν άλλα προϊόντα που μπορείτε να επισυνδέσετε.",
        emptyModifiersSearch: "Δεν υπάρχουν άλλοι τροποποιητές που μπορείτε να επισυνδέσετε.",
        to: "σε",
        attachedItems: "Επισυνδεδεμένα Προϊόντα",
        adddModifiers: "Προσθήκη Τροποποιητών",
        list: "λίστα",
        lists: "λίστες",
        attachitem: "προϊόντο",
        attachitems: "προϊόντα",
        attachmodifier: "τροποποιητή",
        attachmodifiers: "τροποποιητές"
    },
    menus: {
        title: "Μενού",
        pageTitle: "Μενού",
        subtitle: "Διαχείριση Μενού για ",
        addLabel: "Προσθήκη Μενού",
        emptyMessage: "Δεν έχουν προστεθεί μενού",
        recurrence: "Επανάληψη",
        startDate: "Ημερομηνία Έναρξης",
        endDate: "Ημερομηνία Λήξης",
        recurrenceDaysNumberBeforeLabel: "Επανάληψη κάθε",
        recurrenceDaysNumberAfterLabel: "ημέρες",
        recurrenceDaysNumberAfterLabelOne: "ημέρα",
        emptyOutlets: "Δεν υπάρχουν καταστήματα που χρησιμοποιούν το μενού",
        usedBy: "Χρησιμοποιείται από",
        enabledLanguages: "Διαθέσιμες γλώσσες",
        tabs: {
            details: "Λεπτομέρειες",
            settings: "Ρυθμίσεις",
            photos: "Φωτογραφίες",
            parentCategories: "Γονικές Κατηγορίες",
            outlet: "Τμήμα Καταστήματος",
            categories: "Κατηγορίες",
            menus: "Μενού"
        },
        cms: {
            addNewParentCategory: "Προσθήκη νέας γονικής κατηγορίας",
            addNewCategory: "Προσθήκη νέας κατηγορίας",
            goToParentCategories: "Μετάβαση σε Γονικές Κατηγορίες",
            goToCategories: "Μετάβαση σε Κατηγορίες",
            hideFullScreen: "Απόκρυψη Πλήρους Οθόνης",
            fullscreen: "Πλήρης Οθόνη",
            hideCategoriesImages: "Απόκρυψη Εικόνων Κατηγοριών",
            hideItemImages: "Απόκρυψη Εικόνων Αντικειμένων",
            showCategoriesImages: "Εμφάνιση Εικόνων Κατηγοριών",
            showItemImages: "Εμφάνιση Εικόνων Αντικειμένων",
            showUsedCategories: "Εμφάνιση χρησιμοποιούμενων κατηγοριών",
            showUsedItems: "Εμφάνιση χρησιμοποιούμενων αντικειμένων",
            hideUsedCategories: "Απόκρυψη χρησιμοποιούμενων κατηγοριών",
            hideUsedItems: "Απόκρυψη χρησιμοποιούμενων αντικειμένων",
            hideCategories: "Απόκρυψη Κατηγοριών",
            showCategories: "Εμφάνιση Κατηγοριών",
            hideParentCategories: "Απόκρυψη γονικών κατηγοριών",
            showParentCategories: "Εμφάνιση γονικών κατηγοριών",
            hideItems: "Απόκρυψη Αντικειμένων",
            addCategories: "Προσθήκη κατηγοριών",
            addItems: "Προσθήκη αντικειμένων",
            showItems: "Εμφάνιση αντικειμένων",
            parentCategory: "Γονική Κατηγορία",
            category: "Κατηγορία",
            items: "Προϊόντα"
        },
        settings: {
            label: "Ρυθμίσεις",
            allowParentCategories: "Επιτρέπονται οι γονικές κατηγορίες",
            showAdvancedCMS: "Εμφάνιση Προχωρημένου CMS",
            baseColor: "Βασικό Χρώμα",
            font: "Οικογένεια Γραμματοσειράς",
            itemBackgroundColor: "Χρώμα Φόντου Προϊόντος",
            itemHeaderColor: "Χρώμα Κεφαλίδας Προϊόντος",
            itemHeaderFontSize: "Μέγεθος Γραμματοσειράς Κεφαλίδας Προϊόντος",
            itemDescColor: "Χρώμα Περιγραφής Προϊόντος",
            itemDescFontSize: "Μέγεθος Γραμματοσειράς Περιγραφής Προϊόντος",
            itemPriceColor: "Χρώμα Τιμής Προϊόντος",
            itemPriceFontSize: "Μέγεθος Γραμματοσειράς Τιμής Προϊόντος",
            navigationMenu: "Μενού Πλοήγησης",
            navigationMenuFont: "Οικογένεια Γραμματοσειράς",
            navigationMenuFontSize: "Μέγεθος Γραμματοσειράς",
            navigationMenuBackgroundColor: "Χρώμα Φόντου Μενού Πλοήγησης",
            navigationMenuSelectedOptionFontColor: "Χρώμα Γραμματοσειράς (Επιλεγμένο/Ενεργό)",
            navigationMenuUnSelectedOptionFontColor: "Χρώμα Γραμματοσειράς (Μη Επιλεγμένο/Ανενεργό)",
            navigationMenuStyle: "Στυλ Μενού",
            buttonFont: "Γραμματοσειρά Κουμπιών",
            chipTextColor: "Χρώμα Κειμένου Chip",
            buttonColor: "Χρώμα Κουμπιού",
            buttonBackgroundColor: "Χρώμα Φόντου Κουμπιού",
            buttonFontSize: "Μέγεθος Γραμματοσειράς Κουμπιού",
            itemPhotoStyle: {
                label: "Στυλ Εικόνας",
                ROUND: "Κυκλικό",
                SQUARE: "Τετράγωνο",
            },
            layout: {
                label: "Διάταξη",
                LIST: "Λίστα",
                GRID: "Πλέγμα",
            },
            showCurrency: "Εμφάνιση νομίσματος",
            showDescription: "Εμφάνιση περιγραφής",
            showPointsPerItem: "Εμφάνιση πόντων ανά προϊόντο",
            allowCommentsOnItem: "Επιτρέπονται τα σχόλια στο προϊόντο",
            iconPositionStart: "Θέση νομίσματος έναρξης",
            category: "Κατηγορία",
            // menu
            menuBackgroundColor: "Χρώμα Φόντου",
            menuHeaderColor: "Χρώμα Κεφαλίδας",
            menuHeaderFontSize: "Μέγεθος Γραμματοσειράς Κεφαλίδας",
            menuDescColor: "Χρώμα Περιγραφής",
            menuDescFontSize: "Μέγεθος Γραμματοσειράς Περιγραφής",
            menuPhotoStyle: "Στυλ Φωτογραφίας",
            // parent category
            parentCategoryBackgroundColor: "Χρώμα Φόντου",
            parentCategoryHeaderColor: "Χρώμα Κεφαλίδας",
            parentCategoryHeaderFontSize: "Μέγεθος Γραμματοσειράς Κεφαλίδας",
            parentCategoryDescColor: "Χρώμα Περιγραφής",
            parentCategoryDescFontSize: "Μέγεθος Γραμματοσειράς Περιγραφής",
            parentCategoryPhotoStyle: "Στυλ Φωτογραφίας",
            // category
            categoryBackgroundColor: "Χρώμα Φόντου",
            categoryHeaderColor: "Χρώμα Κεφαλίδας",
            categoryHeaderFontSize: "Μέγεθος Γραμματοσειράς Κεφαλίδας",
            categoryDescColor: "Χρώμα Περιγραφής",
            categoryDescFontSize: "Μέγεθος Γραμματοσειράς Περιγραφής"
        },
    },
    menuCategories: {
        plain: "Κατηγορίες",
        title: "Κατηγορίες Μενού",
        addLabel: "Προσθήκη Κατηγοριών",
        viewMenuDetails: "Προβολή λεπτομερειών μενού",
        newCategory: "Νέα Κατηγορία",
        newParentCategory: "Νέα Γονική Κατηγορία",
        parentCategories: "Γονικές Κατηγορίες",
        emptyItemList: "Δεν υπάρχουν προϊόντα επιλεγμένα",
        items: "Προϊόντα",
        categories: "Κατηγορίες",
        category: "Κατηγορία",
        collapseAll: "Σύμπτυξη όλων",
        expandAll: "Ανάπτυξη όλων",
    },
    order: {
        title: "Παραγγελίες",
        pageTitle: "Παραγγελίες",
        plain: "Παραγγελία",
        subtitle: " ",
        comments: "Σχόλια",
        orderNumber: "Αριθμός Παραγγελίας",
        allergies: "Αλλεργίες",
        customerPhoneNumber: "Τηλέφωνο Πελάτη",
        customerName: "Όνομα Πελάτη",
        loyaltyPointsEarned: "Πόντοι Πιστότητας (σύνολο)",
        points: "πόντοι",
        totalAmount: "Σύνολο",
        cart: "Καλάθι (JSON)",
        date: "Ημερομηνία",
        dateFrom: "Από",
        dateTo: "Έως",
        persons: "Άτομα",
        paid: "Πληρωμένο",
        loyaltyPointsEarned: "Πόντοι Πιστότητας",
        status: "Κατάσταση",
        payment: "Πληρωμή",
        actions: {
            all: "Όλα",
            status: {
                pending: "Σε αναμονή",
                working: "Σε εξέλιξη",
                done: "Ολοκληρώθηκε",
                cancelled: "Ακυρώθηκε",
            },
            statusCaps: {
                PAYMENT: "Πληρωμή",
                PENDING: "Σε αναμονή",
                PENDING_PAYMENT: "Εκκρεμής Πληρωμή",
                PICKED_UP: "Παραλήφθηκε",
                SERVED: "Εξυπηρετήθηκε",
                WORKING: "Σε εξέλιξη",
                DONE: "Ολοκληρώθηκε",
                CANCELED: "Ακυρώθηκε",
                UNAVAILABLE_ITEMS: "Μη διαθέσιμα Προϊόντα",
                EXPIRED_PAYMENT: "Ληγμένη Πληρωμή",
                ERROR: "Σφάλμα",
                UPDATE_EQUIPMENT: "Ενημέρωση Τραπεζιού",
                UPDATE_TYPE: "Ενημέρωση Τύπου",
                UPDATE_PERSONS: "Ενημέρωση Ατόμων",
                UPDATE_STATUS: "Ενημέρωση Κατάστασης",
                true: "Ολοκληρώθηκε",
                false: "Μη ολοκληρωμένο"
            },
            paymentMethod: {
                cash: "Μετρητά",
                card: "Κάρτα",
                pos: "POS",
                none: "Μη καθορισμένο",
            },
        },
        tip: {
            label: "tip",
            checkout: {
                label: "Επιλέξτε φιλοδώρημα",
                info: "Το προσωπικό μας θα είναι ευγνώμων."
            }
        },
        item: {
            name: "Όνομα",
            price: "Τιμή",
            quantity: "Ποσότητα",
            pvcRecyclingTax: "Φόρος Ανακύκλωσης PVC",
            done: "Ολοκληρώθηκε",
            modifiers: "Τροποποιητές",
            notes: "Σημειώσεις"
        },
        addOrder: "Δημιουργία Παραγγελίας",
        equipmentInvalid: "Η ετικέτα εξοπλισμού που εισαγάγατε δεν βρέθηκε ή δεν είναι ενεργή. Παρακαλώ δοκιμάστε ξανά!",
        emptyStatusSearch: "Δεν υπάρχουν καταστάσεις παραγγελίας που ταιριάζουν με την αναζήτησή σας.",
    },
    loyalty: {
        title: "Λέσχη Πιστότητας",
        pageTitle: "Πιστότητα",
        subtitle: "Διαχείριση Πιστότητας για ",
        addLabel: "Προσθήκη Πιστότητας",
        category: {
            label: "Κατηγορίες Πιστότητας",
            labelSingle: "Κατηγορία Πιστότητας",
            empty: "Δεν έχουν προστεθεί κατηγορίες πιστότητας",
            fullName: "ΠΛΗΡΕΣ ΟΝΟΜΑ",
            font: "Κείμενο",
            family: "Οικογένεια Γραμματοσειράς",
            baseColor: "Χρώμα",
            background: "Φόντο",
            backgroundColor1: "Χρώμα",
            backgroundColor2: "Δεύτερο Χρώμα",
            backgroundImage: "Εικόνα",
            gradientBackground: "Χρώμα γραμμής",
            singleColorBackground: "Μονόχρωμο",
            backgroundType: "Τύπος",
            IMAGE: "Εικόνα",
            COLOR: "Χρώμα",
            targetPointsClean: "Στόχος πόντων",
            targetPoints: "Στόχος πόντων:",
            points: "πόντοι",
            discountPercentageClean: "Έκπτωση",
            discountPercentage: "Έκπτωση:",
            couponValueClean: "Τιμή Κουπονιού",
            couponValue: "Τιμή Κουπονιού:",
        },
        settings: {
            label: "Ρυθμίσεις Πιστότητας",
            cardProperties: "Ιδιότητες Κάρτας",
            configureLoyaltyCard: "Ρύθμιση κάρτας πιστότητας",
            showLogo: "Εμφάνιση λογοτύπου",
            showLogoTooltip: "Λογότυπο οργανισμού",
            showLogoTooltipDisabled: "Παρακαλώ συμπεριλάβετε πρώτα ένα λογότυπο για τον οργανισμό.",
            showName: "Εμφάνιση ονόματος",
            showLevel: "Εμφάνιση επιπέδου",
            showPoints: "Εμφάνιση πόντων",
            showSubsidy: "Εμφάνιση επιδοτήσεων",
            showWallet: "Εμφάνιση πορτοφολιού",
            showLoyaltyMemberNumber: "Εμφάνιση αριθμού πιστότητας",
            showCredit: "Εμφάνιση πιστώσεων",
            showCoupons: "Εμφάνιση κουπονιών",
            showCouponRemainder: "Εμφάνιση υπολοίπου κουπονιού",
            showBarcode: "Εμφάνιση barcode",
            points: "πόντοι",
            "%": "%",
            configuration: "Διαμόρφωση",
            front: "Μπροστά",
            back: "Πίσω",
            barcode: {
                width: "Πλάτος",
                height: "Ύψος",
                format: "Μορφή",
                displayValue: "Εμφάνιση τιμής",
                hideValue: "Απόκρυψη τιμής",
                fontOptions: {
                    label: "Επιλογές Γραμματοσειράς",
                    bold: "Έντονη",
                    italic: "Πλάγια",
                },
                font: "Γραμματοσειρά",
                textAlign: {
                    label: "Στοίχιση Κειμένου",
                    left: "Αριστερά",
                    center: "Κέντρο",
                    right: "Δεξιά",
                },
                textPosition: {
                    label: "Θέση Κειμένου",
                    top: "Επάνω",
                    bottom: "Κάτω",
                },
                textMargin: "Περιθώριο Κειμένου",
                fontSize: "Μέγεθος Γραμματοσειράς",
                background: "Φόντο",
                lineColor: "Χρώμα Γραμμής",
                margin: "Περιθώριο",
                marginTop: "Επάνω",
                marginBottom: "Κάτω",
                marginLeft: "Αριστερά",
                marginRight: "Δεξιά",
            },
            enableWelcomeBonus: "Ενεργοποίηση Μπόνους Καλωσορίσματος",
            welcomeBonusPoints: "Πόντοι Καλωσορίσματος",
            welcomeBonusCredit: "Πίστωση Καλωσορίσματος"
        },
        extraOutletActionButtons: {
            label: "Επιπλέον κουμπιά Ενεργειών"
        }
    },
    coupon: {
        tabs: {
            configuration: "Διαμόρφωση",
            style: "Στυλ",
            barcodeStyle: "Στυλ barcode"
        },
        baseLabelColor: "Χρώμα Κεφαλίδας",
        baseDescriptionColor: "Χρώμα Περιγραφής",
        baseExpiresColor: "Χρώμα Λήξης",
        background: "Φόντο",
        backgroundColor1: "Χρώμα",
        backgroundColor2: "Δεύτερο Χρώμα",
        backgroundImage: "Εικόνα",
        gradientBackground: "Χρώμα γραμμής",
        singleColorBackground: "Μονόχρωμο",
        backgroundType: "Τύπος",
        IMAGE: "Εικόνα",
        COLOR: "Χρώμα",
        font: "Γραμματοσειρά",
        code: "Κωδικός",
        expirationDate: "Ημερομηνία Λήξης",
        redeemInAllOutlets: "Εξαργυρώσιμο σε όλα τα καταστήματα;"
    },
    feedback: {
        title: "Φόρμα Ανατροφοδότησης",
        pageTitle: "Φόρμες Ανατροφοδότησης",
        subtitle: "",
        settings: {
            label: "Ρυθμίσεις",
        },
        attachedOutlets: "Συνημμένα Καταστήματα"
    },
    item: {
        notes: "Σημειώσεις",
        notesInfo: "Σημειώσεις (αν υπάρχουν ειδικές οδηγίες)",
        itemCharLimit: "χαρ",
        itemCharsLimit: "χαρακτήρες",
        allergies: "Αλλεργίες",
        pvcRecyclingTax: "Φόρος Ανακύκλωσης PVC",
        points: "πόντοι",
        caloriesShort: "Θερμ",
        proteinShort: "Πρωτ",
        carbShort: "Υδατ",
        min: "Ελάχ.",
        max: "Μέγ.",
        nutrients: {
            label: "Θρεπτικά Στοιχεία",
            grams: "Γραμμάρια",
            gramsAfterLabel: "γρ",
            gramsDetailsLabel: "Ποσότητα ανά ",
            gramsPortionLabel: "μερίδα",
            energy: "Ενέργεια",
            enrgeyAfterLabel: "MJ",
            calories: "Θερμίδες",
            caloriesAfterLabel: "kcal",
            protein: "Πρωτεΐνη",
            proteinAfterLabel: "γρ",
            carb: "Υδατάνθρακες",
            carbAfterLabel: "γρ",
            sugars: "Σάκχαρα",
            sugarsDetailsLabel: "(εκ των οποίων σάκχαρα)",
            sugarsAfterLabel: "γρ",
            fat: "Λιπαρά",
            fatDetailsLabel: "Συνολικά Λιπαρά",
            fatAfterLabel: "γρ",
            sat_fat: "Κορεσμένα λιπαρά",
            sat_fatDetailsLabel: "(εκ των οποίων Κορεσμένα)",
            sat_fatAfterLabel: "γρ",
            trans_fat: "Τρανς λιπαρά",
            trans_fatDetailsLabel: "(εκ των οποίων Τρανς)",
            trans_fatAfterLabel: "γρ",
            fiber: "Ίνες",
            fiberDetailsLabel: "(εκ των οποίων ινες)",
            fiberAfterLabel: "γρ",
            sodium: "Νάτριο",
            sodiumAfterLabel: "γρ",
            vitaminA: "Βιταμίνη A",
            vitaminAAfterLabel: "mg",
            vitaminC: "Βιταμίνη C",
            vitaminCAfterLabel: "mg",
            calcium: "Κάλιο",
            calciumAfterLabel: "mg",
            iron: "Σίδηρος",
            ironAfterLabel: "mg",
            salt: "Αλάτι",
            saltAfterLabel: "γρ",
        },
        requiredChoice: "Απαραίτητη επιλογή",
        optionalChoice: "Προαιρετική επιλογή",
        ok: "Εντάξει",
    },
    exportDialog: {
        viewer: "Viewer",
        editor: "Editor"
    },
    section: {
        title: "Ενότητα",
        pageTitle: "Ενότητες",
        subtitle: " ",
        name: "Όνομα",
        description: "Περιγραφή",
        attachEquipments: "Συνδέστε εξοπλισμό",
        emptyEquipment: "Δεν υπάρχει διαθέσιμος εξοπλισμός.",
        emptySection: "Δεν υπάρχουν διαθέσιμες ενότητες.",
        emptyEnName: "Πρέπει να παρέχετε την αγγλική έκδοση του ονόματος της ενότητας."
    },
    help: {
        title: "Βοήθεια",
        pageTitle: "Βοήθεια",
        header: "Βρείτε τις απαντήσεις που ψάχνετε, επιλέγοντας ένα θέμα",
        analytics: {
            title: "Αναλύσεις",
            subtitle:
                "Πώς να δείτε, να εξερευνήσετε και να κατεβάσετε τα δεδομένα σας. Όλη η επιχειρηματική νοημοσύνη που χρειάζεστε για να εκτελέσετε.",
        },
        user: {
            title: "Χρήστης",
            subtitle: "Οι πιο συχνές ερωτήσεις",
        },
        outlet: {
            title: "Κατάστημα",
            subtitle: "Οι πιο συχνές ερωτήσεις",
        },
        organization: {
            title: "Οργάνωση",
            subtitle: "Οι πιο συχνές ερωτήσεις",
        },
        order: {
            title: "Παραγγελία",
            subtitle: "Βρείτε εδώ όλα για τη σελίδα παραγγελιοληψίας της Usee",
        },
        menu: {
            title: "Μενού",
            subtitle: "Οι πιο συχνές ερωτήσεις",
        },
        feedback: {
            title: "Ανατροφοδότηση",
            subtitle: "Οι πιο συχνές ερωτήσεις",
        },
        loyalty: {
            title: "Πιστότητα",
            subtitle: "Οι πιο συχνές ερωτήσεις",
        },
        equipment: {
            title: "Εξοπλισμός",
            subtitle: "Οι πιο συχνές ερωτήσεις",
        },
        faq: {
            title: "Συχνές Ερωτήσεις",
            subtitle: "Οι πιο συχνές ερωτήσεις",
        },
        bookMeetingMessage:
            "Δεν βρήκατε αυτό που ψάχνετε στα παραπάνω θέματα; ",
        bookMeeetingBtnLabel: "Προγραμματίστε μια συνάντηση μαζί μας",
    },
    customer: {
        title: "Πελάτης",
        pageTitle: "Πελάτες",
        subtitle: " ",
        username: "Όνομα Χρήστη",
        firstName: "Όνομα",
        lastName: "Επώνυμο",
        phone: "Τηλέφωνο",
        email: "Email",
        order: {
            ordersNumber: "Συνολικός Αριθμός Παραγγελιών",
            orderTotalAmmount: "Συνολικό Ποσό Παραγγελιών"
        },
        loyalty: {
            name: "Κατηγορία Πιστότητας",
            points: "Πόντοι",
            loyaltyNumber: "Αριθμός Πιστότητας"
        },
        view: {
            since: "Από: ",
            orders: "Παραγγελίες: ",
            spent: "Δαπανήθηκε: ",
            currentPoints: "Τρέχοντες πόντοι:",
            contactDetails: {
                label: "Στοιχεία Επικοινωνίας",
                username: "Όνομα Χρήστη",
                firstName: "Όνομα",
                lastName: "Επώνυμο",
                email: "Email",
                address: "Διεύθυνση",
                country: "Χώρα",
                phone: "Κινητό",
            },
            customProperties: {
                label: "Προσαρμοσμένες Ιδιότητες",
                storeCredit: "Πιστωτικό Υπόλοιπο",
                status: {
                    label: "Κατάσταση",
                    true: "Ενεργό",
                    false: "Ανενεργό"
                },
                signup: "Εγγραφή"
            },
            notes: {
                label: "Σημειώσεις Ομάδας",
                info: "Κείμενο σχολίων....",
                btn: "Υποβολή",
                itemCharLimit: "χαρ",
                itemCharsLimit: "χαρακτήρες",
            },
            tabs: {
                summary: "Σύνοψη",
                orders: {
                    latestOrders: {
                        label: "Τελευταίες Παραγγελίες: ",
                    },
                    label: "Παραγγελίες",
                    order: {
                        moreOrders: "Περισσότερες Παραγγελίες",
                        backButton: "Επιστροφή στις παραγγελίες",
                        outletName: "Κατάστημα:",
                        equipmentLabel: "Τοποθεσία:",
                        orderTotal: "Συνολικό Ποσό Παραγγελίας:",
                        orderNumber: "Αριθμός Παραγγελίας:",
                        paymentTotal: "Συνολικό Ποσό Πληρωμής",
                        paymentMethod: "Τρόπος Πληρωμής: ",
                        cart: "Καλάθι",
                        rating: "Η γνώμη σας μετράει",
                        ratingDescription: "Βαθμολογήστε την παραγγελία σας",
                        allergies: "Οι Αλλεργίες σας",
                        notes: "Σημείωση:",
                        pvcRecyclingTax: "Περιλαμβάνεται περιβαλλοντική εισφορά",
                        discount: "Έκπτωση:",
                        loyalty: "Πιστότητα",
                        coupon: "Κουπόνι:",
                        fail: {
                            backButton: "Ολοκλήρωση Παραγγελίας",
                            infoPayment: "Η πληρωμή σας απέτυχε. Παρακαλώ προσπαθήστε ξανά."
                        },
                        success: {
                            tab: "Επιτυχία",
                            backButton: "Ολοκλήρωση Παραγγελίας",
                            infoPayment: "Η παραγγελία σας ολοκληρώθηκε με επιτυχία."
                        }
                    },
                },
                activity: {
                    label: "Δραστηριότητα",
                    action: {
                        CLEAR: "καθαρισμός",
                        points: "πόντοι"
                    },
                    referenceType: {
                        ACCOUNT: "Λογαριασμός",
                        BOOKING: "Κράτηση",
                        EMPLOYEE: "Υπάλληλος",
                        FEEDBACK: "Ανατροφοδότηση",
                        ORDER: "Παραγγελία",
                        PROMO: "Προσφορά",
                        REFUND: "Επιστροφή",
                        REGISTRATION: "Εγγραφή"
                    },
                    manualAddPointsLabel: "Χειροκίνητη προσθήκη πόντων"
                },
                coupons: {
                    label: "Κουπόνια",
                    expiresOn: "Λήγει στις:"
                }
            }
        },
        tabs: {
            all: "ΟΛΑ",
            returning: "Επιστροφή",
            orderRecently: "Πρόσφατα Παραγγελίες"
        }
    },
    printer: {
        title: "Εκτυπωτής",
        subtitle: " ",
        pageTitle: "Εκτυπωτές",
        subtitle: "Διαχείριση Εκτυπωτών για ",
        printNodeId: "Print Node ID",
        printNodeIdErrror: "Ο Κόμβος Εκτύπωσης απαιτεί αριθμό με μήκος 8 ψηφία",
        outletID: "ID Καταστήματος",
        outletName: "Όνομα Καταστήματος",
        outletSections: "Τμήματα Καταστήματος",
        isActive: "είναι ενεργός;",
        equipments: "Εξοπλισμός",
        includedEquipmentIds: "Συμπεριλαμβανόμενα εξαρτήματα",
        excludedEquipmentIds: "Αποκλεισμένα εξαρτήματα",
        employees: "Υπάλληλοι",
        noPrinters: "Δεν βρέθηκαν εκτυπωτές.",
        friendlyName: "Φιλικό Όνομα",
        type: {
            label: "Τύπος",
            ip: "IP",
            printNode: "Print Node",
        },
        ip: "IP Address Εκτυπωτή",
        port: "Port Εκτυπωτή",
        invalidIP: "Μη έγκυρη διεύθυνση IP",
        invalidPort: "Μη έγκυρος αριθμός θύρας",
        paperSize: {
            label: "Μέγεθος χαρτιού",
            _80mm: "80mm",
            _58mm: "58mm"
        },
        model: {
            label: "Μοντέλο",
        },
        tabs: {
            details: "Λεπτομέρειες",
            ticketConfiguration: {
                label: "Διαμόρφωση Εισιτηρίου",
                NEW_ORDER: "Νέα Παραγγελία",
                CANCELED_ORDER: "Ακυρωμένη Παραγγελία",
                NEW_REQUEST: "Νέο Αίτημα",
                KITCHEN_ORDER: "Παραγγελία Κουζίνας",
                DRINKS_ORDER: "Παραγγελία Ποτών",
                RECEIPT: "Απόδειξη",
                TEMP_RECEIPT: "Προσωρινή Απόδειξη",
                INVOICE: "Τιμολόγιο",
                WAITLIST: "Εγγραφή Λίστας Αναμονής",
                startText: "Αρχικό Κείμενο",
                endText: "Τελικό Κείμενο",
                replicas: "Αντίγραφα",
                field: {
                    labels: "Ετικέτες",
                    ORDER_NUMBER: "Αριθμός Παραγγελίας",
                    OUTLET_NAME: "Όνομα Καταστήματος",
                    TIMESTAMP: "Χρονοσήμανση",
                    WAITER_OR_USER: " Σερβιτόρος ή Χρήστης",
                    CUSTOMER_NAME: "Όνομα Πελάτη",
                    EQUIPMENT_FLOOR: "Όροφος Εξοπλισμού",
                    EQUIPMENT_LABEL: "Ετικέτα Εξοπλισμού",
                    EQUIPMENT_SECTION: "Τμήμα Εξοπλισμού",
                    PAYMENT_METHOD: "Τρόπος Πληρωμής",
                    COMMENTS: "Σχόλια",
                    ITEM_COMMENTS: "Σχόλια Είδους",
                    ITEM_NOTES: "Σημειώσεις Είδους",
                    DELIVERY_TIME: "Χρόνος Παράδοσης",
                    CART_WITH_PRICES: "Καλάθι με τιμές",
                    CART_WITH_NO_PRICES: "Καλάθι χωρίς τιμές",
                    CART_TOTAL: "Συνολικό Καλάθι",
                    QR_CODE: "Κωδικός QR",
                    BAR_CODE: "Κωδικός Γραμμής",
                    TYPE: "Τύπος",
                    TEXT: "Κείμενο",
                    CREATED_BY: "Δημιουργία από",
                    PERSONS: "Άτομα",
                    QRCODE: "QRcode"
                }
            },
            style: {
                label: "Στύλ",
                baseFontSize: "Βασικό Μέγεθος Γραμματοσειράς",
                primaryFontSize: "Κύριο Μέγεθος Γραμματοσειράς",
                secondaryFontSize: "Δευτερέυον Μέγεθος Γραμματοσειράς",
                tableFontSize: "Μέγεθος Γραμματοσειράς Στοιχείων Πίνακα",
                tableNoteFontSize: "Μέγεθος Γραμματοσειράς Σχολίων Πίνακα"
            },
        }
    },
    pos: {
        title: "Συσκευή POS",
        subtitle: " ",
        pageTitle: "Συσκευές POS",
        subtitle: "Διαχείριση Συσκευών POS για ",
        outletID: "ID Καταστήματος",
        outletName: "Όνομα Καταστήματος",
        noPoses: "Δεν βρέθηκαν συσκευές POS.",
        friendlyName: "Φιλικό Όνομα",
        terminalId: "Terminal ID",
        type: {
            label: "Τύπος",
            VIVA: "VIVA"
        },
        provider: {
            label: "Πάροχος",
            SoftPos: "SoftPos"
        },
        syncTooltip: "Sync Viva POS Devices"
    },
    qrcode: {
        title: "Κωδικός QR",
        pageTitle: "Κωδικοί QR",
        subtitle: "Διαχείριση Κωδικών QR για ",
        sign: "προσθήκη υπογραφής?",
        templates: {
            label: "Πρότυπα Κωδικού QR",
            labelSingle: "Πρότυπο Κωδικού QR",
            includeLogo: "Συμπερίληψη λογοτύπου",
            removeQrCodeBehindLogo: "Αφαίρεση κωδικού QR πίσω από το λογότυπο",
            tips: "Σάρωση με το κινητό σας για προεπισκόπηση"
        },
        settings: {
            label: "Ρυθμίσεις Κωδικού QR",
            hasCustomDesign: "Προσαρμοσμένος σχεδιασμός",
            imageAsBackground: "Ορισμός εικόνας ως Φόντο",
            logoOpacity: "Διαφάνεια λογοτύπου",
            logoMissing: "Το λογότυπο του καταστήματος δεν βρέθηκε, παρακαλούμε μεταβείτε στην καρτέλα 'Λεπτομέρειες' και μεταφορτώστε ένα, ή χρησιμοποιήστε ένα από τα διαθέσιμα εικονίδια παρακάτω.",
            logo: {
                label: "Λογότυπο",
                info: "Μεταφορτώστε την δική σας προσαρμοσμένη εικόνα λογότυπου ως αρχείο .png, .jpg .svg με μέγιστο μέγεθος 2 MB.",
                note1: "Δημιουργούμε δυναμικούς κωδικούς QR που μας επιτρέπουν να παρακολουθούμε την απόδοση και να επεξεργαζόμαστε τα δεδομένα ακόμα και μετά την εκτύπωση.",
                icons: "Ή χρησιμοποιήστε τα διαθέσιμα εικονίδια μας:",
                upload: {
                    button: "Κάντε Κλικ ή Σύρετε εδώ",
                },
            },
            assets: {
                label: "Περιουσιακά στοιχεία",
                logoOpacity: "Διαφάνεια λογοτύπου",
                logoPadding: "Περιθώριο λογοτύπου",
                removeQrCodeBehindLogo: "Αφαίρεση φόντου πίσω από το λογότυπο",
                logoWidth: "Πλάτος",
                logoHeight: "Ύψος"
            },
            colors: {
                label: "Χρώματα",
                bgColor: "Χρώμα Φόντου",
                fgColor: "Χρώμα Εμπρόσθιου Πλάνου"
            },
            eyes: {
                label: "Μάτια",
                style: "Στυλ",
                style: "Στυλ",
                eyeFrameShape: "Σχήμα Πλαισίου Ματιών",
                eyeBallShape: "Σχήμα Μπάλας Ματιού",
                color: "Χρώμα",
                info: "Επιλέξτε μάτια για να ξεχωρίσει ο κωδικός QR. Τα μάτια είναι αυτά που αναγνωρίζει η κάμερά σας κατά τη σάρωση",
                left: "Αριστερά",
                right: "Δεξιά",
                bottom: "Κάτω"
            },
            patterns: {
                label: "Πρότυπα"
            },
            options: {
                label: "Επιλογές",
                ecLevel: {
                    label: "Επίπεδο Διόρθωσης Σφαλμάτων",
                    L: "Επίπεδο L - (7%)",
                    M: "Επίπεδο M - (15%)",
                    Q: "Επίπεδο Q - (25%)",
                    H: "Επίπεδο H - (30%)",
                    info: "Κάθε κωδικός QR έχει ένα από τα τέσσερα επίπεδα (διόρθωσης σφαλμάτων): L, M, Q, H. Το επίπεδο καθορίζει το ποσοστό του συνολικού κωδικού QR που μπορεί να είναι βρώμικο ή κατεστραμμένο χωρίς να είναι αδύνατο να διαβαστεί. Το επίπεδο L μπορεί να είναι βρώμικο/κατεστραμμένο έως 7%, το επίπεδο M 15%, το επίπεδο Q 25%, το επίπεδο H 30%. Όσο μεγαλύτερο το ποσοστό, τόσο μεγαλύτερες οι διαστάσεις του κωδικού QR."
                },
                size: "Μέγεθος κωδικού QR",
                quietZone: "Ζώνη ησυχίας"
            },
            frame: {
                label: "Πλαίσιο",
                note: "Ένας κωδικός QR με πλαίσιο και κλήση προς δράση λαμβάνει 80% περισσότερα σκαναρίσματα",
                frameText: "Κείμενο",
                frameTextColor: "Χρώμα Κειμένου",
                frameColor: "Χρώμα",
                frameTextFontSize: "Μέγεθος Γραμματοσειράς",
                style: "Στυλ"
            },
            removeQrCodeBehindLogo: "Αφαίρεση Φόντου Πίσω από το Λογότυπο",
            ecLevel: {
                label: "Ποιότητα",
                M: "Μέτρια",
                L: "Αδύναμη",
                Q: "Δυνατή",
                H: "Υψηλή",
            },
        },
        tip: "Μετά την σημείωση των διαφορετικών τρόπων σχεδιασμού του κωδικού QR σας, το τελικό βήμα θα πρέπει να είναι να το δοκιμάσετε με διάφορες εφαρμογές σάρωσης για να διασφαλίσετε ότι είναι αναγνώσιμο. Όταν είστε έτοιμοι, ελέγξτε τον οδηγό μας σχετικά με το Πώς να Εκτυπώσετε τους Κωδικούς QR Σωστά.",
        info: 'Ο κωδικός QR είναι ένας δισδιάστατος κωδικός γραφής που μπορεί να διαβαστεί από έξυπνα τηλέφωνα. Επιτρέπει την κωδικοποίηση πάνω από 4000 χαρακτήρων σε έναν δισδιάστατο κωδικό γραφής. Οι κωδικοί QR μπορούν να χρησιμοποιηθούν για να εμφανιστεί κείμενο στον χρήστη, για να ανοίξει ένα URL, να αποθηκεύσει ένα επαφέα στο βιβλίο διευθύνσεων ή να συνθέσει μηνύματα κειμένου. Το "QR Code" είναι καταχωρημένο εμπορικό σήμα της DENSO WAVE INCORPORATED. Για να διαβάσετε κωδικούς QR με ένα έξυπνο τηλέφωνο, μπορείτε απλά να χρησιμοποιήσετε την εφαρμογή κάμερας που έρχεται με το τηλέφωνο σας. Στις συσκευές επιφάνειας εργασίας, μπορείτε απλά να προσθέσετε στα αγαπημένα το τμήμα Σάρωση της εφαρμογής μας. Αυτό μπορεί να είναι χρήσιμο για να στείλετε ένα σύνδεσμο από ένα τηλέφωνο στο φυσικό σας υπολογιστή για παράδειγμα.',
        tip2: "Τα προσαρμοσμένα κωδικά QR μπορεί να μην είναι σαρωτές. Βεβαιωθείτε ότι ο κωδικός QR σας μπορεί να σαρώθει στο πραγματικό μέγεθος πριν το χρησιμοποιήσετε!",
    },
    inventory: {
        label: "Αποθήκη",
        subtitle: "Διαχείριση της διαθεσιμότητας των ειδών σας",
        price: "Τιμή",
        defaultPrice: "Προεπιλεγμένη Τιμή",
        pauseAvailability: 'Παύση Διαθεσιμότητας',
        resumeAvailability: "Συνέχιση Διαθεσιμότητας",
        sortage: "Έλλειψη",
        unlimited: "Απεριόριστη",
        quantity: "Συγκεκριμένη Ποσότητα",
        sortage: "Απόθεμα",
        percentage: "Ποσοστό έκπτωσης",
        "%": "%",
        alertSortage: "Ειδοποίηση έλλειψης αποθέματος",
        tabs: {
            items: "Προϊόντα",
            offers: "Προσφορές",
        }
    },
    display: {
        label: "Οθόνη",
        kitchen: {
            label: "Οθόνη Κουζίνας",
            settings: {
                label: "Ρυθμίσεις",
                collapsePendingOrders: "Σύμπτυξη Εκκρεμών Παραγγελιών",
                autoAcceptOrders: "Αυτόματη Αποδοχή Παραγγελιών",
                defaultOrderPreparationTime: "Προεπιλεγμένος χρόνος προετοιμασίας παραγγελίας ",
                autoAcceptPendingTimeInSeconds: "Προεπιλεγμένος χρόνος αυτόματης αποδοχής για εκκρεμείς παραγγελίες",
                refreshTimeInSeconds: "Χρόνος ανανέωσης",
                seconds: "δευτ.",
                showPendingAndWorkingOrdersTogether:
                    "Εμφάνιση Εκκρεμών και Εργασίας Παραγγελιών Μαζί",
                orderColor: "Χρώματα",
                pendingOrderColor: "Εκκρεμής",
                workingOrderColor: "Σε εξέλιξη",
                doneOrderColor: "Ολοκληρώθηκε",
                modifierColor: "Χρώμα Τροποποιητή",
                orderCommentColor: "Χρώμα Σχολίου Παραγγελίας",
                itemNoteColor: "Χρώμα Σημείωσης Προϊόντος",
                allergiesColor: "Χρώμα Αλλεργιών",
                minutes: "λεπτ.",
                showPrice: "Εμφάνιση τιμής"
            }
        },
        pickup: {
            label: "Οθόνη Παραλαβής Παραγγελιών",
            fullscreen: "Πλήρης Οθόνη",
            close: "Κλείσιμο Πλήρους Οθόνης",
            preparing: "ΠΡΟΕΤΟΙΜΑΣΙΑ",
            ready: "ΕΤΟΙΜΟ",
            settings: {
                label: "Ρυθμίσεις",
                px: "px",
                preparingOrder: "Παραγγελία Προετοιμασίας",
                readyOrder: "Έτοιμη Παραγγελία",
                backgroundColor: "Χρώμα Φόντου",
                fontColor: "Χρώμα Γραμματοσειράς",
                headerFontColor: "Χρώμα Κεφαλίδας Γραμματοσειράς",
                font: "Γραμματοσειρά",
                fontSize: "Μέγεθος Γραμματοσειράς",
                headerFontSize: "Μέγεθος Γραμματοσειράς Κεφαλίδας"
            }
        },
        pda: {
            label: "PDA",
        },
        floorPlanning: {
            label: "Floor Planner",
            addSection: "Add Section",
            saveSection: "Save Section",
            drawer: {
                sectionDetails: "Section Details",
                totalCapacity: "Total Capacity",
                equipments: "Tables",
                sectionName: "Section Name",
                tableOptions: "Table Options",
                tableOptionsDesc: "Drag and drop your tables, name them and pick your minimum and maximum covers counts.",
                shapeOptions: "Shape Options",
                shapeOptionsDesc: " Easily drag and drop your shapes, name them and pick colors.",
                minCovers: "Min Covers",
                maxCovers: "Max Covers",
                online: "Online",
                uploadImage: "Ανέβασμα Φωτογραφίας"
            },
            updateSectionName: {
                success: "Section name updated successfully!"
            }
        }
    },
    workingHours: {
        monday: "Δευτέρα",
        tuesday: "Τρίτη",
        wednesday: "Τετάρτη",
        thursday: "Πέμπτη",
        friday: "Παρασκευή",
        saturday: "Σάββατο",
        sunday: "Κυριακή",
        start: "Έναρξη",
        end: "Λήξη",
        open: "Εργασία;",
        working: "Εργάζεται",
        break: "Διάλειμμα",
        equalTimeError: "Παρακαλώ εισάγετε έγκυρη ώρα. Οι ώρες έναρξης και λήξης δεν μπορούν να είναι ίδιες.",
        endTimeError: "Παρακαλώ εισάγετε έγκυρη ώρα. Η ώρα λήξης δεν μπορεί να είναι πριν από την ώρα έναρξης."
    },
    assets: {
        dragNDropMessage: "Σύρετε τα αρχεία εδώ ...",
        dragNDropEmptyMessage:
            "Σύρετε και αποθέστε μερικά αρχεία εδώ, ή κάντε κλικ για να επιλέξετε αρχεία",
        delete: "Διαγραφή φωτογραφίας",
        hidden: "Απόκρυψη φωτογραφίας",
        cover: "Ορισμός φωτογραφίας εξωφύλλου",
        crop: "Περικοπή & αποθήκευση",
        allowImageFilesOnly: "Επιτρέπονται μόνο αρχεία εικόνας",
        imageSizeLimitError: "Επιτρέπονται μόνο αρχεία εικόνας μικρότερα από 2MB.",
        hue: "Χρώμα",
        saturation: "Κορεσμός",
        brightness: "Φωτεινότητα",
        contrast: "Αντίθεση",
        reset: "Επαναφορά",
        emptyPhotos: "Φαίνεται ότι δεν υπάρχουν διαθέσιμες φωτογραφίες.",
        configurePhotos: "Ρύθμιση φωτογραφιών",
        uplooadImage: " Ανέβασμα φωτογραφίας"
    },
    email: {
        from: "ΑΠΟ:",
        to: "Προς:",
        cc: "Κοινοποίηση:",
        bcc: "Κρυφή κοινοποίηση:",
        subject: "Θέμα",
    },
    socialProfile: {
        label: "Κοινωνικά Προφίλ",
        FACEBOOK: "Facebook",
        INSTAGRAM: "Instagram",
        LINKEDIN: "Linkedin",
        PINTEREST: "Pinterest",
        SNAPCHAT: "Snapchat",
        TIKTOK: "TikTok",
        TWITTER: "X",
        YOUTUBE: "Youtube"
    },
    upload: {
        buttonText: 'Σύρετε το αρχείο εδώ ή κάντε κλικ για μεταφόρτωση'
    },
    languages: {
        label: "Γλώσσα",
        en: "Αγγλικά",
        de: "Γερμανικά",
        fr: "Γαλλικά",
        it: "Ιταλικά",
        ru: "Ρωσικά",
        el: "Ελληνικά",
        uk: "Ουκρανικά",
        es: "Ισπανικά",
        nl: "Ολλανδικά",
        cs: "Τσεχικά",
        pl: "Πολωνικά",
        bg: "Βουλγαρικά",
        ar: "Αραβικά",
        et: "Εσθονικά",
        lt: "Λιθουανικά",
        ro: "Ρουμανικά",
        tr: "Τουρκικά",
    },
    ///new
    orders: {
        home: "Ζωντανά",
        pastOrders: "Παλαιές Παραγγελίες",
        minimizeOrders: "Ελαχιστοποίηση Παραγγελιών",
        maximizeOrders: "Μεγιστοποίηση Παραγγελιών",
        moveToDone: "Μετακίνηση σε εκτέλεση",
        complete: "Ολοκλήρωση",
        assignTimeDialog: {
            heading: "Ανάθεση Ώρας",
            text: "Αναθέστε ώρα σε αυτήν κάνοντας κλικ στο κουμπί",
            minute: "Λεπτό",
            custom: "Προσαρμοσμένο",
            customInputPlaceholder: "Ώρα σε λεπτά",
            submit: "Υποβολή",
        },
        cancelOrder: "Ακύρωση Παραγγελίας",
        cancelMessage: "Είστε σίγουροι ότι θέλετε να ακυρώσετε την παραγγελία",
        empty: "Φαίνεται ότι δεν υπάρχουν Παραγγελίες.",
        no: "Όχι",
        yes: "Ναι",
        lackOfProduct: "Έλλειψη προϊόντος",
        lackOfProductDialog: {
            heading: "Έλλειψη προϊόντος στην παραγγελία",
            availableQuantity: "Διαθέσιμη Ποσότητα",
            cancel: "Ακύρωση",
            update: "Ενημέρωση",
        },
        pendingTitle: "Σε αναμονή",
        workingTitle: "Σε εξέλιξη",
        pendingWorking: "Σε αναμονή / Σε εξέλιξη",
        pendingEmpty: "Χωρίς Παραγγελίες σε αναμονή",
        workingEmpty: "Χωρίς Παραγγελίες σε εξέλιξη",
        totalItems: "Σύνολο Ειδών:",
        refresh: "Ανανέωση",
    },
    analytics: {
        label: "Αναλύσεις",
        cart: {
            name: "Όνομα Προϊόντος",
            productRevenue: "Έσοδα Προϊόντος",
            uniquePurchasesCount: "Μοναδικός Αριθμός Αγορών",
            quantity: "Ποσότητα",
            avgQuantity: "Μέση Ποσότητα",
            avgPrice: "Μέση Τιμή",
            emptyMessage: "Δεν υπάρχουν διαθέσιμα δεδομένα"
        },
        chart: {
            paymentMethods: {
                labelCounts: "Πλήθος Μεθόδων Πληρωμής",
                label: "Μεθόδοι Πληρωμής"
            },
            revenue: {
                totalOrders: "Σύνολο Παραγγελιών",
                totalRevenue: "Συνολικά Έσοδα",
                sec: "δευτ.",
                min: "λεπτ.",
                hour: "ώρα",
                day: "ημέρα",
                month: "μήνας",
                year: "έτος",
            },
            employees: {
                totalOrders: "Σύνολο Παραγγελιών Εργαζομένου",
                totalRevenue: "Συνολικά Έσοδα Εργαζομένου",
                yTitle: "Εργαζόμενοι",
                dataLabel: "Παραγγελίες"
            },
            customers: {
                totalOrders: "Σύνολο Παραγγελιών Πελάτη",
                totalRevenue: "Συνολικά Έσοδα Πελάτη",
                yTitle: "Πελάτες",
                dataLabel: "Παραγγελίες"
            },
            ratings: {
                basedOnBefore: "Βασισμένο σε",
                basedOnAfter: "αξιολογήσεις"
            },
            noData: "Παρακαλώ προσαρμόστε τα φίλτρα σας, δεν υπάρχουν δεδομένα για εμφάνιση.",
        },
        tabs: {
            general: "Γενικά",
            items: "Προϊόντα",
            employees: "Υπάλληλοι",
            revenue: "Έσοδα",
            extra: "Επιπλέον",
            ratings: "Αξιολογήσεις"
        }
    },
    rating: {
        label: "Αξιολογήσεις",
        subtitle: " ",
        outletName: "Όνομα Καταστήματος",
        emptyOutlet: "Δεν υπάρχουν αξιολογήσεις γι' αυτό το κατάστημα",
        emptyRatings: "Δεν έχουν προστεθεί ακόμα αξιολογήσεις.",
        moreRatings: "Περισσότερες Αξιολογήσεις",
    },
    filter: {
        tooltip: {
            title: "Φιλτράρισμα Πίνακα"
        }
    },
    cart: {
        label: "Καλάθι",
        emptyLabel: "Κενό Καλάθι",
        emptyDescription: "Συμπληρώστε το καλάθι σας με προϊόντα.",
        options: {
            delivery: "Παράδοση",
            dineIn: "Κατανάλωση στο Κατάστημα",
            pickUp: "Παραλαβή"
        },
        discount: "Έκπτωση",
        totalAmmount: "Συνολικό ποσό",
        analyzeOrder: "Ανάλυση παραγγελίας",
        allergies: {
            label: "Αλλεργίες",
            celery: "Σέλινο",
            eggs: "Αυγά",
            fish: "Ψάρι",
            gluten: "Γλουτένη",
            lupin: "Λουπίνι",
            milk: "Γάλα",
            molluscs: "Μαλάκια",
            mustard: "Μουστάρδα",
            nuts: "Ξηροί καρποί",
            soya: "Σόγια",
            sesame: "Σουσάμι",
            wheat: "Σιτάρι",
            pleaseSelectAllThatApply: "Επιλέξτε όλα όσα ισχύουν"
        },
        item: {
            notes: "Σημειώσεις"
        },
        addToCart: "Προσθήκη στο Καλάθι",
        equipmentLabel: "Τραπέζι:",
        order: {
            label: "Παραγγελία",
            confirmed: "Επιβεβαιώθηκε",
        },
        menus: "Μενού:",
        parentCategories: "Γονικές Κατηγορίες:",
        categories: "Κατηγορίες:",
        items: "Προϊόντα:",
        orderType: {
            label: "Τύπος",
            DINE_IN: "Κατανάλωση Στο Κατάστημα",
            PICKED_UP: "Παραλαβή"
        },
        backButton: "Πίσω στα Μενού",
        persons: "Άτομα"
    },
    checkout: {
        label: "Ολοκλήρωση Παραγγελίας",
        backButton: "Πίσω",
        paymentTotal: "Συνολικό ποσό πληρωμής",
        paymentMethod: "Μέθοδος πληρωμής: ",
        cart: "Καλάθι",
        persons: "Άτομα",
        pvcRecyclingTax: "Περιβαλλοντικό τέλος PVC περιλαμβάνεται",
        discount: "Έκπτωση:",
        loyalty: "Πιστότητα",
        coupon: "Κουπόνι:",
        comments: "Σχόλια",
        info: "Συμπληρώστε με ένα σύντομο κείμενο...",
        requestCharLimit: "χαρ",
        requestCharsLimit: "χαρ",
        requestPlaceholder: "Πληκτρολογήστε εδώ...",
        buttons: {
            sendOrder: "Αποστολή παραγγελίας",
            payOrder: "Πληρωμή παραγγελίας"
        },
        payment: {
            paymentMethods: "Μέθοδοι Πληρωμής",
            card: "Κάρτα",
            paypal: "Paypal",
            cash: "Μετρητά",
            pos: "POS",
        },
        equipmentInvalid: "Το τραπέζι δεν βρέθηκε ή δεν είναι ενεργή. Παρακαλώ προσπαθήστε ξανά!",
        tax: "ΦΠΑ:",
        loyaltyPointsToEarn: "Αν συνεχίσετε, θα κερδίσετε",
        points: "πόντους"
    },
    pda: {
        label: "PDA",
        backButton: "Πίσω στο PDA",
        backButtonAddOderDialog: "Ακύρωση",
        backButtonEqDialog: "Πίσω",
        emptyMySections: "Φαίνεται ότι δεν έχετε ορίσει ακόμη καμία Τμήματα μου. Παρακαλώ επισκεφθείτε τα Τμήματα μου και ενημερώστε τα για να βελτιώσετε την εμπειρία σας.",
        emptyMySectionsButton: "Μετάβαση στα Τμήματα μου",
        emptyOrder: "Φαίνεται ότι δεν υπάρχουν περισσότερες παραγγελίες.",
        moreOrders: "Φόρτωση περισσότερων",
        unPaidOrderButton: "Πληρωμή",
        backToUnpaidOrders: "Πίσω",
        dialogPay: {
            title: "Επιλογή Ποσότητας για Πληρωμή",
            info: "Ποσότητα για πληρωμή ",
            max: "Μέγιστο"
        },
        coupon: "Κουπόνι",
        paySelectedItems: "Πληρωμή επιλεγμένων αντικειμένων",
        paySelectedItemsAndClose: "Πληρωμή επιλεγμένων αντικειμένων & κλείσιμο",
        pay: "Πληρωμή",
        payAll: "Πληρωμή όλων",
        payAndClose: "Πληρωμή & Kλείσιμο",
        payAllAndClose: "Πληρωμή όλων & κλείσιμο",
        viewAll: "Προβολή όλων",
        back: "Πίσω",
        order: {
            empty: "Δεν έχετε ακόμα παραγγελίες",
            info: "Κάντε κλικ για να δείτε περισσότερες λεπτομέρειες",
            view: "Προβολή Παραγγελιών",
            viewSingle: "Προβολή Παραγγελίας",
            viewUnpaid: "Προβολή Ανεξόφλητων Παραγγελιών",
            create: "Δημιουργία Παραγγελίας"
        },
        tabsView: {
            info: "Πληροφορίες",
            cart: "Καλάθι",
            status: "Κατάσταση",
            payment: "Πληρωμή"
        },
        remainToPay: "Υπολειπόμενο πληρωμής",
        payment: {
            treat: "Είναι κέρασμα;",
            method: "Μέθοδος πληρωμής",
            label: "Ιστορικό Πληρωμών",
            card: "Κάρτα",
            paypal: "Paypal",
            cash: "Μετρητά",
            treat: "Είναι κέρασμα;",
            emptyPaymentHistory: "Φαίνεται ότι δεν υπάρχει ακόμη ιστορικό πληρωμών."
        },
        payCloseAll: {
            label: "Έχετε επιλέξει να κλείσετε όλα τα τραπέζια. Η προεπιλεγμένη μέθοδος πληρωμής είναι Μετρητά. Αν θέλετε να κάνετε αλλαγές, παρακαλώ επιλέξτε το πλαίσιο 'Προσαρμοσμένες Επιλογές'.",
            advanceCheckboxLabel: "Προσαρμοσμένες Επιλογές",
            btnTooltipDefault: "Κλείσιμο όλων των ανοιχτών τραπεζιών και ολοκληρώση των πληρωμών."
        }
    },
    offer: {
        title: "Προσφορές",
        pageTitle: "Προσφορά",
        subtitle: " ",
        emptyMessage: "Δεν βρέθηκαν προσφορές.",
        enabled: "Ενεργοποιημένο",
        disabled: "Απενεργοποιημένο",
        all: "Όλα",
        price: "Τιμή",
        percentage: "Ποσοστό Έκπτωσης",
        "%": "%",
        "€": "€",
        loyaltyPoints: "Πόντοι",
        points: "πόντοι",
        pvcRecyclingTax: "Φόρος ανακύκλωσης PVC συμπεριλαμβανομένου",
        categories: "Κατηγορίες",
        type: {
            label: "Τύπος",
            OFFER: "Προσφορά",
            COMBO: "Combo",
            HAPPY_HOUR: "Happy Hour",
            ONE_PLUS_ONE: "1+1"
        },
        tabs: {
            details: "Λεπτομέρειες",
            photos: "Φωτογραφίες",
            hours: "Διαθέσιμες Ώρες"
        }
    },
    marketing: {
        template: {
            title: "Πρότυπο Email",
            pageTitle: "Πρότυαπα Email",
            subtitle: " ",
            name: "'Ονομα'",
            noEmailTemplates: "Δεν βρέθηκαν πρότυπα email.",
            showAdvanceEditor: "Εμφάνιση προχωρημένου επεξεργαστή"
        },
        recipient: {
            pageTitle: "Παραλήπτες",
            title: "Παραλήπτης",
            subtitle: " ",
            age: "Ηλικία",
            birthdate: "Ημ/νια Γενεθλίων",
            empty: "Δεν βρέθηκαν παραλήπτες που να ταιριάζουν με τα φίλτρα.",
            group: {
                pageTitle: "Ομάδες Παραληπτών",
                title: "Ομάδα Παραληπτών",
                subtitle: " ",
                autoUpdate: "Αυτόματη ενημέρωση",
                userGroupType: {
                    label: "Τύπος",
                    ALL: "Όλα",
                    EMAIL: "Email",
                    SMS: "Μηνύματα",
                    NONE: "Κανένα"
                },
                import: {
                    missingRows: "Λείπουν υποχρεωτικά πεδία στις $rows γραμμή(ες)."
                }
            }
        },
        campaign: {
            reset: 'Επαναφορά',
            sms: {
                pageTitle: "Καμπάνιες Μηνυμάτων",
                title: "Καμπάνια Μηνυμάτων",
                subtitle: " ",
                sms: "SMS",
                smsLength: "Μέγεθος Μηνύματος",
                placeholder: "Εισαγωγή μηνύματος",
                message: "Μήνυμα",
                to: "Προς",
                messageCharLimit: "χαρ",
                messageCharsLimit: "χαρ",
                scheduled: "Προγραμματισμός",
                scheduledDateTime: "Αποστολή στις:",
            },
            email: {
                pageTitle: "Καμπάνιες Email",
                title: "Καμπάνια Email",
                subtitle: " ",
                subject: "Θέμα",
                body: "Email",
                message: "Μήνυμα",
                to: "Προς",
                scheduled: "Προγραμματισμός",
                scheduledDateTime: "Αποστολή στις:",
            }
        }
    },
    waitlist: {
        label: "Λίστα Αναμονής",
        enabledWaitlistRequirements: {
            label: "Ενεργοποιημένες Επιλογές Απαιτήσεων Λίστας Αναμονής",
            SMOKING: "Καπνίζοντες",
            NON_SMOKING: "Μη Καπνίζοντες",
            INFANT_SEAT: "Κάθισμα Μωρού",
            VIEW: "Θέα",
            SEA_VIEW: "Θέα στη Θάλασσα",
            INDOOR: "Μέσα",
            OUTDOOR: "Έξω",
            BAR: "Μπαρ",
            TABLE: "Τραπέζι",
            POOL: "Πισίνα",
            BIRTHDAY: "Γενέθλια",
            ANNIVERSARY: "Επέτειος",
            ALLERGIES: "Αλλεργίες",
            VIP: "VIP",
            VVIP: "VVIP",
            LOYAL: "Πιστότητα"
        },
        enabledWaitilistTags: {
            label: "Ενεργοποιημένες Ετικέτες Λίστας Αναμονής",
            FOOD: "Φαγητό",
            DRINK: "Ποτό"
        },
        exportBtn: "Εξαγωγή QRcode PDF για τη Λίστα Αναμονής",
        generateBtn: "Δημιουργία QRcode για τη Λίστα Αναμονής",
        generateQRcodeSuccess: "Το QRcode για τη Λίστα Αναμονής δημιουργήθηκε με επιτυχία. Κάντε ξανά κλικ για εξαγωγή.",
        selectTemplateWaitlist: "Επιλέξτε το πρότυπο που θέλετε για το στυλ του QRcode της Λίστας Αναμονής: ",
        selectTemplateWaitlistEmpty: "Δεν υπάρχουν διαθέσιμα πρότυπα QRcode. Κάντε κλικ για να προσθέσετε ένα."
    },
    chat: {
        tabs: {
            mine: "Δικά Μου",
            all: "Όλα"
        },
        channel: {
            name: "Όνομα",
            placeHolderName: "Όνομα καναλιού",
            color: "Χρώμα",
            image: "Εικόνα",
            uploadImage: "ΑΝΕΒΑΣΤΕ ΜΙΑ ΕΙΚΟΝΑ",
            urgent: "Σήμανση ως Επείγον;",
            selectNow: "Επιλέξτε τώρα",
            selectOne: "Επιλέξτε μια συνομιλία",
            emptyLabel: "Δεν υπάρχουν διαθέσιμες συνομιλίες."
        }
    },
    glabal: {
        fontSizeAfterAdorment: "px"
    },
    date: {
        start: "Ημερομηνία Έναρξης",
        end: "Ημερομηνία Λήξης",
        today: "Σήμερα",
        lastWeek: "Προηγούμενη Εβδομάδα",
        last7Days: "Τελευταίες 7 Ημέρες",
        currentMonth: "Τρέχων Μήνας",
        lastMonth: "Προηγούμενος Μήνας",
        currentYear: "Τρέχον Έτος",
        reset: "Επαναφορά"
    },
    integrations: {
        oxygenConfiguration: {
            label: "Oxygen",
            token: "Token"
        }
    }
}

export default EL;
