import * as moment from "moment";
function formatDateTime(dateTime) {
    const formattedDateTime = moment(dateTime).format(
        "YYYY-MM-DDTHH:mm:ss.SSSSSS"
    );

    return formattedDateTime;
}

export default formatDateTime;
