import { Box } from '@mui/material';
import html2canvas from 'html2canvas';
import { useEffect, useRef, useState } from 'react';

/**
 * HtmlThumbnailPreview Component
 *
 * This component generates a thumbnail image preview from a given HTML string. It uses html2canvas to
 * capture the rendered HTML content as an image and displays the image in an MUI Box component.
 *
 * Props:
 * @param {string} htmlContent - The HTML string to be rendered and converted into a thumbnail.
 * 
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
const HtmlThumbnailPreview = ({ htmlContent }) => {
  // State to hold the thumbnail image source
  const [thumbnailSrc, setThumbnailSrc] = useState(null);

  // Ref for the hidden container where the HTML is rendered
  const previewRef = useRef(null);

  /**
   * Effect to generate the thumbnail whenever the htmlContent changes
   */
  useEffect(() => {
    if (previewRef.current && htmlContent) {
      const element = previewRef.current; // Get the reference to the hidden div

      // Use html2canvas to capture the HTML as an image
      html2canvas(element, { scale: 1 }) // Adjust scale for thumbnail resolution
        .then((canvas) => {
          // Convert the canvas content to a Base64 image
          const imgData = canvas.toDataURL('image/png');
          setThumbnailSrc(imgData); // Update the thumbnail source state
        })
        .catch((err) => console.error('Error capturing thumbnail:', err)); // Handle errors
    }
  }, [htmlContent]);

  return (
    <Box>
      {/* Hidden div to render the HTML content for capturing */}
      <div
        ref={previewRef}
        style={{
          position: 'absolute', // Position off-screen
          top: '-9999px',
          left: '-9999px',
          width: '610px', // Set a fixed maximum width for rendering
          height: 'auto', // Set a fixed maximum height for rendering
          overflow: 'hidden',
        }}
        // Inject the HTML string safely
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      />

      {/* Thumbnail Preview Box */}
      {thumbnailSrc && (
        <Box
          component="img"
          src={thumbnailSrc} // Thumbnail image source
          alt="HTML Thumbnail Preview" // Alt text for accessibility
          sx={{
            width: "100%", // Set the maximum width of the thumbnail
            height: 295, // Set the maximum height of the thumbnail
            borderRadius: "0 0 20px 20px ", // Add rounded corners
            overflow: 'hidden', // Ensure content fits within the box
            objectFit: 'contain', // Ensure content is contained within bounds
            display: 'flex',
            justifyContent: 'center', // Center content horizontally
            alignItems: 'flex-start', // Align content to the top
          }}
        />
      )}
    </Box>
  );
};

export default HtmlThumbnailPreview;