import {
    Add as AddIcon,
    Delete as DeleteIcon,
    Save as SaveIcon, SignLanguage as SignLanguageIcon,
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
    WorkOff as WorkOffIcon
} from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';
import { Dialog, DialogContent, Divider, Fab, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, SvgIcon, Switch, TextField, Typography, useMediaQuery } from "@mui/material";
import { Box } from '@mui/system';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import userApi from "api/user";
import CustomAddEditBreadcrumbMultiParent from 'components/Breadcrumb/CustomAddEditBreadcrumbMultiParent';
import CustomIconButton from 'components/Button/CustomIconButton';
import DeleteAccountCard from 'components/Card/account/DeleteAccountCard';
import DeleteAccountDeleteCard from 'components/Card/account/DeleteAccountDeleteCard';
import CustomSwippableDrawer from 'components/Drawer/CustomSwippableDrawer';
import CustomFieldset from "components/Fieldset/CustomFieldset";
import CustomGrid from 'components/Grid/CustomGrid';
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomObjectSelect from 'components/Select/CustomObjectSelect';
import CustomSelectMultilingual from 'components/Select/CustomSelectMultilingual';
import CustomSelectObjectWithChipAndIconUserOutlet from 'components/Select/CustomSelectObjectWithChipAndIconUserOutlet';
import CustomSelectWithChip from 'components/Select/CustomSelectWithChip';
import CustomSnackbar from "components/Snackbar/CustomSnackbar";
import CustomTabs from 'components/Tab/CustomTabs';
import CustomTextArea from 'components/Text/CustomTextArea';
import CustomTextField from 'components/Text/CustomTextField';
import CustomTextFieldWithIcons from 'components/Text/CustomTextFieldWithIcons';
import CustomTitleBoldSubtitle from "components/Title/CustomTitleBoldSubtitle";
import { getDefaultUserObject, getWorkingHourDay } from 'constants/defaultObjects';
import { getRoleOptions } from 'constants/roleOptions';
import { getThemeColor } from 'constants/theme';
import { getWeekDays } from 'constants/weekdays';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/material.css';
import { useNavigate, useParams } from "react-router-dom";
import authService from 'services/auth.service';
import { addCardActionAreaButtonStyle, addWorkingHoursButtonIconStyle, autDrawerPaperStyle, cardItemButtonGroupStackButton, cardItemButtonGroupStackButtonIcon, fabStyle, formSaveButton, mainArea, mainTag, mainTagBreadcrumb, mainTagTitle, selectError, textFieldError } from 'themes/defaultThemes';
import { hasRoleAdmin, hasRoleAdminOrDirectorOrManager, hasRoleAdminOrgOrDirector } from "utils/auth";
import { formIsValid, formValChangeWithParentElement, formValChangeWithParentElementObjectValues, formValChangeWithParentElementWithNameAndValue, onControlChange, onControlChangeWithParent, onSelectChange, onSelectChangeWithParents } from "utils/form-validation";
import { equalsIgnoreCase, isValidEmail, validatePhoneNumber } from "utils/functions";
import { generateCode } from 'utils/functions/account';
import { getLanguageFromURL } from "utils/language";
import { strengthColor, strengthIndicatorNumFunc } from 'utils/password-strength';
import { getTheme } from 'utils/theme';
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The UserAddEditComponent that triggers the creation of a 
 * new user or the modification of an existing one.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function UserAddEditComponent() {
    const { t } = useTranslation();
    const { organizationID } = useParams();
    const { outletID } = useParams();
    const { userID } = useParams();
    const navigate = useNavigate();

    const isMobile = useMediaQuery('(max-width:468px)');

    let path = `/${getLanguageFromURL()}`
    if (organizationID !== undefined) path += `/organization/${organizationID}`;
    if (outletID !== undefined) path += `/outlet/${outletID}`;
    path += `/user`;

    // const { handleChangeUser } = useContext(authContext);

    const weekDays = getWeekDays();
    // to render DOM based on rest api call status, if true the rest api call completed
    const [redirect, setRedirect] = useState(false);
    // if true the menu item is clicked and a redirect to page should perform
    const [isLoaded, setIsLoaded] = useState(false);

    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313"
    });

    const [tabValue, setTabValue] = React.useState("personalDetails");

    // for password strength
    const [strength, setStrength] = React.useState(0);// eslint-disable-line no-unused-vars


    
    const [level, setLevel] = React.useState();

    /**
     * @type {object}
     * @property {boolean} isAdd to define if requested an edit or an add of a user
     * @property {boolean} redirect if a redirect should be performed
     * @property {string} error holdes the error message of a failed rest api call
     * @property {object} userAuth the authenticated user infromation
     * @property {string} userID tha user id to get user current information for edit user page
     * @property {object} user an empty object if add or the selected user to be editted
     * @property {*} icon the legend fa icon
     * @property {array} roles the different roles a user can have
     * @property {array} countries a list of the different countries
     * @property {object} isError list of indication of empty form required field 
     *                           after submit to present error message to the
     *                           fields/attributes with true value
     */
    const [data, setData] = useState({
        error: null,
        userAuth: null,
        isAdd: userID === undefined,
        roles: getRoleOptions(),
        userID: userID,
        user: null,
        organization: {
            id: organizationID,
            name: ""
        },
        outlet: {
            id: outletID,
            name: ""
        },
        outletIds: [],
        countries: [],
        outlets: [],
        webNotificationActionsEnabled: [],
        emailNotificationActionsEnabled: [],
        mobileNotificationActionsEnabled: [],
        languages: [],
        permissions: [],
        passwordShown: false,
        shownPasswordIcon: VisibilityOffIcon,
        // error messages per field
        isError: {
            username: "",
            firstName: "",
            lastName: "",
            // fatherName: "",
            email: "",
            phone: "",
            roles: "",
            newPassword: "",
            verifyPassword: "",
            outletIds: "",
            preferedLanguage: ""
        }
    });
    const [open, setOpen] = useState(false);
    const deleteCode = generateCode();
    const [deleteIsClicked, setDeleteIsClicked] = useState(false);// eslint-disable-line no-unused-vars


    //change document title
    useDocumentTitle(`Usee  ${organizationID !== undefined ? " | " + t('org.pageTitle') : ""} ${outletID !== undefined ? " | " + t('outlet.pageTitle') : ""} | ${t('page.users')}`);

    useEffect(() => {
        getUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Password toggle handler
     */
    function togglePassword() {
        // When the handler is invoked
        // inverse the boolean state of passwordShown and icon to be shown
        setData({
            ...data,
            shownPasswordIcon: !data.passwordShown ? VisibilityIcon : VisibilityOffIcon,
            passwordShown: !data.passwordShown
        });
    };

    /**
     * The rest endpoint to get the user default (add) or current (edit) information.
     */
    function getUser() {
        if (data.isAdd) {
            userApi.fetchNew(organizationID, outletID).then((r) => {
                setData({
                    ...data,
                    countries: r.data.returnobject.countryList,
                    userAuth: r.data.returnobject.userAuth,
                    user: {
                        organizationID: organizationID,
                        outletIds: outletID === undefined ? undefined : [outletID],
                        ...getDefaultUserObject()
                    },
                    roles: r.data.returnobject.roles,
                    outlets: r.data.returnobject.outlets,
                    organization: r.data.returnobject.organization,
                    outlet: r.data.returnobject.outlet,
                    languages: r.data.returnobject.languages,
                    permissions: r.data.returnobject.permissions,
                    webNotificationActionsEnabled: r.data.returnobject.webNotificationActionsEnabled,
                    emailNotificationActionsEnabled: r.data.returnobject.emailNotificationActionsEnabled,
                    mobileNotificationActionsEnabled: r.data.returnobject.mobileNotificationActionsEnabled
                });
                setIsLoaded(true);
            }).catch((e) => {
                // console.log(e);
            })
        } else {
            userApi.fetchOne(organizationID, outletID, userID).then((r) => {
                setData({
                    ...data,
                    countries: r.data.returnobject.countryList,
                    userAuth: r.data.returnobject.userAuth,
                    user: {
                        ...r.data.returnobject.user,
                        newPassword: "",
                        verifyPassword: ""
                    },
                    roles: r.data.returnobject.roles,
                    outlets: r.data.returnobject.outlets,
                    organization: r.data.returnobject.organization,
                    outlet: r.data.returnobject.outlet,
                    languages: r.data.returnobject.languages,
                    permissions: r.data.returnobject.permissions,
                    webNotificationActionsEnabled: r.data.returnobject.webNotificationActionsEnabled,
                    emailNotificationActionsEnabled: r.data.returnobject.emailNotificationActionsEnabled,
                    mobileNotificationActionsEnabled: r.data.returnobject.mobileNotificationActionsEnabled
                });
                setIsLoaded(true);
            }).catch((e) => {
                // console.log(e);
            })
        }
    }

    /**
     * Gets called when the user clicks on the save button, and triggers 
     * the creation of the new user.
     */
    function addUser() {
        if (formValidation()) {
            let user = data.user;
            user.password = undefined;

            userApi.create(user).then((r) => {
                Promise.resolve()
                    .then(() => {
                        setAlertBox({
                            isOpen: true,
                            message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                            backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                        });
                    })
                    .then(() => {
                        if (r.data.code === "INVALID") {
                            let field = r.data.message.split(" ")[0].toLowerCase();
                            setData({
                                ...data,
                                isError: {
                                    ...data.isError,
                                    [field]: t(`error.${field}`)
                                }
                            });
                        } else if (r.data.code === "SUCCESS")
                            setRedirect((hasRoleAdmin(data.userAuth.roles) ? true : false));
                    })
            }).catch((e) => {
                // console.log(e)
            });
        }
    }

    /**
     * Gets called when the user clicks on the save button, and triggers 
     * the edit of the selected user.
     */
    function editUser() {
        if (formValidation()) {
            let user = data.user;
            user.password = undefined;
            if (user.currentPassword === "")
                user.currentPassword = undefined;
            if (user.newPassword === "")
                user.newPassword = undefined;
            if (user.verifyPassword === "")
                user.verifyPassword = undefined;

            userApi.update(user).then((r) => {
                setAlertBox({
                    isOpen: true,
                    message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                    backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                });

                // if (r.data.code === "SUCCESS") {
                //    let token = r.data.cookie.value;
                //    if (r.data.cookie) {
                //       token = token;
                //       localStorage["token"] = token;
                //       localStorage.setItem("token", token);
                //       cookiesService.setCookie(token);
                //    }
                // }
                // setRedirect((hasRoleAdmin(data.userAuth.roles) && r.data.code === "SUCCESS" ? true : false));
            }).catch((e) => {
                // console.log(e)
            });
        }
    }

    function handleStartEndChange(day, index, field, value) {
        const newWorkingHours = { ...data.user.workingHours };
        newWorkingHours[day][index][field] = value;
        formValChangeWithParentElementObjectValues("user.workingHours", newWorkingHours, data, setData);
    }

    const handleOpenChange = (day, index, value) => {
        const newWorkingHours = { ...data.user.workingHours };
        newWorkingHours[day][index].open = value;
        formValChangeWithParentElementObjectValues("user.workingHours", newWorkingHours, data, setData);
    };

    const handleAddStartEnd = (day) => {
        const newWorkingHours = { ...data.user.workingHours };
        newWorkingHours[day].push(getWorkingHourDay());
        formValChangeWithParentElementObjectValues("user.workingHours", newWorkingHours, data, setData);
    };

    function removeWorkingHour(day, index) {
        let newwWorkingHours = { ...data.user.workingHours };
        newwWorkingHours[day].splice(index, 1);
        formValChangeWithParentElementObjectValues("user.workingHours", newwWorkingHours, data, setData);
    }

    /**
     * Function that triggers the phone value change.
     * @param {string} newPhone the update phone value
     */
    function onPhoneChange(newPhone) {
        setData({
            ...data,
            user: {
                ...data.user,
                phone: newPhone
            }
        });
    }

    /**
     * Function that triggers the removal of a role.
     * @param {*} chipToDelete 
     */
    function removeRole(event, roleToDelete) {
        let roles = data.user.roles;
        const index = roles.indexOf(roleToDelete);
        // only splice array when item is found
        if (index > -1) roles.splice(index, 1); // 2nd parameter means remove one item only
        setData({
            ...data,
            user: {
                ...data.user,
                roles: roles
            }
        });
    }

    /**
     * Function that triggers form validation and print out if the form is valid or not.
     * @returns true if form is Valid
     */
    function formValidation() {
        let isError = createErrorMessages();
        if (formIsValid(isError)) {
            return true;
        } else {
            return false;
        }
    }


    /**
     * Function that create error messages for each required field that are not filled out.
     * @returns object containing the error messages for each form field
     */
    function createErrorMessages() {
        let isError = { ...data.isError };
        isError.username = data.user.username.length < 1 ? t('table.valueReuired') : "";
        isError.firstName = data.user.firstName.length < 1 ? t('table.valueReuired') : "";
        isError.lastName = data.user.lastName.length < 1 ? t('table.valueReuired') : "";
        isError.email = (data.user.email.length < 1 || !isValidEmail(data.user.email)) ? t('table.valueReuired') : "";
        isError.phone = (data.user?.phone?.length < 10 || !validatePhoneNumber(data.user?.phone)) ? t('table.valueReuired') : "";
        isError.roles = data.user.roles.length < 1 ? t('table.valueReuired') : "";
        isError.preferedLanguage = data.user.preferedLanguage === null || data.user.preferedLanguage.length < 1 ? t('table.valueReuired') : "";

        if (!hasRoleAdmin(data.user.roles) && data.outletIds?.length < 0)
            isError.outletIds = t('table.valueReuired');
        else if (!hasRoleAdmin(data.user.roles) && (data.user.defaultOutletID === "" || data.user.defaultOutletID === null))
            isError.outletIds = t('error.defaultOutletRequired')
        else isError.outletIds = "";

        if (data.isAdd) {
            isError.newPassword = data.user.newPassword.length < 1 ? t('table.valueReuired') : "";
            isError.verifyPassword = data.user.verifyPassword.length < 1 ? t('table.valueReuired') :
                (data.user.verifyPassword !== data.user.newPassword) ? t('auth.passwordsUnMatch') : "";
        } else {
            isError.verifyPassword = ((data.user.verifyPassword !== undefined && data.user.newPassword !== undefined) && (data.user.verifyPassword.length > 1 || data.user.newPassword.length > 1) && data.user.verifyPassword !== data.user.newPassword) ? t('auth.passwordsUnMatch') : "";
        }
        setData({
            ...data,
            isError
        });
        return isError;
    }

    function changePassword(value) {
        const temp = strengthIndicatorNumFunc(value);
        setStrength(temp);
        setLevel(strengthColor(temp));
    };

    /**
     * Function that triggers the removal of an Id.
     * @param {*} idToDelete 
     */
    function removeId(event, idToDelete, field) {
        let ids = data.user[field];
        const index = ids.indexOf(idToDelete);
        // only splice array when item is found
        if (index > -1) ids.splice(index, 1); // 2nd parameter means remove one item only
        setData({
            ...data,
            user: {
                ...data.user,
                [field]: ids
            }
        });
    }

    function changeDefaultOutletOfUser(event, outletID) {
        formValChangeWithParentElementWithNameAndValue("user.defaultOutletID", outletID, data, setData);
    }

    /**
     * Function that handles the snackbar open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }

    function deleteAccountPermanently() {
        // userApi.deleteById(data.userAuth?.id).then((r) => {
        userApi.disable(data.userAuth?.id).then((r) => {
            if (r.data.code === "SUCCESS") {
                handleDialogState(false);
                authService.logout();
                navigate("/success/account/delete");
            }
        }).catch((e) => {
            // console.log(e);
        })
    }

    /**
     * Function that handles the visualize modal open or close state.
     * @param {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleDialogState(isOpen) {
        setOpen(isOpen);
    }

    // ===============================================================================RENDER

    function body() {
        return <DeleteAccountDeleteCard
            handleClose={() => handleDialogState(false)}
            handleDeleteClick={deleteAccountPermanently}
            deleteCode={deleteCode}
        />
    }

    // ===============================================================================RENDER

    if (redirect) {
        navigate(`${path}`);
    } else if ((!isLoaded && !data.isAdd) || data.user == null) {
        return (
            <LoadingSkeleton lines={9} />
        );
    } else {
        let defaultCountrySelected = "";
        if (!data.isAdd && data.user !== null && data.user.country !== null && data.countries?.length > 0) {
            if (data.user !== undefined && data.user.address !== undefined && data.user.address.country !== null)
                defaultCountrySelected = data.countries.find(country => equalsIgnoreCase(country.id, data.user.address.country.id)).id;
        }
        let defaultRolesSelected = [];
        if (data.roles !== null && data.user !== null && data.user.roles !== null && data.roles.length > 0 && data.user.roles.length > 0) {
            defaultRolesSelected = data.user.roles;
        }
        /* eslint-disable no-unused-vars */
        let defaultOutletIds = [];
        if (data.outlets !== null && data.user !== null && data.user.outletIds !== null && data.outlets.length > 0 && !data.isAdd) {
            defaultOutletIds = data.outletIds.find(id => data.user.outletIds.includes(id));
        }
        /* eslint-enable no-unused-vars */
        const { isError } = data;

        return (
            <CustomGrid role="main" id={"user-" + data.isAdd ? "add" : "edit"} sx={mainTag()}>
                {(outletID !== undefined && organizationID !== undefined && hasRoleAdminOrDirectorOrManager(data.userAuth?.roles)) &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                                { name: t('outlet.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet` },
                                { name: data.outlet.name, url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet/${outletID}` },
                                { name: t('uuser.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet/${outletID}/user` }
                            ]}
                            instanceName={(data.user !== null) ? data.user.username : ""}
                        />
                    </CustomGrid>
                }

                {(outletID === undefined && organizationID !== undefined && hasRoleAdminOrDirectorOrManager(data.userAuth?.roles)) &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                                { name: t('user.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/user` }
                            ]}
                            instanceName={(data.user !== null) ? data.user.username : ""}
                        />
                    </CustomGrid>
                }

                {(outletID === undefined && organizationID === undefined && hasRoleAdminOrDirectorOrManager(data.userAuth?.roles)) &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('employee.pageTitle'), url: `/${getLanguageFromURL()}/employee` }
                            ]}
                            instanceName={(data.user !== null) ? data.user.username : ""}
                        />
                    </CustomGrid>
                }

                <CustomGrid sx={mainTagTitle()}>
                    <CustomTitleBoldSubtitle
                        title={`${t('user.pageTitle')} | ${data.isAdd ? t('actions.add') : t('actions.edit')}`}
                        subtitleBeforeText={data.isAdd ? t('actions.tableCreateNewLink') + t('user.pageTitle') : t('user.pageTitle') + " "}
                        subtitleboldText={!data.isAdd && data.user !== null && data.user !== undefined && data.user.username}
                        subtitleAfterText={!data.isAdd ? " profile page" : ""}
                    />
                </CustomGrid>

                <CustomGrid sx={mainArea()} container={false}>
                    <form id="user" onSubmit={(e) => { return false; }}>
                        <TabContext value={tabValue}>
                            <CustomTabs
                                initValue={tabValue}
                                instances={[
                                    { name: t('user.personalDetails'), value: "personalDetails" },
                                    { name: t('label.address'), value: "address" },
                                    { name: t('user.notifications'), value: "notifications" },
                                    { name: t('user.workingHours'), value: "workingHours" }
                                ]}
                                setValue={setTabValue}
                                sx={{ marginBottom: "20px" }}
                            />

                            <TabPanel value={"personalDetails"} sx={{ paddingTop: "0px" }} >
                                {data.isAdd &&
                                    <input type="hidden" />
                                }

                                {!data.isAdd &&
                                    <input type="hidden" name="id" id="id" defaultValue={data.userID} />
                                }
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={true} htmlFor="username">{t('label.username')}</InputLabel>
                                        <CustomTextField
                                            type="text"
                                            name="user.username"
                                            id="username"
                                            placeholder="Add at least 4 characters"
                                            required={true}
                                            disabled={!data.isAdd} defaultValue={!data.isAdd ? data.user.username : ""}
                                            error={isError.username.length > 0 ? true : false}
                                            helperText={isError.username}
                                            sx={textFieldError()}
                                            onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={true} htmlFor="firstName">{t('label.firstName')}</InputLabel>
                                        <CustomTextField
                                            type="text"
                                            name="user.firstName"
                                            id="firstName"
                                            required={true}
                                            error={isError.firstName.length > 0 ? true : false}
                                            defaultValue={!data.isAdd ? data.user.firstName : ""}
                                            onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                            helperText={isError.firstName}
                                            sx={textFieldError()}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={true} htmlFor="lastName">{t('label.lastName')}</InputLabel>
                                        <CustomTextField
                                            type="text"
                                            name="user.lastName"
                                            id="lastName"
                                            required={true}
                                            defaultValue={!data.isAdd ? data.user.lastName : ""}
                                            error={isError.lastName.length > 0 ? true : false}
                                            onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                            helperText={isError.lastName}
                                            sx={textFieldError()}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="fatherName">{t('label.fatherName')}</InputLabel>
                                        <CustomTextField
                                            type="text"
                                            name="user.fatherName"
                                            id="fatherName"
                                            required={true}
                                            defaultValue={!data.isAdd ? data.user.fatherName : ""}
                                            // error={isError.fatherName.length > 0 ? true : false}
                                            // helperText={isError.fatherName}
                                            sx={textFieldError()}
                                            onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={true} htmlFor="email">{t('label.email')}</InputLabel>
                                        <CustomTextField
                                            type="email"
                                            name="user.email"
                                            id="email"
                                            placeholder="name@company.com"
                                            required={true}
                                            defaultValue={!data.isAdd ? data.user.email : ""}
                                            error={isError.email.length > 0 ? true : false}
                                            helperText={isError.email}
                                            sx={textFieldError()}
                                            onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={true} htmlFor="phone">{t('label.phone')}</InputLabel>
                                        <PhoneInput
                                            value={!data.isAdd ? data.user.phone : ""}
                                            name="user.phone"
                                            id="phone"
                                            placeholder="Add your phone number" required={true}
                                            className={isError.phone.length > 0 ? "is-invalid" : ""}
                                            onChange={onPhoneChange}
                                            sx={{ width: "100%", }}
                                            country="gr"
                                            inputStyle={{ paddingLeft: "70px", width: "100%" }}
                                        />
                                        {isError.phone.length > 0 &&
                                            <FormHelperText sx={selectError()}>
                                                {isError.phone}
                                            </FormHelperText>
                                        }
                                    </Grid>

                                    {hasRoleAdminOrDirectorOrManager(data.userAuth?.roles) &&
                                        <Grid item xs={12} md={6}>
                                            <InputLabel required={true} htmlFor="roles">{t('label.roles')}</InputLabel>
                                            <CustomSelectWithChip
                                                id="roles"
                                                name="user.roles"
                                                required={true}
                                                defaultValue={defaultRolesSelected}
                                                options={data.roles}
                                                onChange={(event) => onSelectChange(event, data, setData)}
                                                onDelete={removeRole}
                                                sx={{ borderRadius: "30px" }}
                                                error={isError.roles.length > 0}
                                            />
                                            {isError.roles.length > 0 &&
                                                <FormHelperText sx={selectError()}>
                                                    {isError.roles}
                                                </FormHelperText>
                                            }
                                        </Grid>
                                    }

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={true} htmlFor="user.languages">{t('user.preferedLanguage')}</InputLabel>
                                        <CustomSelectMultilingual
                                            // id="timeFormat"
                                            translationPath="languages"
                                            name="user.preferedLanguage"
                                            required={true}
                                            isMultiple={false}
                                            value={data.user.preferedLanguage !== null ? data.user.preferedLanguage : "en"}
                                            options={data.languages}
                                            onChange={(event) => onSelectChange(event, data, setData)}
                                            width="100%"
                                        />
                                        {isError.preferedLanguage.length > 0 &&
                                            <FormHelperText sx={selectError()}>
                                                {isError.preferedLanguage}
                                            </FormHelperText>
                                        }
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={true} htmlFor="outletIds">{t('outlet.title')}</InputLabel>
                                        <CustomSelectObjectWithChipAndIconUserOutlet
                                            id="outletIds"
                                            name="user.outletIds"
                                            required={false}
                                            value={data.user.outletIds}
                                            linkPathPattern={`/${getLanguageFromURL()}/organization/${organizationID}/outlet`}
                                            options={data.outlets}
                                            onChange={(event) => {
                                                if (data.user.outletIds.length === 0) changeDefaultOutletOfUser(event, event.target.value); onSelectChange(event, data, setData)
                                            }}
                                            onDelete={(event, idToDelete) => removeId(event, idToDelete, "outletIds")}
                                            changeDefaultOutletOfUser={(event, outletID) => changeDefaultOutletOfUser(event, outletID)}
                                            defaultOutletID={data.user.defaultOutletID}
                                        />
                                        {isError.outletIds.length > 0 &&
                                            <FormHelperText sx={selectError()}>
                                                {isError.outletIds}
                                            </FormHelperText>
                                        }
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="jobDescription">{t('label.jobDescription')}</InputLabel>
                                        <CustomTextArea
                                            type="text"
                                            name="user.jobDescription"
                                            id="jobDescription"
                                            defaultValue={!data.isAdd ? data.user.jobDescription : ""}
                                            onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                        />
                                    </Grid>

                                    {hasRoleAdminOrgOrDirector(data.userAuth?.roles) &&
                                        <Grid item xs={12} md={6}>
                                            <InputLabel required={false} htmlFor="user.permissions">{t('user.permissions.label')}</InputLabel>
                                            <CustomSelectMultilingual
                                                id="permissions"
                                                translationPath="user.permissions"
                                                name="user.permissions"
                                                required={false}
                                                isMultiple={false}
                                                value={data.user.permissions}
                                                options={data.permissions}
                                                onChange={(event) => onSelectChange(event, data, setData)}
                                                width="100%"
                                            />
                                        </Grid>
                                    }

                                    {((data.userAuth?.id === data.user?.id) || hasRoleAdmin(data.userAuth?.roles)) &&
                                        <>
                                            <Grid item xs={12} md={12} sx={{ margin: "20px 0" }}>
                                                <CustomFieldset
                                                    label={data.isAdd ? t('label.createPassword') : t('label.changePassword')}
                                                    children={<>
                                                        {(!data.isAdd && data.userAuth !== null && (hasRoleAdmin(data.userAuth.roles) || data.userID === data.userAuth.id)) &&
                                                            <Grid item xs={12} sm={4}>
                                                                <InputLabel required={true} htmlFor="currentPassword">{t('label.currentPassword')}</InputLabel>
                                                                <CustomTextFieldWithIcons
                                                                    type={data.passwordShown ? "text" : "password"}
                                                                    name="user.currentPassword"
                                                                    id="currentPassword"
                                                                    placeholder="Type your current password"
                                                                    autoComplete="off"
                                                                    endIcon={<SvgIcon component={data.shownPasswordIcon} id="visibility-icon" onClick={togglePassword} style={{ cursor: "pointer" }} />}
                                                                    onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                                                />
                                                            </Grid>
                                                        }

                                                        <Grid item xs={12} sm={4}>
                                                            <InputLabel required={true} htmlFor="newPassword">{t('label.newPassword')}</InputLabel>
                                                            <CustomTextFieldWithIcons
                                                                type={data.passwordShown ? "text" : "password"}
                                                                name="user.newPassword"
                                                                id="newPassword"
                                                                placeholder="Type your new password"
                                                                autoComplete="new-password"
                                                                required={data.isAdd}
                                                                error={isError.newPassword.length > 0 ? true : false}
                                                                onChange={(event) => { changePassword(event.target.value); formValChangeWithParentElement(event, data, setData, t('table.valueReuired')) }}
                                                                helperText={isError.newPassword}
                                                                endIcon={<SvgIcon component={data.shownPasswordIcon} id="visibility-icon" onClick={togglePassword} style={{ cursor: "pointer" }} />}
                                                                sx={textFieldError()}
                                                            />

                                                            {/* {strength !== 0 && ( */}
                                                            <FormControl fullWidth>
                                                                <Box sx={{ mb: 2 }}>
                                                                    <Grid container spacing={2} alignItems="center">
                                                                        <Grid item>
                                                                            <Box
                                                                                style={{ backgroundColor: level?.color }}
                                                                                sx={{
                                                                                    width: 85,
                                                                                    height: 8,
                                                                                    borderRadius: '7px'
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Typography variant="subtitle1" fontSize="0.75rem">
                                                                                {level?.label}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Box>
                                                            </FormControl>
                                                        </Grid>

                                                        <Grid item xs={12} sm={4}>
                                                            <InputLabel required={true} htmlFor="verifyPassword">{t('label.verifyPassword')}</InputLabel>
                                                            <CustomTextFieldWithIcons
                                                                type={data.passwordShown ? "text" : "password"}
                                                                name="user.verifyPassword"
                                                                id="verifyPassword"
                                                                placeholder="Retype your new password"
                                                                autoComplete="new-password"
                                                                required={data.isAdd} data-parsley-equalto="#newPassword"
                                                                error={isError.verifyPassword.length > 0 ? true : false}
                                                                onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                                                helperText={isError.verifyPassword}
                                                                endIcon={<SvgIcon component={data.shownPasswordIcon} id="visibility-icon" onClick={togglePassword} style={{ cursor: "pointer" }} />}
                                                                sx={textFieldError()}
                                                            />
                                                        </Grid>
                                                    </>
                                                    }
                                                />
                                            </Grid>
                                        </>
                                    }

                                    <Grid item xs={12} md={12}>
                                        <FormControlLabel control={<Switch defaultChecked={data.user.enabled} onChange={(event) => onControlChange(event, data, setData, "user", "enabled")} />} label={t(data.user.enabled ? 'label.isEnabled' : 'label.isDisabled')} />
                                    </Grid>

                                    {!data.isAdd &&
                                        <DeleteAccountCard
                                            handleDeleteNavigationIconClick={() => { setDeleteIsClicked(true); handleDialogState(true) }}
                                        />
                                    }
                                </Grid>
                            </TabPanel>

                            <TabPanel value={"address"} sx={{ paddingTop: "0px" }} >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={3}>
                                        <InputLabel required={false} htmlFor="address">{t('label.address')}</InputLabel>
                                        <CustomTextField
                                            type="text"
                                            name="user.address.address"
                                            placeholder="Ermou 5"
                                            id="address"
                                            defaultValue={!data.isAdd ? data.user.address.address : ""}
                                            onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))} />
                                    </Grid>

                                    <Grid item xs={12} sm={3}>
                                        <InputLabel required={false} htmlFor="city">{t('label.city')}</InputLabel>
                                        <CustomTextField
                                            type="text"
                                            name="user.address.city"
                                            id="city"
                                            defaultValue={!data.isAdd ? data.user.address.city : ""}
                                            onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))} />
                                    </Grid>

                                    <Grid item xs={12} sm={3}>
                                        <InputLabel required={false} htmlFor="postalCode">{t('label.postalCode')}</InputLabel>
                                        <CustomTextField
                                            type="text"
                                            name="user.address.postalCode"
                                            id="postalCode"
                                            defaultValue={!data.isAdd ? data.user.address.postalCode : ""}
                                            onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))} />
                                    </Grid>

                                    <Grid item xs={12} sm={3}>
                                        <InputLabel required={false} htmlFor="country">{t('label.country')}</InputLabel>
                                        <CustomObjectSelect
                                            name="user.address.country.id"
                                            required={true}
                                            defaultValue={defaultCountrySelected}
                                            options={["", ...data.countries]}
                                            onChange={(event) => onSelectChange(event, data, setData)}
                                        />
                                    </Grid>
                                </Grid>
                            </TabPanel>

                            <TabPanel value={"notifications"} sx={{ paddingTop: "0px" }}>
                                <Grid item xs={12} md={12}>
                                    <Grid direction="row" container spacing={0}>
                                        <Grid item xs={12} md={12}>
                                            <FormControlLabel control={<Switch defaultChecked={data.user.notificationSettings.notificationMobileEnabled}
                                                onChange={(event) => onControlChangeWithParent(event, data, setData, "user.notificationSettings.notificationMobileEnabled")} />}
                                                label={t('user.notificationMobileEnabled')} />
                                        </Grid>

                                        {data.user.notificationSettings.notificationMobileEnabled &&
                                            <Grid item xs={12} md={6}>
                                                <CustomSelectMultilingual
                                                    // id="timeFormat"
                                                    translationPath="user.notitification"
                                                    name="user.notificationSettings.mobileNotificationActionsEnabled"
                                                    required={true}
                                                    isMultiple={true}
                                                    value={data.user.notificationSettings.mobileNotificationActionsEnabled}
                                                    options={data.mobileNotificationActionsEnabled}
                                                    onChange={(event) => onSelectChangeWithParents(event, data, setData)}
                                                    width="100%"
                                                />
                                            </Grid>
                                        }

                                        <Grid item xs={12} md={12}>
                                            <FormControlLabel control={<Switch defaultChecked={data.user.notificationSettings.notificationEmailEnabled}
                                                onChange={(event) => onControlChangeWithParent(event, data, setData, "user.notificationSettings.notificationEmailEnabled")} />}
                                                label={t('label.notificationEmailEnabled')} />
                                        </Grid>

                                        {data.user.notificationSettings.notificationEmailEnabled &&
                                            <Grid item xs={12} md={6}>
                                                <CustomSelectMultilingual
                                                    // id="timeFormat"
                                                    translationPath="user.notitification"
                                                    name="user.notificationSettings.emailNotificationActionsEnabled"
                                                    required={true}
                                                    isMultiple={true}
                                                    value={data.user.notificationSettings.emailNotificationActionsEnabled}
                                                    options={data.emailNotificationActionsEnabled}
                                                    onChange={(event) => onSelectChangeWithParents(event, data, setData)}
                                                    width="100%"
                                                />
                                            </Grid>
                                        }

                                        <Grid item xs={12} md={12}>
                                            <FormControlLabel control={<Switch defaultChecked={data.user.notificationSettings.notificationWebEnabled}
                                                onChange={(event) => onControlChangeWithParent(event, data, setData, "user.notificationSettings.notificationWebEnabled")} />}
                                                label={t('label.notificationWebEnabled')} />
                                        </Grid>

                                        {data.user.notificationSettings.notificationWebEnabled &&
                                            <Grid item xs={12} md={6}>
                                                <CustomSelectMultilingual
                                                    // id="timeFormat"
                                                    translationPath="user.notitification"
                                                    name="user.notificationSettings.webNotificationActionsEnabled"
                                                    required={true}
                                                    isMultiple={true}
                                                    value={data.user.notificationSettings.webNotificationActionsEnabled}
                                                    options={data.webNotificationActionsEnabled}
                                                    onChange={(event) => onSelectChangeWithParents(event, data, setData)}
                                                    width="100%"
                                                />
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </TabPanel>


                            <TabPanel value={"workingHours"} sx={{ paddingTop: "0px" }}>
                                <Grid container spacing={2} sx={{ marginTop: '5px' }}>
                                    {weekDays.map((day, dayIndex) => (
                                        <Grid item xs={12} key={day}>
                                            <Grid container spacing={2} key={dayIndex} xs={12} md={12}>
                                                <Grid item xs={12} md={12}>
                                                    <InputLabel required={true} htmlFor={day}>{t(`workingHours.${day}`)}</InputLabel>
                                                </Grid>

                                                <Grid item xs={12} md={12}>
                                                    {data.user.workingHours[day].map((startEndDate, index) => (
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <Grid container spacing={2} key={index} sx={{ marginTop: 0, alignItems: "center" }}>
                                                                <Grid item xs={12} md={4}>
                                                                    <TimePicker
                                                                        // acceptRegex={hoursMinutesPattern}
                                                                        label={t('workingHours.start')}
                                                                        renderInput={(props) => <TextField {...props} />}
                                                                        value={startEndDate.start}
                                                                        format="HH:mm"
                                                                        views={['hours', 'minutes']}
                                                                        onChange={(newValue) => handleStartEndChange(day, index, 'start', newValue)}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} md={4}>
                                                                    <TimePicker
                                                                        // acceptRegex={hoursMinutesPattern}
                                                                        label={t('workingHours.end')}
                                                                        renderInput={(props) => <TextField {...props} />}
                                                                        value={startEndDate.end}
                                                                        format="HH:mm"
                                                                        views={['hours', 'minutes']}
                                                                        onChange={(newValue) => handleStartEndChange(day, index, 'end', newValue)}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} md={2}>
                                                                    <Grid container spacing={2}>
                                                                        <Grid item xs={6} md={6}>
                                                                            <CustomIconButton
                                                                                keyValue="bold"
                                                                                variant="outlined"
                                                                                icon={<SignLanguageIcon />}
                                                                                tooltipTitle={t('workingHours.working')}
                                                                                isFocused={startEndDate.open}
                                                                                onClick={() => handleOpenChange(day, index, true)}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={6} md={6}>
                                                                            <CustomIconButton
                                                                                keyValue="bold"
                                                                                variant="outlined"
                                                                                icon={<WorkOffIcon />}
                                                                                tooltipTitle={t('workingHours.break')}
                                                                                isFocused={!startEndDate.open}
                                                                                onClick={() => handleOpenChange(day, index, false)}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid item xs={6} md={2}>
                                                                    <CustomIconButton
                                                                        keyValue={`btn-delete-${index}`}
                                                                        onClick={() => removeWorkingHour(day, index)}
                                                                        icon={<DeleteIcon style={cardItemButtonGroupStackButtonIcon()} />}
                                                                        className="icon-wrapper tr link"
                                                                        iconSx={{ marginRight: "0px!important" }}
                                                                        sx={{
                                                                            backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColor"],
                                                                            ":hover": {
                                                                                backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColorHover"],
                                                                            },
                                                                            ...cardItemButtonGroupStackButton()
                                                                        }}
                                                                    >
                                                                        {t('actions.delete')}
                                                                    </CustomIconButton>
                                                                </Grid>
                                                            </Grid>
                                                        </LocalizationProvider>
                                                    ))}
                                                </Grid>

                                                <Grid item xs={12} md={12} sx={{ marginBottom: "10px" }}>
                                                    <CustomIconButton
                                                        sx={addCardActionAreaButtonStyle()}
                                                        backgroundColor="transparent"
                                                        icon={<AddIcon sx={addWorkingHoursButtonIconStyle()} />}
                                                        onClick={() => handleAddStartEnd(day)}>
                                                        {t('actions.add')}
                                                    </CustomIconButton>
                                                </Grid>
                                            </Grid>
                                            <Divider />
                                        </Grid>
                                    ))}
                                </Grid>
                            </TabPanel>
                        </TabContext>

                        <Grid container spacing={2}>
                            {/* <Grid item>
                        <CustomIconButton
                           variant="outlined"
                           keyValue="save"
                           onClick={data.isAdd ? addUser : editUser}
                           label={t('actions.save')}
                           sx={{ ...formSaveButton(), marginLeft: "20px" }}
                        />
                     </Grid> */}

                            {(data.isAdd && data.userAuth.id !== userID) &&
                                <Grid item>
                                    <CustomIconButton
                                        variant="outlined"
                                        keyValue="cancel"
                                        link={path}
                                        label={t('actions.back')}
                                        sx={formSaveButton()}
                                        color="error"
                                    />
                                </Grid>
                            }

                            {(!data.isAdd && data.userAuth.id !== userID) &&
                                <Grid item>
                                    <CustomIconButton
                                        variant="outlined"
                                        keyValue="cancel"
                                        onClick={getUser}
                                        label={t('actions.cancel')}
                                        sx={formSaveButton()}
                                        color="error"
                                    />
                                </Grid>
                            }
                        </Grid>
                    </form>

                    <Fab color="primary"
                        aria-label="add"
                        sx={fabStyle()}
                        onClick={data.isAdd ? addUser : editUser}
                    >
                        <SaveIcon />
                    </Fab>
                </CustomGrid>

                {alertBox.isOpen &&
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={3000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        handleSnackbarOpen={handleSnackbarState} />
                }

                {isMobile &&
                    <CustomSwippableDrawer
                        open={open}
                        onClose={() => handleDialogState(false)}
                        onOpen={() => handleDialogState(true)}
                        anchor={isMobile ? "bottom" : "left"}
                        paperSx={autDrawerPaperStyle}
                    >
                        {body()}
                    </CustomSwippableDrawer>
                }

                {!isMobile &&
                    <Dialog
                        open={open}
                        onClose={() => { handleDialogState(false) }}
                        aria-describedby="auth-login-dialog"
                    >
                        <DialogContent sx={{ textAlign: "-webkit-center", minWidth: "300px" }}>
                            {body()}
                        </DialogContent>
                    </Dialog>
                }
            </CustomGrid>
        );
    }
}

export default UserAddEditComponent;