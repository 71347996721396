import { request } from 'constants/alias';
import { API } from "constants/config";
import { includeParamsToUrl } from 'utils/functions';
import { formatISOWithTimezone } from 'utils/functions/date';

/**
 * The rest request which attempts to get the pda view details from the database.
 * @returns an object with an attribute containing the pda view details 
 */
export async function fetchAll(dateFrom) {
    return await request.get(includeParamsToUrl(`${API}pda`, { dateFrom: formatISOWithTimezone(new Date(dateFrom), true) }));
}

const pdaApi = {
    fetchAll,
}

export default pdaApi;