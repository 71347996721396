import {
    Card,
    CardActionArea,
    CardMedia,
    Divider,
    Grid,
    Typography
} from '@mui/material';
import CustomBarcode from 'components/Barcode/CustomBarcode';
import CustomTypography from 'components/Typography/CustomTypography';
import { getDateTimeShortFormatter } from 'constants/calendar';
import { getCouponDefaultBarcodeConfiguration, getCouponCardProperties } from 'constants/couponCardProperties';
import { MINIO_HOST } from 'constants/hosts';
import { useTranslation } from 'react-i18next';
import { couponLogoStyle } from 'themes/defaultThemes';
import { dateTimeFormatter } from 'utils/functions';
import { getCouponPhoto } from 'utils/functions/assets';

/**
 * The CustomCustomerCouponCard, that display each customer coupon.
 * 
 * @param {object} order the past order details
 * @param {string} lang the lang code
 *
 * @returns {JSX.Element} A React component that renders a past order.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomCustomerCouponCard({ coupon, organization, sx, currency = "€" }) {
    const { t } = useTranslation();

    const expirationDate = dateTimeFormatter(coupon?.expirationDate, getDateTimeShortFormatter());

    let style = { sx };

    if (coupon.backgroundType === "IMAGE") {
        let couponBackgroundImage = !coupon.backgroundImage.includes("https://") ? getCouponPhoto(organization?.id, coupon.id, coupon.backgroundImage) : coupon.backgroundImage;
        style = {
            ...style,
            backgroundImage: `url(${couponBackgroundImage})`,
            backgroundSize: "cover"
        }
    } else {
        if (!coupon.gradientBackground)
            style = {
                ...style,
                backgroundColor: `${coupon.backgroundColor1}!important`,
            }
        else
            style = {
                ...style,
                background: `linear-gradient(to right bottom, ${coupon.backgroundColor1}, ${coupon.backgroundColor2})!important`,
            }
    }
    return (
        <Grid>
            <Card variant="outlined"
                key={`coupon-${coupon?.id}`}
                sx={{
                    ...getCouponCardProperties(),
                    ...style
                }}
            >
                <CardActionArea sx={{ justifyContent: 'flex-end', height: "100%", width: "100%", textAlign: "-webkit-center" }} key={`card- back-ontent-${coupon?.id}`}>
                    <Grid container spacing={1} sx={{ justifyContent: "center" }}>
                        {(organization?.logo !== "" && organization?.logo !== null) &&
                            <Grid item xs={12} md={12} sx={{ height: "80px" }}>
                                <CardMedia
                                    component="img"
                                    src={`${MINIO_HOST}organization/${organization?.id}/${organization?.logo}`}
                                    alt={"logo"}
                                    sx={{ ...couponLogoStyle(), textAlign: "center" }}
                                />
                            </Grid>
                        }

                        <Grid item xs={12} md={12} sx={{ margin: "0 10px" }}>
                            <Grid container direction="column" spacing={0} sx={{ justifyContent: "center" }}>

                                {/* Details Section */}
                                <Grid item xs={12} md={12} sx={{ height: "25px" }}>
                                    <CustomTypography level="h6"
                                        sx={{
                                            fontSize: 'md',
                                            fontWeight: "bold",
                                            color: coupon?.baseLabelColor,
                                            textAlign: "left",
                                            height: "25px",
                                            paddingBottom: 0,
                                        }}
                                        text={coupon.label?.en || 'Coupon'}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} sx={{ height: "25px" }}>
                                    <CustomTypography level="h6"
                                        sx={{
                                            fontSize: 'md',
                                            color: coupon?.baseDescriptionColor,
                                            textAlign: "left",
                                            paddingBottom: 0,
                                        }}
                                        text={coupon.description?.en?.replaceAll('%value', `${coupon.value}${currency}`)?.replaceAll('%code', coupon.code)?.replaceAll('%percentage', `${coupon.percentage}%`)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Barcode Section */}
                        <Grid item xs={12} md={12}>
                            <Divider light flexItem sx={{ borderRightWidth: 5, width: "100%" }} />
                            <CustomBarcode {...getCouponDefaultBarcodeConfiguration()} {...coupon.barcodeConfiguration}/>
                        </Grid>

                        {/* Expiration Section */}
                        <Grid item xs={12} md={12}>
                            <Typography variant="body1" sx={{ fontWeight: "bold", margin: "5px", float: "left", fontSize: "10px", color: coupon?.baseExpiresColor }}>
                                {t('customer.view.tabs.coupons.expiresOn')}
                            </Typography>
                            <Typography variant="body1" sx={{ margin: "5px", float: "right", fontSize: "10px", color: coupon?.baseExpiresColor }}>
                                {expirationDate}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardActionArea>
            </Card>
        </Grid>
    );
}

export default CustomCustomerCouponCard;