import CustomRating from "components/Rating/CustomRating";

function Rating_1_To_5({ question, onChange }) {
    const onUpdate = (newValue) => {
        onChange({
            ...question,
            answer: newValue,
        });
    };

    const getValue = () => {
        if (!isNaN(question.answer)) {
            return question.answer;
        }
        return "";
    };

    return (
        <CustomRating
            iconStyle={question.ratingQuestionStyle}
            value={getValue()}
            onChange={(e) => {
                console.log("onChange", e.target.value);
                onUpdate(e.target.value);
            }}
        />
    );
}

export default Rating_1_To_5;