import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

/**
 * The FloorPlanningSkeleton, that display a box with animated skeletons.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function FloorPlanningSkeleton({ isMobile }) {
    return (
        <Box sx={{
            display: "grid",
            flexDirection: "column",
            gap: "10px",
            height: "96.3vh",
            pt: "10px",
            // padding: `20px ${isMobile ? "0" : "30px"}`
        }}>
            {/* First Row */}
            <Box sx={{ display: "flex", gap: "10px" }}>
                {/* Left skeleton */}
                <Skeleton
                    variant="rounded"
                    animation="wave"
                    sx={{ width: "30%", height: "100%" }}
                />
                {/* Right skeleton */}
                <Box sx={{ width: "70%", display: "flex", flexDirection: "column", gap: "10px" }}>
                    <Skeleton
                        variant="rounded"
                        animation="wave"
                        sx={{ width: "100%", height: "50px" }}
                    />
                    {/* Filling the rest height */}
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        sx={{ flex: 1 }}
                    />
                </Box>
            </Box>
        </Box>
    );
}

export default FloorPlanningSkeleton;