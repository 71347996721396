import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { Rating } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/system';
import PropTypes from 'prop-types';

/**
 * The Custom Rating field, that display customized {@link Rating}.
 * 
 * @property {string} name The name attribute of the radio `input` element
 * @property {number} value The rating value.
 * @property {array} defaultValue The default value. Use when the component is not controlled. @default null
 * @property {boolean} readOnly Removes all hover effects and pointer events.
 * @property {boolean} disabled If `true`, the component is disabled.
 * @property {number} precision The minimum increment value change allowed.
 * @property {number} size The size of the component. The available values are 'small' | 'medium' | 'large'. @default 'medium'
 * @property {string} max Maximum rating. @default 5
 * @property {function} onChange Callback fired when the value changes.
 * @property {string} iconStyle The icon style for the componnent. The available values are 'start' | 'heart' | 'emoji'.  @default 'star'
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomRating({ name = "", value, defaultValue, readOnly, disabled, precision, size = "large", max = 5, onChange, iconStyle = "star", sx }) {
    const StyledRating = styled(Rating)(({ theme }) => ({
        '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
            color: theme !== undefined ? theme.palette.action.disabled : "initial",
        },
        '& .MuiRating-iconFilled': {
            color: iconStyle === "star" ? "orange" : iconStyle === "heart" ? '#ff6d75' : "initial",
        },
        '& .MuiRating-iconHover': {
            color: iconStyle === "star" ? "darkorange" : iconStyle === "heart" ? '#ff3d47' : "initial",
        },
        'label': {
            fontSize: "23px"
        }
    }));


    const customIcons = {
        1: {
            icon: <SentimentVeryDissatisfiedIcon sx={{ color: "#f23e4a" }} />,
            label: 'Extremly Dissatisfied',
        },
        2: {

            icon: <SentimentDissatisfiedIcon sx={{ color: "#fe8540" }} />,
            label: 'Very Dissatisfied',
        },
        3: {
            icon: <SentimentNeutralIcon sx={{ color: "#ffc724" }} />,
            label: 'Neutral',
        },
        4: {
            icon: <SentimentSatisfiedAltIcon sx={{ color: "#d8dc3d" }} />,
            label: 'Very Satisfied',
        },
        5: {
            icon: <SentimentVerySatisfiedIcon sx={{ color: "#87d275" }} />,
            label: 'Extremly Satisfied',
        }
    };

    const customIcons10 = {
        1: {
            icon: <SentimentVeryDissatisfiedIcon sx={{ color: "#f23e4a" }} />,
            label: 'Extremly Dissatisfied',
        },
        2: {
            icon: <SentimentVeryDissatisfiedIcon sx={{ color: "#fb6246" }} />,
            label: 'Very Very Dissatisfied',
        },
        3: {
            icon: <SentimentDissatisfiedIcon sx={{ color: "#fe8540" }} />,
            label: 'Very Dissatisfied',
        },
        4: {
            icon: <SentimentDissatisfiedIcon sx={{ color: "#ffa834" }} />,
            label: 'Disappointed',
        },
        5: {
            icon: <SentimentNeutralIcon sx={{ color: "#ffc724" }} />,
            label: 'Neutral',
        },
        6: {
            icon: <SentimentSatisfiedIcon sx={{ color: "#fee319" }} />,
            label: 'Ok',
        },
        7: {
            icon: <SentimentSatisfiedAltIcon sx={{ color: "#d8dc3d" }} />,
            label: 'Satisfied',
        },
        8: {
            icon: <SentimentSatisfiedAltIcon sx={{ color: "#aed65b" }} />,
            label: 'Very Satisfied',
        },
        9: {
            icon: <InsertEmoticonIcon sx={{ color: "#87d275" }} />,
            label: 'Very Very Satisfied',
        },
        10: {
            icon: <SentimentVerySatisfiedIcon sx={{ color: "#58cb8c" }} />,
            label: 'Extremly Satisfied',
        },
    };

    function IconContainer(props) {
        const { value, ...other } = props;
        return <span {...other}>{(max === 10) ? customIcons10[value].icon : customIcons[value].icon}</span>;
    }

    IconContainer.propTypes = {
        value: PropTypes.number.isRequired,
    };

    return (
        <Stack spacing={1} sx={sx}>
            {iconStyle === "heart" &&
                <StyledRating
                    name={name}
                    value={value}
                    defaultValue={defaultValue}
                    readOnly={readOnly}
                    disabled={disabled}
                    precision={precision}
                    size={size}
                    max={max}
                    emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
                    icon={(iconStyle === "heart") && <FavoriteIcon fontSize="inherit" />}
                    onChange={onChange}
                />
            }


            {iconStyle === "emoji" &&
                <StyledRating
                    name={name}
                    value={value}
                    defaultValue={defaultValue}
                    readOnly={readOnly}
                    disabled={disabled}
                    precision={precision}
                    size={size}
                    max={max}
                    IconContainerComponent={IconContainer}
                    highlightSelectedOnly
                    onChange={onChange}
                />
            }

            {iconStyle === "star" &&
                <StyledRating
                    name={name}
                    value={value}
                    defaultValue={defaultValue}
                    readOnly={readOnly}
                    disabled={disabled}
                    precision={precision}
                    size={size}
                    max={max}
                    onChange={onChange}
                />
            }
        </Stack>
    );
}

export default CustomRating;