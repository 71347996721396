import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { Paper } from "@mui/material";
import { getLoyaltyCardProperties } from "constants/loyaltyCardProperties";
import { getThemeColor } from "constants/theme";
import { getTheme } from "utils/theme";

export const mainTag = () => {
    return {
        textAlign: "center",
        // position: "absolute",
        width: "100%",
        height: "calc(100% - 26px)",
        // height: "450px",
        padding: "20px 0px 50px 5px"
    };
}
export const mainTagChat = () => {
    return {
        textAlign: "center",
        // position: "absolute",
        width: "100%",
        // height: "450px",
        padding: "20px 0px 0px 5px"
    };
}

export const mainTagFloorPlanning = () => {
    return {
        textAlign: "center",
        // position: "absolute",
        width: "100%",
        // height: "450px",
        padding: "0px 0px 0px 5px"
    };
}

export const mainTagLeft = () => {
    return {
        textAlign: "left",
        position: "absolute",
        width: "100%",
        // height: "450px",
        padding: "20px 0px 50px 5px"
    };
}

export const mainTagMobile = () => {
    return {
        textAlign: "center",
        // position: "absolute",
        width: "100%",
        // height: "450px",
        padding: "0px 0px 50px 5px"
    };
}
export const mainTagBreadcrumb = () => {
    return {
        maxWidth: "100%",
        height: "fit-content",
        textAlign: "left",
        padding: 0,
        border: "none"
    };
}

export const mainTagTitle = () => {
    return {
        maxWidth: "100%",
        textAlign: "left",
        padding: "0",
        marginTop: "20px"
    };
}

export const mainTagSearch = (isMobile) => {
    return {
        maxWidth: "100%",
        fontSize: 0,
        right: "0px",
        textAlign: "right",
        width: "100%",
        // paddingTop: "10px",
        paddingBottom: isMobile ? "20px" : 0
    };
}


export const mainTagSearchItem = () => {
    return {
        maxHeight: "60px",
        width: "fir-content",
        minWidth: "170px",
        textAlign: "left",
        marginRight: "20px",
        borderRadius: "30px",
        verticalAlign: "middle"
    };
}

export const mainTagSearchObjectSelect = () => {
    return {
        textAlign: "left",
        margin: 0
    };
}

export const mainTagSearchSelect = () => {
    return {
        margin: 0
    };
}

// ==================ORDER search
export const mainTagSearchItemOrderBox = () => {
    return {
        borderRadius: "5px",
        padding: "0px",
        border: '1px dashed lightgrey'
    };
}

export const mainTagSearchItemOrderBoxContainer = () => {
    return {
        margin: "0px 5px 15px 0px",
        // width: "calc(100% - 16px)"
    };
}

export const mainTagSearchItemOrderBoxLegend = () => {
    return {
        color: "grey",
        textAlign: "left",
        marginLeft: "30px"
    };
}

export const mainTagSearchItemOrderContainer = () => {
    return {
        padding: "10px",
        width: "fit-content"
    };
}

export const mainTagSearcAnalytics = () => {
    return {
        width: "fir-content",
        textAlign: "left",
        fontWeight: "600",
        fontSize: "13px",
        textTransform: "initial",
        borderRadius: "10px"
    };
}

export const mainTagSearchItemOrderStatus = () => {
    return {
        width: "fir-content",
        textAlign: "left",
        backgroundColor: getThemeColor()[getTheme()]["addButtonFontColor"],
        color: getThemeColor()[getTheme()]["addButtonBackgroundColor"],
        fontWeight: "600",
        fontSize: "13px",
        textTransform: "initial",
        ":hover": {
            backgroundColor: getThemeColor()[getTheme()]["addButtonBackgroundColor"],
            color: getThemeColor()[getTheme()]["addButtonFontColor"],
        }
    };
}

export const mainTagSearchItemOrderStatusIcon = () => {
    return {
        marginRight: "5px",
        ":hover": {
            color: "white"
        },
    };
}

export const orderButton = () => {
    return {
        width: "100%",
        color: getThemeColor()[getTheme()]["addButtonBackgroundColor"],
        fontWeight: "600",
        fontSize: "13px",
        textTransform: "initial",
        cursor: "pointer",
        ":hover": {
            backgroundColor: "#54b1d273",
            color: getThemeColor()[getTheme()]["addButtonFontColor"],
        }
    }
}

export const getOrderButtonIcon = () => {
    return {
        width: 40,
        height: 40,
        fill: "#54b1d2"
    }
}


export const orderAddTypeButtonIcon = () => {
    return {
        marginRight: "10px",
        ":hover": {
            color: "white"
        },
    };
}

export const orderIconStyle = () => {
    return {
        fontSize: "20px",
        color: "grey",
        border: "1px solid lightgrey",
        padding: "5px",
        borderRadius: "30px"
    }
}

export const orderFontAwesomeIconStyle = () => {
    return {
        fontSize: "20px!important",
        width: 20,
        height: 20,
        color: "grey",
        border: "1px solid lightgrey",
        padding: "5px",
        borderRadius: "30px"
    }
}

export const orderHeaderTextStyle = () => {
    return {
        fontWeight: "bold",
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "12px",
        color: "grey",
        paddingBottom: "2px"
    }
}

export const orderCartTitleTextStyle = () => {
    return {
        ...lumanosimoTitleStyle(),
        fontSize: "17px",
        mt: 0,
        marginLeft: "20px",
        marginRight: "20px",
        textWrap: "balance",
        textAlign: "center"
    }
}

export const orderReviewGridStyle = () => {
    return {
        margin: "20px 0px 0px",
        padding: "10px 0px",
        background: "#fff",
        borderRadius: "10px"
    }
}

export const orderCartItemQuantityTextStyle = () => {
    return {
        ...lumanosimoTitleStyle(),
        fontSize: "12px",
        // marginRight: "10px",
        padding: "10px 12px",
        textWrap: "balance",
        textAlign: "center",
        background: "#f2f2f2",
        borderRadius: "10px"
    }
}

export const orderCommentTextStyle = () => {
    return {
        display: "block",
        width: "90%",
        overflow: "hidden",
        textAlign: "justify",
        fontSize: "10px",
        color: "grey"
    }
}

export const orderItemHeaderTextStyle = () => {
    return {
        fontWeight: "bold",
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "normal",
        textOverflow: "ellipsis",
        fontSize: "12px",
        color: "black",
        paddingBottom: "2px"
    }
}

export const orderItemNoteTextStyle = () => {
    return {
        fontWeight: 600,
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "normal",
        textOverflow: "ellipsis",
        fontSize: "10px",
        color: "grey"
    }
}

export const orderHistoryStatusLabelStyleDot = (status) => {
    return {
        backgroundColor: getThemeColor()[getTheme()].orderStatus[status] + "57",
        padding: "5px 10px",
        textAlign: "center",
        borderRadius: "10px",
        color: getThemeColor()[getTheme()].orderStatus[status]
    }
}

export const orderHistoryStatusLabelStyleChip = (status) => {
    return {
        backgroundColor: getThemeColor()[getTheme()].orderStatus[status] + "57",
        padding: "5px 10px",
        textAlign: "center",
        fontSize: "10px",
        borderRadius: "10px",
        whiteSpace: "normal",
        textOverflow: "ellipsis",
        color: getThemeColor()[getTheme()].orderStatus[status],
        ...lumanosimoTitleStyle()
    }
}


export const orderActionsHistoryCardStyle = () => {
    return {
        border: "1px lightgrey solid",
        boxShadow: "rgb(234, 236, 240) 0px 0px 1px, rgba(29, 41, 57, 0.08) 0px 1px 3px",
        marginLeft: "20px",
        marginBottom: "10px",
        borderRadius: "10px",
        ...orderActionsHistoryCardGridStyle()
    }
}

export const orderActionsHistoryCardGridStyle = () => {
    return {
        py: '12px',
        px: 2
    }
}

export const orderActionsHistoryDetailsLabelStyle = () => {
    return {

        fontWeight: "bold",
        display: "block",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "10px",
        placeSelf: "center",
    }
}

export const orderActionsDetailsHistoryDateTextStyle = () => {
    return {
        fontWeight: "bold",
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "8px",
        color: "grey",
        placeSelf: "center",
        ...lumanosimoTitleStyle()
    }
}

export const mainArea = () => {
    return {
        width: "100%",
        position: "relative",
        marginBottom: "0px",
        backgroundColor: "#fff",
        boxShadow: "0px 0px 15px 0px #dcdcdc",
        borderRadius: "10px",
        transition: "all 0.3s",
        marginTop: "20px",
        textAlign: "left"
        // webkitTransition: "all 0.3s",
        // mozTransition: "all 0.3s",
        // oTransition: "all 0.3s",
        // msTransition: "all 0.3s",
        // ":hover": {
        //     textDecoration: "none"
        // }
    };
}
export const mainAreaFullScreen = () => {
    return {
        width: "100%!important",
        heaight: "100%!important",
        position: "relative",
        backgroundColor: "#fff",
        borderRadius: "10px",
        transition: "all 0.3s",
        textAlign: "left"
    };
}
export const mainAreaTable = () => {
    return {
        width: "100%",
        position: "relative",
        margin: "0px",
        backgroundColor: "#fff",
        boxShadow: "0px 0px 15px 0px #dcdcdc",
        borderRadius: "10px",
        transition: "all 0.3s",
        marginTop: "20px",
        padding: "0px",
        textAlign: "left"
    };
}


export const mainAreaBody = () => {
    return {
        padding: "60px",
        textAlign: "center",
        fontSize: "16px"
    };
}

export const mainAreaBodyDraggableTable = () => {
    return {
        padding: "60px",
        textAlign: "center",
        fontSize: "16px",
        border: "1px dashed grey",
        borderRadius: "10px",
        marginLeft: "10px"
    };
}

export const fabStyle = (position = "fixed") => {
    return {
        position: position,
        bottom: 16,
        right: 16,
        backgroundColor: getThemeColor()[getTheme()]["fabBackgroundColor"],
        color: getThemeColor()[getTheme()]["fabFontColor"],
        ":hover": {
            backgroundColor: getThemeColor()[getTheme()]["fabBackgroundColorHover"],
            color: getThemeColor()[getTheme()]["fabFontColor"]
        }
    }
}

export const fabSecondStyle = (position = "fixed") => {
    return {
        position: position,
        bottom: 16,
        right: 76,
        backgroundColor: getThemeColor()[getTheme()]["fabBackgroundColor"],
        color: getThemeColor()[getTheme()]["fabFontColor"],
        ":hover": {
            backgroundColor: getThemeColor()[getTheme()]["fabBackgroundColorHover"],
            color: getThemeColor()[getTheme()]["fabFontColor"]
        }
    }
}

export const fabStyleIcon = () => {
    return {
        width: 25,
        height: 45,
        fill: "white"
    }
}

export const fabTooltipStyle = () => {
    return {
        "& .MuiTooltip-tooltip": {
            backgroundColor: getThemeColor()[getTheme()]["fabBackgroundColor"],
            p: "7px"
        }
    }
}

export const fabStyleCart = () => {
    return {
        ...fabStyle(),
        width: "180px",
        borderRadius: "20px"
    }
}

export const toggleMenuButton = () => {
    return {
        width: "60px",
        height: "100%",
        boxShadow: "none!important",
        // top: 0 ,
        // position: "absolute",
        backgroundColor: "white",
        color: "grey",
        cursor: "pointer",
        ":hover": {
            backgroundColor: "lightgrey!important",
            color: "grey",
        },
        zIndex: 1300
    }
}

export const toggleMenuIcon = () => {
    return {
        boxShadow: "none !important",
        fontSize: "30px",
        backgroundColor: "transparent",
        color: "grey",
        cursor: "pointer",
        ":hover": {
            backgroundColor: "lightgrey !important",
            color: "grey",
        }
    }
}

export const fabStyleToggleMenuButton = () => {
    return {
        position: 'fixed',
        top: 16,
        right: 16,
        backgroundColor: "white",
        boxShadow: "none",
        color: "grey",
        ":hover": {
            backgroundColor: "white",
            color: "grey",
        },
        zIndex: 1500
    }
}

export const attachDialogAddButton = () => {
    return {
        backgroundColor: "white",
        borderRadius: "50px",
        color: "#54b1d2",
        padding: "2px 8x",
        minWidth: "40px",
        border: "1px solid lightgrey",
        margin: 0,
        marginTop: "3px"
    }
}
export const attachDialogAddButtonIcon = () => {
    return {
        marginRight: "0px!important"
    }
}

export const attachDialogAddButtonAddIcon = () => {
    return {
        marginRight: "0px"
    }
}
export const attachDialogGridDraggable = () => {
    return {
        border: " 1px solid lightgrey",
        borderRadius: "10px"
    }
}

export const cardItemButtonGroupStack = () => {
    return {
        right: 16,
        top: 0,
        position: "relative",
        zIndex: 10,
        justifyContent: "right"
    }
}
export const cardItemButtonGroupStackButton = () => {
    return {
        borderRadius: "50px",
        color: "white",
        padding: "8px 0px",
        minWidth: "40px",
        marginRight: "10px",
        marginTop: "1.5%",
        boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)"
    }
}
export const cardTestPrintButtonGroupStackButton = () => {
    return {
        borderRadius: "50px",
        color: "white",
        padding: "8px 0px",
        minWidth: "40px",
        marginRight: "10px",
        marginTop: "1.5%",
        boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)"
    }
}
export const cardItemButtonGroupStackButtonIcon = () => {
    return {
        marginRight: "0px"
    }
}
export const cardItemContentStackButtonIcon = () => {
    return {
        marginTop: "-10px",
        zIndex: -1
    }
}
export const cardItemContent = () => {
    return {
        width: "320px",
        height: "190px",
        p: 0,
        m: 0,
        flexWrap: 'wrap',
        borderRadius: "20px",
        marginRight: "20px",
        marginBottom: "20px",
        boxShadow: 2,
        overflow: "visible"
    };
}
export const cardItemContentActionArea = () => {
    return {
        justifyContent: 'flex-end',
        height: "100%",
        width: "100%",
        paddingLeft: "0",
        // marginLeft: "15px",
        textAlign: "center"
    };
}
export const cardPosItemContent = () => {
    return {
        minWidth: "320px",
        height: "250px",
        width: "auto",
        p: 0,
        m: 0,
        flexWrap: 'wrap',
        borderRadius: "20px",
        marginRight: "20px",
        marginBottom: "20px",
        boxShadow: 2,
        overflow: "visible"
    };
}

export const imageCardItemContent = () => {
    return {
        width: "310px",
        height: "180px",
        p: 0,
        m: 0,
        flexWrap: 'wrap',
        borderRadius: "10px",
        boxShadow: 2,
        overflow: "visible"
    };
}
export const imageCardItemContentActionArea = () => {
    return {
        justifyContent: 'flex-end',
        height: "100%",
        width: "100%",
        borderRadius: "10px",
        textAlign: "center"
    };
}
export const imageCardItemContentActionAreaMedia = () => {
    return {
        height: "auto",
        width: "100%",
        borderRadius: "10px"
    };
}

export const logoStyle = () => {
    return {
        height: "100px",
        width: "auto"
    };
}

export const logoImageCardItemContent = () => {
    return {
        height: "80px",
        p: 0,
        m: 0,
        flexWrap: 'wrap',
        boxShadow: 2,
        overflow: "visible"
    };
}

export const printerDetailsRow = () => {
    return {
        alignItems: "center"
    };
}

export const activePrinterIcon = () => {
    return {
        // boxShadow: "0px 0px 15px 0px #dcdcdc",
        fontSize: 13,
        marginLeft: "-30px",
        paddingTop: "135px",
        zIndex: "1000"
    };
}

export const textPrinterHeaderTypography = () => {
    return {
        fontSize: '16px',
        paddingBottom: 0,
        fontWeight: "bold",
        paddingTop: "5px",
        textAlign: "center",
        color: "#54b1d2"
    };
}

export const textPrinterItemTypography = () => {
    return {
        fontSize: 'md',
        textAlign: "left",
        height: "25px",
        paddingBottom: 0,
        width: "100%",
        fontWeight: "bold",
        paddingTop: "5px",
    };
}

export const textPrinterItem = () => {
    return {
        alignItems: "center"
    };
}


// POS
export const textPOSHeaderTypography = () => {
    return {
        fontSize: '16px',
        paddingBottom: 0,
        fontWeight: "bold",
        paddingTop: "5px",
        textAlign: "center",
        color: "#54b1d2"
    };
}

export const textPosItemTypography = () => {
    return {
        fontSize: 'md',
        textAlign: "left",
        height: "25px",
        paddingBottom: 0,
        width: "100%",
        fontWeight: "bold",
        paddingTop: "5px",
    };
}

export const linkStyle = () => {
    return {
        color: "#2596be",
        textDecoration: "none"
    };
}

// WIFI
export const textWifiItemTypography = () => {
    return {
        fontSize: 'md',
        textAlign: "left",
        height: "25px",
        paddingBottom: 0,
        width: "100%",
        fontWeight: "bold",
        paddingTop: "5px",
    };
}
export const activeWifiIcon = () => {
    return {
        // boxShadow: "0px 0px 15px 0px #dcdcdc",
        fontSize: 13,
        marginLeft: "-30px",
        paddingTop: "80px",
        zIndex: "1000"
    };
}

export const textWifiHeaderTypography = () => {
    return {
        fontSize: '16px',
        paddingBottom: 0,
        fontWeight: "bold",
        paddingTop: "5px",
        textAlign: "center",
        color: "#54b1d2"
    };
}
export const getWifiCardProperties = () => {
    return {
        border: "1px dashed lightgrey",
        ":hover": { backgroundColor: "#ebf5f9" },
        width: "320px",
        height: "190px",
        p: 0,
        m: 0,
        flexWrap: 'wrap',
        borderRadius: "20px",
        margin: "20px",
        marginTop: "30px",
        boxShadow: 2,
        overflow: "visible"
    };
}


export const appBarIcons = () => {
    return {
        color: "#205d7b",
        textDecoration: "none"
    };
}

export const formSaveButton = () => {
    return {
        backgroundColor: getThemeColor()[getTheme()]["fabBackgroundColor"],
        color: getThemeColor()[getTheme()]["fabFontColor"],
        fontWeight: "600",
        marginTop: "20px",
        letterSpacing: "3px",
        width: "130px",
        ":hover": {
            backgroundColor: getThemeColor()[getTheme()]["fabBackgroundColorHover"],
            color: getThemeColor()[getTheme()]["fabFontColor"],
        },
    };
}

export const formAddButton = () => {
    return {
        backgroundColor: getThemeColor()[getTheme()]["fabBackgroundColor"],
        color: getThemeColor()[getTheme()]["fabFontColor"],
        // fontWeight: "900",
        marginTop: "20px",
        ":hover": {
            backgroundColor: getThemeColor()[getTheme()]["fabBackgroundColorHover"],
            color: getThemeColor()[getTheme()]["fabFontColor"],
        },
    };
}


export const pageAddButton = () => {
    return {
        backgroundColor: getThemeColor()[getTheme()]["addButtonBackgroundColor"],
        color: getThemeColor()[getTheme()]["addButtonFontColor"],
        border: `1px solid ${getThemeColor()[getTheme()]["addButtonBackgroundColor"]}`,
        fontWeight: "600",
        textTransform: "initial",
        marginLeft: "20px",
        // marginTop: "1.5%",
        ":hover": {
            backgroundColor: getThemeColor()[getTheme()]["addButtonFontColor"],
            color: getThemeColor()[getTheme()]["addButtonBackgroundColor"],
        },
    };
}

export const viewButton = () => {
    return {
        backgroundColor: getThemeColor()[getTheme()]["viewButtonBackgroundColor"],
        color: "white",
        ":hover": {
            backgroundColor: getThemeColor()[getTheme()]["viewButtonBackgroundColorHover"],
            color: "white"
        },
    };
}

// ============= PRINTER
export const printerSectionButtonAll = () => {
    return {
        ...formSaveButton(),
        height: "100%",
        width: "100%",
        fontSize: "1rem",
        marginTop: "0",
        fontWeight: 600
    };
}

// ============== SECTION
export const mySectionsButtonAll = () => {
    return {
        height: "100%",
        width: "100%",
        fontSize: "1rem",
        marginTop: "0",
        fontWeight: 600,
        backgroundColor: getThemeColor()[getTheme()]["addButtonBackgroundColor"],
        color: getThemeColor()[getTheme()]["addButtonFontColor"],
        border: `1px solid ${getThemeColor()[getTheme()]["addButtonBackgroundColor"]}`,
        textTransform: "initial",
    };
}


export const pickupDisplayIconButtons = () => {
    return {
        borderRadius: "20px",
        minWidth: "40px",
        maxHeight: "40px"
    }
};

export const pickupDisplayStack = () => {
    return {
        alignItems: "center",
        boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)"
    }
};

export const pickupDisplayStackGrid = () => {
    return {
        justifyContent: "center",
        padding: "30px",
        height: "100vh"
    }
};

export const pickupDisplayStackGridItemHeader = () => {
    return {
        textAlign: "center",
        borderBottom: "2px solid white",
        marginBottom: "20px",
        height: "90px",
        maxHeight: "90px"
    }
};

export const pickupDisplayStackGridItem = () => {
    return {
    }
};

export const infoTooltip = () => {
    return {
        p: '10px',
        backgroundColor: 'black',
        fontSize: "14px",
        textAlign: "justify"
    };
}

export const addCardStyle = () => {
    return {
        border: "1px dashed lightgrey",
        ":hover": { backgroundColor: "#ebf5f9" },
        ...getLoyaltyCardProperties(),
        marginTop: "40px"
    };
}

export const addCardActionAreaStyle = () => {
    return {
        display: "grid",
        height: "100%",
        width: "100%"
    };
}

export const addCardActionAreaButtonStyle = () => {
    return {
        textAlign: "center",
        backgroundColor: "transparent"
    };
}

export const addCardActionAreaButtonIconStyle = () => {
    return {
        fontSize: "40px",
        width: "80px",
        height: "80px",
        color: "lightgrey",
        borderRadius: "50px",
        border: "1px dashed lightgrey",
        marginTop: "10%",
        backgroundColor: "transparent",
        ":hover": {
            backgroundColor: "#ebf5f9",
            color: "white"
        }
    };
}

export const loyaltyBackgroundButtonActiveStyle = () => {
    return {
        color: "#1976d2",
        borderRadius: "10px",
        border: "1px solid grey"
    };
}

export const addWorkingHoursButtonIconStyle = () => {
    return {
        fontSize: "20px",
        width: "40px",
        height: "40px",
        color: "lightgrey",
        borderRadius: "50px",
        border: "1px dashed lightgrey",
        marginTop: "10%",
        backgroundColor: "transparent",
        ":hover": {
            backgroundColor: "#ebf5f9",
            color: "white"
        }
    };
}

// ==================Ticket


export const ticketGridStyle = () => {
    return {
        marginBottom: "30px",
        border: "2px solid lightgrey",
        borderRadius: "10px",
        paddingRight: "20px",
        paddingBottom: "20px"
    };
};

// ==================Fieldset style

export const fieldsetBox = (open) => {
    return {
        borderRadius: "5px",
        padding: "0px",
        border: open ? '1px solid lightgrey' : "none"
    };
}

export const fieldsetBoxContainer = () => {
    return {
        margin: "0px 5px 15px 0px",
        // width: "calc(100% - 16px)"
    };
}

export const fieldsetBoxLegend = () => {
    return {
        color: "grey",
        textAlign: "left",
        marginLeft: "30px"
    };
}

export const fieldsetBoxLegendCollapsable = () => {
    return {
        ...fieldsetBoxLegend(),
        cursor: "pointer"
    };
}


// ==================Fields style

export const labelStyle = () => {
    return {
        textAlign: "left",
        display: "block",
        fontWeight: "bold",
        marginBottom: "5px",
        fontSize: "13px",
        color: "#818181"
    };
};

export const selectStyle = () => {
    return {
        width: "100%",
        textAlign: "left",
        "& .MuiInputBase-root-MuiOutlinedInput-root": {
            borderRadius: "30px",
            width: "100%"
        }
    };
};

// ==================BUTTONS style

export const deleteButtonTicketStyle = () => {
    return {
        margin: 0,
        padding: 0,
        textAlign: "right",
        marginTop: "-15px"
    };
};


// ==================ERROR INDICATORS

export const textFieldError = () => {
    return {
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderWidth: "2px"
        },
        "& .MuiFormHelperText-root.Mui-error": {
            color: "white",
            backgroundColor: "rgba(202, 48, 48, 0.7)",
            borderRadius: 1,
            fontSize: 12,
            margin: "7px 0 0",
            padding: "2px 10px",
            textAlign: "center",
            width: "fit-content",
            alignSelf: "center",
            ":before": {
                borderBottom: "5px solid #ca3030",
                borderLeft: "3px solid transparent",
                borderRight: "3px solid transparent",
                content: '""',
                position: "relative",
                top: "-22px",
                left: "50%"
            }
        }
    };
}

export const selectError = () => {
    return {
        color: "white",
        backgroundColor: "rgba(202, 48, 48, 0.7)",
        borderRadius: 1,
        fontSize: 12,
        margin: "7px 0 0",
        padding: "4px 10px",
        textAlign: "center",
        width: "fit-content",
        alignSelf: "center",
        display: "inline-table",
        ":before": {
            borderBottom: "5px solid #ca3030",
            borderLeft: "3px solid transparent",
            borderRight: "3px solid transparent",
            content: '""',
            position: "relative",
            top: "-24px",
            left: "50%"
        }
    };
}

// ==================SPLASH SCREEN

// export const splashScreenContainer = () => {
//     return {
//         position: "relative",
//         height: "100vh",
//         width: "100vw"
//     };
// }
export const splashScreenContainer = (seconds = 5) => {
    return {
        // position: "absolute",
        textAlign: "center",
        height: "100%",
        width: "100%",
        minHeight: "300px",
        //animate
        // background: "linear-gradient(90deg, #fafafa 0px, #d9d9d9 50%, #fafafa 100%)",
        backgroundSize: "100% 100%",
        animation: `${SplashScreenAnimation} ${seconds}s ease-in-out`
    };
}
const SplashScreenAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const splashScreenContainerImage = () => {
    return {
        width: "50%",
        height: "50%"
        // position: "absolute",
        // top: "50%",
        // left: "50%"
    };
}
export const splashScreenContainerTextContainer = () => {
    return {
        // position: "absolute",
        bottom: "20px",
        width: "100%"
    };
}
export const splashScreenContainerTextContainerText = () => {
    return {
        fontSize: "30px",
        color: "#fff",
        textAlign: "center",
        fontFamily: "'Pacifico', cursive",
        paddingTop: "20px"
    };
}

// ==================ITEM ALLERGIES
export const allergyButton = () => {
    return {
        width: "100%",
        color: getThemeColor()[getTheme()]["addButtonBackgroundColor"],
        fontWeight: "600",
        fontSize: "13px",
        textTransform: "initial",
        padding: "15px",
        ":hover": {
            backgroundColor: "#54b1d273",
            color: getThemeColor()[getTheme()]["addButtonFontColor"],
        }
    }
}
export const allergyButtonIcon = () => {
    return {
        margin: 0,
        padding: "0 10px",
        left: 0,
        position: "absolute"
    };
}

// ==================ITEM INVENTORY

export const inventoryButton = (available) => {
    return {
        backgroundColor: available ? "#5ecbeb" : "#f6b623",
        color: "white",
        fontWeight: "600",
        letterSpacing: "3px",
        borderRadius: 25,
        border: "none",
        ":hover": {
            backgroundColor: available ? "#38b3d7" : "#d19712",
            color: "white",
        },
    };
}



// ================== MENU ITEM

export const exportItemDialogStyle = () => {
    return {
        "& .MuiFilledInput-input": {
            backgroundColor: "white",
        },
        "& .MuiFilledInput-root": {
            backgroundColor: "white",
            border: "1px solid grey"
        },
        "& .MuiInputLabel-root": {
            backgroundColor: "white",
            zIndex: 111111111,
            padding: 0
        },
        "& .MuiDialogContent-root": {
            backgroundColor: "white",
            paddingBottom: "100px"
        },
        "& .MuiDialog-paper": {
            minWidth: "60%",
            maxWidth: "90%",
            minHeight: "70%",
            maxHeight: "90%",
            overflowY: "hidden",
        },
        "& .MuiDialog-container": {
            textAlign: "-webkit-center"
        },
        "& .MuiListItemText-secondary": {
            fontWeight: 700,
            fontSize: "11px"
        },
        "& .MuiAutocomplete-secondary": {
            overflow: "scroll"
        },
        "& .MuiAutocomplete-listbox": {
            marginTop: 0,
            padding: 0,
            height: "100%"
        },
        "& .MuiAutocomplete-popper": {
            height: "fit-content"
        },
        "& .MuiAutocomplete-paper": {
            marginLeft: "25px",
            marginRight: "25px",
            // overflowY: "hidden",
        },
        "& .MuiChip-label": {
            whiteSpace: "pre-wrap!important"
        },
        "& .MuiChip-root": {
            padding: "7px!important",
            marginBottom: "10px",
            height: "fit-content"
        },
        "& .MuiDialogActions-root": {
            paddingRight: "50px",
            zIndex: "1000000000"
        }
    }
}

export const menuItemHeaderTextStyle = (menuIitemStyle) => {
    return {
        fontWeight: "bold",
        wordWrap: "break-word",
        textWrap: "wrap",
        ...menuIitemStyle,
    }
}

export const menuItemDescriptionHeaderTextStyle = (menuIitemStyle) => {
    return {
        wordWrap: "break-word",
        textWrap: "wrap",
        ...menuIitemStyle,
    }
}

export const menuItemPriceHeaderTextStyle = (menuIitemStyle) => {
    return {
        marginTop: "10px",
        marginBottom: "10px",
        fontWeight: "bold",
        wordWrap: "break-word",
        textWrap: "wrap",
        ...menuIitemStyle,
    }
}

export const menuItemPriceIconStyle = (menuIitemStyle) => {
    return {
        placeItems: "center",
        paddingTop: "2px",
        iconPositionStart: false,
        ...menuIitemStyle,
    }
}

export const menuItemPhotoGridStyle = (isMobile) => {
    return {
        padding: "10px",
        // margin: "20px",
        marginLeft: "0px",
        marginTop: "0px",
        borderRadius: "15px",
        border: isMobile ? "none" : "1px solid lightgrey",
        width: "100%",
        textAlign: "left"
    }
}

export const itemNutrientSubDetailTitleStyle = () => {
    return {
        fontSize: "9px",
        ...lumanosimoTitleStyle(),
        overflow: "initial",
        paddingLeft: "10px"
    }
}


export const itemNutrientDetailGramsTitleStyle = () => {
    return {
        fontSize: "10px",
        ...lumanosimoTitleStyle(),
        overflow: "initial"
    }
}

export const itemNutrientDetailTextStyle = () => {
    return {
        fontSize: "10px",
        overflow: "initial"
    }
}


// ==================== Item Nutrients
export const itemNutrientText = () => {
    return {
        ...lumanosimoTitleStyle(),
        fontSize: "10px",
        color: "#b4b4b4"
    }
}
export const itemNutrientBoldText = () => {
    return {
        fontWeight: "bold",
        ...itemNutrientText()
    }
}

export const itemNutrientCardTextStyle = () => {
    return {
        ...lumanosimoTitleStyle(),
        fontSize: "11px",
        // marginRight: "10px",
        padding: "10px 5px",
        textWrap: "balance",
        textAlign: "center",
        background: "#f2f2f2",
        borderRadius: "10px",
        alignSelf: "center"
    }
}

export const itemNutrientDetailTitleStyle = () => {
    return {
        fontSize: "12px",
        ...lumanosimoTitleStyle(),
        overflow: "initial"
    }
}

// ================== CROPPER
export const cropperBasicButtonIcon = () => {
    return {
        ml: 1,
        color: "white"
    };
}
export const cropperButtonsBox = () => {
    return {
        display: 'flex',
        placeContent: 'center',
        background: "black",
        padding: "10px",
    };
}

export const cropperModeButtonIcon = () => {
    return {
        textTransform: "initial",
        padding: "5px",
        marginLeft: "15px",
        borderRadius: "20px",
        backgroundColor: "transparent",
        ":hover": {
            ...cropperModeButtonActiveIcon()
        }
    };
}

export const cropperModeButtonActiveIcon = () => {
    return {
        backgroundColor: "#54b1d273",
        color: "#1976d2",
        border: "1px solid #1976d2",
        padding: "5px",
        marginLeft: "15px",
        borderRadius: "20px",
    };
}

// ================== QRCODE
export const qrcodeExportButtonAll = () => {
    return {
        ...formSaveButton(),
        height: "58px",
        width: "100%",
        fontSize: "1rem",
        marginTop: "20px",
        fontWeight: 600
    };
}

// ================== CUSTOMER REQUEST

export const customerRequestEmptyInfoTextStyle = () => {
    return {
        ...lumanosimoTitleStyle(),
        fontSize: "12px",
        mt: 0,
        marginLeft: "20px",
        marginRight: "20px",
        textWrap: "balance",
        textAlign: "center"
    }
}

export const pastCustomerRequestsMoreButtonStyle = () => {
    return {
        marginTop: "25px",
        padding: "15px 25px",
        fontSize: "13px",
        color: "black",
        borderRadius: "30px",
        textTransform: "initial"
    }
}


export const customerRequestTextStyle = () => {
    return {
        fontWeight: "bold",
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "normal",
        textOverflow: "ellipsis",
        fontSize: "12px",
        color: "grey",
        paddingBottom: "2px"
    }
}

export const customerRequestDateTextStyle = () => {
    return {
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "10px",
        color: "grey"
    }
}


export const requestButtonIcon = () => {
    return {
        fontSize: "25px!important"
    }
}

export const requestButtonFontAwesomeIcon = () => {
    return {
        fontSize: "25px!important",
        width: 25,
        height: 25
    }
}

export const requestButtonSvgIcon = () => {
    return {
        width: 25,
        height: 25,
        fill: "#54b1d2"
    }
}

export const requestButton = () => {
    return {
        width: "100%",
        height: "100%",
        color: "#54b1d2",
        fontWeight: "900",
        fontSize: "18px",
        textTransform: "initial",
        padding: "20px",
        ":hover": {
            backgroundColor: "#54b1d273",
            color: "#54b1d2",
            border: "1px solid #54b1d2"
        }
    }
}


export const requestPdaButtonStyle = (disabled = false) => {
    return {
        // position: position,
        // bottom: 16,
        // right: 16,
        borderRadius: "10px",
        backgroundColor: disabled ? 'lightgrey' : "#54b1d2",
        color: "white",
        textTransform: "none",
        ":hover": {
            backgroundColor: "#54b1d273",
            color: "white",
        }
    }
}
export const requestFabButtonStyle = (disabled = false, position = 'fixed') => {
    return {
        position: position,
        bottom: 16,
        right: 16,
        backgroundColor: disabled ? 'lightgrey' : "#54b1d2",
        color: "white",
        textTransform: "none",
        ":hover": {
            backgroundColor: "#54b1d273",
            color: "white",
        }
    }
}
export const requestTitleStyle = () => {
    return {
        fontSize: "18px",
        ...lumanosimoTitleStyle()
    }
}

export const requestTableLabelChipStyle = () => {
    return {
        fontSize: "10px",
        padding: "4px",
        color: "#4CAF50",
        backgroundcolor: "#dcf7dd"
    }
}

export const requestTextareaBoxStyle = () => {
    return {
        display: 'flex',
        borderTop: '1px solid',
        borderColor: 'divider',
        flex: 'auto',
    }
}

export const extraRequestButtonGroupStackButtonIcon = () => {
    return {
        marginRight: "0px"
    }
}

export const addExtraRequestButtonIconStyle = () => {
    return {
        fontSize: "20px",
        width: "40px",
        height: "40px",
        color: "lightgrey",
        borderRadius: "50px",
        border: "1px dashed lightgrey",
        marginTop: "10%",
        backgroundColor: "transparent",
        ":hover": {
            backgroundColor: "#ebf5f9",
            color: "white"
        }
    };
}
export const extraRequestButtonGroupStack = () => {
    return {
        right: 16,
        top: 0,
        position: "relative",
        zIndex: 10,
        justifyContent: "right"
    }
}
export const extraRequestButtonGroupStackButton = () => {
    return {
        borderRadius: "50px",
        color: "white",
        padding: "8px 0px",
        minWidth: "40px",
        marginRight: "10px",
        marginTop: "1.5%",
        boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)"
    }
}


// ===================== TABLES

export const pastOrderLogoStyle = () => {
    return {
        height: "50px",
        width: "auto",
        borderRadius: "30px",
        border: "1px solid lightgrey",
        boxShadow: "0px 3px 9px 0px #00000029",
    };
}

export const pastOrderViewHeaderTextStyle = () => {
    return {
        fontWeight: "bold",
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "16px",
        color: "grey",
        textAlign: "center",
        paddingBottom: "20px",
        ...lumanosimoTitleStyle()
    }
}

export const pastOrderOutletTextStyle = () => {
    return {
        fontWeight: "bold",
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "normal",
        textOverflow: "ellipsis",
        fontSize: "12px",
        color: "grey",
        paddingBottom: "2px"
    }
}

export const pastOrderDateTextStyle = () => {
    return {
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "10px",
        color: "grey"
    }
}


export const pastOrderDateTextBoldStyle = () => {
    return {
        ...pastOrderDateTextStyle(),
        fontWeight: "bold",
    }
}
export const pastOrderCartTextStyle = () => {
    return {
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "10px",
        color: "grey"
    }
}

export const orderTextStyle = () => {
    return {
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "normal",
        textOverflow: "ellipsis",
        fontSize: "10px",
        color: "grey"
    }
}

export const orderIconStyleSmall = () => {
    return {
        fontSize: "10px",
        color: "grey",
        border: "1px solid lightgrey",
        padding: "5px",
        borderRadius: "30px"
    }
}

export const getOrderAllergyIconStyle = () => {
    return {
        width: 40,
        height: 40,
        fill: "#54b1d2"
    }
}
export const getItemExtraIconStyle = () => {
    return {
        width: 20,
        height: 20
    }
}

export const orderItemFontAwesomeIconStyle = () => {
    return {
        fontSize: "15px!important",
        width: 15,
        height: 15,
        color: "black",
        border: "1px solid lightgrey",
        padding: "5px",
        borderRadius: "30px"
    }
}

export const orderItemPriceTextStyle = () => {
    return {
        display: "block",
        width: "90%",
        overflow: "hidden",
        textAlign: "justify",
        fontSize: "10px",
        color: "black"
    }
}

export const orderItemTaxTextStyle = () => {
    return {
        fontWeight: "bold",
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "12px",
        color: "black",
        paddingBottom: "2px",
        textAlign: "left",
        marginLeft: "10px"
    }
}

export const orderItemButtonStyle = (buttonBackgroundColor, buttonFont, buttonColor, buttonFontSize) => {
    return {
        background: buttonBackgroundColor,
        fontFamily: buttonFont,
        color: buttonColor,
        fontSize: buttonFontSize
    }
}

export const orderItemTaxPriceTextStyle = () => {
    return {
        fontWeight: "bold",
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "12px",
        color: "black",
        paddingBottom: "2px",
        paddingLeft: "8px",
        textAlign: "left",
    }
}

export const orderItemTotalTextStyle = () => {
    return {
        fontWeight: "bold",
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "12px",
        color: "black",
        paddingBottom: "2px",
        textAlign: "left",
        marginLeft: "10px"
    }
}

export const orderItemTotalPriceTextStyle = () => {
    return {
        fontWeight: "bold",
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "12px",
        color: "black",
        paddingBottom: "2px",
        paddingLeft: "8px",
        textAlign: "left",
    }
}

export const orderDiscountTextStyle = () => {
    return {
        fontWeight: "bold",
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "12px",
        color: "black",
        paddingBottom: "2px",
        textAlign: "left",
        marginLeft: "10px"
    }
}

export const orderDiscountValueTextStyle = () => {
    return {
        fontWeight: "bold",
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "12px",
        color: "black",
        paddingBottom: "2px",
        paddingLeft: "15px",
        textAlign: "left",
    }
}

export const orderItemMuiIconStyle = () => {
    return {
        fontSize: "15px!important",
        width: 15,
        height: 15,
        color: "black",
        border: "1px solid lightgrey",
        padding: "5px",
        borderRadius: "30px"
    }
}

export const orderDiscountTextsStyle = () => {
    return {
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "10px",
        color: "grey",
        marginLeft: "10px"
    }
}

// ======================= CUSTOMER
export const customerShortDetailsTextStyle = () => {
    return {
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "12px",
        color: "grey",
        placeSelf: "center"
    }
}

export const customerShortDetailsTextStyleLabel = () => {
    return {
        fontWeight: "bold",
        ...customerShortDetailsTextStyle()
    }
}

export const customerDetailsTextStyle = () => {
    return {
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "8px",
        color: "#bfb8b8",
        placeSelf: "center",
        marginBottom: "5px",
        ...lumanosimoTitleStyle()
    }
}

export const customerDetailsTextStyleLabel = () => {
    return {
        fontWeight: "bold",
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "10px",
        color: "grey",
        placeSelf: "center"
    }
}

export const customerDetailsHeaderStyleLabel = () => {
    return {
        fontWeight: "bold",
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "15px",
        color: "grey",
        placeSelf: "center",
        ...lumanosimoTitleStyle()
    }
}


export const customerDetailsGridStyle = () => {
    return {
        borderRadius: "10px",
        border: "1px solid #e3e3e378",
        width: "calc(100 % * 4 / 2)",
        boxSizing: "border-box",
        boxShadow: "rgb(234, 236, 240) 0px 0px 1px, rgba(29, 41, 57, 0.08) 0px 1px 3px",
        fabBackgroundColor: "rgb(255, 255, 255)",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

    }
}

export const customerDetailsGridDividerStyle = () => {
    return {
        borderRadius: "10px",
        border: "1px solid #00000014",
        boxSizing: "border-box",
        boxShadow: "rgb(234, 236, 240) 0px 0px 1px, rgba(29, 41, 57, 0.08) 0px 1px 3px",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

    }
}

export const customerDetailAssetStyle = () => {
    return {
        borderRadius: "10px",
        boxShadow: "rgb(234, 236, 240) 0px 0px 1px, rgba(29, 41, 57, 0.08) 0px 1px 3px",
        height: 50,
        width: 50,
        margin: "20px 0px"
    }
}

export const customerNoteTextareaBoxStyle = () => {
    return {
        display: 'flex',
        borderTop: '1px solid',
        borderColor: 'divider',
        flex: 'auto',
    }
}


export const customerNoteDateStyle = () => {
    return {
        marginTop: "10px",
        fontSize: "9px",
        color: "#b7b7b7"
    }
}


export const customerNotesGridItemStyle = () => {
    return {
        borderRadius: "10px",
        border: "1px solid #e3e3e378",
        width: "calc(100 % * 4 / 2)",
        boxSizing: "border-box",
        boxShadow: "rgb(234, 236, 240) 0px 0px 1px, rgba(29, 41, 57, 0.08) 0px 1px 3px",
        fabBackgroundColor: "rgb(255, 255, 255)",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        marginBottom: "10px"
    }
}


export const customerNotesTextAreaStyle = () => {
    return {
        borderColor: "lightgrey",
        width: "95%",
        padding: "10px",
        borderRadius: "10px",
        marginTop: "10px",
        marginBottom: "20px"
    }
}
export const customerNoteSubmitButtonIconStyle = () => {
    return {
        fontSize: "10px",
        width: "10px",
        height: "10px",
        color: "white",
        borderRadius: "50px",
        border: "1px dashed lightgrey",
        padding: "5px",
        backgroundColor: "transparent",
    };
}

export const customerNoteSubmitButtonStyle = () => {
    return {
        background: "grey",
        height: "25px",
        marginLeft: "10px",
        color: "white",
        ":hover": {
            backgroundColor: "grey",
            color: "lightgrey"
        }
    };
}

// ==========================COUPON
export const couponLogoStyle = () => {
    return {
        height: "80px",
        width: "auto"
    };
}

// ==========================SUBSCRIPTION
export const subscriptionDetailsHeaderStyle = () => {
    return {
        fontWeight: "bold",
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "15px",
        color: "grey",
        placeSelf: "center",
        ...lumanosimoTitleStyle()
    }
}

export const subscriptionDetailsLabelStyle = () => {
    return {
        fontWeight: "bold",
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "12px",
        placeSelf: "center",
    }
}

export const subscriptionDetailsTextStyle = () => {
    return {
        fontWeight: "bold",
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "12px",
        color: "grey",
        placeSelf: "center",
        ...lumanosimoTitleStyle()
    }
}


export const subscriptionPlanButtonGroupStack = () => {
    return {
        right: 16,
        top: 0,
        position: "relative",
        zIndex: 10,
        justifyContent: "right"
    }
}
export const subscriptionPlanButtonGroupStackButton = () => {
    return {
        borderRadius: "50px",
        color: "white",
        padding: "8px 0px",
        minWidth: "40px",
        marginRight: "10px",
        marginTop: "1.5%",
        boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)"
    }
}

export const subscriptionPlanButtonGroupStackButtonIcon = () => {
    return {
        marginRight: "0px"
    }
}
export const subscriptionPlanContentStackButtonIcon = () => {
    return {
        marginTop: "-10px",
        zIndex: -1
    }
}

export const addSubscriptionPlanButtonIconStyle = () => {
    return {
        fontSize: "20px",
        width: "40px",
        height: "40px",
        color: "lightgrey",
        borderRadius: "50px",
        border: "1px dashed lightgrey",
        marginTop: "10%",
        backgroundColor: "transparent",
        ":hover": {
            backgroundColor: "#ebf5f9",
            color: "white"
        }
    };
}

export const subscriptionHistoryStatusLabelStyle = (status) => {
    return {
        color: getThemeColor()[getTheme()].subscriptionStatus[status],
        fontWeight: "bold",
        display: "block",
        width: "70px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "10px",
        placeSelf: "center",
    }
}

export const subscriptionHistoryDetailsLabelStyle = () => {
    return {

        fontWeight: "bold",
        display: "block",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "10px",
        placeSelf: "center",
    }
}

export const subscriptionHistoryDetailsTextStyle = () => {
    return {

        fontWeight: "bold",
        display: "block",
        color: "grey",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "8px",
        placeSelf: "center",
        ...lumanosimoTitleStyle()
    }
}
export const subscriptionHistoryDetailsTextPriceStyle = () => {
    return {

        fontWeight: "bold",
        display: "block",
        color: "lightblue",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "8px",
        placeSelf: "center",
        ...lumanosimoTitleStyle()
    }
}

export const subscriptionDetailsHistoryDateTextStyle = () => {
    return {
        fontWeight: "bold",
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "8px",
        color: "grey",
        placeSelf: "center",
        ...lumanosimoTitleStyle()
    }
}

export const subscriptionHistoryStatusLabelStyleDot = (status) => {
    return {
        backgroundColor: getThemeColor()[getTheme()].subscriptionStatus[status] + "57",
        padding: "5px 10px",
        textAlign: "center",
        borderRadius: "10px"
    }
}

export const subscriptionHistoryCardStyle = () => {
    return {
        border: "1px lightgrey solid",
        boxShadow: "rgb(234, 236, 240) 0px 0px 1px, rgba(29, 41, 57, 0.08) 0px 1px 3px",
        marginLeft: "20px",
        marginBottom: "10px",
        borderRadius: "10px",
        ...subscriptionHistoryCardGridStyle()
    }
}

export const subscriptionHistoryCardGridStyle = () => {
    return {
        py: '12px',
        px: 2
    }
}

export const getPaymentSuccessText = () => {
    return {
        ...orderDiscountTextStyle(),
        textAlign: "center",
        ...lumanosimoTitleStyle()
    }
}

// ====================== PDA

export const pastOrderAddFeedbackButtonStyle = () => {
    return {
        padding: "5px 10px",
        fontSize: "8px",
        color: "grey",
        textTransform: "initial"
    }
}

export const pastOrderPayButtonStyle = () => {
    return {
        padding: "5px 10px",
        fontSize: "8px",
        color: "grey",
        textTransform: "initial",

    }
}

export const payCloseAllLabelStyle = () => {
    return {
        fontWeight: "bold",
        wordWrap: "break-word",
        textWrap: "wrap",
        textAlign: "justify"
    }
}

export const payCloseAllFabButtonStyle = () => {
    return {
        position: 'absolute',
        bottom: 16,
        right: 16,
    }
}

export const payCloseAllIconButtonStyle = () => {
    return {
        mt: "10px",
        width: "60px",
        height: "60px",
        padding: "10px",
        right: "15px",
        position: "absolute",
    }
}

export const payCloseAllIconDialogButtonStyle = () => {
    return {
        width: "40px",
        height: "40px",
        padding: "10px",
        fill: "white"
    }
}


export const pastOrderAddFeedbackButtonIconStyle = () => {
    return {
        fontSize: "5px",
        // color: "grey",
        ":hover": {
            color: "white"
        }
    }
}

export const menuItemButtonStyle = (buttonBackgroundColor, buttonFont, buttonColor, buttonFontSize) => {
    return {
        background: buttonBackgroundColor,
        fontFamily: buttonFont,
        color: buttonColor,
        fontSize: buttonFontSize
    }
}

export const getPDAEquipmentButtonFontAwesomeIcon = () => {
    return {
        fontSize: "50px!important",
        width: 50,
        height: 50
    }
}
export const getPDAEquipmentActionButton = (key, label) => {
    return {
        keyValue: `btn-${key}`,
        backgroundColor: "#dcf1f7",
        label: label,
        sx: {
            width: "100%",
            height: "100%",
            color: "#54b1d2",
            fontWeight: 900,
            fontSize: "14px",
            textTransform: "initial",
            flexFlow: "column",
            padding: "15px",
            ":hover": {
                backgroundColor: "#54b1d273",
                color: "#54b1d2",
                border: "1px solid #54b1d2"
            }
        },
        variant: 'outlined'
    }
}
export const getPDAEquipmentActionButtonIcon = () => {
    return {
        width: 50,
        height: 50,
        fill: "#54b1d2"
    }
}

export const getPDAEmptyPromptTextStyle = () => {
    return {
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "normal",
        textOverflow: "ellipsis",
        fontSize: "12px",
        color: "grey",
        textAlign: "justify"
    }
}


export const pastOrderMoreOrdersButtonStyle = () => {
    return {
        marginTop: "25px",
        padding: "20px",
        fontSize: "13px",
        color: "black",
        borderRadius: "30px",
        textTransform: "initial"
    }
}

export const orderEmptyInfoTextStyle = () => {
    return {
        ...lumanosimoTitleStyle(),
        fontSize: "12px",
        mt: 0,
        marginLeft: "20px",
        marginRight: "20px",
        textWrap: "balance",
        textAlign: "center"
    }
}

export const orderPDAActionsDetailsHistoryEmptyTextStyle = () => {
    return {
        fontWeight: "bold",
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "normal",
        textOverflow: "ellipsis",
        fontSize: "13px",
        color: "grey",
        marginTop: "20px",
        textAlign: "center",
        ...lumanosimoTitleStyle()
    }
}

// ================== SECTIONS STICKY 

export const sectionsAppBarBoxAppBar = () => {
    return {
        // background: 'transparent',
        width: "100%",
        // height: "60px",
        boxShadow: "none",
        border: "1px solid lightgrey",
        borderRadius: "20px",
        marginBottom: "20px",
        background: "white",
    };
}
export const sectionsAppBarBoxAppBarSticky = () => {
    return {
        // background: 'transparent',
        width: "100%",
        // height: "60px",
        marginTop: "60px",
        boxShadow: "none",
        border: "1px solid lightgrey",
        borderRadius: "20px",
        marginBottom: "20px",
        background: "white",
    };
}

// ==================== ORGANIZATION

export const organizationGridStyle = () => {
    return {
        border: "1px lightgrey solid",
        boxShadow: "rgb(234, 236, 240) 0px 0px 1px, rgba(29, 41, 57, 0.08) 0px 1px 3px",
        marginLeft: "20px",
        marginBottom: "10px",
        borderRadius: "10px",
        ...subscriptionHistoryCardGridStyle()
    }
}

export const organizationSettingsHeaderStyle = () => {
    return {
        fontWeight: "bold",
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "15px",
        color: "grey",
        placeSelf: "center",
        ...lumanosimoTitleStyle()
    }
}
// ============= ADD ORDER
export const orderAddTextStyleLabel = () => {
    return {
        fontWeight: "bold",
        ...orderAddTextStyle()
    }
}

export const orderAddLabelTextStyle = () => {
    return {
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "15px",
        color: "#bfb8b8",
        placeSelf: "center",
        marginBottom: "5px",
        ...lumanosimoTitleStyle()
    }
}
export const orderAddTextStyle = () => {
    return {
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "12px",
        color: "grey",
        placeSelf: "center"
    }
}

// ==================== RATING

export const ratingMessageTitleTextStyle = () => {
    return {
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "18px",
        color: "grey",
        paddingBottom: "2px",
        textAlign: "left",
        marginLeft: "10px",
        ...lumanosimoTitleStyle(),
    }
}

export const ratingCheckbox = () => {
    return {
        wordWrap: "break-word",
        textWrap: "wrap",
        textAlign: "justify",
        '& .MuiFormControlLabel-root .MuiFormControlLabel-label': {
            wordWrap: "break-word",
            textWrap: "wrap",
            fontSize: "16px",
            color: "black",
            marginTop: "20px",
            textAlign: "justify",
        }
    }
}

export const ratingInfoText = () => {
    return {
        wordWrap: "break-word",
        textWrap: "wrap",
        fontSize: "11px",
        color: "grey",
        marginTop: "40px",
        textAlign: "justify",
        ...lumanosimoTitleStyle(),
    }
}

export const ratingIconStyle = () => {
    return {
        fontSize: "20px",
        color: "green",
        border: "1px solid green",
        padding: "5px",
        borderRadius: "30px"
    }
}

// ================== DELETE ACCOUNT

export const deleteAccountIconStyle = () => {
    return {
        fontSize: "20px",
        color: "grey",
        border: "1px solid lightgrey",
        padding: "5px",
        borderRadius: "30px"
    }
}
export const deleteAccountDialogHeaderTextStyle = () => {
    return {
        ...lumanosimoTitleStyle(),
        fontSize: "22px",
        mt: 0,
        marginLeft: "20px",
        marginRight: "20px",
        textWrap: "balance",
        textAlign: "center",
        color: "red"
    }
}

export const deleteAccountDialogTextStyle = () => {
    return {
        display: "block",
        width: "100%",
        textAlign: "justify",
        fontSize: "16px",
        color: "grey"
    }
}

export const deleteAccountDialogActionsQuestionTextStyle = () => {
    return {
        display: "block",
        width: "100%",
        textAlign: "justify",
        fontSize: "18px",
        color: "grey"
    }
}

export const deleteAccountDialogActionsConfirmTextStyle = () => {
    return {
        display: "block",
        width: "100%",
        fontSize: "18px",
        color: "black",
        textAlign: "center",
    }
}

export const deleteAccountHeaderTextStyle = () => {
    return {
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "normal",
        textOverflow: "ellipsis",
        fontSize: "18px",
        color: "red"
    }
}

export const deleteAccountTextStyle = () => {
    return {
        display: "block",
        width: "100%",
        overflow: "hidden",
        whiteSpace: "normal",
        textOverflow: "ellipsis",
        fontSize: "12px",
        color: "grey"
    }
}

// ==================== BREADCRUMB
export const breadcrumbsLinkStyle = () => {
    return {
        color: "#47a6d2"
    }
}
export const breadcrumbsTextStyle = () => {
    return {
        color: "#a7a7a7de"
    }
}

export const breadcrumbsStyle = () => {
    return {
        padding: "10px",
        background: "white",
        borderRadius: "10px",
        width: "100%"
    }
}

// ==================== ANALYTICS
export const analyticsGridDiv = () => {
    return {
        borderRadius: "30px",
        background: "white",
        padding: "20px",
        textAlign: "-webkit-center"
    }
}

// ==================== GLOBAL
export const dividerStyle = () => {
    return {
        borderRightWidth: -5,
        width: "100%",
        margin: "20px 0px"
    }
}

export const lumanosimoTitleStyle = () => {
    return {
        fontWeight: 900,
        fontFamily: "Lumanosimo"
    }
}

export const lumanosimoTitleStylePlain = () => {
    return {
        fontFamily: "Lumanosimo"
    }
}

export const drawerPaperStyle = () => {
    return {
        textAlign: "-webkit-center",
        minWidth: "300px"
    }
}

export const orderBackBtnStyle = () => {
    return {
        color: "#000",
        backgroundColor: "#fff",
        fontWeight: 700,
        textTransform: "none",
        // padding: "20px 30px",
        height: "30px",
        fontSize: "10px",
        marginBottom: "20px"
    }
}
export const textAlignLeft = () => {
    return {
        textAlign: "left"
    }
}
export const textAlignRight = () => {
    return {
        textAlign: "right"
    }
}
export const justifyContentCenter = () => {
    return {
        justifyContent: "center"
    }
}

export const ItemPaper = styled(Paper)(({ sx }) => ({
    background: "transparent",
    padding: "0px",
    textAlign: 'center',
    boxShadow: "none",
    border: "0px",
    fontWeight: "bold",
    ...sx
}));

export const cartButtonLabelItemStyle = () => {
    return {
        width: "60%",
    }
}

export const checkoutCommentTextareaBoxInfoCharStyle = () => {
    return {
        fontSize: "12px",
        alignSelf: "self-end"
    }
}

export const cartButtonStackStyle = () => {
    return {
        width: "100%"
    }
}

export const autDrawerPaperStyle = () => {
    return {
        textAlign: "-webkit-center",
        minWidth: "300px"
    }
}

export const drawerTopStyle = (anchor) => {
    return {
        position: "sticky",
        top: "0",
        zIndex: "100",
        background: "transparent",
        textAlign: "-webkit-center",
        borderRadius: anchor === "bottom" ? "10px 10px 0px 0px" : "initial",
        paddingTop: "10px",
        marginBottom: "10px",
        // display: "flex"
    }
}