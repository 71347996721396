import Authenticate from 'containers/Authenticate';
import { AuthConsumer } from 'context/AuthContext';
import { SnackbarProvider } from "context/SnackbarContext";
import AuthenticatedHomeComponent from "pages/home/authenticated-home.component";
import React, { useEffect } from "react";
import { BrowserRouter as Router } from 'react-router-dom';
import WebFont from "webfontloader";
import Anonymous from '../Routes/Anonymous';

/**
 * The Auth controller.
 *
 * @version 1.0.0
 * @author [Gina Chatzimarkaki]
 */
function AuthController() {

    return (
        <React.Fragment>
            <AuthConsumer>
                {({ isAuthenticated, user, handleLogoutNoRequest, getAuthenticatedUser, setIsAuthenticated }) => (
                    isAuthenticated ? (
                        <AuthenticatedHomeComponent
                            user={user}
                            handleLogoutNoRequest={handleLogoutNoRequest}
                            key={"auth-routes"}
                        />
                    ) : (
                        <Anonymous
                            key={"anon-routes"}
                        // getAuthenticatedUser={getAuthenticatedUser}
                        // setIsAuthenticated={setIsAuthenticated}
                        />
                    )
                )}
            </AuthConsumer>
        </React.Fragment>
    )
}

function App() {
    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Lumanosimo']
            }
        });
    }, []);

    return (
        <Router>
            <Authenticate>
                <SnackbarProvider>
                    <AuthController />
                </SnackbarProvider>
            </Authenticate>
        </Router>
    )
}

export default App;