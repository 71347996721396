import CloseRounded from '@mui/icons-material/CloseRounded';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    Stack,
    Switch
} from '@mui/material';
import { Document, Font, Image, PDFViewer, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomQRCode from 'components/QRCode/CustomQRCode';
import { QRCODE_REDIRECT_HOST } from 'constants/hosts';
import html2canvas from 'html2canvas';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCardPropertiesExport, getCardPropertiesExportRender, qrcodeTemplateButton } from 'themes/qrcode/defaultTheme';
// Import the font file
import lumanosimoFont from 'assets/font/Lumanosimo-Regular.ttf';
import ReactDOM from 'react-dom';


// Register the font
Font.register({ family: 'Lumanosimo', src: lumanosimoFont });

/**
 * The ExportQrInstanceDialog, that display a customized dialog to select equipments to export the qrcodes. 
 * 
 * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
 * @property {string} id the dialog id
 * @property {string} title the dialog title
 * @property {string} cancelLabel the dialog cancel label
 * @property {string} actionLabel the dialog action label
 * @property {function} action the function that handles the dialog action button click
 * @property {function} handleOpen the function that handles the dialog open state
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function ExportQrInstanceDialog({
    isOpen = false,
    id = "",
    title,
    cancelLabel,
    actionLabel,
    handleOpen,
    boldMessage,
    actionColor = "#3593D2",
    qrCodeTemplates,
    organizationID = "",
    qrCodePathNumber,
    logo = "",
}) {
    const { t } = useTranslation();

    // @property {boolean} if the values is `true`, the modal should be open and visible.
    const [open, setOpen] = useState(isOpen);
    const [selectedQrcodeTemplateIndex, setSelectedQrcodeTemplateIndex] = useState();
    const [qrComponent, setQrComponent] = useState("");
    const [sign, setSign] = useState(true);

    const [logoToBase64, setLogoToBase64] = useState("");

    async function imageUrlToBase64(imageUrl) {
        try {
            // Fetch the image from the URL
            const response = await fetch(imageUrl);

            // Ensure the response is successful
            if (!response.ok) {
                throw new Error('Failed to fetch the image');
            }

            // Convert the image to Blob format
            const blob = await response.blob();

            // Create a FileReader to read the Blob data as Base64
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    // FileReader.result contains the Base64 data
                    // We create an object that contains both the Base64 data and the filename
                    const result = {
                        filename: `${organizationID}_waitlist.pdf`,
                        base64Data: reader.result
                    };
                    resolve(result);
                };
                reader.onerror = () => {
                    reject(new Error('Failed to read the image as Base64'));
                };
                reader.readAsDataURL(blob);
            });
        } catch (error) {
            console.error('Error converting image to Base64:', error);
            return null;
        }
    }

    useEffect(() => {
        imageUrlToBase64(logo)
            .then((base64Data) => setLogoToBase64(base64Data))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Function that handles the visualize modal open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleDialogState(isOpen) {
        setOpen(isOpen);
        handleOpen(isOpen);
    }

    // Create styles
    const styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
            backgroundColor: 'white'
        },
        text: {
            fontSize: "30px",
            fontWeight: 900,
            fontFamily: "Lumanosimo",
            paddingBottom: "10px"
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1,
            justifyContent: 'center', // Center vertically
            alignItems: 'center', // Center horizontally
        },
        qrCode: {
            marginLeft: 10,
            marginRight: 10,
        },
        canvas: {
            width: 794,
            height: 1000
        },
        image: {
            width: 500,
            // height: "auto"
        }
    });


    function exportQRcode() {
        if (selectedQrcodeTemplateIndex > -1) {

            const qrCodeSVG = <CustomQRCode
                {...qrCodeTemplates[selectedQrcodeTemplateIndex]}
                index={selectedQrcodeTemplateIndex}
                value={qrCodePathNumber ? `${QRCODE_REDIRECT_HOST}${qrCodePathNumber}` : ""}
                logoImage={qrCodeTemplates[selectedQrcodeTemplateIndex]?.icon === "logoToBase64" ? logoToBase64.base64Data : ""}
                frameText={qrCodeTemplates[selectedQrcodeTemplateIndex]?.frameText.includes("%table_label") ? qrCodeTemplates[selectedQrcodeTemplateIndex].frameText.replace("%table_label", "") : qrCodeTemplates[selectedQrcodeTemplateIndex]?.frameText}
                scaleFactor={5}
            />
            // Create a temporary div element
            const tempDiv = document.createElement('div');

            // Render the qrCodeSVG React element into the tempDiv
            ReactDOM.render(qrCodeSVG, tempDiv);

            // Get the canvas element from the temporary div
            const canvas = tempDiv.querySelector('canvas');


            const qrCodeHtml = document.querySelector(`#pdf`);
            html2canvas(qrCodeHtml).then(canvas => { // Adjust the scale value
                canvas.getContext('2d', { willReadFrequently: true });
                // Convert canvas to image data URL
                const imageDataUrl = canvas.toDataURL('image/png')
                    .replace("image/png", "image/octet-stream");
                setQrComponent(
                    <Page size="A4" style={styles.page}>
                        <View style={styles.section}>
                            <Text style={styles.text}>{`Scan to Join the Waitlist`}</Text>
                            <Image src={imageDataUrl} style={styles.image}></Image>
                        </View>
                    </Page>
                );
            })
            // Convert the larger canvas to a data URL
            // const dataUrl = canvas.toDataURL();

            //     setQrComponent(
            //         <Page size="A4" style={styles.page}>
            //             <View style={styles.section}>
            //                 <Text style={styles.text}>{`Scan for Menu`}</Text>
            //                 {dataUrl && <Image src={dataUrl} style={styles.image} />}
            //             </View>
            //         </Page>
            //     );
        }
    }


    return (
        <Dialog
            id={id}
            open={open}
            onClose={() => { handleDialogState(false) }}
            aria-describedby="alert-dialog-slide-description"
            className="dialog-title"
            sx={{
                '& .MuiDialog-paper': {
                    width: "90%",
                    maxWidth: "90%"
                }
            }}
        >
            <DialogTitle className="dialog-headers">
                {title}
                <IconButton onClick={() => { handleDialogState(false) }} sx={{ float: "right" }}>
                    <CloseRounded />
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{ background: "white" }}>
                <DialogContentText id="alert-dialog-slide-description" className="dialog-desciption">
                    <span>{t('waitlist.selectTemplateWaitlist')}</span>
                    <span>{boldMessage}</span>
                </DialogContentText>

                {qrCodeTemplates?.length === 0 &&
                    <span>{t('waitlist.selectTemplateEmpty')}</span>
                }

                {selectedQrcodeTemplateIndex > -1 && 
                    <span style={{ fontSize: "18px", color: "green" }}>{t('actions.exportQR.viewMultiplePagePdfPromt')}</span>
                }

                {qrCodeTemplates.length > 0 &&
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={6}>
                            <Stack direction="row" sx={{ padding: "10px", width: "calc(100% - 40px)", alignItems: "center" }}>
                                <Grid container gap={2}>
                                    {qrCodeTemplates?.map((qrcodeTemplate, index) => (
                                        <Grid>
                                            <Stack direction="row" sx={{ ...getCardPropertiesExport() }} key={`stack-card-${index}`}>
                                                <CustomIconButton
                                                    backgroundColor="#dcf1f7"
                                                    sx={qrcodeTemplateButton()}
                                                    onClick={() => setSelectedQrcodeTemplateIndex(index)}
                                                    isFocused={selectedQrcodeTemplateIndex === index}
                                                    icon={
                                                        <CustomQRCode
                                                            {...qrcodeTemplate}
                                                            value={"https://app.usee.gr"}
                                                            logoImage={qrcodeTemplate?.icon === "logo" ? logoToBase64.base64Data : ""}
                                                            preview={true}
                                                            sign={sign}
                                                        />
                                                    }
                                                />
                                            </Stack>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Stack>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <PDFViewer height="100%" width="100%">
                                <Document renderingMode="svg" resolution={2400}>
                                    {qrComponent}
                                </Document>
                            </PDFViewer>
                        </Grid>
                    </Grid>
                }


                <div id="pdf" style={{ width: "fit-content" }}>
                    {selectedQrcodeTemplateIndex > -1 && 
                        <Stack direction="row" sx={{ ...getCardPropertiesExportRender() }} key={`stack-card-0`}>
                            <CustomQRCode
                                {...qrCodeTemplates[selectedQrcodeTemplateIndex]}
                                index={selectedQrcodeTemplateIndex}
                                value={qrCodePathNumber ? `${QRCODE_REDIRECT_HOST}${qrCodePathNumber}` : ""}
                                logoImage={qrCodeTemplates[selectedQrcodeTemplateIndex]?.icon === "logo" ? logoToBase64.base64Data : ""}
                                frameText={qrCodeTemplates[selectedQrcodeTemplateIndex]?.frameText.includes("%table_label") ? qrCodeTemplates[selectedQrcodeTemplateIndex].frameText.replace("%table_label", "") : qrCodeTemplates[selectedQrcodeTemplateIndex]?.frameText}
                                scaleFactor={5}
                            />
                        </Stack>
                    }
                </div>
            </DialogContent>

            <DialogActions className="dialog-actions">
                <Button className="dialog-cancel-btn" onClick={() => { handleDialogState(false) }}>{cancelLabel}</Button>
                <Button className="dialog-action-btn" sx={{ color: actionColor, ":hover": { backgroundColor: actionColor + "52" } }} onClick={() => { exportQRcode() }}>{actionLabel.toUpperCase()}</Button>
            </DialogActions>
        </Dialog>
    );
}
export default ExportQrInstanceDialog;