import { Box, Grid } from '@mui/material';
import Popover from '@mui/material/Popover';
import CustomIconButton from 'components/Button/CustomIconButton';
import { useTranslation } from 'react-i18next';
import { orderAddTypeButtonIcon } from 'themes/defaultThemes';

function OrderTypePopover({ anchorEl, open, handleClose, inputValue, handleUpdate }) {
    const id = open ? 'simple-popover' : undefined;
    const { t } = useTranslation();

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Box style={{ padding: '20px' }}>
                <Grid container gap={1}>
                    <Grid item xs="auto">
                        <CustomIconButton
                            keyValue={`btn-dine-in`}
                            onClick={() => handleUpdate("DINE_IN")}
                            label={t('cart.orderType.DINE_IN')}
                            sx={orderAddTypeButtonIcon()}
                            variant='outlined'
                            // backgroundColor="#dcf1f7"
                            isFocused={inputValue === "DINE_IN"}
                        />
                    </Grid>

                    <Grid item xs="auto">
                        <CustomIconButton
                            keyValue={`btn-PICKED_UP`}
                            onClick={() => handleUpdate("PICKED_UP")}
                            label={t('cart.orderType.PICKED_UP')}
                            sx={orderAddTypeButtonIcon()}
                            variant='outlined'
                            // backgroundColor="#dcf1f7"
                            isFocused={inputValue === "PICKED_UP"}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Popover>
    );
}

export default OrderTypePopover;