import {
    Add as AddIcon,
    Delete as DeleteIcon,
    Save as SaveIcon,
    SignLanguage as SignLanguageIcon,
    WorkOff as WorkOffIcon
} from '@mui/icons-material';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { TabContext, TabPanel } from '@mui/lab';
import { Button, CardMedia, Divider, Fab, FormControlLabel, Grid, InputLabel, Stack, SvgIcon, Switch, TextField } from "@mui/material";
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import outletApi from 'api/outlet';
import en from "assets/images/flags/en.png";
import CustomFontAwesomeIconPicker from 'components/Assets/CustomFontAwesomeIconPicker';
import CustomImageCropper from 'components/Assets/CustomImageCropper';
import CustomAddEditBreadcrumbMultiParent from 'components/Breadcrumb/CustomAddEditBreadcrumbMultiParent';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomAddQrcodeTemplateCard from 'components/Card/qrcode/CustomAddQrcodeTemplateCard';
import CustomQrcodeTemplate from 'components/Card/qrcode/CustomQrcodeTemplate';
import CustomCheckbox from 'components/Checkbox/CustomCheckbox';
import CustomChip from 'components/Chip/CustomChip';
import CustomTranslationsDialog from 'components/Dialogs/CustomTranslationsDialog';
import AddEditQRcodeTemplate from 'components/Dialogs/qrcode/AddEditQRcodeTemplate';
import CustomFieldset from 'components/Fieldset/CustomFieldset';
import CustomGrid from 'components/Grid/CustomGrid';
import PhotoComponent from 'components/Grid/photo/PhotoComponent';
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomMenuLayoutButtons from 'components/Menu/CustomMenuLayoutButtons';
import CustomQRCode from 'components/QRCode/CustomQRCode';
import CustomRating from 'components/Rating/CustomRating';
import CustomAutocompleteTimezoneSelect from 'components/Select/CustomAutocompleteTimezoneSelect,';
import CustomSelectMultilingual from 'components/Select/CustomSelectMultilingual';
import CustomSelectWithChipMultilingual from 'components/Select/CustomSelectWithChipMultilingual';
import CustomSnackbar from "components/Snackbar/CustomSnackbar";
import Splashscreen from 'components/Splash/SplashScreen';
import CustomTabs from 'components/Tab/CustomTabs';
import CustomFlagTextField from 'components/Text/CustomFlagTextField';
import CustomTextField from 'components/Text/CustomTextField';
import CustomTypography from 'components/Typography/CustomTypography';
import { getAssetDefaultObject, getDayWorkingHoursDefaultObject, getDefaultQrcodeTemplate, getExtraCustomRequestButtonDefaultObject, getExtraOutletActionButtonsDefaultObject } from 'constants/defaultObjects';
import { getFontSize } from 'constants/fieldProperties';
import { getThemeColor } from 'constants/theme';
import { getTimezoneOptions } from 'constants/timezones';
import { getWeekDays } from 'constants/weekdays';
import { MuiColorInput } from 'mui-color-input';
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { addCardActionAreaButtonStyle, addExtraRequestButtonIconStyle, addWorkingHoursButtonIconStyle, cardItemButtonGroupStackButton, cardItemButtonGroupStackButtonIcon, extraRequestButtonGroupStackButton, extraRequestButtonGroupStackButtonIcon, fabStyle, formSaveButton, logoStyle, mainArea, mainTag, mainTagLeft, mainTagSearchItemOrderBoxContainer } from 'themes/defaultThemes';
import { formValChangeWithParentElementObjectValues, formValChangeWithParentElementWithNameAndValue, formValChangeWithParents, onControlChangeWithParents, onSelectChangeWithParents } from 'utils/form-validation';
import { removeItemAtIndex, replaceLastTwoChars, validateInputNumber } from 'utils/functions';
import { getLanguageFromURL } from "utils/language";
import { getTheme } from 'utils/theme';
import useDocumentTitle from "utils/useDocumentTitle";
import { transformOutletSettingsHoursFormatToISO } from 'utils/functions/date';
import CustomDialogBasedOnScreenChildren from 'components/Dialogs/CustomDialogBasedOnScreenChildren';
import CustomAddWifiCard from 'components/Card/wifi/CustomAddWifiCard';
import CustomWifiConfigCard from 'components/Card/wifi/CustomWifiConfigCard';
import CustomWifiConfigurationPreviewCard from 'components/Card/wifi/CustomWifiConfigurationPreviewCard';
import CustomFontAwesomeIconUrlPicker from 'components/Assets/CustomFontAwesomeIconUrlPicker';
import ExportQrInstanceDialog from 'components/Dialogs/qrcode/ExportQrInstanceDialog';

/**
 * The OutletSettingsComponent that allow the ADMIN to create a 
 * new outlet or edit an existing one.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function OutletSettingsComponent() {
    const { t } = useTranslation();
    const { organizationID } = useParams();
    const { outletID } = useParams();

    //change document title
    useDocumentTitle(`Usee  ${organizationID !== undefined ? " | " + t('org.pageTitle') : ""} | ${t('outlet.pageTitle')} | ${t('menu.settings')}`);

    /**
     * @type {object}
     * @property {*} icon the legend fa icon
     * @property {object} userAuth the authenticated user infromation
     * @property {boolean} isAdd to define if requested an edit or an add of the knowledge base category
     * @property {object} organization the organization basic details (id and name)
     * @property {object} outlet tha outlet basic details (id and name)
     * @property {array} socialProfileTypeOptions the different social profile type options
     * @property {array} accountTicketFieldOptions the different account ticket options
     * @property {array} orderTicketFieldOptions the different order ticket options
     * @property {array} notificationActionOptions the different notification action options (plain or email ones)
     * @property {array} tagOptions the different tag options
     * @property {array} outlets the organization's outlet basic details (id, name)
     * @property {object} isError list of indication of empty form required field 
     *                           after submit to present error message to the
     *                           fields/attributes with true value
     */
    const [data, setData] = useState({
        icon: AddIcon,
        userAuth: null,
        isAdd: outletID === undefined,
        organization: {
            id: organizationID,
            name: ""
        },
        outlet: {
            id: outletID,
            name: ""
        },
        tagOptions: [],
        // working hours
        timeFormats: [],
        weekDays: [],
        publicHolidays: [],
        currencyCodes: [],
        actionsValue: [],//for notification action value field
        displayModes: [],
        hoursOutputs: [],
        layoutOptions: [],
        languages: [],
        // qrcode
        qRcodeEcLevels: [],
        qRcodeLogoPaddingStyles: [],
        qRcodeQrStyles: [],
        requestTypes: [],
        afterScanOptions: [],
        //waitlist
        enabledWaitlistRequirementOptions: [],
        enabledWaitilistTagOptions: [],
        waitlistQrcodePathNumber: null
    });

    const weekDays = getWeekDays();
    // pop dialog for delete
    const [dialog, setDialog] = useState({
        isOpen: false,
        id: "",
        field: "",
        translations: {}
    });

    const [wifiDetails, setWifiDetails] = useState(undefined);

    const [tabValue, setTabValue] = React.useState("details");
    const [workingHourTabValue, setWorkingHourTabValue] = React.useState("hours");

    const [isLoaded, setIsLoaded] = useState(false);

    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313"
    });

    // pop dialog for add/edit qr template
    const [dialogWifiAddEdit, setDialogWifiAddEdit] = useState({
        isOpen: false,
        isAdd: false,//add or 1 for edit,
        index: 0
    });


    // pop dialog for add/edit qr template
    const [dialogAddEdit, setDialogAddEdit] = useState({
        isOpen: false,
        qrTemplateID: "",
        isAdd: false,//add or 1 for edit,
        index: 0
    });

    // pop dialog for delete qr template
    const [deleteDialog, setDeletedDialog] = useState({
        isOpen: false,
        categoryID: "",
        index: 0
    });

    const [file, setFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);

    useEffect(() => {
        getOutletSettings();
    }, [previewUrl]);

    /**
     * The rest endpoint to get the outlet settings.
     */
    function getOutletSettings() {
        outletApi.fetchOneSettings(organizationID, outletID).then((r) => {
            if (r.data.code === "SUCCESS") {
                let outlet = r.data.returnobject?.outlet;

                setData({
                    ...data,
                    userAuth: r.data.returnobject.userAuth,
                    organization: r.data.returnobject.organization,
                    outlet: {
                        ...outlet,
                        settings: {
                            ...outlet.settings,
                            requiredTagSelection: (outlet.settings.requiredTagSelection ? outlet.settings.requiredTagSelection : false),
                            advancedWaitlistAvailability: (outlet.settings.advancedWaitlistAvailability ? outlet.settings.advancedWaitlistAvailability : false),
                            waitlistActive: (outlet.settings.waitlistActive ? outlet.settings.waitlistActive : false),
                            waitingTime1Min: (outlet.settings.waitingTime1Min ? outlet.settings.waitingTime1Min : 15),
                            waitingTime2Min: (outlet.settings.waitingTime2Min ? outlet.settings.waitingTime2Min : 30),
                            waitingTime1ChipTextColor: (outlet.settings.waitingTime1ChipTextColor ? outlet.settings.waitingTime1ChipTextColor : "#89a892"),
                            waitingTime2ChipTextColor: (outlet.settings.waitingTime2ChipTextColor ? outlet.settings.waitingTime2ChipTextColor : "#ffe0b2"),
                            waitingTime3ChipTextColor: (outlet.settings.waitingTime3ChipTextColor ? outlet.settings.waitingTime3ChipTextColor : "#c59fa2"),
                            estimatedTimeChipTextColor: (outlet.settings.estimatedTimeChipTextColor ? outlet.settings.estimatedTimeChipTextColor : "#aba9a9"),
                            waitlistSquareChipTextColor: (outlet.settings.waitlistSquareChipTextColor ? outlet.settings.waitlistSquareChipTextColor : "#b05fc4"),
                            waitlistPositionSquareChipTextColor: (outlet.settings.waitlistPositionSquareChipTextColor ? outlet.settings.waitlistPositionSquareChipTextColor : "#47a6d2"),
                            showWaitlistGroupInfo: (outlet.settings.showWaitlistGroupInfo ? outlet.settings.showWaitlistGroupInfo : false),
                        }
                    },
                    paymentMethods: r.data.returnobject.paymentMethods,
                    currencyCodes: r.data.returnobject.currencyCodes,
                    taxTypes: r.data.returnobject.taxTypes,
                    timeFormats: r.data.returnobject.timeFormats,
                    weekDays: r.data.returnobject.weekDays,
                    publicHolidays: r.data.returnobject.publicHolidays,
                    displayModes: r.data.returnobject.displayModes,
                    hoursOutputs: r.data.returnobject.hoursOutputs,
                    qRcodeEcLevels: r.data.returnobject.qRcodeEcLevels,
                    qRcodeLogoPaddingStyles: r.data.returnobject.qRcodeLogoPaddingStyles,
                    qRcodeQrStyles: r.data.returnobject.qRcodeQrStyles,
                    requestTypes: r.data.returnobject.requestTypes,
                    afterScanOptions: r.data.returnobject.afterScanOptions,
                    layoutOptions: r.data.returnobject.layoutOptions,
                    languages: r.data.returnobject.languages,
                    enabledWaitlistRequirementOptions: r.data.returnobject?.enabledWaitlistRequirementOptions,
                    enabledWaitilistTagOptions: r.data.returnobject?.enabledWaitilistTagOptions,
                    waitlistQrcodePathNumber: r.data.returnobject?.waitlistQrcodePathNumber
                });
                setIsLoaded(true);
            }
        }).catch((e) => {
            // console.log(e);
        });
    }

    /**
     * Function that triggers the modification of the outlet
     */
    function editOutletSettings(data) {
        outletApi.update(transformOutletSettingsHoursFormatToISO(data.outlet)).then((r) => {
            setAlertBox({
                isOpen: true,
                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
        }).catch((e) => {
            // console.log(e)
        });
    }

    /**
     * Function that handles the snackbar open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }

    // ========================== LAYOUT

    /**
     * Function that triggers the modification of the layout style
     * @param {*} tagToDelete 
     */
    function updateLayoutStyle(event, layoutOption, fieldName, isList = false) {
        let layouts = data.outlet.settings.layout;
        if (isList) {
            if (layouts.includes(layoutOption)) {
                const index = layouts.indexOf(layoutOption);
                // only splice array when item is found
                if (index > -1) layouts.splice(index, 1); // 2nd parameter means remove one item only
            } else {
                layouts.push(layoutOption);
            }
        } else {
            layouts = layoutOption;
        }


        formValChangeWithParentElementWithNameAndValue(fieldName, layouts, data, setData)
    }
    // ========================== QR code templates

    /**
     * Function that handles the add/edit modal open or close data.
     * @property {boolean} isOpen The state of the dialog open status.
     * @property {string} qrTemplateID The id of the selected category to be created/editted.
     * @property {boolean} isAdd Value is `true` if the dialog is for creation. 
     */
    function handleAddEditDialogState(isOpen, qrTemplateID = "", isAdd = true, index = 0) {
        setDialogAddEdit({
            ...dialogAddEdit,
            isOpen: isOpen,
            qrTemplateID: qrTemplateID,
            isAdd: isAdd,
            index: index
        });
    }

    /**
     * Function that handles the add/edit modal open or close data.
     * @property {boolean} isOpen The state of the dialog open status.
     * @property {string} qrTemplateID The id of the selected category to be created/editted.
     * @property {boolean} isAdd Value is `true` if the dialog is for creation. 
     */
    function handleWifiAddEditDialogState(isOpen, isAdd = true, index = 0) {
        isAdd ? setWifiDetails(undefined) : setWifiDetails(data.outlet.settings.wifiConfigurations[index]);
        setDialogWifiAddEdit({
            ...dialogWifiAddEdit,
            isOpen: isOpen,
            isAdd: isAdd,
            index: index
        });
    }

    /** Function that handles the delete modal open or close data.
     * 
     * @property {boolean} isOpen The state of the dialog open status.
     * @property {string} qrTemplateID The id of the selected qr template to be removed.
     * @property {string} name The loyalty category name to be removed.
     */
    function handleDeleteDialogState(isOpen, qrTemplateID = "", index = 0) {
        setDeletedDialog({
            ...deleteDialog,
            isOpen: isOpen,
            qrTemplateID: qrTemplateID,
            index: index
        });
    }

    /**
     * Function that triggers the removal of the selected  qr template..
     * 
     * @property {*} event
     * @property {string} qrTemplateID The id of the selected qr template to be removed.
     * @property {string} name The qr template to be removed.
     */
    function removeQRTemplate(qrTemplateID, index) {
        handleDeleteDialogState(true, qrTemplateID, index);
    }


    /**
     * Function that triggers the creation of a qrcode template
     */
    function addQrCodeTemplate(qrcodeTemplate) {
        let newQrcodeTemplates = data.outlet.settings.qrcodeTemplates;
        newQrcodeTemplates.push(qrcodeTemplate);
        formValChangeWithParentElementWithNameAndValue("outlet.settings.qrcodeTemplates", newQrcodeTemplates, data, setData, "");
    }

    /**
     * Function that triggers the modification of a qrcode template
     */
    function updatedQrCodeTemplate(qrcodeTemplate, index) {
        let newQrcodeTemplates = data.outlet.settings.qrcodeTemplates;

        newQrcodeTemplates[index] = qrcodeTemplate;
        formValChangeWithParentElementWithNameAndValue("outlet.settings.qrcodeTemplates", newQrcodeTemplates, data, setData, "");
    }

    function removeQR() {
        let newQrcodeTemplates = data.outlet.settings.qrcodeTemplates;
        let index = deleteDialog.index;

        if (index !== -1) {
            // If target string exists in the array, remove it
            newQrcodeTemplates.splice(index, 1);
        }

        formValChangeWithParentElementWithNameAndValue("outlet.settings.qrcodeTemplates", newQrcodeTemplates, data, setData, "");
        handleDeleteDialogState(false)
    }


    /**
     * Function that triggers the creation of a qrcode template
     */
    function addQrCodeTemplate(qrcodeTemplate) {
        let newQrcodeTemplates = data.outlet.settings.qrcodeTemplates;
        newQrcodeTemplates.push(qrcodeTemplate);
        formValChangeWithParentElementWithNameAndValue("outlet.settings.qrcodeTemplates", newQrcodeTemplates, data, setData, "");
    }

    /**
     * Function that triggers the modification of a qrcode template
     */
    function updatedQrCodeTemplate(qrcodeTemplate, index) {
        let newQrcodeTemplates = data.outlet.settings.qrcodeTemplates;

        newQrcodeTemplates[index] = qrcodeTemplate;
        formValChangeWithParentElementWithNameAndValue("outlet.settings.qrcodeTemplates", newQrcodeTemplates, data, setData, "");
    }

    function removeQR() {
        let newQrcodeTemplates = data.outlet.settings.qrcodeTemplates;
        let index = deleteDialog.index;

        if (index !== -1) {
            // If target string exists in the array, remove it
            newQrcodeTemplates.splice(index, 1);
        }

        formValChangeWithParentElementWithNameAndValue("outlet.settings.qrcodeTemplates", newQrcodeTemplates, data, setData, "");
        handleDeleteDialogState(false)
    }


    // ========================== WIFI

    /**
     * Function that triggers the creation of a wifi details
     */
    function addWifi(wifi) {
        let newWifi = data.outlet.settings.wifiConfigurations;
        if (newWifi === null) newWifi = []
        newWifi.push(wifi);
        formValChangeWithParentElementWithNameAndValue("outlet.settings.wifiConfigurations", newWifi, data, setData, "");
        handleWifiAddEditDialogState(false, true, 0);
    }

    /**
     * Function that triggers the modification of a wifi details
     */
    function updateWifi(wifi, index) {
        let newWifi = data.outlet.settings.wifiConfigurations;
        newWifi[index] = wifi;
        formValChangeWithParentElementWithNameAndValue("outlet.settings.wifiConfigurations", newWifi, data, setData, "");
        handleWifiAddEditDialogState(false, true, 0);
    }


    /**
     * Function that triggers the modification of a wifi details
     */
    function updateWifiConfigurationState(index, newValue) {
        let newWifi = data.outlet.settings.wifiConfigurations;
        newWifi[index].enabled = newValue;

        formValChangeWithParentElementWithNameAndValue("outlet.settings.wifiConfigurations", newWifi, data, setData, "");
        handleWifiAddEditDialogState(false, true, 0);
    }

    /**
     * Function that triggers the modification of a wifi details
     */
    function removeWifi(event, i) {
        let newWifi = data.outlet.settings.wifiConfigurations;
        let index = deleteDialog.index;

        if (index !== -1) {
            // If target string exists in the array, remove it
            newWifi.splice(index, 1);
        }

        formValChangeWithParentElementWithNameAndValue("outlet.settings.wifiConfigurations", newWifi, data, setData, "");
        // handleDeleteDialogState(false)
    }

    // ========================= PHOTOS
    // function resizeFile(file) {
    //     new Promise(resolve => {
    //         ResizeR.imageFileResizer(
    //             file,
    //             300,
    //             300,
    //             'PNG',
    //             100,
    //             0,
    //             uri => {
    //                 resolve(uri);
    //             },
    //             'base64'
    //         );
    //     });
    // }

    /**
     * Callback that handles file upload change.
     * @param {string} fileBase64 the new base64 image 
     */
    function handleFileChange(fileBase64) {
        formValChangeWithParentElementWithNameAndValue("outlet.logo", fileBase64, data, setData, "");
    }

    /**
     * Callback that handles file upload change.
     * @param {string} fileBase64 the new base64 image 
     */
    function handlePhotoFileChange(fileBase64) {
        let newPhotos = data.outlet.photos;
        newPhotos.push(getAssetDefaultObject(fileBase64, data.outlet.photos.length));
        formValChangeWithParentElementWithNameAndValue("outlet.photos", newPhotos, data, setData, "");
    };

    function updatePhotoHiddenState(event, index) {
        const updatedPhotos = data.outlet.photos?.map((photo, i) => {
            if (i === index) {
                return {
                    ...photo,
                    hidden: !photo.hidden,
                };
            }
            return photo;
        });
        formValChangeWithParentElementWithNameAndValue("outlet.photos", updatedPhotos, data, setData);
    }

    function updatePhotoCoverState(event, index) {
        let updatedPhotos = data.outlet.photos?.map((photo, i) => {
            return {
                ...photo,
                cover: (i === index) ? true : false
            };
        });
        formValChangeWithParentElementWithNameAndValue("outlet.photos", updatedPhotos, data, setData);
    }

    function deletePhoto(event, index) {
        const updatedPhotos = data.outlet.photos;
        if (index > -1) updatedPhotos.splice(index, 1);
        formValChangeWithParentElementWithNameAndValue("outlet.photos", updatedPhotos, data, setData);
    }


    // ========================= ORDER
    /**
     * Function that triggers the removal of a payment type.
     * @param {*} tagToDelete 
     */
    function removePaymentMethod(event, paymentMethodToDelete) {
        let paymentMethods = data.outlet.settings.paymentMethods;
        const index = paymentMethods.indexOf(paymentMethodToDelete);
        // only splice array when item is found
        if (index > -1) paymentMethods.splice(index, 1); // 2nd parameter means remove one item only
        formValChangeWithParentElementWithNameAndValue("outlet.settings.paymentMethods", paymentMethods, data, setData);
    }


    // ========================= WORKING HOURS
    function handleStartEndChange(day, index, field, value) {
        const newWorkingHours = { ...data.outlet.settings.hours.hours };
        newWorkingHours[day][index][field] = value;
        formValChangeWithParentElementObjectValues("outlet.settings.hours.hours", newWorkingHours, data, setData);
    }

    const handleOpenChange = (day, index, value) => {
        const newWorkingHours = { ...data.outlet.settings.hours.hours };
        newWorkingHours[day][index].open = value;
        formValChangeWithParentElementObjectValues("outlet.settings.hours.hours", newWorkingHours, data, setData);
    };

    const handleAddStartEnd = (day) => {
        const newWorkingHours = { ...data.outlet.settings.hours.hours };
        newWorkingHours[day].push(getDayWorkingHoursDefaultObject());
        formValChangeWithParentElementObjectValues("outlet.settings.hours.hours", newWorkingHours, data, setData);
    };

    function removeWorkingHour(day, index) {
        let newWorkingHours = { ...data.outlet.settings.hours.hours };
        newWorkingHours[day].splice(index, 1);
        formValChangeWithParentElementObjectValues("outlet.settings.hours.hours", newWorkingHours, data, setData);
    }

    // ========================= TRANSLATIONS
    /**
     * Function that handles the delete modal open or close state.
     * @property {boolean} isOpened If the values is `true`, the dialog should be open and visible.
     * @property {string} field The field of the selected text translations to be removed.
     */
    function handleTranslationsDialogState(isOpen, field = "name") {
        setDialog({
            ...dialog,
            isOpen: isOpen,
            field: field,
            translations: data.outlet.settings.hours.indicator[dialog.field]
        });
    }

    /**
     * Function to trigger the remove of the admin broker.
     * @property {*} event
     * @property {string} field The field of the selected outlet field.
     */
    function configureTranslations(event, field) {
        handleTranslationsDialogState(true, field);
    }

    // ================================================ PHOTO

    /**
    * The rest endpoint to update outlet photos order
    * 
    * @param {array} photos  the photos after reorder
    */
    function updatePhotoOrder(photos) {
        setData({
            ...data,
            outlet: {
                ...data.outlet,
                photos: photos
            }
        })
    }

    // ========================================= Custom extra action buttons

    /**
     * Updates the value of a specific field in a extraOutletActionButton object at a given index.
     *
     * This function takes an index, field name, and a new value, and updates the
     * corresponding field in the extraOutletActionButtons array of the subscription plan.
     *
     * @param {number} index - The index of the price object in the extraOutletActionButtons array to update.
     * @param {string} field - The field within the extraOutletActionButton object to update.
     * @param {*} value - The new value to assign to the field.
     */
    function handleExtraOutletActionButtonChange(index, field, value) {
        let newExtraOutletActionButtons = data.outlet.settings.extraOutletActionButtons;
        newExtraOutletActionButtons[index][field] = value;
        formValChangeWithParentElementWithNameAndValue("outlet.settings.extraOutletActionButtons", newExtraOutletActionButtons, data, setData);
    }

    /**
     * Adds a new extraCustomRequestButton object to the subscription plan's extraOutletActionButtons array.
     *
     * This function adds a default extraCustomRequestButton object to the end of the extraOutletActionButtons array.
     * The default object is different based on whether the array is initially empty or not.
     */
    function handleAddExtraOutletActionButton() {
        let newExtraOutletActionButtons = data.outlet.settings.extraOutletActionButtons;
        if (newExtraOutletActionButtons === null || newExtraOutletActionButtons === undefined) newExtraOutletActionButtons = [getExtraOutletActionButtonsDefaultObject()]
        else newExtraOutletActionButtons.push(getExtraOutletActionButtonsDefaultObject());
        formValChangeWithParentElementWithNameAndValue("outlet.settings.extraOutletActionButtons", newExtraOutletActionButtons, data, setData);
    }

    /**
     * Removes a extraCustomRequestButton object from the subscription plan's extraOutletActionButtons array based on its index.
     *
     * @param {number} index - The index of the extraCustomRequestButton object in the extraOutletActionButtons array to remove.
     */
    function removeExtraOutletActionButton(index) {
        let newExtraOutletActionButtons = removeItemAtIndex(data.outlet.settings.extraOutletActionButtons, index);
        formValChangeWithParentElementWithNameAndValue("outlet.settings.extraOutletActionButtons", newExtraOutletActionButtons, data, setData);
    }

    // ========================================= Custom extra request button

    /**
     * Updates the value of a specific field in a extraCustomRequestButton object at a given index.
     *
     * This function takes an index, field name, and a new value, and updates the
     * corresponding field in the extraCustomRequestButtons array of the subscription plan.
     *
     * @param {number} index - The index of the price object in the extraCustomRequestButtons array to update.
     * @param {string} field - The field within the extraCustomRequestButton object to update.
     * @param {*} value - The new value to assign to the field.
     */
    function handleExtraRequestButtonChange(index, field, value) {
        let newRequestExtraCustomButtons = data.outlet.settings.requestExtraCustomButtons;
        newRequestExtraCustomButtons[index][field] = value;
        formValChangeWithParentElementWithNameAndValue("outlet.settings.requestExtraCustomButtons", newRequestExtraCustomButtons, data, setData);
    }

    /**
     * Adds a new extraCustomRequestButton object to the subscription plan's extraCustomRequestButtons array.
     *
     * This function adds a default extraCustomRequestButton object to the end of the extraCustomRequestButtons array.
     * The default object is different based on whether the array is initially empty or not.
     */
    function handleAddExtraRequestButton() {
        let newRequestExtraCustomButtons = data.outlet.settings.requestExtraCustomButtons;
        if (newRequestExtraCustomButtons === null || newRequestExtraCustomButtons === undefined) newRequestExtraCustomButtons = [getExtraCustomRequestButtonDefaultObject()]
        else newRequestExtraCustomButtons.push(getExtraCustomRequestButtonDefaultObject());
        formValChangeWithParentElementWithNameAndValue("outlet.settings.requestExtraCustomButtons", newRequestExtraCustomButtons, data, setData);
    }

    /**
     * Removes a extraCustomRequestButton object from the subscription plan's extraCustomRequestButtons array based on its index.
     *
     * @param {number} index - The index of the extraCustomRequestButton object in the extraCustomRequestButtons array to remove.
     */
    function removeExtraRequestButton(index) {
        let newRequestExtraCustomButtons = removeItemAtIndex(data.outlet.settings.requestExtraCustomButtons, index);
        formValChangeWithParentElementWithNameAndValue("outlet.settings.requestExtraCustomButtons", newRequestExtraCustomButtons, data, setData);
    }


    // ================ EXPORT QRCODE
    // pop dialog for eportQr
    const [exportQrDialog, setExportQrDialog] = useState({
        id: "",
        isOpen: false,
        equipment: {},
        label: "",
    });

    /**
     * Function that handles the delete modal open or close data.
     * @property {boolean} isOpen The state of the dialog open status.
     * @property {string} label The label
     */
    function handleExportQrDialogState(isOpen, label = "") {
        setExportQrDialog({
            ...exportQrDialog,
            isOpen: isOpen,
            label: label
        });
    }

    function exportQRcodeForWaitlist(event, label = "") {
        handleExportQrDialogState(true, label);
    }

    function generateQRcodeForWaitlist() {
        outletApi.generateWaitlistQrcode(organizationID, outletID).then((r) => {
            if (r.data.code === "SUCCESS") {
                setData({
                    ...data,
                    waitlistQrcodePathNumber: r.data.returnobject
                });
            }

            setAlertBox({
                isOpen: true,
                message: "message" in r.data ?
                    (r.data.message?.includes("generated") ? t('waitlist.generateQRcodeSuccess') :
                        r.data.message) : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
        }).catch((e) => {
            // console.log(e);
        });
    }


    // ===============================================================================RENDER
    if (!isLoaded) {
        return <CustomGrid role="main" id="outlet-settings" sx={mainTag()}>
            <LoadingSkeleton lines={9} />
        </CustomGrid>
    } else {
        let defaultTaxTypeSelected = "";
        let defaultCurrencysSelected = "";
        let defaultPaymentMethodsSelected = [];
        let defaultTimezone = "";
        if (data.outlet.settings !== null) {
            defaultPaymentMethodsSelected = data.outlet.settings.paymentMethods;
            defaultTaxTypeSelected = data.outlet.settings.taxType;
            defaultCurrencysSelected = data.outlet.settings.currency;
            defaultTimezone = data.outlet.settings.hours.indicator.timeFormat !== null ? data.outlet.settings.hours.indicator.timeFormat : "(UTC+02:00) Athens, Bucharest";
        }

        return (
            // OUTLET Settings
            <CustomGrid role="main" id="outlet-settings" sx={mainTagLeft()}>
                {organizationID !== undefined &&
                    <CustomAddEditBreadcrumbMultiParent
                        isAdd={data.isAdd}
                        parents={[
                            { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                            { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                            { name: t('outlet.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet` },
                            { name: data.outlet.name.en, url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet/${data.outlet.id}` }
                        ]}
                        instanceName={t('outlet.settings.label')}
                    />
                }
                {organizationID === undefined &&
                    <CustomAddEditBreadcrumbMultiParent
                        isAdd={data.isAdd}
                        parents={[
                            { name: t('outlet.pageTitle'), url: `/${getLanguageFromURL()}/outlet` },
                            { name: data.outlet.name.en, url: `/${getLanguageFromURL()}/outlet/${data.outlet.id}` }
                        ]}
                        instanceName={t('outlet.settings.label')}
                    />
                }

                <CustomGrid sx={mainArea()} container={false}>
                    <form id="outlet-settings" onSubmit={(e) => { return false; }}>
                        {data.isAdd &&
                            <input type="hidden" />
                        }

                        <input defaultValue={organizationID} name="organizationID" type="hidden" id="organizationID" />

                        {!data.isAdd && data.outlet !== null &&
                            <input defaultValue={outletID} name="id" type="hidden" id="id" />
                        }

                        <TabContext value={tabValue}>
                            <CustomTabs
                                initValue={tabValue}
                                instances={[
                                    { name: t('outlet.settings.details'), value: "details" },
                                    { name: t('outlet.settings.workingHours'), value: "workingHours" },
                                    { name: t('menus.tabs.menus'), value: "menus" },
                                    { name: t('outlet.settings.orders'), value: "orders" },
                                    { name: t('outlet.settings.ratings.label'), value: "ratings" },
                                    // { name: t('outlet.settings.bookings'), value: "bookings" },
                                    { name: t('outlet.settings.splashscreen'), value: "splashscreen" },
                                    { name: t('outlet.settings.qrcode'), value: "qrcode" },
                                    { name: t('outlet.settings.request.label'), value: "request" },
                                    { name: t('waitlist.label'), value: "waitlist" },
                                    { name: t('outlet.settings.afterQrDispay.label'), value: "scanPage" },
                                    { name: t('outlet.settings.wifi.label'), value: "wifi" },
                                    { name: t('outlet.settings.style.label'), value: "style" }
                                ]}
                                setValue={setTabValue}
                                sx={{ marginBottom: "20px" }}
                                scrollButtons="auto"
                            />

                            <TabPanel value={"details"} sx={{ paddingTop: "0px" }} >

                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} >
                                        <CustomFieldset
                                            label={t('outlet.logo')}
                                            children={<>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={12}>
                                                        <Grid>
                                                            {(data.outlet.logo !== null && data.outlet.logo !== "") &&
                                                                <Stack direction="row" key="stack-logo">
                                                                    <CustomIconButton
                                                                        keyValue={`btn-delete-logo`}
                                                                        onClick={() => { setFile(null); setPreviewUrl(null); formValChangeWithParentElementWithNameAndValue("outlet.logo", "", data, setData); }}
                                                                        icon={<DeleteIcon style={cardItemButtonGroupStackButtonIcon()} />}
                                                                        iconSx={{ marginRight: "0px!important" }}
                                                                        sx={{
                                                                            backgroundColor: "red",
                                                                            ...cardItemButtonGroupStackButton()
                                                                        }}
                                                                    >
                                                                        {t('actions.delete')}
                                                                    </CustomIconButton>
                                                                </Stack>
                                                            }

                                                            {(data.outlet.logo !== null && data.outlet.logo !== "") &&
                                                                <CardMedia
                                                                    component="img"
                                                                    image={data.outlet.logo.includes("base64") ?
                                                                        data.outlet.logo
                                                                        :
                                                                        `https://storage.usee.gr/usee/organization/${data.organization.id}/outlet/${data.outlet.id}/${data.outlet.logo}`}
                                                                    alt={"logo"}
                                                                    sx={logoStyle()}
                                                                />
                                                            }

                                                            <Button>
                                                                {/* Choose File */}
                                                                <CustomImageCropper storeCroppedImage={handleFileChange} />
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                            }
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2} sx={{ marginTop: "50px" }}>
                                    <Grid item xs={12} md={12} >
                                        <CustomFieldset
                                            label={t('outlet.photos')}
                                            children={<>
                                                <PhotoComponent
                                                    photos={data.outlet.photos}
                                                    showEmptyMessage={true}
                                                    emptyMessage={t('assets.emptyPhotos')}
                                                    imagePath={`https://storage.usee.gr/usee/organization/${data.organization.id}/outlet/${data.outlet.id}/cover/`}
                                                    updatePhotoHiddenState={updatePhotoHiddenState}
                                                    updatePhotoCoverState={updatePhotoCoverState}
                                                    deletePhoto={deletePhoto}
                                                    handleFileChange={handlePhotoFileChange}
                                                    updatePhotoOrder={updatePhotoOrder}
                                                />
                                            </>
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </TabPanel>

                            <TabPanel value={"workingHours"} sx={{ paddingTop: "0px" }}>
                                <TabContext value={workingHourTabValue}>
                                    <CustomTabs
                                        initValue={workingHourTabValue}
                                        instances={[
                                            { name: t('outlet.settings.hours.labelPlain'), value: "hours" },
                                            { name: t('outlet.settings.hours.settings'), value: "settings" }
                                        ]}
                                        setValue={setWorkingHourTabValue}
                                        sx={{ marginBottom: "20px" }}
                                        scrollButtons="auto"
                                    />

                                    <TabPanel value={"hours"} sx={{ paddingTop: "0px" }}>
                                        <Grid container spacing={2} sx={{ marginTop: '5px' }}>
                                            {weekDays.map((day, dayIndex) => (
                                                <Grid item xs={12} key={day}>
                                                    <Grid container spacing={2} key={dayIndex} xs={12} md={12}>
                                                        <Grid item xs={12} md={12}>
                                                            <InputLabel required={true} htmlFor={day}>{t(`workingHours.${day}`)}</InputLabel>
                                                        </Grid>

                                                        <Grid item xs={12} md={12}>
                                                            {data.outlet?.settings?.hours?.hours[day]?.map((startEndDate, index) => (
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <Grid container spacing={2} key={index} sx={{ marginTop: 0, alignItems: "center" }}>
                                                                        <Grid item xs={12} md={4}>
                                                                            <TimePicker
                                                                                label={t('workingHours.start')}
                                                                                renderInput={(props) => <TextField {...props} />}
                                                                                value={startEndDate.start}
                                                                                views={['hours', 'minutes']}
                                                                                onChange={(newValue) => handleStartEndChange(day, index, 'start', newValue)}
                                                                            />
                                                                        </Grid>

                                                                        <Grid item xs={12} md={4}>
                                                                            <TimePicker
                                                                                label={t('workingHours.end')}
                                                                                renderInput={(props) => <TextField {...props} />}
                                                                                value={startEndDate.end}
                                                                                views={['hours', 'minutes']}
                                                                                onChange={(newValue) => handleStartEndChange(day, index, 'end', newValue)}
                                                                            />
                                                                        </Grid>

                                                                        <Grid item xs={12} md={2}>
                                                                            <Grid container spacing={2}>
                                                                                <Grid item xs={6} md={6}>
                                                                                    <CustomIconButton
                                                                                        keyValue="bold"
                                                                                        variant="outlined"
                                                                                        icon={<SignLanguageIcon />}
                                                                                        tooltipTitle={t('workingHours.working')}
                                                                                        isFocused={startEndDate.open}
                                                                                        onClick={() => handleOpenChange(day, index, true)}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={6} md={6}>
                                                                                    <CustomIconButton
                                                                                        keyValue="bold"
                                                                                        variant="outlined"
                                                                                        icon={<WorkOffIcon />}
                                                                                        tooltipTitle={t('workingHours.break')}
                                                                                        isFocused={!startEndDate.open}
                                                                                        onClick={() => handleOpenChange(day, index, false)}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid item xs={6} md={2}>
                                                                            <CustomIconButton
                                                                                keyValue={`btn-delete-${index}`}
                                                                                onClick={() => removeWorkingHour(day, index)}
                                                                                icon={<DeleteIcon style={cardItemButtonGroupStackButtonIcon()} />}
                                                                                className="icon-wrapper tr link"
                                                                                iconSx={{ marginRight: "0px!important" }}
                                                                                sx={{
                                                                                    backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColor"],
                                                                                    ":hover": {
                                                                                        backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColorHover"],
                                                                                    },
                                                                                    ...cardItemButtonGroupStackButton()
                                                                                }}
                                                                            >
                                                                                {t('actions.delete')}
                                                                            </CustomIconButton>
                                                                        </Grid>
                                                                    </Grid>
                                                                </LocalizationProvider>
                                                            ))}
                                                        </Grid>

                                                        <Grid item xs={12} md={12} sx={{ marginBottom: "10px" }}>
                                                            <CustomIconButton
                                                                sx={addCardActionAreaButtonStyle()}
                                                                backgroundColor="transparent"
                                                                icon={<AddIcon sx={addWorkingHoursButtonIconStyle()} />}
                                                                onClick={() => handleAddStartEnd(day)}>
                                                                {t('actions.add')}
                                                            </CustomIconButton>
                                                        </Grid>
                                                    </Grid>
                                                    <Divider />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </TabPanel>

                                    <TabPanel value={"settings"} sx={{ paddingTop: "0px" }}>
                                        <Grid container spacing={2} sx={{ marginTop: '5px' }}>
                                            <Grid item xs={12} md={6}>
                                                <InputLabel htmlFor="timezone">{t('outlet.settings.hours.timezone')}</InputLabel>
                                                <CustomAutocompleteTimezoneSelect
                                                    instances={getTimezoneOptions()}
                                                    onSelectChange={(value) => onSelectChangeWithParents({ "target": { name: "outlet.settings.hours.timezone", "value": value } }, data, setData)}
                                                    defaultValue={data.outlet.settings.hours.timezone}
                                                // searchLabel={`${t('actions.searchFor')} ${t('outlet.settings.hours.timezone')}`}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <InputLabel htmlFor="timeFormat">{t('outlet.settings.hours.timeFormat.label')}</InputLabel>
                                                <CustomSelectMultilingual
                                                    id="timeFormat"
                                                    translationPath="outlet.settings.hours.timeFormat"
                                                    name="outlet.settings.hours.timeFormat"
                                                    required={true}
                                                    isMultiple={false}
                                                    defaultValue={data.outlet.settings.hours.timeFormat}
                                                    options={data.timeFormats}
                                                    onChange={(event) => onSelectChangeWithParents(event, data, setData)}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <InputLabel htmlFor="nowOpenMessage">{t('outlet.settings.hours.indicator.nowOpenMessage')}</InputLabel>
                                                <CustomFlagTextField
                                                    country="en"
                                                    flag={en}
                                                    placeholder=""
                                                    name="outlet.settings.hours.indicator.nowOpenMessage.en" id="nowOpenMessage"
                                                    onChange={(event) => formValChangeWithParents(event, data, setData, t('table.valueReuired'))}
                                                    defaultValue={data.outlet !== null && data.outlet.settings !== null ? data.outlet.settings.hours.indicator.nowOpenMessage.en : ""}
                                                    onTranslationSelect={configureTranslations}
                                                    field="nowOpenMessage"
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <InputLabel htmlFor="nowCloseMessage">{t('outlet.settings.hours.indicator.nowCloseMessage')}</InputLabel>
                                                <CustomFlagTextField
                                                    country="en"
                                                    flag={en}
                                                    placeholder=""
                                                    name="outlet.settings.hours.indicator.nowCloseMessage.en" id="nowCloseMessage"
                                                    onChange={(event) => formValChangeWithParents(event, data, setData, t('table.valueReuired'))}
                                                    defaultValue={data.outlet !== null && data.outlet.settings !== null ? data.outlet.settings.hours.indicator.nowCloseMessage.en : ""}
                                                    onTranslationSelect={configureTranslations}
                                                    field="nowCloseMessage"
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <InputLabel htmlFor="openingSoonMessage">{t('outlet.settings.hours.indicator.openingSoonMessage')}</InputLabel>
                                                <CustomFlagTextField
                                                    country="en"
                                                    flag={en}
                                                    placeholder=""
                                                    name="outlet.settings.hours.indicator.openingSoonMessage.en" id="openingSoonMessage"
                                                    onChange={(event) => formValChangeWithParents(event, data, setData, t('table.valueReuired'))}
                                                    defaultValue={data.outlet !== null && data.outlet.settings !== null ? data.outlet.settings.hours.indicator.openingSoonMessage.en : ""}
                                                    onTranslationSelect={configureTranslations}
                                                    field="openingSoonMessage"
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <InputLabel htmlFor="closingSoonMessage">{t('outlet.settings.hours.indicator.closingSoonMessage')}</InputLabel>
                                                <CustomFlagTextField
                                                    country="en"
                                                    flag={en}
                                                    placeholder=""
                                                    name="outlet.settings.hours.indicator.closingSoonMessage.en" id="closingSoonMessage"
                                                    onChange={(event) => formValChangeWithParents(event, data, setData, t('table.valueReuired'))}
                                                    defaultValue={data.outlet !== null && data.outlet.settings !== null ? data.outlet.settings.hours.indicator.closingSoonMessage.en : ""}
                                                    onTranslationSelect={configureTranslations}
                                                    field="closingSoonMessage"
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <InputLabel htmlFor="includeTime">{t('outlet.settings.hours.indicator.includeTime')}</InputLabel>
                                                <CustomCheckbox
                                                    name="outlet.settings.hours.indicator.includeTime"
                                                    selected={data.outlet.settings.hours.indicator.includeTime}
                                                    onChange={(event) => onControlChangeWithParents(event, data, setData, "outlet.settings.hours.indicator.includeTime")}
                                                    label={t('outlet.settings.hours.indicator.includeTimeInfo')}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <InputLabel htmlFor="includeDay">{t('outlet.settings.hours.indicator.includeDay')}</InputLabel>
                                                <CustomCheckbox
                                                    name="outlet.settings.hours.indicator.includeDay"
                                                    selected={data.outlet.settings.hours.indicator.includeDay}
                                                    onChange={(event) => onControlChangeWithParents(event, data, setData, "outlet.settings.hours.indicator.includeDay")}
                                                    label={t('outlet.settings.hours.indicator.includeDayInfo')}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <InputLabel htmlFor="showOpeningClosingSoonWarning">{t('outlet.settings.hours.indicator.showOpeningClosingSoonWarning')}</InputLabel>
                                                <CustomCheckbox
                                                    name="outlet.settings.hours.indicator.showOpeningClosingSoonWarning"
                                                    selected={data.outlet.settings.hours.indicator.showOpeningClosingSoonWarning}
                                                    onChange={(event) => onControlChangeWithParents(event, data, setData, "outlet.settings.hours.indicator.showOpeningClosingSoonWarning")}
                                                    label={t('outlet.settings.hours.indicator.showOpeningClosingSoonWarningInfo')}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <InputLabel htmlFor="openSoonWarning">{t('outlet.settings.hours.indicator.openSoonWarning')}</InputLabel>
                                                <CustomTextField
                                                    type="number"
                                                    step="1"
                                                    min={0}
                                                    name="outlet.settings.hours.indicator.openSoonWarning"
                                                    id="openSoonWarning"
                                                    variant="filled"
                                                    defaultValue={data.outlet !== null ? data.outlet.settings.hours.indicator.openSoonWarning : 45}
                                                    inputAdornmentLabel='outlet.settings.hours.indicator.openSoonWarningStart'
                                                    inputAdornmentAfterLabel='outlet.settings.hours.indicator.minutesInAdvance'
                                                    required={false}
                                                    sx={{ marginBottom: "30px", padding: "10px" }}
                                                    onChange={(event) => formValChangeWithParents(event, data, setData, t('table.valueReuired'))}
                                                    startAdormentWidth="600px"
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <InputLabel htmlFor="closeSoonWarning">{t('outlet.settings.hours.indicator.closeSoonWarning')}</InputLabel>
                                                <CustomTextField
                                                    type="number"
                                                    step="1"
                                                    min={0}
                                                    name="outlet.settings.hours.indicator.closeSoonWarning"
                                                    id="closeSoonWarning"
                                                    variant="filled"
                                                    defaultValue={data.outlet !== null ? data.outlet.settings.hours.indicator.closeSoonWarning : 45}
                                                    inputAdornmentLabel='outlet.settings.hours.indicator.closeSoonWarningStart'
                                                    inputAdornmentAfterLabel='outlet.settings.hours.indicator.minutesInAdvance'
                                                    required={false}
                                                    sx={{ marginBottom: "30px", padding: "10px" }}
                                                    onChange={(event) => formValChangeWithParents(event, data, setData, t('table.valueReuired'))}
                                                    startAdormentWidth="600px"
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <InputLabel htmlFor="displayMode">{t('outlet.settings.hours.indicator.displayMode.label')}</InputLabel>
                                                <CustomSelectMultilingual
                                                    id="displayMode"
                                                    translationPath="outlet.settings.hours.indicator.displayMode"
                                                    name="outlet.settings.hours.indicator.displayMode"
                                                    required={true}
                                                    defaultValue={data.outlet.settings.hours.indicator.displayMode}
                                                    options={data.displayModes}
                                                    onChange={(event) => onSelectChangeWithParents(event, data, setData)}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <InputLabel htmlFor="output">{t('outlet.settings.hours.indicator.output.label')}</InputLabel>
                                                <CustomSelectMultilingual
                                                    id="output"
                                                    translationPath="outlet.settings.hours.indicator.output"
                                                    name="outlet.settings.hours.indicator.output"
                                                    required={true}
                                                    defaultValue={data.outlet.settings.hours.indicator.output}
                                                    options={data.hoursOutputs}
                                                    onChange={(event) => onSelectChangeWithParents(event, data, setData)}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <InputLabel htmlFor="includeHolidays">{t('outlet.settings.hours.indicator.includeHolidays')}</InputLabel>
                                                <CustomCheckbox
                                                    name="outlet.settings.hours.indicator.includeHolidays"
                                                    selected={data.outlet.settings.hours.indicator.includeHolidays}
                                                    onChange={(event) => onControlChangeWithParents(event, data, setData, "outlet.settings.hours.indicator.includeHolidays")}
                                                    label={t('outlet.settings.hours.indicator.includeHolidaysInfo')}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <InputLabel htmlFor="includeVacations">{t('outlet.settings.hours.indicator.includeVacations')}</InputLabel>
                                                <CustomCheckbox
                                                    name="outlet.settings.hours.indicator.includeVacations"
                                                    selected={data.outlet.settings.hours.indicator.includeVacations}
                                                    onChange={(event) => onControlChangeWithParents(event, data, setData, "outlet.settings.hours.indicator.includeVacations")}
                                                    label={t('outlet.settings.hours.indicator.includeVacationsInfo')}
                                                />
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                </TabContext>
                            </TabPanel>

                            {/* MENUS */}
                            <TabPanel value="menus">
                                <Grid container spacing={2}>
                                    <CustomMenuLayoutButtons
                                        updateLayoutStyle={updateLayoutStyle}
                                        activeLayout={data.outlet.settings.menuLayout}
                                        fieldName="outlet.settings.menuLayout"
                                        isList={false}
                                        layoutOptions={data.layoutOptions}
                                    />

                                    <Grid item xs={12} md={6}>
                                        <InputLabel htmlFor="paymentMethods">{t('menus.enabledLanguages')}</InputLabel>
                                        <CustomSelectMultilingual
                                            id="languages"
                                            isMultiple={true}
                                            name="enableLanguagesForMenu"
                                            translationPath="languages"
                                            required={true}
                                            value={data.outlet.settings.enableLanguagesForMenu}
                                            options={data.languages}
                                            lowerCaseInstance={true}
                                            onChange={(event) => formValChangeWithParentElementWithNameAndValue("outlet.settings.enableLanguagesForMenu", event.target.value, data, setData)}
                                        />
                                    </Grid>
                                </Grid>
                            </TabPanel>

                            <TabPanel value={"orders"} sx={{ paddingTop: "0px" }} >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <CustomFieldset
                                            label={t('outlet.settings.outletStyle')}
                                            children={<>
                                                <Grid container spacing={2} sx={mainTagSearchItemOrderBoxContainer()}>
                                                    <Grid item xs={12} md={12}>
                                                        <FormControlLabel control={<Switch defaultChecked={data.outlet.settings.showLogo} onChange={(event) => onControlChangeWithParents(event, data, setData, "outlet.settings.showLogo")} />} label={t('outlet.settings.showLogo')} />
                                                    </Grid>
                                                    <Grid item xs={12} md={12}>
                                                        <FormControlLabel control={<Switch defaultChecked={data.outlet.settings.showCover} onChange={(event) => onControlChangeWithParents(event, data, setData, "outlet.settings.showCover")} />} label={t('outlet.settings.showCover')} />
                                                    </Grid>
                                                    <Grid item xs={12} md={12}>
                                                        <FormControlLabel control={<Switch defaultChecked={data.outlet.settings.showPhotos} onChange={(event) => onControlChangeWithParents(event, data, setData, "outlet.settings.showPhotos")} />} label={t('outlet.settings.showPhotos')} />
                                                    </Grid>
                                                </Grid>
                                            </>
                                            }
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <CustomFieldset
                                            label={t('outlet.settings.reviewsStyle')}
                                            children={<>
                                                <Grid container spacing={2} sx={mainTagSearchItemOrderBoxContainer()}>
                                                    <Grid item xs={12} md={12}>
                                                        <FormControlLabel control={<Switch defaultChecked={data.outlet.settings.allowReviews} onChange={(event) => onControlChangeWithParents(event, data, setData, "outlet.settings.allowReviews")} />} label={t('outlet.settings.allowReviews')} />
                                                    </Grid>
                                                    <Grid item xs={12} md={12}>
                                                        <FormControlLabel control={<Switch defaultChecked={data.outlet.settings.showReviews} onChange={(event) => onControlChangeWithParents(event, data, setData, "outlet.settings.showReviews")} />} label={t('outlet.settings.showReviews')} />
                                                    </Grid>
                                                    <Grid item xs={12} md={12}>
                                                        <FormControlLabel control={<Switch defaultChecked={data.outlet.settings.showReviewsAverageScore} onChange={(event) => onControlChangeWithParents(event, data, setData, "outlet.settings.showReviewsAverageScore")} />} label={t('outlet.settings.showReviewsAverageScore')} />
                                                    </Grid>
                                                    {data.outlet.settings.allowReviews &&
                                                        <Grid item xs={12} md={12}>
                                                            <InputLabel htmlFor="style">{t('outlet.settings.ratingStyle')}</InputLabel>
                                                            <Grid container>
                                                                <Grid item xs={12} md={6}>
                                                                    <CustomRating
                                                                        iconStyle="star"
                                                                        value={data.outlet.settings.ratingStyle === "star" ? 5 : 0}
                                                                        size={"medium"}
                                                                        onChange={() => formValChangeWithParentElementWithNameAndValue("outlet.settings.ratingStyle", "star", data, setData)}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} md={6}>
                                                                    <CustomRating
                                                                        iconStyle="heart"
                                                                        value={data.outlet.settings.ratingStyle === "heart" ? 5 : 0}
                                                                        size={"medium"}
                                                                        onChange={() => formValChangeWithParentElementWithNameAndValue("outlet.settings.ratingStyle", "heart", data, setData)}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </>
                                            }
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={12} sx={{ marginTop: "50px" }}>
                                    <CustomFieldset
                                        label={t('outlet.settings.orderStyle')}
                                        children={<>
                                            <Grid container spacing={2} sx={mainTagSearchItemOrderBoxContainer()}>
                                                <Grid item xs={12} md={4}>
                                                    <InputLabel htmlFor="paymentMethods">{t('outlet.settings.payment.paymentMethods')}</InputLabel>
                                                    <CustomSelectWithChipMultilingual id="paymentMethods"
                                                        name="outlet.settings.paymentMethods"
                                                        required={true}
                                                        defaultValue={defaultPaymentMethodsSelected}
                                                        options={data.paymentMethods}
                                                        onChange={(event) => onSelectChangeWithParents(event, data, setData)}
                                                        onDelete={removePaymentMethod}
                                                        width="99.5%"
                                                    />
                                                </Grid>

                                                <Grid item xs={12} md={2}>
                                                    <InputLabel htmlFor="currency">{t('outlet.settings.currency.label')}</InputLabel>
                                                    <CustomSelectMultilingual id="currency"
                                                        name="outlet.settings.currency"
                                                        required={true}
                                                        defaultValue={defaultCurrencysSelected}
                                                        options={data.currencyCodes}
                                                        onChange={(event) => onSelectChangeWithParents(event, data, setData)}
                                                        width="99.5%"
                                                    />
                                                </Grid>

                                                <Grid item xs={12} md={3}>
                                                    <InputLabel htmlFor="taxType">{t('outlet.settings.tax.type')}</InputLabel>
                                                    <CustomSelectMultilingual id="taxType"
                                                        name="outlet.settings.taxType"
                                                        required={true}
                                                        defaultValue={defaultTaxTypeSelected}
                                                        options={data.taxTypes}
                                                        onChange={(event) => onSelectChangeWithParents(event, data, setData)}
                                                        width="99.5%"
                                                        translationPath="outlet.settings.tax"
                                                    />
                                                </Grid>

                                                <Grid item xs={12} md={3}>
                                                    <CustomTextField
                                                        type="number"
                                                        step="1"
                                                        min={0}
                                                        name="outlet.settings.taxNumberPercentage"
                                                        id="recurrenceDaysNumber"
                                                        variant="filled"
                                                        defaultValue={24}
                                                        inputAdornmentLabel="outlet.settings.tax.numberPercentage"
                                                        inputAdornmentAfterLabel="outlet.settings.tax.%"
                                                        required={false}
                                                        sx={{ marginBotttom: "40px", marginTop: "20px" }}
                                                        onChange={(event) => formValChangeWithParents(event, data, setData, t('table.valueReuired'))}
                                                    />
                                                </Grid>

                                                <Grid container spacing={2} sx={mainTagSearchItemOrderBoxContainer()}>
                                                    {data.outlet?.settings?.paymentMethods?.includes("cash") &&
                                                        <Grid item xs={12} md={12}>
                                                            <FormControlLabel control={<Switch defaultChecked={data.outlet.settings.automaticallyProduceReceiptForCashPayments} onChange={(event) => onControlChangeWithParents(event, data, setData, "outlet.settings.automaticallyProduceReceiptForCashPayments")} />} label={t('outlet.settings.automaticallyProduceReceiptForCashPayments')} />
                                                        </Grid>
                                                    }
                                                    <Grid item xs={12} md={12}>
                                                        <FormControlLabel control={<Switch defaultChecked={data.outlet.settings.allowComments} onChange={(event) => onControlChangeWithParents(event, data, setData, "outlet.settings.allowComments")} />} label={t('outlet.settings.allowComments')} />
                                                    </Grid>
                                                    <Grid item xs={12} md={12}>
                                                        <FormControlLabel control={<Switch defaultChecked={data.outlet.settings.enableCart} onChange={(event) => onControlChangeWithParents(event, data, setData, "outlet.settings.enableCart")} />} label={t('outlet.settings.enableCart')} />
                                                    </Grid>
                                                    <Grid item xs={12} md={12}>
                                                        <FormControlLabel control={<Switch defaultChecked={data.outlet.settings.enableOrder} onChange={(event) => onControlChangeWithParents(event, data, setData, "outlet.settings.enableOrder")} />} label={t('outlet.settings.enableOrder')} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>
                                        }
                                    />
                                </Grid>

                                <Grid item xs={12} md={6} sx={{ marginTop: "20px" }}>
                                    <CustomFieldset
                                        label={t('outlet.settings.invoice.label')}
                                        children={<>
                                            <Grid container spacing={2} sx={mainTagSearchItemOrderBoxContainer()}>
                                                <Grid item xs={12} md={11}>
                                                    <CustomTextField
                                                        label={t('outlet.settings.invoice.number')}
                                                        type="text"
                                                        name="outlet.invoiceCounter"
                                                        id="number"
                                                        onChange={(event) => formValChangeWithParentElementWithNameAndValue("outlet.invoiceCounter", event.target.value, data, setData)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={11}>
                                                    <CustomTextField
                                                        label={t('outlet.settings.invoice.series')}
                                                        type="text"
                                                        name="outlet.invoiceSeries"
                                                        id="series"
                                                        onChange={(event) => formValChangeWithParentElementWithNameAndValue("outlet.invoiceSeries", event.target.value, data, setData)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={11}>
                                                    <CustomTextField
                                                        label={t('outlet.settings.invoice.invoiceDOY')}
                                                        type="text"
                                                        name="outlet.invoiceDOY"
                                                        id="invoiceDOY"
                                                        onChange={(event) => formValChangeWithParentElementWithNameAndValue("outlet.invoiceDOY", event.target.value, data, setData)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={11}>
                                                    <CustomTextField
                                                        label={t('outlet.settings.invoice.invoiceBusiness')}
                                                        type="text"
                                                        name="outlet.invoiceBusiness"
                                                        id="invoiceBusiness"
                                                        onChange={(event) => formValChangeWithParentElementWithNameAndValue("outlet.invoiceBusiness", event.target.value, data, setData)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </>
                                        }
                                    />
                                </Grid>

                            </TabPanel>

                            <TabPanel value={"bookings"} sx={{ paddingTop: "0px" }} >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <FormControlLabel control={
                                            <Switch
                                                defaultChecked={data.outlet.settings.allowReservations}
                                                onChange={(event) => onControlChangeWithParents(event, data, setData, "outlet.settings.allowReservations")}
                                            />
                                        }
                                            label={t('outlet.settings.allowReservations')}
                                        />
                                    </Grid>
                                </Grid>
                            </TabPanel>

                            <TabPanel value={"request"} sx={{ paddingTop: "0px" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel htmlFor="allowedRequestTypes">{t('outlet.settings.request.allowedActions')}</InputLabel>
                                        <CustomSelectMultilingual
                                            id="request"
                                            isMultiple={true}
                                            name="outlet.settings.allowedRequestTypes"
                                            translationPath="outlet.settings.request.allowedRequestTypes"
                                            required={true}
                                            defaultValue={data.outlet.settings.allowedRequestTypes}
                                            options={data.requestTypes}
                                            onChange={(event) => onSelectChangeWithParents(event, data, setData)}
                                        />
                                    </Grid>
                                </Grid>

                                {data.outlet.settings.allowedRequestTypes?.includes("CUSTOM") &&
                                    <Grid item xs={12} md={12} sx={{ margin: "20px 0" }}>
                                        <CustomFieldset
                                            isOpen={false}
                                            label={`${t('actions.add')} ${t('outlet.settings.request.customRequestButtons')}`}
                                            children={<>
                                                <Grid container spacing={2} sx={{ marginTop: '5px', marginLeft: "10px" }}>
                                                    <Grid item xs={12} md={12}>
                                                        {data.outlet?.settings?.requestExtraCustomButtons?.map((extraRequestButton, index) => (
                                                            <Grid container spacing={2} key={index} sx={{ marginTop: 0, alignItems: "center" }}>
                                                                <Grid item xs={10} md={10}>
                                                                    <CustomFontAwesomeIconPicker
                                                                        icon={extraRequestButton.icon}
                                                                        label={extraRequestButton.label}
                                                                        index={index}
                                                                        handleExtraRequestButtonChange={(index, field, value) => handleExtraRequestButtonChange(index, field, value)} />
                                                                </Grid>

                                                                <Grid item xs={1} md={1}>
                                                                    <CustomIconButton
                                                                        keyValue={`btn-delete-${index}`}
                                                                        onClick={() => removeExtraRequestButton(index)}
                                                                        icon={<DeleteIcon style={extraRequestButtonGroupStackButtonIcon()} />}
                                                                        iconSx={{ marginRight: "0px!important" }}
                                                                        sx={{
                                                                            backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColor"],
                                                                            ":hover": {
                                                                                backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColorHover"],
                                                                            },
                                                                            ...extraRequestButtonGroupStackButton(),
                                                                            marginTop: "20px"
                                                                        }}
                                                                    >
                                                                        {t('actions.delete')}
                                                                    </CustomIconButton>
                                                                </Grid>
                                                            </Grid>
                                                        ))}
                                                    </Grid>

                                                    <Grid item xs={12} md={12} sx={{ marginBottom: "10px" }}>
                                                        <CustomIconButton
                                                            sx={addCardActionAreaButtonStyle()}
                                                            backgroundColor="#dcf1f7"
                                                            icon={<AddIcon sx={addExtraRequestButtonIconStyle()} />}
                                                            onClick={handleAddExtraRequestButton}>
                                                            {t('actions.add')}
                                                        </CustomIconButton>
                                                    </Grid>
                                                </Grid>
                                            </>
                                            }
                                        />
                                    </Grid>
                                }
                            </TabPanel>

                            <TabPanel value={"waitlist"} sx={{ paddingTop: "0px" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel htmlFor="outlet.settings.enabledWaitlistRequirements">{t('waitlist.enabledWaitlistRequirements.label')}</InputLabel>
                                        <CustomSelectMultilingual
                                            id="enabledWaitlistRequirements"
                                            isMultiple={true}
                                            name="outlet.settings.enabledWaitlistRequirements"
                                            translationPath="waitlist.enabledWaitlistRequirements"
                                            required={true}
                                            defaultValue={data.outlet.settings.enabledWaitlistRequirements === null ? [] : data.outlet.settings.enabledWaitlistRequirements}
                                            options={data.enabledWaitlistRequirementOptions}
                                            onChange={(event) => onSelectChangeWithParents(event, data, setData)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel htmlFor="outlet.settings.enabledWaitilistTags">{t('waitlist.enabledWaitilistTags.label')}</InputLabel>
                                        <CustomSelectMultilingual
                                            id="enabledWaitilistTags"
                                            isMultiple={true}
                                            name="outlet.settings.enabledWaitilistTags"
                                            translationPath="waitlist.enabledWaitilistTags"
                                            required={true}
                                            defaultValue={data.outlet.settings.enabledWaitilistTags === null ? [] : data.outlet.settings.enabledWaitilistTags}
                                            options={data.enabledWaitilistTagOptions}
                                            onChange={(event) => onSelectChangeWithParents(event, data, setData)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <CustomIconButton
                                            keyValue={`exportPdf`}
                                            onClick={(event, label) => data.waitlistQrcodePathNumber === null ? generateQRcodeForWaitlist() : exportQRcodeForWaitlist(event, label)}
                                            variant='outlined'
                                            backgroundColor="#dcf1f7"
                                            label={""}
                                            tooltipTitle={t(`waitlist.${data.waitlistQrcodePathNumber ? "generateBtn" : "exportBtn"}`)}
                                            icon={<SvgIcon component={QrCode2Icon} width={60} height={60} style={{ background: "transparent", color: "#54b1d2" }} />}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}></Grid>

                                    <Grid item xs={12} md={6} sx={{ marginTop: "20px" }}>
                                        <FormControlLabel control={
                                            <Switch
                                                defaultChecked={data.outlet.settings.requiredTagSelection}
                                                onChange={(event) => onControlChangeWithParents(event, data, setData, "outlet.settings.requiredTagSelection")} />
                                        }
                                            label={t('outlet.settings.waitlist.requiredTagSelection')}
                                        />
                                        <FormControlLabel control={
                                            <Switch
                                                defaultChecked={data.outlet.settings.waitlistActive}
                                                onChange={(event) => onControlChangeWithParents(event, data, setData, "outlet.settings.waitlistActive")} />
                                        }
                                            label={t('outlet.settings.waitlist.waitlistActive')}
                                        />
                                        {/* <FormControlLabel control={
                                            <Switch
                                                defaultChecked={data.outlet.settings.showWaitlistGroupInfo}
                                                onChange={(event) => onControlChangeWithParents(event, data, setData, "outlet.settings.showWaitlistGroupInfo")} />
                                        }
                                            label={t('outlet.settings.waitlist.showWaitlistGroupInfo')}
                                        /> */}
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <CustomFieldset
                                            label={t('outlet.settings.waitlist.style')}
                                            spacing={1}
                                            sx={{ padding: "10px", marginTop: "40px" }}
                                            children={<>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={6}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <InputLabel htmlFor="outlet.settings.waitingTime1Min">{t('outlet.settings.waitlist.waitingTime1Min')}</InputLabel>
                                                            </Grid>
                                                            <Grid item xs={6.7}>
                                                                <MuiColorInput
                                                                    label={t('outlet.settings.waitlist.waitingTime1ChipTextColor')}
                                                                    name="outlet.settings.waitingTime1ChipTextColor"
                                                                    format="hex8"
                                                                    value={data.outlet.settings.waitingTime1ChipTextColor}
                                                                    onChange={(value) => formValChangeWithParentElementWithNameAndValue("outlet.settings.waitingTime1ChipTextColor", value, data, setData)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <CustomTextField
                                                                    type="number"
                                                                    step="1"
                                                                    min={1}
                                                                    name="outlet.settings.waitingTime1Min"
                                                                    defaultValue={data.outlet.settings.waitingTime1Min}
                                                                    inputAdornmentAfterLabel={"outlet.settings.waitlist.minutes"}
                                                                    onChange={(event) => formValChangeWithParentElementWithNameAndValue("outlet.settings.waitingTime1Min", event.target.value, data, setData)}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <InputLabel htmlFor="outlet.settings.waitingTime2Min">{t('outlet.settings.waitlist.waitingTime2Min')}</InputLabel>
                                                            </Grid>
                                                            <Grid item xs={6.7}>
                                                                <MuiColorInput
                                                                    label={t('outlet.settings.waitlist.waitingTime2ChipTextColor')}
                                                                    name="outlet.settings.waitingTime2ChipTextColor"
                                                                    format="hex8"
                                                                    value={data.outlet.settings.waitingTime2ChipTextColor}
                                                                    onChange={(value) => formValChangeWithParentElementWithNameAndValue("outlet.settings.waitingTime2ChipTextColor", value, data, setData)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <CustomTextField
                                                                    type="number"
                                                                    step="1"
                                                                    min={data.outlet.settings.waitingTime1Min + 1}
                                                                    name="outlet.settings.waitingTime2Min"
                                                                    defaultValue={data.outlet.settings.waitingTime2Min}
                                                                    inputAdornmentAfterLabel={"outlet.settings.waitlist.minutes"}
                                                                    onChange={(event) => formValChangeWithParentElementWithNameAndValue("outlet.settings.waitingTime2Min", event.target.value, data, setData)}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <InputLabel htmlFor="outlet.settings.waitingTime3">{t('outlet.settings.waitlist.waitingTime3')}</InputLabel>
                                                            </Grid>
                                                            <Grid item xs={6.7}>
                                                                <MuiColorInput
                                                                    label={t('outlet.settings.waitlist.waitingTime3ChipTextColor')}
                                                                    name="outlet.settings.waitingTime3ChipTextColor"
                                                                    format="hex8"
                                                                    value={data.outlet.settings.waitingTime3ChipTextColor}
                                                                    onChange={(value) => formValChangeWithParentElementWithNameAndValue("outlet.settings.waitingTime3ChipTextColor", value, data, setData)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} md={5.8} sx={{ marginTop: "42px" }}>
                                                        <MuiColorInput
                                                            label={t('outlet.settings.waitlist.estimatedTimeChipTextColor')}
                                                            name="outlet.settings.estimatedTimeChipTextColor"
                                                            format="hex8"
                                                            value={data.outlet.settings.estimatedTimeChipTextColor}
                                                            onChange={(value) => formValChangeWithParentElementWithNameAndValue("outlet.settings.estimatedTimeChipTextColor", value, data, setData)}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} md={6} sx={{ marginTop: "20px" }}>
                                                        <MuiColorInput
                                                            label={t('outlet.settings.waitlist.waitlistSquareChipTextColor')}
                                                            name="outlet.settings.waitlistSquareChipTextColor"
                                                            format="hex8"
                                                            value={data.outlet.settings.waitlistSquareChipTextColor}
                                                            onChange={(value) => formValChangeWithParentElementWithNameAndValue("outlet.settings.waitlistSquareChipTextColor", value, data, setData)}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} md={5.8} sx={{ marginTop: "20px" }}>
                                                        <MuiColorInput
                                                            label={t('outlet.settings.waitlist.waitlistPositionSquareChipTextColor')}
                                                            name="outlet.settings.waitlistPositionSquareChipTextColor"
                                                            format="hex8"
                                                            value={data.outlet.settings.waitlistPositionSquareChipTextColor}
                                                            onChange={(value) => formValChangeWithParentElementWithNameAndValue("outlet.settings.waitlistPositionSquareChipTextColor", value, data, setData)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </>}
                                        />
                                    </Grid>


                                </Grid>
                            </TabPanel>

                            <TabPanel value={"ratings"} sx={{ paddingTop: "0px" }} >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel htmlFor="minRatingNumberToGoogle">{t('outlet.settings.ratings.minRatingNumberToGoogle')}</InputLabel>
                                        <CustomRating
                                            size={"large"}
                                            iconStyle={data.outlet.settings.ratingStyle}
                                            value={data.outlet.settings.minRatingNumberToGoogle}
                                            sx={{ alignItems: "center" }}
                                            labelSize={"40px"}
                                            onChange={(event) => formValChangeWithParentElementWithNameAndValue("outlet.settings.minRatingNumberToGoogle", event.target.value, data, setData)}
                                        />
                                    </Grid>
                                </Grid>
                            </TabPanel>

                            <TabPanel value={"scanPage"} sx={{ paddingTop: "0px" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <CustomCheckbox
                                            name="outlet.settings.allowActionFromAuthenticatedUserOnly"
                                            selected={data.outlet.settings.allowActionFromAuthenticatedUserOnly}
                                            onChange={(event) => onControlChangeWithParents(event, data, setData, "outlet.settings.allowActionFromAuthenticatedUserOnly")}
                                            label={t('outlet.settings.afterQrDispay.allowActionFromAuthenticatedUserOnly')}
                                        />
                                    </Grid>

                                    {data.outlet.settings.allowActionFromAuthenticatedUserOnly &&
                                        <>
                                            <Grid item xs={12} md={12}>
                                                <FormControlLabel control={<Switch defaultChecked={data.outlet.settings.allowOrdesFromAuthenticatedUsersOnly} onChange={(event) => onControlChangeWithParents(event, data, setData, "outlet.settings.allowOrdesFromAuthenticatedUsersOnly")} />} label={t('outlet.settings.afterQrDispay.allowOrdesFromAuthenticatedUsersOnly')} />
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <FormControlLabel control={<Switch defaultChecked={data.outlet.settings.allowRequestsFromAuthenticatedUserOnly} onChange={(event) => onControlChangeWithParents(event, data, setData, "outlet.settings.allowRequestsFromAuthenticatedUserOnly")} />} label={t('outlet.settings.afterQrDispay.allowRequestsFromAuthenticatedUserOnly')} />
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <FormControlLabel control={<Switch defaultChecked={data.outlet.settings.allowRequestsFromAuthenticatedUserOnly} onChange={(event) => onControlChangeWithParents(event, data, setData, "outlet.settings.allowRatingsFromAuthenticatedUserOnly")} />} label={t('outlet.settings.afterQrDispay.allowRatingsFromAuthenticatedUserOnly')} />
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <FormControlLabel control={<Switch defaultChecked={data.outlet.settings.allowWaitlistFromAuthenticatedUserOnly} onChange={(event) => onControlChangeWithParents(event, data, setData, "outlet.settings.allowWaitlistFromAuthenticatedUserOnly")} />} label={t('outlet.settings.afterQrDispay.allowWaitlistFromAuthenticatedUserOnly')} />
                                            </Grid>
                                        </>
                                    }

                                    <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                                        <CustomCheckbox
                                            name="outlet.settings.afterScanLandOnMenuPage"
                                            selected={data.outlet.settings.afterScanLandOnMenuPage}
                                            onChange={(event) => onControlChangeWithParents(event, data, setData, "outlet.settings.afterScanLandOnMenuPage")}
                                            label={t('outlet.settings.afterQrDispay.afterScanLandOnMenuPage')}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        {!data.outlet.settings.afterScanLandOnMenuPage &&
                                            <CustomSelectMultilingual
                                                id="request"
                                                isMultiple={true}
                                                name="outlet.settings.afterScanLandOnMenuPageButtonOptions"
                                                translationPath="outlet.settings.afterQrDispay.afterScanLandOnMenuPageButtonOptions"
                                                required={true}
                                                defaultValue={data.outlet.settings.afterScanLandOnMenuPageButtonOptions}
                                                options={data.afterScanOptions}
                                                onChange={(event) => onSelectChangeWithParents(event, data, setData)}
                                            />
                                        }

                                        {data.outlet.settings.afterScanLandOnMenuPageButtonOptions?.includes("CUSTOM") &&
                                            <Grid item xs={12} md={12} sx={{ margin: "20px 0" }}>
                                                <CustomFieldset
                                                    isOpen={false}
                                                    label={`${t('actions.add')} ${t('outlet.settings.extraOutletActionButtons.label')}`}
                                                    children={<>
                                                        <Grid container spacing={2} sx={{ marginTop: '5px', marginLeft: "10px" }}>
                                                            <Grid item xs={12} md={12}>
                                                                {data.outlet?.settings?.extraOutletActionButtons?.map((extraOutletActionButton, index) => (
                                                                    <Grid container spacing={2} key={index} sx={{ marginTop: 0, alignItems: "center" }}>
                                                                        <Grid item xs={10} md={10}>
                                                                            <CustomFontAwesomeIconUrlPicker
                                                                                icon={extraOutletActionButton.icon}
                                                                                label={extraOutletActionButton.label}
                                                                                url={extraOutletActionButton.url}
                                                                                index={index}
                                                                                handleExtraButtonChange={(index, field, value) => handleExtraOutletActionButtonChange(index, field, value)} />
                                                                        </Grid>

                                                                        <Grid item xs={1} md={1}>
                                                                            <CustomIconButton
                                                                                keyValue={`btn-delete-${index}`}
                                                                                onClick={() => removeExtraOutletActionButton(index)}
                                                                                icon={<DeleteIcon style={extraRequestButtonGroupStackButtonIcon()} />}
                                                                                iconSx={{ marginRight: "0px!important" }}
                                                                                sx={{
                                                                                    backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColor"],
                                                                                    ":hover": {
                                                                                        backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColorHover"],
                                                                                    },
                                                                                    ...extraRequestButtonGroupStackButton(),
                                                                                    marginTop: "20px"
                                                                                }}
                                                                            >
                                                                                {t('actions.delete')}
                                                                            </CustomIconButton>
                                                                        </Grid>
                                                                    </Grid>
                                                                ))}
                                                            </Grid>

                                                            <Grid item xs={12} md={12} sx={{ marginBottom: "10px" }}>
                                                                <CustomIconButton
                                                                    sx={addCardActionAreaButtonStyle()}
                                                                    backgroundColor="#dcf1f7"
                                                                    icon={<AddIcon sx={addExtraRequestButtonIconStyle()} />}
                                                                    onClick={handleAddExtraOutletActionButton}>
                                                                    {t('actions.add')}
                                                                </CustomIconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                    }
                                                />
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </TabPanel>

                            <TabPanel value={"splashscreen"} sx={{ paddingTop: "0px" }} >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={8}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={12}>
                                                <FormControlLabel control={
                                                    <Switch
                                                        defaultChecked={data.outlet.settings.splashScreenConfiguration.enable}
                                                        onChange={(event) => onControlChangeWithParents(event, data, setData, "outlet.settings.splashScreenConfiguration.enable")} />
                                                }
                                                    label={t('outlet.settings.splashScreenConfiguration.enable')}
                                                />
                                            </Grid>
                                            {data.outlet.settings.splashScreenConfiguration.enable &&
                                                <>
                                                    <Grid item xs={12} md={6}>
                                                        <FormControlLabel control={
                                                            <Switch
                                                                defaultChecked={data.outlet.settings.splashScreenConfiguration.showLogo}
                                                                onChange={(event) => onControlChangeWithParents(event, data, setData, "outlet.settings.splashScreenConfiguration.showLogo")} />
                                                        }
                                                            label={t('outlet.settings.splashScreenConfiguration.showLogo')}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <MuiColorInput
                                                            label={t('outlet.settings.splashScreenConfiguration.backgroundColor')}
                                                            name="outlet.settings.splashScreenConfiguration.backgroundColor"
                                                            format="hex8"
                                                            value={data.outlet.settings.splashScreenConfiguration.backgroundColor}
                                                            onChange={(value) => formValChangeWithParentElementWithNameAndValue("outlet.settings.splashScreenConfiguration.backgroundColor", value, data, setData)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={12}>
                                                        <FormControlLabel control={
                                                            <Switch
                                                                defaultChecked={data.outlet.settings.splashScreenConfiguration.showDescription}
                                                                onChange={(event) => onControlChangeWithParents(event, data, setData, "outlet.settings.splashScreenConfiguration.showDescription")} />}
                                                            label={t('outlet.settings.splashScreenConfiguration.showDescription')}
                                                        />
                                                    </Grid>
                                                    {data.outlet.settings.splashScreenConfiguration.showDescription &&
                                                        <>
                                                            <Grid item xs={12} md={6}>
                                                                <CustomTextField
                                                                    label={t('outlet.settings.splashScreenConfiguration.description')}
                                                                    type="text"
                                                                    name="outlet.settings.splashScreenConfiguration.description"
                                                                    id="description"
                                                                    onChange={(event) => formValChangeWithParentElementWithNameAndValue("outlet.settings.splashScreenConfiguration.description", event.target.value, data, setData)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <MuiColorInput
                                                                    label={t('outlet.settings.splashScreenConfiguration.descriptionColor')}
                                                                    name="outlet.settings.splashScreenConfiguration.descriptionColor"
                                                                    format="hex8"
                                                                    value={data.outlet.settings.splashScreenConfiguration.descriptionColor}
                                                                    onChange={(value) => formValChangeWithParentElementWithNameAndValue("outlet.settings.splashScreenConfiguration.descriptionColor", value, data, setData)}
                                                                />
                                                            </Grid>
                                                        </>
                                                    }
                                                    <Grid item xs={12} md={6} sx={{ marginTop: "30px" }}>
                                                        <CustomTextField
                                                            type="number"
                                                            step="1"
                                                            min={1}
                                                            max={6}
                                                            label={t('outlet.settings.splashScreenConfiguration.durationInSeconds')}
                                                            name="outlet.settings.splashScreenConfiguration.durationInSeconds"
                                                            defaultValue={data.outlet.settings.splashScreenConfiguration.durationInSeconds}
                                                            inputAdornmentAfterLabel={"outlet.settings.splashScreenConfiguration.durationInSecondsAfterText"}
                                                            onChange={(event) => formValChangeWithParentElementWithNameAndValue("outlet.settings.splashScreenConfiguration.durationInSeconds", event.target.value, data, setData)}
                                                        />
                                                    </Grid>
                                                </>
                                            }
                                        </Grid>
                                    </Grid>

                                    {data.outlet.settings.splashScreenConfiguration.enable &&
                                        <Grid item xs={12} md={4}>
                                            <Splashscreen
                                                logo={data.outlet.logo}
                                                splashScreen={data.outlet.settings.splashScreenConfiguration}
                                                logoPath={`https://storage.usee.gr/usee/organization/${data.organization.id}/outlet/${data.outlet.id}/`}
                                            />
                                        </Grid>
                                    }
                                </Grid>
                            </TabPanel>

                            <TabPanel value={"qrcode"} sx={{ paddingTop: "0px" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <Stack direction="row" sx={{ padding: "10px", width: "calc(100% - 40px)", alignItems: "center" }}>
                                            <Grid container gap={2}>
                                                {data.outlet.settings.qrcodeTemplates?.map((qrcodeTemplate, index) => (
                                                    <CustomQrcodeTemplate
                                                        qrcodeTemplate={qrcodeTemplate}
                                                        handleDialogState={handleAddEditDialogState}
                                                        removeQRTemplate={removeQRTemplate}
                                                        logoImgSrc={qrcodeTemplate.icon !== "none" ? data.outlet.logo.includes("base64") ?
                                                            data.outlet.logo
                                                            :
                                                            `https://storage.usee.gr/usee/organization/${data.organization.id}/outlet/${data.outlet.id}/${data.outlet.logo}`
                                                            : ""}
                                                        organizationID={data.organization.id}
                                                        outletID={data.outlet.id}
                                                        index={index}
                                                    />
                                                ))}
                                                <CustomAddQrcodeTemplateCard onClick={() => handleAddEditDialogState(true)} />
                                            </Grid>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </TabPanel>

                            <TabPanel value={"wifi"} sx={{ paddingTop: "0px" }} >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <Stack direction="row" sx={{ padding: "10px", width: "calc(100% - 40px)", alignItems: "center" }}>
                                            <Grid container gap={2}>
                                                {data.outlet.settings.wifiConfigurations?.map((wifi, index) => (
                                                    <CustomWifiConfigurationPreviewCard
                                                        wifiConfiguration={wifi}
                                                        index={index}
                                                        updateWifiConfigurationState={updateWifiConfigurationState}
                                                        updateWifiConfiguration={() => handleWifiAddEditDialogState(true, false, index)}
                                                        deleteWifiConfiguration={removeWifi}
                                                    />
                                                ))}
                                                <CustomAddWifiCard onClick={() => handleWifiAddEditDialogState(true)} />
                                            </Grid>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </TabPanel>

                            <TabPanel value={"style"} sx={{ paddingTop: "0px" }}>
                                <Grid container gap={1}>
                                    <Grid item xs={12} sm={"auto"} sx={{ marginTop: "auto" }}>
                                        <InputLabel htmlFor="headerFont">{t('outlet.settings.style.backgroundColor')}</InputLabel>
                                        <MuiColorInput
                                            name="outlet.settings.backgroundColor"
                                            format="hex8"
                                            value={data.outlet.settings.backgroundColor}
                                            onChange={(value) => formValChangeWithParentElementWithNameAndValue("outlet.settings.backgroundColor", value, data, setData)}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={12} sm={4}>
                                        <InputLabel htmlFor="headerFont">{t('outlet.settings.style.headerFont')}</InputLabel>
                                        <CustomSelect
                                            name="menu.settings.headerFont"
                                            required={true}
                                            variant={"outlined"}
                                            defaultValue={data.outlet.settings.headerFont}
                                            options={getFontFamilyOptions()}
                                            onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                        />
                                    </Grid> */}
                                    <Grid item xs={12} sm={"auto"} sx={{ marginTop: "auto" }}>
                                        <InputLabel htmlFor="headerColor">{t('outlet.settings.style.headerColor')}</InputLabel>
                                        <MuiColorInput
                                            name="outlet.settings.headerColor"
                                            format="hex8"
                                            value={data.outlet.settings.headerColor}
                                            onChange={(value) => formValChangeWithParentElementWithNameAndValue("outlet.settings.headerColor", value, data, setData)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={"auto"}>
                                        <InputLabel htmlFor="headerFontSize">{t('outlet.settings.style.headerFontSize')}</InputLabel>
                                        <CustomTextField
                                            type="number"
                                            step={getFontSize().step}
                                            min={getFontSize().min}
                                            max={getFontSize().max}
                                            name="outlet.settings.style.headerFontSize"
                                            inputAdornmentAfterLabel={t("glabal.fontSizeAfterAdorment")}
                                            value={data.outlet.settings.headerFontSize}
                                            required={true}
                                            onChange={(event) => {
                                                let value = validateInputNumber(event.target.value, getFontSize().length, getFontSize().min, getFontSize().max, getFontSize().isFloat);
                                                if (value === undefined) value = data.outlet.settings.headerFontSize;
                                                formValChangeWithParentElementWithNameAndValue("outlet.settings.headerFontSize", parseFloat(value), data, setData)
                                            }}
                                        />
                                    </Grid>

                                    {/* <Grid item xs={12} md={4}>
                                        <InputLabel htmlFor="textFont">{t('outlet.settings.style.textFont')}</InputLabel>
                                        <CustomSelect
                                            name="menu.settings.textFont"
                                            required={true}
                                            variant={"outlined"}
                                            defaultValue={data.outlet.settings.textFont}
                                            options={getFontFamilyOptions()}
                                            onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                        />
                                    </Grid> */}
                                    <Grid item xs={12} sm={"auto"} sx={{ marginTop: "auto" }}>
                                        <InputLabel htmlFor="textColor">{t('outlet.settings.style.textColor')}</InputLabel>
                                        <MuiColorInput
                                            name="outlet.settings.textColor"
                                            format="hex8"
                                            value={data.outlet.settings.textColor}
                                            onChange={(value) => formValChangeWithParentElementWithNameAndValue("outlet.settings.textColor", value, data, setData)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={"auto"}>
                                        <InputLabel htmlFor="textFontSize">{t('outlet.settings.style.textFontSize')}</InputLabel>
                                        <CustomTextField
                                            type="number"
                                            step={getFontSize().step}
                                            min={getFontSize().min}
                                            max={getFontSize().max}
                                            name="outlet.settings.style.textFontSize"
                                            inputAdornmentAfterLabel={t("glabal.fontSizeAfterAdorment")}
                                            value={data.outlet.settings.textFontSize}
                                            required={true}
                                            onChange={(event) => {
                                                let value = validateInputNumber(event.target.value, getFontSize().length, getFontSize().min, getFontSize().max, getFontSize().isFloat);
                                                if (value === undefined) value = data.outlet.settings.textFontSize;
                                                formValChangeWithParentElementWithNameAndValue("outlet.settings.textFontSize", parseFloat(value), data, setData)
                                            }}
                                        />
                                    </Grid>

                                    {/* <Grid item xs={12} sm={4}>
                                        <InputLabel htmlFor="textInfoFont">{t('outlet.settings.style.textInfoFont')}</InputLabel>
                                        <CustomSelect
                                            name="menu.settings.textInfoFont"
                                            required={true}
                                            variant={"outlined"}
                                            defaultValue={data.outlet.settings.textInfoFont}
                                            options={getFontFamilyOptions()}
                                            onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                        />
                                    </Grid> */}
                                    <Grid item xs={12} sm={"auto"} sx={{ marginTop: "auto" }}>
                                        <InputLabel htmlFor="textInfoColor">{t('outlet.settings.style.textInfoColor')}</InputLabel>
                                        <MuiColorInput
                                            name="outlet.settings.textInfoColor"
                                            format="hex8"
                                            value={data.outlet.settings.textInfoColor}
                                            onChange={(value) => formValChangeWithParentElementWithNameAndValue("outlet.settings.textInfoColor", value, data, setData)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={"auto"}>
                                        <InputLabel htmlFor="textInfoFontSize">{t('outlet.settings.style.textInfoFontSize')}</InputLabel>
                                        <CustomTextField
                                            type="number"
                                            step={getFontSize().step}
                                            min={getFontSize().min}
                                            max={getFontSize().max}
                                            name="outlet.settings.style.textInfoFontSize"
                                            inputAdornmentAfterLabel={t("glabal.fontSizeAfterAdorment")}
                                            value={data.outlet.settings.textInfoFontSize}
                                            required={true}
                                            onChange={(event) => {
                                                let value = validateInputNumber(event.target.value, getFontSize().length, getFontSize().min, getFontSize().max, getFontSize().isFloat);
                                                if (value === undefined) value = data.outlet.settings.textInfoFontSize;
                                                formValChangeWithParentElementWithNameAndValue("outlet.settings.textInfoFontSize", parseFloat(value), data, setData)
                                            }}
                                        />
                                    </Grid>


                                    <Grid item xs={12}></Grid>

                                    <Grid item xs={12} sm={5.8}>
                                        <CustomFieldset
                                            label={t('outlet.settings.style.chips')}
                                            spacing={1}
                                            sx={{ padding: "10px" }}
                                            children={<>
                                                {/* <Grid item xs={12} sm={4}>
                                                    <InputLabel htmlFor="buttonFont">{t('outlet.settings.style.buttonFont')}</InputLabel>
                                                    <CustomSelect
                                                        name="menu.settings.buttonFont"
                                                        required={true}
                                                        variant={"outlined"}
                                                        defaultValue={data.outlet.settings.buttonFont}
                                                        options={getFontFamilyOptions()}
                                                        onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                                    />
                                                </Grid> */}
                                                <Grid item xs={12} sm={"auto"} sx={{ marginTop: "auto" }}>
                                                    <InputLabel htmlFor="buttonColor">{t('outlet.settings.style.buttonColor')}</InputLabel>
                                                    <MuiColorInput
                                                        name="outlet.settings.buttonColor"
                                                        format="hex8"
                                                        value={data.outlet.settings.buttonColor}
                                                        onChange={(value) => formValChangeWithParentElementWithNameAndValue("outlet.settings.buttonColor", value, data, setData)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={"auto"} sx={{ marginTop: "auto" }}>
                                                    <InputLabel htmlFor="buttonBackgroundColor">{t('outlet.settings.style.buttonBackgroundColor')}</InputLabel>
                                                    <MuiColorInput
                                                        name="outlet.settings.buttonBackgroundColor"
                                                        format="hex8"
                                                        value={data.outlet.settings.buttonBackgroundColor}
                                                        onChange={(value) => formValChangeWithParentElementWithNameAndValue("outlet.settings.buttonBackgroundColor", value, data, setData)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={"auto"}>
                                                    <InputLabel htmlFor="buttonFontSize">{t('outlet.settings.style.buttonFontSize')}</InputLabel>
                                                    <CustomTextField
                                                        type="number"
                                                        step={getFontSize().step}
                                                        min={getFontSize().min}
                                                        max={getFontSize().max}
                                                        name="outlet.settings.style.buttonFontSize"
                                                        inputAdornmentAfterLabel={t("glabal.fontSizeAfterAdorment")}
                                                        value={data.outlet.settings.buttonFontSize}
                                                        required={true}
                                                        onChange={(event) => {
                                                            let value = validateInputNumber(event.target.value, getFontSize().length, getFontSize().min, getFontSize().max, getFontSize().isFloat);
                                                            if (value === undefined) value = data.outlet.settings.buttonFontSize;
                                                            formValChangeWithParentElementWithNameAndValue("outlet.settings.buttonFontSize", parseFloat(value), data, setData)
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <Button
                                                        ariant="extended"
                                                        sx={{
                                                            width: "100px",
                                                            borderRadius: "10px",
                                                            bottom: -5,
                                                            height: "40px",
                                                            background: data.outlet.settings.buttonBackgroundColor,
                                                            color: data.outlet.settings.buttonColor,
                                                            ":hover": { background: `${data.outlet.settings.buttonBackgroundColor}bf` }
                                                        }}>
                                                        <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
                                                            <Grid container>
                                                                <Grid item xs={4}></Grid>
                                                                <Grid item xs={4} sx={{ textAlign: "-webkit-center" }}>
                                                                    <CustomTypography
                                                                        variant="body2"
                                                                        text={t('cart.label')}
                                                                        sx={{ fontWeight: "bold", fontSize: `${data.outlet.settings.buttonFontSize}px` }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={4}></Grid>
                                                            </Grid>
                                                        </Stack>
                                                    </Button>
                                                </Grid>
                                            </>}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={5.8}>
                                        <CustomFieldset
                                            label={t('outlet.settings.style.buttons')}
                                            spacing={1}
                                            sx={{ padding: "10px" }}
                                            children={<>
                                                <Grid item xs={12} sm={3} md={"auto"}>
                                                    <InputLabel htmlFor="chipTextColor">{t('outlet.settings.style.chipTextColor')}</InputLabel>
                                                    <MuiColorInput
                                                        name="outlet.settings.chipTextColor"
                                                        format="hex8"
                                                        value={data.outlet.settings.chipTextColor}
                                                        onChange={(value) => formValChangeWithParentElementWithNameAndValue("outlet.settings.chipTextColor", value, data, setData)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={3} md={"auto"}>
                                                    <InputLabel htmlFor="chipPercentageTextColor">{t('outlet.settings.style.chipPercentageTextColor')}</InputLabel>
                                                    <MuiColorInput
                                                        name="outlet.settings.chipPercentageTextColor"
                                                        format="hex8"
                                                        value={data.outlet.settings.chipPercentageTextColor}
                                                        onChange={(value) => formValChangeWithParentElementWithNameAndValue("outlet.settings.chipPercentageTextColor", value, data, setData)}
                                                    />
                                                    <CustomChip
                                                        label={"25%"}
                                                        sx={{
                                                            background: replaceLastTwoChars(data.outlet.settings.chipPercentageTextColor, "1c"),
                                                            color: data.outlet.settings.chipPercentageTextColor
                                                        }} />
                                                </Grid>
                                                <Grid item xs={12} sm={3} md={"auto"}>
                                                    <InputLabel htmlFor="chip1plus1TextColor">{t('outlet.settings.style.chip1plus1TextColor')}</InputLabel>
                                                    <MuiColorInput
                                                        name="outlet.settings.chip1plus1TextColor"
                                                        format="hex8"
                                                        value={data.outlet.settings.chip1plus1TextColor}
                                                        onChange={(value) => formValChangeWithParentElementWithNameAndValue("outlet.settings.chip1plus1TextColor", value, data, setData)}
                                                    />
                                                    <CustomChip
                                                        label={"1 + 1"}
                                                        sx={{
                                                            background: replaceLastTwoChars(data.outlet.settings.chip1plus1TextColor, "1c"),
                                                            color: data.outlet.settings.chip1plus1TextColor
                                                        }} />
                                                </Grid>
                                            </>}
                                        />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                        </TabContext>

                        <CustomIconButton variant="outlined" keyValue="save" onClick={() => editOutletSettings(data)} label={t('actions.save')} sx={formSaveButton()} />
                    </form>

                    <Fab color="primary"
                        aria-label="add"
                        sx={fabStyle()}
                        onClick={() => editOutletSettings(data)}
                    >
                        <SaveIcon />
                    </Fab>
                </CustomGrid>

                {dialog.isOpen &&
                    <CustomTranslationsDialog
                        isOpen={dialog.isOpen}
                        id="translations"
                        title={t('actions.translationModal.title')}
                        actionLabel={t('actions.save')}
                        action={configureTranslations}
                        handleOpen={handleTranslationsDialogState}
                        translations={data.outlet.settings.hours.indicator[dialog.field]}
                        field={dialog.field}
                    />
                }

                {/* ===================== Start QR code=============== */}
                {dialogAddEdit.isOpen &&
                    <AddEditQRcodeTemplate
                        isOpen={dialogAddEdit.isOpen}
                        handleOpen={handleAddEditDialogState}
                        isAdd={dialogAddEdit.isAdd}
                        qrcode={dialogAddEdit.isAdd ? getDefaultQrcodeTemplate() : data.outlet.settings.qrcodeTemplates[dialogAddEdit.index]}
                        addQrCodeTemplate={addQrCodeTemplate}
                        updateQrCodeTemplate={updatedQrCodeTemplate}
                        logoImgSrc={data.outlet.logo}
                        organizationID={data.organization.id}
                        outletID={data.outlet.id}
                        qRcodeEcLevels={data.qRcodeEcLevels}
                        qRcodeLogoPaddingStyles={data.qRcodeLogoPaddingStyles}
                        qRcodeQrStyles={data.qRcodeQrStyles}
                        index={dialogAddEdit.index}
                    />
                }

                {/* WIFI */}
                {dialogWifiAddEdit.isOpen &&
                    <CustomDialogBasedOnScreenChildren
                        isOpen={dialogWifiAddEdit.isOpen}
                        id="wifi"
                        title={dialogWifiAddEdit.isAdd ? t('outlet.settings.wifi.addWifi') : t('outlet.settings.wifi.editWifi')}
                        actionLabel={dialogWifiAddEdit.isAdd ? t("actions.add") : t("actions.save")}
                        cancelLabel={t("actions.cancel")}
                        handleClose={handleWifiAddEditDialogState}
                        handleOpen={handleWifiAddEditDialogState}
                        handleAction={() => dialogWifiAddEdit.isAdd ? addWifi(wifiDetails) : updateWifi(wifiDetails, dialogWifiAddEdit.index)}
                        children={
                            <CustomWifiConfigCard
                                wifiDetails={wifiDetails}
                                setWifiDetails={setWifiDetails}
                            />
                        }
                    />
                }

                {deleteDialog.isOpen &&
                    <CustomDialogBasedOnScreenChildren
                        isOpen={deleteDialog.isOpen}
                        id="delete"
                        title={t('actions.removeModal.title')}
                        actionLabel={t("actions.removeModal.message")}
                        cancelLabel={t("actions.cancel")}
                        message={`${t('actions.removeModal.notice')} ${t('actions.removeModal.confirm')}: `}
                        children={
                            <div>
                                <CustomQRCode {...data.outlet.settings.qrcodeTemplates[deleteDialog.index]}
                                    logoImage={data.outlet.settings.qrcodeTemplates[deleteDialog.index].icon !== "none" ? data.outlet.logo.includes("base64") ?
                                        data.outlet.logo
                                        :
                                        `https://storage.usee.gr/usee/organization/${data.organization.id}/outlet/${data.outlet.id}/${data.outlet.logo}`
                                        : ""} />
                            </div>
                        }
                        handleAction={removeQR}
                        handleClose={handleDeleteDialogState}
                        handleOpen={handleDeleteDialogState}
                    />
                }

                {exportQrDialog.isOpen &&
                    <ExportQrInstanceDialog
                        isOpen={exportQrDialog.isOpen}
                        id="export-qrcode-waitlist"
                        title={`${t('actions.exportQR.title')} ${exportQrDialog.label}`}
                        actionLabel={t("actions.exportQR.actionButton")}
                        cancelLabel={t("actions.cancel")}
                        boldMessage={<span style={{ fontWeight: "bold" }}>{exportQrDialog.label}</span>}
                        action={exportQRcodeForWaitlist}
                        handleOpen={handleExportQrDialogState}
                        actionColor="#54b1d2"
                        qrCodeTemplates={data.outlet.settings.qrcodeTemplates}
                        logo={
                            data.outlet.logo.includes("base64") ?
                                data.outlet.logo
                                : `https://storage.usee.gr/usee/organization/${data.organization.id}/outlet/${data.outlet.id}/${data.outlet.logo}`
                        }
                        organizationID={data.organization.id}
                        outletID={data.outlet?.id}
                        qrCodePathNumber={data.waitlistQrcodePathNumber}
                    />
                }

                {/* ===================== End QR code=============== */}

                {alertBox.isOpen &&
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={3000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        handleSnackbarOpen={handleSnackbarState} />
                }
            </CustomGrid>
        );
    }
}

export default OutletSettingsComponent;