import { Button, Checkbox, TextField, Drawer, Grid, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { DeleteIcon } from 'assets/mui/MuiIcons';
import { MuiColorInput } from 'mui-color-input';
import formatBytes from 'utils/formatBytes';
import { shapeOptions, tablesOptions } from 'constants/floor/floorOptions';
import { getLanguageFromURL } from 'utils/language';
// import DragFloorItem from 'components/Table/draggable/floor/DragFloorItem';

/**
 * The FloorPlannerDrawer, that display floor planner drawer.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function FloorPlannerDrawer({
    link = null,
    sidebarOpen = false,
    children = undefined,
    sidebarWidth = "30%",
    selectedSection,
    setSelectedSection,
    selectedObject,
    nameChangeHandler,
    fileChangeHandler,
    setDeleteDialog

}) {
    const { t } = useTranslation();
    // let lang = getLanguageFromURL();

    const navigate = useNavigate();
    const location = useLocation();
    const [openV, setOpenV] = useState(sidebarOpen);
    const isActive = location.pathname === link || children?.some(child => location.pathname === child.link); // Check if the current item or any child is active

    let selectedObjectObj = selectedSection?.objects?.find(
        (o) => o.id === selectedObject
    );

    /**
     * Fucntion that handles the collapsable menu click.
     */
    const handleClick = () => {
        if (children) {
            setOpenV(!openV);
        } else if (link) {
            navigate(link);
        }
    }

    // active menu item on page load
    useEffect(() => {
        // Automatically open the parent item if a child item is active
        if (isActive && children) {
            setOpenV(true);
        }
    }, [isActive, children]);


    return (
        <Drawer
            sx={{
                height: "100%",
                flexShrink: 0,
                width: sidebarWidth,
                "& .MuiDrawer-paper": {
                    width: sidebarWidth,
                    height: "100%",
                    maxHeight: "100%",
                    overflowY: "auto",
                    boxSizing: "border-box",
                    // marginTop: "87.7px",
                    // pb: "100px",
                    position: "absolute"
                },
            }}
            variant='persistent'
            anchor='left'
            open={sidebarOpen}
        >
            <Grid container direction='column'>
                {/* room details */}
                <Grid item sx={{ p: "1em", borderTop: "1px solid #d8d8d8" }}>
                    <Typography variant='body1'>{t("display.floorPlanning.drawer.sectionDetails")}</Typography>
                    {/* Total Capacity,Tables, Online Capacity */}
                    <Grid
                        container
                        spacing={2}
                        justifyContent='space-between'
                        sx={{ mt: "6px" }}
                    >
                        {/* Total Capacity */}
                        <Grid item>
                            <Grid container direction='column' alignItems='center'>
                                <Grid item>
                                    <Typography
                                        variant='body1'
                                        align='center'
                                        sx={{ fontSize: "13px", color: "#4a4c50", fontWeight: 700 }}
                                    >
                                        {selectedSection?.objects
                                            ?.filter((obj) => obj.type === "TABLE")
                                            .reduce((sum, o) => o.minCover + sum, 0)}{" "}
                                        -{" "}
                                        {selectedSection?.objects
                                            ?.filter((obj) => obj.type === "TABLE")
                                            .reduce((sum, o) => o.maxCover + sum, 0)}
                                    </Typography>
                                </Grid>

                                <Grid item sx={{ mt: "8px" }}>
                                    <Typography
                                        variant='body1'
                                        align='center'
                                        sx={{ fontSize: "13px", color: "#4a4c50" }}
                                    >
                                        {t("display.floorPlanning.drawer.totalCapacity")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Tables*/}
                        <Grid item>
                            <Grid container direction='column' alignItems='center'>
                                <Grid item>
                                    <Typography
                                        variant='body1'
                                        align='center'
                                        sx={{ fontSize: "13px", color: "#4a4c50", fontWeight: 700 }}
                                    >
                                        {selectedSection?.objects?.filter((obj) => obj.type === "TABLE")?.length}
                                    </Typography>
                                </Grid>

                                <Grid item sx={{ mt: "8px" }}>
                                    <Typography
                                        variant='body1'
                                        align='center'
                                        sx={{ fontSize: "13px", color: "#4a4c50" }}
                                    >
                                        {t("display.floorPlanning.drawer.equipments")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Online Capacity*/}
                        <Grid item>
                            <Grid container direction='column' alignItems='center'>
                                <Grid item>
                                    <Typography
                                        variant='body1'
                                        align='center'
                                        sx={{ fontSize: "13px", color: "#4a4c50", fontWeight: 700 }}
                                    >
                                        {selectedSection?.objects
                                            ?.filter((x) => x.availableOnline)
                                            .reduce((sum, o) => o.minCover + sum, 0)}{" "}
                                        -{" "}
                                        {selectedSection?.objects
                                            ?.filter((x) => x.availableOnline)
                                            .reduce((sum, o) => o.maxCover + sum, 0)}
                                    </Typography>
                                </Grid>

                                <Grid item sx={{ mt: "8px" }}>
                                    <Typography
                                        variant='body1'
                                        align='center'
                                        sx={{ fontSize: "13px", color: "#4a4c50" }}
                                    >
                                        {t("display.floorPlanning.drawer.onlineCapacity")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Room name input */}
                    {/* {selectedSection !== null &&
                        <Grid container direction='column' sx={{ mt: "16px" }}>
                            <Typography
                                variant='body1'
                                sx={{ fontSize: "11px", color: "#4a4c50" }}
                                component='label'
                                htmlFor='name'
                            >
                                {t("display.floorPlanning.drawer.sectionName").toUpperCase()}
                            </Typography>

                            <TextField
                                variant='outlined'
                                fullWidth
                                size='small'
                                sx={{
                                    mt: "4px",
                                    ".MuiOutlinedInput-input": { fontSize: "12px" },
                                }}
                                value={selectedSection?.name[lang]}
                                onChange={(e) => {
                                    nameChangeHandler(e.target.value);
                                }}
                            />
                        </Grid>
                    } */}
                </Grid>

                {/* table options */}
                <Grid
                    item
                    sx={{
                        p: "1em",
                        pt: "20px",
                        pb: "36px",
                        borderTop: "1px solid #d8d8d8",
                    }}
                >
                    {/* text */}
                    <Typography variant='h5' align='center' sx={{ fontWeight: "700" }}>
                        {t("display.floorPlanning.drawer.tableOptions").toUpperCase()}
                    </Typography>

                    {/* description */}
                    <Grid container justifyContent='center' sx={{ pt: "12px" }}>
                        <Grid item sx={{ width: { md: "80%", xs: "100%" } }}>
                            <Typography
                                variant='body1'
                                align='center'
                                sx={{
                                    fontSize: "14px",
                                    color: "#808080",
                                }}
                            >
                                {t("display.floorPlanning.drawer.tableOptionsDesc")}
                            </Typography>
                        </Grid>
                    </Grid>

                    {/* shapes */}
                    <Grid
                        container
                        justifyContent='center'
                        alignItems='center'
                        sx={{ m: "28px 0", gap: "36px" }}
                    >
                        {tablesOptions?.map((item, i) => (
                            <Grid
                                item
                                key={item.shape}
                            // draggable='true'
                            // onDragStart={(e) => {
                            //   e.dataTransfer.setData("type", item.type);
                            //   e.dataTransfer.setData("shape", item.shape);
                            // }}
                            // onTouchMove={(e) => {
                            //   e.target.setAttribute("type", item.type);
                            //   e.target.setAttribute("shape", item.shape) ;
                            // }}
                            // onTouchEnd={(e) => {
                            //   const container = e.target;
                            //   console.log(container);
                            //   if (container.className === "droppable") {
                            //     console.log(container);
                            //   }
                            // }}

                            // style={{ width: "120px", height: "100px" }}
                            >

                            </Grid>
                        ))}
                    </Grid>
                </Grid>

                {/* shape options */}
                <Grid
                    item
                    sx={{
                        p: "1em",
                        pt: "20px",
                        pb: "36px",
                        borderTop: "1px solid #d8d8d8",
                    }}
                >
                    {/* text */}
                    <Typography variant='h5' align='center' sx={{ fontWeight: "700" }}>
                        {t("display.floorPlanning.drawer.shapeOptions").toUpperCase()}
                    </Typography>

                    {/* description */}
                    <Grid container justifyContent='center' sx={{ pt: "12px" }}>
                        <Grid item sx={{ width: { md: "80%", xs: "100%" } }}>
                            <Typography
                                variant='body1'
                                align='center'
                                sx={{
                                    fontSize: "14px",
                                    color: "#808080",
                                }}
                            >
                                {t("display.floorPlanning.drawer.shapeOptionsDesc")}
                            </Typography>
                        </Grid>
                    </Grid>

                    {/* shapes */}
                    <Grid
                        container
                        justifyContent='center'
                        alignItems='center'
                        sx={{ m: "28px 0", gap: "36px" }}
                    >
                        {shapeOptions?.map((item, i) => (
                            <Grid
                                item
                                key={item.shape}
                            // draggable='true'
                            // onDragStart={(e) => {
                            //   e.dataTransfer.setData("type", item.type);
                            //   e.dataTransfer.setData("shape", item.shape);
                            // }}
                            // style={{ width: "120px", height: "100px" }}
                            >

                            </Grid>
                        ))}
                    </Grid>
                </Grid>

                {/* table Details */}
                {selectedObjectObj && (
                    <Grid
                        item
                        sx={{
                            pb: "36px",
                            borderTop: "1px solid #d8d8d8",
                        }}
                    >
                        {/* text */}
                        <Typography
                            variant='h5'
                            align='center'
                            sx={{
                                fontSize: "16px",
                                fontWeight: "700",
                                pt: "20px",
                                pb: "8px",
                                color: "#4a4c50",
                                background: "#F4F4F6",
                            }}
                        >
                            {selectedObjectObj.type === "SHAPE" ? "Shape" : "Table"} Details
                        </Typography>

                        {/* name */}
                        <Grid
                            container
                            alignItems='center'
                            sx={{
                                p: "12px 16px",

                                borderTop: "1px solid #d8d8d8",
                            }}
                        >
                            <Grid item md={3} xs={6}>
                                <Typography
                                    variant='body1'
                                    sx={{
                                        fontSize: "14px",

                                        color: "#4a4c50",
                                    }}
                                >
                                    {selectedObjectObj.type === "SHAPE" ? "Shape" : "Table"} NAME
                                </Typography>
                            </Grid>
                            <Grid item flex={1}>
                                <TextField
                                    variant='outlined'
                                    size='small'
                                    fullWidth
                                    value={selectedObjectObj.name}
                                    inputProps={{
                                        max: 10,
                                    }}
                                    onChange={(e) => {
                                        if (e.target.value && e.target.value.length > 10) return;
                                        setSelectedSection((r) => {
                                            return {
                                                ...r,
                                                objects: r.objects.map((o) => {
                                                    if (o.id === selectedObject) o.name = e.target.value;
                                                    return o;
                                                }),
                                            };
                                        });
                                    }}
                                    sx={{
                                        ".MuiOutlinedInput-input": {
                                            pl: 0,
                                        },
                                        ".Mui-focused": {
                                            background: "#dff2e4",
                                        },
                                        ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                            border: 0,
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>

                        {/* minCover */}
                        {selectedObjectObj.type === "TABLE" && (
                            <Grid
                                container
                                alignItems='center'
                                justifyContent='space-between'
                                sx={{
                                    p: "12px 16px",
                                    gap: "15px",
                                    borderTop: "1px solid #d8d8d8",
                                }}
                            >
                                <Grid item sx={{ flex: 1 }}>
                                    <Grid container alignItems='center' sx={{ gap: "15px" }}>
                                        <Grid item md={3} xs={6}>
                                            <Typography
                                                variant='body1'
                                                sx={{
                                                    fontSize: "14px",

                                                    color: "#4a4c50",
                                                }}
                                            >
                                                {t("display.floorPlanning.drawer.minCovers")}
                                            </Typography>
                                        </Grid>
                                        <TextField
                                            variant='outlined'
                                            size='small'
                                            value={selectedObjectObj.minCover}
                                            onChange={(e) => {
                                                setSelectedSection((r) => {
                                                    return {
                                                        ...r,
                                                        objects: r.objects.map((o) => {
                                                            if (o.id === selectedObject) {
                                                                if (e.target.value === "") {
                                                                    o.minCover = "";
                                                                } else if (
                                                                    !isNaN(e.target.value) &&
                                                                    parseInt(e.target.value) &&
                                                                    parseInt(e.target.value) >= 1
                                                                )
                                                                    o.minCover = parseInt(e.target.value);
                                                            }
                                                            return o;
                                                        }),
                                                    };
                                                });
                                            }}
                                            sx={{
                                                width: "60px",
                                                ".Mui-focused": {
                                                    background: "#dff2e4",
                                                },
                                                ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                                {
                                                    border: 0,
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container alignItems='center' sx={{ gap: "10px" }}>
                                        <IconButton
                                            disabled={selectedObjectObj.minCover <= 1}
                                            onClick={() =>
                                                setSelectedSection((r) => {
                                                    return {
                                                        ...r,
                                                        objects: r.objects.map((o) => {
                                                            if (o.id === selectedObject) {
                                                                o.minCover -= 1;
                                                            }
                                                            return o;
                                                        }),
                                                    };
                                                })
                                            }
                                        >
                                            <RemoveCircleIcon
                                                sx={{
                                                    fill:
                                                        selectedObjectObj.minCover <= 1
                                                            ? "#e4e5e7"
                                                            : "rgb(18,136,73)",
                                                }}
                                            />
                                        </IconButton>
                                        <IconButton
                                            onClick={() =>
                                                setSelectedSection((r) => {
                                                    return {
                                                        ...r,
                                                        objects: r.objects.map((o) => {
                                                            if (o.id === selectedObject) {
                                                                o.minCover += 1;
                                                            }
                                                            return o;
                                                        }),
                                                    };
                                                })
                                            }
                                        >
                                            <AddCircleIcon sx={{ fill: "rgb(18,136,73)" }} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}

                        {/* maxCover */}
                        {selectedObjectObj.type === "TABLE" && (
                            <Grid
                                container
                                alignItems='center'
                                justifyContent='space-between'
                                sx={{
                                    p: "12px 16px",
                                    gap: "15px",
                                    borderTop: "1px solid #d8d8d8",
                                }}
                            >
                                <Grid item sx={{ flex: 1 }}>
                                    <Grid container alignItems='center' sx={{ gap: "15px" }}>
                                        <Grid item md={3} xs={6}>
                                            <Typography
                                                variant='body1'
                                                sx={{
                                                    fontSize: "14px",

                                                    color: "#4a4c50",
                                                }}
                                            >
                                                {t("display.floorPlanning.drawer.maxCovers")}
                                            </Typography>
                                        </Grid>
                                        <TextField
                                            variant='outlined'
                                            size='small'
                                            value={selectedObjectObj.maxCover}
                                            onChange={(e) => {
                                                setSelectedSection((r) => {
                                                    return {
                                                        ...r,
                                                        objects: r.objects.map((o) => {
                                                            if (o.id === selectedObject) {
                                                                if (e.target.value === "") {
                                                                    o.maxCover = "";
                                                                } else if (
                                                                    !isNaN(e.target.value) &&
                                                                    parseInt(e.target.value) &&
                                                                    parseInt(e.target.value) >= 1
                                                                )
                                                                    o.maxCover = parseInt(e.target.value);
                                                            }
                                                            return o;
                                                        }),
                                                    };
                                                });
                                            }}
                                            sx={{
                                                width: "60px",
                                                ".Mui-focused": {
                                                    background: "#dff2e4",
                                                },
                                                ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                                {
                                                    border: 0,
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container alignItems='center' sx={{ gap: "10px" }}>
                                        <IconButton
                                            disabled={selectedObjectObj.maxCover <= 1}
                                            onClick={() =>
                                                setSelectedSection((r) => {
                                                    return {
                                                        ...r,
                                                        objects: r.objects.map((o) => {
                                                            if (o.id === selectedObject) {
                                                                o.maxCover -= 1;
                                                            }
                                                            return o;
                                                        }),
                                                    };
                                                })
                                            }
                                        >
                                            <RemoveCircleIcon
                                                sx={{
                                                    fill:
                                                        selectedObjectObj.maxCover <= 1
                                                            ? "#e4e5e7"
                                                            : "rgb(18,136,73)",
                                                }}
                                            />
                                        </IconButton>
                                        <IconButton
                                            onClick={() =>
                                                setSelectedSection((r) => {
                                                    return {
                                                        ...r,
                                                        objects: r.objects.map((o) => {
                                                            if (o.id === selectedObject) {
                                                                o.maxCover += 1;
                                                            }
                                                            return o;
                                                        }),
                                                    };
                                                })
                                            }
                                        >
                                            <AddCircleIcon sx={{ fill: "rgb(18,136,73)" }} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}

                        {/* avialableOnline */}
                        {selectedObjectObj.type === "TABLE" && (
                            <Grid
                                container
                                alignItems='center'
                                justifyContent='space-between'
                                sx={{
                                    p: "12px 16px",
                                    gap: "15px",
                                    borderTop: "1px solid #d8d8d8",
                                }}
                            >
                                <Grid item flex={1}>
                                    <Grid
                                        container
                                        justifyContent='flex-start'
                                        wrap='nowrap'
                                        alignItems='center'
                                        sx={{ gap: "15px" }}
                                    >
                                        <Grid item md={3} xs={6}>
                                            <Typography
                                                variant='body1'
                                                sx={{
                                                    fontSize: "14px",

                                                    color: "#4a4c50",
                                                }}
                                            >
                                                ONLINE
                                            </Typography>
                                        </Grid>
                                        <Grid item flex={1}>
                                            <Typography
                                                sx={{ cursor: "pointer" }}
                                                onClick={() =>
                                                    setSelectedSection((r) => {
                                                        return {
                                                            ...r,
                                                            objects: r.objects.map((o) => {
                                                                if (o.id === selectedObject) {
                                                                    o.availableOnline = !o.availableOnline;
                                                                }
                                                                return o;
                                                            }),
                                                        };
                                                    })
                                                }
                                            >
                                                {selectedObjectObj.availableOnline
                                                    ? "Available"
                                                    : "Not Available"}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Checkbox
                                        style={{ color: "rgb(18,136,73)" }}
                                        checked={selectedObjectObj.availableOnline}
                                        onChange={(e) =>
                                            setSelectedSection((r) => {
                                                return {
                                                    ...r,
                                                    objects: r.objects.map((o) => {
                                                        if (o.id === selectedObject) {
                                                            o.availableOnline = e.target.checked;
                                                        }
                                                        return o;
                                                    }),
                                                };
                                            })
                                        }
                                    />
                                </Grid>
                            </Grid>
                        )}

                        {/* borderColor */}
                        <Grid
                            container
                            alignItems='center'
                            justifyContent='space-between'
                            sx={{
                                p: "12px 16px",
                                gap: "15px",
                                borderTop: "1px solid #d8d8d8",
                            }}
                        >
                            <Grid item flex={1}>
                                <Typography
                                    variant='body1'
                                    sx={{
                                        fontSize: "14px",

                                        color: "#4a4c50",
                                    }}
                                >
                                    Border Color
                                </Typography>
                            </Grid>
                            <Grid item>
                                <MuiColorInput
                                    format="hex8"
                                    value={selectedObjectObj.borderColor}
                                    onChange={(color) =>
                                        setSelectedSection((r) => {
                                            return {
                                                ...r,
                                                objects: r.objects.map((o) => {
                                                    if (o.id === selectedObject) {
                                                        o.borderColor = color;
                                                    }
                                                    return o;
                                                }),
                                            };
                                        })
                                    }
                                    style={{
                                        margin: "9px",
                                    }}
                                />
                            </Grid>
                        </Grid>

                        {/* fill */}
                        {selectedObjectObj.type === "SHAPE" && (
                            <Grid
                                container
                                alignItems='center'
                                justifyContent='space-between'
                                sx={{
                                    p: "12px 16px",
                                    gap: "15px",
                                    borderTop: "1px solid #d8d8d8",
                                }}
                            >
                                <Grid item flex={1}>
                                    <Typography
                                        variant='body1'
                                        sx={{
                                            fontSize: "14px",

                                            color: "#4a4c50",
                                        }}
                                    >
                                        Color
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <MuiColorInput
                                        format="hex8"
                                        value={selectedObjectObj.fill}
                                        onChange={(color) =>
                                            setSelectedSection((r) => {
                                                return {
                                                    ...r,
                                                    objects: r.objects.map((o) => {
                                                        if (o.id === selectedObject) {
                                                            o.fill = color;
                                                        }
                                                        return o;
                                                    }),
                                                };
                                            })
                                        }
                                        style={{
                                            margin: "9px",
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        )}

                        {/* delete */}
                        <Grid
                            container
                            alignItems='center'
                            justifyContent='space-between'
                            sx={{
                                p: "12px 16px",
                                pb: 0,
                                gap: "15px",
                                borderTop: "1px solid #d8d8d8",
                            }}
                        >
                            <IconButton
                                onClick={() =>
                                    setDeleteDialog({
                                        active: true,
                                        object: selectedObjectObj,
                                    })
                                }
                                sx={{ border: "1px solid red", borderRadius: "4px" }}
                            >
                                <DeleteIcon style={{ fill: "red" }} />
                            </IconButton>
                        </Grid>
                    </Grid>
                )}

                {/* BACKGROUND IMAGE */}
                {!selectedObject && (
                    <Grid
                        item
                        sx={{
                            p: "1em",
                            pt: "20px",
                            pb: "36px",
                            borderTop: "1px solid #d8d8d8",
                        }}
                    >
                        {/* text */}
                        <Typography variant='h5' align='center' sx={{ fontWeight: "700" }}>
                            BACKGROUND IMAGE
                        </Typography>
                        {/* description */}
                        <Grid container justifyContent='center' sx={{ pt: "12px" }}>
                            <Grid item sx={{ width: { md: "80%", xs: "100%" } }}>
                                <Typography
                                    variant='body1'
                                    align='center'
                                    sx={{
                                        fontSize: "14px",
                                        color: "#808080",
                                    }}
                                >
                                    Recommended image size is 1200x800, otherwise background could
                                    get cut off
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* upload */}
                        {selectedSection?.background ? (
                            <Grid
                                container
                                justifyContent='space-between'
                                alignItems='center'
                                spacing={1}
                                sx={{ m: "24px 0" }}
                            >
                                <Grid item>
                                    <Grid container alignItems='center' gap={"10px"}>
                                        <Grid item>
                                            <img
                                                src={
                                                    typeof selectedSection.background === "object"
                                                        ? `${URL.createObjectURL(selectedSection.background)}`
                                                        : `${selectedSection.background}`
                                                }
                                                style={{
                                                    width: "56px",
                                                    height: "56px",
                                                    padding: "1px",
                                                    border: "3px solid rgba(223,239,248)",
                                                    borderRadius: "4px",
                                                }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                variant='small'
                                                sx={{
                                                    color: "#4a4c50",
                                                    fontSize: "12px",
                                                    display: "block",
                                                }}
                                            >
                                                {typeof selectedSection.background === "object"
                                                    ? selectedSection.background.name
                                                    : selectedSection.background}
                                            </Typography>
                                            <Typography
                                                variant='small'
                                                sx={{
                                                    color: "#D8D8D8",
                                                    fontSize: "12px",
                                                    display: "block",
                                                }}
                                            >
                                                {formatBytes(selectedSection.backgroundImageSize)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <IconButton
                                        sx={{ p: 0 }}
                                        onClick={() => {
                                            setSelectedSection((r) => {
                                                return {
                                                    ...r,
                                                    background: "",
                                                    backgroundImageSize: "",
                                                };
                                            });
                                        }}
                                    >
                                        <DeleteIcon sx={{ fill: "red" }} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid container sx={{ m: "24px 0" }}>
                                <input
                                    accept='image/*'
                                    style={{ display: "none" }}
                                    id='background'
                                    type='file'
                                    onChange={fileChangeHandler}
                                />
                                <label htmlFor='background' style={{ width: "100%" }}>
                                    <Button
                                        component='span'
                                        variant='contained'
                                        fullWidth
                                        sx={{
                                            fontSize: "14px",
                                            background: "#54b1d2",
                                            color: "#fff",
                                            borderRadius: "0.35rem",
                                            fontWeight: 600,
                                            p: "10px 16px",
                                        }}
                                    >
                                        {t('display.floorPlanning.drawer.uploadImage')}
                                    </Button>
                                </label>
                            </Grid>
                        )}
                    </Grid>
                )}
            </Grid>
        </Drawer>
    );
}

export default FloorPlannerDrawer;