
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getThemeColor } from 'constants/theme';
import { getTheme } from "utils/theme";

/**
 * The Custom control Button, that display an edit button for table actions section.
 * @property {string} label the button text value
 * @property {boolean} shownLabel if the label should be shown
 * @property {string} color the control button color
 * @property {function} onClick a button on click action
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomControlButton({ label, shownLabel = true, isEnabled = true, color = isEnabled ? "trueCcontrolButtonColor" : "falseControlButtonColor", onClick }) {

    function getMuiTheme() {
        return createTheme({
            components: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            backgroundColor: "transparent",
                            color: getThemeColor()[getTheme()][color],
                            margin: "0px",
                            boxShadow: "none",
                            transition: "none",
                            ":hover": {
                                backgroundColor: "transparent",
                                color: getThemeColor()[getTheme()][color],
                                margin: "0px",
                                boxShadow: "none",
                                transition: "none"
                            },
                        },
                        startIcon: {
                            margin: "0px",
                            padding: "0px",
                            "*:nth-of-type(1)": {
                                fontSize: "40px"
                            }
                        }
                    }
                }
            }
        })
    }

    return (
        <ThemeProvider theme={getMuiTheme()}>
            <Button variant="contained" startIcon={isEnabled ? <ToggleOnIcon /> : <ToggleOffIcon />} onClick={onClick}>{shownLabel ? label : ""}</Button>
        </ThemeProvider>
    );
}

export default CustomControlButton;