/**
 * Updates an object field with a boolean value if the value is strictly true or false.
 * If the value is null or undefined, the field will not be updated.
 *
 * @param {object} data - The object to update.
 * @param {string} fieldName - The field name to update.
 * @param {boolean|null|undefined} value - The value to assign to the field. Must be strictly true or false.
 * @returns {object} - The updated data object.
 *
 * @example
 * const obj = { isActive: false };
 * updateFieldIfBooleanValid(obj, "isActive", true); // { isActive: true }
 * updateFieldIfBooleanValid(obj, "isActive", null); // { isActive: false }
 * 
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
const updateFieldIfBooleanValid = (data, fieldName, value) => {
    // Check if the value is strictly a boolean and not null or undefined
    if (typeof value === "boolean") {
      data[fieldName] = value; // Update the field if the value is a valid boolean
    }
  
    return data; // Return the updated object
  };
  
  export default updateFieldIfBooleanValid;
  