
import {
    Ellipse,
    Rect
} from "react-konva";

export const tablesOptions = [
    {
        type: "TABLE",
        shape: "square",
        renderImage: (props) => (
            <div
                draggable='true'
                {...props}
                style={{ ...props.style, borderRadius: "4px" }}
            >
                <svg style={{ width: "22px", height: "22px" }}>
                    <path
                        fill='rgb(9,119,62)'
                        d='M11 20.167a8.982 8.982 0 01-3.598-.722 9.256 9.256 0 01-2.91-1.96 9.067 9.067 0 01-1.948-2.921 9.192 9.192 0 01-.71-3.61c0-1.283.236-2.479.71-3.586a9.134 9.134 0 011.948-2.9 8.946 8.946 0 012.91-1.936A9.238 9.238 0 0111 1.833c1.283 0 2.483.233 3.598.7a8.947 8.947 0 012.91 1.936 9.133 9.133 0 011.948 2.899c.474 1.107.71 2.303.71 3.586a9.192 9.192 0 01-.71 3.61 9.067 9.067 0 01-1.948 2.921 9.256 9.256 0 01-2.91 1.96 8.982 8.982 0 01-3.598.722zm3.62-12.604h3.438a7.425 7.425 0 00-2.074-2.636 7.902 7.902 0 00-3.036-1.49c.382.566.706 1.177.974 1.834.267.657.5 1.42.699 2.292zm-5.82 0h4.446a8.937 8.937 0 00-.848-2.35A9.375 9.375 0 0011 3.209c-.489.413-.902.955-1.238 1.627-.336.673-.657 1.582-.962 2.728zM3.483 13.04h3.644a12.88 12.88 0 01-.08-1.112 41.896 41.896 0 01-.012-.974c0-.382.008-.722.023-1.02.016-.297.046-.63.092-.996H3.483c-.107.366-.18.695-.217.985-.039.29-.058.634-.058 1.031 0 .397.02.753.058 1.066.038.313.11.653.217 1.02zm5.569 5.523c-.382-.581-.71-1.207-.985-1.88a13.727 13.727 0 01-.688-2.268H3.942c.58 1.084 1.252 1.936 2.016 2.555.764.619 1.795 1.15 3.094 1.593zm-5.11-11h3.46a12.9 12.9 0 01.642-2.212c.26-.65.588-1.28.985-1.89-1.146.29-2.146.779-3.002 1.466-.856.688-1.55 1.566-2.085 2.636zM11 18.837c.535-.55.982-1.18 1.34-1.89.36-.71.654-1.555.883-2.532H8.8c.214.916.5 1.741.86 2.475A7.807 7.807 0 0011 18.837zM8.548 13.04h4.927c.061-.474.1-.86.114-1.158a18.111 18.111 0 000-1.81c-.015-.283-.053-.66-.114-1.134H8.548c-.061.473-.1.851-.115 1.134a18.134 18.134 0 000 1.81c.016.298.054.684.115 1.158zm4.423 5.5a9.175 9.175 0 002.967-1.582 8.21 8.21 0 002.12-2.543h-3.414a16.598 16.598 0 01-.7 2.245 10.406 10.406 0 01-.973 1.88zm1.879-5.5h3.667c.107-.367.18-.707.217-1.02.038-.313.058-.669.058-1.066s-.02-.74-.058-1.031a6.689 6.689 0 00-.217-.985h-3.644c.046.534.076.943.091 1.226.016.282.023.546.023.79 0 .336-.011.653-.034.951-.023.298-.057.676-.103 1.135z'
                    ></path>
                </svg>
                <div
                    style={{
                        width: "16px",
                        height: "22px",
                        background: "rgb(52,53,58)",
                        borderRadius: "8px",
                        position: "absolute",
                        bottom: "-15px",
                        left: "calc(50% - 8px)",
                    }}
                ></div>
            </div>
        ),
        //renderImage: (props) => <img src='/dev/square.png' alt='' {...props} />,
        renderComponent: (props) => <Rect cornerRadius={3} {...props} />,
    },
    {
        type: "TABLE",
        shape: "circle",
        renderImage: (props) => (
            <div
                draggable='true'
                {...props}
                style={{ ...props.style, borderRadius: "50%" }}
            >
                <svg style={{ width: "22px", height: "22px" }}>
                    <path
                        fill='rgb(9,119,62)'
                        d='M11 20.167a8.982 8.982 0 01-3.598-.722 9.256 9.256 0 01-2.91-1.96 9.067 9.067 0 01-1.948-2.921 9.192 9.192 0 01-.71-3.61c0-1.283.236-2.479.71-3.586a9.134 9.134 0 011.948-2.9 8.946 8.946 0 012.91-1.936A9.238 9.238 0 0111 1.833c1.283 0 2.483.233 3.598.7a8.947 8.947 0 012.91 1.936 9.133 9.133 0 011.948 2.899c.474 1.107.71 2.303.71 3.586a9.192 9.192 0 01-.71 3.61 9.067 9.067 0 01-1.948 2.921 9.256 9.256 0 01-2.91 1.96 8.982 8.982 0 01-3.598.722zm3.62-12.604h3.438a7.425 7.425 0 00-2.074-2.636 7.902 7.902 0 00-3.036-1.49c.382.566.706 1.177.974 1.834.267.657.5 1.42.699 2.292zm-5.82 0h4.446a8.937 8.937 0 00-.848-2.35A9.375 9.375 0 0011 3.209c-.489.413-.902.955-1.238 1.627-.336.673-.657 1.582-.962 2.728zM3.483 13.04h3.644a12.88 12.88 0 01-.08-1.112 41.896 41.896 0 01-.012-.974c0-.382.008-.722.023-1.02.016-.297.046-.63.092-.996H3.483c-.107.366-.18.695-.217.985-.039.29-.058.634-.058 1.031 0 .397.02.753.058 1.066.038.313.11.653.217 1.02zm5.569 5.523c-.382-.581-.71-1.207-.985-1.88a13.727 13.727 0 01-.688-2.268H3.942c.58 1.084 1.252 1.936 2.016 2.555.764.619 1.795 1.15 3.094 1.593zm-5.11-11h3.46a12.9 12.9 0 01.642-2.212c.26-.65.588-1.28.985-1.89-1.146.29-2.146.779-3.002 1.466-.856.688-1.55 1.566-2.085 2.636zM11 18.837c.535-.55.982-1.18 1.34-1.89.36-.71.654-1.555.883-2.532H8.8c.214.916.5 1.741.86 2.475A7.807 7.807 0 0011 18.837zM8.548 13.04h4.927c.061-.474.1-.86.114-1.158a18.111 18.111 0 000-1.81c-.015-.283-.053-.66-.114-1.134H8.548c-.061.473-.1.851-.115 1.134a18.134 18.134 0 000 1.81c.016.298.054.684.115 1.158zm4.423 5.5a9.175 9.175 0 002.967-1.582 8.21 8.21 0 002.12-2.543h-3.414a16.598 16.598 0 01-.7 2.245 10.406 10.406 0 01-.973 1.88zm1.879-5.5h3.667c.107-.367.18-.707.217-1.02.038-.313.058-.669.058-1.066s-.02-.74-.058-1.031a6.689 6.689 0 00-.217-.985h-3.644c.046.534.076.943.091 1.226.016.282.023.546.023.79 0 .336-.011.653-.034.951-.023.298-.057.676-.103 1.135z'
                    ></path>
                </svg>
                <div
                    style={{
                        width: "16px",
                        height: "22px",
                        background: "rgb(52,53,58)",
                        borderRadius: "8px",
                        position: "absolute",
                        bottom: "-15px",
                        left: "calc(50% - 8px)",
                    }}
                ></div>
            </div>
        ),
        //renderImage: (props) => <img src='/dev/circle.png' alt='' {...props} />,
        renderComponent: (props) => <Ellipse {...props} />,
    },
];

export const shapeOptions = [
    {
        type: "SHAPE",
        shape: "shape-rectangle",
        renderImage: (props) => (
            <div
                draggable='true'
                {...props}
                style={{ ...props.style, borderRadius: "4px" }}
            >
                <div
                    style={{
                        width: "16px",
                        height: "22px",
                        background: "rgb(52,53,58)",
                        borderRadius: "8px",
                    }}
                ></div>
            </div>
        ),
        // renderImage: (props) => (
        //   <img src='/dev/shape-rectangle.png' alt='' {...props} />
        // ),
        renderComponent: (props) => <Rect cornerRadius={3} {...props} />,
    },
    {
        type: "SHAPE",
        shape: "shape-circle",
        renderImage: (props) => (
            <div
                draggable='true'
                {...props}
                style={{ ...props.style, borderRadius: "50%" }}
            >
                <div
                    style={{
                        width: "16px",
                        height: "22px",
                        background: "rgb(52,53,58)",
                        borderRadius: "8px",
                    }}
                ></div>
            </div>
        ),
        // renderImage: (props) => (
        //   <img src='/dev/shape-circle.png' alt='' {...props} />
        // ),
        renderComponent: (props) => <Ellipse {...props} />,
    },
];
