import { Add as AddIcon, Save as SaveIcon } from '@mui/icons-material';
import { Box, Fab, Grid } from "@mui/material";
import pickupDisplayApi from "api/pickupDisplay";
import CustomBreadcrumbMultiParent from 'components/Breadcrumb/CustomBreadcrumbMultiParent';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomGrid from 'components/Grid/CustomGrid';
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomSnackbar from "components/Snackbar/CustomSnackbar";
import CustomTextField from 'components/Text/CustomTextField';
import CustomTitle from 'components/Title/CustomTitle';
import { getDefaultPickupOrderDisplaySettings } from 'constants/defaultObjects';
import { MuiColorInput } from 'mui-color-input';
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { fabStyle, formSaveButton, mainArea, mainTag, mainTagBreadcrumb, mainTagSearchItemOrderBox, mainTagSearchItemOrderBoxContainer, mainTagSearchItemOrderBoxLegend, mainTagTitle } from 'themes/defaultThemes';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';
import { getLanguageFromURL } from 'utils/language';
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The PickupOrderDisplaySettingsComponent that allow the ADMIN to configure
 * pickup display settings.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function PickupOrderDisplaySettingsComponent() {

    const { t } = useTranslation();
    const { organizationID } = useParams();
    const { outletID } = useParams();

    // if true the menu item is clicked and a redirect to page should perform
    const [isLoaded, setIsLoaded] = useState(false);

    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313"
    });
    /**
     * @type {object}
     * @property {*} icon the legend fa icon
     * @property {object} userAuth the authenticated user infromation
     * @property {object} organization the organization basic details (id and name)
     * @property {object} outlet tha outlet basic details (id and name)
     * @property {object} isError list of indication of empty form required field 
     *                           after submit to present error message to the
     *                           fields/attributes with true value
     */
    const [data, setData] = useState({
        icon: AddIcon,
        userAuth: null,
        isAdd: outletID === undefined,
        organization: {
            id: organizationID,
            name: ""
        },
        outlet: {
            id: outletID,
            name: ""
        },
        displaySettings: getDefaultPickupOrderDisplaySettings()
    });

    const [search] = useState({
        organizationID: "635e94f54960603c9bd07641",//organizationID,
        outletID: "63b31564c78b8371cf2d61ab",//outletID !== undefined ? outletID : undefined,
    });

    useEffect(() => {
        getPickupOrderDisplaySettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //change document title
    useDocumentTitle(`Usee ${t('display.pickup.label')} | ${t('display.pickup.settings.label')}`);

    /**
     * The rest endpoint to get the pickup display settings.
     */
    function getPickupOrderDisplaySettings() {
        // organizationID, outletID
        pickupDisplayApi.fetchOne(search).then((r) => {
            setData({
                ...data,
                userAuth: r.data.returnobject.userAuth,
                organization: r.data.returnobject.organization,
                outlet: r.data.returnobject.outlet,
                displaySettings: r.data.returnobject.displaySettings,
            });
            setIsLoaded(true);
        }).catch((e) => {
            //     // console.log(e);
        });
    }

    /**
     * Function that triggers the modification of the loyalty settings
     */
    function editPickupOrderDisplaySettings() {
        pickupDisplayApi.update(data.displaySettings).then((r) => {
            setAlertBox({
                isOpen: true,
                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
        }).catch((e) => {
            // console.log(e)
        });
    }

    /**
     * Function that handles the snackbar open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }

    // ===============================================================================RENDER

    if (!isLoaded) {
        return (
            <CustomGrid role="main" id={"pickup-display-settings"} sx={mainTag()}>
                <LoadingSkeleton lines={9} />;
            </CustomGrid>
        );
    } else {
        return (
            // PickupOrder Display Settings
            <CustomGrid role="main" id={"pickup-display-settings"} sx={mainTag()}>
                <CustomGrid sx={mainTagBreadcrumb()}>
                    <CustomBreadcrumbMultiParent
                        parents={[
                            { name: t('display.pickup.label'), url: `/${getLanguageFromURL()}/display/pickup` },
                        ]}
                        instanceName={t('display.pickup.settings.label')}
                    />
                </CustomGrid>

                <CustomGrid sx={mainTagTitle()}>
                    <CustomTitle
                        title={`${t('display.pickup.label')} ${t('display.pickup.settings.label')}`}
                        subtitle={""}
                    />
                </CustomGrid>

                <CustomGrid sx={mainArea()} container={false}>
                    <form id="display-settings" onSubmit={(e) => { return false; }}>
                        {data.isAdd &&
                            <input type="hidden" />
                        }

                        {organizationID !== undefined &&
                            <input defaultValue={organizationID} name="organizationID" type="hidden" id="organizationID" />
                        }

                        <Grid container spacing={2}>
                            {/* preparing */}
                            <Grid item xs={12} md={6} xl={4}>
                                <Box component="fieldset" sx={mainTagSearchItemOrderBox()}>
                                    <legend style={mainTagSearchItemOrderBoxLegend()}>{t('display.pickup.settings.preparingOrder')}</legend>
                                    <Grid container spacing={2} sx={mainTagSearchItemOrderBoxContainer()}>
                                        <Grid item xs={12} xl={4} lg={4} md={6} sm={6}>
                                            <MuiColorInput
                                                label={t('display.pickup.settings.backgroundColor')}
                                                name="data.displaySettings.preparingOrderBackgroundColor"
                                                format="hex8"
                                                value={data.displaySettings.preparingOrderBackgroundColor}
                                                onChange={(value) => formValChangeWithParentElementWithNameAndValue("displaySettings.preparingOrderBackgroundColor", value, data, setData)}
                                            />
                                        </Grid>

                                        <Grid item xs={12} xl={4} lg={4} md={6} sm={6}>
                                            <MuiColorInput
                                                label={t('display.pickup.settings.fontColor')}
                                                name="data.displaySettings.preparingOrderFontColor"
                                                format="hex8"
                                                value={data.displaySettings.preparingOrderFontColor}
                                                onChange={(value) => formValChangeWithParentElementWithNameAndValue("displaySettings.preparingOrderFontColor", value, data, setData)}
                                            />
                                        </Grid>

                                        <Grid item xs={12} xl={4} lg={4} md={6} sm={6}>
                                            <MuiColorInput
                                                label={t('display.pickup.settings.headerFontColor')}
                                                name="data.displaySettings.preparingOrderHeaderFontColor"
                                                format="hex8"
                                                value={data.displaySettings.preparingOrderHeaderFontColor}
                                                onChange={(value) => formValChangeWithParentElementWithNameAndValue("displaySettings.preparingOrderHeaderFontColor", value, data, setData)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            {/* ready */}
                            <Grid item xs={12} md={6} xl={4}>
                                <Box component="fieldset" sx={mainTagSearchItemOrderBox()}>
                                    <legend style={mainTagSearchItemOrderBoxLegend()}>{t('display.pickup.settings.readyOrder')}</legend>
                                    <Grid container spacing={2} sx={mainTagSearchItemOrderBoxContainer()}>
                                        <Grid item xs={12} xl={4} lg={4} md={6} sm={6}>
                                            <MuiColorInput
                                                label={t('display.pickup.settings.backgroundColor')}
                                                name="data.displaySettings.readyOrderBackgroundColor"
                                                format="hex8"
                                                value={data.displaySettings.readyOrderBackgroundColor}
                                                onChange={(value) => formValChangeWithParentElementWithNameAndValue("displaySettings.readyOrderBackgroundColor", value, data, setData)}
                                            />
                                        </Grid>

                                        <Grid item xs={12} xl={4} lg={4} md={6} sm={6}>
                                            <MuiColorInput
                                                label={t('display.pickup.settings.fontColor')}
                                                name="data.displaySettings.readyOrderFontColor"
                                                format="hex8"
                                                value={data.displaySettings.readyOrderFontColor}
                                                onChange={(value) => formValChangeWithParentElementWithNameAndValue("displaySettings.readyOrderFontColor", value, data, setData)}
                                            />
                                        </Grid>

                                        <Grid item xs={12} xl={4} lg={4} md={6} sm={6}>
                                            <MuiColorInput
                                                label={t('display.pickup.settings.headerFontColor')}
                                                name="data.displaySettings.readyOrderHeaderFontColor"
                                                format="hex8"
                                                value={data.displaySettings.readyOrderHeaderFontColor}
                                                onChange={(value) => formValChangeWithParentElementWithNameAndValue("displaySettings.readyOrderHeaderFontColor", value, data, setData)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} xl={4}>
                                <Box component="fieldset" sx={mainTagSearchItemOrderBox()}>
                                    <legend style={mainTagSearchItemOrderBoxLegend()}>{t('display.pickup.settings.font')}</legend>
                                    <Grid container spacing={2} sx={mainTagSearchItemOrderBoxContainer()}>
                                        <Grid item xs={6} md={6}>
                                            <CustomTextField
                                                type="number" step="1" min={25}
                                                label={t('display.pickup.settings.fontSize')}
                                                name="displaySettings.fontSize"
                                                defaultValue={data.displaySettings.fontSize}
                                                inputAdornmentAfterLabel={t('display.pickup.settings.px')}
                                                onChange={(event) => formValChangeWithParentElementWithNameAndValue("displaySettings.fontSize", event.target.value, data, setData)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <CustomTextField
                                                type="number" step="1" min={25}
                                                label={t('display.pickup.settings.headerFontSize')}
                                                name="displaySettings.headerFontSize"
                                                inputAdornmentAfterLabel={t('display.pickup.settings.px')}
                                                defaultValue={data.displaySettings.headerFontSize}
                                                onChange={(event) => formValChangeWithParentElementWithNameAndValue("displaySettings.headerFontSize", event.target.value, data, setData)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>

                        <CustomIconButton variant="outlined" keyValue="save" onClick={editPickupOrderDisplaySettings} label={t('actions.save')} sx={formSaveButton()} />
                    </form>
                    <Fab color="primary"
                        aria-label="save"
                        sx={fabStyle()}
                        onClick={editPickupOrderDisplaySettings}
                    >
                        <SaveIcon />
                    </Fab>
                </CustomGrid>


                {alertBox.isOpen &&
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={3000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        handleSnackbarOpen={handleSnackbarState} />
                }
            </CustomGrid>
        );
    }
}

export default PickupOrderDisplaySettingsComponent;