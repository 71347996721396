import { Box, CardMedia, Grid, Grow, Typography } from "@mui/material";
import { getDefaultSplashScreenConfiguration } from "constants/defaultObjects";
import { splashScreenContainer, splashScreenContainerImage, splashScreenContainerTextContainer, splashScreenContainerTextContainerText } from "themes/defaultThemes";

/**
 * The Splash Screen.
 *
 * @version 1.0.0
 * @author [Gina Chatzimarkaki]
 */
function Splashscreen({ logo, splashScreen = getDefaultSplashScreenConfiguration(), logoPath = "" }) {

    return (
        <Grid container sx={{ ...splashScreenContainer(splashScreen.durationInSeconds), backgroundColor: splashScreen.backgroundColor }} justify="center" alignItems="center">
            <Grid item sx={{ width: "100%" }}>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Grow >
                        <>
                            {(splashScreen.showLogo && logo !== '') &&
                                <CardMedia
                                    component="img"
                                    src={`${logoPath}${logo}`}
                                    alt="splash"
                                    sx={splashScreenContainerImage()}
                                />
                            }
                            <div style={splashScreenContainerTextContainer()}>
                                {splashScreen.showDescription && <Typography variant="h1" sx={{ ...splashScreenContainerTextContainerText(), color: `${splashScreen.descriptionColor}!important` }}>{splashScreen.description} </Typography>}
                            </div>
                        </>
                    </Grow>
                </Box>
            </Grid>
        </Grid>
    );
}

export default Splashscreen;