import { getWeekDays } from 'constants/weekdays';
import { parseISO } from 'date-fns';

function parseISOWithNullCheck(dateTimeString) {
    return dateTimeString ? parseISO(dateTimeString) : null;
}

// Updated to handle timezone and format dates accordingly
export function formatISOWithTimezone(date, isAnalytics = false) {
    if (!date) return isAnalytics ? date : null;
    if (typeof (date) === "string" && date.includes('Z')) return date;//already formatted

    try {
        return date.toISOString();
    } catch (error) {
        // console.error('Error converting timezone:', error);
        return null; // Fallback in case of an error
    }
};

export function transformOutletSettingsHoursFormatFromISO(outlet) {
    // Check if outlet and outlet.settings.hours exist
    if (!outlet?.settings?.hours?.hours) {
        return outlet;
    }

    const daysOfWeek = getWeekDays();

    // Clone and transform hours for each day
    const transformedHours = daysOfWeek.reduce((acc, day) => {
        // Map through each time slot for the day, transforming start and end times
        acc[day] = outlet.settings.hours.hours[day].map(slot => ({
            ...slot,
            start: parseISOWithNullCheck(slot.start),
            end: parseISOWithNullCheck(slot.end),
        }));

        return acc;
    }, {});

    return {
        ...outlet,
        settings: {
            ...outlet.settings,
            hours: {
                ...outlet.settings.hours,
                hours: {
                    ...outlet.settings.hours.hours,
                    ...transformedHours,
                }
            }
        },
    };
};

export function transformOutletSettingsHoursFormatToISO(outlet) {
    // Check if outlet and outlet.settings.hours exist
    if (!outlet?.settings?.hours?.hours) {
        return outlet;
    }

    const daysOfWeek = getWeekDays();

    // Clone and transform hours for each day
    const transformedHours = daysOfWeek.reduce((acc, day) => {
        // Map through each time slot for the day, transforming start and end times
        acc[day] = outlet.settings.hours.hours[day].map(slot => ({
            ...slot,
            start: slot.start !== null ? formatISOWithTimezone(slot.start) : null,
            end: slot.end !== null ? formatISOWithTimezone(slot.end) : null,
        }));

        return acc;
    }, {});

    return {
        ...outlet,
        settings: {
            ...outlet.settings,
            hours: {
                ...outlet.settings.hours,
                hours: {
                    ...outlet.settings.hours.hours,
                    ...transformedHours,
                }
            }
        },
    };
}

export function getFirstDayOfLastMonth() {
    let date = new Date();
    let firstDayLastMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    if (date.getMonth() === 0) { // If current month is January
        firstDayLastMonth = new Date(date.getFullYear() - 1, 11, 1); // Set to December of the previous year
    }
    return firstDayLastMonth;
}

export function getLastDayOfLastMonth() {
    let date = new Date();
    return new Date(date.getFullYear(), date.getMonth(), 0); // Set day to 0 to get the last day of the previous month
}

export function getAnalyticsDateFormatter(dateFormat = "day") {
    if (dateFormat === "year")
        return "yyyy";
    else if (dateFormat === "month")
        return "MM/yyyy";
    else if (dateFormat === "day")
        return "DD/MM/yyyy";
    else if (dateFormat === "hour")
        return "DD/MM/yyyy, HH";
    // else if (dateFormat === "min")
    //     return "DD/MM/yyyy, HH:mm";
    // else if (dateFormat === "sec")
    //     return "DD/MM/yyyy, HH:mm:ss";
    else
        return "DD/MM/yyyy, HH:mm";
}