import { request } from "constants/alias";
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty, parsePageable } from "utils/functions";
import updateFieldIfBooleanValid from "utils/functions/updateFieldIfBooleanValid";
import updateFieldIfValidList from "utils/functions/updateFieldIfNonEmptyArray";
import updateFieldIfValid from "utils/functions/updateFieldIfValid";

/**
 * The rest request which attempts to get the orders list from the database.
 * @param {*} search The filters for search.
 *
 * @returns an object with an attribute containing the orders list
 */
export async function fetchAllKitchen(search) {
    let data = {};
    updateFieldIfValid(data, "organizationID", search.organizationID);
    updateFieldIfValid(data, "outletID", search.outletID);
    updateFieldIfValid(data, "orderNumber", search.orderNumber);
    updateFieldIfValid(data, "equipmentID", search.equipmentID);
    updateFieldIfValid(data, "dateFrom", search.dateFrom);
    updateFieldIfValid(data, "dateTo", search.dateTo);
    updateFieldIfBooleanValid(data, "isPaid", search.isPaid);
    updateFieldIfValid(data, "customerName", search.customerName);
    updateFieldIfValid(data, "customerPhoneNumber", search.customerPhoneNumber);
    updateFieldIfValidList(data, "statuses", search.statuses);
    updateFieldIfValidList(data, "paymentMethods", search.paymentMethods);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}order`, data));
    else return await request.get(includeParamsToUrl(`${API}order`));
}

/**
 * The rest request which attempts to get the orders list from the database.
 * @param {*} search The filters for search.
 *
 * @returns an object with an attribute containing the orders list
 */
export async function fetchAll(pageable, search) {
    let data = {};
    updateFieldIfValid(data, "organizationID", search.organizationID);
    // updateFieldIfValidList(data, "outletIDs", search.outletIDs);
    // if (search.outletIDs !== undefined && search.outletIDs !== null && search.outletIDs.length > 0) {
    //   data = { ...data, outletIds: search.outletIDs }
    // }
    updateFieldIfValid(data, "orderNumber", search.orderNumber);
    updateFieldIfValid(data, "equipmentID", search.equipmentID);
    updateFieldIfValid(data, "employeeID", search.employeeID);
    updateFieldIfValidList(data, "statuses", search.statuses);
    updateFieldIfValidList(data, "paymentMethods", search.paymentMethods);
    updateFieldIfBooleanValid(data, "isPaid", search.isPaid);
    updateFieldIfValid(data, "customerName", search.customerName);
    updateFieldIfValid(data, "customerPhoneNumber", search.customerPhoneNumber);
    updateFieldIfValid(data, "dateFrom", search.dateFrom);
    updateFieldIfValid(data, "dateTo", search.dateTo);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}order/list`, pageable !== undefined ? { ...parsePageable(pageable), ...data } : data));
    else return await request.get(includeParamsToUrl(`${API}order/list`, parsePageable(pageable) !== undefined ? pageable : {}));
}


/**
 * The rest request which attempts to get the orders list to be payed on
 * the requested equipment from the database.
 * @param string equipmentID The id of the equipment to fetch unpaid orders
 *
 * @returns an object with an attribute containing the orders list
 */
export async function fetchAllOrdersEquipmentToPay(equipmentID, dateFrom) {
    return await request.get(includeParamsToUrl(`${API}order/list/pay/${equipmentID}`, { dateFrom: dateFrom }));
}

/**
 * The rest request which attempts to get all the orders list to be payed on
 * the requested outlet from the database.
 * @param string outletID The id of the outlet to fetch unpaid orders
 *
 * @returns an object with an attribute containing the orders list
 */
export async function fetchAllListViewPayOutlet(outletID, dateFrom) {
    return await request.get(includeParamsToUrl(`${API}order/list/pay/outlet/${outletID}`, { dateFrom: dateFrom }));
}


export async function updateOrderStatus(orderID, status, time) {
    let url = `${API}order/${orderID}/${status}`;
    if (time) url = url + "?processingTimeInMinutes=" + time;
    return await request.put(url);
}

export async function updateOrderItemStatus(orderID, itemID, done, index) {
    return await request.put(`${API}order/${orderID}/item/${itemID}/${index}/${done}`);
}

export async function updateOrderItemUnavailbale(orderID, data) {
    return await request.put(`${API}order/${orderID}/item/unavailable`, data);
}

export async function create(data) {
    return await request.post(`${API}order`, data);
}

/**
 * The rest request which return required information to be presented on add new order page.
 * @returns an object with an attribute containing the requested information to be presented on the order page
 */
export async function fetchNew() {
    return await request.get(`${API}order/add`);
}

/**
 * The rest request which attempts to get an existing order information.
 * 
 * @param {*} orderID The id of the order form to be fetched
 * 
 * @returns an object with an attribute containing the current infromation of the order
 */
export async function fetchOne(orderID) {
    return await request.get(includeParamsToUrl(`${API}order/${orderID}`));
}

export async function updateTable(orderID, data) {
    return await request.put(includeParamsToUrl(`${API}order/${orderID}/table/update`, data));
}

export async function updateType(orderID, data) {
    return await request.put(includeParamsToUrl(`${API}order/${orderID}/type/update`, data));
}

export async function updatePersons(orderID, data) {
    return await request.put(includeParamsToUrl(`${API}order/${orderID}/persons/update`, data));
}

export async function payAction(orderID, data) {
    return await request.put(`${API}order/${orderID}/pay`, data);
}
export async function payActionAndClose(orderID, data) {
    return await request.put(`${API}order/${orderID}/pay/close`, data);
}

export async function payCombineAction(data) {
    return await request.put(`${API}order/pay/combine`, data);
}
export async function payCombineActionAndClose(data) {
    return await request.put(`${API}order/pay/combine/close`, data);
}

const orderApi = {
    fetchAllKitchen,
    fetchAll,
    fetchAllListViewPayOutlet,
    fetchAllOrdersEquipmentToPay,
    fetchNew,
    fetchOne,
    updateTable,
    updateType,
    updatePersons,
    updateOrderStatus,
    updateOrderItemStatus,
    updateOrderItemUnavailbale,
    create,
    payAction,
    payActionAndClose,
    payCombineAction,
    payCombineActionAndClose
};

export default orderApi;
