
import { Delete as DeleteIcon, Edit as EditIcon, Print as PrintIcon, PowerSettingsNew as PowerSettingsNewIcon } from '@mui/icons-material';
import { Card, CardActionArea, Divider, Grid } from '@mui/material';
import { Stack } from '@mui/system';
import { PosIcon } from 'assets/svg/SvgIcons';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomChip from 'components/Chip/CustomChip';
import CustomTypography from 'components/Typography/CustomTypography';
import { getDefaultPosObject } from 'constants/defaultObjects';
import { getThemeColor } from 'constants/theme';
import { t } from 'i18next';
import { cardItemButtonGroupStack, cardItemButtonGroupStackButton, cardItemButtonGroupStackButtonIcon, cardItemContent, cardItemContentActionArea, cardItemContentStackButtonIcon, cardPosItemContent, printerDetailsRow, textPOSHeaderTypography, textPosItemTypography } from 'themes/defaultThemes';
import { getTheme } from 'utils/theme';

/**
 * The Custom POS device Card, that display a customized {@link Card}.
 * 
 * @property {string} name the Checkbox name value
 * @property {string} selected If `true`, the component is checked.
 * @property {string} label the Checkbox text value
 * @property {object} sx The system prop that allows defining system overrides as well as additional CSS styles.
 * @property {function} onChange Callback that triggered when value is changed
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomPosCard({
   outletPos = getDefaultPosObject(),
   onDialog = false,
   sx = {},
   updateOutletPos,
   deleteOutletPos
}) {

   return (
      <Grid>
         {!onDialog &&
            <Stack direction="row" sx={cardItemButtonGroupStack()} key={`stack-${outletPos.id}`}>
               <CustomIconButton
                  keyValue={`btn-edit-${outletPos.id}`}
                  onClick={(event) => updateOutletPos(outletPos)}
                  icon={<EditIcon style={cardItemButtonGroupStackButtonIcon()} />}
                  iconSx={{ marginRight: "0px!important" }}
                  sx={{
                     backgroundColor: getThemeColor()[getTheme()]["editButtonBackgroundColor"],
                     ":hover": {
                        backgroundColor: getThemeColor()[getTheme()]["editButtonBackgroundColorHover"],
                     },
                     ...cardItemButtonGroupStackButton()
                  }}
                  tooltipTitle={t('actions.edit')}
               />
               <CustomIconButton
                  keyValue={`btn-delete-${outletPos.id}`}
                  onClick={(event) => deleteOutletPos(event, outletPos.id, outletPos.friendlyName)}
                  icon={<DeleteIcon style={cardItemButtonGroupStackButtonIcon()} />}
                  iconSx={{ marginRight: "0px!important" }}
                  sx={{
                     backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColor"],
                     ":hover": {
                        backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColorHover"],
                     },
                     ...cardItemButtonGroupStackButton()
                  }}
                  tooltipTitle={t('actions.delete')}
               />
            </Stack>
         }

         <Stack direction="row" sx={cardItemContentStackButtonIcon()} key={`stack-card-${outletPos.id}`}>
            <Card variant="outlined"
               key={`card-${outletPos.id}-stack`}
               sx={{
                  ...cardPosItemContent(),
                  ...sx
               }}
            >
               <CardActionArea sx={cardItemContentActionArea()} key={`card- back-ontent-${outletPos.id}`}>
                  {/* PRINTER FRIENDLY NAME */}
                  <CustomTypography
                     level="h6"
                     sx={{ ...textPOSHeaderTypography(), height: "20px" }}
                     text={outletPos.friendlyName}
                  />
                  {outletPos.friendlyName && <Divider />}

                  {/* REST OF PRINTER DETAILS */}
                  <Grid container direction="column" sx={{ height: "100%" }}>
                     <Grid direction="row" sx={{ height: "100%", display: "flex", justifyContent: "center", paddingLeft: 0, marginLeft: 0, minWidth: "100px" }}>
                        <CustomIconButton
                           icon={<PosIcon
                              color={"#000000 !important"}
                              style={{
                                 width: 80,
                                 height: 80,
                                 color: "#000000",
                                 padding: "10px"
                              }} />}
                           sx={{
                              borderRadius: "50px",
                              backgroundColor: "#00000040",
                              color: "#00000040",
                              border: "1px dash #00000040",
                              ":hover": {
                                 backgroundColor: "#00000040",
                              },
                              height: "fit-content",
                              placeSelf: "center",
                              boxShadow: "0px 0px 15px 0px #dcdcdc",
                           }}
                           iconSx={{
                              backgroundColor: "transparent",
                              padding: "0px",
                              margin: "0px"
                           }}
                        />
                     </Grid>

                     <Grid direction="row">
                        <Grid item xs={12} md={12} spacing={1}>
                           <CustomTypography
                              level="h6"
                              sx={textPosItemTypography()}
                              text={`${t('pos.terminalId')}:`}
                           />
                        </Grid>
                        <Grid item xs={12} md={12} spacing={1}>
                           <CustomChip label={outletPos.terminalId} rowIndex={0} />
                        </Grid>

                        <Grid item xs={12} md={5} spacing={1}>
                           <CustomTypography
                              level="h6"
                              sx={textPosItemTypography()}
                              text={`${t('pos.type.label')}:`}
                           />
                        </Grid>
                        <Grid item xs={12} md={6.5} spacing={1}>
                           <CustomChip label={t(`${outletPos.type}`)} rowIndex={0} />
                        </Grid>

                        <Grid item xs={12} md={5} spacing={1}>
                           <CustomTypography
                              level="h6"
                              sx={textPosItemTypography()}
                              text={`${t('pos.provider.label')}:`}
                           />
                        </Grid>
                        <Grid item xs={12} md={6.5} spacing={1}>
                           <CustomChip label={t(`${outletPos.provider}`)} rowIndex={0} />
                        </Grid>
                     </Grid>
                  </Grid>
               </CardActionArea>
            </Card>
         </Stack>
      </Grid>
   );
}

export default CustomPosCard;