
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

/**
 * The Custom Text Field, that display customized {@link TextField}.
 * 
 * @property {string} id the text field id
 * @property {string} name the text field name
 * @property {string} type the text field type
 * @property {int} step  For number type case, the number step counter
 * @property {int} min  For number type case, the min value
 * @property {int} max  For number type case, the max value
 * @property {string} className the text field class
 * @property {string} label the text field label
 * @property {string} variant The variant to use. @default 'outlined'
 * @property {string} inputAdornmentLabel the text field start input-adorment label
 * @property {string} inputAdornmentAfterLabel the text field after input-adorment label
 * @property {string} defaultValue the text field default value
 * @property {string} placeholder the text field placeholder
 * @property {string} helperText the text field helper text
 * @property {boolean} required if the value is required at this text field 
 * @property {boolean} error if text field is at an error state
 * @property {function} onChange function that triggered when value is changed
 * @property {function} onChange function that triggered when value is changed
 * @property {*} InputProps  Props applied to the Input element (for search text fields).
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomTextField({ id = "", name = "", type = "text", step, min = 0, max, className, label, variant, disabled,
    inputAdornmentLabel, inputAdornmentAfterLabel, defaultValue, value, inputWidth = "100%",
    placeholder, helperText, required = true, error = false, onChange, sx, onKeyUp, startAdormentWidth = "280px", InputProps = undefined
}) {
    const { t } = useTranslation();

    /* eslint-disable no-unused-vars */
    const handleIncrease = () => {
        onChange({ target: { value: value + 1 } });
    };

    const handleDecrease = () => {
        // setValue(prevValue => prevValue - 1);
    };
    const isMaxReached = value >= max;
    /* eslint-enable no-unused-vars */

    return (
        <TextField
            variant={variant}
            id={id}
            name={name}
            type={type}
            // step={step}
            // min={min}
            // max={max}
            value={value}
            disabled={disabled}
            className={className}
            label={label}
            autoComplete="off"
            defaultValue={defaultValue}
            placeholder={placeholder}
            helperText={helperText}
            required={required}
            error={error}
            onChange={onChange}
            sx={{
                border: "none",
                width: "100%",
                ...sx
            }}
            onKeyUp={onKeyUp}
            InputProps={(type === "number") ? {
                inputMode: 'numeric',
                pattern: '[0-9]*',
                inputProps: { max: max, min: min, step: step },
                style: {
                    // MozAppearance: 'textfield',
                    // appearance: 'none',
                    margin: 0,
                    width: inputWidth
                },
                startAdornment: (
                    <>
                        {inputAdornmentLabel !== undefined && <span style={{ width: startAdormentWidth, fontWeight: 600 }}>{t(inputAdornmentLabel)}</span>}
                    </>
                ),
                endAdornment: (
                    <>
                        {/* {inputAdornmentAfterLabel !== undefined && */}
                        {/* <InputAdornment position="end">
                            <Stack direction="row" spacing={1} sx={{ display: "inline-grid" }}>
                                <IconButton
                                    onClick={handleIncrease}
                                    disabled={isMaxReached}
                                    sx={{ height: "11px", width: "11px", marginLeft: 0 }}
                                >
                                    <ArrowDropUpIcon sx={{ fontSize: "24px" }} />
                                </IconButton>
                                <IconButton
                                    onClick={handleIncrease}
                                    disabled={isMaxReached}
                                    sx={{ height: "11px", width: "11px", marginLeft: "0!important" }}
                                >
                                    <ArrowDropDownIcon sx={{ fontSize: "24px" }} />
                                </IconButton>
                            </Stack>
                        </InputAdornment> */}
                        <span style={{ fontWeight: 600 }}>{t(inputAdornmentAfterLabel)}</span>
                        {/* }  */}
                    </>

                ),
            } : (InputProps) ? InputProps : {}}
        />
    );
}

export default CustomTextField;