import styled from '@emotion/styled';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Button, ButtonGroup, Fab, Grid, InputLabel, Popper, TextField, Typography, useMediaQuery } from '@mui/material';
import { Stack } from '@mui/system';
import { SwipeableList } from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';
import requestApi from 'api/request';
import { FontAwesomeIcon } from "assets/fontAwesome/FontAwesomeIcons";
import { NoRequests } from 'assets/lottie/LottieAnimations';
import {
    AddIcon,
    CalendarMonthIcon,
    FilterListIcon,
    InfoIcon,
    ListIcon,
    TableChartIcon
} from 'assets/mui/MuiIcons';
import CustomBreadcrumbMultiParent from 'components/Breadcrumb/CustomBreadcrumbMultiParent';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomRefreshButton from 'components/Button/CustomRefreshButton';
import CustomCustomerRequestCard from 'components/Card/request/CustomCustomerRequestCard';
import CustomMuiDateTimeRangePickerStore from 'components/DatePicker/CustomMuiDateTimeRangePickerStore';
import CustomDialogBasedOnScreenChildren from 'components/Dialogs/CustomDialogBasedOnScreenChildren';
import CustomAddRequestDialog from 'components/Dialogs/request/CustomAddRequestDialog';
import CustomFieldset from 'components/Fieldset/CustomFieldset';
import CustomGrid from 'components/Grid/CustomGrid';
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';
import CustomPageableTable from "components/Table/CustomPageableTable";
import CustomTitle from 'components/Title/CustomTitle';
import { lottieLoopOptions } from 'constants/animations/lottieOptions';
import { MORE_REQUESTS_ADD_SIZE } from 'constants/fieldProperties';
import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updateRequestData, updateRequestDataPagination, updateRequestSearch } from 'store/request/requestActions';
import { customerRequestEmptyInfoTextStyle, fabStyle, mainAreaTable, mainTag, mainTagBreadcrumb, mainTagSearch, mainTagSearchItemOrderContainer, mainTagSearchItemOrderStatusIcon, mainTagTitle, orderButton, pastCustomerRequestsMoreButtonStyle } from 'themes/defaultThemes';
import { hasRoleBuseer } from 'utils/auth';
import { calculateTimePassed, prepareTablePaginationSortParams } from 'utils/functions';
import { getRequestFilterStatusIcon } from 'utils/functions/requestStatus';
import { getRequestFilterTypeIcon } from 'utils/functions/requestType';
import { getLanguageFromURL } from 'utils/language';
import { fieldFormatter, nanFormatter, requestActionCellItemButtonsFormatter, requestActionFormatter } from "utils/table";
import useDocumentTitle from "utils/useDocumentTitle";

const CustomPopper = styled(Popper)({
    zIndex: 1300, // Adjust the z-index value as needed
});

/**
 * The CustomerRequestListComponent, that allow the customer to view the customer requests list into a table.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomerRequestListComponent() {
    const isMobile = useMediaQuery('(max-width:668px)');
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { organizationID } = useParams();
    const { outletID } = useParams();

    let path = `/${getLanguageFromURL()}`
    if (organizationID !== undefined) path += `/organization/${organizationID}`;
    if (outletID !== undefined) path += `/outlet/${outletID}`;

    //change document title
    useDocumentTitle(`Usee  ${organizationID !== undefined ? " | " + t('org.pageTitle') : ""} ${outletID !== undefined ? " | " + t('outlet.pageTitle') : ""} | ${t('request.pageTitle')}`);

    // data, search
    const data = useSelector(state => state?.request?.data);
    const search = useSelector(state => state?.request?.search);
    const refetchDataTriggered = useSelector(state => state?.request?.refetchData || false);

    const [isFirstTime, setIsFirstTime] = useState(true);

    // to perform initial request
    const [isLoaded, setIsLoaded] = useState(false);

    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313"
    });

    const [dialogOpen, setDialogOpen] = useState(false);
    const [viewSelected, setViewSelected] = useState(isMobile ? "list" : "table");

    // enable more button to view older requests
    const [enableMoreButton, setEnableMoreButton] = useState(true);
    const [actionButtonLoading, setActionButtonLoading] = useState(false);
    const [open, setOpen] = useState(false);

    // useEffect(() => {
    //    console.log("3")
    //    getCustomerRequests();
    // }, [refetchDataTriggered])

    useEffect(() => {
        // console.log("1")
        setIsFirstTime(false);
        getCustomerRequests();
        const fetchDataInterval = setInterval(() => {
            // console.log("2")
            if (!refetchDataTriggered) getCustomerRequests(); // Fetch data
        }, 5000); // 5 seconds interval

        // Clear interval on component unmount to avoid memory leaks
        return () => clearInterval(fetchDataInterval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetchDataTriggered]);

    /**
     * The rest endpoint to get the customers list.
     */
    function getCustomerRequests() {
        requestApi.fetchAll(prepareTablePaginationSortParams(data.pagination), search).then((r) => {
            Promise.resolve()
                .then(() => {
                    dispatch(updateRequestData({
                        ...data,
                        userAuth: r.data.returnobject.userAuth,
                        customerRequests: r.data.returnobject.customerRequests,
                        requestExtraCustomButtons: r.data.returnobject.requestExtraCustomButtons,
                        equipments: r.data.returnobject.equipments,
                        organization: r.data.returnobject.organization,
                        outlet: r.data.returnobject.outlet,
                        types: r.data.returnobject.types,
                        statuses: r.data.returnobject.statuses,
                        pagination: {
                            ...data.pagination,
                            count: r.data.returnobject.customerRequests.totalPages,
                            size: r.data.returnobject.customerRequests.size,
                            page: r.data.returnobject.customerRequests.number
                        }
                    }));
                })
                .then(() => {
                    if (r.data.returnobject.customerRequests.totalPages <= (r.data.returnobject.customerRequests.number + 1)) {
                        setActionButtonLoading(false);
                    }
                })
                .then(() => {
                    if (hasRoleBuseer(r.data.returnobject.userAuth.roles))
                        dispatch(updateRequestSearch({
                            ...search,
                            type: "CLEAN_TABLE"
                        }, false))
                })
                .then(() => {
                    if (isFirstTime)
                        dispatch(updateRequestSearch({
                            ...search,
                            status: "PENDING"
                        }, false))
                })
                .then(() => {
                    setIsLoaded(true);
                    setEnableMoreButton(false)
                })
        }).catch((e) => {
            // console.log(e);
        })
    }

    /**
     * 
     * @param {*} event 
     * @param {*} id 
     * @param {*} status 
     */
    function changeRequestStatus(event, id, status, trigerBySnackbar = false) {
        requestApi.changeRequestStatus(id, status).then((r) => {
            setAlertBox({
                isOpen: true,
                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313",
                action: (r.data.code === "SUCCESS") ? <Button color="inherit" size="small" sx={{ color: "white", border: "1px solid white", borderRadius: "20px" }} onClick={(event) => changeRequestStatus(event, id, "PENDING", trigerBySnackbar)}>{t('actions.undo')}</Button> : undefined
            });
            getCustomerRequests();
        }).catch((e) => {
            // console.log(e);
        })
    }

    /**
     * Update a specific search field valuer.
     * 
     * @param {string} field - The search field to alter its value.
     * @param {string} fieldValue - The new field value.
     */
    function handleSearchChange(field, fieldValue, extra = false) {
        let extraObj = {};
        if (extra) extraObj.type = "CUSTOM";
        if (field === "type") extraObj.label = null;

        dispatch(updateRequestSearch({
            ...search,
            [field]: fieldValue,
            ...extraObj
        }))
    }

    /**
     * Function that handles the snackbar open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }

    /**
     * Function that handles the pagination information.
     * @property {int} page the page to be redirected
     */
    function handlePaginationChange(page) {
        dispatch(updateRequestDataPagination({
            ...data,
            "pagination.page": page
        }))
    }

    /**
     * Function that handles the pagination information.
     * @property {int} size the size the new numberOfRows to be requested
     */
    function handleRowsPerPageChange(size) {
        let pagination = data.pagination;
        pagination.size = size;
        pagination.page = 0;
        dispatch(updateRequestDataPagination({
            ...data,
            pagination: pagination
        }))
    }

    /**
     * Function that handles the sort information.
     * @property {int} size the size the new numberOfRows to be requested
     */
    function handleColumnSortChange(changedColumn, direction) {
        let pagination = data.pagination;
        pagination.sort = changedColumn;
        pagination.direction = direction;
        dispatch(updateRequestDataPagination({
            ...data,
            "pagination": pagination
        }))
    }

    function getLanguageValue(tableData, rowIndex, getLanguageFromURL) {
        const rowData = tableData[rowIndex][6];
        let languageValue = rowData?.en;

        if (rowData !== null) {
            const languageFromURL = getLanguageFromURL();
            if (rowData[languageFromURL] !== null && rowData[languageFromURL] !== "") {
                languageValue = rowData[languageFromURL];
            }
        }

        return languageValue !== null ? languageValue : "react";
    }

    function onFilterChange(event) {
        // console.log(event)
        let field = event.field;
        let value = "";
        if (event !== null) {
            if (field === "equipment") {
                value = data.equipments?.find((eq) => eq.label === event?.value)?.id
                field = `equipmentID`;
            } else if (field === "status") {
                if (value === t("request.status.DONE")) {
                    value = "DONE";
                } else if (value === t("request.status.PENDING")) {
                    value = "PENDING";
                } else {
                    value = "";
                }
            } else if (field === "type") {
                if (value === t("request.type.PAYMENT")) {
                    value = "PAYMENT";
                } else if (value === t("request.type.CLEAN_TABLE")) {
                    value = "CLEAN_TABLE";
                } else if (value === t("request.type.ORDER")) {
                    value = "ORDER";
                } else if (value === t("request.type.OTHER")) {
                    value = "OTHER";
                } else if (value === t("request.type.CUSTOM")) {
                    value = "CUSTOM";
                } else {
                    value = "";
                }
            } else if (field === "customer") {
                field = `customerName`;
                value = event.value;
            } else if (field === "employee") {
                field = `employeeName`;
                value = event.value;
            }

            if (event.value === "All") {
                value = "";
            }

            dispatch(updateRequestSearch({
                ...search,
                [field]: value
            }))
        } else {
            dispatch(updateRequestSearch({
                ...search,
                equipmentID: "",
                status: "PENDING",
                type: ""
            }))
        }
    }

    // ======================================== NAVIGATE ADD ORDER/EDIT
    function navigateEditOrder(customerDraftOrder) {
        navigate(`${path}/order/add`, { state: { customerDraftOrder: customerDraftOrder } });
    }

    // ======================================== Filters

    const [popoverOpen, setPopoverOpen] = useState(false);

    function filtersElement() {
        return <Stack direction="row" sx={mainTagSearch(isMobile)}>
            <Grid container gap={1} sx={{ pading: "5px" }}>
                <Grid item xs={12} sm="auto">
                    {/* TYPES */}
                    <CustomFieldset
                        label={t('request.type.label')}
                        children={<>
                            <Stack direction="row" sx={{ padding: "10px", width: "calc(100% - 40px)", alignItems: "center" }}>
                                <Grid container gap={1}>
                                    <Grid item xs="auto">
                                        <CustomIconButton
                                            keyValue={`btn-search-all`}
                                            onClick={() => handleSearchChange("type", "")}
                                            icon={getRequestFilterTypeIcon("ALL")}
                                            iconSx={mainTagSearchItemOrderStatusIcon()}
                                            label={t('request.actions.all')}
                                            sx={orderButton()}
                                            variant='outlined'
                                            backgroundColor="#dcf1f7"
                                            isFocused={search.type === "" && search.label === null}
                                        />
                                    </Grid>

                                    {data.types?.map((type) => (
                                        <Grid item xs="auto">
                                            <CustomIconButton
                                                keyValue={`btn-search-${type}`}
                                                onClick={() => handleSearchChange("type", type)}
                                                icon={getRequestFilterTypeIcon(type)}
                                                iconSx={mainTagSearchItemOrderStatusIcon()}
                                                label={t(`request.type.${type}`)}
                                                sx={orderButton()}
                                                variant='outlined'
                                                backgroundColor="#dcf1f7"
                                                isFocused={search.type === type && search.label === null}
                                            />
                                        </Grid>
                                    ))}

                                    {data.requestExtraCustomButtons?.map((extraRequestBtn) => (
                                        <Grid item xs="auto">
                                            <CustomIconButton
                                                keyValue={`btn-search-${extraRequestBtn?.label?.en}`}
                                                onClick={() => handleSearchChange("label", extraRequestBtn?.label?.en, true)}
                                                icon={<FontAwesomeIcon icon={fas[extraRequestBtn.icon]} style={orderButton()} />} iconSx={mainTagSearchItemOrderStatusIcon()}
                                                label={extraRequestBtn?.label?.en}
                                                sx={orderButton()}
                                                variant='outlined'
                                                backgroundColor="#dcf1f7"
                                                isFocused={search.type === "CUSTOM" && search.label === extraRequestBtn?.label?.en}
                                            />
                                        </Grid>
                                    ))}

                                </Grid>
                            </Stack>
                        </>
                        }
                    />
                </Grid>

                <Grid item xs={12} sm="auto" sx={{ marginBottom: "10px" }}>
                    {/* STATUS */}
                    <CustomFieldset
                        label={t('request.status.label')}
                        children={<>
                            <Stack direction="row" sx={{ paddingLeft: "10px", alignItems: "center" }}>
                                <Grid container gap={1}>
                                    <Grid item xs="auto">
                                        <CustomIconButton
                                            keyValue={`btn-search-all`}
                                            onClick={() => handleSearchChange("status", "")}
                                            icon={getRequestFilterStatusIcon("ALL")}
                                            iconSx={mainTagSearchItemOrderStatusIcon()}
                                            label={t('request.actions.all')}
                                            sx={orderButton()}
                                            variant='outlined'
                                            backgroundColor="#dcf1f7"
                                            isFocused={search.status === ""}
                                        />
                                    </Grid>

                                    {data.statuses?.map((status) => (
                                        <Grid item xs="auto">
                                            <CustomIconButton
                                                keyValue={`btn-search-${status}`}
                                                onClick={() => handleSearchChange("status", status)}
                                                icon={getRequestFilterStatusIcon(status)}
                                                iconSx={mainTagSearchItemOrderStatusIcon()}
                                                label={t(`request.status.${status}`)}
                                                sx={orderButton()}
                                                variant='outlined'
                                                backgroundColor="#dcf1f7"
                                                isFocused={search.status === status}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Stack>
                        </>
                        }
                    />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Autocomplete
                        id="equipment-list-select"
                        options={data.equipments}
                        autoHighlight
                        multiple={false}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(event, newValue) => {
                            dispatch(updateRequestSearch({
                                ...search,
                                equipmentID: (newValue !== null) ? newValue.id : ""
                            }))
                        }}
                        value={search.equipmentID === "" ? data.equipments.find(eq => eq.id === search.equipmentID) : null}
                        renderOption={(props, option, { selected }) => {
                            return <>
                                <li key={option.id} {...props} style={{ paddingTop: "20px" }}>
                                    <Typography variant="inherit" sx={{ color: "#205d7b" }}>{option.label}</Typography>
                                </li>
                            </>
                        }}
                        PopperComponent={(props) => <CustomPopper {...props} />}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t('equipment.pageTitle')}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={12}>
                    <CustomIconButton
                        keyValue={`btn-search-date`}
                        onClick={() => setOpen(!open)}
                        icon={<CalendarMonthIcon />}
                        iconSx={mainTagSearchItemOrderStatusIcon()}
                        sx={{ ...mainTagSearchItemOrderStatusIcon(), borderRadius: "10px" }}
                    />
                    <CustomDialogBasedOnScreenChildren
                        isOpen={open}
                        handleClose={() => setOpen(false)}
                        handleOpen={() => setOpen(true)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        actionLabel={t('actions.ok')}
                        handleAction={() => setOpen(false)}
                        children={
                            <>
                                <CustomMuiDateTimeRangePickerStore
                                    search={search}
                                    dispatchedDateRangeUpdate={(newSearch) => dispatch(updateRequestSearch({ ...search, ...newSearch }))}
                                />
                            </>
                        }
                    />
                </Grid>
            </Grid>
        </Stack>
    }

    // ===============================================================================RENDER

    if (!isLoaded) {
        return <LoadingSkeleton lines={9} />;
    } else {
        /**
         * datatable columns.
         */
        const table_columns = [
            {
                name: "id",
                options: {
                    filter: false,
                    display: false
                }
            },
            {
                name: "equipment",
                label: t('equipment.pageTitle'),
                options: {
                    filter: true,
                    filterType: 'select',
                    filterOptions: {
                        names: data.equipments.map((eq) => eq?.label)
                    },
                    customFiltershowLabelListOptions: {
                        render: (v) => {
                            return `${t('equipment.pageTitle')}: ${v}`
                        }
                    },
                    // filterList: search.equipmentID !== "" ? [search.equipmentID]: undefined,
                    sort: true,
                    customBodyRender: (value, tableMeta) => {
                        return value?.label;
                    }
                }
            },
            {
                name: "customer",
                label: t('request.customerName'),
                options: {
                    filter: true,
                    filterType: 'textField',
                    customFilterListOptions: {
                        render: (v) => {
                            return `${t('customer.pageTitle')} ${t('label.name')}: ${v}`
                        }
                    },
                    // filterList: search.customerName !== "" ? search.customerName : undefined,
                    sort: true,
                    customBodyRender: (value, tableMeta) => {
                        return nanFormatter(value?.name);
                    }
                }
            },
            {
                name: "employee",
                label: t('request.employeeName'),
                options: {
                    filter: true,
                    filterType: 'textField',
                    customFilterListOptions: {
                        render: (v) => {
                            return `${t('employee.pageTitle')} ${t('label.name')}: ${v}`
                        }
                    },
                    // filterList: search.employeeName !== "" ? search.employeeName : undefined,
                    sort: true,
                    customBodyRender: (value, tableMeta) => {
                        return nanFormatter(value?.name);
                    }
                }
            },
            {
                name: "request",
                label: t('request.request'),
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta) => {
                        return tableMeta.tableData[tableMeta.rowIndex][5] !== "CUSTOM" ?
                            value :
                            getLanguageValue(tableMeta.tableData, tableMeta.rowIndex, getLanguageFromURL);
                    }
                }
            },
            {
                name: "type",
                label: t('request.type.label'),
                options: {
                    filter: true,
                    filterType: "custom",
                    filterOptions: {
                        display: () => {
                            return (
                                <>
                                    <Grid container gap={1} sx={{ pading: "5px" }}>
                                        <Grid item xs={12} sm="auto" sx={{ width: "fit-content !important" }}>
                                            <CustomFieldset
                                                label={t('request.type.label')}
                                                children={<>
                                                    <Stack direction="row" sx={{ padding: "10px", width: "calc(100% - 40px)", alignItems: "center" }}>
                                                        <Grid container gap={1}>
                                                            <Grid item xs="auto">
                                                                <CustomIconButton
                                                                    keyValue={`btn-search-all`}
                                                                    onClick={() => handleSearchChange("type", "")}
                                                                    icon={getRequestFilterTypeIcon("ALL")}
                                                                    iconSx={mainTagSearchItemOrderStatusIcon()}
                                                                    label={t('request.actions.all')}
                                                                    sx={orderButton()}
                                                                    variant='outlined'
                                                                    backgroundColor="#dcf1f7"
                                                                    isFocused={search.type === "" && search.label === null}
                                                                />
                                                            </Grid>

                                                            {data.types?.map((type) => (
                                                                <Grid item xs="auto">
                                                                    <CustomIconButton
                                                                        keyValue={`btn-search-${type}`}
                                                                        onClick={() => handleSearchChange("type", type)}
                                                                        icon={getRequestFilterTypeIcon(type)}
                                                                        iconSx={mainTagSearchItemOrderStatusIcon()}
                                                                        label={t(`request.type.${type}`)}
                                                                        sx={orderButton()}
                                                                        variant='outlined'
                                                                        backgroundColor="#dcf1f7"
                                                                        isFocused={search.type === type && search.label === null}
                                                                    />
                                                                </Grid>
                                                            ))}

                                                            {data.requestExtraCustomButtons?.map((extraRequestBtn) => (
                                                                <Grid item xs="auto">
                                                                    <CustomIconButton
                                                                        keyValue={`btn-search-${extraRequestBtn?.label?.en}`}
                                                                        onClick={() => handleSearchChange("label", extraRequestBtn?.label?.en, true)}
                                                                        icon={<FontAwesomeIcon icon={fas[extraRequestBtn.icon]} style={orderButton()} />} iconSx={mainTagSearchItemOrderStatusIcon()}
                                                                        label={extraRequestBtn?.label?.en}
                                                                        sx={orderButton()}
                                                                        variant='outlined'
                                                                        backgroundColor="#dcf1f7"
                                                                        isFocused={search.type === "CUSTOM" && search.label === extraRequestBtn?.label?.en}
                                                                    />
                                                                </Grid>
                                                            ))}

                                                        </Grid>
                                                    </Stack>
                                                </>
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            )
                        }
                    },
                    sort: true,
                    customBodyRender: (value, tableMeta) => {
                        return <>
                            {fieldFormatter(t(`request.type.${value}`))}
                            {/* if draft order is included */}
                            {(value === "ORDER" && tableMeta.tableData[tableMeta.rowIndex][7] !== null) &&
                                <Button startIcon={<InfoIcon style={{ color: "grey" }} onClick={() => navigateEditOrder(tableMeta.tableData[tableMeta.rowIndex][7])} />} />
                            }
                        </>
                    }
                }
            },
            {
                name: "label",
                options: {
                    filter: false,
                    sort: false,
                    display: false,
                }
            },
            {
                name: "draftOrder",
                options: {
                    filter: false,
                    sort: false,
                    display: false,
                }
            },
            {
                name: "dateCreated",
                label: t('label.dateCreated'),
                options: {
                    filter: true,
                    filterType: "custom",
                    filterOptions: {
                        display: () => {
                            return (
                                <>
                                    <Grid container gap={2} sx={mainTagSearchItemOrderContainer()} wrap="flex-wrap">
                                        <Grid item xs={12} sm="auto">
                                            <CustomIconButton
                                                keyValue={`btn-search-date`}
                                                onClick={() => setOpen(!open)}
                                                icon={<CalendarMonthIcon />}
                                                iconSx={mainTagSearchItemOrderStatusIcon()}
                                                sx={{ ...mainTagSearchItemOrderStatusIcon(), borderRadius: "10px" }}
                                            />
                                            <CustomDialogBasedOnScreenChildren
                                                isOpen={open}
                                                handleClose={() => setOpen(false)}
                                                handleOpen={() => setOpen(true)}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                actionLabel={t('actions.ok')}
                                                handleAction={() => setOpen(false)}
                                                children={
                                                    <>
                                                        <CustomMuiDateTimeRangePickerStore
                                                            search={search}
                                                            dispatchedDateRangeUpdate={(newSearch) => dispatch(updateRequestSearch(newSearch))}
                                                        />
                                                    </>
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            )
                        }
                    },
                    sort: false,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return calculateTimePassed(value);
                    }
                }
            },
            {
                name: "status",
                label: t('label.status'),
                options: {
                    // filter: true,
                    // filterType: 'select',
                    // filterOptions: {
                    //    names: data.statuses.map((status) => t(`request.status.${status}`))
                    // },
                    // customFilterListOptions: {
                    //    render: (v) => {
                    //       return `${t('label.status')}: ${v}`
                    //    }
                    // },
                    filter: true,
                    filterType: "custom",
                    filterOptions: {
                        display: () => {
                            return (
                                <>
                                    <Grid container gap={1} sx={{ pading: "5px" }}>
                                        <Grid item xs={12} sm="auto" sx={{ width: "fit-content !important" }}>
                                            <CustomFieldset
                                                label={t('request.status.label')}
                                                children={<>
                                                    <Stack direction="row" sx={{ paddingLeft: "10px", alignItems: "center" }}>
                                                        <Grid container gap={1}>
                                                            <Grid item xs="auto">
                                                                <CustomIconButton
                                                                    keyValue={`btn-search-all`}
                                                                    onClick={() => handleSearchChange("status", "")}
                                                                    icon={getRequestFilterStatusIcon("ALL")}
                                                                    iconSx={mainTagSearchItemOrderStatusIcon()}
                                                                    label={t('request.actions.all')}
                                                                    sx={orderButton()}
                                                                    variant='outlined'
                                                                    backgroundColor="#dcf1f7"
                                                                    isFocused={search.status === ""}
                                                                />
                                                            </Grid>

                                                            {data.statuses?.map((status) => (
                                                                <Grid item xs="auto">
                                                                    <CustomIconButton
                                                                        keyValue={`btn-search-${status}`}
                                                                        onClick={() => handleSearchChange("status", status)}
                                                                        icon={getRequestFilterStatusIcon(status)}
                                                                        iconSx={mainTagSearchItemOrderStatusIcon()}
                                                                        label={t(`request.status.${status}`)}
                                                                        sx={orderButton()}
                                                                        variant='outlined'
                                                                        backgroundColor="#dcf1f7"
                                                                        isFocused={search.status === status}
                                                                    />
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    </Stack>
                                                </>
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            )
                        }
                    },
                    sort: true,
                    customBodyRender: (value, tableMeta) => {
                        return requestActionFormatter(value,
                            tableMeta.tableData[tableMeta.rowIndex],
                            tableMeta.rowIndex,
                            0,
                            requestActionCellItemButtonsFormatter(changeRequestStatus, value),
                        );
                    },
                    setCellProps: () => ({ className: "click action" })
                }
            },
        ];

        return (
            <CustomGrid role="main" id="customer-list" sx={mainTag()}>
                {outletID !== undefined && organizationID !== undefined &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomBreadcrumbMultiParent
                            parents={[
                                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                                { name: t('outlet.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet` },
                                { name: data.outlet.name, url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet/${outletID}` }
                            ]}
                            instanceName={t('request.title')}
                        />
                    </CustomGrid>
                }

                {outletID === undefined && organizationID !== undefined &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` }
                            ]}
                            instanceName={t('request.title')}
                        />
                    </CustomGrid>
                }

                <Grid item xs={12} sm={12}>
                    <Grid container>
                        <Grid item xs={12} sm={3} md={3}>
                            <CustomGrid sx={mainTagTitle()}>
                                {!isMobile &&
                                    <Grid item xs="auto">
                                        <CustomRefreshButton onClick={getCustomerRequests} marginTop="-1%" />
                                    </Grid>
                                }
                                <Grid item xs={isMobile ? 9 : "auto"}>
                                    <CustomTitle
                                        title={t('request.title')}
                                    />
                                </Grid>

                                {/* FILTERS */}
                                {(isMobile || viewSelected === "list") &&
                                    <Grid item xs="auto">
                                        <CustomIconButton
                                            variant="outlined"
                                            onClick={() => setPopoverOpen(!popoverOpen)}
                                            icon={<FilterListIcon />}
                                        />
                                        <CustomDialogBasedOnScreenChildren
                                            isOpen={popoverOpen}
                                            handleClose={() => setPopoverOpen(false)}
                                            handleOpen={() => setPopoverOpen(true)}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            actionLabel={t('actions.ok')}
                                            handleAction={() => setPopoverOpen(false)}
                                            children={filtersElement()}
                                        />
                                    </Grid>
                                }
                            </CustomGrid>
                        </Grid>


                        {/* FILTERS */}
                        {/* {!isMobile &&
                     <Grid item xs={12} sm={9} md={9}>
                        {filtersElement()}
                     </Grid>
                  } */}
                    </Grid>
                </Grid>

                {!isMobile &&
                    <Grid item xs={12} sx={{ textAlign: "left" }} >
                        <ButtonGroup
                            disableElevation
                            variant="contained"
                        >
                            <CustomIconButton
                                label={t('request.btns.list')}
                                icon={<ListIcon />}
                                isFocused={viewSelected === "list"}
                                onClick={() => setViewSelected("list")}
                            />
                            <CustomIconButton
                                label={t('request.btns.table')}
                                icon={<TableChartIcon />}
                                isFocused={viewSelected === "table"}
                                onClick={() => setViewSelected("table")}
                            />
                        </ButtonGroup>
                    </Grid>
                }

                <Grid item xs={12} sx={{ textAlign: "center" }} >
                    <CustomGrid sx={{ ...mainAreaTable(), width: isMobile ? "97%" : "100%" }} container={false}>
                        {(viewSelected === "table" && !isMobile) &&
                            <CustomPageableTable
                                instances={data.customerRequests}
                                emptyMessage={t('table.noData')}
                                table_columns={table_columns}
                                addPageUrl=""
                                keyField="customer.firstName"
                                defaultSorted={{ name: data.pagination.sort, direction: data.pagination.direction }}
                                pagination={data.pagination}
                                handlePaginationChange={handlePaginationChange}
                                handleRowsPerPageChange={handleRowsPerPageChange}
                                handleColumnSortChange={handleColumnSortChange}
                                filter={true}
                                onFilterChange={onFilterChange}
                            />
                        }
                    </CustomGrid>

                    {(viewSelected === "list" || isMobile) &&
                        <>
                            <Stack direction="row" sx={{ padding: "10px", width: "calc(100% - 40px)", alignItems: "center" }}>
                                <Grid container gap={1}>
                                    <Grid item >
                                        <SwipeableList>
                                            {data.customerRequests?.content?.map((customerRequest, index) => (
                                                <CustomCustomerRequestCard
                                                    customerRequest={customerRequest}
                                                    index={index}
                                                    handleAction={(event, customerRequestID, status) => changeRequestStatus(event, customerRequestID, status)}
                                                />
                                            ))}
                                        </SwipeableList>
                                    </Grid>

                                    {enableMoreButton &&
                                        <Grid item xs={12} md={12}>
                                            <LoadingButton
                                                sx={pastCustomerRequestsMoreButtonStyle()}
                                                onClick={() => {
                                                    setActionButtonLoading(true);
                                                    dispatch(updateRequestData({
                                                        ...data,
                                                        "pagination.size": data.pagination.size + MORE_REQUESTS_ADD_SIZE
                                                    }))
                                                }}
                                                variant="outlined"
                                                type="button"
                                                keyValue="loadMore"
                                                data-redirect="stay"
                                                loading={actionButtonLoading}
                                                loadingPosition="end"
                                            >
                                                {t('request.moreRequests')}
                                            </LoadingButton>
                                        </Grid>
                                    }
                                </Grid>
                            </Stack>

                            {data.customerRequests?.content?.length === 0 &&
                                <div style={{ textAlign: "center", maerginTop: "20px" }}>
                                    <Lottie
                                        animationData={NoRequests}
                                        {...lottieLoopOptions}
                                    />

                                    <InputLabel sx={customerRequestEmptyInfoTextStyle()}>{t('request.empty')}</InputLabel>
                                </div>
                            }
                        </>
                    }
                </Grid>

                <Fab color="primary"
                    aria-label="add"
                    sx={fabStyle()}
                    onClick={() => setDialogOpen(true)}
                >
                    <AddIcon />
                </Fab>

                {dialogOpen &&
                    <CustomAddRequestDialog
                        isOpen={dialogOpen}
                        handleClose={() => setDialogOpen(false)}
                        organizationID={data.organization?.id}
                        outletID={data.outlet?.id}
                        title={`${t('actions.add')} ${t('request.pageTitle')}`}
                        equipmentOptions={data.equipments}
                        setAlertBox={setAlertBox}
                        getCustomerRequests={getCustomerRequests}
                        actionLabel={""}
                    />
                }

                {alertBox.isOpen &&
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={3000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        handleSnackbarOpen={handleSnackbarState} />
                }
            </CustomGrid>
        );
    }
}

export default CustomerRequestListComponent;