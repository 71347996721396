
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { Card, CardActionArea, Divider, Grid } from '@mui/material';
import { Stack } from '@mui/system';
import HtmlThumbnailPreview from 'components/Assets/HtmlThumbnailPreview';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomTypography from 'components/Typography/CustomTypography';
import { getDefaultEmailTemplateObject } from 'constants/defaultObjects';
import { getThemeColor } from 'constants/theme';
import { t } from 'i18next';
import { cardItemButtonGroupStack, cardItemButtonGroupStackButton, cardItemButtonGroupStackButtonIcon, cardItemContent, cardItemContentActionArea, cardItemContentStackButtonIcon, textPrinterHeaderTypography } from 'themes/defaultThemes';
import { getTheme } from 'utils/theme';

/**
 * The Custom emailTemplate Card, that display a customized {@link Card}.
 * 
 * @param {string} name the Checkbox name value
 * @param {string} selected If `true`, the component is checked.
 * @param {string} label the Checkbox text value
 * @param {object} sx The system prop that allows defining system overrides as well as additional CSS styles.
 * @param {function} onChange Callback that triggered when value is changed
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomEmailTemplateCard({
    emailTemplate = getDefaultEmailTemplateObject(),
    onDialog = false,
    sx = {},
    updateEmailTemplate,
    deleteEmailTemplate
}) {

    return (
        <Grid>
            {!onDialog &&
                <Stack direction="row" sx={cardItemButtonGroupStack()} key={`stack-${emailTemplate.id}`}>
                    <CustomIconButton
                        keyValue={`btn-edit-${emailTemplate.id}`}
                        onClick={(event) => updateEmailTemplate(emailTemplate)}
                        icon={<EditIcon style={cardItemButtonGroupStackButtonIcon()} />}
                        iconSx={{ marginRight: "0px!important" }}
                        sx={{
                            backgroundColor: getThemeColor()[getTheme()]["editButtonBackgroundColor"],
                            ":hover": {
                                backgroundColor: getThemeColor()[getTheme()]["editButtonBackgroundColorHover"],
                            },
                            ...cardItemButtonGroupStackButton()
                        }}
                        tooltipTitle={t('actions.edit')}
                    />
                    <CustomIconButton
                        keyValue={`btn-delete-${emailTemplate.id}`}
                        onClick={(event) => deleteEmailTemplate(event, emailTemplate.id, emailTemplate.name)}
                        icon={<DeleteIcon style={cardItemButtonGroupStackButtonIcon()} />}
                        iconSx={{ marginRight: "0px!important" }}
                        sx={{
                            backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColor"],
                            ":hover": {
                                backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColorHover"],
                            },
                            ...cardItemButtonGroupStackButton()
                        }}
                        tooltipTitle={t('actions.delete')}
                    />
                </Stack>
            }
            <Stack direction="row" sx={cardItemContentStackButtonIcon()} key={`stack-card-${emailTemplate.id}`}>
                <Card variant="outlined"
                    key={`card-${emailTemplate.id}-stack`}
                    sx={{
                        ...cardItemContent(),
                        minHeight: "320px",
                        width: "200px",
                        ...sx
                    }}
                >
                    <CardActionArea sx={cardItemContentActionArea()} key={`card- back-ontent-${emailTemplate.id}`}>
                        {/* EMAIL Template FRIENDLY NAME */}
                        <CustomTypography
                            level="h6"
                            sx={{ ...textPrinterHeaderTypography(), height: "20px" }}
                            text={emailTemplate.name}
                        />
                        {emailTemplate.name && <Divider />}

                        {/* THUMBNAIL OF HTML */}
                        <Grid container direction="column" sx={{ height: "100%" }}>
                            <Grid direction="row">
                                <Grid item xs={12} md={12} spacing={1} >
                                    <HtmlThumbnailPreview htmlContent={emailTemplate.bodyHTML} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardActionArea>
                </Card>
            </Stack>
        </Grid>
    );
}

export default CustomEmailTemplateCard;