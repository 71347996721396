import { Grid } from '@mui/material';

/**
 * The CustomTitleBoldSubtitle, that display each page custom title with bold subtitle.
 * @property {string} title the page title
 * @property {string} subtitleboldText the page bold subtitle section
 * @property {string} subtitleBeforeText the before subtitle text
 * @property {string} subtitleBeforeText the after subtitle text
 * @property {string} className the text class name
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomTitleBoldSubtitle({ title, subtitleBeforeText = "", subtitleboldText = "", subtitleAfterText = "", className = "usee-h3" }) {

    return (
        <Grid>
            {title !== undefined && <h1>{title}</h1>}
            <h3 className={className}>
                {subtitleBeforeText}
                {subtitleboldText !== "" && <strong>{subtitleboldText}</strong>}
                {subtitleAfterText}
            </h3>
        </Grid>
    );
}

export default CustomTitleBoldSubtitle;