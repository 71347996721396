import { request } from "constants/alias";
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from "utils/functions";
import updateFieldIfValidList from "utils/functions/updateFieldIfNonEmptyArray";
import updateFieldIfValid from "utils/functions/updateFieldIfValid";

/**
 * The rest request which attempts to get the feedback forms list from the database.
 *
 * @param {object} pageable includes all pageable details (page, size, sort, direction)
 * @param {*} search The filters for search.
 *
 * @returns an object with an attribute containing the feedback forms list
 */
export async function fetchAll(pageable, search) {
  let data = {};
  updateFieldIfValid(data, "organizationID", search.organizationID);
  updateFieldIfValid(data, "name", search.name);
  updateFieldIfValidList(data, "outletIDs", search.outletIDs);

  if (!objectIsEmpty(data))
    return await request.get(includeParamsToUrl(`${API}feedback`, pageable !== undefined ? { ...pageable, ...data } : data));
  else
    return await request.get(includeParamsToUrl(`${API}feedback`, pageable !== undefined ? pageable : {}));
}

/**
 * The rest request which return required information to be presented on add new feedback form page.
 *
 * @returns an object with an attribute containing the requested information to be presented on    feedback form page
 */
export async function fetchNew(organizationID, outletID) {
  let data = {};
  updateFieldIfValid(data, "organizationID", organizationID);
  updateFieldIfValid(data, "outletID", outletID);

  if (!objectIsEmpty(data))
    return await request.get(includeParamsToUrl(`${API}feedback/add`, data));
  else return await request.get(includeParamsToUrl(`${API}feedback/add`));
}

/**
 * The rest request which attempts to get an existing feedback form information.
 *
 * @param {*} organizationID The id of the feedback form form organization id
 * @param {*} feedbackID The id of the feedback form to be fetched
 *
 * @returns an object with an attribute containing the current infromation of the feedback
 */
export async function fetchOne(organizationID, outletID, feedbackID) {
  let data = {};
  updateFieldIfValid(data, "organizationID", organizationID);
  updateFieldIfValid(data, "outletID", outletID);

  if (!objectIsEmpty(data))
    return await request.get(includeParamsToUrl(`${API}feedback/${feedbackID}`, data));
  else
    return await request.get(includeParamsToUrl(`${API}feedback/${feedbackID}`));
}

/**
 * The rest request which attempts to create a new feedback form.
 *
 * @param {*} data A JSON object which contains new feedback form infromation
 *
 * @returns an object refering to the success or failure of the request
 */
export async function create(data) {
  return await request.post(`${API}feedback`, data);
}

/**
 * The rest request which attempts to update an existing feedback form.
 *
 * @param {*} data A JSON object which contains the updated infromation of the feedback form
 *
 * @returns an object refering to the success or failure of the request
 */
export async function update(data) {
  return await request.put(`${API}feedback`, data);
}

// /**
//  * The rest request which attempts to enable an existing feedback form.
//  * @param {*} feedbackID The id of the feedback form to be show
//  *
//  * @returns an object refering to the success or failure of the request
//  */
// export async function show(feedbackID) {
//     return await request.post(`${API}feedback/${feedbackID}/show`);
// }

// /**
//  * The rest request which attempts to hide an existing feedback form.
//  * @param {*} feedbackID The id of the feedback form to be hide
//  *
//  * @returns an object refering to the success or failure of the request
//  */
// export async function hide(feedbackID) {
//     return await request.post(`${API}feedback/${feedbackID}/hide`);
// }

/**
 * The rest request to delete a specific feedback form.
 * @param {*} id The id of the feedback form to be deleted
 *
 * @returns an object refering to the success or failure of the request
 */
export async function deleteById(id) {
  return await request.delete(`${API}feedback/${id}`);
}

const feedbackApi = {
  fetchAll,
  fetchNew,
  fetchOne,
  create,
  update,
  // show,
  // hide,
  deleteById,
};

export default feedbackApi;
