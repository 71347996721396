import { Close as CloseIcon, Fullscreen as FullscreenIcon, Settings as SettingsIcon } from '@mui/icons-material';
import { Box, Grid, Stack, Typography } from "@mui/material";
import pickupDisplayApi from "api/pickupDisplay";
import waitlistApi from 'api/waitlist';
import CustomIconButton from "components/Button/CustomIconButton";
import CustomGrid from "components/Grid/CustomGrid";
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomObjectSelect from "components/Select/CustomObjectSelect";
import CustomTitle from "components/Title/CustomTitle";
import { useEffect, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { mainArea, mainTag, mainTagSearch, mainTagSearchItem, mainTagTitle, pickupDisplayIconButtons, pickupDisplayStack, pickupDisplayStackGrid, pickupDisplayStackGridItem, pickupDisplayStackGridItemHeader } from "themes/defaultThemes";
import { hasRoleAdmin } from "utils/auth";
import { getLanguageFromURL } from "utils/language";
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The WaitlistDisplayComponent, that shows the outlet waitlist details.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function WaitlistDisplayComponent() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    //change document title
    useDocumentTitle(`Usee | ${t('waitlist.label')}`)

    const { organizationID } = useParams();
    const { outletID } = useParams();

    const [width] = useState(window.innerWidth);
    // const [height, setHeight] = React.useState(window.innerHeight);

    // to perform initial request
    const [isFirstTime, setIsFirstTime] = useState(true);
    // to render DOM based on rest api call status, if true the rest api call completed
    const [isLoaded, setIsLoaded] = useState(true);

    /**
     * @type {object}
     * 
     * @property {object} userAuth the authenticated user infromation
     * @property {object} alertBox holds snackbar infromation and style
     * @property {array} orders the orders list
     * @property {object} outlet tha outlet basic details (id and name, isActive)
     * @property {object} organization tha organization basic details (id and name)
     * @property {object} pagination includes all pageable details (page, size, sort, direction)
     */
    const [data, setData] = useState({
        userAuth: null,
        organization: {
            id: organizationID,
            name: ""
        },
        outlet: {
            id: outletID,
            name: ""
        },
        organizations: [],
        outlets: [],
        waitlists: [],
        waitlist: {},
        enabledWaitlistRequirementOptions: [],
        enabledWaitilistTagOptions: [],
        requiredTagSelection: false,
        active: false,
    });

    const [search, setSearch] = useState({
        organizationID: "",
        outletID: ""
    });

    /**
     * The rest endpoint to get the orders list.
     */
    function getWaitlists() {
        // waitlistApi.fetchInfo(search).then((r) => {
        //     setData({
        //         ...data,
        //         userAuth: r.data.returnobject.userAuth,
        //         workingOrders: r.data.returnobject.workingOrders,
        //         doneOrders: r.data.returnobject.doneOrders,
        //         organization: r.data.returnobject.organization,
        //         organizations: r.data.returnobject.organizations,
        //         outlets: r.data.returnobject.outlets,
        //         displaySettings: r.data.returnobject.displaySettings
        //     });
        //     if (!hasRoleAdmin(r.data.returnobject.userAuth.roles))
        //         setSearch({
        //             ...search,
        //             organizationID: r.data.returnobject.organization.id
        //         })
        setIsLoaded(true);
        // }).catch((e) => {
        //     // console.log(e);
        // })
    }

    useEffect(() => {
        if (isFirstTime) {
            getWaitlists();
            setIsFirstTime(false);
        }
        const identifier = setInterval(() => {
            if (!isFirstTime) getWaitlists();
        }, 7000);

        return () => {
            clearInterval(identifier);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);


    const handle = useFullScreenHandle();


    // ===============================================================================RENDER

    if (!isLoaded) {
        return (
            <CustomGrid role="main" id="display-waitlist" sx={mainTag()}>
                <LoadingSkeleton lines={9} />
            </CustomGrid>
        );
    } else {
        const fontSize = width / 2 / 20;

        return (
            <CustomGrid role="main" id="display-waitlist" sx={mainTag()}>
                <CustomGrid sx={mainTagTitle()}>
                    <CustomTitle
                        title={t('waitlist.label')}
                        subtitle={""}
                    />
                </CustomGrid>

                <Grid item>
                    <CustomIconButton
                        onClick={handle.enter}
                        icon={<FullscreenIcon />}
                        sx={pickupDisplayIconButtons()}
                        iconSx={{ margin: 0 }}
                        tooltipTitle={t('display.pickup.fullscreen')}
                    />
                </Grid>

                <Box sx={{ flexGrow: 1 }} />

                <Grid item>
                    <Stack direction="row" sx={mainTagSearch()} >
                        <CustomGrid container={false} sx={mainTagSearch()} direction="column" spacing={2}>

                            {(data.userAuth !== null && hasRoleAdmin(data.userAuth.roles)) &&
                                <CustomObjectSelect name="organizationID"
                                    required={true}
                                    // defaultValue={defaultOrganizationSelected}
                                    labelID="organizationID" label={t('org.pageTitle')}
                                    options={data.organizations}
                                    onChange={(event) => setSearch({ ...search, organizationID: event.target.value, outletID: "" })}
                                    sx={{ ...mainTagSearchItem() }}
                                    field="name"
                                />
                            }
                            {data.outlets !== null &&
                                <CustomObjectSelect name="outletID"
                                    required={true}
                                    // defaultValue={defaultOrganizationSelected}
                                    labelID="outletID" label={t('outlet.pageTitle')}
                                    options={data.outlets}
                                    onChange={(event) => setSearch({ ...search, outletID: event.target.value })}
                                    sx={{ ...mainTagSearchItem() }}
                                    field="name"
                                />
                            }
                        </CustomGrid>
                    </Stack>
                </Grid>

                <CustomGrid sx={mainArea()} container={false}>
                    <FullScreen handle={handle}>
                        {/* <CustomIconButton
                            onClick={handle.exit}
                            icon={<CloseIcon />}
                            // sx={{
                            //     backgroundColor: data.displaySettings.readyOrderBackgroundColor,
                            //     color: data.displaySettings.readyOrderHeaderFontColor, position: "absolute", right: "20px", width: "20px"
                            // }}
                            iconSx={{ fontSize: `${fontSize / 1.5}px` }}
                            tooltipTitle={t('display.pickup.close')}
                        /> */}

                        <Grid container spacing={0}>
                            <Grid item xs={6}>
                            </Grid>
                        </Grid>
                    </FullScreen>
                </CustomGrid>
            </CustomGrid>
        );
    }
}

export default WaitlistDisplayComponent;