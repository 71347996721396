import { AccountCircle as AccountCircleIcon, Logout as LogoutIcon } from '@mui/icons-material';
import {
    Divider,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Popover
} from '@mui/material';
import { SectionsIcon } from 'assets/svg/SvgIcons';
import { getProfileMenuSvgIconProperties } from 'constants/fieldProperties';
import { useTranslation } from 'react-i18next';

/**
 * The CustomMenuLayoutButtons, that display menu layout change.
 * 
 * @param {object} item the menu item details
 * @param {string} priceColor the color for price to have
 * @param {string} lang the lang code
 * @param {fucntion} handleItemDialogState Callback that handles the open item details dialog for more details and to fill in fields to add one or more to cart.
 *
 * @returns {JSX.Element} A React component that renders a menu item.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomMenu({ id, anchorEl, open, handleClose, handleLogout, handleViewProfile, handleViewMySections, viewMysections = false }) {
    const { t } = useTranslation();

    return (
        <Popover
            id={id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem
                onClick={handleViewProfile}
                style={{
                    minWidth: "100px",
                    padding: "15px 40px 15px 20px"
                }}>
                <ListItemIcon>
                    <AccountCircleIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>{t('label.profile')}</ListItemText>
            </MenuItem>
            <Divider />
            {viewMysections &&
                <>
                    <MenuItem
                        onClick={handleViewMySections}
                        style={{
                            minWidth: "100px",
                            padding: "15px 40px 15px 20px"
                        }}>
                        <ListItemIcon>
                            <SectionsIcon {...getProfileMenuSvgIconProperties()} />
                        </ListItemIcon>
                        <ListItemText>{t('menu.mysections')}</ListItemText>
                    </MenuItem>
                    <Divider />
                </>
            }
            <MenuItem
                onClick={handleLogout}
                style={{
                    minWidth: "100px",
                    padding: "15px 40px 15px 20px"
                }}>
                <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>{t('logout')}</ListItemText>
            </MenuItem>
        </Popover>
    );
}

export default CustomMenu;