import { Close as CloseIcon, Fullscreen as FullscreenIcon, Settings as SettingsIcon } from '@mui/icons-material';
import { Box, Grid, Stack, Typography } from "@mui/material";
import pickupDisplayApi from "api/pickupDisplay";
import CustomIconButton from "components/Button/CustomIconButton";
import CustomGrid from "components/Grid/CustomGrid";
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomObjectSelect from "components/Select/CustomObjectSelect";
import CustomTitle from "components/Title/CustomTitle";
import { useEffect, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { mainArea, mainTag, mainTagSearch, mainTagSearchItem, mainTagTitle, pickupDisplayIconButtons, pickupDisplayStack, pickupDisplayStackGrid, pickupDisplayStackGridItem, pickupDisplayStackGridItemHeader } from "themes/defaultThemes";
import { hasRoleAdmin } from "utils/auth";
import { getLanguageFromURL } from "utils/language";
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The UserListComponent, that allow the user to view the users list into a table.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function PickupOrderDisplayComponent() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    //change document title
    useDocumentTitle(`Usee | ${t('display.pickup.label')}`)

    const { organizationID } = useParams();
    const { outletID } = useParams();

    const [width] = useState(window.innerWidth);
    // const [height, setHeight] = React.useState(window.innerHeight);

    // to perform initial request
    const [isFirstTime, setIsFirstTime] = useState(true);
    // to render DOM based on rest api call status, if true the rest api call completed
    const [isLoaded, setIsLoaded] = useState(true);

    /**
     * @type {object}
     * 
     * @property {object} userAuth the authenticated user infromation
     * @property {object} alertBox holds snackbar infromation and style
     * @property {array} orders the orders list
     * @property {object} outlet tha outlet basic details (id and name, isActive)
     * @property {object} organization tha organization basic details (id and name)
     * @property {object} pagination includes all pageable details (page, size, sort, direction)
     */
    const [data, setData] = useState({
        userAuth: null,
        workingOrders: null,
        doneOrders: null,
        displaySettings: null,
        organization: {
            id: organizationID,
            name: ""
        },
        outlet: {
            id: outletID,
            name: ""
        },
        organizations: [],
        outlets: []
    });

    const [search, setSearch] = useState({
        organizationID: "",
        outletID: ""
    });

    /**
     * The rest endpoint to get the orders list.
     */
    function getPickUpOrders() {
        pickupDisplayApi.fetchInfo(search).then((r) => {
            setData({
                ...data,
                userAuth: r.data.returnobject.userAuth,
                workingOrders: r.data.returnobject.workingOrders,
                doneOrders: r.data.returnobject.doneOrders,
                organization: r.data.returnobject.organization,
                organizations: r.data.returnobject.organizations,
                outlets: r.data.returnobject.outlets,
                displaySettings: r.data.returnobject.displaySettings
            });
            if (!hasRoleAdmin(r.data.returnobject.userAuth.roles))
                setSearch({
                    ...search,
                    organizationID: r.data.returnobject.organization.id
                })
            setIsLoaded(true);
        }).catch((e) => {
            // console.log(e);
        })
    }

    useEffect(() => {
        if (isFirstTime) {
            getPickUpOrders();
            setIsFirstTime(false);
        }
        const identifier = setInterval(() => {
            if (!isFirstTime) getPickUpOrders();
        }, 7000);

        return () => {
            clearInterval(identifier);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);


    const handle = useFullScreenHandle();


    // ===============================================================================RENDER

    if (!isLoaded) {
        return (
            <CustomGrid role="main" id="display-pickup-list" sx={mainTag()}>
                <LoadingSkeleton lines={9} />
            </CustomGrid>
        );
    } else {
        const fontSize = width / 2 / 20;

        return (
            <CustomGrid role="main" id="display-pickup-list" sx={mainTag()}>
                <CustomGrid sx={mainTagTitle()}>
                    <CustomTitle
                        title={t('display.pickup.label')}
                        subtitle={""}
                    />
                </CustomGrid>

                {search.organizationID !== "" && search.outletID !== "" &&
                    <Grid item>
                        <CustomIconButton onClick={() => navigate(`/${getLanguageFromURL()}/display/pickup/settings`)}
                            icon={<SettingsIcon />}
                            sx={pickupDisplayIconButtons()}
                            iconSx={{ margin: 0 }}
                            tooltipTitle={t('menu.settings')}
                        />
                    </Grid>
                }

                <Grid item>
                    <CustomIconButton
                        onClick={handle.enter}
                        icon={<FullscreenIcon />}
                        sx={pickupDisplayIconButtons()}
                        iconSx={{ margin: 0 }}
                        tooltipTitle={t('display.pickup.fullscreen')}
                    />
                </Grid>

                <Box sx={{ flexGrow: 1 }} />

                <Grid item>
                    <Stack direction="row" sx={mainTagSearch()} >
                        <CustomGrid container={false} sx={mainTagSearch()} direction="column" spacing={2}>

                            {(data.userAuth !== null && hasRoleAdmin(data.userAuth.roles)) &&
                                <CustomObjectSelect name="organizationID"
                                    required={true}
                                    // defaultValue={defaultOrganizationSelected}
                                    labelID="organizationID" label={t('org.pageTitle')}
                                    options={data.organizations}
                                    onChange={(event) => setSearch({ ...search, organizationID: event.target.value, outletID: "" })}
                                    sx={{ ...mainTagSearchItem() }}
                                    field="name"
                                />
                            }
                            {data.outlets !== null &&
                                <CustomObjectSelect name="outletID"
                                    required={true}
                                    // defaultValue={defaultOrganizationSelected}
                                    labelID="outletID" label={t('outlet.pageTitle')}
                                    options={data.outlets}
                                    onChange={(event) => setSearch({ ...search, outletID: event.target.value })}
                                    sx={{ ...mainTagSearchItem() }}
                                    field="name"
                                />
                            }
                        </CustomGrid>
                    </Stack>
                </Grid>

                {data.displaySettings !== null &&
                    <CustomGrid sx={mainArea()} container={false}>
                        <FullScreen handle={handle}>

                            <CustomIconButton
                                onClick={handle.exit}
                                icon={<CloseIcon />}
                                sx={{
                                    backgroundColor: data.displaySettings.readyOrderBackgroundColor,
                                    color: data.displaySettings.readyOrderHeaderFontColor, position: "absolute", right: "20px", width: "20px"
                                }}
                                iconSx={{ fontSize: `${fontSize / 1.5}px` }}
                                tooltipTitle={t('display.pickup.close')}
                            />

                            <Grid container spacing={0}>
                                <Grid item xs={6}>
                                    <Stack direction="column" sx={pickupDisplayStack()}>
                                        <Grid container gap={2} sx={{ ...pickupDisplayStackGrid(), backgroundColor: data.displaySettings.preparingOrderBackgroundColor }}>
                                            <Grid item xs={12} md={12}>
                                                <Typography sx={{ ...pickupDisplayStackGridItemHeader(), fontSize: `${data.displaySettings.headerFontSize}px`, color: data.displaySettings.preparingOrderHeaderFontColor }} variant="h2" align="center">
                                                    {t('display.pickup.preparing')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={12} >
                                                <Grid container gap={5} >
                                                    {data.workingOrders?.map((order, i) => (
                                                        <Grid item>
                                                            <Typography sx={{ ...pickupDisplayStackGridItem(), fontSize: `${data.displaySettings.fontSize}px`, color: data.displaySettings.preparingOrderFontColor }} variant="h2" align="center">
                                                                {`#${order.orderNumber}`}
                                                            </Typography>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                </Grid>
                                <Grid item xs={6}>
                                    <Stack direction="column" sx={pickupDisplayStack()}>
                                        <Grid container gap={2} sx={{ ...pickupDisplayStackGrid(), backgroundColor: data.displaySettings.readyOrderBackgroundColor }}>
                                            <Grid item xs={12} md={12} sx={{ maxHeight: "90px" }}>
                                                <Typography sx={{ ...pickupDisplayStackGridItemHeader(), fontSize: `${data.displaySettings.headerFontSize}px`, color: data.displaySettings.readyOrderHeaderFontColor }} variant="h2" align="center">
                                                    {t('display.pickup.ready')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={12} >
                                                <Grid container gap={4} >
                                                    {data.doneOrders?.map((order, i) => (
                                                        <Grid item >
                                                            <Typography sx={{ ...pickupDisplayStackGridItem(), fontSize: `${data.displaySettings.fontSize}px`, color: data.displaySettings.readyOrderFontColor }} variant="h2" align="center">
                                                                {`#${order.orderNumber}`}
                                                            </Typography>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </FullScreen>
                    </CustomGrid>
                }
            </CustomGrid>
        );
    }
}

export default PickupOrderDisplayComponent;