import { faCreditCard, faExclamationCircle, faHands, faMoneyBill1Wave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    AllOut as AllOutIcon,
    Block as BlockIcon,
    CalendarMonth as CalendarMonthIcon,
    Close as CloseIcon,
    Done as DoneIcon,
    Schedule as ScheduleIcon
} from '@mui/icons-material';
import { Button, Grid, TableCell, TableRow } from '@mui/material';
import { Stack } from '@mui/system';
import orderApi from 'api/order';
import CustomBreadcrumbMultiParent from 'components/Breadcrumb/CustomBreadcrumbMultiParent';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomRefreshButton from 'components/Button/CustomRefreshButton';
import CustomCheckbox from 'components/Checkbox/CustomCheckbox';
import CustomMuiDateTimeRangePickerStore from 'components/DatePicker/CustomMuiDateTimeRangePickerStore';
import CustomDialogBasedOnScreenChildren from 'components/Dialogs/CustomDialogBasedOnScreenChildren';
import CustomFieldset from 'components/Fieldset/CustomFieldset';
import CustomGrid from 'components/Grid/CustomGrid';
import ListPageSkeleton from 'components/Loading/ListPageSkeleton';
import CustomObjectSelect from 'components/Select/CustomObjectSelect';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';
import CustomExpandablePageableTable from 'components/Table/CustomExpandablePageableTable';
import CustomTable from 'components/Table/CustomTable';
import CustomTextField from 'components/Text/CustomTextField';
import CustomTitle from 'components/Title/CustomTitle';
import { getOrderDateTimeFormatter } from 'constants/calendar';
import { getDefaultSearchOrder } from 'constants/defaultObjects';
import { getThemeColor } from 'constants/theme';
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updateOrderDataPagination, updateOrderSearch } from 'store/order/orderActions';
import { getOrderButtonIcon, mainAreaTable, mainTag, mainTagBreadcrumb, mainTagSearch, mainTagSearchItem, mainTagSearchItemOrderContainer, mainTagSearchItemOrderStatusIcon, mainTagTitle, orderButton, pageAddButton } from 'themes/defaultThemes';
import { hasRoleAdmin } from 'utils/auth';
import { getLanguageFromURL } from 'utils/language';
import { actionFormatter, dateFormatter, fieldFormatter, fixItemInArray, idFormatterLinkWithParentAndNameLabel, idFormatterLinkWithParentAndNameLabelEquipment, orderActionCellButtonsFormatter, orderActionCellItemButtonsFormatter, orderActionFormatter } from "utils/table";
import { getTheme } from 'utils/theme';
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The OrderListComponent, that allow the user to view the organization/outlet orders list into a table.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function OrderListComponent() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { organizationID } = useParams();
    const { outletID } = useParams();

    const navigate = useNavigate();
    let path = `/${getLanguageFromURL()}`
    if (organizationID !== undefined) path += `/organization/${organizationID}`;
    if (outletID !== undefined) path += `/outlet/${outletID}`;
    path += `/order`;

    //change document title
    useDocumentTitle(`Usee ${organizationID !== undefined ? " | " + t('org.pageTitle') : ""} ${outletID !== undefined ? " | " + t('outlet.pageTitle') : ""} | ${t('order.pageTitle')}`);

    // data, search
    const data = useSelector(state => state.order?.data);
    const search = useSelector(state => state.order?.search);
    const refetchDataTriggered = useSelector(state => state?.order?.refetchData || false);

    // if true the menu item is clicked and a redirect to page should perform
    const [isLoaded, setIsLoaded] = useState(false);

    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313"
    });

    const [isFirstTime, setIsFirstTime] = useState(true);
    // calendar range
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (!isFirstTime) {
            getOrders();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.pagination.size, data.pagination.page, data.pagination.sort, data.pagination.direction, search.organizationID, search.outletIDs, search.field, search.equipmentID, search.customerPhoneNumber, search.customerName, search.orderNumber, search.paymentMethods, search.statuses, search.isPaid, search.equipmentID, search.dateFrom, search.dateTo]);

    useEffect(() => {
        setIsFirstTime(false);
        getOrders();

        const fetchDataInterval = setInterval(() => {
            if (!isFirstTime && refetchDataTriggered) getOrders();
        }, 5000);

        // Clear interval on component unmount to avoid memory leaks
        return () => {
            clearInterval(fetchDataInterval);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetchDataTriggered])

    function getOrders() {
        orderApi.fetchAll((data.pagination?.count !== -1 ? { ...data.pagination } : { sort: data.pagination.sort, direction: data.pagination.direction }), search).then((r) => {
            if (r.data.code !== "EXCEPTION" && r.data.code !== "INVALID") {
                Promise.resolve()
                    .then(() => {
                        dispatch(updateOrderDataPagination({
                            ...data,
                            userAuth: r.data.returnobject.userAuth,
                            organization: r.data.returnobject.organization,
                            outlet: r.data.returnobject.outlet,
                            orders: r.data.returnobject.orders,
                            pagination: {
                                ...data.pagination,
                                count: r.data.returnobject.orders.totalPages,
                                size: r.data.returnobject.orders.size,
                                page: r.data.returnobject.orders.number
                            },
                            paymentMethods: r.data.returnobject.paymentMethods,
                            statuses: r.data.returnobject.statuses,
                            organizations: r.data.returnobject.organizations,
                            outlets: r.data.returnobject.outlets,
                            equipments: r.data.returnobject.equipments,
                            employees: r.data.returnobject.employees
                            // currency: r.data.returnobject.currency
                        }))
                    })
                    .then(() => {
                        if (isFirstTime)
                            dispatch(updateOrderSearch({
                                ...search,
                                organizationID: !hasRoleAdmin(r.data.returnobject.userAuth.roles) ? r.data.returnobject.organization.id : search.organizationID,
                                outletIDs: r.data.returnobject.outlets !== null && r.data.returnobject.outlets.length === 1 ? [r.data.returnobject.outlets[0].id] : search.outletIDs
                            }))
                    })
                    .then(() => { setIsLoaded(true) })
            }
        }).catch((e) => {
            // console.log(e);
        })
    }


    /**
     * 
     * @param {*} event 
     * @param {*} id 
     * @param {*} status 
     */
    function changeOrderStatus(event, id, status) {
        orderApi.updateOrderStatus(id, status).then((r) => {
            setAlertBox({
                isOpen: true,
                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
        }).catch((e) => {
            // console.log(e);
        })
    }

    /**
     * 
     * @param {*} event 
     * @param {*} id 
     * @param {*} status 
     */
    function changeItemStatus(event, orderID, id, status, index) {
        orderApi.updateOrderItemStatus(orderID, id, status, index).then((r) => {
            setAlertBox({
                isOpen: true,
                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
        }).catch((e) => {
            // console.log(e);
        })
    }


    /**
     * Update a specific search field valuer.
     * 
     * @param {string} field - The search field to alter its value.
     * @param {string} fieldValue - The new field value.
     */
    function handleSearchChange(field, fieldValue) {
        if (field === "paymentMethods") {
            if (fieldValue === undefined) fieldValue = null
            else fieldValue = fixItemInArray(search.paymentMethods, fieldValue)
        } else if (field === "statuses") {
            if (fieldValue === undefined) fieldValue = null
            else fieldValue = fixItemInArray(search.statuses, fieldValue)
        }
        if (fieldValue === "all") {
            fieldValue = []
        }

        dispatch(updateOrderSearch({
            ...search,
            [field]: fieldValue
        }))
    }

    /**
     * Function that handles the snackbar open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }


    /**
     * Function that handles the pagination information.
     * @property {int} page the page to be redirected
     */
    function handlePaginationChange(page) {
        dispatch(updateOrderDataPagination({
            ...data,
            "pagination.page": page
        }))
    }

    /**
     * Function that handles the pagination information.
     * @property {int} size the size the new numberOfRows to be requested
     */
    function handleRowsPerPageChange(size) {
        let pagination = data.pagination;
        pagination.size = size;
        pagination.page = 0;
        dispatch(updateOrderDataPagination({
            ...data,
            pagination: pagination
        }))
    }
    /**
     * Function that handles the sort information.
     * @property {int} size the size the new numberOfRows to be requested
     */
    function handleColumnSortChange(changedColumn, direction) {
        let pagination = data.pagination;
        pagination.sort = changedColumn;
        pagination.direction = direction;
        dispatch(updateOrderDataPagination({
            ...data,
            pagination: pagination
        }))
    }

    /**
     * Function that triggers the multi select input value change.
     * @param {*} event 
     */
    function onSelectSearchChange(event) {
        let field = event.target.value;
        dispatch(updateOrderDataPagination({
            ...search,
            field: field,
            customerName: "",
            customerPhoneNumber: "",
            orderNumber: "",
            [field]: ""
        }))
    }

    function onFilterChange(event) {
        // console.log(event)
        if (event !== null) {
            let field = event.field;
            let value = event.value;
            if (event.field === "isModifier") {
                if (value === t("itemOrModifier.item")) {
                    value = false;
                } else if (value === t("itemOrModifier.modifier")) {
                    value = true;
                }
            } else if (event.field === "equipment") {
                field = "equipmentID"
                value = data.equipments.find((eq) => eq.label === value)?.id;
            } else if (event.field === "paymentMethod") {
                field = "paymentMethods"
                if (value === undefined) value = []
                else value = fixItemInArray(search.paymentMethods, value)
            } else if (event.field === "statuses") {
                if (value === undefined) value = []
                else value = fixItemInArray(search.statuses, value)
            }

            if (value === "all") {
                value = []
            }
            dispatch(updateOrderSearch({
                ...search,
                [field]: value,
            }))
        } else {
            dispatch(updateOrderSearch(getDefaultSearchOrder()))
        }
    }

    // ===============================================================================RENDER

    if (!isLoaded) {
        return <CustomGrid role="main" id="order-list" sx={mainTag()}>
            <ListPageSkeleton />
        </CustomGrid>
    } else {
        /**
        * datatable columns.
        */
        const table_columns = [
            {
                name: "id",
                label: t('label.id'),
                options: {
                    filter: false,
                    sort: false,
                    empty: false,
                    viewColumns: true,
                    display: data.userAuth !== null && hasRoleAdmin(data.userAuth.roles) ? true : false,
                    customBodyRender: (value, tableMeta) => {
                        return value;//idFormatterLinkWithParent(value, tableMeta.tableData[tableMeta.rowIndex], path);
                    }
                }
            },
            {
                name: "orderNumber",
                label: t('order.orderNumber'),
                options: {
                    filter: true,
                    filterType: 'textField',
                    customFilterListOptions: {
                        render: v => `${t('order.orderNumber')}: ${v}`,
                    },
                    sort: true,
                    empty: false,
                    viewColumns: true,
                    customBodyRender: (value) => {
                        return fieldFormatter("#" + value);
                    }
                }
            },
            {
                name: "organization",
                label: t('org.pageTitle'),
                options: {
                    filter: false,
                    sort: false,
                    empty: false,
                    viewColumns: true,
                    display: data.userAuth !== null && hasRoleAdmin(data.userAuth.roles) ? true : false,
                    customBodyRender: (value, tableMeta) => {
                        return idFormatterLinkWithParentAndNameLabel(value, tableMeta.tableData[tableMeta.rowIndex], `/${getLanguageFromURL()}/organization`);
                    }
                }
            },
            {
                name: "outlet",
                label: t('outlet.pageTitle'),
                options: {
                    filter: false,
                    sort: false,
                    empty: false,
                    viewColumns: true,
                    display: data.userAuth !== null && hasRoleAdmin(data.userAuth.roles) ? true : false,
                    customBodyRender: (value, tableMeta) => {
                        return idFormatterLinkWithParentAndNameLabel(value, tableMeta.tableData[tableMeta.rowIndex], `/${getLanguageFromURL()}/outlet`);
                    }
                }
            },
            {
                name: "equipment",
                label: `${t('equipment.pageTitle')} ${t('label.label')}`,
                options: {
                    filter: true,
                    filterType: 'select',
                    customFilterListOptions: {
                        render: v => `${t('equipment.pageTitle')} ${t('label.label')}: ${v}`,
                    },
                    filterOptions: {
                        names: data.equipments?.map((eq) => eq.label)
                    },
                    sort: false,
                    empty: false,
                    viewColumns: true,
                    customBodyRender: (value, tableMeta) => {
                        return idFormatterLinkWithParentAndNameLabelEquipment(value, `/${getLanguageFromURL()}/equipment`);
                    }
                }
            },
            {
                name: "employee",
                label: `${t('employee.pageTitle')} ${t('label.name')}`,
                options: {
                    filter: false,
                    sort: false,
                    empty: false,
                    viewColumns: true,
                    customBodyRender: (value, tableMeta) => {
                        return idFormatterLinkWithParentAndNameLabel(value, tableMeta.tableData[tableMeta.rowIndex], `/${getLanguageFromURL()}/employee`);
                    }
                }
            },
            {
                name: "customer",
                label: t('customer.pageTitle'),
                options: {
                    filter: true,
                    filterType: 'custom',
                    customFilterListOptions: {
                        render: v => `${t(`order.${search.field}`)}}: ${v}`,
                    },
                    filterOptions: {
                        display: () => {
                            return (
                                <Grid container>
                                    <Grid item xs="auto">
                                        <CustomObjectSelect
                                            name="field"
                                            required={false}
                                            defaultValue={search.field}
                                            options={[{ id: "customerName", field: t("order.customerName") }, { id: "customerPhoneNumber", field: t("order.customerPhoneNumber") }]}
                                            onChange={onSelectSearchChange}
                                            field="field"
                                        />
                                    </Grid>

                                    <Grid item xs="auto">
                                        <CustomTextField
                                            type="search"
                                            name={search.field}
                                            id={search.field}
                                            variant="filled"
                                            label={t('actions.search') + " by " + t(`order.${search.field}`)}
                                            value={search[search.field] !== "" ? search[search.field] : ""}
                                            required={false}
                                            onChange={(event) =>
                                                dispatch(updateOrderSearch({
                                                    ...search,
                                                    [search.field]: event.target.value
                                                }))}
                                        />
                                    </Grid>
                                </Grid>
                            )
                        },
                    },
                    sort: true,
                    empty: false,
                    viewColumns: true,
                    customBodyRender: (value, tableMeta) => {
                        return idFormatterLinkWithParentAndNameLabel(value, tableMeta.tableData[tableMeta.rowIndex], `/${getLanguageFromURL()}/customer`);
                    }
                }
            },
            {
                name: "persons",
                label: t('order.persons'),
                options: {
                    filter: false,
                    sort: true,
                    empty: false,
                    viewColumns: true,
                    customBodyRender: (value, tableMeta) => {
                        return fieldFormatter(value);
                    }
                }
            },
            {
                name: "paymentMethod",
                label: t('order.paid'),
                options: {
                    filter: true,
                    filterType: 'custom',
                    customFilterListOptions: {
                        render: v => `${t(`order.payment`)}: ${v}`,
                    },
                    setFilterChipProps: (colIdx, colName, data) => {
                        return {
                            label: `${colName}: ${data}`,
                        }
                    },
                    filterList: search.paymentMethods,
                    filterOptions: {
                        display: () => {
                            return (
                                <CustomFieldset
                                    label={t('order.payment')}
                                    children={<>
                                        <Stack direction="row" sx={{ padding: "10px", width: "calc(100% - 40px)", alignItems: "center" }}>
                                            <Grid container gap={1} sx={mainTagSearchItemOrderContainer()}>
                                                <Grid item xs="auto">
                                                    <CustomIconButton
                                                        keyValue={`btn-search-all-payment`}
                                                        onClick={(event) => handleSearchChange("paymentMethods", "all")}
                                                        icon={<AllOutIcon {...getOrderButtonIcon()} />}
                                                        iconSx={mainTagSearchItemOrderStatusIcon()}
                                                        label={t('order.actions.all')}
                                                        sx={orderButton()}
                                                        variant='outlined'
                                                        backgroundColor="#dcf1f7"
                                                        isFocused={search.paymentMethods.length === 0}
                                                    />
                                                </Grid>

                                                <Grid item xs="auto">
                                                    <CustomIconButton
                                                        keyValue={`btn-search-cash`}
                                                        onClick={(event) => handleSearchChange("paymentMethods", "cash")}
                                                        icon={<FontAwesomeIcon key={"icon-cash-search"} icon={faMoneyBill1Wave} sx={getOrderButtonIcon()} />}
                                                        iconSx={{ ...mainTagSearchItemOrderStatusIcon() }}
                                                        label={t('order.actions.paymentMethod.cash')}
                                                        sx={orderButton()}
                                                        variant='outlined'
                                                        backgroundColor="#dcf1f7"
                                                        isFocused={search.paymentMethods && search.paymentMethods.includes("cash")}
                                                    />
                                                </Grid>

                                                <Grid item xs="auto">
                                                    <CustomIconButton
                                                        keyValue={`btn-search-card`}
                                                        onClick={(event) => handleSearchChange("paymentMethods", "card")}
                                                        icon={<FontAwesomeIcon key={"icon-card-search"} icon={faCreditCard} sx={getOrderButtonIcon()} />}
                                                        iconSx={{ ...mainTagSearchItemOrderStatusIcon(), color: "cornflowerblue" }}
                                                        label={t('order.actions.paymentMethod.card')}
                                                        sx={orderButton()}
                                                        variant='outlined'
                                                        backgroundColor="#dcf1f7"
                                                        isFocused={search.paymentMethods && search.paymentMethods.includes("card")}
                                                    />
                                                </Grid>

                                                <Grid item xs="auto">
                                                    <CustomIconButton
                                                        keyValue={`btn-search-none`}
                                                        onClick={(event) => handleSearchChange("paymentMethods", "none")}
                                                        icon={<BlockIcon {...getOrderButtonIcon()} />}
                                                        iconSx={{ ...mainTagSearchItemOrderStatusIcon(), color: "red" }}
                                                        label={t('order.actions.paymentMethod.none')}
                                                        sx={orderButton()}
                                                        variant='outlined'
                                                        backgroundColor="#dcf1f7"
                                                        isFocused={search.paymentMethods && search.paymentMethods.includes("none")}
                                                    />
                                                </Grid>

                                                <Grid item xs="auto">
                                                    <CustomCheckbox
                                                        name="order.paid"
                                                        value={search.isPaid}
                                                        onChange={(event) =>
                                                            dispatch(updateOrderSearch({
                                                                ...search,
                                                                isPaid: event.target.checked
                                                            }))}
                                                        label={t('order.paid')}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Stack>
                                    </>
                                    }
                                />
                            )
                        }
                    },
                    sort: false,
                    empty: false,
                    viewColumns: true,
                    customBodyRender: (value, tableMeta) => {
                        let rowData = tableMeta.rowData;
                        let isPaid = rowData != null && rowData[18];
                        if (value === "cash")
                            return <FontAwesomeIcon key={"icon-cash-" + tableMeta.rowIndex} icon={faMoneyBill1Wave} aria-hidden="true" color={isPaid ? "green" : "red"} />;
                        else if (value === "card")
                            return <FontAwesomeIcon key={"icon-card-" + tableMeta.rowIndex} icon={faCreditCard} aria-hidden="true" color={isPaid ? "green" : "red"} />;
                        else if (!isPaid)
                            return <FontAwesomeIcon key={"icon-card-" + tableMeta.rowIndex} icon={faExclamationCircle} aria-hidden="true" color="red" />;
                        return t(`outlet.settings.payment.${value}`);
                        // TODO: include formatter paid for color green or red, paymentMethod: card or cash icon or text?
                        // if paid===true -> type icon else unpaid
                        // <FontAwesomeIcon key={tooltipID + "-icon-" + id} icon={FaMoneyBill1Wave} aria-hidden="true" />
                        // <FontAwesomeIcon key={tooltipID + "-icon-" + id} icon={faCreditCard} aria-hidden="true" />
                        // <FontAwesomeIcon icon={faOctagonExclamation} />
                    }
                }
            },
            {
                name: "totalAmmount",
                label: t('order.totalAmount'),
                options: {
                    filter: false,
                    sort: true,
                    empty: false,
                    viewColumns: true,
                    customBodyRender: (value, tableMeta, extraText) => {
                        return `${parseFloat(value).toFixed(2)} ${data.currency}`;
                    }
                }
            },
            {
                name: "loyaltyPointsEarned",
                label: t('order.loyaltyPointsEarned'),
                options: {
                    filter: false,
                    sort: true,
                    empty: false,
                    viewColumns: true,
                    customBodyRender: (value, tableMeta) => {
                        return fieldFormatter(value + " " + t('order.points'));
                    }
                }
            },
            {
                name: "cart",
                label: t('order.cart'),
                options: {
                    filter: false,
                    sort: false,
                    empty: false,
                    viewColumns: data.userAuth !== null && hasRoleAdmin(data.userAuth.roles) ? true : false,
                    display: data.userAuth !== null && hasRoleAdmin(data.userAuth.roles) ? true : false,
                    customBodyRender: (value, tableMeta) => {
                        return JSON.stringify(value);
                    }
                }
            },
            {
                name: "cart",
                label: "",
                options: {
                    display: false,
                    filter: false,
                    sort: false,
                    empty: true,
                    viewColumns: false,
                }
            },
            {
                name: "comments",
                label: t('order.comments'),
                options: {
                    filter: false,
                    sort: false,
                    empty: true,
                    viewColumns: true,
                }
            },
            {
                name: "allergies",
                label: t('order.allergies'),
                options: {
                    filter: false,
                    sort: false,
                    empty: true,
                    viewColumns: true,
                    customBodyRender: (value, tableMeta) => {
                        let allergies = [];
                        value?.map((allergy) => {
                            allergies.push(fieldFormatter(allergy));
                        })
                        return allergies;
                    }
                }
            },
            {
                name: "status",
                label: t('order.status'),
                options: {
                    filter: true,
                    filterType: 'custom',
                    customFilterListOptions: {
                        render: v => `${t('label.status')}: ${v}`,
                    },
                    setFilterChipProps: (colIdx, colName, data) => {
                        return {
                            label: `${colName}: ${data}`,
                        }
                    },
                    filterList: search.statuses,
                    filterOptions: {
                        display: () => {
                            return (
                                <CustomFieldset
                                    label={t('order.status')}
                                    children={<>
                                        <Stack direction="row" sx={{ padding: "10px", width: "calc(100% - 40px)", alignItems: "center" }}>
                                            <Grid container gap={1} sx={mainTagSearchItemOrderContainer()}>
                                                <Grid item xs="auto">
                                                    <CustomIconButton
                                                        keyValue={`btn-search-all`}
                                                        onClick={(event) => handleSearchChange("statuses", "all")}
                                                        icon={<AllOutIcon {...getOrderButtonIcon()} />}
                                                        iconSx={mainTagSearchItemOrderStatusIcon()}
                                                        label={t('order.actions.all')}
                                                        sx={orderButton()}
                                                        variant='outlined'
                                                        backgroundColor="#dcf1f7"
                                                        isFocused={search.statuses.length === 0}
                                                    />
                                                </Grid>

                                                <Grid item xs="auto">
                                                    <CustomIconButton
                                                        keyValue={`btn-search-pending`}
                                                        onClick={(event) => handleSearchChange("statuses", "PENDING")}
                                                        icon={<ScheduleIcon {...getOrderButtonIcon()} />}
                                                        iconSx={{ ...mainTagSearchItemOrderStatusIcon(), color: "orange" }}
                                                        label={t('order.actions.status.pending')}
                                                        sx={orderButton()}
                                                        variant='outlined'
                                                        backgroundColor="#dcf1f7"
                                                        isFocused={search.statuses && search.statuses.includes("PENDING")}
                                                    />
                                                </Grid>

                                                <Grid itemxs="auto">
                                                    <CustomIconButton
                                                        keyValue={`btn-search-working`}
                                                        onClick={(event) => handleSearchChange("statuses", "WORKING")}
                                                        icon={<FontAwesomeIcon key={"icon-search-working"} icon={faHands} sx={getOrderButtonIcon()} />}
                                                        iconSx={{ ...mainTagSearchItemOrderStatusIcon(), color: "cornflowerblue" }}
                                                        label={t('order.actions.status.working')}
                                                        sx={orderButton()}
                                                        variant='outlined'
                                                        backgroundColor="#dcf1f7"
                                                        isFocused={search.statuses && search.statuses.includes("WORKING")}
                                                    />
                                                </Grid>

                                                <Grid item xs="auto">
                                                    <CustomIconButton
                                                        keyValue={`btn-search-done`}
                                                        onClick={(event) => handleSearchChange("statuses", "DONE")}
                                                        icon={<DoneIcon {...getOrderButtonIcon()} />}
                                                        iconSx={{ ...mainTagSearchItemOrderStatusIcon(), color: "green" }}
                                                        label={t('order.actions.status.done')}
                                                        sx={orderButton()}
                                                        variant='outlined'
                                                        backgroundColor="#dcf1f7"
                                                        isFocused={search.statuses && search.statuses.includes("DONE")}
                                                    />
                                                </Grid>

                                                <Grid item xs="auto">
                                                    <CustomIconButton
                                                        keyValue={`btn-search-cancelled`}
                                                        onClick={(event) => handleSearchChange("statuses", "CANCELED")}
                                                        icon={<BlockIcon {...getOrderButtonIcon()} />}
                                                        iconSx={{ ...mainTagSearchItemOrderStatusIcon(), color: "red" }}
                                                        label={t('order.actions.status.cancelled')}
                                                        sx={orderButton()}
                                                        variant='outlined'
                                                        backgroundColor="#dcf1f7"
                                                        isFocused={search.statuses && search.statuses.includes("CANCELED")}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Stack>
                                    </>
                                    }
                                />
                            )
                        }
                    },
                    sort: true,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return fieldFormatter(value, {
                            background: getThemeColor()[getTheme()]["actions"][`${value}`] + "4d",
                            color: getThemeColor()[getTheme()]["actions"][`${value}`],
                        });
                    }
                }
            },
            {
                name: "",
                label: t('actions.label'),
                options: {
                    filter: false,
                    sort: false,
                    empty: true,
                    viewColumns: true,
                    customBodyRender: (value, tableMeta) => {
                        return orderActionFormatter(t, value,
                            tableMeta.tableData[tableMeta.rowIndex],
                            tableMeta.rowIndex,
                            0,
                            tableMeta.tableData[tableMeta.rowIndex][0],//order id
                            6,
                            18,
                            orderActionCellButtonsFormatter(changeOrderStatus, tableMeta.tableData[tableMeta.rowIndex], 15),
                            true,
                        );
                    },
                    setCellProps: () => ({ className: "click action" })
                }
            },
            {
                name: "dateCreated",
                label: t('label.dateCreated'),
                options: {
                    filter: true,
                    filterType: "custom",
                    filterOptions: {
                        display: () => {
                            return (
                                <>
                                    <Grid container gap={2} sx={mainTagSearchItemOrderContainer()} wrap="flex-wrap">
                                        <Grid item xs={4.5} sm="auto">
                                            <CustomIconButton
                                                keyValue={`btn-search-date`}
                                                onClick={() => setOpen(!open)}
                                                icon={<CalendarMonthIcon />}
                                                iconSx={mainTagSearchItemOrderStatusIcon()}
                                                sx={{ ...mainTagSearchItemOrderStatusIcon(), borderRadius: "10px" }}
                                            />
                                            <CustomDialogBasedOnScreenChildren
                                                isOpen={open}
                                                handleClose={() => setOpen(false)}
                                                handleOpen={() => setOpen(true)}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                actionLabel={t('actions.ok')}
                                                handleAction={() => setOpen(false)}
                                                children={
                                                    <>
                                                        <CustomMuiDateTimeRangePickerStore
                                                            search={search}
                                                            dispatchedDateRangeUpdate={(newSearch) => dispatch(updateOrderSearch(newSearch))}
                                                        />
                                                    </>
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            )
                        }
                    },
                    sort: true,
                    empty: false,
                    viewColumns: true,
                    customBodyRender: (value, tableMeta) => {
                        // return moment( new Date(value));
                        return dateFormatter(value, tableMeta.tableData[tableMeta.rowIndex], null, getOrderDateTimeFormatter());
                    }
                }
            },
            {
                name: "paid",
                options: {
                    filter: false,
                    sort: false,
                    empty: false,
                    display: false,
                    viewColumns: true,
                }
            },
            {
                name: "cart",
                label: t('actions.label'),
                options: {
                    filter: false,
                    sort: false,
                    empty: true,
                    customBodyRender: (value, tableMeta) => {
                        return actionFormatter(value,
                            tableMeta.tableData[tableMeta.rowIndex],
                            tableMeta.rowIndex,
                            0,
                            null,
                            null,
                            null,
                            2,
                            path,
                            data.showLabel ? t('actions.edit') : undefined,
                            undefined,
                            undefined
                        )
                    },
                    setCellProps: () => ({ className: "click action" })
                }
            }
        ];

        const table_inner_columns = [
            {
                name: "id",
                label: t('label.id'),
                options: {
                    filter: false,
                    sort: false,
                    empty: false,
                    display: data.userAuth !== null && hasRoleAdmin(data.userAuth.roles) ? true : false,
                    // customBodyRender: (value, tableMeta) => {
                    //     return idFormatterLinkWithParent(value, tableMeta.tableData[tableMeta.rowIndex], path);
                    // }
                }
            },
            {
                name: "quantity",
                label: t('order.item.quantity'),
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, extraText) => {
                        return fieldFormatter(value);
                    }
                }
            },
            {
                name: "name",
                label: t('order.item.name'),
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta, extraText) => {
                        return value;
                    }
                }
            },
            {
                name: "notes",
                label: t('order.item.notes'),
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta, extraText) => {
                        return fieldFormatter(value);;
                    }
                }
            },
            {
                name: "price",
                label: t('order.item.price'),
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta, extraText) => {
                        return `${parseFloat(value).toFixed(2)} ${data.currency}`;
                    }
                }
            },
            // {
            //     name: "pvcRecyclingTax",
            //     label: t('order.item.pvcRecyclingTax'),
            //     options: {
            //         filter: false,
            //         sort: false,
            //     },
            //     customBodyRender: (value, tableMeta, extraText) => {
            //         return fieldFormatter(value);
            //     }
            // },
            {
                name: "",
                label: t('order.item.modifiers'),
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, extraText) => {
                        let modifiers = [];
                        tableMeta.tableData[tableMeta.rowIndex].modifiers.map((modifier) =>
                            modifiers.push(fieldFormatter(modifier.name))
                        )

                        return <>{modifiers}</>
                    }
                }
            },
            {
                name: "done",
                label: t('order.item.done'),
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta) => {
                        if (value)
                            return <DoneIcon sx={{ color: "green" }} />;
                        else
                            return <CloseIcon sx={{ color: "red" }} />
                    }
                }
            },
            {
                name: "",
                label: t('actions.label'),
                options: {
                    filter: false,
                    sort: false,
                    empty: true,
                    customBodyRender: (value, tableMeta) => {
                        return orderActionFormatter(t, value,
                            tableMeta.tableData[tableMeta.rowIndex],
                            tableMeta.rowIndex,
                            0,
                            tableMeta.tableData[tableMeta.rowIndex][0],//order id
                            6,//based on object parsed
                            5,//based on object parsed
                            orderActionCellItemButtonsFormatter(changeItemStatus, tableMeta.tableData[tableMeta.rowIndex][4], tableMeta.rowIndex),
                            false,
                            false
                        );
                    },
                    customHeadRender: (columnMeta, updateDirection) => (
                        <TableCell style={{ textAlign: 'center' }}>
                            {columnMeta.label}
                        </TableCell>
                    ),
                    setCellProps: () => ({ className: "click action" })
                }
            },
        ];
        return (
            <CustomGrid role="main" id="order-list" sx={mainTag()}>
                {outletID !== undefined && organizationID !== undefined &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomBreadcrumbMultiParent
                            parents={[
                                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                                { name: t('outlet.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet` },
                                { name: data.outlet.name, url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet/${outletID}` }
                            ]}
                            instanceName={t('order.title')}
                        />
                    </CustomGrid>
                }

                {outletID === undefined && organizationID !== undefined &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` }
                            ]}
                            instanceName={t('order.title')}
                        />
                    </CustomGrid>
                }

                <Grid item xs={12} sm={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={7} md={10}>
                            <CustomGrid sx={mainTagTitle()}>
                                <Grid item xs="auto">
                                    <CustomRefreshButton onClick={getOrders} marginTop="-1%" />
                                </Grid>
                                <Grid item xs="auto">
                                    <CustomTitle
                                        title={t('order.title')}
                                    // subtitle={t('order.subtitle')}
                                    />
                                </Grid>
                            </CustomGrid>
                        </Grid>
                        <Grid item xs={12} sm={5} md={2}>
                            <Button variant="text"
                                onClick={() => navigate(`${path}/add`)}
                                sx={pageAddButton()}
                            >
                                {t('actions.add') + " " + t('order.plain')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Stack direction="row" sx={{ ...mainTagSearch(), textAlign: "right" }}>
                        <Grid container gap={1} xs={12} sm={12}>
                            {(data.userAuth !== null && hasRoleAdmin(data.userAuth.roles)) &&
                                <Grid item xs="auto">
                                    <CustomObjectSelect
                                        name="organizationID"
                                        required={true}
                                        defaultValue={search.organizationID}
                                        labelID="organizationID" label={t('org.pageTitle')}
                                        options={data.organizations}
                                        onChange={(event) =>
                                            dispatch(updateOrderSearch({
                                                ...search,
                                                organizationID: event.target.value
                                            }))}
                                        sx={mainTagSearchItem()}
                                        field="name"
                                    />
                                </Grid>
                            }
                            {(search.organizationID !== "" && data.outlets !== null && data.outlets.size > 0) &&
                                <Grid item xs="auto">
                                    <CustomObjectSelect
                                        name="outletID"
                                        required={true}
                                        isMultiple={true}
                                        defaultValue={search.outletIDs}
                                        labelID="organizationID" label={t('outlet.pageTitle')}
                                        options={data.outlets}
                                        onChange={(event) =>
                                            dispatch(updateOrderSearch({
                                                ...search,
                                                outletIDs: [event.target.value]
                                            }))}
                                        sx={mainTagSearchItem()}
                                        field="name"
                                    />
                                </Grid>
                            }

                            {(search.organizationID !== "" && data.outlets !== null && data.equipments !== null && data.equipments.size > 0) &&
                                <Grid item xs="auto">
                                    <CustomObjectSelect
                                        name="equipmentID"
                                        required={true}
                                        defaultValue={search.equipmentID}
                                        labelID="equipmentID" label={t('equipment.pageTitle')}
                                        options={[{ id: "", name: "all" }, ...data.equipments]}
                                        onChange={(event) =>
                                            dispatch(updateOrderSearch({
                                                ...search,
                                                equipmentID: event.target.value
                                            }))}
                                        sx={mainTagSearchItem()}
                                        field="name"
                                    />
                                </Grid>
                            }
                        </Grid>
                    </Stack>
                </Grid>

                {(search.organizationID !== "" && search.outletIDs !== null) &&
                    <CustomGrid sx={mainAreaTable()} container={false}>
                        <CustomExpandablePageableTable
                            instances={data.orders}
                            emptyMessage={t('table.noData') + t('actions.tableCreateNewLink') + t('order.pageTitle')}
                            table_columns={table_columns}
                            addPageUrl={`${path}/add`}
                            keyField="id"
                            defaultSorted={{ name: data.pagination.sort, direction: data.pagination.direction }}
                            pagination={data.pagination}
                            handlePaginationChange={handlePaginationChange}
                            handleRowsPerPageChange={handleRowsPerPageChange}
                            handleColumnSortChange={handleColumnSortChange}
                            filter={true}
                            onFilterChange={onFilterChange}
                            extraOptions={{
                                renderExpandableRow: (rowData, rowMeta) => {
                                    let data = (rowData[12] !== undefined && rowData[12] !== null) && rowData[12]?.map(item => ({
                                        ...item,
                                        orderID: rowData[0]//include order id for actions field
                                    }));
                                    return (
                                        <TableRow>
                                            <TableCell colSpan={rowData?.length + 1}>
                                                {rowData[12]?.length > 0 &&
                                                    <CustomTable
                                                        instances={data}
                                                        emptyMessage={t('modifierList.empty')}
                                                        table_columns={table_inner_columns}
                                                        keyField="id"
                                                        hasCleanStyle={true}
                                                    />
                                                }
                                            </TableCell>
                                        </TableRow>
                                    );
                                }
                            }}
                        />
                    </CustomGrid>
                }

                {alertBox.isOpen &&
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={3000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        handleSnackbarOpen={handleSnackbarState} />
                }
            </CustomGrid>
        );
    }

}

export default OrderListComponent; 