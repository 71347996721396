import { Box, Grid } from "@mui/material";
import DropFloorItem from "components/Table/draggable/floor/DropFloorItem";
import { Image, Stage } from "react-konva";
import { Layer } from "recharts";
import RenderFloorShape from "./RenderFloorShape";

/**
 * The FloorSectionsBody, that display the floor planning sections toolbar.
 * 
 * @returnsa a custom grid
 * 
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
const FloorSectionsBody = ({
    backgroundCanvas,
    selectedSection,
    setSelectedSection,
    selectedObject,
    setSelectedObject,
    tablesOptions,
    shapeOptions,
    dimensions,
    checkDeselect,
    layerRef,
    containerRef,
    addTableHandler
}) => {

    return (
        <Grid
            item
            sx={{
                mt: "0.5em",

                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Box
                elevation={0}
                sx={{
                    background: "transparent",
                    width: { md: "70%", sm: "90%", xs: "98%" },
                    height: "100%",
                }}
            >
                {addTableHandler &&
                    <DropFloorItem
                        onDrop={(type, shape, offset) =>
                            addTableHandler(type, shape, offset)
                        }
                        children={
                            <div
                                id='new-container'
                                ref={containerRef}
                                // onDragOver={(event) => event.preventDefault()}
                                // onDrop={(event) => addTableHandler(event)}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    position: "relative",
                                }}
                            >
                                {/* <div
                            style={{
                                position: "absolute",
                                right: 10,
                                top: 10,
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px",
                                zIndex: 25,
                            }}
                            >
                            <button
                                style={{ fontWeight: "bold", cursor: "pointer" }}
                                onClick={() =>
                                setStage((x) => {
                                    return {
                                    ...x,
                                    scale: x.scale + 0.02,
                                    };
                                })
                                }
                            >
                                +
                            </button>
                            <button
                                style={{ fontWeight: "bold", cursor: "pointer" }}
                                onClick={() =>
                                setStage((x) => {
                                    return {
                                    ...x,
                                    scale: x.scale - 0.02,
                                    };
                                })
                                }
                            >
                                -
                            </button>
                            <button
                                style={{ fontWeight: "bold", cursor: "pointer" }}
                                onClick={() =>
                                setStage((x) => {
                                    return {
                                    ...x,
                                    scale: 1,
                                    };
                                })
                                }
                            >
                                x
                            </button>
                            </div> */}

                                <Stage
                                    // onWheel={handleWheel}
                                    // scaleX={stage.scale}
                                    // scaleY={stage.scale}
                                    // x={stage.x}
                                    // y={stage.y}
                                    width={dimensions?.width}
                                    height={dimensions?.height}
                                    //height={(2 / 3) * dimensions.width}
                                    onMouseDown={checkDeselect}
                                    onTouchStart={checkDeselect}
                                    // style={{
                                    //   backgroundImage: selectedSection.background
                                    //     ? typeof selectedSection.background === "object"
                                    //       ? `url(${URL.createObjectURL(selectedSection.background)})`
                                    //       : `url(${selectedSection.background})`
                                    //     : "unset",
                                    //   backgroundSize: "cover",
                                    //   backgroundRepeat: "no-repeat",
                                    // }}
                                    style={{
                                        background:
                                            "linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7))",
                                    }}
                                >
                                    <Layer ref={layerRef}>
                                        {selectedSection?.background && (
                                            <Image
                                                listening={false}
                                                image={
                                                    typeof selectedSection?.background === "object"
                                                        ? selectedSection?.backgroundCanvas
                                                        : backgroundCanvas
                                                }
                                                backgroundSize='cover'
                                                width={dimensions?.width}
                                                //height={(2 / 3) * dimensions.width}
                                                height={dimensions?.height}
                                            />
                                        )}

                                        {selectedSection?.objects.map((item, i) => {
                                            return (
                                                <RenderFloorShape
                                                    key={i}
                                                    renderComponent={(props) => {
                                                        let shape = shapeOptions?.find(
                                                            (to) => to.shape === item.shape
                                                        );
                                                        if (shape) {
                                                            return shape.renderComponent(props);
                                                        }

                                                        return tablesOptions
                                                            ?.find((to) => to.shape === item.shape)
                                                            .renderComponent(props);
                                                    }}
                                                    layerRef={layerRef}
                                                    rotation={item.canvas.rotation}
                                                    shapeWidth={{
                                                        width: item.canvas.size.width,
                                                        height: item.canvas.size.height,
                                                    }}
                                                    shapePosition={{
                                                        x: item.canvas.position.left,
                                                        y: item.canvas.position.top,
                                                    }}
                                                    type={item.type}
                                                    shape={item.shape}
                                                    name={item.name}
                                                    minCover={item.minCover}
                                                    maxCover={item.maxCover}
                                                    fill={item.fill}
                                                    borderColor={item.borderColor}
                                                    availableOnline={item.availableOnline}
                                                    id={item.id}
                                                    isSelected={item.id === selectedObject}
                                                    onSelect={() => {
                                                        setSelectedObject(item.id);
                                                    }}
                                                    onChange={(newAttrs) => {
                                                        setSelectedSection((r) => {
                                                            return {
                                                                ...r,
                                                                objects: r.objects.map((o) => {
                                                                    if (o.id === item.id) {
                                                                        o.canvas = {
                                                                            ...o.canvas,
                                                                            ...newAttrs,
                                                                        };
                                                                    }
                                                                    return o;
                                                                }),
                                                            };
                                                        });
                                                    }}
                                                />
                                            );
                                        })}
                                    </Layer>
                                </Stage>
                            </div>
                        }
                    />
                }
            </Box>
        </Grid>
    );
};

export default FloorSectionsBody;