import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { TabContext, TabPanel } from "@mui/lab";
import { AppBar, Badge, Card, CardActionArea, Grid, IconButton, Stack, Toolbar, Typography, useMediaQuery } from "@mui/material";
import pdaApi from "api/pda";
import { NoMenus } from "assets/lottie/LottieAnimations";
import { TablePayIcon } from "assets/svg/SvgIcons";
import CustomIconButton from "components/Button/CustomIconButton";
import CustomClosePayPdaDialog from "components/Dialogs/pay/CustomClosePayPdaDialog";
import CustomEquipmentPdaDialog from "components/Dialogs/pda/CustomEquipmentPdaDialog";
import CustomGrid from "components/Grid/CustomGrid";
import PdaSkeleton from "components/Loading/PdaSkeleton";
import CustomSnackbar from "components/Snackbar/CustomSnackbar";
import CustomTabs from "components/Tab/CustomTabs";
import CustomTypography from "components/Typography/CustomTypography";
import { lottieDefaultOptions } from "constants/animations/lottieOptions";
import { getDefaultDataPda } from "constants/defaultObjects";
import { getOutletPayClose } from "constants/fieldProperties";
import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { updatePdaAll, updatePdaData, updatePdaTabValue } from "store/pda/pdaActions";
import { getPDAEmptyPromptTextStyle, mainArea, mainTagMobile, orderAddTextStyle, payCloseAllIconButtonStyle, sectionsAppBarBoxAppBar, sectionsAppBarBoxAppBarSticky } from "themes/defaultThemes";
import { dateTimeFormatterFilter } from "utils/functions";
import { getLanguageFromURL } from "utils/language";
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The PDADisplayComponent, that allow the user to view the PDA main screen.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function PDADisplayComponent() {
    const isMobile = useMediaQuery('(max-width:468px)');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isLoaded, setIsLoaded] = useState(false);

    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313",
        action: undefined,
    });
    const data = useSelector(state => state?.pda?.data || getDefaultDataPda());
    const tabValue = useSelector(state => state?.pda?.tabValue);

    // State to hold the currently selected date
    const [searchDate, setSearchDate] = useState(dateTimeFormatterFilter());

    // The initializeData function now outside of useEffect
    const initializeData = async () => {
        const fetchedData = await getPDADisplay();
        if (fetchedData) { // Ensure fetchedData is not undefined
            if (fetchedData.initialTabValue === null)
                dispatch(updatePdaData(fetchedData.content));
            else
                dispatch(updatePdaAll(fetchedData.content, fetchedData.initialTabValue)); // Set the data state with the fetched content
        }
    };

    useEffect(() => {
        initializeData();

        const fetchDataInterval = setInterval(() => {
            initializeData(); // Fetch data
        }, 5000); // 5 seconds interval

        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            setIsSticky(currentScrollPos > 80);
        };

        window.addEventListener('scroll', handleScroll);

        // Clear interval on component unmount to avoid memory leaks
        return () => {
            window.removeEventListener('scroll', handleScroll);
            clearInterval(fetchDataInterval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //change document title
    useDocumentTitle(`Usee ${t('display.pda.label')}`);

    const [selectedEquipment, setSelectedEquipment] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogPayOpen, setDialogPayOpen] = useState(false);

    /**
     * The rest endpoint to get the pickup display settings.
     */
    async function getPDADisplay() {
        return new Promise(resolve => {
            setTimeout(() => {
                pdaApi.fetchAll(searchDate).then((r) => {
                    const mySections = r.data.returnobject.mySections || [];
                    // Simulate fetching data
                    const simulatedData = {
                        content: {
                            ...data,
                            userAuth: r.data.returnobject.userAuth,
                            organization: r.data.returnobject.organization,
                            outlet: r.data.returnobject.outlet,
                            equipments: r.data.returnobject.equipments,
                            mySections: mySections,
                            equipmentUnpaidOrders: r.data.returnobject.equipmentUnpaidOrders,
                            requests: r.data.returnobject.requests,
                            isFirstTime: false
                        },
                        initialTabValue: (mySections.length > 0 && tabValue === "") ? mySections[0]?.id : null
                    };
                    resolve(simulatedData);
                }, 1000); // Simulates a delay, e.g., from a network request

                setIsLoaded(true);
            })
        }).catch((e) => {
        });
    }
    /**
    * Function that handles the snackbar open or close state.
    * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
    */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }

    function handleChange(newValue) {
        dispatch(updatePdaTabValue(newValue));
    };

    function handleEquipmentSelect(equipment) {
        // Handle equipment selection logic here
        Promise.resolve(equipment).then(() => {
            setSelectedEquipment(equipment.id);
        }).then(() => {
            setDialogOpen(true);
        })
    }

    function handleCloseDialog() {
        // Handle closing the dialog
        setDialogOpen(false);
    }


    function handleClosePayDialog() {
        // Handle closing the dialog
        setDialogPayOpen(false);
    }

    const handleCreateOrder = () => {
        navigate(`/${getLanguageFromURL()}/pda/order/add`);
    };

    // ==============================  SECTIONS TABS SCROLL to STICKY
    const [isSticky, setIsSticky] = useState(false);

    // ============================== DATE CHANGER
    /**
     * Helper function to format a date to the ISO format: `yyyy-MM-DDTHH:mm:ss.SSSZ`.
     * @param {Date} date - The date object to format.
     * @returns {string} - The formatted date string.
     */
    const formatDate = (date) => {
        return date.toISOString();
    };

    /**
     * Handles clicking the left arrow to navigate to the previous date.
     * Updates the `searchDate` state and triggers a data fetch.
     */
    const handlePreviousDate = () => {
        const newDate = new Date(searchDate);
        newDate.setDate(newDate.getDate() - 1); // Subtract one day
        setSearchDate(newDate); // Update state
        getPDADisplay(newDate); // Fetch data for the new date
    };

    /**
     * Handles clicking the right arrow to navigate to the next date.
     * Updates the `searchDate` state and triggers a data fetch.
     */
    const handleNextDate = () => {
        const newDate = new Date(searchDate);
        newDate.setDate(newDate.getDate() + 1); // Add one day
        setSearchDate(newDate); // Update state
        getPDADisplay(newDate); // Fetch data for the new date
    };

    /**
     * Checks if the currently selected date (`searchDate`) is today.
     * Used to disable the right arrow button.
     * @returns {boolean} - True if `searchDate` is today, false otherwise.
     */
    const isToday = () => {
        const today = new Date();
        return searchDate.substring(0, 10) === today.toDateString(); // Compare date strings
    };

    // ===============================================================================RENDER
    if (!isLoaded) {
        return (
            <CustomGrid role="main" id={"pda-display"} sx={{ ...mainTagMobile(), padding: "0px 0px 0px 5px" }}>
                <PdaSkeleton isMobile={isMobile} />
            </CustomGrid>
        );
    } else {
        return (
            <CustomGrid role="main" sx={mainTagMobile()}>
                <CustomGrid sx={{ ...mainArea(), background: "transparent", boxShadow: "0", border: "none", padding: 0, textAlign: "center" }} container={false}>
                    {data.mySections?.length > 0 &&
                        <TabContext value={tabValue}>
                            <AppBar position={isSticky ? 'sticky' : 'static'} style={isSticky ? sectionsAppBarBoxAppBarSticky() : sectionsAppBarBoxAppBar()}>
                                <CustomTabs
                                    initValue={tabValue}
                                    instances={
                                        data.mySections?.map((section) => (
                                            { name: section.name, value: section.id }
                                        ))}
                                    setValue={handleChange}
                                    sx={{
                                        width: 'calc(100% - 50px)',
                                        "& .MuiTabs-indicator": {
                                            // backgroundColor: navigationMenuSelectedOptionFontColor
                                        },
                                    }}
                                    tabSx={{
                                        // fontSize: navigationMenuFontSize,
                                        borderRadius: "10px",
                                        fontWeight: "bold",
                                        margin: "10px 1px",
                                        textTransform: "initial",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        // color: tabValue === index ? "#4394fb" : "initial",
                                        // backgroundColor: tabValue === index ? "#4394fb1c" : "initial",
                                        ":hover": {
                                            backgroundColor: "#4394fb1c",
                                            color: "#4394fb"
                                        },
                                        ":focus": {
                                            backgroundColor: "#4394fb1c",
                                            color: "#4394fb"
                                        },
                                        ":active": {
                                            backgroundColor: "#4394fb1c",
                                            color: "#4394fb"
                                        },
                                        ":selected": {
                                            backgroundColor: "#4394fb1c",
                                            color: "#4394fb"
                                        },
                                    }}
                                    scrollButtons={true}
                                />


                                {data.equipmentUnpaidOrders.length > 0 &&
                                    <IconButton aria-label="close-all"
                                        sx={payCloseAllIconButtonStyle()}
                                        onClick={() => setDialogPayOpen(true)}>
                                        <TablePayIcon {...getOutletPayClose()} />
                                        <Badge
                                            badgeContent={data.equipmentUnpaidOrders.length}
                                            color={"error"}
                                            sx={{ position: 'absolute', top: "10px", right: "15px" }} />
                                    </IconButton>
                                }
                            </AppBar>

                            <AppBar position={isSticky ? 'sticky' : 'static'} style={isSticky ? sectionsAppBarBoxAppBarSticky() : sectionsAppBarBoxAppBar()}>
                                <Toolbar style={{ justifyContent: 'center' }}>
                                    {/* Left Arrow: Navigate to the previous date */}
                                    <IconButton edge="start" color="#00000099" onClick={handlePreviousDate}>
                                        <ArrowBackIosIcon sx={{ width: "20px" }} />
                                    </IconButton>

                                    {/* Centered Current Date: Displays the selected date */}
                                    <Typography variant="h6" style={{ textAlign: 'center', width: "150px" }}>
                                        {searchDate.substring(0, 10)} {/* Display date in a readable format */}
                                    </Typography>

                                    {/* Right Arrow: Navigate to the next date, disabled if the selected date is today */}
                                    <IconButton
                                        edge="end"
                                        color="#00000099"
                                        onClick={handleNextDate}
                                        disabled={isToday()} // Disable button if `searchDate` is today
                                    >
                                        <ArrowForwardIosIcon sx={{ width: "20px" }} />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>

                            {data.mySections?.map((section) => (
                                <TabPanel key={section.id} value={section.id} sx={{ p: 3 }}>
                                    <Grid item xs={12} md={12}>
                                        <Stack direction="row" sx={{ placeContent: 'center' }}>
                                            <Grid container gap={2}>
                                                {section.equipments.map((equipmentId) => {
                                                    const equipment = data.equipments.find((eq) => eq.id === equipmentId);
                                                    const unpaidOrder = data.equipmentUnpaidOrders.find((eq) => eq.id === equipmentId);
                                                    const newRequests = data.requests.find((request) => request.id === equipmentId);

                                                    return (
                                                        <Grid key={equipmentId}>
                                                            <Card
                                                                variant="outlined"
                                                                onClick={() => handleEquipmentSelect(equipment)}
                                                                sx={{
                                                                    width: 'fit-content',
                                                                    borderRadius: "20px",
                                                                    boxShadow:
                                                                        '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
                                                                }}
                                                            >
                                                                <CardActionArea
                                                                    sx={{
                                                                        minWidth: '80px',
                                                                        minHeight: '80px',
                                                                        padding: '0 5px',
                                                                        position: 'relative', // Set position relative for Badge
                                                                    }}
                                                                >
                                                                    <CustomTypography variant="body2" text={equipment?.label} sx={{ ...orderAddTextStyle(), textAlign: "center" }} />

                                                                    <Badge
                                                                        badgeContent={(unpaidOrder?.unavailable || 0) + (newRequests?.numberOfNewRequests || 0)}
                                                                        color={(unpaidOrder?.unavailable || 0) + (newRequests?.numberOfNewRequests || 0) > 0 ? "error" : "primary"}
                                                                        sx={{ position: 'absolute', top: "15px", right: "15px" }} />
                                                                </CardActionArea>
                                                            </Card>
                                                        </Grid>
                                                    )
                                                })}

                                            </Grid>
                                        </Stack>
                                    </Grid>
                                </TabPanel>
                            ))}

                        </TabContext>
                    }


                    {(data.mySections === null || data.mySections?.length === 0) &&
                        <>
                            <Grid item xs={12} sx={{ padding: "10px 0px!important", textAlign: "-webkit-center" }}>
                                <Lottie
                                    {...lottieDefaultOptions}
                                    animationData={NoMenus}
                                />
                            </Grid>

                            <CustomTypography
                                variant="body2"
                                text={t('pda.emptyMySections')}
                                sx={{ ...getPDAEmptyPromptTextStyle(), textAlign: "center" }}
                            />

                            <CustomIconButton
                                variant={"outlined"}
                                label={t('pda.emptyMySectionsButton')}
                                onClick={() => navigate(`/${getLanguageFromURL()}/mysections`)}
                                sx={{ position: "absolute", right: isMobile ? "10px" : "initial", textTransform: "none", textAlign: "center", marginTop: "20px" }}
                            />

                        </>
                    }

                    {dialogOpen &&
                        <CustomEquipmentPdaDialog
                            isOpen={dialogOpen}
                            setIsOpen={setDialogOpen}
                            onClose={handleCloseDialog}
                            equipmentID={selectedEquipment}
                            equipmentLabel={data.equipments?.find((eq) => eq.id === selectedEquipment).label}
                            handleCreateOrder={handleCreateOrder}
                            organizationID={data.organization?.id}
                            outletID={data.outlet?.id}
                            setAlertBox={setAlertBox}
                            currency={data.currency}
                            unpaidOrderCount={
                                data.equipmentUnpaidOrders?.find((eq) => eq.id === selectedEquipment)?.unavailable || 0
                            }
                            pendingRequestsCount={
                                data.requests?.find((request) => request.id === selectedEquipment)?.numberOfNewRequests || 0
                            }
                            getPDADisplay={initializeData}
                            userAuth={data.userAuth}
                        />
                    }

                    {dialogPayOpen &&
                        <CustomClosePayPdaDialog
                            isOpen={dialogPayOpen}
                            setIsOpen={setDialogOpen}
                            onClose={handleClosePayDialog}
                            organizationID={data.organization?.id}
                            outletID={data.outlet?.id}
                            setAlertBox={setAlertBox}
                            currency={data.currency}
                            userAuth={data.userAuth}
                        />
                    }
                </CustomGrid>

                {
                    alertBox.isOpen &&
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={3000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        action={alertBox.action}
                        handleSnackbarOpen={handleSnackbarState} />
                }
            </CustomGrid>
        )
    }
}

export default PDADisplayComponent;