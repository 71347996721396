import {
    ArrowBack as ArrowBackIcon,
    Save as SaveIcon,
} from "@mui/icons-material";
import { Fab, Grid } from "@mui/material";
import feedbackApi from "api/feedback";
import CustomAddEditBreadcrumbMultiParent from "components/Breadcrumb/CustomAddEditBreadcrumbMultiParent";
import CustomButton from "components/Button/CustomButton";
import CustomIconButton from "components/Button/CustomIconButton";
import AddUpdateCategoryDialog from "components/Dialogs/feedback/AddUpdateCategoryDialog";
import AddUpdateFeedbackDialog from "components/Dialogs/feedback/AddUpdateFeedbackDialog";
import ConnectionDialog from "components/Dialogs/feedback/FeedbackFormConnectionDialog";
import CustomGrid from "components/Grid/CustomGrid";
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomSnackbar from "components/Snackbar/CustomSnackbar";
import CustomTitleBoldSubtitle from "components/Title/CustomTitleBoldSubtitle";
import { getFeedbackFormDefaultObject } from "constants/defaultObjects";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-phone-input-2/lib/material.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    fabStyle,
    formSaveButton,
    mainArea,
    mainTag,
    mainTagBreadcrumb,
    mainTagTitle,
} from "themes/defaultThemes";
import { formIsValid } from "utils/form-validation";
import { getLanguageFromURL } from "utils/language";
import useDocumentTitle from "utils/useDocumentTitle";

import FeedbackFormMainGrid from "components/Grid/feedback/FeedbackFormMainGrid";
import FeedbackFormPreview from "components/Preview/feedback/FeedbackFormPreview";
import IPhone from "components/Preview/IPhone";

/**
 * The FeedbackFormAddEditComponent that triggers the creation of a
 * new feedback form or the modification of an existing one.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function FeedbackFormAddEditComponent() {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const { organizationID } = useParams();
    const { outletID } = useParams();
    const { feedbackID } = useParams();
    let path = `/${getLanguageFromURL()}`;
    if (organizationID !== undefined) path += `/organization/${organizationID}`;
    if (outletID !== undefined) path += `/outlet/${outletID}`;
    path += `/feedback`;

    // to render DOM based on rest api call status, if true the rest api call completed
    const [isLoaded, setIsLoaded] = useState(false);

    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313",
    });

    /**
     * @type {object}
     *
     * @property {boolean} isAdd to define if requested an edit or an add of a feedbackForm
     * @property {object} userAuth the authenticated user infromation
     * @property {object} feedbackForm an empty object if add or the selected feedbackForm to be editted
     * @property {object} isError list of indication of empty form required field
     *                           after submit to present error message to the
     *                           fields/attributes with true value
     */
    const [data, setData] = useState({
        userAuth: null,
        isAdd: feedbackID === undefined,
        organization: {
            id: organizationID,
            name: "",
        },
        outlet: {
            id: outletID,
            name: "",
        },
        connections: [],
        outlets: [],
        feedbackForm: {},
        organizations: [],
        // error messages per field
        isError: {
            name: "",
        },
    });

    const [addFeedbackDialog, setAddFeedbackDialog] = useState(false);
    const [feedbackCategoryDialog, setFeedbackCategoryDialog] = useState({
        active: false,
        id: null,
    });
    const [showConnectionsDialog, setShowConnectionsDialog] = useState(false);

    //change document title
    useDocumentTitle(
        `Usee  ${organizationID !== undefined ? " | " + t("org.pageTitle") : ""} ${outletID !== undefined ? " | " + t("outlet.pageTitle") : ""
        } | ${t("feedback.title")} | ${data.isAdd ? t("actions.add") : t("actions.edit")
        }`
    );

    useEffect(() => {
        getFeedbackForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * The rest endpoint to get the feedbackForm default (add) or current (edit) information.
     */
    function getFeedbackForm() {
        if (data.isAdd) {
            // if (!location.state?.data) {
            //     navigate(path);
            // }
            feedbackApi
                .fetchNew(organizationID, outletID)
                .then((r) => {
                    setData({
                        ...data,
                        userAuth: r.data.returnobject.userAuth,
                        feedbackForm: {
                            ...getFeedbackFormDefaultObject(),
                            ...location.state.data,
                        },
                        connections: r.data.returnobject.connections,
                        outlets: r.data.returnobject.outlets,
                        outlet: r.data.returnobject.outlet,
                        organizations: r.data.returnobject.organizations,
                        organization: r.data.returnobject.organization,
                    });
                    setIsLoaded(true);
                })
                .catch((e) => {
                    // console.log(e);
                });
        } else {
            feedbackApi
                .fetchOne(organizationID, outletID, feedbackID)
                .then((r) => {
                    setData({
                        ...data,
                        userAuth: r.data.returnobject.userAuth,
                        feedbackForm: r.data.returnobject.feedbackForm,
                        connections: r.data.returnobject.connections,
                        outlets: r.data.returnobject.outlets,
                        outlet: r.data.returnobject.outlet,
                        organizations: r.data.returnobject.organizations,
                        organization: r.data.returnobject.organization,
                    });
                    setIsLoaded(true);
                })
                .catch((e) => {
                    // console.log(e);
                });
        }
    }

    /**
     * Gets called when the user clicks on the save button, and triggers
     * the creation of the new feeedback form.
     */
    function addFeedbackForm() {
        if (formValidation()) {
            let feedbackForm = { ...data.feedbackForm };
            //let categories = [...feedbackForm.categories];
            // feedbackForm.categories = categories.map((f) => {
            //   f.id = undefined;
            //   let questionsCopy = [...f.questions];
            //   f.questions = questionsCopy.map((q) => {
            //     q.id = undefined;
            //     return q;
            //   });
            //   return f;
            // });
            feedbackForm.organizationID = organizationID;
            feedbackApi
                .create(feedbackForm)
                .then((r) => {
                    setAlertBox({
                        isOpen: true,
                        message:
                            "message" in r.data
                                ? r.data.message
                                : t("errorPages.somethingWentWrong"),
                        backgroundColor: r.data.code === "SUCCESS" ? "#177910" : "#a71313",
                    });
                    navigate(path);
                })
                .catch((e) => {
                    // console.log(e)
                });
        }
    }

    /**
     * Gets called when the user clicks on the save button, and triggers
     * the edit of the selected feeedback form.
     */
    function editFeedbackForm() {
        if (formValidation()) {
            let feedbackForm = data.feedbackForm;
            feedbackApi
                .update(feedbackForm)
                .then((r) => {
                    setAlertBox({
                        isOpen: true,
                        message:
                            "message" in r.data
                                ? r.data.message
                                : t("errorPages.somethingWentWrong"),
                        backgroundColor: r.data.code === "SUCCESS" ? "#177910" : "#a71313",
                    });
                })
                .catch((e) => {
                    // console.log(e)
                });
        }
    }

    /**
     * Function that create error messages for each required field that are not filled out.
     *
     * @returns the object with the error messages for each form field
     */
    function createErrorMessages() {
        let isError = { ...data.isError };
        isError.name =
            data.feedbackForm.name.en.length < 1 ? t("form.requiredField") : "";
        setData({
            ...data,
            isError,
        });
        return isError;
    }

    /**
     * Function that triggers form validation.
     * @returns true if form is Valid
     */
    function formValidation() {
        let isError = createErrorMessages();
        if (formIsValid(isError)) {
            return true;
        } else {
            return false;
        }
    }
    /**
     * Function that handles the snackbar open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen,
        });
    }

    // ===============================================================================RENDER

    if ((!isLoaded && !data.isAdd) || data.feedbackForm == null) {
        return <LoadingSkeleton lines={9} />;
    } else {
        return (
            <CustomGrid
                role='main'
                id={"feedback-" + data.isAdd ? "add" : "edit"}
                sx={mainTag()}
            >
                {outletID !== undefined && organizationID !== undefined && (
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                {
                                    name: t("org.pageTitle"),
                                    url: `/${getLanguageFromURL()}/organization`,
                                },
                                {
                                    name: data.organization.name,
                                    url: `/${getLanguageFromURL()}/organization/${organizationID}`,
                                },
                                {
                                    name: t("outlet.pageTitle"),
                                    url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet`,
                                },
                                {
                                    name: data.outlet.name,
                                    url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet/${outletID}`,
                                },
                                {
                                    name: t("feedback.pageTitle"),
                                    url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet/${outletID}/feedback`,
                                },
                            ]}
                            instanceName={
                                data.feedbackForm !== null ? data.feedbackForm.name.en : ""
                            }
                        />
                    </CustomGrid>
                )}
                {outletID === undefined && organizationID !== undefined && (
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                {
                                    name: t("org.pageTitle"),
                                    url: `/${getLanguageFromURL()}/organization`,
                                },
                                {
                                    name: data.organization.name,
                                    url: `/${getLanguageFromURL()}/organization/${organizationID}`,
                                },
                                {
                                    name: t("feedback.pageTitle"),
                                    url: `/${getLanguageFromURL()}/organization/${organizationID}/feedback`,
                                },
                            ]}
                            instanceName={
                                data.feedbackForm !== null ? data.feedbackForm.name.en : ""
                            }
                        />
                    </CustomGrid>
                )}

                <CustomGrid sx={mainTagTitle()}>
                    <CustomTitleBoldSubtitle
                        title={`${t("feedback.pageTitle")} | ${data.isAdd ? t("actions.add") : t("actions.edit")
                            }`}
                        subtitleBeforeText={
                            data.isAdd
                                ? t("actions.tableCreateNewLink") + t("feedback.title")
                                : t("feedback.title") + " "
                        }
                        subtitleboldText={
                            !data.isAdd &&
                            data.feedbackForm !== null &&
                            data.feedbackForm !== undefined &&
                            data.feedbackForm.name.en
                        }
                        subtitleAfterText={!data.isAdd ? " profile page" : ""}
                    />
                </CustomGrid>

                {/* header */}
                <CustomGrid sx={mainArea()} container={false}>
                    {/* TODO INCLUDE CODE */}
                    {/* getFeedbackFormCategoryDefaultObject() */}
                    {/* getFeedbackFormCategoryQuestionDefaultObject() */}
                    {/* getFeedbackFormCategoryQuestionkMultipleChoiceOptionDefaultObject() */}

                    {/* header */}
                    <Grid container justifyContent='flex-end' gap={2}>
                        <Grid item sx={{ flex: 1 }}>
                            <CustomButton
                                variant='contained'
                                keyValue='save'
                                label={t("feedback.header.back")}
                                sx={{
                                    // ...formSaveButton(),
                                    fontSize: "12px",
                                    px: "20px",
                                    textTransform: "none",
                                    "&:hover": {
                                        backgroundColor: "#2596be !important",
                                        color: "white",
                                    },
                                    marginTop: 0,
                                }}
                                startIcon={<ArrowBackIcon />}
                                onClick={() => {
                                    navigate(path);
                                }}
                            />
                        </Grid>

                        <CustomButton
                            variant='contained'
                            keyValue='save'
                            label={t("feedback.header.connections")}
                            sx={{
                                // ...formSaveButton(),
                                fontSize: "12px",
                                px: "20px",
                                textTransform: "none",
                                "&:hover": {
                                    backgroundColor: "#2596be !important",
                                    color: "white",
                                },
                                marginTop: 0,
                            }}
                            onClick={() => setShowConnectionsDialog(true)}
                        />
                        <ConnectionDialog
                            open={showConnectionsDialog}
                            onClose={() => setShowConnectionsDialog(false)}
                            attachedOutletIDs={data.feedbackForm?.attachedOutletIDs || []}
                            connectionsData={data.connections}
                            onSubmit={(attachedOutletIDs) => {
                                setData((d) => {
                                    return {
                                        ...d,
                                        feedbackForm: {
                                            ...d.feedbackForm,
                                            attachedOutletIDs: attachedOutletIDs,
                                        },
                                    };
                                });
                                setShowConnectionsDialog(false);
                            }}
                        />
                        <CustomButton
                            variant='contained'
                            keyValue='save'
                            label={t("feedback.header.edit")}
                            sx={{
                                // ...formSaveButton(),
                                fontSize: "12px",
                                px: "20px",
                                textTransform: "none",
                                "&:hover": {
                                    backgroundColor: "#2596be !important",
                                    color: "white",
                                },
                                marginTop: 0,
                            }}
                            onClick={() => setAddFeedbackDialog(true)}
                        />
                        <AddUpdateFeedbackDialog
                            open={addFeedbackDialog}
                            setOpen={setAddFeedbackDialog}
                            editData={data.feedbackForm}
                            onSubmit={(data) => {
                                setData((d) => {
                                    return {
                                        ...d,
                                        feedbackForm: {
                                            ...d.feedbackForm,
                                            ...data,
                                        },
                                    };
                                });
                                setAddFeedbackDialog(false);
                            }}
                        />
                        <CustomButton
                            variant='contained'
                            keyValue='save'
                            label={t("feedback.header.addCategory")}
                            sx={{
                                // ...formSaveButton(),
                                fontSize: "12px",
                                px: "20px",
                                textTransform: "none",
                                "&:hover": {
                                    backgroundColor: "#2596be !important",
                                    color: "white",
                                },
                                marginTop: 0,
                            }}
                            onClick={() => {
                                setFeedbackCategoryDialog({
                                    active: true,
                                    id: null,
                                });
                            }}
                        />
                        <AddUpdateCategoryDialog
                            open={feedbackCategoryDialog.active}
                            onClose={() => {
                                setFeedbackCategoryDialog({
                                    active: false,
                                    id: null,
                                });
                            }}
                            onSubmit={(data) => {
                                setData((d) => {
                                    return {
                                        ...d,
                                        feedbackForm: {
                                            ...d.feedbackForm,
                                            categories: d.feedbackForm?.categories
                                                ? [...d.feedbackForm.categories, data]
                                                : [data],
                                        },
                                    };
                                });
                                setFeedbackCategoryDialog({
                                    active: false,
                                    id: null,
                                });
                            }}
                        />
                        <CustomIconButton
                            variant='outlined'
                            keyValue='save'
                            onClick={data.isAdd ? addFeedbackForm : editFeedbackForm}
                            label={t("feedback.header.save")}
                            sx={{
                                ...formSaveButton(),
                                marginTop: 0,
                            }}
                        />
                    </Grid>

                    <Fab
                        color='primary'
                        aria-label='add'
                        sx={fabStyle()}
                        onClick={data.isAdd ? addFeedbackForm : editFeedbackForm}
                    >
                        <SaveIcon />
                    </Fab>
                </CustomGrid>

                {/* main area */}
                <Grid
                    container
                    gap={2}
                    sx={{
                        marginTop: "20px",
                    }}
                >
                    {/* main  */}
                    <Grid item sx={{ flex: 1, ...mainArea(), marginTop: 0 }}>
                        <CustomGrid container={false}>
                            <FeedbackFormMainGrid
                                categories={data?.feedbackForm?.categories || []}
                                updateCategories={(updatedCategories) => {
                                    setData((d) => {
                                        return {
                                            ...d,
                                            feedbackForm: {
                                                ...d.feedbackForm,
                                                categories: updatedCategories,
                                            },
                                        };
                                    });
                                }}
                            />
                        </CustomGrid>
                    </Grid>
                    <Grid item>
                        <IPhone>
                            <FeedbackFormPreview categories={data?.feedbackForm?.categories || []} />
                        </IPhone>
                    </Grid>
                </Grid>

                {alertBox.isOpen && (
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={3000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        handleSnackbarOpen={handleSnackbarState}
                    />
                )}
            </CustomGrid>
        );
    }
}

export default FeedbackFormAddEditComponent;
