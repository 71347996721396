import {
    Checkbox,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
} from "@mui/material";

function RatingMultipleChoice({ question, onChange }) {
    const onUpdate = (newValue) => {
        onChange({
            ...question,
            answer: newValue,
        });
    };

    const getValue = (val) => {
        if (question.allowMultipleChoices) {
            if (Array.isArray(val)) {
                return val;
            }
            return [];
        } else {
            if (typeof val === "string") {
                return val;
            }
            return "";
        }
    };

    return !question.allowMultipleChoices ? (
        <RadioGroup row aria-labelledby={question.question.en} name={question.id}>
            {question.choices.map((item, i) => (
                <FormControlLabel
                    key={i}
                    checked={getValue(question.answer) === item.optionValue.en}
                    onChange={(e) => onUpdate(item.optionValue.en)}
                    control={<Radio />}
                    label={item.optionValue.en}
                />
            ))}
        </RadioGroup>
    ) : (
        <Grid container>
            {question.choices.map((item, i) => (
                <Grid item key={i}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={getValue(question.answer).includes(
                                    item.optionValue.en
                                )}
                                onChange={(e) =>
                                    onUpdate(
                                        getValue(question.answer).includes(item.optionValue.en)
                                            ? getValue(question.answer).filter(
                                                (x) => x !== item.optionValue.en
                                            )
                                            : [...getValue(question.answer), item.optionValue.en]
                                                .length <= question.maxChoices
                                                ? [...getValue(question.answer), item.optionValue.en]
                                                : getValue(question.answer)
                                    )
                                }
                            />
                        }
                        label={item.optionValue.en}
                    />
                </Grid>
            ))}
        </Grid>
    );
}

export default RatingMultipleChoice;