import { request } from 'constants/alias';
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from 'utils/functions';
import updateFieldIfValid from 'utils/functions/updateFieldIfValid';
import updateFieldIfValidDate from 'utils/functions/updateFieldIfValidDate';
/**
 * The rest request which attempts to get an existing request information.
 * 
 * @param {*} lang TThe lang code for multilingual fields
 * @param {*} outletUrl The url of the loyalty outlet to be fetched
 * 
 * @returns an object with an attribute containing the current infromation of the loyalties 
 */
export async function fetchAll(pageable, search) {
    let data = {};
    updateFieldIfValid(data, "organizationID", search.organizationID);
    updateFieldIfValid(data, "outletID", search.outletID);
    updateFieldIfValid(data, "equipmentID", search.equipmentID);
    updateFieldIfValid(data, "customerName", search.customerName);
    updateFieldIfValid(data, "employeeName", search.employeeName);
    updateFieldIfValid(data, "equipmentLabel", search.equipmentLabel);
    updateFieldIfValid(data, "type", search.type);
    updateFieldIfValid(data, "status", search.status);
    updateFieldIfValid(data, "label", search.label);
    updateFieldIfValidDate(data, "dateFrom", search.dateFrom);
    updateFieldIfValidDate(data, "dateTo", search.dateTo);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}request`, pageable !== undefined ? { ...pageable, ...data } : data));
    else return await request.get(includeParamsToUrl(`${API}request`, pageable !== undefined ? pageable : {}));
}

export async function changeRequestStatus(requestID, status) {
    return await request.post(`${API}request/${requestID}/${status}`);
}
/**
 * The rest request which return required information to be presented on add new request page.
 * 
 * @returns an object with an attribute containing the requested information to be presented on request page
 */
export async function fetchNew() {
    return await request.get(includeParamsToUrl(`${API}request/add`));
}

/**
 * The rest request which attempts to create a new request.
 * 
 * @param {*} data A JSON object which contains new request infromation
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function create(data) {
    return await request.post(`${API}request`, data);
}
const requestApi = {
    fetchNew,
    fetchAll,
    create,
    changeRequestStatus
}

export default requestApi;