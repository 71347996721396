import { request } from 'constants/alias';
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from 'utils/functions';
import updateFieldIfValidList from 'utils/functions/updateFieldIfNonEmptyArray';
import updateFieldIfValid from 'utils/functions/updateFieldIfValid';
import updateFieldIfValidDate from 'utils/functions/updateFieldIfValidDate';

/**
 * The rest request which attempts to fetch analytic details for payment method chart. 
 * @param {*} search The filters for search.
 *
 * @returns an object with an attribute containing the analytic details  for payment method chart
 */
export async function paymentMethodPieChartDataHistoryBar(search) {
    let data = {};
    updateFieldIfValid(data, "organizationID", search.organizationID);
    updateFieldIfValid(data, "employeeID", search.employeeID);
    updateFieldIfValid(data, "forPDA", search.forPDA);
    updateFieldIfValidList(data, "outletIDs", search.outletIDs);
    updateFieldIfValidDate(data, "dateFrom", search.dateFrom, true);
    updateFieldIfValidDate(data, "dateTo", search.dateTo, true);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}analytics/payment-method/history/bar`, data));
    else return await request.get(includeParamsToUrl(`${API}analytics/payment-method/history/bar`));
}

/**
 * The rest request which attempts to fetch analytic details for payment method chart. 
 * @param {*} search The filters for search.
 *
 * @returns an object with an attribute containing the analytic details  for payment method chart
 */
export async function paymentMethodPieChartDataHistoryPie(search) {
    let data = {};
    updateFieldIfValid(data, "organizationID", search.organizationID);
    updateFieldIfValidList(data, "outletIDs", search.outletIDs);
    updateFieldIfValid(data, "employeeID", search.employeeID);
    updateFieldIfValidDate(data, "dateFrom", search.dateFrom, true);
    updateFieldIfValidDate(data, "dateTo", search.dateTo, true);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}analytics/payment-method/history/pie`, data));
    else return await request.get(includeParamsToUrl(`${API}analytics/payment-method/history/pie`));
}


/**
 * The rest request which attempts to fetch analytic details for payment method chart. 
 * @param {*} search The filters for search.
 *
 * @returns an object with an attribute containing the analytic details  for payment method chart
 */
export async function paymentMethodPieChartDataHistoryEmployee(search) {
    let data = {};
    updateFieldIfValid(data, "organizationID", search.organizationID);
    updateFieldIfValidList(data, "outletIDs", search.outletIDs);
    updateFieldIfValid(data, "employeeID", search.employeeID);
    updateFieldIfValidDate(data, "dateFrom", search.dateFrom, true);
    updateFieldIfValidDate(data, "dateTo", search.dateTo, true);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}analytics/payment-method/employee`, data));
    else return await request.get(includeParamsToUrl(`${API}analytics/payment-method/employee`));
}

/**
 * The rest request which attempts to fetch analytic details for payment method chart. 
 * @param {*} search The filters for search.
 *
 * @returns an object with an attribute containing the analytic details  for payment method chart
 */
export async function paymentMethodPieChartData(search) {
    let data = {};
    updateFieldIfValid(data, "organizationID", search.organizationID);
    updateFieldIfValidList(data, "outletIDs", search.outletIDs);
    updateFieldIfValidDate(data, "dateFrom", search.dateFrom, true);
    updateFieldIfValidDate(data, "dateTo", search.dateTo, true);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}analytics/payment-method/pie`, data));
    else return await request.get(includeParamsToUrl(`${API}analytics/payment-method/pie`));
}


/**
 * The rest request which attempts to fetch analytic details for cart (table). 
 * @param {*} search The filters for search.
 *
 * @returns an object with an attribute containing the analytic details for cart
 */
export async function getCartAnalytics(search) {
    let data = {};
    updateFieldIfValid(data, "organizationID", search.organizationID);
    updateFieldIfValidList(data, "outletIDs", search.outletIDs);
    updateFieldIfValidDate(data, "dateFrom", search.dateFrom, true);
    updateFieldIfValidDate(data, "dateTo", search.dateTo, true);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}analytics/cart`, data));
    else return await request.get(`${API}analytics/cart`);
}

/**
 * The rest request which attempts to fetch analytic details for payment method chart. 
 * @param {*} search The filters for search.
 *
 * @returns an object with an attribute containing the analytic details  for payment method chart
 */
export async function getRevenue(search) {
    let data = {};
    updateFieldIfValid(data, "organizationID", search.organizationID);
    updateFieldIfValidList(data, "outletIDs", search.outletIDs);
    updateFieldIfValidDate(data, "dateFrom", search.dateFrom, true);
    updateFieldIfValidDate(data, "dateTo", search.dateTo, true);
    updateFieldIfValid(data, "dateFormat", search.dateFormat);
    
    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}analytics/revenue`, data));
    else return await request.get(includeParamsToUrl(`${API}analytics/revenue`));
}

/**
 * The rest request which attempts to fetch analytic details for payment method chart. 
 * @param {*} search The filters for search.
 *
 * @returns an object with an attribute containing the analytic details  for payment method chart
 */
export async function fetchGeneral(search) {
    let data = {};
    updateFieldIfValid(data, "organizationID", search.organizationID);
    updateFieldIfValidList(data, "outletIDs", search.outletIDs);
    updateFieldIfValid(data, "employeeID", search.employeeID);
    updateFieldIfValidDate(data, "dateFrom", search.dateFrom, true);
    updateFieldIfValidDate(data, "dateTo", search.dateTo, true);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}analytics/general`, data));
    else return await request.get(includeParamsToUrl(`${API}analytics/general`));
}

/**
 * The rest request which attempts to fetch analytic details for order who method chart. 
 * @param {*} search The filters for search.
 *
 * @returns an object with an attribute containing the analytic details for order who method chart
 */
export async function fetchOrderAnalytics(search) {
    let data = {};
    updateFieldIfValid(data, "organizationID", search.organizationID);
    updateFieldIfValidList(data, "outletIDs", search.outletIDs);
    updateFieldIfValid(data, "employeeID", search.employeeID);
    updateFieldIfValidDate(data, "dateFrom", search.dateFrom, true);
    updateFieldIfValidDate(data, "dateTo", search.dateTo, true);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}analytics/order`, data));
    else return await request.get(includeParamsToUrl(`${API}analytics/order`));
}

const analyticsApi = {
    paymentMethodPieChartDataHistoryEmployee,
    paymentMethodPieChartDataHistoryPie,
    paymentMethodPieChartDataHistoryBar,
    paymentMethodPieChartData,
    getCartAnalytics,
    getRevenue,
    fetchGeneral,
    fetchOrderAnalytics
}

export default analyticsApi;