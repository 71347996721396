import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Box, Grid } from '@mui/material';
import notificationApi from 'api/notification';
import NotificationLoadingSkeleton from 'components/Loading/NotificationLoadingSkeleton';
import CustomMenu from 'components/Menu/CustomMenu';
import { getLoadingTime } from "constants/loadingTime";
import { AuthConsumer } from 'context/AuthContext';
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import AuthService from 'services/auth.service';
import { toggleMenuIcon } from 'themes/defaultThemes';
import { hasRoleForMySections } from 'utils/auth';
import { getLanguageFromURL } from "utils/language";
import LanguageSelector from '../Language/LanguageSelector';

/**
 * The MiniMenuComponent, that displays the mini menu.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function MiniMenuComponent({ action }) {
    const [userAuth, setUserAuth] = useState({
        roles: ["anonymous"]
    })
    const [notifications, setNotifications] = useState([]);
    const [setNfNumb] = useState(0);
    const [loadMoreFlag, setLoadMoreFlag] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        getNotifications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * The rest endpoint to get the notifications list.
     */
    function getNotifications() {
        notificationApi.fetchAll().then((r) => {
            setUserAuth(r.data.returnobject.userAuth);
            setNotifications(r.data.returnobject.notifications);
            setNfNumb(r.data.returnobject.notifications.content.reduce((acc, cur) => cur.seen === false ? ++acc : acc, 0));
        }).catch((e) => {
            // console.log(e);
        });
    }

    /* eslint-disable no-unused-vars */
    /*
     * Mark all notifications as have seen 
     */
    function notificationHaveSeen(notification) {
        if (notification.seen) return;

        let seenNotifications = [];
        let elements = document.getElementById('notifications').getElementsByClassName('nf-data');
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].getAttribute('data-seen') === 'false') {
                seenNotifications.push(elements[i].getAttribute('id'));
            }
        }

        notificationApi.seen(seenNotifications).then((r) => {
            if (r.data.code === "SUCCESS") {
                getNotifications();
            }
        }).catch((e) => {
            console.log(`${e.response.status} (${e.message}). Please contact the system administrator`);
        });
    };

    /**
     * Make all unread notifications as read
     */
    function notificationBatchRead() {
        let data = [];
        let liList = document.getElementById('nf-wrapper').querySelectorAll('li');
        for (let i = 0; i < liList.length - 1/*last one is the loading*/; i++) {
            if (!liList[i].classList.contains('read')) {
                data.push(liList[i].querySelectorAll('.nf-data')[0].getAttribute('id'));
            }
        }

        if (data.length === 0) return;

        notificationApi.batchRead(data).then((r) => {
            if (r.data.code === "SUCCESS") {
                getNotifications();
            }
        }).catch((e) => {
            console.log(`${e.response.status} (${e.message}). Please contact the system administrator`);
        })
    };

    /**
     * Load more notifications 
     */
    function notificationLoadMore(event) {
        if (!loadMoreFlag) return;

        let loadingHeight = 54; // the height of <li className=loading/> element
        let nfMain = document.getElementById('notifications').getElementsByTagName('main')[0];
        let nfMainUL = nfMain.getElementsByTagName('ul')[0];
        let loading = nfMainUL.querySelector('li.notification-loading');
        let nfMainHeight = nfMain.offsetHeight;
        let nfMainULHeight = nfMainUL.offsetHeight;
        let scrollTopHeight = Math.round(nfMain.scrollTop);

        // Change opacity according to scrollTop
        if ((nfMainULHeight - (scrollTopHeight + nfMainHeight)) <= loadingHeight) {
            let loadingComparison = loadingHeight - (nfMainULHeight - (scrollTopHeight + nfMainHeight));
            if (loadingComparison >= 0 && loadingComparison < 5) {
                loading.style.opacity = 0;
            } else if (loadingComparison >= 5 && loadingComparison < 10) {
                loading.style.opacity = 0.1;
            } else if (loadingComparison >= 10 && loadingComparison < 15) {
                loading.style.opacity = 0.2;
            } else if (loadingComparison >= 15 && loadingComparison < 20) {
                loading.style.opacity = 0.3;
            } else if (loadingComparison >= 20 && loadingComparison < 25) {
                loading.style.opacity = 0.4;
            } else if (loadingComparison >= 25 && loadingComparison < 30) {
                loading.style.opacity = 0.5;
            } else if (loadingComparison >= 30 && loadingComparison < 35) {
                loading.style.opacity = 0.6;
            } else if (loadingComparison >= 35 && loadingComparison < 40) {
                loading.style.opacity = 0.7;
            } else if (loadingComparison >= 40 && loadingComparison < 45) {
                loading.style.opacity = 0.8;
            } else if (loadingComparison >= 45 && loadingComparison < 50) {
                loading.style.opacity = 0.9;
            } else if (loadingComparison >= 50 && loadingComparison < 55) {
                loading.style.opacity = 1;
            } else {
                loading.style.opacity = 1;
            }
        }
        if ((scrollTopHeight + nfMainHeight) === nfMainULHeight) {
            notificationApi.fetchPrevious(notifications.content[notifications.numberOfElements - 1].id).then((r) => {
                if (r.data.code === "SUCCESS") {
                    if (r.data.returnobject !== "eonf" && r.data.returnobject.length > 0) {
                        setTimeout(() => {
                            removeLoading(); // Remove loading
                            let notifications = notifications;
                            notifications.content.push(...r.data.returnobject);//add extra notifications
                            notifications.numberOfElements = notifications.content.length;//update elements number
                            setNotifications(notifications);
                            setNfNumb(notifications.content.reduce((acc, cur) => cur.seen === false ? ++acc : acc, 0));
                        }, getLoadingTime());
                    } else {
                        removeLoading();
                        console.log("No more results to show");
                        setLoadMoreFlag(false);
                    }
                }
            }).catch((e) => {
                console.log(`${e.response.status} (${e.message}). Please contact the system administrator`);
            });
        }
    }

    /**
     * Make notification status as read
     * @param {type} event 
     * @param {boolean} flag The flag of the notification to be marked as read or not. If the value is `true` the notification will be marked as read.
     * @param {type} notificationID The id of the notification to be marked as read.
     */
    function readAndRedirect(event, flag, notificationID) {
        event.preventDefault();
        var self = event.currentTarget;
        var li = self.closest('li');
        var link = self.getAttribute('href');
        if (!li.classList.contains('read')) {
            read(event, flag, notificationID);
        }
        if (link !== '#!') {
            window.location.href = (link)
        }//TODO: check - make notifications box to stay open
    };

    /**
     * Make notification dismiss
     * @param {type} event 
     * @param {type} notificationID The id of the notification to be dismissed.
     */
    function dismiss(event, notificationID) {
        notificationApi.dismiss(notificationID).then((r) => {
            if (r.data.code === "SUCCESS") {
                getNotifications();
            }
        }).catch((e) => {
            // console.log(e);
        })
    };

    /**
     * Loading Animation for notification box.
     */
    function loadingHTML() {
        return <li className="notification-loading" key="n-loading"> +
            <NotificationLoadingSkeleton /> +
        </li>
    }

    /**
     * Update element class
     */
    function updateClass(read) {
        return read ? 'read' : "";
    }
    /* eslint-enable no-unused-vars */

    /**
     * Make notification status as read
     * @param {type} event 
     * @param {boolean} flag The flag of the notification to be marked as read or not. If the value is `true` the notification will be marked as read.
     * @param {type} notificationID The id of the notification to be marked as read.
     */
    function read(event, flag, notificationID) {
        notificationApi.read(notificationID, flag).then((r) => {
            if (r.data.code === "SUCCESS") {
                let li = event.target.closest('li');
                !li.classList.contains('read') ? li.classList.add('read') : li.classList.remove('read');
            }
        }).catch((e) => {
            // console.log(e);
        })
    };

    /**
     * Render empty notification list
     * @returns the html element showing the empty notification list.
     */
    function renderEmptyNotification(render) {
        var nfRow = <li className="empty">
            <a href="#!" className="tr">
                <div className="nf-title-wrapper">
                    <div className="nf-title">No notifications available</div>
                </div>
            </a>
        </li>;
        if (render)
            document.getElementById('notifications').getElementsByTagName('main')[0].getElementsByTagName('ul')[0].innerHTML = nfRow;
        return nfRow;
    }

    /**
     * Remove loading animation
     */
    function removeLoading() {
        let nfMainUL = document.getElementById('notifications').getElementsByTagName('main')[0].getElementsByTagName('ul')[0];
        const li = nfMainUL.querySelector('li.notification-loading')
        li.remove();
        // Show the empty notification message if the user dismiss them all
        if (document.getElementById('nf-wrapper').getElementsByTagName('li').length === 0) {
            renderEmptyNotification(true);
        }
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    function handleMenu(event) {
        setAnchorEl(event.currentTarget);
    };

    function handleClose() {
        setAnchorEl(null);
    };

    function handleLogout() {
        AuthService.logout();
        // history.push('/login'); // Redirect to login page after logout
    };

    return (
        //  HEADER - Mini menu -->
        <AuthConsumer>
            {({ roles }) => (
                <>
                    <Grid container spacing={2} sx={{ paddingRight: "20px" }}>
                        <Box sx={{ flexGrow: 1 }} /> {/* Spacing to push the following items to the right */}

                        <Grid item xs={"auto"} key="account">
                            <AccountCircleIcon sx={toggleMenuIcon()} onClick={handleMenu} />
                            <CustomMenu
                                id="account-menu"
                                anchorEl={anchorEl}
                                open={open}
                                handleClose={handleClose}
                                handleLogout={handleLogout}
                                handleViewProfile={() => navigate(`/${getLanguageFromURL()}/user/${userAuth.id}`)}
                                handleViewMySections={() => navigate(`/${getLanguageFromURL()}/mysections`)}
                                viewMySections={hasRoleForMySections(roles)}
                            />
                        </Grid>

                        {/* <Grid item xs={"auto"}>
                            <DarkModeToggleButton isLightModeEnabled={localStorage.getItem("theme") === "light"} />
                        </Grid> */}

                        <Grid item xs={"auto"} key="lang">
                            <LanguageSelector />
                        </Grid>
                    </Grid>
                </>
            )}
        </AuthConsumer>
    );
}

export default withTranslation()(MiniMenuComponent);