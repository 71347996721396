import { request } from 'constants/alias';
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from 'utils/functions';
import updateFieldIfValid from 'utils/functions/updateFieldIfValid';

/**
 * The rest request which attempts to get the poss list from the database.
 * @param {*} search The filters for search.
 * 
 * @returns an object with an attribute containing the poss list
 */
export async function fetchAll(search) {
    let data = {};
    updateFieldIfValid(data, "organizationID", search.organizationID);
    updateFieldIfValid(data, "outletID", search.outletID);
    updateFieldIfValid(data, "friendlyName", search.friendlyName);
    updateFieldIfValid(data, "terminalId", search.terminalId);
    updateFieldIfValid(data, "provider", search.provider);
    updateFieldIfValid(data, "type", search.type);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}pos`, data));
    else return await request.get(includeParamsToUrl(`${API}pos`));
}

/**
 * The rest request which return required information to be presented on add new pos page.
 * @param {*} organizationID The id of the pos organization id
 * @param {*} outletID The id of the pos outlet id
 * 
 * @returns an object with an attribute containing the requested information to be presented on the pos page
 */
export async function fetchNew(organizationID, outletID) {
    let data = {};
    updateFieldIfValid(data, "organizationID", organizationID);
    updateFieldIfValid(data, "outletID", outletID);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}pos/add`, data));
    else return await request.get(includeParamsToUrl(`${API}pos/add`));
}

/**
 * The rest request which attempts to get an existing pos information.
 * @param {*} organizationID The id of the pos organization id
 * @param {*} outletID The id of the pos outlet id
 * @param {*} id The id of the pos to be fetched
 * 
 * @returns an object with an attribute containing the current infromation of the pos
 */
export async function fetchOne(organizationID, outletID, id) {
    let data = {};
    updateFieldIfValid(data, "organizationID", organizationID);
    updateFieldIfValid(data, "outletID", outletID);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}pos/${id}`, data));
    else return await request.get(includeParamsToUrl(`${API}pos/${id}`));
}

/**
 * The rest request which attempts to create a new pos.
 * @param {*} data A JSON object which contains new pos infromation
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function create(data) {
    return await request.post(`${API}pos`, data);
}

/**
 * The rest request which attempts to update an existing pos.
 * @param {*} data A JSON object which contains the updated infromation of the pos
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function update(data) {
    return await request.put(`${API}pos`, data);
}

export async function exist(friendlyName) {
    let data = { friendlyName: friendlyName };
    return await request.get(includeParamsToUrl(`${API}pos/exist`, data));
}

export async function syncDevices(outletID) {
    let data = { };
    updateFieldIfValid(data, "outletID", outletID);

    return await request.get(includeParamsToUrl(`${API}viva/pos/devices/sync`, data));
}

/**
 * The rest request to delete a specific pos.
 * @param {*} id The id of the pos to be deleted
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function deleteById(id) {
    return await request.delete(`${API}pos/${id}`);
}

const posApi = {
    fetchAll,
    fetchNew,
    fetchOne,
    create,
    update,
    deleteById,
    exist,
    syncDevices
}

export default posApi;