import React, {Component} from "react";
import {Builder, Query, Utils as QbUtils,} from "@react-awesome-query-builder/ui";
import "@react-awesome-query-builder/ui/css/styles.css";
import {MuiConfig} from "@react-awesome-query-builder/mui";
import "assets/scss/query-builder.scss";

const InitialConfig = MuiConfig;

const settings = {
    ...InitialConfig.settings,
    showNot: false,//Show NOT together with AND/OR?
    showLock: false,//Show "Lock" switch for rules and groups to make them read-only ("admin mode")
    showErrorMessage: true,//how error message in QueryBuilder if validateValue() in field config returns false
    canLeaveEmptyGroup: true,// leave empty group after deletion of rules
    canLeaveEmptyCase: true,//automatically remove empty case
    shouldCreateEmptyGroup: false,//automatically add 1 empty rule into new group
};
// Const that provides the default config
const config = {
    ...InitialConfig,
    settings: settings
}
const queryValue = {id: QbUtils.uuid(), type: "group"};


class DemoQueryBuilder extends Component {
    constructor(props) {
        super(props);

        /**
         * @type {object}
         * @property {object} dialog the information related to the crawl country dialog
         * @property {object} options the information related to the highchart map chart options
         */
        this.state = {
            fields: this.props.fields,
            tree: QbUtils.loadTree(this.props.queryTree, config),
            config: config
        };
        // console.log("log tree", QbUtils.getTree(this.state.tree));
        // console.log("fields", this.state.fields)
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        // Only update state if props have changed
        const fieldsChanged = nextProps.fields !== prevState.fields;
        const treeChanged = nextProps.queryTree !== prevState.tree;

        if (fieldsChanged || treeChanged) {
            // console.log("new Tree", QbUtils.getTree(nextProps.queryTree));
            return {
                fields: nextProps.fields,
                tree: QbUtils.loadTree(nextProps.queryTree, prevState.config),
            };
        }
        return null; // No changes
    }

    render = () => (
        <div>
            <Query
                {...config}
                fields={this.state.fields}
                get_children={this.state.tree._list._tail.array["3"]?.["1"]}
                value={this.state.tree}
                onChange={this.onChange}
                renderBuilder={this.renderBuilder}
            />
        </div>
    )

    renderBuilder = (props) => (
        <div className="query-builder-container">
            <div className="query-builder">
                <Builder {...props} />
            </div>
        </div>
    )

    onChange = (immutableTree, config) => {
        this.setState({tree: immutableTree, config: config});
        this.props.handleQueryBuilderChange(immutableTree, QbUtils.isValidTree(immutableTree, config))
    }
}

export default DemoQueryBuilder;
