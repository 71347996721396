import { request } from 'constants/alias';
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from 'utils/functions';
import updateFieldIfBooleanValid from 'utils/functions/updateFieldIfBooleanValid';
import updateFieldIfValid from 'utils/functions/updateFieldIfValid';

/**
 * The rest request which attempts to get the items list from the database.
 * @param {object} pageable includes all pageable details (page, size, sort, direction)
 * @param {*} search The filters for search.
 * 
 * @returns an object with an attribute containing the items list
 */
export async function fetchAll(pageable, search) {
    let data = {};
    updateFieldIfValid(data, "organizationID", search.organizationID);
    updateFieldIfValid(data, "name", search.name);
    updateFieldIfBooleanValid(data, "allowMultipleSelection", search.allowMultipleSelection);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}modifier-list`, pageable !== undefined ? { ...pageable, ...data } : data));
    else return await request.get(includeParamsToUrl(`${API}modifier-list`, pageable !== undefined ? pageable : {}));
}

/**
 * The rest request which attempts to get the equipments list from the database.
 * @param {*} orgID The id of the modifier-list organization to be fetched
 * @param {object} pageable includes all pageable details (page, size, sort, direction)
 * 
 * @returns an object with an attribute containing the outlets list
 */
export async function fetchAllByOrganizationID(orgID, pageable) {
    let config = {
        baseURL: process.env.REACT_APP_AXIOSBASE,
        params: pageable !== undefined ? pageable : {}
    }

    return await request.get(`${API}organization/${orgID}/modifier-list`, config);
}

/**
 * The rest request which return required information to be presented on add new modifier-list page.
 * @param {*} organizationID The id of the modifier-list organization
 * @returns an object with an attribute containing the requested information to be presented on the modifier-list page
 */
export async function fetchNew(organizationID) {
    let data = {};
    updateFieldIfValid(data, "organizationID", organizationID);
    
    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}modifier-list/add`, data));
    else return await request.get(includeParamsToUrl(`${API}modifier-list/add`));
}

/**
 * The rest request which attempts to get an existing modifier-list information.
 * @param {*} organizationID The id of the modifier-list organization
 * @param {*} modifierListID The id of the modifier-list to be fetched
 * 
 * @returns an object with an attribute containing the current infromation of the modifier-list
 */
export async function fetchOne(organizationID, modifierListID) {
    let data = {};
    updateFieldIfValid(data, "organizationID", organizationID);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}modifier-list/${modifierListID}`, data));
    else return await request.get(includeParamsToUrl(`${API}modifier-list/${modifierListID}`));
}

/**
 * The rest request which attempts to create a new modifier-list.
 * @param {*} data A JSON object which contains new modifier-list infromation
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function create(data) {
    return await request.post(`${API}modifier-list`, data);
}

/**
 * The rest request which attempts to update an existing modifier-list.
 * @param {*} data A JSON object which contains the updated infromation of the modifier-list
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function update(data) {
    return await request.put(`${API}modifier-list`, data);
}

/**
 * The rest request which attempts to update an existing modifier-list details.
 * @param {*} data A JSON object which contains the updated infromation of the modifier-list
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function updateDetails(data) {
    return await request.put(`${API}modifier-list/details`, data);
}

/**
 * The rest request which attempts to enable an existing modfier list.
 * @param {*} modifierListID The id of the modfier list to be enabled
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function enable(modifierListID) {
    return await request.post(`${API}modifier-list/${modifierListID}/enable`);
}

/**
 * The rest request which attempts to disable an existing modfier list.
 * @param {*} modifierListID The id of the modfier list to be disabled
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function disable(modifierListID) {
    return await request.post(`${API}modifier-list/${modifierListID}/disable`);
}

/**
 * The rest request to delete a specific modifier-list.
 * @param {*} id The id of the modifier-list to be deleted
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function deleteById(id) {
    return await request.delete(`${API}modifier-list/${id}`);
}

/**
 * The rest request which attempts to get the items list for attach dialog from the database.
 * @param {*} search The filters for search.
 * 
 * @returns an object with an attribute containing the items/modifiers list
 */
export async function fetchListDialog(organizationID) {
    let data = {};
    updateFieldIfValid(data, "organizationID", organizationID);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}modifier-list/list`, data));
    else return await request.get(includeParamsToUrl(`${API}modifier-list/list`, {}));
}

const modifierListApi = {
    fetchAll,
    fetchAllByOrganizationID,
    fetchNew,
    fetchOne,
    fetchListDialog,
    create,
    update,
    disable,
    enable,
    updateDetails,
    deleteById
}

export default modifierListApi;