import { Typography } from '@mui/material';

/**
 * The Custom Typography, that display customized {@link Typography}.
 * 
 * @param {string} text the typography text
 * @param {string} variant Applies the theme typography styles.
 * @param {object} sx defining system overrides as well as additional CSS styles
 * @param {*} icon the icon to be displayed
 * @param {boolean} iconPositionStart if the icon should be position in front or after the `text`
 *
 * @returns {JSX.Element} A React component that renders a typography with icon.
 * 
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomTypographyWithIcon({ text = "", variant = "body2", sx, icon, iconPositionStart = true }) {

    return (
        <Typography sx={sx} variant={variant} display="flex">
            {iconPositionStart &&
                <>{icon} {text}</>
            }
            {!iconPositionStart &&
                <>{text} {icon}</>
            }
        </Typography>
    );
}

export default CustomTypographyWithIcon;