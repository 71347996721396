
import { Box, Grid } from '@mui/material';
import { fieldsetBox, fieldsetBoxContainer, fieldsetBoxLegend } from 'themes/defaultThemes';

/**
 * The Custom Fieldset, that display customized fieldset section.
 * 
 * @param {*} children The children of the drawer
 * @param {*} label The fieldset label
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomFieldset({ children, label = "", sx, gridSx, spacing = 3 }) {
    return (
        <Box component="fieldset" sx={{ ...fieldsetBox(true), ...sx }}>
            <legend style={fieldsetBoxLegend()}>{label}</legend>
            <Grid container spacing={spacing} sx={{ ...fieldsetBoxContainer(), ...gridSx }}>
                {children}
            </Grid>
        </Box>
    );
}

export default CustomFieldset;