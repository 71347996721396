import { request } from "constants/alias";
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from "utils/functions";
import updateFieldIfValid from "utils/functions/updateFieldIfValid";

/**
 * The rest request which attempts to get the ratings list from the database.
 * @param {*} search The filters for search.
 *
 * @returns an object with an attribute containing the ratings list
 */
export async function fetchAll(search, pageable) {
    let data = {};
    updateFieldIfValid(data, "organizationID", search.organizationID);
    updateFieldIfValid(data, "outletID", search.outletID);
    updateFieldIfValid(data, "customerID", search.customerID);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}rating`, pageable !== undefined ? { ...pageable, ...data } : data));
    else return await request.get(includeParamsToUrl(`${API}rating`, pageable !== undefined ? pageable : {}));
}

export async function fetchCounts(search) {
    let data = {};
    updateFieldIfValid(data, "organizationID", search.organizationID);
    updateFieldIfValid(data, "outletID", search.outletID);
    updateFieldIfValid(data, "customerID", search.customerID);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}analytics/ratings`, data));
    else return await request.get(includeParamsToUrl(`${API}analytics/ratings`, null));
}

const ratingApi = {
    fetchAll,
    fetchCounts
};

export default ratingApi;
