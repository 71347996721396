import { Button } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

/**
 * The Custom Button, that display an button.
 * @property {string} label the button text value
 * @property {boolean} shownLabel if the label should be shown
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomButton({
  label = "",
  onClick,
  sx,
  sxHover,
  startIcon = undefined,
}) {
  return (
    <ThemeProvider
      theme={createTheme({
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                backgroundColor: "#fff",
                margin: "0px",
                fontSize: "8px",
                fontWeight: "900",
                color: "#2596be",
                ...sx,
                ":hover": {
                  backgroundColor: "#000",
                  ...sxHover,
                },
              },
            },
          },
        },
      })}
    >
      <Button onClick={onClick} startIcon={startIcon}>
        {label}
      </Button>
    </ThemeProvider>
  );
}

export default CustomButton;
