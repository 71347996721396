
export const getSectionToolbarIconStyle = () => {
    return {
        py: "0.6em",
        paddingLeft: "10px",
        fontSize: "0.8rem",
        fill: "rgb(153, 153, 153)",
    };
}

export const getSectionToolbarSectionIconStyle = () => {
    return {
        py: "0.6em",
        paddingLeft: "10px",
        fontSize: "0.8rem",
        fill: "rgb(153, 153, 153)",
    };
}
