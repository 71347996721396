import EuroIcon from '@mui/icons-material/Euro';
import {
    Grid,
    List,
    ListItem,
} from '@mui/material';
import { Stack } from '@mui/system';
import itemImage from 'assets/images/item.jpg';
import CustomTypography from 'components/Typography/CustomTypography';
import CustomTypographyWithIcon from 'components/Typography/CustomTypographyWithIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * The CustomMenuItem, that display each menu item.
 * 
 * @param {object} item the menu item details
 * @param {string} priceColor the color for price to have
 * @param {string} lang the lang code
 * @param {fucntion} handleItemDialogState Callback that handles the open item details dialog for more details and to fill in fields to add one or more to cart.
 *
 * @returns {JSX.Element} A React component that renders a menu item.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomPreviewMenuItems({ font, itemHeaderColor, itemHeaderFontSize = "16px", itemDescColor, itemDescFontSize, itemPriceColor,
    itemPriceFontSize, layout, showCurrency, iconPositionStart = false, itemPhotoStyle, showDescription, layoutOptions }) {

    const [activeLayout, setActiveLayout] = React.useState(layoutOptions[0]);
    const { t } = useTranslation();

    let item = {
        name: `${t('itemOrModifier.pageTitleItem')} ${t('label.name')}`,
        description: `${t('itemOrModifier.pageTitleItem')} ${t('label.description')}`,
        price: "1.2"
    }

    return (
        <>
            {layout.length === 2 &&
                <Stack direction="row" style={{ marginBottom: "30px", borderRadius: "30%" }}>
                    <Stack direction="column" sx={{ padding: "10px", marginRight: "20px", alignItems: "center", boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)" }}
                        onClick={(event) => setActiveLayout(layoutOptions[0])}>
                        <span>
                            <svg width="33" height="36" xmlns="http://www.w3.org/2000/svg">
                                <g stroke={`${activeLayout === layoutOptions[0] ? "#47a6d2" : "#A2B8C6"}`} fill="none" fillRule="evenodd">
                                    <rect fill="#F4F6F9" x=".5" y=".5" width="32" height="7" rx="3"></rect>
                                    <path fill="#A2B8C6" d="M25.5 7.5h1v-7h-1z"></path>
                                    <rect fill="#F4F6F9" x=".5" y="11.5" width="32" height="7" rx="3"></rect>
                                    <path fill="#A2B8C6" d="M25.5 18.5h1v-7h-1z"></path>
                                    <rect fill="#F4F6F9" x=".5" y="23" width="32" height="7" rx="3"></rect>
                                    <path fill="#A2B8C6" d="M25.5 29.5h1v-7h-1z"></path>
                                </g>
                            </svg>
                        </span>
                    </Stack>

                    <Stack direction="column" sx={{ padding: "10px", alignItems: "center", boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)" }}
                        onClick={(event) => setActiveLayout(layoutOptions[1])} >
                        <span>
                            <svg width="33" height="36" xmlns="http://www.w3.org/2000/svg">
                                <g stroke={`${activeLayout === layoutOptions[1] ? "#47a6d2" : "#A2B8C6"}`} fill="none" fillRule="evenodd">
                                    <rect fill="#F4F6F9" x=".5" y=".5" width="14" height="15" rx="3"></rect>
                                    <rect fill="#F4F6F9" x="18.5" y=".5" width="14" height="15" rx="3"></rect>
                                    <path fill="#A2B8C6" d="M.5 9.5h14v1h-25zm52 0h25v1h-25zm0 0h14.5v1h-25z"></path>
                                    <rect fill="#F4F6F9" x=".5" y="20.5" width="14" height="15" rx="3"></rect>
                                    <rect fill="#F4F6F9" x="18.5" y="20.5" width="14" height="15" rx="3"></rect>
                                    <path fill="#A2B8C6" d="M.5 30h14v1h-25zm52 0h17v1h-25zm0 0h14v1h-25z"></path>
                                </g>
                            </svg>
                        </span>
                    </Stack>
                </Stack>
            }

            {((layout.length === 2 && activeLayout === layoutOptions[0]) || (layout.length === 1 && layout.includes(layoutOptions[0]))) &&
                <List>
                    {[0, 1].map((index) => (
                        <ListItem key={index} sx={{ width: 420, marginBottom: index === 2 ? "70px" : "20px", boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)" }}>
                            <div style={{ width: "80%" }}>
                                <CustomTypography variant="body2" text={item.name} sx={{ fontFamily: font, fontSize: itemHeaderFontSize, color: itemHeaderColor, fontWeight: "bold", padding: 0, paddingBottom: "3px" }} />
                                {showDescription && <CustomTypography variant="body2" text={item.description} sx={{ fontFamily: font, fontSize: itemDescFontSize, color: itemDescColor, padding: 0, paddingBottom: "7px" }} />}
                                <CustomTypographyWithIcon
                                    variant="body2"
                                    text={item.price}
                                    sx={{ fontWeight: "bold", fontFamily: font, fontSize: itemPriceFontSize, color: itemPriceColor, marginTop: "10px", padding: 0 }}
                                    icon={showCurrency ? <EuroIcon sx={{ color: itemPriceColor, placeItems: "center", iconPositionStart: false, fontSize: itemPriceFontSize, paddingTop: "3px" }} /> : <></>}
                                    iconPositionStart={iconPositionStart}
                                />
                            </div>
                            <div style={{ paddingLeft: "10px", width: "112px", height: "98px" }}>
                                <img src={itemImage} width={112} height={98} style={{ borderRadius: itemPhotoStyle === "ROUND" ? "50%" : "10px" }} alt="" />
                            </div>
                        </ListItem>
                    ))}
                </List>
            }

            {((layout.length === 2 && activeLayout === layoutOptions[1]) || (layout.length === 1 && layout.includes(layoutOptions[1]))) &&
                <Grid container gap={4}>
                    {[0, 1, 2, 3, 4, 5].map((index) => (
                        <ListItem key={index} sx={{ width: 170, height: 220, marginBottom: index === 5 ? "70px" : "0px", boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)" }}>
                            <div style={{ paddingLeft: "15px", width: "112px" }}>
                                <img src={itemImage} width={112} height={98} style={{ paddingTop: "10px", borderRadius: itemPhotoStyle === "ROUND" ? "50%" : "10px" }} alt="" />

                                <CustomTypography variant="body2" text={item.name} sx={{ fontFamily: font, fontSize: itemHeaderFontSize, color: itemHeaderColor, fontWeight: "bold", padding: 0, paddingBottom: "3px" }} />
                                {showDescription && <CustomTypography variant="body2" text={item.description} sx={{ fontFamily: font, fontSize: itemDescFontSize, color: itemDescColor, padding: 0, paddingBottom: "7px" }} />}
                                <CustomTypographyWithIcon
                                    variant="body2"
                                    text={item.price}
                                    sx={{ fontWeight: "bold", fontFamily: font, fontSize: itemPriceFontSize, color: itemPriceColor, marginTop: "10px", padding: 0 }}
                                    icon={showCurrency ? <EuroIcon sx={{ color: itemPriceColor, placeItems: "center", iconPositionStart: false, fontSize: itemPriceFontSize, paddingTop: "3px" }} /> : <></>}
                                    iconPositionStart={iconPositionStart}
                                />
                            </div>
                        </ListItem>
                    ))}
                </Grid>
            }
        </>
    );
}

export default CustomPreviewMenuItems;