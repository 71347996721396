import AddIcon from '@mui/icons-material/Add';
import { Card, CardActionArea } from '@mui/material';
import React from 'react';
import CustomIconButton from 'components/Button/CustomIconButton';
import { addCardActionAreaButtonIconStyle, addCardActionAreaButtonStyle, addCardStyle, cardItemContentActionArea } from 'themes/defaultThemes';

/**
 * The Custom Add POS device Card, that display customized {@link Card} with an add button for triggering the add new pos card.
 * 
 * @property {function} onClick Callback that triggered when card button is clicked
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomAddPosCard({ onClick }) {
    return (
        <Card
            key={`card-new-pos`}
            variant="outlined"
            sx={{ ...addCardStyle(), minHeight: "250px", minWidth: "320px", marginTop: "30px", width: "auto" }}
        >
            <CardActionArea sx={cardItemContentActionArea()}>
                <CustomIconButton
                    sx={addCardActionAreaButtonStyle()}
                    backgroundColor="transparent"
                    icon={<AddIcon sx={addCardActionAreaButtonIconStyle()} />}
                    onClick={onClick}
                />
            </CardActionArea>
        </Card>
    );
}

export default CustomAddPosCard;