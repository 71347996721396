import { Link as LinkIcon, LinkOff as LinkOffIcon } from '@mui/icons-material';
import { Stack } from "@mui/system";
import CustomIconButton from "components/Button/CustomIconButton";
import CustomTextField from "components/Text/CustomTextField";
import { getLoyaltyCardBarcodeMarginBottom, getLoyaltyCardBarcodeMarginLeft, getLoyaltyCardBarcodeMarginRight, getLoyaltyCardBarcodeMarginTop } from 'constants/fieldProperties';
import { useTranslation } from "react-i18next";
import { formValChangeWithParentElementObjectValues, formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';
import { validateInputNumber } from 'utils/functions';

function CustomMarginField({
    data, setData, objectnName = ""
}) {
    const { t } = useTranslation();
    const cleanObjectName = objectnName.slice(0, -1);
    const buttonStyles = {
        // width: "13%",
        borderRadius: "50px",
        height: "50px",
        padding: "5px",
        // minWidth: "40px",
        marginBottom: "0px"
    }
    const buttonIconStyles = {
        marginLeft: "0px",
        marginRight: "0px",
    }
    const textFieldStyles = {
        height: "50px",
        padding: "0",
    }

    function handleLinkClick() {
        if (data[cleanObjectName].hasCustomMargin) {
            const marginTop = data[cleanObjectName].marginTop;
            formValChangeWithParentElementObjectValues(`${objectnName}`,
                {
                    hasCustomMargin: !data[cleanObjectName].hasCustomMargin,
                    margin: marginTop,
                    marginRight: marginTop,
                    marginBottom: marginTop,
                    marginLeft: marginTop
                },
                data, setData);
        } else {
            formValChangeWithParentElementWithNameAndValue(`${objectnName}hasCustomMargin`, !data[cleanObjectName].hasCustomMargin, data, setData);
        }
    };

    function updateAllBasedOnTop(marginTop) {
        if (!data[cleanObjectName].hasCustomMargin) {
            formValChangeWithParentElementObjectValues(`${objectnName}`,
                {
                    margin: marginTop,
                    marginTop: marginTop,
                    marginRight: marginTop,
                    marginBottom: marginTop,
                    marginLeft: marginTop
                },
                data, setData);
        } else {
            formValChangeWithParentElementWithNameAndValue(`${objectnName}marginTop`, marginTop, data, setData);
        }
    }

    return (
        <Stack direction="row" spacing={2} alignItems="flex-end">
            <Stack direction="column">
                <CustomTextField
                    type="number"
                    step={getLoyaltyCardBarcodeMarginTop().step}
                    min={getLoyaltyCardBarcodeMarginTop().min}
                    max={getLoyaltyCardBarcodeMarginTop().max}
                    name="marginTop"
                    label={t('loyalty.settings.barcode.marginTop')}
                    value={data[cleanObjectName].marginTop}
                    sx={textFieldStyles}
                    onChange={(event) => {
                        let value = validateInputNumber(event.target.value, getLoyaltyCardBarcodeMarginTop().length, getLoyaltyCardBarcodeMarginTop().min, getLoyaltyCardBarcodeMarginTop().max, getLoyaltyCardBarcodeMarginTop().isFloat);
                        if (value === undefined) value = data[cleanObjectName].marginTop;
                        updateAllBasedOnTop(parseInt(value))
                    }}
                />
            </Stack>
            <Stack direction="column">
                <CustomTextField type="number"
                    name="marginRight"
                    step={getLoyaltyCardBarcodeMarginRight().step}
                    min={getLoyaltyCardBarcodeMarginRight().min}
                    max={getLoyaltyCardBarcodeMarginRight().max}
                    label={t('loyalty.settings.barcode.marginRight')}
                    value={data[cleanObjectName].marginRight}
                    sx={textFieldStyles}
                    disabled={data[cleanObjectName].hasCustomMargin ? false : true}
                    onChange={(event) => {
                        let value = validateInputNumber(event.target.value, getLoyaltyCardBarcodeMarginRight().length, getLoyaltyCardBarcodeMarginRight().min, getLoyaltyCardBarcodeMarginRight().max, getLoyaltyCardBarcodeMarginRight().isFloat);
                        if (value === undefined) value = data[cleanObjectName].marginRight;
                        formValChangeWithParentElementWithNameAndValue(`${objectnName}marginRight`, parseInt(value), data, setData)
                    }}
                />
            </Stack>
            <Stack direction="column">
                <CustomTextField type="number"
                    step={getLoyaltyCardBarcodeMarginBottom().step}
                    min={getLoyaltyCardBarcodeMarginBottom().min}
                    max={getLoyaltyCardBarcodeMarginBottom().max}
                    name="marginBottom"
                    label={t('loyalty.settings.barcode.marginBottom')}
                    value={data[cleanObjectName].marginBottom}
                    sx={textFieldStyles}
                    disabled={data[cleanObjectName].hasCustomMargin ? false : true}
                    onChange={(event) => {
                        let value = validateInputNumber(event.target.value, getLoyaltyCardBarcodeMarginRight().length, getLoyaltyCardBarcodeMarginRight().min, getLoyaltyCardBarcodeMarginRight().max, getLoyaltyCardBarcodeMarginRight().isFloat);
                        if (value === undefined) value = data[cleanObjectName].marginRight;
                        formValChangeWithParentElementWithNameAndValue(`${objectnName}marginBottom`, parseInt(value), data, setData)
                    }}
                />
            </Stack>
            <Stack direction="column">
                <CustomTextField type="number"
                    name="marginLeft"
                    step={getLoyaltyCardBarcodeMarginLeft().step}
                    min={getLoyaltyCardBarcodeMarginLeft().min}
                    max={getLoyaltyCardBarcodeMarginLeft().max}
                    label={t('loyalty.settings.barcode.marginLeft')}
                    value={data[cleanObjectName].marginLeft}
                    sx={textFieldStyles}
                    disabled={data[cleanObjectName].hasCustomMargin ? false : true}
                    onChange={(event) => {
                        let value = validateInputNumber(event.target.value, getLoyaltyCardBarcodeMarginRight().length, getLoyaltyCardBarcodeMarginRight().min, getLoyaltyCardBarcodeMarginRight().max, getLoyaltyCardBarcodeMarginRight().isFloat);
                        if (value === undefined) value = data[cleanObjectName].marginRight;
                        formValChangeWithParentElementWithNameAndValue(`${objectnName}marginLeft`, parseInt(value), data, setData)
                    }}
                />
            </Stack>
            <Stack direction="column">
                <CustomIconButton
                    keyValue="hasCustomMargin"
                    variant="outlined"
                    icon={data[cleanObjectName].hasCustomMargin ? <LinkOffIcon /> : <LinkIcon />}
                    // tooltipTitle={t("loyalty.settings.barcode.margin")}
                    sx={buttonStyles}
                    iconSx={buttonIconStyles}
                    onClick={handleLinkClick}
                    isFocused={data[cleanObjectName].hasCustomMargin ? false : true}
                />
            </Stack>
        </Stack>
    )
}
export default CustomMarginField;
