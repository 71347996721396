import { request } from 'constants/alias';
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from 'utils/functions';
import updateFieldIfValid from 'utils/functions/updateFieldIfValid';

/**
 * The rest request which return required information to be presented on floor planning settins page.
 * @param {*} organizationID The id of the organization
 * @param {*} outletID The id of the outlet
 * @returns an object with an attribute containing the requested information to be presented on the floor planning settins page
 */
export async function fetchSettings(organizationID, outletID) {
    let data = {};
    updateFieldIfValid(data, "organizationID", organizationID);
    updateFieldIfValid(data, "outletID", outletID);
    
    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}display/floor-planning/settings`, data));
    else return await request.get(includeParamsToUrl(`${API}display/floor-planning/settings`));
}

const floorPlanningApi = {
    fetchSettings,
}

export default floorPlanningApi;