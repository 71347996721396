import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import formatDateTime from "utils/formatDateTime";
import reorder from "utils/reOrder";
import reOrderNested from "utils/reOrderNested";
import truncateText from "utils/truncateText";
import AddItemPopup from "pages/menu/AddorUpdateDialog";
import LeftSider from "./leftSider";

const Board = ({
    Items,
    setItems,
    leftItems,
    leftSiderWidth,
    showLeftSider,
    cardWidth,
    showParent,
    organizationID,
    showItemsImages,
    ParentCategories,
    setParentCategories,
}) => {
    const { t } = useTranslation();
    const [deleteDialog, setDeleteDialog] = useState({
        active: false,
        parentName: "",
        itemName: "",
        parentId: "",
        itemId: "",
    });
    const [deleteMainDialog, setDeleteMainDialog] = useState({
        active: false,
        itemName: "",
        itemId: "",
    });
    const [editMainDialog, setEditMainDialog] = useState({
        active: false,
        item: null,
    });
    const [morePopup, setMorePopup] = useState({
        active: null,
        item: null,
    });
    const [addItemDialog, setAddItemDialog] = useState({
        active: false,
        itemId: "",
    });
    const [search, setSearch] = useState("");
    const [selectedItems, setSelectedItems] = useState([]);
    const onDragEnd = (result) => {
        // dropped nowhere
        if (!result.destination) {
            return;
        }

        const source = result.source;
        const destination = result.destination;

        //console.log(source, destination, result);

        // did not move anywhere - can bail early
        if (
            source.droppableId === destination.droppableId &&
            source.index === destination.index
        ) {
            return;
        }

        // reordering column
        if (result.type === "COLUMN") {
            const ordered = reorder(Items, source.index - 1, destination.index - 1);

            setItems(
                ordered.map((ele) => {
                    return {
                        ...ele,
                        itemIds: ele.itemIds.map((nestedEle) => nestedEle.id),
                    };
                })
            );

            return;
        }

        //means element not drop from leftSideBar( Copy pase Logic)
        if (source.droppableId === "Items") {
            const nextParent = Items.find(
                (ele) => ele.id === destination.droppableId
            );
            const next = nextParent.itemIds;
            const target = leftItems[source.index];
            if (next.some((x) => x.id === target.id)) {
                return;
            }
            next.splice(destination.index, 0, target);
            setItems((list) =>
                list.map((li) => {
                    if (li.id === destination.droppableId) {
                        li.itemIds = next.map((nestedEle) => nestedEle.id);
                    }
                    return li;
                })
            );
        } else {
            const ordered = reOrderNested(Items, source, destination);

            setItems(
                ordered.map((ele) => {
                    return {
                        ...ele,
                        itemIds: ele.itemIds.map((nestedEle) => nestedEle.id),
                    };
                })
            );
        }
    };

    const deleteItemHandler = () => {
        setItems((d) => {
            return d.map((ele) => {
                if (ele.id === deleteDialog.parentId) {
                    ele.itemIds = ele.itemIds.filter((x) => x !== deleteDialog.itemId);
                }
                return ele;
            });
        });
        setDeleteDialog({
            active: false,
            parentName: "",
            itemName: "",
            parentId: "",
            itemId: "",
        });
    };

    const deleteMainHandler = () => {
        setItems((d) => {
            return d.filter((ele) => ele.id !== deleteMainDialog.itemId);
        });
        if (!showParent) {
            setParentCategories((pc) => {
                return pc.map((p) => {
                    let newItemsIds = p.itemIds.filter(
                        (ele) => ele !== deleteMainDialog.itemId
                    );
                    p.itemIds = newItemsIds;
                    return p;
                });
            });
        }
        setDeleteMainDialog({
            active: false,
            itemName: "",
            itemId: "",
        });
        setMorePopup({
            active: null,
            item: null,
        });
    };

    const addItemHandler = () => {
        setItems((d) =>
            d.map((ele) => {
                if (ele.id === addItemDialog.itemId) {
                    ele.itemIds = [
                        ...ele.itemIds,
                        ...selectedItems.filter((x) => !ele.itemIds.includes(x)),
                    ];
                }
                return ele;
            })
        );
        setSearch("");
        setSelectedItems([]);
        setAddItemDialog({
            active: false,
            item: "",
        });
    };

    const onHideHandler = () => {
        setItems((d) =>
            d.map((ele) => {
                if (ele.id === morePopup?.item?.id) {
                    ele.active = false;
                    ele.activeUI = false;
                }
                return ele;
            })
        );
        setMorePopup({
            active: null,
            item: null,
        });
    };

    const onDuplicateHandler = () => {
        let newItemId = uuidv4();
        setItems((d) => {
            let elementInd = d.findIndex((ele) => ele.id === morePopup?.item?.id);
            let ItemsCopy = [...d];
            let newItem = { ...ItemsCopy[elementInd] };
            newItem.name = {
                ...newItem.name,
                en: newItem.name.en + " Copy",
            };
            newItem.id = newItemId;
            newItem.lastActivity = formatDateTime(new Date());
            newItem.dateCreated = formatDateTime(new Date());
            newItem.duplicate = true;
            ItemsCopy.splice(elementInd + 1, 0, newItem);
            return ItemsCopy;
        });
        if (!showParent) {
            let defaultParent = ParentCategories.find(
                (x) => x.name?.en === "default"
            );

            if (defaultParent) {
                setParentCategories((c) =>
                    c.map((pc) => {
                        if (pc.id === defaultParent.id) {
                            pc.itemIds = [...pc.itemIds, newItemId];
                        }
                        return pc;
                    })
                );
            }
        }
        setMorePopup({
            active: null,
            item: null,
        });
    };

    const moreMenuID = "more-menu";
    const renderMoreMenu = (
        <Menu
            anchorEl={morePopup.active}
            id={moreMenuID}
            keepMounted
            open={Boolean(morePopup.active)}
            onClose={() =>
                setMorePopup({
                    active: null,
                    item: null,
                })
            }
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            disableScrollLock
        >
            <MenuItem
                onClick={() => {
                    setAddItemDialog({
                        active: true,
                        itemId: morePopup?.item?.id,
                    });
                    setMorePopup({
                        active: null,
                        item: null,
                    });
                }}
                sx={{ background: "transparent", padding: "10px 16px" }}
            >
                {t('actions.add')}
            </MenuItem>
            <MenuItem
                onClick={onHideHandler}
                sx={{ background: "transparent", padding: "10px 16px" }}
            >
                {t('actions.hide')}
            </MenuItem>
            <MenuItem
                onClick={() =>
                    setEditMainDialog({
                        active: true,
                        item: morePopup.item,
                    })
                }
                sx={{ background: "transparent", padding: "10px 16px" }}
            >
                {t('actions.edit')}
            </MenuItem>
            <MenuItem
                onClick={onDuplicateHandler}
                sx={{ background: "transparent", padding: "10px 16px" }}
            >
                {t('actions.duplicate')}
            </MenuItem>
            <MenuItem
                onClick={() =>
                    setDeleteMainDialog({
                        active: true,
                        itemId: morePopup.item?.id,
                        itemName: morePopup.item?.name.en,
                    })
                }
                sx={{ background: "transparent", padding: "10px 16px" }}
            >
                {t('actions.delete')}
            </MenuItem>
        </Menu>
    );
    const renderDeleteDialog = (
        <Dialog
            maxWidth='sm'
            fullWidth
            open={deleteDialog.active}
            onClose={() => {
                setDeleteDialog({
                    active: false,
                    parentName: "",
                    itemName: "",
                    parentId: "",
                    itemId: "",
                });
            }}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogContent>
                <Typography variant='h5' sx={{ color: "#34353A", fontWeight: 700 }}>
                    {t('actions.delete')}
                    {deleteDialog?.itemName}
                </Typography>
                <Typography variant='body1' sx={{ mt: "20px", color: "#4a4c50" }}>
                    {`${t('actions.deleteModal.confirm')} '${deleteDialog.itemName}' ${t('actions.deleteModal.confirm')} {" "}
          '${deleteDialog.parentName}' ?`}
                </Typography>
                <Grid
                    container
                    spacing={2}
                    justifyContent='flex-end'
                    sx={{ mt: "28px" }}
                >
                    <Grid item>
                        <Button
                            variant='text'
                            sx={{
                                borderColor: "transparent",
                                fontWeight: 600,
                                color: "#4A4c50",
                            }}
                            onClick={() =>
                                setDeleteDialog({
                                    active: false,
                                    parentName: "",
                                    itemName: "",
                                    parentId: "",
                                    itemId: "",
                                })
                            }
                        >
                            {t('actions.delete')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant='outlined'
                            sx={{
                                borderColor: "red",
                                color: "red",
                                fontWeight: 600,
                            }}
                            onClick={deleteItemHandler}
                        // disabled={
                        //   loading.active && loading.action === "cover"
                        // }
                        >
                            {t('actions.delete')}
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
    const renderDeleteMainDialog = (
        <Dialog
            maxWidth='sm'
            fullWidth
            open={deleteMainDialog.active}
            onClose={() => {
                setDeleteMainDialog({
                    active: false,
                    itemName: "",
                    itemId: "",
                });
            }}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogContent>
                <Typography variant='h5' sx={{ color: "#34353A", fontWeight: 700 }}>
                    {t('actions.delete')} {deleteMainDialog?.itemName}
                </Typography>
                <Typography variant='body1' sx={{ mt: "20px", color: "#4a4c50" }}>
                    {`${t('actions.deleteModal.confirm')} ${deleteMainDialog.itemName}?`}
                </Typography>
                <Grid
                    container
                    spacing={2}
                    justifyContent='flex-end'
                    sx={{ mt: "28px" }}
                >
                    <Grid item>
                        <Button
                            variant='text'
                            sx={{
                                borderColor: "transparent",
                                fontWeight: 600,
                                color: "#4A4c50",
                            }}
                            onClick={() =>
                                setDeleteMainDialog({
                                    active: false,
                                    itemName: "",
                                    itemId: "",
                                })
                            }
                        >
                            {t('actions.cancel')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant='outlined'
                            sx={{
                                borderColor: "red",
                                color: "red",
                                fontWeight: 600,
                            }}
                            onClick={deleteMainHandler}
                        // disabled={
                        //   loading.active && loading.action === "cover"
                        // }
                        >
                            {t('actions.delete')}
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
    const renderAddItemDialog = (
        <Dialog
            maxWidth='sm'
            fullWidth
            open={addItemDialog.active}
            onClose={() => {
                setAddItemDialog({
                    active: false,
                    itemId: "",
                });
            }}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogTitle>
                <Typography
                    variant='h5'
                    align='center'
                    sx={{ color: "#34353A", fontWeight: 700 }}
                >
                    {t('menus.cms.addItems')}
                </Typography>

                {/* Input for filter */}
                <Grid
                    container
                    spacing={2}
                    justifyContent='space-between'
                    sx={{ mt: "20px" }}
                >
                    <TextField
                        fullWidth
                        placeholder='Filter'
                        variant='outlined'
                        size='small'
                        sx={{ mt: "4px" }}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </Grid>
            </DialogTitle>
            <DialogContent>
                {leftItems
                    .filter((x) => {
                        let openItem = Items.find(
                            (eachItem) => eachItem.id === addItemDialog?.itemId
                        );
                        return !openItem?.itemIds
                            .map((eachItem) => eachItem.id)
                            .includes(x.id);
                    })
                    .filter((x) =>
                        x.name.en?.toLowerCase()?.includes(search.toLowerCase())
                    )
                    .map((item, i) => (
                        <Grid
                            container
                            justifyContent='space-between'
                            sx={{
                                mt: i === 0 ? "20px" : 0,
                                pb: "5px",

                                borderBottom: "1px solid #e4e4e4",
                            }}
                            key={i}
                        >
                            <Grid item>
                                <FormControlLabel
                                    label={item.name.en}
                                    control={
                                        <Checkbox
                                            size='small'
                                            checked={selectedItems.includes(item.id)}
                                            onChange={(e) => {
                                                setSelectedItems((si) =>
                                                    si.includes(item.id)
                                                        ? si.filter((x) => x !== item.id)
                                                        : [...si, item.id]
                                                );
                                            }}
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item></Grid>
                        </Grid>
                    ))}
            </DialogContent>
            <DialogActions>
                <Grid container spacing={2} justifyContent='space-between'>
                    <Grid item>
                        <Button
                            variant='text'
                            sx={{
                                borderColor: "transparent",
                                fontWeight: 600,
                                color: "#4A4c50",
                            }}
                            onClick={() => {
                                setAddItemDialog({
                                    active: false,
                                    itemId: "",
                                });
                                setSelectedItems([]);
                                setSearch("");
                            }}
                        >

                            {t('actions.cancel')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant='contained'
                            sx={{
                                fontWeight: 600,
                            }}
                            onClick={addItemHandler}
                        // disabled={
                        //   loading.active && loading.action === "cover"
                        // }
                        >
                            {`${t('actions.add')} ${selectedItems.length} ${t('menus.cms.items')}`}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            {renderDeleteDialog}
            {renderAddItemDialog}
            {renderMoreMenu}
            {renderDeleteMainDialog}
            <AddItemPopup
                showParent={showParent}
                open={editMainDialog.active}
                editData={editMainDialog.item}
                setOpen={(state) =>
                    setEditMainDialog({
                        active: state,
                        item: null,
                    })
                }
                title={showParent ? t('menus.cms.parentCategory') : t('menus.cms.category')}
                onSubmit={(data) => {
                    setItems((c) =>
                        c.map((ele) => {
                            if (ele.id === data.id) {
                                return {
                                    ...data,
                                    itemIds: data.itemIds.map((item) => item.id),
                                };
                            }
                            return ele;
                        })
                    );
                    setMorePopup({
                        active: null,
                        item: null,
                    });
                    setEditMainDialog({
                        active: false,
                        item: null,
                    });
                }}
                Items={Items}
            />
            <Droppable
                droppableId='board'
                type='COLUMN'
                direction='horizontal'
            //ignoreContainerClipping={Boolean(containerHeight)}
            //isCombineEnabled={isCombineEnabled}
            >
                {(provided) => (
                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            //marginLeft: "356px",

                            //position: "relative",
                        }}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        <LeftSider
                            cardWidth={cardWidth}
                            drawerWidth={leftSiderWidth}
                            open={showLeftSider}
                            Items={leftItems}
                            ParentData={Items}
                            showParent={showParent}
                            organizationID={organizationID}
                            showItemsImages={showItemsImages}
                        />
                        <div style={{ display: "flex", flex: 1, overflowX: "auto" }}>
                            {Items?.map((item, index) => (
                                <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index + 1}
                                >
                                    {(provided, snapshot) => (
                                        <Grid
                                            container
                                            direction='column'
                                            component={Paper}
                                            sx={{
                                                // width: "fit-content",
                                                maxWidth: cardWidth + 20,
                                                borderRadius: "5px",
                                                margin: "8px",
                                                // marginLeft: index === 0 ? `${leftSiderWidth}px` : 0,
                                                position: "unset",
                                                display: item.activeUI
                                                    ? showParent && item.name.en === "default"
                                                        ? "none"
                                                        : "flex"
                                                    : "none",
                                                opacity: !item.active ? 0.7 : 1,
                                            }}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                        >
                                            {/* header */}
                                            <Grid
                                                Item
                                                sx={{
                                                    minWidth: cardWidth,
                                                    width: "100%",
                                                    px: "10px",
                                                    py: "20px",
                                                    background: snapshot.isDragging
                                                        ? "#D4D4D4"
                                                        : "#EBECF0",

                                                    transition: "background-color 0.2s ease",
                                                    position: "relative",
                                                }}
                                                {...provided.dragHandleProps}
                                            >
                                                <div style={{ position: "absolute", top: 5, right: 5 }}>
                                                    <Tooltip title={!item.active ? "Unactive" : "Active"}>
                                                        <FiberManualRecordIcon
                                                            fontSize='0.5rem'
                                                            sx={{
                                                                fill: !item.active ? "red" : "#14A800",
                                                                opacity: 1,
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </div>
                                                <Grid
                                                    container
                                                    flexWrap={"nowrap"}
                                                    alignItems='center'
                                                    gap={"5px"}
                                                >
                                                    <Grid
                                                        item
                                                        sx={{
                                                            flex: 1,
                                                            // "& .hover-name": {
                                                            //   display: "none",
                                                            // },
                                                            // "&:hover": {
                                                            //   "& .hover-name": {
                                                            //     display: "unset",
                                                            //   },
                                                            //   "& .name": {
                                                            //     display: "none",
                                                            //   },
                                                            // },
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                gap: "10px",
                                                            }}
                                                        >
                                                            {item.photo ? (
                                                                <img
                                                                    src={
                                                                        item.image
                                                                            ? item.photo.imageSrc
                                                                            : `${process.env.REACT_APP_IMAGE_HOST}/${organizationID}/${showParent ? "parentCategory" : "category"}/${item.id}/${item.photo.imageSrc}`
                                                                    }
                                                                    style={{ width: "50px", height: "50px" }}
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={
                                                                        showParent
                                                                            ? "/dev/parent.svg"
                                                                            : "/dev/category.svg"
                                                                    }
                                                                    style={{ width: "50px", height: "50px" }}
                                                                />
                                                            )}
                                                            {item.name?.en?.length <= 20 ? (
                                                                <Typography
                                                                    variant='h5'
                                                                    sx={{ wordBreak: "break-all", flex: 1 }}
                                                                    className='hover-name'
                                                                >
                                                                    {item.name?.en}
                                                                </Typography>
                                                            ) : (
                                                                <Tooltip title={item.name?.en}>
                                                                    <Typography
                                                                        variant='h5'
                                                                        sx={{ wordBreak: "break-all", flex: 1 }}
                                                                        className='name'
                                                                    >
                                                                        {truncateText(item.name?.en, 20)}
                                                                    </Typography>
                                                                </Tooltip>
                                                            )}
                                                        </div>
                                                    </Grid>
                                                    <Grid item>
                                                        <IconButton
                                                            sx={{ p: 0 }}
                                                            aria-owns={
                                                                morePopup.active ? moreMenuID : undefined
                                                            }
                                                            aria-haspopup={morePopup.active ? true : false}
                                                            onClick={(e) =>
                                                                setMorePopup({
                                                                    active: e.currentTarget,
                                                                    item: item,
                                                                })
                                                            }
                                                        >
                                                            <MoreHorizIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {/* items */}
                                            <Grid
                                                Item
                                                sx={{
                                                    width: "100%",
                                                    flex: 1,
                                                    background: snapshot.isDragging ? "#D4D4D4" : "#fff",
                                                    transition: "background-color 0.2s ease",

                                                    //pb: "10px",
                                                    position: "unset",
                                                    height: "calc(100% - 10px)",
                                                    maxHeight: "calc(100% - 10px)",
                                                    overflowY: "auto",
                                                    overflowX: "hidden",
                                                }}
                                            >
                                                <Droppable droppableId={item.id} type={"Categories"}>
                                                    {(dropProvided, dropSnapshot) => (
                                                        <div
                                                            {...dropProvided.droppableProps}
                                                            ref={dropProvided.innerRef}
                                                        //style={{ height: "100%" }}
                                                        >
                                                            {item.itemIds?.map((subItem, subIndex) => (
                                                                <Draggable
                                                                    key={item?.id + "-" + subItem?.id}
                                                                    draggableId={item?.id + "-" + subItem?.id}
                                                                    index={subIndex}
                                                                >
                                                                    {(dragProvided, dragSnapshot) => (
                                                                        <Grid
                                                                            container
                                                                            alignItems='center'
                                                                            //direction='column'
                                                                            component={Paper}
                                                                            sx={{
                                                                                mx: "10px",
                                                                                padding: "4px 10px",
                                                                                mt: "10px",
                                                                                width: cardWidth,
                                                                                ".options": {
                                                                                    display: "none",
                                                                                },
                                                                                ":hover": {
                                                                                    ".options": {
                                                                                        display: "flex",
                                                                                    },
                                                                                },
                                                                            }}
                                                                            ref={dragProvided.innerRef}
                                                                            {...dragProvided.draggableProps}
                                                                            {...dragProvided.dragHandleProps}
                                                                        >
                                                                            <Grid item sx={{ flex: 1 }}>
                                                                                <div
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        gap: "10px",
                                                                                    }}
                                                                                >
                                                                                    {showParent ? (
                                                                                        !showItemsImages ? null : subItem.photo ? (
                                                                                            <img
                                                                                                src={
                                                                                                    subItem.image
                                                                                                        ? subItem.photo.imageSrc
                                                                                                        : `${process.env.REACT_APP_IMAGE_HOST}/${organizationID}/category/${subItem.id}/${subItem.photo.imageSrc}`
                                                                                                }
                                                                                                style={{
                                                                                                    width: "20px",
                                                                                                    height: "20px",
                                                                                                }}
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                src={"/dev/category.svg"}
                                                                                                style={{
                                                                                                    width: "20px",
                                                                                                    height: "20px",
                                                                                                }}
                                                                                            />
                                                                                        )
                                                                                    ) : showItemsImages ? (
                                                                                        subItem.photo ||
                                                                                            subItem?.photos?.length > 0 ? (
                                                                                            <img
                                                                                                src={`${process.env.REACT_APP_IMAGE_HOST}/${organizationID}/item/${subItem.id}/${subItem.photo
                                                                                                    ? subItem.photo.imageSrc
                                                                                                    : subItem.photos.find(
                                                                                                        (x) => x.cover === true
                                                                                                    ).imageSrc
                                                                                                    }`}
                                                                                                style={{
                                                                                                    width: "20px",
                                                                                                    height: "20px",
                                                                                                }}
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                src={"/dev/item.svg"}
                                                                                                style={{
                                                                                                    width: "20px",
                                                                                                    height: "20px",
                                                                                                }}
                                                                                            />
                                                                                        )
                                                                                    ) : null}
                                                                                    {subItem?.name?.en?.length <= 20 ? (
                                                                                        <Typography variant='body2'>
                                                                                            {subItem.name.en}
                                                                                        </Typography>
                                                                                    ) : (
                                                                                        <Tooltip title={subItem?.name.en}>
                                                                                            <Typography variant='body2'>
                                                                                                {truncateText(
                                                                                                    subItem?.name.en,
                                                                                                    20
                                                                                                )}
                                                                                            </Typography>
                                                                                        </Tooltip>
                                                                                    )}
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Grid
                                                                                    container
                                                                                    alignItems='center'
                                                                                    gap={"10px"}
                                                                                    className='options'
                                                                                >
                                                                                    {/* delete Icon */}
                                                                                    <Grid item>
                                                                                        <IconButton
                                                                                            sx={{ p: 0 }}
                                                                                            onClick={() => {
                                                                                                setDeleteDialog({
                                                                                                    active: true,
                                                                                                    parentId: item.id,
                                                                                                    parentName: item.name.en,
                                                                                                    itemId: subItem.id,
                                                                                                    itemName: subItem.name.en,
                                                                                                });
                                                                                            }}
                                                                                        >
                                                                                            <DeleteIcon fontSize='small' />
                                                                                        </IconButton>
                                                                                    </Grid>
                                                                                    {/* view Icon */}
                                                                                    {!showParent && (
                                                                                        <Grid item>
                                                                                            <Link
                                                                                                style={{
                                                                                                    textDecoration: "none",
                                                                                                    color: "inherit",
                                                                                                }}
                                                                                                to={`/en/item-or-modifier/${subItem?.id}`}
                                                                                                target='_blank'
                                                                                            >
                                                                                                <VisibilityIcon
                                                                                                    fontSize='small'
                                                                                                    sx={{ mt: "4px" }}
                                                                                                />
                                                                                            </Link>
                                                                                        </Grid>
                                                                                    )}
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            <div
                                                                style={{
                                                                    margin: "0px 10px",
                                                                    display: "flex",
                                                                    width: cardWidth,
                                                                }}
                                                            >
                                                                <Button
                                                                    variant='text'
                                                                    size='small'
                                                                    sx={{
                                                                        mt: dropSnapshot.isDraggingOver
                                                                            ? "40px"
                                                                            : "10px",
                                                                        fontSize: "12px",
                                                                        px: "10px",
                                                                        color: "#47a6d2",
                                                                        textTransform: "none",
                                                                    }}
                                                                    startIcon={<AddIcon />}
                                                                    onClick={() => {
                                                                        setAddItemDialog({
                                                                            active: true,
                                                                            itemId: item.id,
                                                                        });
                                                                    }}
                                                                >
                                                                    {showParent ? t('menus.cms.addCategories') : t('menus.cms.addItems')}
                                                                </Button>
                                                            </div>
                                                            {dropProvided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default Board;
