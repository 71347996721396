import { Fab, Grid, Tooltip } from '@mui/material';
import { Stack } from '@mui/system';
import outletApi from "api/outlet";
import { AddIcon } from 'assets/mui/MuiIcons';
import CustomBreadcrumbMultiParent from "components/Breadcrumb/CustomBreadcrumbMultiParent";
import CustomRefreshButton from 'components/Button/CustomRefreshButton';
import CustomDialog from 'components/Dialogs/CustomDialog';
import CustomGrid from 'components/Grid/CustomGrid';
import ListPageSkeleton from 'components/Loading/ListPageSkeleton';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';
import CustomPageableTable from "components/Table/CustomPageableTable";
import CustomTitle from "components/Title/CustomTitle";
import { getDateFormatter } from 'constants/calendar';
import { getDefaultSortDirectionData } from 'constants/sortDirection';
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import { fabStyle, fabStyleIcon, fabTooltipStyle, mainAreaTable, mainTag, mainTagBreadcrumb, mainTagSearch, mainTagTitle } from 'themes/defaultThemes';
import { hasRoleAdmin } from 'utils/auth';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';
import { prepareTablePaginationSortParams } from 'utils/functions';
import { getLanguageFromURL } from "utils/language";
import { actionFormatter, dateFormatter, fieldFormatter, iconFormatter, idFormatterLinkWithParent, idFormatterLinkWithParentAndNameLabel, idFormatterLinkWithParentAndNameLabelMultilingual } from "utils/table";
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The Outlet List component to view the outlets of the system.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function OutletListComponent() {
   const { organizationID } = useParams();
   const { t } = useTranslation();
   const navigate = useNavigate();

   //change document title
   useDocumentTitle(`Usee ${organizationID !== undefined ? "| " + t('org.title') : ""} | ${t('outlet.title')}`);

   let path = `/${getLanguageFromURL()}`
   if (organizationID !== undefined) path += `/organization/${organizationID}`;
   path += `/outlet`;

   /**
    * @type {object}
    * @property {object} userAuth the authenticated user infromation
    * @property {array} outlets the outlets (pageable)
    * @property {object} organization tha organization basic details (id and name)
    * @property {object} pagination includes all pageable details (page, size, sort, direction)
    */
   const [data, setData] = useState({
      userAuth: null,
      outlets: null,
      showLabel: false,
      organization: {
         id: organizationID,
         name: ""
      },
      pagination: {
         count: -1,
         size: 10,
         page: 0,
         ...getDefaultSortDirectionData
      }
   });

   // to perform initial request
   const [isFirstTime, setIsFirstTime] = useState(true);
   // if true the menu item is clicked and a redirect to page should perform
   const [isLoaded, setIsLoaded] = useState(false);
   // for search
   const [search, setSearch] = useState({
      organizationID: organizationID,
      name: { en: "" },
      url: ""
   });
   // pop dialog for delete
   const [dialog, setDialog] = useState({
      isOpen: false,
      outletID: "",
      name: ""
   });
   // snackbar details
   const [alertBox, setAlertBox] = useState({
      isOpen: false,
      message: "",
      backgroundColor: "#a71313"
   });

   useEffect(() => {
      getOutlets();
      setIsFirstTime(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      const identifier = setTimeout(() => {
         if (!isFirstTime) getOutlets();
      }, 700);

      return () => {
         clearTimeout(identifier);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [search, data.pagination])


   /**
    * The rest endpoint to get the outlets list.
    */
   function getOutlets() {
      outletApi.fetchAll(search, prepareTablePaginationSortParams(data.pagination)).then((r) => {
         setData({
            ...data,
            outlets: r.data.returnobject.page,
            userAuth: r.data.returnobject.userAuth,
            organization: r.data.returnobject.organization,
            pagination: {
               ...data.pagination,
               count: r.data.returnobject.page.totalPages,
               size: r.data.returnobject.page.size,
               page: r.data.returnobject.page.number
            }
         });
         setIsLoaded(true);
      }).catch((e) => {
         // console.log(e);
      })
   }

   /**
    * Gets called to activate the selected outlet
    * @param {*} event
    * @param {string} id the id of the outlet
    */
   function activateOutlet(event, id) {
      outletApi.activate(id).then((r) => {
         setAlertBox({
            isOpen: true,
            message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
            backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
         });
         // setRedirect((r.data.code === "SUCCESS" ? true : false));
         getOutlets();
      }).catch((e) => {
         // console.log(e);
      })
   }

   /**
    * Gets called to activate the selected outlet
    * @param {*} event
    * @param {string} id the id of the outlet 
    */
   function disableOutlet(event, id) {
      outletApi.disable(id).then((r) => {
         setAlertBox({
            isOpen: true,
            message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
            backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
         });
         // setRedirect((r.data.code === "SUCCESS" ? true : false));
         getOutlets();
      }).catch((e) => {
         // console.log(e);
      })
   }


   /**
    * Gets called to remove the selected outlet
    */
   function deleteO() {
      outletApi.deleteById(dialog.outletID).then((r) => {
         handleDialogState(false);
         setAlertBox({
            isOpen: true,
            message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
            backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
         });
         // setRedirect((r.data.code === "SUCCESS" ? true : false));
         getOutlets();
      }).catch((e) => {
         // console.log(e);
      })
   }

   /**
    * 
    * Function that triggers the removal of the selected outlet.
    * @property {*} event
    * @property {string} outletID The id of the selected outlet to be removed.
    */
   function deleteOutlet(event, outletID, name = "") {
      handleDialogState(true, outletID, name);
   }

   /**
    * Function that handles the delete modal open or close data.
    * @property {boolean} isOpen The state of the dialog open status.
    * @property {string} outletID The id of the selected outlet to be removed.
    */
   function handleDialogState(isOpen, outletID = "", name = "") {
      setDialog({
         ...dialog,
         isOpen: isOpen,
         outletID: outletID,
         name: name
      });
   }

   /**
    * Function that handles the snackbar open or close state.
    * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
    */
   function handleSnackbarState(isOpen) {
      setAlertBox({
         ...alertBox,
         isOpen: isOpen
      });
   }

   /**
    * Function that handles the pagination information.
    * @property {int} page the page to be redirected
    */
   function handlePaginationChange(page) {
      formValChangeWithParentElementWithNameAndValue("pagination.page", page, data, setData);
   }

   /**
    * Function that handles the pagination information.
    * @property {int} size the size the new numberOfRows to be requested
    */
   function handleRowsPerPageChange(size) {
      let pagination = data.pagination;
      pagination.size = size;
      pagination.page = 0;
      formValChangeWithParentElementWithNameAndValue("pagination", pagination, data, setData);
   }
   /**
    * Function that handles the sort information.
    * @property {int} size the size the new numberOfRows to be requested
    */
   function handleColumnSortChange(changedColumn, direction) {
      let pagination = data.pagination;
      pagination.sort = changedColumn;
      pagination.direction = direction;
      formValChangeWithParentElementWithNameAndValue("pagination", pagination, data, setData);
   }

   // ===============================================================================RENDER

   if (!isLoaded) {
      return <CustomGrid role="main" id="outlet-list" sx={mainTag()}>
         <ListPageSkeleton />
      </CustomGrid>
   } else {
      /**
       * datatable columns.
       */
      const table_columns = [
         {
            name: "isActive",
            label: t('label.isActive'),
            options: {
               filter: false,
               sort: false,
               empty: false,
               customBodyRender: (value, tableMeta) => {
                  return iconFormatter(value,
                     tableMeta.tableData[tableMeta.rowIndex],
                     tableMeta.rowIndex,
                     {
                        enable: (event, rowIndex) => activateOutlet(event, rowIndex),
                        disable: (event, rowIndex) => disableOutlet(event, rowIndex),
                        positionOfId: 1
                     }
                  );
               }
            }
         },
         {
            name: "id",
            label: t('label.id'),
            options: {
               viewColumns: data.userAuth !== null && hasRoleAdmin(data.userAuth.roles) ? true : false,
               filter: false,
               sort: false,
               empty: false,
               display: data.userAuth !== null && hasRoleAdmin(data.userAuth.roles) ? true : false,
               customBodyRender: (value, tableMeta) => {
                  return idFormatterLinkWithParent(value, tableMeta.tableData[tableMeta.rowIndex], `${path}`);
               }
            }
         },
         {
            name: "organization",
            label: t('org.pageTitle'),
            options: {
               viewColumns: data.userAuth !== null && hasRoleAdmin(data.userAuth.roles) ? true : false,
               filter: false,
               sort: false,
               empty: false,
               display: data.userAuth !== null && hasRoleAdmin(data.userAuth.roles) && !organizationID ? true : false,
               customBodyRender: (value, tableMeta) => {
                  return idFormatterLinkWithParentAndNameLabel(value, tableMeta.tableData[tableMeta.rowIndex], `/${getLanguageFromURL()}/organization`);
               }
            }
         },
         {
            name: "id",
            label: t('label.name'),
            options: {
               filter: false,
               sort: true,
               empty: false,
               customBodyRender: (value, tableMeta) => {
                  return idFormatterLinkWithParentAndNameLabelMultilingual(value, tableMeta.tableData[tableMeta.rowIndex], `${path}`, "en", 4);
               }
            }
         },
         {
            name: "name",
            options: {
               viewColumns: false,
               filter: true,
               filterType: 'textField',
               customFilterListRender: v => `${t('label.name')}: ${v}`,
               display: false
            }
         },
         {
            name: "url",
            label: t('outlet.url'),
            options: {
               filter: true,
               filterType: 'textField',
               customFilterListRender: v => `${t('outlet.url')}: ${v}`,
               sort: true,
               empty: false,
               customBodyRender: (value, tableMeta) => {
                  return fieldFormatter(value);
               }
            }
         },
         {
            name: "telephones",
            label: t('label.telephone'),
            options: {
               filter: false,
               sort: true,
               empty: false,
               customBodyRender: (value, tableMeta) => {
                  return value.map((telephone) => fieldFormatter(telephone));
               }
            }
         },
         {
            name: "counters",
            label: t('label.emails'),
            options: {
               filter: false,
               sort: true,
               empty: false,
               customBodyRender: (value) => {
                  return value?.email;
               }
            }
         },
         {
            name: "counters",
            label: t('label.smss'),
            options: {
               filter: false,
               sort: true,
               empty: false,
               customBodyRender: (value) => {
                  return value?.sms;
               }
            }
         },
         {
            name: "counters",
            label: t('label.ticketCounter'),
            options: {
               filter: false,
               sort: true,
               empty: false,
               customBodyRender: (value) => {
                  return value?.ticket;
               }
            }
         },
         {
            name: "counters",
            label: t('label.receiptTicketCounter'),
            options: {
               filter: false,
               sort: true,
               empty: false,
               customBodyRender: (value) => {
                  return value?.receiptTicket;
               }
            }
         },
         {
            name: "dateCreated",
            label: t('label.dateCreated'),
            options: {
               filter: false,
               sort: false,
               empty: false,
               customBodyRender: (value, tableMeta) => {
                  return dateFormatter(value, tableMeta.tableData[tableMeta.rowIndex], null, getDateFormatter());
               }
            }
         },
         {
            name: "",
            label: t('actions.label'),
            options: {
               filter: false,
               sort: false,
               empty: true,
               customBodyRender: (value, tableMeta) => {
                  return actionFormatter(value,
                     tableMeta.tableData[tableMeta.rowIndex],
                     tableMeta.rowIndex,
                     1,
                     null,
                     null,
                     null,
                     4,
                     path,
                     data.showLabel ? t('actions.edit') : undefined,
                     (event, rowIndex, name) => deleteOutlet(event, rowIndex, name),
                     data.showLabel ? t('actions.delete') : undefined,
                     path
                  )
               },
               setCellProps: () => ({ className: "click action" })
            }
         }
      ];

      return (
         <CustomGrid role="main" id="outlet-list" sx={mainTag()}>
            {organizationID !== undefined &&
               <CustomGrid sx={mainTagBreadcrumb()}>
                  <CustomBreadcrumbMultiParent
                     parents={[
                        { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                        { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                     ]}
                     instanceName={t('outlet.subtitle')}
                  />
               </CustomGrid>
            }

            <Grid container spacing={2} sx={{ alignItems: "center" }}>
               <Grid item xs={"auto"}>
                  <CustomRefreshButton onClick={getOutlets} marginTop="0" />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <CustomGrid sx={mainTagTitle()}>
                     <CustomTitle
                        title={t('outlet.title')}
                        subtitle={t('outlet.subtitle')}
                     />
                  </CustomGrid>

               </Grid>
               <Grid item xs={12} sm={8}>
                  <Stack direction="row" sx={mainTagSearch()}>
                     <CustomGrid sx={mainTagSearch()} direction="column" container={false} spacing={2}>
                        {/* <CustomTextField
                           type="search"
                           name="name.en"
                           id="name"
                           variant="filled" label={t('actions.search') + " by " + t('label.name')}
                           defaultValue={search.name !== null ? search.name.en : ""}
                           InputProps={{
                              endAdornment: (
                                 <InputAdornment position="end" >
                                    <CustomSearchButton onClick={getOutlets} />
                                 </InputAdornment>
                              )
                           }}
                           required={false}
                           sx={mainTagSearchItem()}
                           onChange={(event) => formValChangeWithParentElementWithNameAndValue(event.target.name, event.target.value, search, setSearch)}
                        /> */}
                     </CustomGrid>
                  </Stack>
               </Grid>
            </Grid>

            <CustomGrid sx={mainAreaTable()} container={false}>
               <CustomPageableTable
                  instances={data.outlets}
                  emptyMessage={t('outlet.emptyMessage')}
                  table_columns={table_columns}
                  keyField="id"
                  defaultSorted={{ name: data.pagination.sort, direction: data.pagination.direction }}
                  pagination={data.pagination}
                  handlePaginationChange={handlePaginationChange}
                  handleRowsPerPageChange={handleRowsPerPageChange}
                  handleColumnSortChange={handleColumnSortChange}
                  filter={true}
                  onFilterChange={(event) => (event !== null) ? formValChangeWithParentElementWithNameAndValue(event.field, event.value, search, setSearch) : setSearch({
                     ...search,
                     name: "",
                     url: ""
                  })}
               />

               <Fab color="primary"
                  aria-label="add"
                  sx={fabStyle()}
                  onClick={() => navigate(`/${getLanguageFromURL()}/outlet/add`)}
               >
                  <Tooltip arrow title={t('actions.add') + " " + t('outlet.pageTitle')} sx={fabTooltipStyle()}>
                     <AddIcon {...fabStyleIcon()} />
                  </Tooltip>
               </Fab>
            </CustomGrid>

            {dialog.isOpen &&
               <CustomDialog
                  isOpen={dialog.isOpen}
                  id="delete"
                  title={t('actions.deleteModal.title')}
                  actionLabel={t("actions.deleteModal.message")}
                  cancelLabel={t("actions.cancel")}
                  message={`${t('actions.deleteModal.notice')} ${t('actions.deleteModal.confirm')} ${t('outlet.pageTitle')}: `}
                  boldMessage={<span style={{ fontWeight: "bold" }}>{dialog.name}</span>}
                  action={deleteO}
                  handleOpen={handleDialogState}
                  actionColor="#e91e1e"
               />
            }

            {alertBox.isOpen &&
               <CustomSnackbar
                  isOpen={alertBox.isOpen}
                  autoHideDuration={3000}
                  message={alertBox.message}
                  backgroundColor={alertBox.backgroundColor}
                  handleSnackbarOpen={handleSnackbarState} />
            }
         </CustomGrid>
      );
   }
}

export default OutletListComponent;