import { formatISOWithTimezone } from 'utils/functions/date';

/**
 * Updates an object field with a formatted date if the date is valid.
 *
 * A valid date must:
 * - Not be null.
 * - Not be an empty string.
 *
 * @param {object} data - The object to update.
 * @param {string} fieldName - The field name to update.
 * @param {string|Date} value - The date value to format and assign to the field.
 * @param {boolean} isAnalytics - If the date is for analytics API
 * @returns {object} - The updated data object.
 *
 * @example
 * const obj = {};
 * updateFieldIfValidDate(obj, "dateFrom", "2025-01-14"); 
 * // { dateFrom: "2025-01-14T00:00:00+00:00" }
 * updateFieldIfValidDate(obj, "dateFrom", null); 
 * // {}
 * 
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
const updateFieldIfValidDate = (data, fieldName, value, isAnalytics=false) => {
  // Check if the value is not null or an empty string
  if (value !== null && value !== "") {
    const formattedDate = formatISOWithTimezone(new Date(value), isAnalytics)
    data = { ...data, [fieldName]: formattedDate };
  }

  return data; // Return the updated object
};

export default updateFieldIfValidDate;
