import { ArrowBack as ArrowBackIcon, Lock as LockIcon } from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { LoadingButton } from '@mui/lab';
import { SvgIcon } from '@mui/material';
import { Stack } from '@mui/system';
import userApi from 'api/user';
import logo from 'assets/images/logo.png';
import 'assets/scss/app.scss';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomGrid from 'components/Grid/CustomGrid';
import LanguageSelector from 'components/Language/LanguageSelector';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';
import CustomTextFieldWithIcons from 'components/Text/CustomTextFieldWithIcons';
import CustomTypography from 'components/Typography/CustomTypography';
import { AuthConsumer } from 'context/AuthContext';
import { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import authService from 'services/auth.service';
import { formSaveButton } from 'themes/defaultThemes';
import { formIsAllValid } from "utils/form-validation";
import { getLanguageFromURL } from 'utils/language';
import useDocumentBodyClass from 'utils/useDocumentBodyClass';
import useDocumentTitle from 'utils/useDocumentTitle';

/**
 * The Home component before user logged-in
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function AnonymousHomeComponent() {

    const { t } = useTranslation();
    //change document title
    useDocumentTitle(`Usee | ${t('login')}`);
    useDocumentBodyClass("no-touch bg-usee front")

    /**
     * @type {object}
     * 
     * @property {string} username the username to attempt login
     * @property {string} password the password to attempt login
     * @property {object} isError the password to attempt login
     * @property {boolean} redirect if a redirect should be performed
     * @property {boolean} isLoaded to render DOM based on rest api call status, if true the rest api call completed
     * @property {object} userAuth the authenticated user infromation
     * @property {object} alertBox holds snackbar infromation and style
     * @property {object} organization holds the organization information
     * @property {array} items the menu items list
     * @property {array} providerOptions the different provider options
     * @property {object} pagination includes all pageable details (page, size, sort, direction)
     * @property {object} dialog holds dialog for delete action
     * @property {object} alertBox holds snackbar infromation and style
     * @property {object} dialog holds dialog for delete action
     */
    const [data, setData] = useState({
        //form fields
        username: "",//username or email
        password: "",
        newPassword: "",
        verifyPassword: "",
        otpCode: "",
        passwordShown: false,
        shownPasswordIcon: VisibilityOffIcon,
        // error messages per field
        isError: {
            username: "",
            password: "",
            newPassword: "",
            verifyPassword: ""
        }
    });

    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313"
    });

    const [isForgotPasswordStep, setIsForgotPasswordStep] = useState(false);
    const [actionButtonLoading, setActionButtonLoading] = useState(false);
    const [isOTPStep, setIsOTPStep] = useState(false);
    const [isResetPasswordStep, setIsResetPasswordStep] = useState(false);

    // create a counter to enable the resend code logic after 30sec
    const timerIdRef = useRef(0);
    const [counter, setCounter] = useState(5);

    useEffect(() => {
        return () => clearInterval(timerIdRef.current);
    }, []);


    useEffect(() => {
        const listener = event => {
            if ((event.code === "Enter" || event.code === "NumpadEnter")) {
                console.log("Enter key was pressed. Run your function.");
                console.log(data)
                if (formValidation()) {
                    if (!isForgotPasswordStep)
                        authService.login(data.username, data.password);
                    // } else if (isResetPasswordStep) {
                    //     resetPassword(data, data.otpCode)
                    // }
                }
                event.preventDefault();
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.password, data.username]);

    /**
     * Callback that handles the start of the time interval for the resend code button to be shown on end.
     * @returns nothing
     */
    function startHandler() {
        if (timerIdRef.current) {
            return;
        }
        timerIdRef.current = setInterval(() => setCounter((prevCounter) => (prevCounter === 0) ? stopHandler() : prevCounter - 1), 1000);
    }

    /**
     * Callback that handles the end of the time interval for the resend code button to be shown.
     */
    function stopHandler() {
        clearInterval(timerIdRef.current);
        timerIdRef.current = 0;
        return 0;
    }

    function resetForm() {
        setIsForgotPasswordStep(false);
        setIsOTPStep(false);
        setIsResetPasswordStep(false);
    }

    /**
     * Callback that trigers the send OTP code to customer mobile number.
     */
    function checkPasswordResetCode(code) {
        setActionButtonLoading(true);
        if (code.length !== 8) return;
        if (formValidation(code)) {
            userApi.checkPasswordResetCode(data, code).then((r) => {
                setAlertBox({
                    isOpen: true,
                    message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                    backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                });
                if ((r.data.code === "SUCCESS")) {
                    setIsOTPStep(false)
                    setIsForgotPasswordStep(true);
                    setIsResetPasswordStep(true);
                    setData({
                        ...data,
                        otpCode: code
                    });
                }
                setActionButtonLoading(false);
            }).catch((e) => {
                console.log(e)
            });
        }
    }

    function resetPassword(code) {
        setActionButtonLoading(true);
        if (formValidation()) {
            userApi.resetPassword(data, code).then((r) => {
                console.log(data)
                setAlertBox({
                    isOpen: true,
                    message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                    backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                });
                if ((r.data.code === "SUCCESS")) {
                    setData({
                        ...data,
                        username: data.username,
                        password: data.newPassword,
                        otpCode: "",
                        newPassword: "",
                        verifyPassword: ""
                    })
                    setIsOTPStep(false)
                    setIsForgotPasswordStep(false);
                    setIsResetPasswordStep(false);
                    setActionButtonLoading(false);
                }
            }).catch((e) => {
                // console.log(e)
            });
        }
    }

    /**
     * Function to trigger Sign-in when a user press "Sign in" button
     */
    function loginHandler(event, contextLogin) {
        setActionButtonLoading(true);
        if (formValidation()) {
            contextLogin(data.username, data.password).then((r) => {
                window.location.reload();
            },
                error => {
                    let message = error;
                    if (error === "Something went wrong. Please contact the system administrator")
                        message = t('errorPages.somethingWentWrong')
                    setAlertBox({
                        isOpen: true,
                        message: message,
                        backgroundColor: "#a71313"
                    });
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setData({
                        ...data,
                        loading: false,
                        message: resMessage
                    });

                    setActionButtonLoading(false);
                }
            );
        }
    }

    function forgotPasswordPressed() {
        setIsForgotPasswordStep(true);
        setCounter(30);
        startHandler();
    }

    /**
     * Function to trigger Sign-in when a user press "Sign in" button
     */
    function forgotPassword(event) {
        setActionButtonLoading(true);
        if (formValidation()) {
            userApi.forgotPassword(data.username).then((r) => {
                setData({
                    ...data,
                    otpCode: ""
                });
                setAlertBox({
                    isOpen: true,
                    message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                    backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                });
                setIsOTPStep(true);
                forgotPasswordPressed();
                setActionButtonLoading(false);
            }).catch((e) => {
                // console.log(e)
            });
        }
    }

    /**
     * Function that triggers the form value change.
     *
     * @param {type} event 
     */
    async function formValChange(event) {
        event.preventDefault();
        // get input tag name and value
        const { name, value } = event.target;
        let isError = { ...data.isError };

        isError[name] = value.length < 1 ? "The value is required." : ""
        await setData({
            ...data,
            isError,
            [name]: value
        });
        if (name === "otpCode") checkPasswordResetCode(value)
    };

    /**
     * Function that triggers form validation and print out if the form is valid or not.
     * @returns true if form is Valid
     */
    function formValidation(code = null) {
        const credentials = {
            isError: createErrorMessages(),
            username: data.username
        }

        if (!isForgotPasswordStep) {
            credentials.password = data.password;
        }
        if (isForgotPasswordStep && isOTPStep) {
            credentials.otpCode = code !== null ? code : data.otpCode;
        }
        if (isForgotPasswordStep && isResetPasswordStep) {
            credentials.newPassword = data.newPassword;
            credentials.verifyPassword = data.verifyPassword;
        }

        if (formIsAllValid(credentials) && data.username !== undefined) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Function that create error messages for each required field that are not filled out.
     * @returns the object with the error messages for each form field
     */
    function createErrorMessages() {
        let isError = { ...data.isError };
        isError.username = data.username.length < 1 ? "The value is required." : "";
        if (!isForgotPasswordStep) {
            isError.password = data.password.length < 1 ? "The value is required." : "";
        } else {
            isError.password = "";
            if (isOTPStep) {
                isError.otpCode = data.otpCode.length !== 8 ? "The value is required" : "";
                isError.newPassword = "";
                isError.verifyPassword = "";
            } else if (isResetPasswordStep) {
                // TODO: The correct password length is at least 8 chars
                isError.newPassword = data.newPassword.length < 0 ? t('auth.newPasswordLength') : "";
                isError.verifyPassword = data.verifyPassword.length < 0 && data.newPassword !== data.verifyPassword ? t('auth.passwordsUnMatch') : "";
            }
        }

        setData({
            ...data,
            isError
        });
        return isError;
    }

    /**
     * Password toggle handler
     */
    function togglePassword() {
        // When the handler is invoked
        // inverse the boolean state of passwordShown and icon to be shown
        setData({
            ...data,
            shownPasswordIcon: !data.passwordShown ? VisibilityIcon : VisibilityOffIcon,
            passwordShown: !data.passwordShown
        });
    };

    /**
     * Image click handler
     */
    function imageClick() {
        window.location.href = '/' + getLanguageFromURL();
    }

    /**
     * Function that handles the snackbar open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }

    const { isError } = data;

    return (
        <AuthConsumer>
            {({ handleLogin }) => (
                // ANONYMOUS - HTML for homepage
                <div id="anonymous-home">
                    <LanguageSelector classVal={"anonymous"} />
                    <main id="block-login" className="cc">
                        <div id="logo"><img alt="" src={logo} onClick={imageClick} /></div>

                        <form id="login" className="white-area" onSubmit={() => { }}>
                            {isForgotPasswordStep &&
                                <Stack direction="row" sx={{ alignItems: "center" }}>
                                    <CustomIconButton icon={<ArrowBackIcon color="black" sx={{ fontSize: "30px!important" }} />} iconSx={{ fontSize: "30px!important" }} sx={{ height: "30px", marginRight: "10px", backgroundColor: "transparent", fontSize: "28px", color: "white", marginLeft: "35%", position: "absolute", left: "-33%", top: "10%" }} onClick={resetForm} />
                                </Stack>
                            }
                            <div className="form-group" style={{ width: "90%", justifyContent: "center" }}>
                                <CustomGrid gap={1} sx={{ placeContent: "center" }}>
                                    {/* username or email */}
                                    {!isResetPasswordStep &&
                                        <>
                                            {/* {data.username} */}
                                            <CustomTextFieldWithIcons
                                                id="username"
                                                name="username"
                                                variant="filled"
                                                type="text"
                                                value={data.username}
                                                defaultValue={data.username}
                                                label={t('auth.username')}
                                                required={true}
                                                error={isError.username.length > 0 ? true : false}
                                                autoCapitalize="none"
                                                startIcon={<PersonIcon style={{ color: "#2596be" }} />}
                                                onChange={formValChange}
                                                sx={{ marginLeft: "20px" }}
                                                autocomplete="username"
                                                startIconMarginTopEnable={true}
                                            />
                                            {isError.username.length > 0 && (
                                                <ul className="parsley-errors-list filled" id="parsley-id-33" aria-hidden="false"><li className="parsley-required">{isError.username}</li></ul>
                                            )}
                                        </>
                                    }
                                    {!isForgotPasswordStep &&
                                        <>
                                            <CustomTextFieldWithIcons
                                                id="password"
                                                name="password"
                                                variant="filled"
                                                type={data.passwordShown ? "text" : "password"}
                                                defaultValue={data.password}
                                                label={t('password')}
                                                required={true}
                                                startIcon={<LockIcon style={{ color: "#2596be" }} />}
                                                endIcon={<SvgIcon component={data.shownPasswordIcon} id="visibility-icon" onClick={togglePassword} style={{ cursor: "pointer" }} />}
                                                error={isError.password.length > 0 ? true : false}
                                                // onKeyDown={(event) => keyPressedOnLoginPasswordField(event, handleLogin)}
                                                onChange={formValChange}
                                                sx={{ marginLeft: "20px" }}
                                                autocomplete="current-password"
                                                startIconMarginTopEnable={true}
                                            />

                                            {isError.password.length > 0 &&
                                                <ul className="parsley-errors-list filled" id="parsley-id-33" aria-hidden="false"><li className="parsley-required">{isError.password}</li></ul>
                                            }
                                        </>
                                    }
                                    {isForgotPasswordStep && !isOTPStep && isResetPasswordStep &&
                                        <>
                                            <CustomTextFieldWithIcons
                                                id="newPassword"
                                                name="newPassword"
                                                variant="filled"
                                                type={data.passwordShown ? "text" : "password"}
                                                defaultValue={data.password}
                                                label={t('auth.newPassword')}
                                                required={true}
                                                startIcon={<LockIcon style={{ color: "#2596be" }} />}
                                                endIcon={<SvgIcon component={data.shownPasswordIcon} id="visibility-icon" onClick={togglePassword} style={{ cursor: "pointer" }} />}
                                                error={isError.password.length > 7 ? true : false}
                                                // onKeyPress={(event) => keyPressedOnLoginPasswordField(event, handleLogin)}
                                                onChange={formValChange}
                                                sx={{ marginLeft: "20px" }}
                                                autocomplete="current-password"
                                                startIconMarginTopEnable={true}
                                            />
                                            {isError.newPassword.length > 0 &&
                                                <ul className="parsley-errors-list filled" id="parsley-id-33" aria-hidden="false"><li className="parsley-required">{isError.newPassword}</li></ul>
                                            }

                                            <CustomTextFieldWithIcons
                                                id="verifyPassword"
                                                name="verifyPassword"
                                                variant="filled"
                                                type={data.passwordShown ? "text" : "password"}
                                                defaultValue={data.password}
                                                label={t('label.verifyPassword')}
                                                required={true}
                                                startIcon={<LockIcon style={{ color: "#2596be" }} />}
                                                // endIcon={<SvgIcon component={data.shownPasswordIcon} id="visibility-icon" onClick={togglePassword} style={{ cursor: "pointer" }} />}
                                                error={isError.password.length > 7 ? true : false}
                                                // onKeyDown={(event) => keyPressedOnLoginPasswordField(event, handleLogin)}
                                                onChange={formValChange}
                                                sx={{ marginLeft: "20px" }}
                                                startIconMarginTopEnable={true}
                                            />
                                            {isError.verifyPassword.length > 0 &&
                                                <ul className="parsley-errors-list filled" id="parsley-id-33" aria-hidden="false"><li className="parsley-required">{isError.verifyPassword}</li></ul>
                                            }
                                        </>
                                    }

                                    {isForgotPasswordStep && !isOTPStep && !isResetPasswordStep &&
                                        <CustomTypography text={t('auth.enterVerfCode')} variant="body2" sx={{ fontSize: "14px", marginTop: "10px", textAlign: "justify", marginLeft: "20px" }} />
                                    }

                                    {isForgotPasswordStep && isOTPStep &&
                                        <>
                                            <CustomTextFieldWithIcons
                                                id="otpCode"
                                                name="otpCode"
                                                variant="filled"
                                                defaultValue={data.otpCode}
                                                label={t('auth.code')}
                                                required={true}
                                                onChange={formValChange}
                                                //onPaste={handlePaste}
                                                sx={{ marginLeft: "20px", width: "100%" }}
                                                startIconMarginTopEnable={true}
                                            />

                                            <div style={{ justifyContent: "center", marginTop: "20px" }}>
                                                {/* <CustomTypography text={`${t('auth.didNotReceive')}`} variant="body2" sx={{ fontSize: "14px", width: "fit-content", paddingTop: "0px", paddingBottom: "0", marginLeft: "20px", marginTop: "30px" }} /> */}
                                                <CustomIconButton tooltipTitle={t('button.sendCode')} label={`${t('auth.resendNewCode')} ${counter !== 0 ? "(" + counter + "s)" : ""}`}
                                                    onClick={forgotPassword}
                                                    disabled={counter === 0 ? false : true}
                                                    sx={{ textTransform: "initial", fontSize: "12px", width: "fit-content", marginBottom: "20px", marginLeft: "20px", ":hover": { background: "lightblue" } }} />
                                            </div>
                                        </>
                                    }
                                </CustomGrid>
                            </div>

                            <div id="button-wrapper">
                                {!isForgotPasswordStep &&
                                    <LoadingButton
                                        id="btn-login"
                                        type="button"
                                        form="login"
                                        data-redirect="stay"
                                        onClick={(event) => loginHandler(event, handleLogin)}
                                        sx={formSaveButton()}
                                        loading={actionButtonLoading}
                                        loadingPosition="end"
                                    >
                                        {t('login')}
                                    </LoadingButton>
                                }
                                {isForgotPasswordStep && !isOTPStep && !isResetPasswordStep &&
                                    <LoadingButton
                                        id="btn-login"
                                        type="button"
                                        form="login"
                                        data-redirect="stay"
                                        onClick={(event) => forgotPassword(event)}
                                        sx={formSaveButton()}
                                        loading={actionButtonLoading}
                                        loadingPosition="end"
                                    >
                                        {t('button.sendCode')}
                                    </LoadingButton>
                                }
                                {isForgotPasswordStep && isResetPasswordStep &&
                                    <LoadingButton
                                        id="btn-login"
                                        type="button"
                                        form="login"
                                        data-redirect="stay"
                                        onClick={(event) => resetPassword(data.otpCode)}
                                        sx={formSaveButton()}
                                        loading={actionButtonLoading}
                                        loadingPosition="end"
                                    >
                                        {t('button.resetPassword')}
                                    </LoadingButton>
                                }
                            </div>

                            {!isForgotPasswordStep &&
                                <div className="info">
                                    {/* <span><a className="link" href={`/${getLanguageFromURL()}/forgot-password`}>{t('forgotPassword')}</a></span> */}
                                    <Link component="button" underline="hover" onClick={forgotPasswordPressed} variant="subtitle2" style={{ display: "contents" }}>{t('forgotPassword')}</Link>
                                    {/* <span style={{ paddingTop: "20px" }}>{t('haveAccount')}<a className="link" href={`/${getLanguageFromURL()}/${t('signInURLPath')}`}> {t('signIn')}</a> </span> */}
                                </div>
                            }
                        </form>
                    </main>

                    <footer id="footer-wrapper" role="contentinfo">
                        <div className="copyright">&copy; <span >{new Date().getFullYear()}</span> Usee | <a className="link" href={t('aboutURLPath')}>{t('aboutLabel')}</a> | <a className="link" href={t('aboutURLPath') + t('termsOfUseURLPath')}>{t('termsOfUseLabel')}</a> | <a className="link" href={t('aboutURLPath') + t('privacyPolicyURLPath')}>{t('privacyPolicyLabel')}</a></div>
                    </footer>

                    {alertBox.isOpen &&
                        <CustomSnackbar
                            isOpen={alertBox.isOpen}
                            autoHideDuration={6000}
                            message={alertBox.message}
                            backgroundColor={alertBox.backgroundColor}
                            handleSnackbarOpen={handleSnackbarState} />
                    }
                </div>
            )}
        </AuthConsumer>
    );
}

export default AnonymousHomeComponent;