import emailTemplateApi from "api/emailTemplate";
import 'assets/scss/campaign.scss'; // Import custom style for editor
import CustomGrid from 'components/Grid/CustomGrid';
import { fabStyle, mainArea, mainAreaBody, mainTag } from 'themes/defaultThemes';
import useDocumentTitle from "utils/useDocumentTitle";
// Import the JSON translations
import { Fab, Grid } from '@mui/material';
import { Stack } from '@mui/system';
import CustomBreadcrumbMultiParent from "components/Breadcrumb/CustomBreadcrumbMultiParent";
import CustomRefreshButton from 'components/Button/CustomRefreshButton';
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomObjectSelect from 'components/Select/CustomObjectSelect';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';
import CustomTitle from "components/Title/CustomTitle";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { mainTagBreadcrumb, mainTagSearch, mainTagSearchItem, mainTagSearchObjectSelect, mainTagTitle } from "themes/defaultThemes";
import { hasRoleAdmin } from 'utils/auth';
import { getLanguageFromURL } from "utils/language";
import { AddIcon } from "assets/mui/MuiIcons";
import CustomDialog from "components/Dialogs/CustomDialog";
import AddEditEmailTemplateDialog from "components/Dialogs/marketing/AddEditEmailTemplateDialog";
import CustomAddEmailTemplateCard from "components/Card/marketing/template/CustomAddEmailTemplateCard";
import CustomEmailTemplateCard from "components/Card/marketing/template/CustomEmailTemplateCard";

/**
 * The EmailTemplateListComponent, that presents email templates list component.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function EmailTemplateListComponent() {
    const { organizationID } = useParams();
    const { outletID } = useParams();
    const { t } = useTranslation();

    // Set document title
    useDocumentTitle(`Usee  ${organizationID !== undefined ? " | " + t('org.pageTitle') : ""} ${outletID !== undefined ? " | " + t('outlet.pageTitle') : ""} | ${t('marketing.template.pageTitle')}`);

    // let path = `/${getLanguageFromURL()}`
    // if (organizationID !== undefined) path += `/organization/${organizationID}`;
    // if (outletID !== undefined) path += `/outlet/${outletID}`;
    // path += `/marketing/email/template`;

    /**
     * @type {object}
     * @property {object} userAuth the authenticated user infromation
     * @property {array} emailTemplates the emailTemplates (pageable)
     * @property {object} organization tha organization basic details (id and name)
     * @property {object} pagination includes all pageable details (page, size, sort, direction)
     */
    const [data, setData] = useState({
        userAuth: null,
        organization: {
            id: organizationID,
            name: ""
        },
        outlet: {
            id: outletID,
            name: ""
        },
        organizations: [],
        outlets: [],
        emailTemplates: []
    });

    // if true the menu item is clicked and a redirect to page should perform
    const [isLoaded, setIsLoaded] = useState(false);
    // for search
    const [search, setSearch] = useState({
        organizationID: organizationID,
        outletID: outletID,
        name: "",
    });

    // pop dialog for delete
    const [deleteDialog, setDeleteDialog] = useState({
        isOpen: false,
        id: "",
        name: ""
    });
    // pop dialog for add/edit
    const [dialog, setDialog] = useState({
        isOpen: false,
        isAdd: true,
        id: "",
        name: ""
    });
    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313"
    });

    useEffect(() => {
        getEmailTemplates();
        // eslint-disable-next-line
    }, [search]);


    /**
     * The rest endpoint to get the email templates list.
     */
    function getEmailTemplates() {
        emailTemplateApi.fetchAll(search).then((r) => {
            Promise.resolve()
                .then(() => {
                    setData({
                        ...data,
                        userAuth: r.data.returnobject.userAuth,
                        organization: r.data.returnobject.organization,
                        organizations: r.data.returnobject.organizations,
                        outlet: r.data.returnobject.outlet,
                        outlets: r.data.returnobject.outlets,
                        emailTemplates: r.data.returnobject.emailTemplates,
                    });
                })
                .then(() => {
                    let organizationID = r.data.returnobject.organization !== null ? r.data.returnobject.organization.id : null;
                    let outletID = r.data.returnobject.outlet !== null ? r.data.returnobject.outlet.id : null;
                    if ((search.organizationID !== null && hasRoleAdmin(r.data.returnobject.userAuth.roles)) || !hasRoleAdmin(r.data.returnobject.userAuth.roles))
                        if (organizationID !== search.organizationID || (outletID !== search.outletID))
                            setSearch({ ...search, organizationID: organizationID, outletID: outletID !== null ? outletID : (r.data.returnobject.outlets?.size === 1) ? r.data.returnobject.outlets?.outlets[0].id : null });
                })
                .then(() => {
                    setIsLoaded(true);
                })
        }).catch((e) => {
            // console.log(e);
        })
    }


    /**
     * Function that triggers the creation of a email template
     */
    function addEmailTemplate(emailTemplate) {
        emailTemplate.organizationID = search.organizationID;
        emailTemplate.outletID = search.outletID;
        emailTemplateApi.create(emailTemplate).then((r) => {
            // let newPrinters = data.printers;
            // newPrinters.push(r.data.returnobject);//to get the id
            Promise.resolve()
                .then(() => {
                    getEmailTemplates();
                })
                .then(() => {
                    setAlertBox({
                        isOpen: true,
                        message: r.data.message,
                        backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                    });
                })
                .then(() => {
                    if (r.data.code === "SUCCESS")
                        setDialog({
                            isOpen: false,
                            printerID: "",
                            printNodeId: "",
                            isAdd: true//add or 1 for edit
                        });
                })

        }).catch((e) => {
            // console.log(e)
        });
    }

    /**
     * Function that triggers the modification of a email template
     */
    function updateEmailTemplate(emailTemplate) {
        emailTemplateApi.update(emailTemplate).then((r) => {
            Promise.resolve()
                .then(() => {
                    getEmailTemplates();
                })
                .then(() => {
                    setAlertBox({
                        isOpen: true,
                        message: r.data.message,
                        backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                    });
                })
                .then(() => {
                    if (r.data.code === "SUCCESS")
                        setDialog({
                            isOpen: false,
                            name: "",
                            isAdd: true//add or 1 for edit
                        });
                })
        }).catch((e) => {
            // console.log(e)
        });
    }

    /**
     * Gets called to remove the selected email template
     */
    function deleteET() {
        emailTemplateApi.deleteById(deleteDialog.id).then((r) => {
            handleDeleteDialogState(false);
            setAlertBox({
                isOpen: true,
                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
            getEmailTemplates();
        }).catch((e) => {
            // console.log(e);
        })
    }

    /**
     * Function that handles the delete modal open or close data.
     * @property {boolean} isOpen The state of the dialog open status.
     * @property {string} id The id of the selected email template to be removed.
     */
    function handleDialogState(isOpen, id = "", name = "", isAdd = true) {
        setDialog({
            ...dialog,
            isOpen: isOpen,
            id: id,
            name: name,
            isAdd: isAdd
        });
    }

    /**
     * Function that handles the delete modal open or close data.
     * 
     * @property {boolean} isOpen The state of the dialog open status.
     * @property {string} id The id of the selected email template to be removed.
     * @property {string} friendlyName The name of the selected email template to be removed.
     */
    function handleDeleteDialogState(isOpen, id = "", name = "") {
        setDeleteDialog({
            ...deleteDialog,
            isOpen: isOpen,
            id: id,
            name: name
        });
    }

    /**
     * Function that triggers the removal of the selected email template.
     * 
     * @property {*} event
     * @property {string} id The id of the selected email template to be removed.
     */
    function deleteEmailTemplate(event, id, name = "") {
        handleDeleteDialogState(true, id, name);
    }

    /**
     * Function that handles the snackbar open or close data.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }


    // ===============================================================================RENDER
    if (!isLoaded) {
        return <CustomGrid role="main" id="printer-list" sx={mainTag()}>
            <LoadingSkeleton lines={9} />
        </CustomGrid>
    } else {
        let breadcrumbs = [];
        if (outletID !== undefined && data.outlet !== null)
            breadcrumbs.push(
                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                { name: t('outlet.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet` },
                { name: data.outlet.name, url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet/${outletID}` },
            );
        else if (organizationID !== undefined && data.organization !== null)
            breadcrumbs.push(
                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
            );

        return (
            <CustomGrid role="main" id="test" sx={mainTag()}>
                {/* SELECT FOR OUTLET ORGANIZATION id  */}
                {(organizationID === undefined && search.organizationID === undefined && hasRoleAdmin(data.userAuth.roles)) &&
                    <>
                        <CustomObjectSelect
                            name="organizationID"
                            required={true}
                            defaultValue={search.organizationID}
                            labelID="organizationID" label={t('org.pageTitle')}
                            options={[{ id: "", name: "all" }, ...data.organizations]}
                            onChange={(event) => setSearch({ ...search, organizationID: event.target.value })}
                            sx={{ ...mainTagSearchItem(), ...mainTagSearchObjectSelect() }}
                            field="name"
                        />
                        {data.outlets !== null && data.outlets.length > 0 &&
                            <Grid item xs={12} sm={2}>
                                <CustomObjectSelect
                                    name="outletID"
                                    required={true}
                                    isMultiple={false}
                                    defaultValue={search.outletID}
                                    labelID="organizationID" label={t('outlet.pageTitle')}
                                    options={data.outlets}
                                    onChange={(event) => setSearch({ ...search, outletID: outletID })}
                                    sx={mainTagSearchItem()}
                                    field="name"
                                />
                            </Grid>
                        }
                    </>
                }
                {/* BREADCRUMB, TITLE, SEARCH */}
                {(search.organizationID !== null && search.organizationID !== undefined) &&
                    <>
                        {breadcrumbs.length > 0 &&
                            <CustomGrid sx={mainTagBreadcrumb()}>
                                <CustomBreadcrumbMultiParent
                                    parents={breadcrumbs}
                                    instanceName={t('marketing.template.pageTitle')}
                                />
                            </CustomGrid>
                        }

                        <Grid container spacing={2} sx={{ alignItems: "center" }}>
                            <Grid item xs={"auto"}>
                                <CustomRefreshButton onClick={getEmailTemplates} marginTop="-1%" />
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <CustomGrid sx={mainTagTitle()}>
                                    <CustomTitle
                                        title={t('marketing.template.pageTitle')}
                                        subtitle={t('marketing.template.subtitle')}
                                    />
                                </CustomGrid>
                                {/*TODO: on mobile button filter search -> oepn swippable drawer */}
                            </Grid>

                            <Grid item xs={12} sm={8}>
                                <Stack direction="row" sx={{ ...mainTagSearch(), textAlign: "right", width: "100%" }} >
                                    <CustomGrid sx={{ ...mainTagSearch(), textAlign: "right", width: "100%" }} direction="column" container={false} spacing={2} >
                                        {(hasRoleAdmin(data.userAuth.roles) && organizationID === undefined) &&
                                            <CustomObjectSelect
                                                name="organizationID"
                                                required={true}
                                                defaultValue={search.organizationID}
                                                labelID="organizationID" label={t('org.pageTitle')}
                                                options={[{ id: "", name: "all" }, ...data.organizations]}
                                                onChange={(event) => setSearch({ ...search, organizationID: event.target.value })}
                                                sx={{ ...mainTagSearchItem(), ...mainTagSearchObjectSelect() }}
                                                field="name"
                                            />
                                        }

                                        {search.organizationID !== null && data.outlets !== null && data.outlets.size > 1 && organizationID === undefined &&
                                            <Grid item xs={12} sm={2}>
                                                <CustomObjectSelect
                                                    name="outletID"
                                                    required={true}
                                                    isMultiple={true}
                                                    defaultValue={search.outletIDs}
                                                    labelID="organizationID" label={t('outlet.pageTitle')}
                                                    options={data.outlets}
                                                    onChange={(event) => setSearch({ ...search, outletIDs: [event.target.value] })}
                                                    sx={mainTagSearchItem()}
                                                    field="name"
                                                />
                                            </Grid>
                                        }
                                    </CustomGrid>
                                </Stack>
                            </Grid>
                        </Grid>

                        <CustomGrid sx={mainArea()} container={false}>
                            {data.emailTemplates.length === 0 &&
                                <CustomGrid sx={mainAreaBody()} container={false}>
                                    {`${t('marketing.template.noEmailTemplates')} ${t('actions.tableCreateNewLink')} ${t('marketing.template.title')} `}
                                </CustomGrid>
                            }

                            {data.emailTemplates.length > 0 &&
                                <Stack direction="row" sx={{ padding: "10px", marginRight: "50px", alignItems: "center" }}>
                                    <CustomGrid container={true} gap={2} width={200}>
                                        {data.emailTemplates !== null && data.emailTemplates.map((emailTemplate) => (
                                            <CustomEmailTemplateCard
                                                emailTemplate={emailTemplate}
                                                deleteEmailTemplate={deleteEmailTemplate}
                                                updateEmailTemplate={(updateEmailTemplate) => handleDialogState(true, updateEmailTemplate.id, updateEmailTemplate.name, false)}
                                                onDialog={false}
                                            />
                                        ))}
                                        <CustomAddEmailTemplateCard onClick={() => handleDialogState(true)} />
                                    </CustomGrid>
                                </Stack>
                            }
                        </CustomGrid>

                        <Fab color="primary"
                            aria-label="add"
                            sx={fabStyle()}
                            onClick={() => handleDialogState(true)}
                        >
                            <AddIcon />
                        </Fab>
                    </>
                }

                {alertBox.isOpen &&
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={3000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        handleSnackbarOpen={handleSnackbarState} />
                }

                {dialog.isOpen &&
                    <AddEditEmailTemplateDialog
                        existingEmailTemplate={!dialog.isAdd ? data.emailTemplates?.find(emailTemplate => emailTemplate.id === dialog.id) : undefined}
                        isOpen={dialog.isOpen}
                        isAdd={dialog.isAdd}
                        handleOpen={handleDialogState}
                        addEmailTemplate={addEmailTemplate}
                        updateEmailTemplate={updateEmailTemplate}
                        userAuth={data.userAuth.roles}
                    />
                }

                {deleteDialog.isOpen &&
                    <CustomDialog
                        isOpen={deleteDialog.isOpen}
                        id="delete"
                        title={t('actions.deleteModal.title')}
                        actionLabel={t("actions.deleteModal.message")}
                        cancelLabel={t("actions.cancel")}
                        message={`${t('actions.deleteModal.notice')} ${t('actions.deleteModal.confirm')} ${t('marketing.template.title')}: `}
                        boldMessage={<span style={{ fontWeight: "bold" }}>
                            {deleteDialog.name !== "" ? `"${deleteDialog.name}"` : ""}
                        </span>}
                        action={deleteET}
                        handleOpen={handleDeleteDialogState}
                        actionColor="#e91e1e"
                    />
                }
            </CustomGrid>
        );
    }
}

export default EmailTemplateListComponent;