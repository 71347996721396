import moment from "moment";

/**
 * Check if a string contains text from an array of substrings.
 * 
 * @param {String} str the full string
 * @param {*} substrings array of substrings to check
 * 
 * @returns true if string contains text from an array of substrings.
 */
export function isStringContainsTextFromArrayOfSubstrings(str, substrings) {
    return substrings.some(v => str.includes(v));
}

/**
 * Capitalizes the first letter of a string and converts the rest of the letters to lowercase.
 * @param {string} string - The input string.
 * @returns {string} The capitalized string.
 */
export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function equalsIgnoreCase(str1, str2) {
    if (str1 === undefined || str2 === undefined) return false;
    return str1.toLowerCase() === str2.toLowerCase();
}

/**
 * Calcuates the days left between two days
 * 
 * @param {String} date1 the full string
 * @param {String} date2 the full string
 * 
 * @returns the remaing days count
 */
export function days_between(date1, date2 = new Date()) {
    // The number of milliseconds in one day
    const ONE_DAY = 1000 * 60 * 60 * 24;
    if (typeof date2 === "string")
        date2 = date2.substring(0, date2.length - 7);

    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(new Date(date1) - date2);

    // Convert back to days and return
    const differenceDays = Math.round(differenceMs / ONE_DAY);
    return differenceDays < 0 ? 0 : differenceDays
}

/**
 * Date formatter
 * 
 * @param {String} string the date to be formatted
 * @param {String} formatter the pattern for the date to be formatted
 * 
 * @returns the remaing days count
 */
export function dateTimeFormatter(string, formatter = "DD/MM/yyyy - HH:mm:ss") {
    return moment(new Date(string)).format(formatter);
}

/**
 * Formats a date and time based on a specified number of hours before the current time.
 * If the number of hours to go back is not provided, it calculates this value based on the
 * current time using the `calculateHoursBefore` function, which considers 4 AM as a reference
 * point for calculation. The function aims to provide flexibility in generating formatted
 * date strings for various applications, such as logging, displaying on UIs, or scheduling tasks.
 * 
 * @param {number} [hoursBefore] - Optional. The number of hours to go back from the current time
 *                                 to calculate the date and time for formatting. If undefined,
 *                                 it's calculated automatically based on the current hour.
 * @param {string} [formatter="yyyy-MM-DDTHH:mm:ss.SSSZ"] - Optional. The format string to use
 *                                 for formatting the date and time. Defaults to an extended version
 *                                 of the ISO 8601 format, including timezone information.
 * @return {string} The formatted date and time string, based on the calculated or provided
 *                  hoursBefore, and in the specified format.
 * 
 * Note: The actual formatting of the date and time is handled by the `dateTimeFormatter` function,
 * which is not defined in this snippet. This external function needs to support the specified
 * formatter string syntax and should be implemented or included from a library.
 */
export function dateTimeFormatterFilter(hoursBefore, formatter = "yyyy-MM-DDTHH:mm:ss.SSSZ") {
    let filterDate = new Date();
    if (hoursBefore === undefined) {
        let result = calculateHoursBefore(filterDate);
        hoursBefore = result.hours;
        // If minutes need to be cleaned, adjust the filterDate to the start of the hour
        if (result.cleanMinutes) {
            filterDate.setMinutes(0, 0, 0); // Resets minutes, seconds, and milliseconds
        }
    }
    return dateTimeFormatter(new Date((filterDate).getTime() - hoursBefore * 60 * 60 * 1000), formatter);
}

/**
 * Calculates the number of hours to go back from the current time to reach a reference time.
 * The reference time is based on the concept of "4 AM today" as a pivotal point.
 * If the current time is between midnight (0:00) and 4 AM (4:00), it calculates the hours
 * since 4 AM plus an additional 18 hours, effectively considering the time since 4 AM
 * the previous day. For times after 4 AM, it simply calculates the hours since 4 AM today.
 * 
 * @param {Date} now - The current date and time.
 * @return {number} The calculated number of hours before the current time, based on the
 *                  logic of reverting to 4 AM as a reference point. If the current time is
 *                  between midnight and 4 AM, it returns the count of hours since 4 AM of
 *                  the same day plus 18, effectively covering the period since 4 AM the
 *                  previous day. For times after 4 AM, it returns the hours passed since 4 AM.
 */
function calculateHoursBefore(now) {
    // Get the current hour
    const currentHour = now.getHours();

    if (currentHour <= 4) {
        // If the current hour is between midnight (0) and 4 AM, 
        // return the count of hours from 4 AM plus an additional 18 hours
        // This effectively considers the time since 4 AM the previous day.
        return { hours: currentHour + 20, cleanMinutes: true };// Adjusting as per the new logic
    } else {
        // Calculate the hours passed since 4 AM for hours beyond 4 AM
        const hoursPassed = currentHour - 4;
        return { hours: hoursPassed, cleanMinutes: true };
    }
}

/**
 * Create url params from an object.
 * 
 * @param {String} url the url to include the parameters
 * @param {object} params the list of parameteres to be included at the end of the url.
 * 
 * @returns the formatted url with the parameters at the end.
 */
export function includeParamsToUrl(url, params) {
    if (!params) return url;

    let query = Object.keys(params).map(key => {
        let value = params[key];
        // console.log(typeof value, key, value)
        if (typeof value === 'object' && value !== null) {
            return Object.keys(value).map(subKey => {
                return `${encodeURIComponent(key)}${encodeURIComponent("[")}${encodeURIComponent(subKey)}${encodeURIComponent("]")}=${encodeURIComponent(value[subKey])}`;
            }).join('&');
        } else {
            return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        }
    }).join('&');
    return url + (query ? `?${query}` : '');
}

/**
 * Insert element to array
 * 
 * @param {array} arr the previous array
 * @param {int} index the index of the object to be inserted at the arr
 * @param {object} newItem the new object to be inserted
 * 
 * @returns the new array with the added object at a specific index
 */
export function insertToArray(arr, index, newItem) {
    return [
        // part of the array before the specified index
        ...arr.slice(0, index),
        // inserted item
        newItem,
        // part of the array after the specified index
        ...arr.slice(index)
    ]
}

/**
 * Checks if the object is empty.
 * @param {*} obj the object 
 * @returns true if the object is empty
 */
export function objectIsEmpty(obj) {
    return Object.keys(obj).length === 0;
}

export function validatePhoneNumber(phoneNumber) {
    // Create a regular expression for phone numbers
    const phoneRegex = /^\+[0-9]{1,3}\.[0-9]{4,14}(?:x.+)?$/;

    // Check if the phone number matches the regular expression
    if (!phoneRegex.test(phoneNumber)) {
        return 'Invalid phone number';
    }

    // If the phone number is valid, return null
    return "";
}

/**
 * Validates the email format using a regular expression.
 *
 * @param {string} email - The email address to validate.
 * @returns {boolean} - Whether the email address is valid or not.
 */
export function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

/**
 * Validates a VAT number and returns the country code and number if valid.
 *
 * @param {string} vatNumber - The VAT number to validate.
 * @returns {Object|null} An object with the `countryCode` and `number` properties if the input is a valid VAT number, or `null` if not.
 *
 * @example
 *
 * const input = 'DE123456789';
 * const result = validateVatNumber(input);
 * result: { countryCode: 'DE', number: '123456789' }
 *
 * @example
 *
 * const input = '1234';
 * const result = validateVatNumber(input);
 * result: null
 */
export function validateVatNumber(vatNumber) {
    const regexMap = {
        // Add regex patterns for each country's VAT number format
        // The patterns should match the entire input string // European Union (EU) VAT numbers
        AT: /^(AT)?U\d{8}$/,
        BE: /^(BE)?0?\d{9}$/,
        BG: /^(BG)?\d{9,10}$/,
        CY: /^(CY)?[0-59]\d{7}[A-Z]$/,
        CZ: /^(CZ)?\d{8,10}$/,
        DE: /^(DE)?[1-9]\d{8}$/,
        DK: /^(DK)?\d{8}$/,
        EE: /^(EE)?\d{9}$/,
        EL: /^(EL|GR)?\d{9}$/,
        ES: /^(ES)?[A-Z]\d{8}$/,
        FI: /^(FI)?\d{8}$/,
        FR: /^(FR)?[A-HJ-NP-Z0-9]{2}\d{9}$/,
        GB: /^(GB)?(\d{9}|\d{12}|GD\d{3}|HA\d{3})$/,
        HR: /^(HR)?\d{11}$/,
        HU: /^(HU)?\d{8}$/,
        IE: /^(IE)?\d{7}[A-W][A-IW]{0,1}$/,
        IT: /^(IT)?\d{11}$/,
        LT: /^(LT)?(\d{9}|\d{12})$/,
        LU: /^(LU)?\d{8}$/,
        LV: /^(LV)?\d{11}$/,
        MT: /^(MT)?[1-9]\d{7}$/,
        NL: /^(NL)?\d{9}B\d{2}$/,
        PL: /^(PL)?\d{10}$/,
        PT: /^(PT)?\d{9}$/,
        RO: /^(RO)?[1-9]\d{1,9}$/,
        SE: /^(SE)?\d{10}01$/,
        SI: /^(SI)?[1-9]\d{7}$/,
        SK: /^(SK)?\d{10}$/,
        // Non-EU countries
        CH: /^(CHE)?(\d{9})(MWST)?$/,
        NO: /^(NO)?\d{9}MVA$/,
        US: /^(US)?[0-9]{2}-[0-9]{7}$/,
    };

    // Check vatNumber against each pattern
    for (const countryCode in regexMap) {
        const regex = regexMap[countryCode];
        const match = vatNumber.match(regex);
        if (match) {
            return {
                countryCode: countryCode,
                number: match[0].replace(countryCode, '').replace(/^0+/, ''),
            };
        }
    }

    // If no pattern matched, vatNumber is not a valid VAT number
    return null;
}
/**
 * Validates the input number and returns it if it is a positive number or zero.
 * @param {string} input - The input number to be validated.
 * @returns {string|undefined} - The validated input number, or undefined if it is not a positive number or zero.
 */
export function validateInput(input, length = -1) {
    return input.length <= length;
};


/**
 * Validates the input number and returns it if it is a positive number or zero.
 * @param {string} input - The input number to be validated.
 * @returns {string|undefined} - The validated input number, or undefined if it is not a positive number or zero.
 */
export function validateInputNumber(input, length = -1, min = null, max = null, isFloat = false, mustHaveSizeLegth = false, field = "", allowZero = false, fixedPointForFloat = 2) {
    if (input.length === 0 && field === "")
        return allowZero ? 0 : "";
    else if (input.length === 0 && field === "printNodeId")
        return undefined;
    // Regex pattern to match positive numbers or zero
    // Remove any non-digit characters
    let sanitizedValue;
    if (isFloat) {
        sanitizedValue = input.replace(/[^0-9.]/g, '');
    } else {
        sanitizedValue = input.replace(/[^0-9]/g, '');
    }

    sanitizedValue = sanitizedValue.replaceAll("+", "").replaceAll("e", "").replaceAll("-", "")
    // Convert the sanitized value to a number
    const numericValue = isFloat ? parseFloat(sanitizedValue).toFixed(fixedPointForFloat) : parseInt(sanitizedValue);

    console.log(sanitizedValue)
    console.log(numericValue)
    if (isFloat) {
        let integer = parseInt(sanitizedValue);
        let floatDigits = numericValue.toString().split(".")[1];
        let finalNumber = integer;
        if (!isNaN(finalNumber) && finalNumber >= 0) {
            if (min !== null && finalNumber < min)
                return min;
            else if (max !== null && finalNumber > max)
                return max
            else if (isNaN(finalNumber)) return "";
            else if (length > 0 && !mustHaveSizeLegth)
                if (sanitizedValue.length > length)
                    finalNumber = finalNumber.toString().slice(0, length);
                // else
                //     finalNumber = finalNumber;

            finalNumber = (length > 0 && mustHaveSizeLegth) ? finalNumber.toString().slice(0, length) : finalNumber.toString();
            finalNumber = parseFloat(`${finalNumber}.${floatDigits}`);
            if (finalNumber > max)
                finalNumber = max;
            return finalNumber
        }
    } else {
        if (!isNaN(numericValue) && numericValue >= 0) {
            if (length > 0 && !mustHaveSizeLegth)
                if (sanitizedValue.length > length)
                    return numericValue.toString().slice(0, length)
                else
                    return numericValue.toString()
            else if (min !== null && numericValue < min)
                return min;
            else if (max !== null && numericValue > max)
                return max
            else if (isNaN(numericValue)) return "";

            return length > 0 && mustHaveSizeLegth ? numericValue.toString().slice(0, length) : numericValue.toString();
        } else {
            return ""
        }
    }
};

/**
 * Validates input to ensure it has a length of 8 and contains only numbers.
 * @param {string} input - The input to be validated.
 * @returns {boolean} - True if the input is valid, false otherwise.
 */
export function validatePrintNodeIdInput(input) {
    const regex = /^[0-9]{8}$/;
    return regex.test(input);
}


export function handleKeyDownNumberTextField(event) {
    console.log(event)
    // Prevent input of "+" and "-" symbols
    const disallowedKeys = ['+', '-', 'e'];
    if (disallowedKeys.includes(event.key)) {
        event.preventDefault();
    }
};

/**
 * Flter an array of objects based on an array of values at a specific field and return the
 * matching objects in the same order as values array.
 * 
 * @param {*} instances the instances ids
 * @param {*} instanceOptionList  the instace option list
 * @returns 
 */
export function findAllObjectsMatchInArrayOrdered(instances, instanceOptionList) {
    return instanceOptionList.filter((instance) => instances.includes(instance.id)).sort((a, b) => instances.indexOf(a.id) - instances.indexOf(b.id))
}

export function openInNewTab(url) {
    window.open(url, '_blank');
}

/**
 * Callback that calculates the total size of the image and returns the appropriate size suffix.
 * @param {*} files 
 * @returns 
 */
export function getTotalFilesSizeMessage(files) {
    let totalSize = 0;
    let i;
    if (typeof files[0].size === "number") {
        for (i = 0; i < files.length; i++) {
            if (files[i].size) {
                totalSize += files[i].size || 0;
            }
        }
    } else {
        return "";
    }
    totalSize /= 1024;
    if (totalSize < 1024) {
        return totalSize.toFixed(2) + " KB";
    } else {
        return (totalSize / 1024).toFixed(2) + " MB";
    }
};

/**
 * Callback that returns the icon class for the file extension given
 * @param {*} file 
 * @returns an icon class based on the extension of a file’s extension.
 */
export function getFileExtensionIcon(file) {
    switch (file.extension) {
        case ".png":
        case ".jpg":
        case ".jpeg":
        case ".tiff":
        case ".bmp":
        case ".gif":
            return "k-i-file-image";
        case ".mp3":
        case ".mp4":
        case ".wav":
            return "k-i-file-audio";
        case ".mkv":
        case ".webm":
        case ".flv":
        case ".gifv":
        case ".avi":
        case ".wmv":
            return "k-i-file-video";
        case ".txt":
            return "k-i-file-txt";
        case ".pdf":
            return "k-i-file-pdf";
        case ".ppt":
        case ".pptx":
            return "k-i-file-presentation";
        case ".csv":
        case ".xls":
        case ".xlsx":
            return "k-i-file-data";
        case ".html":
        case ".css":
        case ".js":
        case ".ts":
            return "k-i-file-programming";
        case ".exe":
            return "k-i-file-config";
        case ".zip":
        case ".rar":
            return "k-i-file-zip";
        default:
            return "k-i-file";
    }
};


export function checkFileIsImage(file) {
    if (!file.type.includes('image/')) {
        return 'assets.allowImageFilesOnly';
    }
    return null;
};

export function checkFileSizeLimit(file) {
    // Check if the file size is within the limit (2MB)
    const maxSize = 2 * 1024 * 1024; // 2MB in bytes
    if (file.size > maxSize) {
        return 'assets.imageSizeLimitError';
    }
    return null;
};

export function findCurrency(currencyCode) {
    switch (currencyCode) {
        case "EUR":
            return "€"
        default:
            return "€"
    }
}

export function prepareTablePaginationSortParams(pagination) {
    if (pagination?.sort !== undefined)
        return { page: pagination.page, size: pagination.size, sort: `${pagination.sort},${pagination.direction}` };
    else return pagination
}

export function findNameMultilingual(name, lang) {
    let requestedNameLang = name[lang.slice(0, 2)];
    if (requestedNameLang !== null && requestedNameLang !== "")
        return requestedNameLang;
    else
        return name.en
}

export function calculateTimePassed(fromDate) {
    const startDate = new Date(fromDate);
    const currentDate = new Date();

    // const timeDiffSeconds = Math.floor((currentDate - startDate) / 1000);
    const timeDiffMinutes = Math.floor((currentDate - startDate) / (1000 * 60));
    const timeDiffHours = Math.floor(timeDiffMinutes / 60);
    const timeDiffDays = Math.floor(timeDiffMinutes / (60 * 24));

    if (timeDiffMinutes < 1) {
        return 'now';
        // } else if (timeDiffMinutes < 1) {
        //    return `${timeDiffSeconds} sec ago`;
    } else if (timeDiffMinutes < 60) {
        return `${timeDiffMinutes} min ago`;
    } else if (timeDiffHours < 24) {
        return `${timeDiffHours} hours ago`;
    } else if (timeDiffDays < 2) {
        return 'Yesterday';
    } else {
        return `${timeDiffDays} days ago`;
    }
}

/**
 * Removes an item from an array based on its index.
 *
 * @param {Array} array - The array from which the item will be removed.
 * @param {number} index - The index of the item to remove.
 * @returns {Array} A new array with the item removed.
 */
export function removeItemAtIndex(array, index) {
    // Copy the array to avoid mutating the original array
    let newArray = [...array];

    // Check if index is within the bounds of the array
    if (index > -1 && index < newArray.length) {
        newArray.splice(index, 1); // Removes the item at the specified index
    }

    return newArray;
}

export function validateIPAddress(value) {
    // Regular expression to validate IP address (IPv4 and IPv6)
    const ipRegex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$|^([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}$|^([0-9a-fA-F]{1,4}:){1,7}:$|^([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}$|^([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}$|^([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}$|^([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}$|^([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}$|^[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})$|:^:$|^::[fF]{4}(:0{1,4}){0,1}$|^[fF]{4}(:0{1,4}){0,1}:$/;

    // Check if input value matches the IP regex
    return ipRegex.test(value);
};

export function validatePort(value) {
    value = parseInt(value);
    // Regular expression to match port numbers (range 0-65535)
    const portRegex = /^(0|([1-9]\d{0,3})|([1-5]\d{4})|(6[0-4]\d{3})|(65[0-4]\d{2})|(655[0-2]\d)|6553[0-5])$/;

    // Check if input value matches the port regex
    return portRegex.test(value);
};

/**
* Returns the input value if it's truthy, otherwise returns "N/A".
*
* This function checks if the provided value is truthy (i.e., has a value that is
* considered true in a boolean context). If it is truthy, the function returns
* the value itself. If not (i.e., the value is falsy), it returns the string "N/A".
* 
* Falsy values in JavaScript include `false`, `0`, `""` (empty string), `null`,
* `undefined`, and `NaN`.
*
* @param {*} value - The value to be checked and potentially returned.
* @returns {*} Returns the original value if it's truthy, otherwise returns "N/A".
*/
export function clearBeforePresent(value) {
    return value ? value : "N/A";
}

export function parsePageable(pageable) {
    return {
        ...pageable,
        sort: pageable.sort + "," + pageable.direction
    };
}

export function replaceLastTwoChars(inputString, replacement) {
    if (inputString === null)
        return "#47a6d21c";
    // Check if the inputString has at least 2 characters
    if (inputString?.length >= 8) {
        // Use slice to get all characters except the last 2, and concatenate the replacement
        return inputString.slice(0, -2) + replacement;
    } else {
        // Handle the case where the string has less than 2 characters
        // console.error("Input string should have at least 2 characters");
        return inputString + replacement;
    }
}

/**
 * Merges two lists based on a common `id` property. Items from the `baseList` will be enriched
 * with additional properties from the `referenceList` if a match is found.
 *
 * @param {Array<Object>} baseList - The primary list to transform. Each item must have an `id` property.
 * @param {Array<Object>} referenceList - The secondary list providing additional properties. Each item must have an `id` property.
 * @returns {Array<Object>} A new array where each item from the `baseList` is enriched with properties from the `referenceList` if an `id` match is found.
 *
 * @example
 * const baseList = [
 *   { id: 1, quantity: 10 },
 *   { id: 3, quantity: 5 },
 * ];
 *
 * const referenceList = [
 *   { id: 1, name: "Item 1", icon: "icon1" },
 *   { id: 2, name: "Item 2", icon: "icon2" },
 *   { id: 3, name: "Item 3", icon: "icon3" },
 * ];
 *
 * const mergedList = mergeLists(baseList, referenceList);
 *
 * console.log(mergedList);
 * // Output:
 * // [
 * //   { id: 1, quantity: 10, name: "Item 1", icon: "icon1" },
 * //   { id: 3, quantity: 5, name: "Item 3", icon: "icon3" },
 * // ]
 */
export function mergeLists(baseList, referenceList, fieldFirst = "id", fieldSecond = "id") {
    return baseList.map(baseItem => {
        const matchedItem = referenceList.find(refItem => refItem[fieldSecond] === baseItem[fieldFirst]);
        return {
            ...baseItem,
            ...(matchedItem || {}), // Merge properties from matched item, if found
        };
    });
}


export default class functions {
    static isStringContainsTextFromArrayOfSubstrings(str, substrings) { return isStringContainsTextFromArrayOfSubstrings(str, substrings); }
    static equalsIgnoreCase(str1, str2) { return equalsIgnoreCase(str1, str2); }
    static days_between(date1, date2) { return days_between(date1, date2); }
    static dateTimeFormatter(string, formatter) { return dateTimeFormatter(string, formatter); }
    static includeParamsToUrl(url, params) { return includeParamsToUrl(url, params); }
    static insertToArray(arr, index, newItem) { return insertToArray(arr, index, newItem); }
    static objectIsEmpty(obj) { return objectIsEmpty(obj); }
    static findAllObjectsMatchInArrayOrdered(instances, instanceOptionList) { return findAllObjectsMatchInArrayOrdered(instances, instanceOptionList); }
    static validatePhoneNumber(phoneNumber) { return validatePhoneNumber(phoneNumber) }
    static isValidEmail(email) { return isValidEmail(email); }
    static validateVatNumber(vatNumber) { return validateVatNumber(vatNumber); }
    static validateInputNumber(input, length, min, max, field) { return validateInputNumber(input, length, min, max, field); }
    static validatePrintNodeIdInput(input) { return validatePrintNodeIdInput(input); }
    static handleKeyDownNumberTextField(event) { return handleKeyDownNumberTextField(event); }
    static capitalizeFirstLetter(string) { return capitalizeFirstLetter(string); }
    static validateInput(input, length) { return validateInput(input, length); }
    static openInNewTab(url) { return openInNewTab(url); }
    static checkFileIsImage(file) { return checkFileIsImage(file); }
    static checkFileSizeLimit(file) { return checkFileSizeLimit(file); }
    static findCurrency(currencyCode) { return findCurrency(currencyCode); }
    static prepareTablePaginationSortParams(pagination) { return prepareTablePaginationSortParams(pagination); }
    static findNameMultilingual(name, lang) { return findNameMultilingual(name, lang); }
    static removeItemAtIndex(array, index) { return removeItemAtIndex(array, index); }
    static clearBeforePresent(value) { return clearBeforePresent(value); }
    static mergeLists(baseList, referenceList) { return mergeLists(baseList, referenceList); }
}