import { CloseRounded as CloseRoundedIcon } from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    Switch
} from '@mui/material';
import CustomTextField from 'components/Text/CustomTextField';
import { getDefaultEmailTemplateObject } from 'constants/defaultObjects';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';
import { validateInput } from 'utils/functions';
// Import the JSON translations
import enTranslations from 'constants/languages/emailEditor/en.json';
import elTranslations from 'constants/languages/emailEditor/el.json';
// email editor
import { getLanguageFromURL } from 'utils/language';
import sample from './sample.json';
import 'assets/scss/campaign.scss'; // Import custom style for editor
import EmailEditor from 'react-email-editor';
import { hasRoleAdminOrg } from 'utils/auth';
import SunEditor from 'suneditor-react';
import { sortedFontOptions } from 'utils/fonts';

/**
 * The AddEditEmailTemplateDialog, that display a customized dialog to add/edit an email template.
 *
 * @property {object} existingEmailTemplate the object containing details for email template
 * @property {boolean} isOpen If the values is `true`, the modal should be open and visible. Default value is false.
 * @property {boolean} isAdd If the values is `true`, the modal refering to creation of a new email template.
 * @property {function} handleOpen the function that handles the dialog open state
 * @property {function} addEmailTemplate the function that handles the creation of an email template
 * @property {function} updateEmailTemplate the function that handles the modification of an email template
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function AddEditEmailTemplateDialog({
    existingEmailTemplate,
    isOpen = false,
    isAdd = true,
    handleOpen,
    addEmailTemplate,
    updateEmailTemplate,
    roles
}) {
    const { t } = useTranslation();
    const [emailTemplate, setEmailTemplate] = useState(isAdd ? getDefaultEmailTemplateObject() : existingEmailTemplate);

    // @property {boolean} if the values is `true`, the modal should be open and visible.
    const [open, setOpen] = useState(isOpen);

    const [showAdvanceEditor, setShowAdvanceEditor] = useState(true);

    useEffect(() => {
        if (!isAdd)
            setEmailTemplate(existingEmailTemplate)
    }, [existingEmailTemplate]);

    /**
     * Function that handles the visualize modal open or close state.
     * @property {boolean} isOpened If the values is `true`, the modal should be open and visible.
     */
    function handleDialogState(isOpened) {
        setOpen(isOpened);
        handleOpen(isOpened);
    }

    function handleAction() {
        if (showAdvanceEditor) {
            const unlayer = emailEditorRef.current?.editor;
            unlayer?.exportHtml((data) => {
                const { design, html } = data;
                emailTemplate.designJSON = design;
                emailTemplate.bodyHTML = html;

                if (isAdd) {
                    addEmailTemplate(emailTemplate)
                } else {
                    updateEmailTemplate(emailTemplate);
                }
                // console.log('exportHtml', html);
            });
        } else {
            emailTemplate.designJSON = null
            emailTemplate.bodyHTML = !message.includes("hmtl") ? `<html><body>${message}</body></html>` : message
            if (isAdd) {
                addEmailTemplate(emailTemplate)
            } else {
                updateEmailTemplate(emailTemplate);
            }
        }
    }

    // ------ EMAIL
    const emailEditorRef = useRef(null);
    const [preview, setPreview] = useState(false);
    const [locale, setLocale] = useState(getLanguageFromURL()); // Default locale
    const [translations, setTranslations] = useState(enTranslations); // Default translations

    // Load the appropriate translations based on the locale
    useEffect(() => {
        if (locale === 'en') {
            setTranslations(enTranslations);
        } else if (locale === 'el') {
            setTranslations(elTranslations);
        }
    }, [locale]);

    // Toggle preview mode
    const togglePreview = () => {
        const unlayer = emailEditorRef.current?.editor;

        if (preview) {
            unlayer?.hidePreview();
            setPreview(false);
        } else {
            unlayer?.showPreview('desktop');
            setPreview(true);
        }
    };

    // Hide branding on editor load
    const hideBranding = () => {
        const brandingElement = document.querySelector('a.blockbuilder-branding');
        if (brandingElement) {
            brandingElement.style.visibility = 'hidden';
        }
    };

    // --------- SUN editor
    const editorRef = useRef(null);
    // handle new message form
    const [message, setMessage] = useState(isAdd ? "" : emailTemplate.bodyHTML);

    // ======================================================= RENDER
    return (
        <>
            <Dialog
                open={open}
                // onClose={() => { handleDialogState(false) }}  //to avoid accidental backdrop click to close before saving
                aria-describedby="alert-dialog-description"
                className="dialog-title"
                sx={{
                    "& .MuiDialog-paper": {
                        maxWidth: "97%"
                    }
                }}
            >
                <DialogTitle className="dialog-headers">
                    {isAdd ? `${t('actions.add')} ${t('marketing.template.title')}` : `${t('actions.edit')} ${t('marketing.template.title')} '${(emailTemplate.name !== "" && emailTemplate.name !== null) ? emailTemplate.name : ""}'`}
                    <IconButton onClick={() => { handleDialogState(false) }} sx={{ float: "right" }}>
                        <CloseRoundedIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ background: "#fff" }}>
                    <Grid container gap={1}>
                        <Grid item xs={12}>
                            <InputLabel required={false} htmlFor="name">{t('marketing.template.name')}</InputLabel>
                            <CustomTextField
                                type="text"
                                name="name"
                                id="name"
                                value={emailTemplate.name}
                                onChange={(event) => {
                                    let value = event.target.value;
                                    if (!validateInput(value, 20)) value = emailTemplate.name;
                                    formValChangeWithParentElementWithNameAndValue("name", value, emailTemplate, setEmailTemplate)
                                }}
                            />
                        </Grid>

                        {/* {hasRoleAdminOrg(roles) && */}
                        <Grid item xs={12} sx={{ paddingTop: "20px" }}>
                            <FormControlLabel control={<Switch checked={showAdvanceEditor}
                                onChange={(event) => setShowAdvanceEditor(event.target.checked)} />}
                                label={t('marketing.template.showAdvanceEditor')}
                            />
                        </Grid>
                        {/* } */}

                        <Grid item xs={12} sx={{ paddingTop: "20px" }}>
                            {!showAdvanceEditor &&
                                <SunEditor
                                    mode='classic'
                                    showPathLabel={false}
                                    autoFocus
                                    setDefaultStyle='font-family: Inter; font-size: 14px; border-radius:6px;'
                                    setOptions={{
                                        height: "600px",
                                        katex: "window.katex",
                                        resizeEnable: true,
                                        resizingBar: false,
                                        font: sortedFontOptions,
                                        buttonList: [
                                            [
                                                "undo",
                                                "redo"
                                            ],
                                            [
                                                "font",
                                                "fontSize",
                                                "formatBlock",
                                            ],
                                            [
                                                "bold",
                                                "underline",
                                                "italic",
                                                "strike",
                                                "subscript",
                                                "superscript"
                                            ],
                                            [
                                                "fontColor",
                                                "hiliteColor"
                                            ],
                                            [
                                                "align",
                                                "list",
                                                "lineHeight"
                                            ],
                                            [
                                                "outdent",
                                                "indent"
                                            ],
                                            [
                                                "table",
                                                "horizontalRule",
                                                "link",
                                            ],
                                            [
                                                'fullScreen',
                                                'showBlocks',
                                                'codeView'
                                            ],
                                            [
                                                'preview',
                                                'print'
                                            ],
                                            ["removeFormat"]
                                        ],
                                        defaultTag: "div",
                                    }}
                                    className={"editor"}
                                    placeholder='Message'
                                    defaultValue={message}
                                    ref={editorRef}
                                    onChange={(content) => setMessage(content)}
                                />
                            }

                            {showAdvanceEditor &&
                                <EmailEditor
                                    ref={emailEditorRef}
                                    onLoad={() => {
                                        // console.log('Editor loaded');
                                        // hideBranding(); // Hide branding after the editor loads
                                    }}
                                    onReady={() => {
                                        if (!isAdd && existingEmailTemplate?.designJSON) {
                                            emailEditorRef.current.editor.loadDesign(existingEmailTemplate?.designJSON);
                                        }
                                    }}
                                    // locale='gr'
                                    options={{
                                        version: 'latest',
                                        appearance: {
                                            theme: 'modern_light',
                                            // loader: {
                                            //     url: 'https://example.com/loader.gif'  // URL of the custom loading spinner image
                                            // },
                                            panels: {
                                                tools: {
                                                    dock: 'left',
                                                    collapsible: true
                                                }
                                            }
                                        },
                                        displayMode: "email",
                                        // locale: locale,
                                        translations: {
                                            "en-US": translations
                                        },
                                        // customCSS: [
                                        //     `
                                        //        a.blockbuilder-branding: {
                                        //             visibility: hidden
                                        //         }
                                        //     `
                                        // ]
                                    }}
                                    style={{
                                        minHeight: "700px",
                                        // height: "90%"
                                    }}
                                />
                            }
                        </Grid>

                    </Grid>
                </DialogContent>

                <DialogActions className="dialog-actions">
                    <Button className="dialog-cancel-btn" onClick={() => { handleDialogState(false) }}>{t("actions.cancel")}</Button>
                    <Button className="dialog-action-btn" onClick={() => { handleAction() }}>{isAdd ? t('actions.create') : t('actions.save')}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default AddEditEmailTemplateDialog;