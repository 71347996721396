import { Button, Grid, TextField } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { endOfMonth, endOfWeek, endOfYear, startOfMonth, startOfWeek, startOfYear, subDays } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dateTimeFormatterFilter } from 'utils/functions';

function CustomMuiDateTimeRangePicker({ dateRange = { dateFrom: null, dateTo: null }, setDateRange }) {
    const [setIsMobile] = useState(window.innerWidth < 768);
    const { t } = useTranslation();

    const updateWindowDimensions = () => {
        setIsMobile(window.innerWidth < 768);
    };

    useEffect(() => {
        window.addEventListener('resize', updateWindowDimensions);
        return () => window.removeEventListener('resize', updateWindowDimensions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const shortcuts = [
        { label: t('date.today'), range: { dateFrom: dateTimeFormatterFilter(), dateTo: null } },
        { label: t('date.lastWeek'), range: { dateFrom: startOfWeek(subDays(new Date(), 7)), dateTo: endOfWeek(subDays(new Date(), 7)) } },
        { label: t('date.last7Days'), range: { dateFrom: subDays(new Date(), 7), dateTo: new Date() } },
        { label: t('date.currentMonth'), range: { dateFrom: startOfMonth(new Date()), dateTo: endOfMonth(new Date()) } },
        { label: t('date.currentYear'), range: { dateFrom: startOfYear(new Date()), dateTo: endOfYear(new Date()) } },
        { label: t('date.reset'), range: { dateFrom: null, dateTo: null } }
    ];

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Grid container spacing={2}>
                        {shortcuts.map((shortcut) => (
                            <Grid item xs={12} >
                                <Button key={shortcut.label} onClick={() => setDateRange((prev) => ({ ...prev, ...shortcut.range }))}>
                                    {shortcut.label}
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Grid item xs={6} sx={{ marginTop: "20px" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <DateTimePicker
                                renderInput={(props) => <TextField {...props} />}
                                label="Start Date"
                                value={dateRange.dateFrom}
                                onChange={(newValue) => setDateRange((prev) => ({ ...prev, dateFrom: newValue }))}
                                fullWidth
                                PopperProps={{
                                    sx: {
                                        zIndex: 3000
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <DateTimePicker
                                renderInput={(props) => <TextField {...props} />}
                                label="End Date"
                                value={dateRange.dateTo}
                                onChange={(newValue) => setDateRange((prev) => ({ ...prev, dateTo: newValue }))}
                                fullWidth
                                PopperProps={{
                                    sx: {
                                        zIndex: 3000
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </LocalizationProvider>
    );
};

export default CustomMuiDateTimeRangePicker;
