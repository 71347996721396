import { request } from 'constants/alias';
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from 'utils/functions';
import updateFieldIfValid from 'utils/functions/updateFieldIfValid';

/**
 * The rest request which attempts to create a new settings.
 * @param {*} data A JSON object which contains new settings infromation
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function fetchInfo(search) {
    let data = {};
    updateFieldIfValid(data, "organizationID", search.organizationID);
    updateFieldIfValid(data, "outletID", search.outletID);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}display/pickup`, data));
    else return await request.get(includeParamsToUrl(`${API}display/pickup`));
}

/**
 * The rest request which attempts to fetch  settings.
 * @param {*} data A JSON object which contains a specific outlet settings infromation
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function fetchOne(search) {
    let data = {};
    updateFieldIfValid(data, "organizationID", search.organizationID);
    updateFieldIfValid(data, "outletID", search.outletID);
    
    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}display/pickup/settings`, data));
    else return await request.get(includeParamsToUrl(`${API}display/pickup/settings`));
}

/**
 * The rest request which attempts to update an existing settings.
 * @param {*} data A JSON object which contains the updated infromation of the settings
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function update(data) {
    return await request.put(`${API}display/pickup/settings`, data);
}


const pickupDisplayApi = {
    fetchOne,
    fetchInfo,
    update
}

export default pickupDisplayApi;