import { createTheme, TextField, ThemeProvider } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

/**
 * The Custom DateTime Picker, that display customized {@link DateTimePicker}.
 * 
 * @property {string} id the text field id
 * @property {string} name the text field name
 * @property {string} type the text field type
 * @property {string} className the text field class
 * @property {string} label Applies the theme typography styles.
 * @property {string} defaultValue the text field default value
 * @property {string} placeholder the text field placeholder
 * @property {string} helperText the text field helper text
 * @property {boolean} required if the value is required at this text field 
 * @property {boolean} error if text field is at an error state
 * @property {function} onChange function that triggered when value is changed
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomDateTimePicker({ name, inputFormat, value, onChange, label, disabled = false, minDateTime }) {

    return (
        <ThemeProvider theme={createTheme({
            components: {
                MuiCalendarPicker: {
                    styleOverrides: {
                        root: {
                            background: "white"
                        }
                    }
                },
                MuiCalendarOrClockPicker: {
                    styleOverrides: {
                        root: {
                            background: "white"
                        }
                    }
                }
            }
        })}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                    renderInput={(props) => <TextField {...props} />}
                    name={name}
                    inputFormat={inputFormat}
                    value={value}
                    onChange={onChange}
                    label={label}
                    disabled={disabled}
                    minDateTime={minDateTime}
                />
            </LocalizationProvider>
        </ThemeProvider>
    );
}

export default CustomDateTimePicker;