const EN = {
    register: "Register",
    registerSuccess: "You have been registered successfully.",
    signIn: "Sign in",
    signUp: "Sign up",
    login: "Login",
    logout: "Logout",
    signUpHere: "Sign up here!",
    forgotPassword: "Forgot Password?",
    noAccount: "Don't have an account?",
    haveAccount: "Already have an account?",
    backToSignIn: "Back to Sign In",
    username: "Username",
    usernameRequired: "Username is required",
    usernameRule:
        "Username must be at least 4 characters long and contain only letters, numbers, underscore is optional",
    password: "Password",
    aboutLabel: "About",
    privacyPolicyLabel: "Privacy Policy",
    termsOfUseLabel: "Terms of Use",
    aboutURLPath: "https://usee.gr",
    privacyPolicyURLPath: "/privacy-policy",
    termsOfUseURLPath: "/terms-and-conditions",
    signInURLPath: "/en/sign-in",
    forgotPasswordURLPath: "/en/forgot-password",
    errorPages: {
        pageNotFound: "The page you were looking for was not found!!",
        accessIsForbidden: "You are not allowed to access this page!!",
        authorizationFailed: "You are not authorized to access this page!!",
        somethingWentWrong:
            "Oops! Something’s wrong here. Please try again in five minute.",
        // "Oops! Something’s wrong here. Please contact the system administrator.",
    },
    error: {
        username: "Username already being used for another account",
        email: "Email already being used for another account",
        phone: "Phone already being used for another account",
        valueRequired: "The value is required",
        defaultOutletRequired: "You have to select the default outlet",
        vatNumberFormatError: "VAT number format is not correct",
    },
    auth: {
        username: "Username or email",
        code: "OTP code",
        enterVerfCode: "You'll receive a 8 digit verification code to your email.",
        verifyCode: "Verify your username or email",
        codeIsSentTo: "Code is sent to",
        didNotReceive: "Didn't receive code?",
        newPassword: "New password",
        resendNewCode: "Resend new code",
        resetPassword: "Reset Password",
        newPasswordLength: "Password should have at least length 8.",
        passwordsUnMatch: "New password and verify password do not match.",
    },
    button: {
        sendCode: "Send code",
        next: "Next",
        resetPassword: "Reset password",
    },
    actions: {
        label: "Actions",
        back: "Back",
        all: "All",
        add: "Add",
        cancel: "Cancel",
        clearAll: "Clear All",
        create: "Create",
        clickHere: "here",
        delete: "Delete",
        remove: "Remove",
        edit: "Edit",
        new: "New",
        reload: "Refresh",
        newItem: "New",
        overview: "Overview",
        save: "Save",
        saveAndCreateNew: "Save and create new",
        view: "View",
        drag: "Drag around to change the order.",
        duplicate: "Duplicate",
        tableCreateNewLink: " Create a new ",
        tableCreateNewLinkBulk: " Create new ",
        selectOption: "Select an option",
        sync: "Sync",
        search: "Search",
        searchFor: "Search for",
        upload: "Upload",
        download: "Download",
        pay: "Pay",
        hide: "Hide",
        undo: "Undo",
        cms: "CMS",
        for: " for ",
        powerOff: "Activate",
        powerOn: "Deactivate",
        send: "Send",
        update: "Update",
        export: "Export",
        import: "Import",
        uploadBtn: "Upload file",
        copy: "Copy",
        testPrint: "Test Print",
        exportQR: {
            titleEq: "Export QRcode for",
            title: "Export QRcode",
            titleEqs: "Export QRcodes",
            selectEquipments: "Select the equipments you would like to export their QRcodes.",
            selectTemplateEq: "Select the the template you would like to style your QRcode for equipment: ",
            selectTemplate: "Select the the template you would like to style your QRcodes .",
            selectTemplateEmpty: "There are no qrcode template. Click to add one.",
            viewMultiplePagePdfPromt: "After clicking `Generate PDF` button, click on one of the qrcode template to view the rendered pdf.",
            success: "Export successfully",
            actionButton: "Create PDF"
        },
        error: {
            addFailed: 'Fill in all details and then click the "add" button',
        },
        deleteModal: {
            title: "Delete Confirmation",
            message: "Delete",
            notice:
                "This action cannot be undone and you will be unable to recover any data. ",
            confirm: "Are you sure you want to delete ",
            confirmClear: "Are you sure you want to clear everything?",
            success: "Deleted successfully",
            from: "from"
        },
        removeModal: {
            title: "Removal Confirmation",
            message: "Remove",
            notice:
                "This action cannot be undone and you will be unable to recover any data. ",
            confirm: "Are you sure you want to remove ",
            confirmClear: "Are you sure you want to clear everything?",
            success: "Removed successfully",
            from: "from"
        },
        translationModal: {
            title: "Translations Configuration",
        },
        cancel: "Cancel",
        submit: "Submit",
        saveSuccessfully: "saved successfully",
        createSuccessfully: "created successfully",
        ok: "Ok"
    },
    table: {
        noData: "No data available.",
        valueReuired: "The value is required.",
        rowsPerPage: "Rows per page:",
        denseTable: "Dense Table",
        labels: {
            body: {
                noMatch: 'Sorry, no matching records found',
                toolTip: 'Sort',
            },
            pagination: {
                next: 'Next Page',
                previous: 'Previous Page',
                rowsPerPage: 'Rows per page:',
                displayRows: 'of',
                jumpToPage: 'Jump to Page:',
            },
            toolbar: {
                search: 'Search',
                downloadCsv: 'Download CSV',
                print: 'Print',
                viewColumns: 'View Columns',
                filterTable: 'Filter Table',
            },
            filter: {
                all: 'All',
                title: 'FILTERS',
                reset: 'RESET',
            },
            viewColumns: {
                title: 'Show Columns',
                titleAria: 'Show/Hide Table Columns',
            },
            selectedRows: {
                text: 'row(s) selected',
                delete: 'Delete',
                deleteAria: 'Delete Selected Rows',
            },
        }
    },
    label: {
        all: "All",
        profile: "Profile",
        id: "Id",
        name: "Name",
        displayName: "Display Name",
        username: "Username",
        password: "Password",
        firstName: "First Name",
        lastName: "Last Name",
        fatherName: "Father Name",
        email: "Email",
        emailConfigurations: "Email Configurations",
        emails: "Emails",
        address: "Address",
        city: "City",
        postalCode: "Postal Code",
        state: "State",
        country: "Country",
        phone: "Mobile",
        telephone: "TelePhone",
        roles: "Roles",
        dateCreated: "Created at",
        lastActivity: "Last activity",
        error: "Error",
        currentPassword: "Current Password",
        newPassword: "New Password",
        verifyPassword: "Retype New Password",
        description: "Description",
        type: "Type",
        currency: "Currency",
        organizationID: "Organization ID",
        planID: "Plan ID",
        dueDate: "dueDate",
        status: "Status",
        service: "Service",
        services: "Services",
        details: "Details",
        detail: "Detail",
        price: "Price",
        reason: "Reason",
        namePlaceholder: "Add at least 4 characters",
        labelPlaceholder: " ",
        key: "Key",
        value: "Value",
        isAvailable: "isAvailable",
        isEnabled: "Enabled",
        isDisabled: "Disabled",
        isActive: "Active",
        isNotActive: "Inactive",
        notificationWebEnabled: "Browser notifications",
        notificationEmailEnabled: "Email notifications",
        createPassword: "Create Password",
        changePassword: "Change your Password",
        jobDescription: "Job description",
        invocationAddress: "Invocation Address",
        configureTranslations: "Configure Translations",
        outletId: "Outlet Id",
        outletIds: "Outlet Ids",
        outletName: "Outlet Name",
        section: "Section",
        floor: "Floor",
        label: "Label",
        tags: "Tags",
        na: "N/A",
        subscriptionExpirationDate: "Active subscription Expiration date",
        smsCounter: "SMS Counter",
        emailCounter: "Email Counter",
        orderCounter: "Order Counter",
        smss: "SMSs",
        emails: "Emails",
        ticketCounter: "Tickets",
        receiptTicketCounter: "Receipts",
        vatNumber: "VAT Number",
        socialProfile: "Social Profile",
        action: "Action",
        actions: "Actions",
        notificationActions: "Notification Actions",
        chipListEditHelperText: "Double click to edit the value",
        subscriptionPlan: "Subscription Plan",
        single: "Single",
        multiple: "Multiple",
        duration: "Duration",
        days: " days left",
        csv: "CSV",
        json: "JSON",
        downloadCsvTemplate: "Download CSV Template",
        url: "Url"
    },
    page: {
        users: "Users",
        employees: "Employees",
    },
    form: {
        requiredField: "Field is required",
    },
    search: {
        all: "All",
    },
    organization: {
        logo: {
            type: {
                SQUARE: "SQUARE",
                RECTANGULAR: "RECTANGULAR"
            }
        },
    },
    menu: {
        dashboard: "Dashboard",
        stats: "Analytics",
        analytics: "Analytics",
        rating: "Ratings",
        organizations: "Organizations",
        organization: "Organization",
        outlets: "Outlets",
        subscriptions: "Subscriptions",
        subscription: "Subscription",
        subscriptionPlans: "Plans",
        menus: "Menus",
        itemsAndModifiers: "Items & Modifiers",
        offers: "Offers",
        modifierLists: "Modifier Lists",
        bookings: "Bookings",
        feedback: "Feedback Forms",
        loyalty: "Loyalty Settings",
        kitchenDisplay: "Kitchen Display",
        customers: "Customers",
        employees: "Employees",
        users: "Users",
        pickUpOrderDisplay: "Pickup Order Display",
        settings: "Settings",
        orders: "Orders",
        equipment: "Equipment",
        chat: "Support Chat",
        help: "Help",
        mysections: "My Sections",
        printers: "Printers",
        pos: "Pos",
        section: "Sections",
        requests: "Requests",
        pda: "PDA",
        inventory: "Inventory",
        displays: "Displays",
        campaign: "Campaigns",
        autoSave: "Auto save changes",
        active: "Active",
        deactive: "Deactive",
        marketing: "Marketing",
        emailTemplates: "Email Templates",
        recipients: "Recipients",
        groups: "Groups",
        waitlist: "Waitlist",
        campaign: {
            sms: "SMS Campaign",
            email: "Email Campaign"
        }
    },
    org: {
        title: "Organizations",
        pageTitle: "Organization",
        subtitle: "View Organizations",
        emptyMessage: "No organizations available.",
        addOrganizationLabel: "Add Organization",
        socialProfilesEmpty: "No Social Profiles have been added",
        emailsEmpty: "No emails have been added",
        tags: {
            ENABLE_RE_USE_OF_MENU: "Enable re-use of menu",
            ENABLE_RE_USE_OF_ITEM: "Enable re-use of item",
            ENABLE_SHARE_VAT_NUMBER_BETWEEN_OUTLETS: "Enable share VAT number between outlets "
        },
        monthsPassedWithoutOrderToMarkAsReturning: "Months passed without order to mark as returning customer",
        daysPassedFromLastOrderToMarkAsRecent: "Days passed from last order to mark as recent",
        monthsPassedWithoutOrderToMarkAsReturningAfterLabel: "months",
        daysPassedFromLastOrderToMarkAsRecentAfterLabel: "days",
        tabs: {
            details: "Details",
            socialProfiles: {
                label: "Social Profiles",
                info: "Enter your social profiles complete url here"
            },
            integrations: {
                label: "Integrations"
            },
            settings: {
                label: "Settings"
            },
            vat: {
                label: "VAT"
            }
        },
    },
    outlet: {
        title: "Outlets",
        pageTitle: "Outlet",
        subtitle: "Outlets Management",
        emptyMessage: "No outlets available.",
        addOutletLabel: "Add Outlet",
        extraNote: "Extra Note",
        photos: "Photos",
        url: "Url",
        logo: "Logo",
        email: "Email",
        inheritFromOrganization: "Same as organization",
        thirdPartyPosId: "Third Party POS id",
        hasCustomTicket: "Custom Ticket",
        printNodeIds: "Print Node Ids",
        printNodeId: "Print Node Id",
        equipmentIds: "Equipment Ids",
        employeeIds: "Employee Ids",
        emptyPrintNodeIds: "Outlet has no Print Node Ids Attached",
        labelBegginingText: "Label starting text",
        startNumber: "From",
        endNumber: "Until",
        googleReview: {
            locationIDInfo: {
                "step1": "1. Sign in to Google My Business",
                "step1Details": "Visit the Google My Business website and sign in using your Google account credentials.",
                "step2": "2. Navigate to Your Locations",
                "step2Details": "Once signed in, click on the 'Locations' tab on the left-hand side of the screen.",
                "step3": "3. Select the Location",
                "step3Details": "Click on the name or profile of the location you want to obtain the location ID for.",
                "step4": "4. View the URL",
                "step4Details": "Look at the URL in your browser's address bar. The location ID is part of the URL.",
                "step5": "5. Copy the Location ID",
                "step5Details": "Simply copy the location ID from the URL."
            },
        },
        tags: {
            allowReservations: "Allow Reservations",
            disableReservations: "Disable Reservations",
            allowComments: "Allow Comments",
            disableComments: "Disable Comments",
            allowReviews: "Allow Reviews",
            disableReviews: "Disable Reviews",
            hasAddress: "Has address",
            hasCart: "Has Cart",
            hasTable: "Has Table",
            hasUmbrella: "Has Umbrella",
        },
        tabs: {
            details: "Details",
            socialProfiles: {
                label: "Social Profiles",
                info: "Enter your social profile complete url here",
                copyFromOrg: "Copy from organziation"
            },
            ticketConfiguration: {
                label: "Email Configuration",
                ORDER: "Order",
                ACCOUNT: "Account",
                ANALYTIC: "Analytic",
                INVENTORY: "Inventory",
                FEEDBACK: "Feedback",
                REPORT: "Report",
                RATING_CUSTOMER: "Rating (Customer)",
                startText: "Start Text",
                endText: "End Text",
                replicas: "Replicas",
                field: {
                    labels: "Fields",
                    ORDER_NUMBER: "Order Number",
                    OUTLET_NAME: "Outlet Name",
                    TIMESTAMP: "Timestamp",
                    WAITER_OR_USER: " Waiter or User",
                    CUSTOMER_NAME: "Customer Name",
                    EQUIPMENT_FLOOR: "Equipment Floor",
                    EQUIPMENT_LABEL: "Equipment Label",
                    EQUIPMENT_SECTION: "Equipment Section",
                    PAYMENT_METHOD: "Payment method",
                    COMMENTS: "Comments",
                    ITEM_COMMENTS: "Item Comments",
                    ITEM_NOTES: "Item Notes",
                    DELIVERY_TIME: "Delivery Time",
                    CART_WITH_PRICES: "Cart with prices",
                    CART_WITH_NO_PRICES: "Cart without prices",
                    CART_TOTAL: "Cart Total",
                    REMAINING_QUANTITY: "Remaining Quantity",
                    QR_CODE: "QRcode",
                    BAR_CODE: "Barcode",
                    CUSTOMER_NAME: "Customer Name",
                    SCORE: "Score",
                    EMPLOYEE_OR_USER: "Type of user",
                    EMPLOYEE_NAME: "Employee Name",
                    REVIEW: "Review"
                }
            },
            integrations: {
                label: "Integrations",
                vivaWalletConfiguration: {
                    label: "Viva Wallet",
                    appSourceCode: "App source code",
                    clientId: "Client ID",
                    // hashed like password --sensitive
                    clientSecret: "CLient secret",
                    merchantId: "Merchant ID",
                    apiKey: "API key"
                },
                vivaPosConfiguration: {
                    label: "Viva POS",
                    clientId: "Client ID",
                    clientSecret: "CLient secret",
                },
                googleReview: {
                    label: "Google",
                    locationID: "locationId"
                },
                oxygenConfiguration: {
                    label: "Oxygen",
                    token: "Token"
                }
            }
        },
        settings: {
            label: "Settings",
            outletStyle: "Outlet",
            reviewsStyle: "Reviews",
            bookingsStyle: "Bookings",
            orderStyle: "Order",
            bookings: "Bookings",
            orders: "Orders",
            workingHours: "Working Hours",
            splashscreen: "Splash Screen",
            details: "Details",
            qrcode: "QRcodes",
            splashScreenConfiguration: {
                showLogo: "Show logo",
                showDescription: "Show description",
                description: "Description",
                backgroundColor: "Background Color",
                descriptionColor: "Description Color",
                durationInSeconds: "Duration",
                durationInSecondsAfterText: "seconds",
                enable: "Enable"
            },
            hours: {
                label: "Working Hours",
                labelPlain: "Hours",
                settings: "Settings",
                timezone: "Timezone",
                timeFormat: {
                    label: "Time format",
                    TWELVE_HOURS: "12-hour format",
                    TWENTY_FOUR_HOUR: "24-hour format",
                },
                indicator: {
                    label: "Indicators",
                    nowOpenMessage: "Now open message",
                    nowCloseMessage: "Now closed message",
                    includeTime: "Include time",
                    includeTimeInfo: "Include the current time in the output",
                    includeDay: "Include day",
                    includeDayInfo: "Include the current day in the output",
                    showOpeningClosingSoonWarning: "Opening/closing soon warning",
                    showOpeningClosingSoonWarningInfo:
                        "When it's near  opening or closing time, show a different message.",
                    openingSoonMessage: "Opening soon message",
                    closingSoonMessage: "Closing soon message",
                    openSoonWarning: "Opening soon warning",
                    closeSoonWarning: "Closing soon warning",
                    openSoonWarningStart: "Show `opening soon` warning",
                    closeSoonWarningStart: "Show `closing soon` warning",
                    minutesInAdvance: "minutes in advance.",
                    includeHolidays: "Include holidays",
                    includeHolidaysInfo: "Add holidays to the hours table",
                    includeVacations: "Include vacations",
                    includeVacationsInfo: "Add vacations to the hours table",
                    displayMode: {
                        label: "Display mode",
                        NORMAL: "Normal",
                        CONSOLIDATED: "Consolidated",
                    },
                    output: {
                        label: "Output",
                        TABLE: "Table",
                        INLINE: "Inline",
                    },
                },
            },
            showLogo: "Show logo",
            showCover: "Show cover",
            showPhotos: "Show Photos",
            allowReviews: "Allow reviews",
            ratingStyle: "Rating style",
            showReviewsAverageScore: "Show reviews average score",
            showReviews: "Show reviews",
            allowReservations: "Allow reservations",
            allowComments: "Allow comments",
            enableCart: "Enable cart",
            enableOrder: "Enable order",
            automaticallyProduceReceiptForCashPayments: "Automatically produce receipt for CASH payments",
            payment: {
                paymentMethods: "Payment methods",
                card: "Card",
                paypal: "Paypal",
                cash: "Cash",
            },
            currency: {
                label: "Currency",
                EUR: "Euro",
                USD: "USD",
            },
            tax: {
                numberPercentage: "Tax",
                "%": "%",
                type: "Tax Type",
                INCLUSIVE: "Inclusive",
                EXCLUSIVE: "Eclusive",
            },
            invoice: {
                label: "Receipt / Invoice",
                series: "Series",
                number: "Number",
                invoiceDOY: "ΔΟΥ",
                invoiceBusiness: "Δραστηριότητα"
            },
            request: {
                label: "Request",
                allowedActions: "Allowed Actions",
                allowedRequestTypes: {
                    PAYMENT: "Pay",
                    MENU: "Order",
                    CLEAN_TABLE: "Clean Table",
                    ORDER: "Order",
                    OTHER: "Other",
                    CUSTOM: "Custom"
                },
                customRequestButtons: "Custom Request Buttons"
            },
            afterQrDispay: {
                label: "After qrcode scan Display",
                allowActionFromAuthenticatedUserOnly: "Allow actions from autheticated users only",
                allowOrdesFromAuthenticatedUsersOnly: "Allow Orders from authenticated users only",
                allowRequestsFromAuthenticatedUserOnly: "Allow Requests from authenticated users only",
                allowRatingsFromAuthenticatedUserOnly: "Allow Ratings from authenticated users only",
                allowWaitlistFromAuthenticatedUserOnly: "Allow Add to Waitlist from authenticated users only",
                afterScanLandOnMenuPage: "After scan load on Menu Page",
                afterScanLandOnMenuPageButtonOptions: {
                    label: "Enable buttons after scan",
                    MENU: "Order",
                    CUSTOMER_REQUEST: "Call waiter",
                    PAYMENT: "Pay",
                    WIFI: "Wifi",
                    RATING: "Rating",
                    WAITLIST: "Waitlist",
                    CUSTOM: "Custom"
                }
            },
            ratings: {
                label: "Ratings",
                minRatingNumberToGoogle: "Minimum Rating Number to publish in Google Reviews"
            },
            wifi: {
                label: "Wifi",
                ssid: "SSID",
                password: "Password",
                enableQRcode: "Enable QRcode",
                addWifi: "Add Wifi Configuration",
                editWifi: "Edit Wifi Configuration",
                enabled: "Enabled",
                locationName: "Location Name",
                encryption: {
                    label: "Encryption",
                    nopass: "nopass",
                    WPA: "WPA/WPA2",
                    WEP: "WEP"
                }
            },
            extraOutletActionButtons: {
                label: "Extra Action Buttons"
            },
            style: {
                label: "Brand Style",
                backgroundColor: "Background Color",
                headerFont: "Header Font",
                headerColor: "Header Color",
                headerFontSize: "Header Font Size",
                textFont: "Text Font",
                textColor: "Text Color",
                textFontSize: "Text Font Size",
                textInfoFont: "Info Text Font",
                textInfoColor: "Info Text Color",
                textInfoFontSize: "Info Text Font Size",
                buttonFont: "Button Font",
                buttonColor: "Button Color",
                buttonBackgroundColor: "Button Background Color",
                buttonFontSize: "Button Font Size",
                backgroundColor: "Background Color",
                chipTextColor: "Chip Text Color",
                chipPercentageTextColor: "Chip Percentage Text Color",
                chip1plus1TextColor: "Chip 1+1 Text Color",
                chips: "Chips",
                buttons: "Buttons"
            },
            waitlist: {
                style: "Style",
                minutes: "Min",
                waitingTime: "Waiting ",
                waitingTime1Min: "Waiting 1st Minutes",
                waitingTime2Min: "Waiting 2nd Minutes",
                waitingTime3: "Waiting 3d",
                waitingTime1ChipTextColor: "Chip",
                waitingTime2ChipTextColor: "Chip",
                waitingTime3ChipTextColor: "Chip",
                estimatedTimeChipTextColor: "Estimate Time Chip",
                waitlistSquareChipTextColor: "Waitlist Icon Chip",
                waitlistPositionSquareChipTextColor: "Waitlist Position Chip",
                showWaitlistGroupInfo: "Show Group info to customer",
                requiredTagSelection: "Required Tag selection",
                waitlistActive: "Active",
                showWaitlistGroupInfo: "Show group info to customers"
            }
        },
    },
    mysection: {
        label: "My Sections"
    },
    request: {
        title: "Requests",
        pageTitle: "Request",
        subtitle: "",
        request: "Request Text",
        btns: {
            list: "List",
            table: "Table",
            send: "Send",
        },
        employeeName: "Εmployee Name",
        customerName: "Customer Name",
        requestCharLimit: "char",
        requestCharsLimit: "chars",
        requestPlaceholder: "Type in here…",
        moreRequests: "More Requests",
        empty: "Seems that there are no customer requests",
        type: {
            label: "Type",
            name: "Name",
            PAYMENT: "Payment",
            CLEAN_TABLE: "Clean Table",
            ORDER: "Order",
            OTHER: "Other",
            CUSTOM: "Custom"
        },
        status: {
            label: "Status",
            DONE: "Done",
            PENDING: "Pending"
        },
        actions: {
            done: "Done",
            all: "All",
            type: {
                payment: "Payment",
                clean_table: "Clean Table",
                order: "Order",
                other: "Other",
                custom: "Custom"
            },
            status: {
                done: "Done",
                pending: "Pending"
            }
        }
    },
    user: {
        title: "Users",
        pageTitle: "User",
        subtitle: "User Management",
        manageAccount: "Manage account",
        personalDetails: "Personal Details",
        notifications: "Notifications",
        workingHours: "Working Hours",
        notificationMobileEnabled: "Mobile Notifications",
        notitification: {
            ORDER: "Order",
            CANCEL_ORDER: "Cancel Order",
            BOOKING: "Booking",
            REQUEST: "Customer Request",
            PAYMENT: "Payment",
            NEW_ORDER: "New Order",
            NEW_BOOK: "New Booking",
            BOOK_CANCELLATION: "Booking Cancelation",
            ACCOUNT: "Account",
            ANALYTIC: "Analytic",
            INVENTORY: "Inventory",
            FEEDBACK: "Feedback",
            REPORT: "Report",
            ERROR: "Error"
        },
        outletsIdsDefaultOutletTooltip: "Default outlet",
        clickToMakeDefaultOutletTooltip: "Click to make default outlet",
        preferedLanguage: "Prefered Language",
        deleletAccount: {
            label: "Delete account",
            desc: "Temporaly deactivate your account.",
            info: "Please note tat deactivating your account will have the following consequences:",
            info1: "You will no longer have access to your account",
            info2: "We will delete all data related to your account. We will keep data that we are legally required to store.",
            info3: "You will not be able to undo the deletion.",
            actionInfo: "Are you sure you want to proceed?",
            confirmByTypingCodeBefore: "Confirm by typing",
            confirmByTypingCodeAfter: "below.",
            btn: "Deactivate",
            codesNotMatching: "The inserted code not match the given. Try again.",
            successTitle: "Account Deactivated",
            success: "We have now permenently deleted your user account. We are sorry to see you leave. You are always welcome to activate your account again!"
        },
        permissions: {
            label: "Permissions",
            allowOrderPaymentAsTreat: "Allow treat"
        }
    },
    employee: {
        title: "Employees",
        pageTitle: "Employee",
        subtitle: "Employee Management",
        defaultOutletIDError: "Default outlet is required. Double click to make your selection."
    },
    subscription: {
        title: "Subscriptions",
        pageTitle: "Subscription",
        subtitle: "Subscription Management",
        paymentSuccessful: "The renewal of your subscription plan was successful.",
        paymentFailure: "The renewal of your subscription plan was failed.",
        cancelReccurentPaymentBtnTooltip: "Cancel recurrent payment",
        recurrentStatus: {
            label: "Recurrent Status",
            ACTIVE: "ACTIVE",
            INACTIVE: "INACTIVE"
        },
        organization: {
            label: "Plan Details",
            currentPlan: "Current Plan:",
            payPeriod: "Pay Period:",
            memberSince: "Member Since:",
            dueDate: "Due Date:",
            pendingCharges: "Pending Charges",
            pay: "Pay",
            renew: "Renew",
            history: {
                smsCounter: "Sms Counter: ",
                emailCounter: "Emsil Counter: ",
                ticketCounter: "Ticket Counter: ",
                receiptTicketCounter: "Receipt Ticket Counter: ",
                organizationCharges: "Organization Fee: ",
                totalAmount: "Total: ",
                outlet: "Outlet: ",
                amount: "Total: "
            },
            payment: {
                fail: {
                    label: "Fail Payment",
                    backButton: "Subscription",
                    infoPayment: "Your payment failed. Please try again."
                },
                success: {
                    label: "Success Payment",
                    backButton: "Subscription",
                    infoPayment: "Your payment placed successfully."
                }
            }
        },
        status: {
            PENDING: "Pending",
            FAILED: "Failed",
            ACTIVE: "Active",
            COMPLETED: "Completed",
            EXPIRED: "Expired",
            CANCELED: "Canceled",
            UPGRADED: "Upgraded",
            DOWNGRADED: "Downgraded",
            EXCEPTION: 'Exception',
            REFUSED: "Refused",
            UNKNOWN: "Unknown",
            UPDATED: "Updated",
            RENEWED: "Renewwed"
        },
        history: "Payment History",
        emails: "Emails"
    },
    subscriptionPlan: {
        label: "Plan: ",
        title: "Subscription Plans",
        pageTitle: "Subscription Plan",
        subtitle: "Subscription Plan Management",
        planPricesEmpty: "No Prices have been added",
        planServicesEmpty: "No Services have been added",
        planDetailsEmpty: "No Details have been added",
        visibleOnlyToAdmin: "Only visible to Admin and assigned organizations",
        type: {
            MONTHLY: "Monthly",
            YEARLY: "Yearly",
            HALF_YEAR: "6 Months",
            TWO_YEARS: "2 Years",
            QUARTER: "4 Months"
        },
        serviceType: {
            SMS: "SMS",
            SMS_MANAGEMENT: "SMS Management",
            EMAIL: "Email",
            EMAIL_MANAGEMENT: "Email Management",
            PRINTER_TICKET: "Printer Ticket",
            RECEIPT_TICKET: "Receipt Ticket",
            TICKET_MANAGEMENT: "Ticket Management",
            USERS_MANAGMENT: "Users Management",
            ORDERING: "Ordering",
            ANALYTICS: "Analytics",
            MENU_CMS: "Menu CMS",
            INVENTORY: "Inventory",
            FEEDBACK: "Feedback",
            KITCHEN_DISPLAY: "Kitchen Display",
            PICKUP_DISPLAY: "Pickup Display",
            PRINTER_DEVICE: "Printer Device",
            PRINTERS_MANAGMENT: "Printers Management",
            BOOKING: "Booking",
            WAITLIST: "Waitlist",
            REQUESTS: "Requests",
            OFFERS: "Offers",
            PDA: "PDA",
            QRCODES_MANAGMENT: "QRcodes Management",
            QRCODES_PRINT: "QRcodes Print",
            LOYALTY: "Loyalty",
            SPLASH_SCREEN: "Splash Screen",
            BRANDING: "Branding"
        },
        service: {
            oneTimePayment: "One time Payment"
        }
    },
    notification: {
        title: "Notificaations",
        markAllAsRead: "Mark All as Read",
    },
    equipment: {
        title: "Equipments",
        pageTitle: "Equipment",
        subtitle: " ",
        addLabel: "Add Equipment",
        bulkAddLabel: "Bulk Add Equipments",
        detailsEmpty: "No Details have been added",
        labelBegginingText: "Label beginning text",
        startNumber: "Start Number",
        endNumber: "End Number",
        attachEquipment: "equipment",
        attachEquipments: "equipments",
        emptyEquipmentsSearch: "There is no equipment matching your search.",
        to: "to",
        maxLessThanMinErrorMessage: "The endNumber value must be greater than or equal to the startNumber value.",
        capacity: "Capacity"
    },
    itemOrModifier: {
        title: "Items or Modifiers",
        titleDelete: "Item/Modifier",
        subtitle: "Item/Modifier Management for ",
        pageTitleItem: "Item",
        pageTitleModifier: "Modifier",
        extraNote: "Extra Note",
        remark: "Remark",
        provider: "Provider",
        modifierList: "Modifier List",
        prices: "Prices",
        itemPricesEmpty: "No prices have been added",
        emptyMessage: "No items/modifiers have been added",
        price: "Price",
        discountPercent: "Discount Percent",
        priceType: "Type",
        priceListName: "Prιce List Name",
        isModifier: "isModifier",
        enabled: "Enabled",
        status: "Status",
        addLabel: "Add Item/Modifier",
        item: "Item",
        modifier: "Modifier",
        viewPrice: "View Prices",
        loyaltyPoints: "Loyalty points",
        points: "points",
        pvcRecyclingTax: "PVC Recycling Tax",
        default: "Default",
        details: "Details",
        photos: "Photos",
        advanced: "Advanced",
        STANDARD: "Standard",
        allergies: {
            label: "Allergies",
            eggs: "Eggs",
            wheat: "Wheat/Gluten",
            fish: "Fish",
            mustard: "Mustard",
            celery: "Celery",
            nuts: "Nuts",
            milk: "Milk",
            molluscs: "Molluscs",
            crustaceans: "Crustaceans",
            soya: "Soya",
            lupin: "Lypin",
            seasame: "Seasame"
        },
        extraIcons: {
            label: "Extra Icons",
            vegan: "Vegan",
            vegetarian: "Vegetarian",
            spicy: "Spicy",
            new: "New"
        },
        nutrients: {
            label: "Nutrients",
            grams: "Grams",
            gramsAfterLabel: "g",
            energy: "Energy",
            enrgeyAfterLabel: "MJ",
            calories: "Calories",
            caloriesAfterLabel: "kcal",
            protein: "Protein",
            proteinAfterLabel: "g",
            carb: "Carbohydrates",
            carbAfterLabel: "g",
            sugars: "Sugars",
            sugarsAfterLabel: "g",
            fat: "Fat",
            fatAfterLabel: "g",
            sat_fat: "Saturated fat",
            sat_fatAfterLabel: "g",
            trans_fat: "Trans fat",
            trans_fatAfterLabel: "g",
            fiber: "Fiber",
            fiberAfterLabel: "g",
            sodium: "Sodium",
            sodiumAfterLabel: "g",
            vitaminA: "Vitamin A",
            vitaminAAfterLabel: "mg",
            vitaminC: "Vitamin C",
            vitaminCAfterLabel: "mg",
            calcium: "Calcium",
            calciumAfterLabel: "mg",
            iron: "Iron",
            ironAfterLabel: "mg",
            salt: "Salt",
            saltAfterLabel: "g",

        },
        ingredients: {
            label: "Ingredients",
            addLabel: "Add Ingredient",
            emptyIngredientsList: "There are no igredients attached.",
            name: "Name",
            quantityToBeUsed: "Quantity",
            dialog: {
                noOptions: "There are no more ingredients that you can attach.",
                attachIngredient: "ingredient",
                attachIngredients: "ingredients",
            }
        },
        export: {
            title: "Export Items",
            viewer: "Viewer",
            editor: "Editor",
            copy: "Select the text or place your cursor at the line to copy.",
            includePhotos: "Include photos?"
        },
        import: {
            title: "Import Items"
        }
    },
    itemCategories: {
        label: "Item Categories",
        FOOD: "Food",
        DRINKS: "Drinks",
        OTHER: "Other",
        CUSTOM: "Custom",
        INGREDIENT: "Ingredient"
    },
    itemVatTypes: {
        label: "Item VAT Types",
        labelSingle: "VAT Type",
        NONE: "None",
        SIX_PERCENT: "6%",
        THIRTEEN_PERCENT: "13%",
        TWENTY_FOUR_PERCENT: "24%"
    },
    modifierList: {
        title: "Modifier Lists",
        subtitle: "Modifier List Management for ",
        pageTitle: "Modifier List",
        enabled: "Enabled",
        allowMultipleSelection: "Allow multiple selection ?",
        minModifiers: "Min",
        maxModifiers: "Max",
        addLabel: "Add Modifier List",
        empty: "No Modifier List have been added",
        modifiersAttached: "Modifiers Attached",
        minSelection: "Min Selection",
        maxSelection: "Max Selection",
        items: "Items",
        modifiers: "Modifiers",
        addItem: "Add Item",
        addModifier: "Add Modifier",
        emptyItemList: "There are no items attached",
        emptymodifierList: "There are no modifiers attached",
        emptyModifierListSearch: "There is no modifier lists matching your search.",
        emptyItemsSearch: "There are no more items that you can attach.",
        emptyModifiersSearch: "There are no more modifiers that you can attach.",
        to: "to",
        attachedItems: "Attached Items",
        adddModifiers: "Add Modifiers",
        list: "list",
        lists: "lists",
        attachitem: "item",
        attachitems: "items",
        attachmodifier: "modifier",
        attachmodifiers: "modifiers"
    },
    menus: {
        title: "Menus",
        pageTitle: "Menu",
        subtitle: "Menu Management for ",
        addLabel: "Add Menu",
        emptyMessage: "No menus have been added",
        recurrence: "Recurrence",
        startDate: "Start Date",
        endDate: "End Date",
        recurrenceDaysNumberBeforeLabel: "Repeat every",
        recurrenceDaysNumberAfterLabel: "days",
        recurrenceDaysNumberAfterLabelOne: "day",
        emptyOutlets: "There are no outlets using the menu",
        usedBy: "Used By",
        enabledLanguages: "Available Languages",
        tabs: {
            details: "Details",
            settings: "Settings",
            photos: "Photos",
            parentCategories: "Parent Categories",
            outlet: "Outlet Section",
            categories: "Categories",
            menus: "Menus"
        },
        cms: {
            addNewParentCategory: "Add new parent category",
            addNewCategory: "Add new category",
            goToParentCategories: "Go to Parent Categories",
            goToCategories: "Go to Categories",
            hideFullScreen: "Hide Full screen",
            fullscreen: "Full screen",
            hideCategoriesImages: "Hide Categories Images",
            hideItemImages: "Hide item Images",
            showCategoriesImages: "Show Categories images",
            showItemImages: "Show item Images",
            showUsedCategories: "Show used categories",
            showUsedItems: "Show used items",
            hideUsedCategories: "Hide used categories",
            hideUsedItems: "Hide used items",
            hideCategories: "Hide Categories",
            showCategories: "Show Categories",
            hideParentCategories: "Hide parent categories",
            showParentCategories: "Show parent categories",
            hideItems: "Hide Items",
            addCategories: "Add categories",
            addItems: "Add items",
            showItems: "Show items",
            parentCategory: "Parent Category",
            category: "Category",
            items: "Items"
        },
        settings: {
            label: "Settings",
            allowParentCategories: "Allow Parent Categories",
            showAdvancedCMS: "Show Advanced CMS",
            baseColor: "Base Color",
            font: "Font Family",
            itemBackgroundColor: "Background Color",
            itemHeaderColor: "Header Color",
            itemHeaderFontSize: "Header Font Size",
            itemDescColor: "Description Color",
            itemDescFontSize: "Description Font Size",
            itemPriceColor: "Price Color",
            itemPriceFontSize: "Price Font Size",
            navigationMenu: "Navigation Menu",
            navigationMenuFont: "Font Family",
            navigationMenuFontSize: "Font Size",
            navigationMenuBackgroundColor: "Background Color",
            navigationMenuSelectedOptionFontColor: "Font Color (Selected/Active)",
            navigationMenuUnSelectedOptionFontColor:
                "Font Color (Unselected/Inactive)",
            navigationMenuStyle: "Menu Style",
            buttonFont: "Button Font",
            chipTextColor: "Chip Text Color",
            buttonColor: "Button Color",
            buttonBackgroundColor: "Button Background Color",
            buttonFontSize: "Button Font Size",
            itemPhotoStyle: {
                label: "Image Style",
                ROUND: "Round",
                SQUARE: "Square",
            },
            layout: {
                label: "Layout",
                LIST: "List",
                GRID: "Grid",
            },
            showCurrency: "Show currency",
            showDescription: "Show description",
            showPointsPerItem: "Show points per item",
            allowCommentsOnItem: "Allow comments on item",
            iconPositionStart: "Currency position Start",
            category: "Category",
            // menu
            menuBackgroundColor: "Background Color",
            menuHeaderColor: "Header Color",
            menuHeaderFontSize: "Header Font Size",
            menuDescColor: "Description Color",
            menuDescFontSize: "Description Font Size",
            menuPhotoStyle: "Photo Style",
            // parent category
            parentCategoryBackgroundColor: "Background Color",
            parentCategoryHeaderColor: "Header Color",
            parentCategoryHeaderFontSize: "Header Font Size",
            parentCategoryDescColor: "Description Color",
            parentCategoryDescFontSize: "Description Font Size",
            parentCategoryPhotoStyle: "Photo Style",
            // category
            categoryBackgroundColor: "Background Color",
            categoryHeaderColor: "Header Color",
            categoryHeaderFontSize: "Header Font Size",
            categoryDescColor: "Description Color",
            categoryDescFontSize: "Description Font Size"
        },
    },
    menuCategories: {
        plain: "Categories",
        title: "Menu Categories",
        addLabel: "Add Categories",
        viewMenuDetails: "View menu details",
        newCategory: "New Category",
        newParentCategory: "New Parent Category",
        parentCategories: "Parent Categories",
        emptyItemList: "There are no items attached",
        items: "Items",
        categories: "Categories",
        category: "Category",
        collapseAll: "Collapse all",
        expandAll: "Expand all",
    },
    order: {
        title: "Orders",
        pageTitle: "Orders",
        plain: "Order",
        subtitle: " ",
        comments: "Comments",
        orderNumber: "Order Number",
        allergies: "Allergies",
        customerPhoneNumber: "Customer Phone",
        customerName: "Customer Name",
        loyaltyPointsEarned: "Loyalty Points Earned (total)",
        points: "points",
        totalAmount: "Total",
        cart: "Cart (JSON)",
        date: "Date",
        dateFrom: "From",
        dateTo: "To",
        persons: "Persons",
        paid: "Paid",
        loyaltyPointsEarned: "Loyalty points Earned",
        status: "Status",
        payment: "Payment",
        actions: {
            all: "All",
            status: {
                pending: "Pending",
                working: "Working",
                done: "Done",
                cancelled: "Canceled",
            },
            statusCaps: {
                PAYMENT: "Payment",
                PENDING: "Pending",
                PENDING_PAYMENT: "Pending Payment",
                PICKED_UP: "Picked up",
                SERVED: "Served",
                WORKING: "Working",
                DONE: "Done",
                CANCELED: "Canceled",
                UNAVAILABLE_ITEMS: "Unavailable Items",
                EXPIRED_PAYMENT: "Expired Payment",
                ERROR: "Error",
                UPDATE_EQUIPMENT: "Update Table",
                UPDATE_TYPE: "Update Type",
                UPDATE_PERSONS: "Update Persons",
                UPDATE_STATUS: "Update Status",
                true: "Done",
                false: "Undone"
            },
            paymentMethod: {
                cash: "Cash",
                card: "Card",
                pos: "POS",
                none: "Not specified",
            },
        },
        tip: {
            label: "Tip",
            checkout: {
                label: "Choose a tip",
                info: "Our staff will be grateful."
            }
        },
        item: {
            name: "Name",
            price: "Price",
            quantity: "Quanity",
            pvcRecyclingTax: "PVC Recycling Tax",
            done: "Done",
            modifiers: "Modifiers",
            notes: "Notes"
        },
        addOrder: "Create Order",
        equipmentInvalid: "The inserted equipment label not found or is not active. Please try again!",
        emptyStatusSearch: "There is no order statuses matching your search.",
    },
    loyalty: {
        title: "Loyalty Club",
        pageTitle: "Loyalty",
        subtitle: "Loyalty Management for ",
        addLabel: "Add Loyalty",
        category: {
            label: "Loyalty Categories",
            labelSingle: "Loyalty Category",
            empty: "No Loyalty categories have been added",
            fullName: "FULL NAME",
            font: "Text",
            family: "Font Family",
            baseColor: "Color",
            background: "Background",
            backgroundColor1: "Color",
            backgroundColor2: "Second Color",
            backgroundImage: "Image",
            gradientBackground: "Gradient color",
            singleColorBackground: "Single color",
            backgroundType: "Type",
            IMAGE: "Image",
            COLOR: "Color",
            targetPointsClean: "Target points",
            targetPoints: "Target points:",
            points: "points",
            discountPercentageClean: "Discount",
            discountPercentage: "Discount:",
            couponValueClean: "Coupon Value",
            couponValue: "Coupon Value:",
        },
        settings: {
            label: "Loyalty Settings",
            cardProperties: "Card Properties",
            configureLoyaltyCard: "Configure Loyalty card",
            showLogo: "Show logo",
            showLogoTooltip: "Organizaion logo",
            showLogoTooltipDisabled: "Please include a logo for the organization first.",
            showName: "Show name",
            showLevel: "Show level",
            showPoints: "Show points",
            showSubsidy: "Show subsidy",
            showWallet: "Show wallet",
            showLoyaltyMemberNumber: "Show loyalty number",
            showCredit: "Show credit",
            showCoupons: "Show coupons",
            showCouponRemainder: "Show coupon remainder",
            showBarcode: "Show barcode",
            points: "points",
            "%": "%",
            configuration: "Configuration",
            front: "Front",
            back: "Back",
            barcode: {
                width: "Width",
                height: "Height",
                format: "Format",
                displayValue: "Display value",
                hideValue: "Hide value",
                fontOptions: {
                    label: "Font Options",
                    bold: "Bold",
                    italic: "Italic",
                },
                font: "Font",
                textAlign: {
                    label: "Text Align",
                    left: "Left",
                    center: "Center",
                    right: "Right",
                },
                textPosition: {
                    label: "Text Position",
                    top: "Top",
                    bottom: "Bottom",
                },
                textMargin: "Text Margin",
                fontSize: "Font Size",
                background: "Background",
                lineColor: "Line Color",
                margin: "Margin",
                marginTop: "Top",
                marginBottom: "Bottom",
                marginLeft: "Left",
                marginRight: "Right",
            },
            enableWelcomeBonus: "Enable Welcome Bonus",
            welcomeBonusPoints: "Welcome Bonus Points",
            welcomeBonusCredit: "Welcome Bonus Credit"
        },
        extraOutletActionButtons: {
            label: "Extra Outlet Action Buttons"
        }
    },
    coupon: {
        tabs: {
            configuration: "Configuration",
            style: "Style",
            barcodeStyle: "Barcode Style"
        },
        baseLabelColor: "Label Color",
        baseDescriptionColor: "Description Color",
        baseExpiresColor: "Expiration Color",
        background: "Background",
        backgroundColor1: "Color",
        backgroundColor2: "Second Color",
        backgroundImage: "Image",
        gradientBackground: "Gradient color",
        singleColorBackground: "Single color",
        backgroundType: "Type",
        font: "Font",
        IMAGE: "Image",
        COLOR: "Color",
        code: "Code",
        expirationDate: "Expiration Date",
        redeemInAllOutlets: "Redeemable in all outlets?"
    },
    feedback: {
        title: "Feedback",
        pageTitle: "Feedbacks",
        subtitle: "Feedback Management for ",
        settings: {
            label: "Settings",
        },
        attachedOutlets: "Attached Outlets",
        addUpdateDialog: {
            add: "Add new feedback form",
            update: "Edit feedback form",
            name: "Name",
            description: "Description",
            redeemPoints: "Redeem Points",
            cancel: "Cancel",
            submit: "Submit",
        },
        header: {
            back: "Back",
            connections: "Connection",
            edit: "Edit Feedback form",
            addCategory: "Add category",
            save: "Save",
        },
        addUpdateCategory: {
            add: "Create new category",
            update: "Update category",
            name: "Name",
            description: "Description",
            cancel: "Cancel",
            submit: "Submit",
        },
        categories: "Categories",
        expandAll: "Expand All",
        collapseAll: "Collapse All",
        addQuestion: "Add Question",
        edit: "Edit",
        delete: "Delete",
        addUpdateQuestion: {
            add: "Add new Question",
            update: "Update Question",
            active: "Active",
            required: "Required",
            question: "Question",
            description: "Description",
            type: "Type",
            choices: "Choices",
            addNew: "Add new Choice",
            allowMultipleSelection: "Allow Multiple Selection",
            min: "Min choices",
            max: "Max choices",
            promptText: "Prompt Text",
            maxAllowedCharacters: "Max Allowed Characters",
            ratingStyle: "Rating Style",
            booleanStyle: "Boolean Style",
        },
        maxQuestionDialog: {
            title: "Are you sure?",
            message: `People tend to answer no more than {{suggestedQuestionsNumber}} questions in a feedback form`,
            cancel: "Cancel",
            proceed: "Proceed",
        },
        connectionsDialog: {
            heading: "Connections",
            search: "Search",
            cancel: "Cancel",
            save: "Save",
        },
    },
    item: {
        notes: "Notes",
        notesInfo: "Notes (if there are special instructions)",
        itemCharLimit: "char",
        itemCharsLimit: "chars",
        allergies: "Allergies",
        pvcRecyclingTax: "PVC Recycling Tax",
        points: "points",
        caloriesShort: "Cal",
        proteinShort: "Prot",
        carbShort: "Carb",
        min: "Min",
        max: "Max",
        nutrients: {
            label: "Nutrients",
            grams: "Grams",
            gramsAfterLabel: "g",
            gramsDetailsLabel: "Quantity per ",
            gramsPortionLabel: "portion",
            energy: "Energy",
            enrgeyAfterLabel: "MJ",
            calories: "Calories",
            caloriesAfterLabel: "kcal",
            protein: "Protein",
            proteinAfterLabel: "g",
            carb: "Carbohydrates",
            carbAfterLabel: "g",
            sugars: "Sugars",
            sugarsDetailsLabel: "(of which sugars)",
            sugarsAfterLabel: "g",
            fat: "Fat",
            fatDetailsLabel: "Total Fat",
            fatAfterLabel: "g",
            sat_fat: "Saturated fat",
            sat_fatDetailsLabel: "(of which Saturates)",
            sat_fatAfterLabel: "g",
            trans_fat: "Trans fat",
            trans_fatDetailsLabel: "(of which Trans)",
            trans_fatAfterLabel: "g",
            fiber: "Fiber",
            fiberDetailsLabel: "(of which fibers)",
            fiberAfterLabel: "g",
            sodium: "Sodium",
            sodiumAfterLabel: "g",
            vitaminA: "Vitamin A",
            vitaminAAfterLabel: "mg",
            vitaminC: "Vitamin C",
            vitaminCAfterLabel: "mg",
            calcium: "Calcium",
            calciumAfterLabel: "mg",
            iron: "Iron",
            ironAfterLabel: "mg",
            salt: "Salt",
            saltAfterLabel: "g",
        },
        requiredChoice: "Required Choice",
        optionalChoice: "Optional Choice",
        ok: "Ok",
    },
    section: {
        title: "Section",
        pageTitle: "Sections",
        subtitle: " ",
        name: "Name",
        description: "Description",
        attachEquipments: "Attach equipments",
        emptyEquipment: "No equipments available.",
        emptySection: "No sections available.",
        emptyEnName: "Πρέπει να παρέχετε την αγγλική έκδοση του ονόματος της ενότητας.",
    },
    help: {
        title: "Help",
        pageTitle: "Help",
        header: "Find the answers you are looking for, by selecting a topic",
        analytics: {
            title: "Analytics",
            subtitle:
                "How to see, explore and download your data. All the Business Intelligence you need to perform.",
        },
        user: {
            title: "User",
            subtitle: "The most commonly asked questions",
        },
        outlet: {
            title: "Outlet",
            subtitle: "The most commonly asked questions",
        },
        organization: {
            title: "Organization",
            subtitle: "The most commonly asked questions",
        },
        order: {
            title: "Order",
            subtitle: "Find here everything about Usee's ordering page",
        },
        menu: {
            title: "Menu",
            subtitle: "The most commonly asked questions",
        },
        feedback: {
            title: "Feedback",
            subtitle: "The most commonly asked questions",
        },
        loyalty: {
            title: "Loyalty",
            subtitle: "The most commonly asked questions",
        },
        equipment: {
            title: "Equipment",
            subtitle: "The most commonly asked questions",
        },
        faq: {
            title: "Frequently Asked Questions",
            subtitle: "The most commonly asked questions",
        },
        bookMeetingMessage:
            "Did not find what you are looking for on the above topics? ",
        bookMeeetingBtnLabel: "Schedule a meeting with us",
    },
    customer: {
        title: "Customer",
        pageTitle: "Customers",
        subtitle: " ",
        username: "Username",
        firstName: "First Name",
        lastName: "Last Name",
        phone: "Phone",
        email: "Email",
        order: {
            ordersNumber: "Total Orders Number",
            orderTotalAmmount: "Total Orders Income"
        },
        loyalty: {
            name: "Loyalty Category",
            points: "Points",
            loyaltyNumber: "Loyalty Number"
        },
        view: {
            since: "Since: ",
            orders: "Orders: ",
            spent: "Spent: ",
            currentPoints: "Current points:",
            contactDetails: {
                label: "Contact Details",
                username: "Username",
                firstName: "First Name",
                lastName: "Last Name",
                email: "Email",
                address: "Address",
                country: "Country",
                phone: "Mobile",
            },
            customProperties: {
                label: "Custom Properties",
                storeCredit: "Store Credit",
                status: {
                    label: "Status",
                    true: "Active",
                    false: "Deactive"
                },
                signup: "Signup"
            },
            notes: {
                label: "Team Notes",
                info: "Comment text....",
                btn: "Submit",
                itemCharLimit: "char",
                itemCharsLimit: "chars",
            },
            tabs: {
                summary: "Summary",
                orders: {
                    latestOrders: {
                        label: "Latest Orders: ",
                    },
                    label: "Orders",
                    order: {
                        moreOrders: "More Orders",
                        backButton: "Back to orders",
                        outletName: "Outlet:",
                        equipmentLabel: "Location:",
                        orderTotal: "Order Total:",
                        orderNumber: "Order Number:",
                        paymentTotal: "Payment total",
                        paymentMethod: "Payment method: ",
                        cart: "Cart",
                        rating: "Your opinion counts",
                        ratingDescription: "Rate your order",
                        allergies: "Your Allergies",
                        notes: "Note:",
                        pvcRecyclingTax: "Environmental fee included",
                        discount: "Discount:",
                        loyalty: "Loyalty",
                        coupon: "Coupon:",
                        fail: {
                            backButton: "Checkout",
                            infoPayment: "Your payment failed. Please try again."
                        },
                        success: {
                            tab: "Success",
                            backButton: "Checkout",
                            infoPayment: "Your order placed successfully."
                        }
                    },
                },
                activity: {
                    label: "Activity",
                    action: {
                        CLEAR: "clear",
                        points: "points"
                    },
                    referenceType: {
                        ACCOUNT: "Account",
                        BOOKING: "Booking",
                        EMPLOYEE: "Employee",
                        FEEDBACK: "Feedback",
                        ORDER: "Order",
                        PROMO: "Promo",
                        REFUND: "Refund",
                        REGISTRATION: "Registration"
                    },
                    manualAddPointsLabel: "Manually include points"
                },
                coupons: {
                    label: "Coupons",
                    expiresOn: "Expires on:"
                }
            }
        },
        tabs: {
            all: "ALL",
            returning: "Returning",
            orderRecently: "Ordered Recently"
        }
    },
    printer: {
        title: "Printer",
        subtitle: " ",
        pageTitle: "Printers",
        subtitle: "Printer Management for ",
        printNodeId: "PrintNode id",
        printNodeIdErrror: "PrintNode id require an 8 digits length number",
        outletID: "Outlet id",
        outletName: "Outlet name",
        outletSections: "Outlet sections",
        isActive: "is Active?",
        equipments: "Equipments",
        includedEquipmentIds: "Include equipments",
        excludedEquipmentIds: "Exclude equipments",
        employees: "Employees",
        noPrinters: "No printers are found.",
        friendlyName: "Friendly Name",
        type: {
            label: "Type",
            ip: "IP",
            printNode: "Print Node",
        },
        ip: "Printer IP Address",
        port: "Printer Port",
        invalidIP: "Invalid IP address",
        invalidPort: "Invalid port number",
        paperSize: {
            label: "Paper Size",
            _80mm: "80mm",
            _58mm: "58mm"
        },
        model: {
            label: "Model",
        },
        tabs: {
            details: "Details",
            ticketConfiguration: {
                label: "Ticket Configuration",
                NEW_ORDER: "New Order",
                CANCELED_ORDER: "Canceled Order",
                NEW_REQUEST: "New Request",
                KITCHEN_ORDER: "Kitchen Order",
                DRINKS_ORDER: "Drinks Order",
                RECEIPT: "Receipt",
                TEMP_RECEIPT: "Receipt",
                INVOICE: "Invoice",
                WAITLIST: "Waitlist Entry",
                startText: "Start Text",
                endText: "End Text",
                replicas: "Replicas",
                field: {
                    labels: "Fields",
                    ORDER_NUMBER: "Order Number",
                    OUTLET_NAME: "Outlet Name",
                    TIMESTAMP: "Timestamp",
                    WAITER_OR_USER: " Waiter or User",
                    CUSTOMER_NAME: "Customer Name",
                    EQUIPMENT_FLOOR: "Equipment Floor",
                    EQUIPMENT_LABEL: "Equipment Label",
                    EQUIPMENT_SECTION: "Equipment Section",
                    PAYMENT_METHOD: "Payment method",
                    COMMENTS: "Comments",
                    ITEM_COMMENTS: "Item Comments",
                    ITEM_NOTES: "Item Notes",
                    DELIVERY_TIME: "Delivery Time",
                    CART_WITH_PRICES: "Cart with prices",
                    CART_WITH_NO_PRICES: "Cart without prices",
                    CART_TOTAL: "Cart Total",
                    QR_CODE: "QRcode",
                    BAR_CODE: "Barcode",
                    TYPE: "Type",
                    TEXT: "Text",
                    CREATED_BY: "Created by",
                    PERSONS: "Persons",
                    QRCODE: "QRcode"
                }
            },
            style: {
                label: "Style",
                baseFontSize: "Base Font Size",
                primaryFontSize: "Primary Font Size",
                secondaryFontSize: "Secondary Font Size",
                tableFontSize: "Table Font Size",
                tableNoteFontSize: "Table Note Font Size",
            },
        }
    },
    pos: {
        title: "POS Device",
        subtitle: " ",
        pageTitle: "POS Devices",
        subtitle: "POS devices Management for ",
        outletID: "Outlet id",
        outletName: "Outlet name",
        noPoses: "No POS devices are found.",
        friendlyName: "Friendly Name",
        terminalId: "Terminal ID",
        type: {
            label: "Type",
            VIVA: "VIVA"
        },
        provider: {
            label: "Provider",
            SoftPos: "SoftPos"
        },
        syncTooltip: "Sync Viva POS Devices"
    },
    qrcode: {
        title: "QRcode",
        pageTitle: "QRcodes",
        subtitle: "QRcode Management for ",
        sign: "include signature?",
        templates: {
            label: "QRcode Templates",
            labelSingle: "QRcode Template",
            includeLogo: "Include logo",
            removeQrCodeBehindLogo: "Remove qrcode behind logo",
            tips: "Scan with your phone to preview"
        },
        settings: {
            label: "QRcode Settings",
            hasCustomDesign: "Custom design",
            imageAsBackground: "Set image as Background",
            logoOpacity: "Opacity",
            logoMissing: "Outlet Logo not found, please move to Tab `Details` and upload one, or use one of the available icons below.",
            logo: {
                label: "Logo",
                info: "Upload your own custom logo image as .png, .jpg .svg file format with a maximum size of 2 MB.",
                note1: "We are creating dynamic QR codes that allow us to track performance and edit data even after printing.",
                icons: "Or use our available logos:",
                upload: {
                    button: "Click or Drop here",
                },
            },
            assets: {
                label: "Assets",
                logoOpacity: "Logo Opacity",
                logoPadding: "Logo Padding",
                removeQrCodeBehindLogo: "Remove background behind Logo",
                logoWidth: "Width",
                logoHeight: "Height"
            },
            colors: {
                label: "Colors",
                bgColor: "Background Color",
                fgColor: "Foreground Color"
            },
            eyes: {
                label: "Eyes",
                style: "Style",
                style: "Style",
                eyeFrameShape: "Eye Frame Shape",
                eyeBallShape: "Eye Ball Shape",
                color: "Color",
                info: "Select eyes to make your QR code stand out. Eyes are what your camera recognizes when scanning",
                left: "Left",
                right: "Right",
                bottom: "Bottom"
            },
            patterns: {
                label: "Patterns"
            },
            options: {
                label: "Options",
                ecLevel: {
                    label: "Error Correction Level",
                    L: "Level L - (7%)",
                    M: "Level M - (15%)",
                    Q: "Level Q - (25%)",
                    H: "Level H - (30%)",
                    info: "Each QR code has one of four levels (of error correction): L, M, Q, H. The level determines the percentage of the total QR code that is allowed to be dirty or damaged without being unable to read. Level L can be dirty/damaged for up to 7%, level M 15%, level Q 25%, level H 30%. The higher the percentage, the larger the dimensions of the QR code."
                },
                size: "QRcode size",
                quietZone: "Quiet Zone"
            },
            frame: {
                label: "Frame",
                note: "A QR code with a frame and call-to-action gets 80% more scans",
                frameText: "Text",
                frameTextColor: "Text Color",
                frameColor: "Color",
                frameTextFontSize: "Font Size",
                style: "Style"
            },
            removeQrCodeBehindLogo: "Remove Background Behind Logo",
            ecLevel: {
                label: "Quality",
                M: "Medium",
                L: "Weak",
                Q: "Strong",
                H: "High",
            },
        },
        tip: "After noting the different ways to design your QR Code, the final step should be to test it with various scanning apps to ensure that it is readable. When youre ready, check out our guide on How To Print QR Codes Correctly.",
        info: 'A QR Code is a two-dimensional barcode that is readable by smartphones. It allows to encode over 4000 characters in a two dimensional barcode. QR Codes may be used to display text to the user, to open a URL, save a contact to the address book or to compose text messages. "QR Code" is a registered trademark of DENSO WAVE INCORPORATED. To read QR Codes with a smartphone, you can just use the default camera app. On desktop devices, you can just bookmark the Scan section of this web app. Than can be useful to send a link from a phone to your desktop browser for example.',
        tip2: "Customized QR Codes may be unscannable. Be sure to verify that your QR Code can be scanned in real size before using it!",
    },
    inventory: {
        label: "Inventory",
        subtitle: "Manage the availability of your items",
        price: "Price",
        defaultPrice: "Default Price",
        pauseAvailability: 'Pause availability',
        resumeAvailability: "Resume Availability",
        sortage: "Sortage",
        unlimited: "Unlimited",
        quantity: "Specific Quantity",
        sortage: "Sortage",
        percentage: "Percentage",
        "%": "%",
        alertSortage: "Alert sortage",
        tabs: {
            items: "Items",
            offers: "Offers",
        }
    },
    display: {
        label: "Display",
        kitchen: {
            label: "Kitchen Display",
            settings: {
                label: "Settings",
                collapsePendingOrders: "Collapse Pending Orders",
                autoAcceptOrders: "Auto accept orders",
                defaultOrderPreparationTime: "Default order preparation time ",
                autoAcceptPendingTimeInSeconds: "Default auto accept time for pending orders",
                refreshTimeInSeconds: "Refresh time",
                seconds: "sec",
                showPendingAndWorkingOrdersTogether:
                    "Show Pending and Working Orders Together",
                orderColor: "Colors",
                pendingOrderColor: "Pending",
                workingOrderColor: "Working",
                doneOrderColor: "Done",
                modifierColor: "Modifier Color",
                orderCommentColor: "Order Comment Color",
                itemNoteColor: "Item Note Color",
                allergiesColor: "Allergies Color",
                minutes: "min",
                showPrice: "Show price"
            }
        },
        pickup: {
            label: "Pickup Order Display",
            fullscreen: "Full screen",
            close: "Close Full screen",
            preparing: "PREPARING",
            ready: "READY",
            settings: {
                label: "Settings",
                px: "px",
                preparingOrder: "Preparing Order",
                readyOrder: "Ready Order",
                backgroundColor: "Background Color",
                fontColor: "Font Color",
                headerFontColor: "Header Font Color",
                font: "Font",
                fontSize: "Font size",
                headerFontSize: "Header Font Size"
            }
        },
        pda: {
            label: "PDA",
        },
        floorPlanning: {
            label: "Floor Planner",
            addSection: "Add Section",
            saveSection: "Save Section",
            drawer: {
                sectionDetails: "Section Details",
                totalCapacity: "Total Capacity",
                onlineCapacity: "Online Capacity",
                equipments: "Tables",
                sectionName: "Section Name",
                tableOptions: "Table Options",
                tableOptionsDesc: "Drag and drop your tables, name them and pick your minimum and maximum covers counts.",
                shapeOptions: "Shape Options",
                shapeOptionsDesc: " Easily drag and drop your shapes, name them and pick colors.",
                minCovers: "Min Covers",
                maxCovers: "Max Covers",
                online: "Online",
                uploadImage: "Upload Image"
            },
            updateSectionName: {
                success: "Section name updated successfully!"
            }
        }
    },
    workingHours: {
        monday: "Monday",
        tuesday: "Tuesday",
        wednesday: "Wednesday",
        thursday: "Thursday",
        friday: "Friday",
        saturday: "Staturday",
        sunday: "Sunday",
        start: "Start",
        end: "End",
        open: "Working?",
        working: "Working",
        break: "Break",
        equalTimeError: "Please enter a valid time. Start and End times cannot be equal.",
        endTimeError: "Please enter a valid time. End time cannot be before start time."
    },
    assets: {
        dragNDropMessage: "Drop the files here ...",
        dragNDropEmptyMessage:
            "Drag’n’drop some files here, or click to select files",
        delete: "Delete photo",
        hidden: "Hide photo",
        cover: "Make photo cover",
        crop: "Crop & save",
        allowImageFilesOnly: "Only image files are allowed",
        imageSizeLimitError: "Only image files smaller than 2MB allowed.",
        hue: "Hue",
        saturation: "Saturation",
        brightness: "Brightness",
        contrast: "Contrast",
        reset: "Reset",
        emptyPhotos: "Seems that are no photos available.",
        configurePhotos: "Configure photos",
        uplooadImage: "Upload an Image"
    },
    email: {
        from: "FROM:",
        to: "To:",
        cc: "CC:",
        bcc: "BCC:",
        subject: "Subject",
    },
    socialProfile: {
        label: "Social Profiles",
        FACEBOOK: "Facebook",
        INSTAGRAM: "Instagram",
        LINKEDIN: "Linkedin",
        PINTEREST: "Pinterest",
        SNAPCHAT: "Snapchat",
        TIKTOK: "TikTok",
        TWITTER: "X",
        YOUTUBE: "Youtube"
    },
    upload: {
        buttonText: 'Drop file here or click to upload'
    },
    languages: {
        label: "Language",
        en: "English",
        de: "German",
        fr: "French",
        it: "Italian",
        ru: "Russian",
        el: "Greek",
        uk: "Ukrainian",
        es: "Spanish",
        nl: "Dutch",
        cs: "Czech",
        pl: "Polish",
        bg: "Bulgarian",
        ar: "Arabic",
        et: "Estonian",
        lt: "Lithuanian",
        ro: "Romanian",
        tr: "Turkish",
    },
    ///new
    orders: {
        home: "Live",
        pastOrders: "Past Orders",
        minimizeOrders: "Minimize Orders",
        maximizeOrders: "Maximize Orders",
        moveToDone: "Move to working",
        complete: "Complete",
        assignTimeDialog: {
            heading: "Assign Time",
            text: "Assign time to this by clicking on button",
            minute: "Min",
            custom: "Custom",
            customInputPlaceholder: "Time in minute",
            submit: "Submit",
        },
        empty: "Seems that there are no orders yet.",
        cancelOrder: "Cancel Order",
        cancelMessage: "Are you sure you want to Cancel order",
        no: "No",
        yes: "Yes",
        lackOfProduct: "Lack of product",
        lackOfProductDialog: {
            heading: "Lack of product at order",
            availableQuantity: "Available Quantity",
            cancel: "Cancel",
            update: "Update",
        },
        pendingTitle: "Pending",
        workingTitle: "Working",
        pendingWorking: "Pending / Working",
        pendingEmpty: "No Pending Orders",
        workingEmpty: "No Working Orders",
        totalItems: "Total Items:",
        refresh: "Refresh",
    },
    analytics: {
        label: "Analytics",
        cart: {
            name: "Item Name",
            productRevenue: "Product revenue",
            uniquePurchasesCount: "Unique Purchases Count",
            quantity: "Quantity",
            avgQuantity: "Average Quantity",
            avgPrice: "Average Price",
            emptyMessage: "No data available"
        },
        chart: {
            paymentMethods: {
                labelCounts: "Payment Methods Counts",
                label: "Payment Methods"
            },
            revenue: {
                totalOrders: "Total Orders",
                totalRevenue: "Total Revenue",
                sec: "sec",
                min: "min",
                hour: "hour",
                day: "day",
                month: "month",
                year: "year",
            },
            employees: {
                totalOrders: "Total Employee Orders",
                totalRevenue: "Total Employee Revenue",
                yTitle: "Employees",
                dataLabel: "Orders"
            },
            customers: {
                totalOrders: "Total Customer Orders",
                totalRevenue: "Total Customer Revenue",
                yTitle: "Customers",
                dataLabel: "Orders"
            },
            ratings: {
                basedOnBefore: "Based on",
                basedOnAfter: "reviews"
            },
            noData: "Please adjust your filters, as there is no data to display."
        },
        tabs: {
            general: "General",
            items: "Items",
            employees: "Employees",
            revenue: "Revenue",
            extra: "Extra",
            ratings: "Ratings"
        }
    },
    rating: {
        label: "Ratings",
        subtitle: " ",
        outletName: "Outlet Name",
        emptyOutlet: "There are no ratings for this outlet",
        emptyRatings: "No ratings have been added yet.",
        moreRatings: "More Ratings",
    },
    filter: {
        tooltip: {
            title: "Filter Table"
        }
    },
    cart: {
        label: "Cart",
        emptyLabel: "Empty Cart",
        emptyDescription: "Fill in your cart with items.",
        options: {
            delivery: "Devilery",
            dineIn: "Dine in",
            pickUp: "Pick up"
        },
        discount: "Discount",
        totalAmmount: "Total amount",
        analyzeOrder: "Analyze order",
        allergies: {
            label: "Allergies",
            celery: "Celery",
            eggs: "Eggs",
            fish: "Fish",
            gluten: "Gluten",
            lupin: "Lupin",
            milk: "Milk",
            molluscs: "Molluscs",
            mustard: "Mustard",
            nuts: "Nuts",
            soya: "Soya",
            sesame: "Sesame",
            wheat: "Wheat",
            pleaseSelectAllThatApply: "Please select all that apply"
        },
        item: {
            notes: "Notes"
        },
        addToCart: "Add to Cart",
        equipmentLabel: "Table:",
        order: {
            label: "Order",
            confirmed: "Confirmed",
        },
        menus: "Menus:",
        parentCategories: "Parent Categories:",
        categories: "Categories:",
        items: "Items:",
        orderType: {
            label: "Type",
            DINE_IN: "Dine In",
            PICKED_UP: "Picked Up"
        },
        backButton: "Back to Menus",
        persons: "Persons"
    },
    checkout: {
        label: "Checkout",
        backButton: "Back",
        paymentTotal: "Payment total",
        paymentMethod: "Payment method: ",
        cart: "Cart",
        persons: "Persons",
        pvcRecyclingTax: "Environmental fee included",
        discount: "Discount:",
        loyalty: "Loyalty",
        coupon: "Coupon:",
        comments: "Comments",
        info: "Fill in with a short text...",
        requestCharLimit: "char",
        requestCharsLimit: "chars",
        requestPlaceholder: "Type in here…",
        buttons: {
            sendOrder: "Send order",
            payOrder: "Pay order"
        },
        payment: {
            paymentMethods: "Payment methods",
            card: "Card",
            paypal: "Paypal",
            cash: "Cash",
            pos: "POS",
        },
        equipmentInvalid: "The inserted equipment label not found or is not active. Please try again!",
        tax: "VAT value: ",
        loyaltyPointsToEarn: "If you proceed, you will gain",
        points: "points"
    },
    pda: {
        label: "PDA",
        backButton: "Back to PDA",
        backButtonAddOderDialog: "Cancel",
        backButtonEqDialog: "Back",
        emptyMySections: "It seems like you haven't defined any My-sections yet. Please visit My-Sections and update them to enhance your experience.",
        emptyMySectionsButton: "Go to My-Sections",
        emptyOrder: "It seems like no orders remaining.",
        moreOrders: "Load more",
        unPaidOrderButton: "Pay",
        backToUnpaidOrders: "Back",
        dialogPay: {
            title: "Select Quantity to Pay",
            info: "Quantity to pay ",
            max: "Max"
        },
        coupon: "Coupon",
        paySelectedItems: "Pay selected items",
        paySelectedItemsAndClose: "Pay selected items & close",
        pay: "Pay",
        payAll: "Pay all",
        payAndClose: "Pay & close",
        payAllAndClose: "Pay all & close",
        viewAll: "View all",
        back: "Back",
        order: {
            empty: "You have no orders yet",
            info: "Click to view more details",
            view: "View Orders",
            viewSingle: "View Order",
            viewUnpaid: "View Unpaid Orders",
            create: "Create Order"
        },
        tabsView: {
            info: "Info",
            cart: "Cart",
            status: "Status",
            payment: "Payment"
        },
        remainToPay: "Require pay",
        payment: {
            treat: "Is treat?",
            method: "Payment method",
            label: "Payment History",
            card: "Card",
            paypal: "Paypal",
            cash: "Cash",
            treat: "Is treat?",
            emptyPaymentHistory: "Seems that there are no payment history yet."
        },
        payCloseAll: {
            label: "You have selected to close all tables. The default payment method is Cash. If you wish to make changes, please check the 'Advanced Options' checkbox.",
            advanceCheckboxLabel: "Advanced Options",
            btnTooltipDefault: "Close all open tables and finalize payments."
        }
    },
    offer: {
        title: "Offers",
        pageTitle: "Offer",
        subtitle: " ",
        emptyMessage: "No offer items have been found.",
        enabled: "Enabled",
        disabled: "Disabled",
        all: "All",
        price: "Price",
        percentage: "Percentage",
        "%": "%",
        "€": "€",
        errorPricePercentageEmpty: "You must define a new price or a discount percentage.",
        errorPricePercentageEmpty: "You must define a new price or a discount percentage.",
        loyaltyPoints: "Points",
        points: "points",
        pvcRecyclingTax: "Environmental fee included",
        categories: "Categories",
        type: {
            label: "Type",
            OFFER: "Offer",
            COMBO: "Combo",
            HAPPY_HOUR: "Happy Hour",
            ONE_PLUS_ONE: "1+1"
        },
        tabs: {
            details: "Details",
            photos: "Photos",
            hours: "Available Hours"
        }
    },
    marketing: {
        template: {
            title: "Email Template",
            pageTitle: "Email Templates",
            subtitle: " ",
            name: "Name",
            noEmailTemplates: "No email templates are found.",
            showAdvanceEditor: "Show advanced editor"
        },
        recipient: {
            pageTitle: "Recipients",
            title: "Recipient",
            subtitle: " ",
            age: "Age",
            birthdate: "Birthday",
            empty: "No recipients found matching the filters.",
            group: {
                pageTitle: "Recipient Groups",
                title: "Recipient Group",
                subtitle: " ",
                autoUpdate: "Auto update",
                userGroupType: {
                    label: "Type",
                    ALL: "All",
                    EMAIL: "Email",
                    SMS: "SMS",
                    NONE: "None"
                },
                import: {
                    missingRows: "Missing required fields in $rows row(s)."
                }
            }
        },
        campaign: {
            reset: 'Reset',
            sms: {
                pageTitle: "SMS Campaigns",
                title: "SMS Campaign",
                subtitle: " ",
                sms: "SMS",
                smsLength: "Message Length",
                placeholder: "Enter message",
                message: "Message",
                to: "To",
                messageCharLimit: "char",
                messageCharsLimit: "chars",
                scheduled: "Schedule",
                scheduledDateTime: "Send on:",
            },
            email: {
                pageTitle: "Email Campaigns",
                title: "Email Campaign",
                subtitle: " ",
                body: "Body",
                subject: "Subject",
                message: "Message",
                to: "To",
                scheduled: "Schedule",
                scheduledDateTime: "Send on:",
            }
        }
    },
    waitlist: {
        label: "Waitlist",
        enabledWaitlistRequirements: {
            label: "Enabled Waitlist Requirement Options",
            SMOKING: "Smoking",
            NON_SMOKING: "NON Smoking",
            INFANT_SEAT: "Infant Seat",
            VIEW: "View",
            SEA_VIEW: "Sea View",
            INDOOR: "Indoor",
            OUTDOOR: "Outdoor",
            BAR: "Bar",
            TABLE: "Table",
            POOL: "Pool",
            BIRTHDAY: "Birdthday",
            ANNIVERSARY: "Anniversary",
            ALLERGIES: "Allergies",
            VIP: "VIP",
            VVIP: "VVIP",
            LOYAL: "Loyal"
        },
        enabledWaitilistTags: {
            label: "Enabled Waitlist Tags",
            FOOD: "Food",
            DRINK: "Drink"
        },
        exportBtn: "Export QRcode PDF for Waitlist",
        generateBtn: "Generate QRcode for Waitlist",
        generateQRcodeSuccess: "QRcode for Waitlist was generated successfully. Click again to export.",
        selectTemplateWaitlist: "Select the the template you would like to style your QRcode for Waitlist: ",
        selectTemplateWaitlistEmpty: "There are no qrcode template. Click to add one.",
    },
    chat: {
        tabs: {
            mine: "Mine",
            all: "All"
        },
        channel: {
            name: "Name",
            placeHolderName: 'Channel name',
            color: "Color",
            image: "Image",
            uploadImage: "UPLOAD AN IMAGE",
            urgent: "Mark Urgent?",
            selectNow: "Select now",
            selectOne: "Select a conversation",
            emptyLabel: "There are no available conversations."
        }
    },
    glabal: {
        fontSizeAfterAdorment: "px"
    },
    date: {
        start: "Start Date",
        end: "End Date",
        today: "Today",
        lastWeek: "Last Week",
        last7Days: "Last 7 Days",
        currentMonth: "Current Month",
        lastMonth: "Last Month",
        currentYear: "Current Year",
        reset: "Reset"
    },
    integrations: {
        oxygenConfiguration: {
            label: "Oxygen",
            token: "Token"
        }
    }
};

export default EN;
