import { request } from 'constants/alias';
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from 'utils/functions';
import updateFieldIfValid from 'utils/functions/updateFieldIfValid';

/**
 * The rest request which attempts to get the users list from the database.
 * @param {object} pageable includes all pageable details (page, size, sort, direction)
 * @param {*} search The filters for search.
 * @returns an object with an attribute containing the users list
 */
export async function fetchAll(pageable, search) {
    let data = {};
    updateFieldIfValid(data, "organizationID", search.organizationID);
    updateFieldIfValid(data, "outletID", search.outletID);
    updateFieldIfValid(data, "username", search.username);
    updateFieldIfValid(data, "phone", search.phone);
    updateFieldIfValid(data, "email", search.email);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}user`, pageable !== undefined ? { ...pageable, ...data } : data));
    else return await request.get(includeParamsToUrl(`${API}user`, pageable !== undefined ? pageable : {}));
}

/**
 * The rest request which attempts to get the users list from the database.
 * @param {*} organizationID The id of the organization users to be fetched
 * @param {object} pageable includes all pageable details (page, size, sort, direction)
 * @returns an object with an attribute containing the users list
 */
export async function fetchAllByOrganizationID(organizationID, pageable) {
    let config = {
        baseURL: process.env.REACT_APP_AXIOSBASE,
        params: pageable !== undefined ? pageable : {}
    }

    return await request.get(`${API}organization/${organizationID}/user`, config);
}

/**
 * The rest request which attempts to get the users list from the database.
 * @param {*} organizationID The id of the organization outlet users to be fetched
 * @param {*} outletID The id of the outlet users to be fetched
 * @param {object} pageable includes all pageable details (page, size, sort, direction)
 * @returns an object with an attribute containing the users list
 */
export async function fetchAllByOutletID(organizationID, outletID, pageable) {
    let config = {
        baseURL: process.env.REACT_APP_AXIOSBASE,
        params: pageable !== undefined ? pageable : {}
    }

    return await request.get(`${API}organization/${organizationID}/outlet/${outletID}/user`, config);
}


/**
 * The rest request which return required information to be presented on add new user page.
 * @returns an object with an attribute containing the requested information to be presented on the user page
 */
export async function fetchNew(organizationID, outletID) {
    let data = {};
    updateFieldIfValid(data, "organizationID", organizationID);
    updateFieldIfValid(data, "outletID", outletID);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}user/add`, data));
    else return await request.get(includeParamsToUrl(`${API}user/add`));
}

/**
 * The rest request which return required information to be presented on aaccount pop up.
 * @returns an object with an attribute containing the requested information of the authenticated user
 */
export async function fetchAuth() {
    return await request.get(`${API}user/auth`);
}

/**
 * The rest request which attempts to get an existing user information.
 * @param {*} userID The id of the user to be fetched
 * @returns an object with an attribute containing the current infromation of the user
 */
export async function fetchOne(organizationID, outletID, userID) {
    let data = {};
    updateFieldIfValid(data, "organizationID", organizationID);
    updateFieldIfValid(data, "outletID", outletID);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}user/${userID}`, data));
    else return await request.get(includeParamsToUrl(`${API}user/${userID}`));
}

/**
 * The rest request which attempts to fetch authenticated user details.
 * @returns an object refering to the success or failure of the request
 */
export async function fetchAuthDetails() {
    return await request.get(`${API}user/auth`);
}

/**
 * The rest request which attempts to check if username or email ir already taken.
 * @param {String} field The field to be check (username | password)
 * @returns an object refering to the success or failure of the request
 */
export async function checkFieldExistance(field, value) {
    let data = { value: value };
    return await request.get(`${API}user/ckeck/exists/${field}`, data);
}

/**
 * The rest request which attempts to create a new user.
 * @param {*} data A JSON object which contains new user infromation
 * @returns an object refering to the success or failure of the request
 */
export async function create(data) {
    return await request.post(`${API}user`, data);
}

/**
 * The rest request which attempts to update an existing user.
 * @param {*} data A JSON object which contains the updated infromation of the user
 * @returns an object refering to the success or failure of the request
 */
export async function update(data) {
    return await request.put(`${API}user`, data);
}

/**
 * The rest request which attempts to change the password of a specific user.
 * @param {*} userID The id of the user to change the password
 * @param {*} data A JSON object which contains the updated infromation of the user password
 * @returns an object refering to the success or failure of the request
 */
export async function changePassword(userID, data) {
    return await request.put(`${API}user/${userID}/password`, data);
}

/**
 * The rest request which attempts to request otp code for reset password
 *
 * @param {*} data A JSON object which contains the updated infromation of the user password
 * @returns an object refering to the success or failure of the request
 */
export async function forgotPassword(data) {
    return await request.post(`${API}user/forgotPassword?emailOrUsername=` + data);
}


/**
 * The rest request which attempts to check the otp code
 *
 * @param {*} data A JSON object which contains the updated infromation of the user password
 * @returns an object refering to the success or failure of the request
 */
export async function checkPasswordResetCode(data, otpCode) {
    return await request.post(`${API}user/checkPasswordResetCode?emailOrUsername=${data.username}&resetPasswordCode=${otpCode}`);
}

/**
 * The rest request which attempts to reset user's password
 *
 * @param {*} data A JSON object which contains the updated infromation of the user password
 * @returns an object refering to the success or failure of the request
 */
export async function resetPassword(data, otpCode) {
    return await request.post(`${API}user/resetPassword?emailOrUsername=${data.username}&resetPasswordCode=${otpCode}&newPassword=${data.newPassword}`);
}

/**
 * The rest request which attempts to enable an existing user.
 * @param {*} userId The id of the user to be enabled
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function enable(userId) {
    return await request.post(`${API}user/${userId}/enable`);
}

/**
 * The rest request which attempts to disable an existing user.
 * @param {*} userId The id of the user to be disabled
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function disable(userId) {
    return await request.post(`${API}user/${userId}/disable`);
}

/**
 * The rest request which request a user logout action to be performed.
 * @returns an object refering to the success or failure of the request
 */
export async function logout() {
    return await request.post(`${API}user/logout`);
}

/**
 * The rest request to delete a specific user.
 * @param {*} id The id of the user to be deleted
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function deleteById(id) {
    return await request.delete(`${API}user/${id}`);
}


/**
 * The rest request which attempts to fetch user my sections.
 * @returns an object refering to the success or failure of the request
 */
export async function fetchMySections() {
    return await request.get(`${API}user/section`);
}


const userApi = {
    fetchAll,
    fetchAllByOrganizationID,
    fetchAllByOutletID,
    fetchNew,
    fetchOne,
    fetchAuthDetails,
    fetchAuth,
    fetchMySections,
    create,
    update,
    enable,
    disable,
    changePassword,
    forgotPassword,
    checkPasswordResetCode,
    resetPassword,
    deleteById,
    logout
}

export default userApi;