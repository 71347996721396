import { request } from 'constants/alias';
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from 'utils/functions';
import updateFieldIfValid from 'utils/functions/updateFieldIfValid';

/**
 * The rest request which attempts to get the chat view details from the database.
 * @returns an object with an attribute containing the chat view details 
 */
export async function fetchAll(pageable, search, employeeID) {
    let data = {};
    updateFieldIfValid(data, "organizationID", search.organizationID);
    updateFieldIfValid(data, "outletID", search.outletID);
    updateFieldIfValid(data, "name", search.name);
    if (search.isMine) {
        data = { ...data, employeeIds: [employeeID] }
    }

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}chat`, pageable !== undefined ? { ...pageable, ...data } : data));
    else return await request.get(includeParamsToUrl(`${API}chat`, pageable !== undefined ? pageable : {}));
}

/**
 * The rest request which attempts to get an existing channel information.
 * @param {*} channelID The id of the channel to be fetched
 * @returns an object with an attribute containing the current infromation of the channel
 */
export async function fetchOne(channelID) {
    return await request.get(`${API}chat/${channelID}`);
}

/**
 * The rest request which attempts to create a new chat channel.
 * @param {*} data A JSON object which contains new chat channel infromation
 * @returns an object refering to the success or failure of the request
 */
export async function create(data) {
    // data.expirationDate = dateTimeFormatter(data.expirationDate, "yyyy-MM-DDTHH:mm:ss.SSSSSS");
    return await request.post(`${API}chat`, data);
}

/**
 * The rest request which attempts to create a new chat channel.
 * @param {*} data A JSON object which contains new chat channel infromation
 * @returns an object refering to the success or failure of the request
 */
export async function createMessage(channelID, message) {
    // data.expirationDate = dateTimeFormatter(data.expirationDate, "yyyy-MM-DDTHH:mm:ss.SSSSSS");
    return await request.post(`${API}chat/channel/${channelID}/message`, message);
}

const chatApi = {
    fetchAll,
    fetchOne,
    create,
    createMessage
}

export default chatApi;