// import { Settings as SettingsIcon } from '@mui/icons-material';
import { UploadFile } from '@mui/icons-material';
import { Button, Fab, Grid, Stack, Tooltip } from '@mui/material';
import menuApi from "api/menu";
import { AddIcon } from 'assets/svg/SvgIcons';
import CustomBreadcrumbMultiParent from "components/Breadcrumb/CustomBreadcrumbMultiParent";
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomRefreshButton from 'components/Button/CustomRefreshButton';
import CustomDialog from 'components/Dialogs/CustomDialog';
import CustomTranslationsDialog from 'components/Dialogs/CustomTranslationsDialog';
import CustomGrid from 'components/Grid/CustomGrid';
import ListPageSkeleton from 'components/Loading/ListPageSkeleton';
import CustomObjectSelect from 'components/Select/CustomObjectSelect';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';
import CustomPageableTable from "components/Table/CustomPageableTable";
import CustomTitle from "components/Title/CustomTitle";
import { getDateTimeFormatter } from 'constants/calendar';
import { getTranslationDefaultObject } from 'constants/defaultObjects';
import { getDefaultSortDirectionData } from 'constants/sortDirection';
import { CSVImporter } from 'csv-import-react';
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import { fabStyle, fabStyleIcon, fabTooltipStyle, mainAreaTable, mainTag, mainTagBreadcrumb, mainTagSearch, mainTagTitle, pageAddButton } from 'themes/defaultThemes';
import { hasRoleAdmin, hasRoleAdminMenu, hasRoleAdminOrDirectorOrManager } from 'utils/auth';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';
import { prepareTablePaginationSortParams } from 'utils/functions';
import { getLanguageFromURL } from "utils/language";
import { actionFormatterMenuCMS, dateFormatter, fieldFormatter, iconFormatter, idFormatterLinkWithParent, recurrenceFormatter } from "utils/table";
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The Menu List component to view the menus of the system.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function MenuListComponent() {
    const { t } = useTranslation();

    const { organizationID } = useParams();
    const { outletID } = useParams();

    const navigate = useNavigate();

    let path = `/${getLanguageFromURL()}`
    if (organizationID !== undefined) path += `/organization/${organizationID}`;
    if (outletID !== undefined) path += `/outlet/${outletID}`;
    path += `/menu`;

    /**
     * @type {object}
     * @property {object} userAuth the authenticated user infromation
     * @property {array} menus the menus (pageable)
     * @property {object} organization the organization details
     * @property {object} pagination includes all pageable details (page, size, sort, direction)
     */
    const [data, setData] = useState({
        userAuth: null,
        menus: null,
        organization: {
            id: organizationID,
            name: ""
        },
        organizations: [],
        outlets: [],
        pagination: {
            count: -1,
            size: 10,
            page: 0,
            ...getDefaultSortDirectionData
        }
    });

    //change document title
    useDocumentTitle(`Usee  ${(organizationID !== undefined && data.userAuth !== null && !hasRoleAdminMenu(data.userAuth.roles)) ? " | " + t('org.pageTitle') : ""} ${outletID !== undefined ? " | " + t('outlet.pageTitle') : ""} | ${t('menus.title')}`);

    // to perform initial request
    const [isFirstTime, setIsFirstTime] = useState(true);
    // if true the menu item is clicked and a redirect to page should perform
    const [isLoaded, setIsLoaded] = useState(false);
    // for search
    const [search, setSearch] = useState({
        organizationID: organizationID,
        outletID: outletID,
        outletIds: [],
        field: "name",
        name: ""
    });
    // pop dialog for delete
    const [dialog, setDialog] = useState({
        isOpen: false,
        menuID: "",
        name: ""
    });
    // pop-up dialog for translations
    const [transDialog, setTransDialog] = useState({
        isOpen: false,
        message: t("label.configureTranslations"),
        id: "",
        field: "name",
        translations: {}
    });
    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313"
    });

    const [importCsvDialogOpen, setImportCsvDialogOpen] = useState(false);
    const [menuName, setMenuName] = useState(getTranslationDefaultObject());
    const [menuData, setMenuData] = useState({});

    useEffect(() => {
        getMenus();
        setIsFirstTime(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const identifier = setTimeout(() => {
            if (!isFirstTime) getMenus();
        }, 700);

        return () => {
            clearTimeout(identifier);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, data.pagination.size, data.pagination.page, data.pagination.sort, data.pagination.direction])


    /**
     * The rest endpoint to get the menu list.
     * @property {number} page the list page number
     */
    function getMenus(page) {
        let sear = search;
        if (hasRoleAdminMenu(data.userAuth?.roles)) {
            sear.organizationID = localStorage.getItem("oid")
        }

        menuApi.fetchAll(prepareTablePaginationSortParams(data.pagination), sear).then((r) => {
            Promise.resolve()
                .then(() => {
                    if (r.data?.code === "SUCCESS")
                        setData({
                            ...data,
                            userAuth: r.data.returnobject.userAuth,
                            menus: r.data.returnobject.page,
                            organization: r.data.returnobject.organization,
                            organizations: r.data.returnobject.organizations,
                            outlets: r.data.returnobject.outlets,
                            pagination: {
                                ...data.pagination,
                                ...(r.data.returnobject.page != null ? {
                                    count: r.data.returnobject.page?.totalPages,
                                    size: r.data.returnobject.page?.size,
                                    page: r.data.returnobject.page?.number,
                                } : {})
                            }
                        });

                    if (hasRoleAdminMenu(r.data.returnobject.userAuth?.roles) && isFirstTime) {
                        setSearch({ ...search, organizationID: localStorage.getItem("oid") });
                    }
                })
                .then(() => {
                    setIsLoaded(true);
                })
        }).catch((e) => {
            // console.log(e);
        })
    }

    /**
     * The rest endpoint to clear all menu, parent categories and categories for organization.
     */
    function clearAll() {
        menuApi.clearAll(organizationID).then((r) => {
            setAlertBox({
                isOpen: true,
                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
            // setRedirect((r.data.code === "SUCCESS" ? true : false));
            getMenus();
        }).catch((e) => {
            // console.log(e);
        })
    }

    /**
     * Gets called to activate the selected menu
     * @param {*} event
     * @param {string} id the id of the menu
     */
    function activateMenu(event, id) {
        menuApi.activate(id).then((r) => {
            setAlertBox({
                isOpen: true,
                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
            // setRedirect((r.data.code === "SUCCESS" ? true : false));
            getMenus();
        }).catch((e) => {
            // console.log(e);
        })
    }

    /**
     * Gets called to activate the selected menu
     * @param {*} event
     * @param {string} id the id of the menu 
     */
    function disableMenu(event, id) {
        menuApi.disable(id).then((r) => {
            setAlertBox({
                isOpen: true,
                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
            // setRedirect((r.data.code === "SUCCESS" ? true : false));
            getMenus();
        }).catch((e) => {
            // console.log(e);
        })
    }

    /**
     * Function that triggers the multi select input value change.
     * @param {*} event 
     *//* eslint-disable no-unused-vars */
    function onSelectSearchChange(event) {
        let field = event.target.value;
        setSearch({
            ...search,
            field: field,
            name: {
                en: ""
            },
            outletIds: [],
            [field]: ""
        });
    }
    /* eslint-enable no-unused-vars */

    /**
     * Function that triggers the removal of the selected menu.
     * @property {*} event
     * @property {string} menuID The id of the selected menu to be removed.
     */
    function deleteMenu(event, menuID, name) {
        handleDialogState(true, menuID, name);
    }

    /**
     * Function that handles the delete modal open or close state.
     * 
     * @property {boolean} isOpen The state of the dialog open status.
     * @property {string} menuID The id of the selected menu to be removed.
     */
    function handleDialogState(isOpen, menuID = "", name = "") {
        setDialog({
            ...dialog,
            isOpen: isOpen,
            menuID: menuID,
            name: name
        });
    }

    /**
     * Gets called to remove the selected menu
     */
    function deleteM() {
        menuApi.deleteById(dialog.menuID).then((r) => {
            handleDialogState(false);
            setAlertBox({
                isOpen: true,
                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
            // setRedirect((r.data.code === "SUCCESS" ? true : false));
            getMenus();
        }).catch((e) => {
            // console.log(e);
        })
    }

    /**
     * Function that handles the snackbar open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }

    /**
     * Function that handles the pagination information.
     * @property {int} page the page to be redirected
     */
    function handlePaginationChange(page) {
        formValChangeWithParentElementWithNameAndValue("pagination.page", page, data, setData);
    }

    /**
     * Function that handles the pagination information.
     * @property {int} size the size the new numberOfRows to be requested
     */
    function handleRowsPerPageChange(size) {
        let pagination = data.pagination;
        pagination.size = size;
        pagination.page = 0;
        formValChangeWithParentElementWithNameAndValue("pagination", pagination, data, setData);
    }

    /**
     * Function that handles the sort information.
     * @property {int} size the size the new numberOfRows to be requested
     */
    function handleColumnSortChange(changedColumn, direction) {
        let pagination = data.pagination;
        pagination.sort = changedColumn;
        pagination.direction = direction;
        formValChangeWithParentElementWithNameAndValue("pagination", pagination, data, setData);
    }


    function importMenuItems(translations) {
        let d = menuData.rows.map((row) => row.values);
        d = d.map(item => ({
            ...item,
            itemVatType: item.itemVatType || "NONE" // This will set itemVatType to "NONE" if it's empty or null
        }));
        setImportCsvDialogOpen(false);

        menuApi.importCsv(data.organization.id, d, translations).then((r) => {
            if (r.data.code === "SUCCESS") {
                setImportCsvDialogOpen(false);
                getMenus();
                setMenuData({});
                setMenuName(getTranslationDefaultObject());
            } else {
                setAlertBox({
                    isOpen: true,
                    message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                    backgroundColor: "#a71313"
                });
            }
        }).catch((e) => {
            setAlertBox({
                isOpen: true,
                message: "message" in e.data ? e.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: "#a71313"
            });
        })
    }

    /**
     * This function will trigger the menu field translations configuration
     */
    function configureMenuTranslations(translations, field) {
        setMenuName(translations);

        handleTranslationDialogState(true, field);
        setTransDialog({
            ...setTransDialog,
            isOpen: false,
            field: "",
            translations: {}
        });
        importMenuItems(translations);
    }

    /**
     * Function that handles the delete modal open or close state.
     * @property {boolean} isOpen If the values is `true`, the dialog should be open and visible.
     * @property {string} field The field of the selected text translations to be removed.
     * @property {string} dialogName The dialog name
     */
    function handleTranslationDialogState(isOpen, field = "", data) {
        setTransDialog({
            ...transDialog,
            isOpen: isOpen,
            field: field,
            translations: menuName
        });
        if (data) setMenuData(data);
        setImportCsvDialogOpen(false);
    }

    // ===============================================================================RENDER

    if (!isLoaded) {
        return <CustomGrid role="main" id="menu-list" sx={mainTag()}>
            <ListPageSkeleton />
        </CustomGrid>
    } else {
        /**
         * datatable columns.
         */
        const table_columns = [
            {
                name: "active",
                label: t('label.isActive'),
                options: {
                    filter: false,
                    sort: false,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return iconFormatter(value,
                            tableMeta.tableData[tableMeta.rowIndex],
                            tableMeta.rowIndex,
                            {
                                enable: (event, rowIndex) => activateMenu(event, rowIndex),
                                disable: (event, rowIndex) => disableMenu(event, rowIndex),
                                positionOfId: 1
                            });
                    }
                }
            },
            {
                name: "id",
                label: t('label.id'),
                options: {
                    viewColumns: data.userAuth !== null && hasRoleAdmin(data.userAuth.roles) ? true : false,
                    filter: false,
                    sort: false,
                    empty: false,
                    display: data.userAuth !== null && hasRoleAdmin(data.userAuth.roles) ? true : false,
                    customBodyRender: (value, tableMeta) => {
                        return idFormatterLinkWithParent(value, tableMeta.tableData[tableMeta.rowIndex], path, "/categories");
                    }
                }
            },
            {
                name: "name",
                label: t('label.name'),
                options: {
                    filter: true,
                    filterType: 'textField',
                    customFilterListRender: v => `${t('label.name')}: ${v}`,
                    sort: true,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return value?.en//nameFormatterLinkWithParentMultilingual(value, tableMeta.tableData[tableMeta.rowIndex], `${path}`, "/categories", "en", 1);
                    }
                }
            },
            {
                name: "outlets",
                label: `${t('menus.usedBy')}  ${t('outlet.title')}`,
                options: {
                    filter: false,
                    sort: true,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return <Grid container spacing={2} direction="column">
                            <Grid item xs={12} sm={12}>
                                {fieldFormatter(value?.length, { backgroundColor: "lightgrey", color: "grey" })}
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                {value?.map((outlet) =>
                                    fieldFormatter(outlet.name)
                                )}
                            </Grid>
                        </Grid>
                    }
                }
            },
            {
                name: "hasRecurrence",
                label: t('menus.recurrence'),
                options: {
                    filter: false,
                    sort: true,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return recurrenceFormatter(value,
                            tableMeta.tableData[tableMeta.rowIndex],
                            t('menus.recurrenceDaysNumberBeforeLabel'),
                            `${(tableMeta.tableData[tableMeta.rowIndex].recurrenceDaysNumber !== 1) ? t('menus.recurrenceDaysNumberAfterLabel') : t('menus.recurrenceDaysNumberAfterLabelOne')}`,
                            `${t('menus.startDate')}: `,
                            `${t('menus.endDate')}: `,
                            5,
                            6,
                            7
                        );
                    }
                }
            },
            {
                name: "recurrenceDaysNumber",
                options: {
                    viewColumns: false,
                    filter: false,
                    display: false,
                }
            },
            {
                name: "startDate",
                options: {
                    viewColumns: false,
                    filter: false,
                    display: false,
                }
            },
            {
                name: "endDate",
                options: {
                    viewColumns: false,
                    filter: false,
                    display: false,
                }
            },
            {
                name: "lastActivity",
                label: t('label.lastActivity'),
                options: {
                    filter: false,
                    sort: false,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return dateFormatter(value, tableMeta.tableData[tableMeta.rowIndex], null, getDateTimeFormatter());
                    }
                }
            },
            {
                name: "",
                label: t('actions.label'),
                options: {
                    filter: false,
                    sort: false,
                    empty: true,
                    customBodyRender: (value, tableMeta) => {
                        return actionFormatterMenuCMS(value,
                            tableMeta.tableData[tableMeta.rowIndex],
                            tableMeta.rowIndex,
                            1,
                            2,
                            path,
                            data.showLabel ? t('actions.edit') : undefined,
                            (event, rowIndex, fullName) => deleteMenu(event, rowIndex, fullName),
                            data.showLabel ? t('actions.delete') : undefined,
                            data.showLabel ? t('actions.cms') : undefined,
                            10
                            // "CMS"
                        )
                    },
                    setCellProps: () => ({ className: "click action" })
                }
            },
            {
                name: "settings",
                options: {
                    viewColumns: false,
                    filter: false,
                    sort: false,
                    empty: true,
                    display: false
                }
            }
        ];

        return (
            <CustomGrid role="main" id="menu-list" sx={mainTag()}>
                {outletID !== undefined && organizationID !== undefined &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomBreadcrumbMultiParent
                            parents={[
                                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                                { name: t('outlet.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet` },
                                { name: data.outlet.name, url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet/${outletID}` }
                            ]}
                            instanceName={t('menus.title')}
                        />
                    </CustomGrid>
                }
                {outletID === undefined && organizationID !== undefined &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` }
                            ]}
                            instanceName={t('menus.title')}
                        />
                    </CustomGrid>
                }



                <Grid container spacing={2} sx={{ alignItems: "center" }}>
                    <Grid item xs={"auto"}>
                        <CustomRefreshButton onClick={getMenus} marginTop="0" />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <CustomGrid sx={mainTagTitle()}>
                            {/* <Grid item >
                                <CustomIconButton onClick={() => navigate(`${path}/settings`)}
                                    marginTop="-1%"
                                    icon={<SettingsIcon />}
                                    sx={{ borderRadius: "20px", minWidth: "40px" }}
                                    iconSx={{ margin: 0 }}
                                    tooltipTitle={t('menu.settings')}
                                />
                            </Grid> */}
                            <Grid item >
                                <CustomTitle
                                    title={t('menus.title')}
                                    subtitle={""}
                                />
                            </Grid>
                        </CustomGrid>

                    </Grid>

                    <Grid item xs={12} sm={8}>
                        <Stack direction="row" sx={mainTagSearch()}>
                            <CustomGrid container={true} spacing={2} sx={{ justifyContent: "right" }}>
                                {/* {(data.userAuth !== null && hasRoleAdmin(data.userAuth.roles) && organizationID === undefined) &&
                                    <CustomObjectSelect
                                        name="organizationID"
                                        required={true}
                                        defaultValue={search.organizationID}
                                        labelID="organizationID"
                                        label={t('org.pageTitle')}
                                        options={data.organizations}
                                        onChange={(event) => { setIsLoaded(false); setSearch({ ...search, organizationID: event.target.value }) }}
                                        sx={mainTagSearchItem()}
                                        field="name"
                                    />
                                }

                                <CustomObjectSelect
                                    name="outletIds"
                                    required={false}
                                    defaultValue={search.outletIds}
                                    labelID="outletIds"
                                    label={t('outlet.pageTitle')}
                                    options={[{ "id": "", name: t(('label.all')) }, ...data.outlets]}
                                    onChange={onSelectSearchChange}
                                    sx={{ ...mainTagSearchItem(), ...mainTagSearchObjectSelect(), marginRight: "10px" }}
                                    field="name"
                                /> */}

                                {/* <CustomTextField type="search" name="name" id="name" variant="filled" label={t('actions.search') + " by " + t('label.name')}
                                    defaultValue={search.name !== null ? search.name : ""}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end" >
                                                <CustomSearchButton onClick={getMenus} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    required={false}
                                    sx={{ ...mainTagSearchItem(), ...mainTagSearchSelect() }}
                                    onChange={(event) => formValChangeWithParentElementSelect(event, search, setSearch, t('table.valueReuired'))}
                                /> */}

                                {hasRoleAdminMenu(data.userAuth?.roles) &&
                                    <Grid item xs={"auto"}>
                                        <label htmlFor="organizationID">{t('org.pageTitle')}</label>
                                        <CustomObjectSelect
                                            name="organizationID"
                                            required={true}
                                            value={localStorage.getItem("oid")}
                                            // labelID="organizationID" label={t('org.pageTitle')}
                                            options={data.organizations}
                                            onChange={(event) => { localStorage.setItem("oid", event.target?.value); setSearch({ ...search, organizationID: event.target?.value }) }}
                                            field={"name"}
                                            sx={{ borderRadius: "30px" }}
                                        />
                                    </Grid>
                                }

                                <Grid item>
                                    <CustomIconButton
                                        onClick={() => setImportCsvDialogOpen(true)}
                                        icon={<UploadFile style={{ marginRight: "0px" }} />}
                                        className="icon-wrapper tr link"
                                        iconSx={{ marginRight: "0px!important" }}
                                        tooltipTitle={`${t('actions.import')} ${t('label.csv')}`}
                                        sx={{ backgroundColor: "#54b1d2", borderRadius: "50px", color: "white", padding: "8px 0px", minWidth: "40px", marginRight: "10px", marginTop: "1.5%" }}
                                    />
                                </Grid>

                                <Grid item>
                                    <Button variant="text"
                                        onClick={() => handleDialogState(true, "")}
                                        sx={pageAddButton()}
                                    >
                                        {t('actions.clearAll')}
                                    </Button>
                                </Grid>
                            </CustomGrid>
                        </Stack>
                    </Grid>
                </Grid>

                <CustomGrid sx={mainAreaTable()} container={false}>
                    <CustomPageableTable
                        instances={data.menus}
                        emptyMessage={t('menus.emptyMessage')}
                        table_columns={table_columns}
                        keyField="id"
                        defaultSorted={{ name: data.pagination.sort, direction: data.pagination.direction }}
                        pagination={data.pagination}
                        handlePaginationChange={handlePaginationChange}
                        handleRowsPerPageChange={handleRowsPerPageChange}
                        handleColumnSortChange={handleColumnSortChange}
                        filter={true}
                        onFilterChange={(event) => (event !== null) ? formValChangeWithParentElementWithNameAndValue(event.field, event.value, search, setSearch) : setSearch({
                            ...search,
                            name: "",
                            outletIds: [],
                            field: "name"
                        })}
                    />

                    {((search.organizationID && hasRoleAdminMenu(data.userAuth.roles)) || hasRoleAdminOrDirectorOrManager(data.userAuth.roles)) &&
                        <Fab color="primary"
                            aria-label="add"
                            sx={fabStyle()}
                            onClick={() => navigate(hasRoleAdminMenu(data.userAuth.roles) ? `/${getLanguageFromURL()}/organization/${search.organizationID}/menu/add` : `${path}/add`)}
                        >
                            <Tooltip arrow title={t('actions.add') + " " + t('menus.pageTitle')} sx={fabTooltipStyle()}>
                                <AddIcon {...fabStyleIcon()} />
                            </Tooltip>
                        </Fab>
                    }
                </CustomGrid>

                {dialog.isOpen &&
                    <CustomDialog
                        isOpen={dialog.isOpen}
                        id="delete"
                        title={t('actions.deleteModal.title')}
                        actionLabel={t("actions.deleteModal.message")}
                        cancelLabel={t("actions.cancel")}
                        boldMessage={<span style={{ fontWeight: "bold" }}>{dialog.name}</span>}
                        message={`${t('actions.deleteModal.notice')} ${dialog.menuID !== "" ? t('actions.deleteModal.confirm') : t('actions.deleteModal.confirmClear')} ${t('menus.pageTitle')} `} a
                        action={dialog.menuID !== "" ? deleteM : clearAll}
                        handleOpen={handleDialogState} />
                }

                <CSVImporter
                    modalIsOpen={importCsvDialogOpen}
                    modalOnCloseTriggered={() => setImportCsvDialogOpen(false)}
                    darkMode={false}
                    onComplete={(data) => handleTranslationDialogState(true, 'name', data)}//importMenuItems(data)}
                    customStyles={{
                        "color-primary": "#47a6d2",
                        "color-secondary": "#47a6d2",
                    }}
                    template={{
                        columns: [
                            {
                                name: "nameEN",
                                key: "nameEN",
                                required: true,
                                data_type: "text",
                                description: "The English name of the item",
                            },
                            {
                                name: "nameEL",
                                key: "nameEL",
                                required: false,
                                data_type: "text",
                                description: "The Greek name of the item",
                            },
                            {
                                name: "descriptionEN",
                                key: "descriptionEN",
                                required: false,
                                data_type: "text",
                                description: "The English description of the item",
                            },
                            {
                                name: "descriptionEL",
                                key: "descriptionEL",
                                required: false,
                                description: "The Greek description of the item",
                            },
                            {
                                name: "price",
                                key: "price",
                                data_type: "number",
                                required: true,
                            },
                            {
                                name: "itemVatType",
                                key: "itemVatType",
                                data_type: "text",
                                required: false,
                            },
                            {
                                name: "categoryEN",
                                key: "categoryEN",
                                required: false,
                                data_type: "text",
                                description: "The English category of the item",
                            },
                            {
                                name: "categoryEL",
                                key: "categoryEL",
                                required: false,
                                description: "The Greek category of the item",
                            },
                        ]
                    }}
                />

                {transDialog.isOpen &&
                    <CustomTranslationsDialog
                        isOpen={transDialog.isOpen} id="translations"
                        title={`${t('actions.add')} ${t('menus.pageTitle')} ${t('label.name')}`}
                        actionLabel={t('actions.add')}
                        action={configureMenuTranslations}
                        handleOpen={handleTranslationDialogState}
                        translations={menuName}
                        field={transDialog.field}
                    />
                }

                {alertBox.isOpen &&
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={3000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        handleSnackbarOpen={handleSnackbarState} />
                }
            </CustomGrid>
        );
    }
}

export default MenuListComponent;