import { faFacebook, faInstagram, faLinkedin, faPinterest, faSnapchat, faTiktok, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { openInNewTab } from './functions';

/**
* Get Font Awesome icon based on the social profile name.
* @param {string} socialProfileName - The name of the social profile.
* @returns {JSX.Element|null} - The corresponding Font Awesome icon or null if no matching icon is found.
*/
export function getSocialFontAwesomeIcon(socialProfileName, url) {
    let icon = null;

    switch (socialProfileName) {
        case "Facebook":
            icon = faFacebook;
            break;
        case "Instagram":
            icon = faInstagram;
            break;
        case "Linkedin":
            icon = faLinkedin;
            break;
        case "Pinterest":
            icon = faPinterest;
            break;
        case "Snapchat":
            icon = faSnapchat;
            break;
        case "TikTok":
            icon = faTiktok;
            break;
        case "Twitter":
            icon = faTwitter;
            break;
        case "Youtube":
            icon = faYoutube;
            break;
        default:
            break;
    }

    if (icon) {
        return <FontAwesomeIcon icon={icon} style={{ cursor: (url !== "") ? "pointer" : "none" }} onClick={() => { if (url !== "") openInNewTab(url) }} />;
    }

    return null;
}

export default class functions {
    static getSocialFontAwesomeIcon(socialProfileName) { return getSocialFontAwesomeIcon(socialProfileName); }
}