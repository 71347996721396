import { request } from 'constants/alias';
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from 'utils/functions';
import updateFieldIfValid from 'utils/functions/updateFieldIfValid';

/**
 * The rest request which attempts to get the email templates list from the database.
 * @param {*} search The filters for search.
 * 
 * @returns an object with an attribute containing the email templates list
 */
export async function fetchAll(search) {
    let data = {};
    updateFieldIfValid(data, "organizationID", search.organizationID);
    updateFieldIfValid(data, "outletID", search.outletID);
    updateFieldIfValid(data, "name", search.name);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}marketing/email/template`, data));
    else return await request.get(includeParamsToUrl(`${API}marketing/email/template`));
}

/**
 * The rest request which attempts to create a new email template.
 * @param {*} data A JSON object which contains new email template infromation
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function create(data) {
    return await request.post(`${API}marketing/email/template`, data);
}

/**
 * The rest request which attempts to update an existing email template.
 * @param {*} data A JSON object which contains the updated infromation of the email template
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function update(data) {
    return await request.put(`${API}marketing/email/template`, data);
}

/**
 * The rest request to delete a specific email template.
 * @param {*} id The id of the email template to be deleted
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function deleteById(id) {
    return await request.delete(`${API}marketing/email/template/${id}`);
}

const emailTemplateApi = {
    fetchAll,
    create,
    update,
    deleteById,
}

export default emailTemplateApi;