import { Box, Divider, Fab, FormControlLabel, Grid, InputLabel, Switch, Typography } from '@mui/material';
import smsCampaignApi from 'api/smsCampaign';
import { SendIcon } from 'assets/mui/MuiIcons';
import CustomAddEditBreadcrumbMultiParent from 'components/Breadcrumb/CustomAddEditBreadcrumbMultiParent';
import CustomDateTimePicker from 'components/DatePicker/CustomDateTimePicker';
import CustomGrid from 'components/Grid/CustomGrid';
import LoadingSkeleton from 'components/Loading/LoadingSkeleton';
import CustomObjectSelect from 'components/Select/CustomObjectSelect';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';
import CustomListTable from 'components/Table/CustomListTable';
import CustomTextArea from 'components/Text/CustomTextArea';
import CustomTitleBoldSubtitle from 'components/Title/CustomTitleBoldSubtitle';
import { getDefaultSmsCampaignObject } from 'constants/defaultObjects';
import { getDefaultRecipientForGroupSortDirection } from 'constants/sortDirection';
import { recipient_table_columns_sms_campaign } from 'constants/table/recipientTable';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { fabStyle, mainArea, mainTag, mainTagBreadcrumb, mainTagSearchItem, mainTagTitle, requestTextareaBoxStyle } from 'themes/defaultThemes';
import { hasRoleAdminOrDirectorOrManager } from 'utils/auth';
import { formIsValid, formValChangeWithParentElementWithNameAndValue, onControlChange } from 'utils/form-validation';
import { getLanguageFromURL } from "utils/language";
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The SmsCampaignAddViewComponent, that presents the sms campaign main component.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function SmsCampaignAddViewComponent() {
    const { t } = useTranslation();
    const { organizationID } = useParams();
    const { outletID } = useParams();
    const { smsCampaignID } = useParams();
    const navigate = useNavigate();

    let path = `/${getLanguageFromURL()}`
    if (organizationID !== undefined) path += `/organization/${organizationID}`;
    if (outletID !== undefined) path += `/outlet/${outletID}`;
    path += `/marketing/campaign/sms`;

    // to render DOM based on rest api call status, if true the rest api call completed
    const [redirect, setRedirect] = useState(false);
    // if true the menu item is clicked and a redirect to page should perform
    const [isLoaded, setIsLoaded] = useState(false);

    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313"
    });

    /**
     * @type {object}
     * @property {string} error holdes the error message of a failed rest api call
     * @property {object} userAuth the authenticated recipient infromation
     * @property {boolean} isAdd to define if requested an edit or an add of a recipient group
     * @property {string} smsCampaignID tha recipient group id to get recipient current information for edit recipient page
     * @property {object} sms an empty object if add or the selected sms campaign to be editted
     * @property {object} isError list of indication of empty form required field 
     *                           after submit to present error message to the
     *                           fields/attributes with true value
     */
    const [data, setData] = useState({
        error: null,
        userAuth: null,
        isAdd: smsCampaignID === undefined,
        smsCampaignID: smsCampaignID,
        sms: null,
        recipientGroupIDs: [],
        recipientsGroups: [],
        recipients: [],
        smsLength: 160,
        organization: {
            id: organizationID,
            name: ""
        },
        outlet: {
            id: outletID,
            name: ""
        },
        // error messages per field
        isError: {
            name: "",
        }
    });

    // Calculate the minimum date-time (5 minutes from now)
    const minDateTime = new Date();
    minDateTime.setMinutes(minDateTime.getMinutes() + 5);

    //change document title
    useDocumentTitle(`Usee  ${organizationID !== undefined ? " | " + t('org.pageTitle') : ""} ${outletID !== undefined ? " | " + t('outlet.pageTitle') : ""} | ${t('marketing.campaign.sms.title')}`);

    useEffect(() => {
        getSms();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * The rest endpoint to get the sms default (add) or current (edit) information.
     */
    function getSms() {
        if (data.isAdd) {
            smsCampaignApi.fetchNew(organizationID, outletID).then((r) => {
                setData({
                    ...data,
                    userAuth: r.data.returnobject.userAuth,
                    sms: {
                        organizationID: organizationID,
                        outletIds: outletID === undefined ? undefined : [outletID],
                        ...getDefaultSmsCampaignObject()
                    },
                    organization: r.data.returnobject.organization,
                    outlet: r.data.returnobject.outlet,
                    smsLength: r.data.returnobject.smsLength,
                    recipientsGroups: r.data.returnobject.recipientsGroups
                });
                setIsLoaded(true);
            }).catch((e) => {
                // console.log(e);
            })
        } else {
            smsCampaignApi.fetchOne(organizationID, outletID, smsCampaignID).then((r) => {
                setData({
                    ...data,
                    userAuth: r.data.returnobject.userAuth,
                    sms: r.data.returnobject.sms,
                    organization: r.data.returnobject.organization,
                    outlet: r.data.returnobject.outlet,
                    smsLength: r.data.returnobject.smsLength,
                    recipientsGroups: r.data.returnobject.recipientsGroups,
                    recipients: r.data.returnobject.recipients
                });
                setIsLoaded(true);
            }).catch((e) => {
                // console.log(e);
            })
        }
    }

    /**
     * Function that triggers form validation and print out if the form is valid or not.
     * @returns true if form is Valid
     */
    function formValidation() {
        let isError = createErrorMessages();
        if (formIsValid(isError)) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Function that create error messages for each required field that are not filled out.
     * @returns object containing the error messages for each form field
     */
    function createErrorMessages() {
        let isError = { ...data.isError };

        setData({
            ...data,
            isError
        });
        return isError;
    }
    /**
     * Gets called when the sms clicks on the save button, and triggers 
     * the creation of the new sms.
     */
    function addSms() {
        if (formValidation()) {
            let sms = data.sms;
            sms.recipientsGroupIds = data.sms.recipientsGroupIds;

            smsCampaignApi.create(sms).then((r) => {
                Promise.resolve()
                    .then(() => {
                        setAlertBox({
                            isOpen: true,
                            message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                            backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                        });
                    })
                    .then(() => {
                        if (r.data.code === "SUCCESS")
                            setRedirect(true);
                    })
            }).catch((e) => {
                // console.log(e)
            });
        }
    }

    /**
     * Function that handles the snackbar open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }

    // ===============================================================================RENDER

    if (redirect) {
        navigate(`${path}`);
    } else if ((!isLoaded && !data.isAdd) || data.sms == null) {
        return (
            <LoadingSkeleton lines={9} />
        );
    } else {
        const messageLength = Math.floor(data.sms.sms.length / data.smsLength);

        return (
            <CustomGrid role="main" id="test" sx={mainTag()}>
                {(outletID !== undefined && organizationID !== undefined && hasRoleAdminOrDirectorOrManager(data.userAuth?.roles)) &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                                { name: t('outlet.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet` },
                                { name: data.outlet.name, url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet/${outletID}` },
                                { name: t('marketing.campaign.sms.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet/${outletID}/marketing/campaign/sms` }
                            ]}
                            instanceName={""}
                        />
                    </CustomGrid>
                }

                {(outletID === undefined && organizationID !== undefined && hasRoleAdminOrDirectorOrManager(data.userAuth?.roles)) &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            editLabel={'actions.view'}
                            parents={[
                                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                                { name: t('marketing.campaign.sms.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/marketing/campaign/sms` }
                            ]}
                            instanceName={""}
                        />
                    </CustomGrid>
                }

                {(outletID === undefined && organizationID === undefined && hasRoleAdminOrDirectorOrManager(data.userAuth?.roles)) &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            editLabel={'actions.view'}
                            parents={[
                                { name: t('marketing.campaign.sms.pageTitle'), url: `/${getLanguageFromURL()}/marketing/campaign/sms` }
                            ]}
                            instanceName={""}
                        />
                    </CustomGrid>
                }

                <CustomGrid sx={mainTagTitle()}>
                    <CustomTitleBoldSubtitle
                        title={`${t('marketing.campaign.sms.title')} | ${data.isAdd ? t('actions.add') : data.sms.sent ? t('actions.view') : t('actions.edit')}`}
                        subtitleBeforeText={data.isAdd ? t('actions.tableCreateNewLink') + t('marketing.campaign.sms.title') : t('marketing.campaign.sms.title') + " "}
                        subtitleboldText={""}
                        subtitleAfterText={""}
                    />
                </CustomGrid>

                <CustomGrid sx={mainArea()} container={true} gap={2}>
                    {!data.sms.sent &&
                        <Grid item xs={12} sm={5.9}>
                            <InputLabel required={true} htmlFor="to">{t('marketing.campaign.sms.to')}</InputLabel>
                            <CustomObjectSelect
                                name="recipientsGroupIds"
                                required={true}
                                value={data.sms.recipientsGroupIds}
                                // labelID="recipientsIds"
                                // label={t('marketing.recipient.group.pageTitle')}
                                options={data.recipientsGroups}
                                isMultiple={true}
                                disabled={data.sms.sent}
                                onChange={(event) => formValChangeWithParentElementWithNameAndValue("sms.recipientsGroupIds", event.target.value, data, setData)}
                                sx={{ ...mainTagSearchItem(), width: "100%" }}
                                field="name"
                            />
                        </Grid>
                    }

                    <Grid item xs={12} sm={5.9}>
                        <InputLabel required={true} htmlFor="message">{t('marketing.campaign.sms.message')}</InputLabel>
                        <CustomTextArea
                            placeholder={t('marketing.campaign.sms.placeholder')}
                            // variant="outlined"
                            value={data.sms?.sms}
                            inputProps={{ maxLength: 100 }}
                            // maxLength={100}
                            onChange={(event) => {
                                let newValue = event.target.value;
                                formValChangeWithParentElementWithNameAndValue("sms.sms", newValue, data, setData);
                            }}
                            disabled={data.sms.sent}
                            sx={{ borderColor: "lightgrey" }}
                            endDecorator={
                                <Box sx={requestTextareaBoxStyle()}>
                                    <Divider flexItem={true} />
                                    <Typography level="span" sx={{ ml: 'auto', color: "initial" }}>
                                        {`${data.sms.sms?.length} ${data.sms.sms?.length === 1 ? t('marketing.campaign.sms.messageCharLimit') : t('marketing.campaign.sms.messageCharsLimit')} /${data.smsLength} ${(messageLength > 0 ? `(${messageLength})` : '')}`}
                                    </Typography>
                                </Box>
                            }
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <FormControlLabel
                            control={<Switch
                                defaultChecked={data.sms.scheduled}
                                onChange={(event) => onControlChange(event, data, setData, "sms", "scheduled")}
                                disabled={data.sms.sent}
                            />}
                            label={t('marketing.campaign.sms.scheduled')}
                        />

                        {data.sms.scheduled &&
                            <>
                                <InputLabel required={false} htmlFor="scheduledDateTime">{t('marketing.campaign.sms.scheduledDateTime')}</InputLabel>
                                <CustomDateTimePicker
                                    name="sms.scheduledDateTime"
                                    inputFormat="DD/MM/YYYY HH:MM"
                                    value={data.sms.sms}
                                    onChange={(newValue) => {
                                        formValChangeWithParentElementWithNameAndValue("sms.sms", newValue, data, setData);
                                    }}
                                    disabled={data.sms.sent}
                                    sx={{ width: "99.5%" }}
                                    minDateTime={minDateTime} // Restrict to 5 minutes after now
                                />
                            </>
                        }
                    </Grid>


                    {data.sms.sent &&
                        <Grid item xs={12} sm={12}>
                            <InputLabel required={true} htmlFor="to">{t('marketing.campaign.sms.to')}</InputLabel>
                            <CustomListTable
                                instances={data.recipients}
                                emptyMessage={t('marketing.recipient.empty')}
                                table_columns={recipient_table_columns_sms_campaign(data, t)}
                                keyField="id"
                                defaultSorted={getDefaultRecipientForGroupSortDirection}
                                filter={true}
                            />
                        </Grid>
                    }
                </CustomGrid>

                {(data.isAdd && data.sms.sms?.length > 0 && data.sms.recipientsGroupIds?.length > 0 && !data.sms.sent) &&
                    <Fab color="primary"
                        aria-label="add"
                        sx={fabStyle()}
                        onClick={addSms}
                    >
                        <SendIcon />
                    </Fab>
                }

                {alertBox.isOpen &&
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={3000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        handleSnackbarOpen={handleSnackbarState} />
                }
            </CustomGrid>
        );
    }
}

export default SmsCampaignAddViewComponent;