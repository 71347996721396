import React, { useState } from "react";
import {
    Drawer,
    Grid,
    IconButton,
    Paper,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import reorder from "utils/reOrder";
export default function RightSider({
    drawerWidth,
    cardWidth,
    open,
    Items,
    setItems,
    showParent,
    organizationID,
}) {
    const [search, setSearch] = useState("");

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const ordered = reorder(
            Items,
            result.source.index,
            result.destination.index
        );

        setItems(ordered);
    };

    const onToggleHideHandler = (id) => {
        setItems((d) =>
            d.map((ele) => {
                if (ele.id === id) {
                    ele.active = !ele.active;
                }
                return ele;
            })
        );
    };
    const onToggleUIHideHandler = (id) => {
        setItems((d) =>
            d.map((ele) => {
                if (ele.id === id) {
                    ele.activeUI = !ele.activeUI;
                }
                return ele;
            })
        );
    };

    let active = Items.some((x) => x.activeUI);
    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                    width: drawerWidth,
                    boxSizing: "border-box",
                    pb: "100px",
                    position: "absolute",
                    //height: "100vh",
                    top: "initial",
                },
            }}
            variant='persistent'
            anchor='right'
            open={open}
        >
            <Grid item sx={{ width: "100%", mt: "10px", px: "20px" }}>
                <Grid container wrap='nowrap' alignItems='center' gap='10px'>
                    <Grid item sx={{ flex: 1 }}>
                        <TextField
                            fullWidth
                            placeholder='Search by name'
                            variant='outlined'
                            size='small'
                            // sx={{ width: cardWidth }}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </Grid>

                    <Grid item>
                        <Tooltip
                            title={
                                active
                                    ? showParent
                                        ? "Hide all Parent categories"
                                        : "Hide all Categories"
                                    : showParent
                                        ? "Show all Parent categories"
                                        : "Show all categories"
                            }
                        >
                            <IconButton
                                sx={{ p: 0 }}
                                onClick={() =>
                                    setItems((d) =>
                                        d.map((ele) => {
                                            ele.activeUI = !active;

                                            return ele;
                                        })
                                    )
                                }
                            >
                                {!active ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction='column' sx={{ mt: "10px", px: "20px" }}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId='page'>
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {Items.map((item, i) => (
                                    <Draggable key={item.id} draggableId={item.id} index={i}>
                                        {(provided, snapshot) => (
                                            <Grid
                                                item
                                                sx={{
                                                    width: "100%",
                                                    mt: "10px",
                                                    display:
                                                        showParent && item.name?.en === "default"
                                                            ? "none"
                                                            : item.name?.en
                                                                ?.toLowerCase()
                                                                ?.includes(search.toLowerCase())
                                                                ? "block"
                                                                : "none",
                                                }}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <Grid
                                                    container
                                                    alignItems='center'
                                                    justifyContent='space-between'
                                                    wrap='nowrap'
                                                    component={Paper}
                                                    sx={{ padding: "4px 10px", width: cardWidth }}
                                                    gap={"5px"}
                                                >
                                                    <Grid item sx={{ flex: 1 }}>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                gap: "10px",
                                                            }}
                                                        >
                                                            {item.photo ? (
                                                                <img
                                                                    src={
                                                                        item.image
                                                                            ? item.photo.imageSrc
                                                                            : `${process.env.REACT_APP_IMAGE_HOST}/${organizationID}/${showParent
                                                                                ? "parentCategory"
                                                                                : "category"
                                                                            }/${item.id}/${item.photo.imageSrc}`
                                                                    }
                                                                    style={{ width: "20px", height: "20px" }}
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={
                                                                        showParent
                                                                            ? "/dev/parent.svg"
                                                                            : "/dev/category.svg"
                                                                    }
                                                                    style={{ width: "20px", height: "20px" }}
                                                                />
                                                            )}
                                                            <Typography variant='body2'>
                                                                {item.name?.en}
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container wrap='nowrap' gap={"4px"}>
                                                            <IconButton
                                                                sx={{ p: 0 }}
                                                                onClick={() => onToggleUIHideHandler(item.id)}
                                                            >
                                                                {item.activeUI ? (
                                                                    <VisibilityIcon fontSize='small' />
                                                                ) : (
                                                                    <VisibilityOffIcon fontSize='small' />
                                                                )}
                                                            </IconButton>
                                                            {/* <Switch
                                size='small'
                                color='success'
                                checked={item.active}
                                onChange={() => {
                                  onToggleHideHandler(item.id);
                                }}
                              /> */}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </Grid>
        </Drawer>
    );
}
