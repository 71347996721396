import { Grid, InputLabel } from '@mui/material';
import {
    EyeBallCircle,
    EyeBallDropEye,
    EyeBallDropEyeRight,
    EyeBallRounded,
    EyeBallSquare,
    EyeBallSquareCorner,
    EyeBallSquareCornerBottomLeft,
    EyeBallSquareCornerBottomRight,
    EyeBallSquareCornerRight,
    EyeFrameCircle,
    EyeFrameComic,
    EyeFrameCurved,
    EyeFrameLeaf,
    EyeFrameLeafRight,
    EyeFrameRounded,
    EyeFrameRoundedCorner,
    EyeFrameRoundedCornerBottomLeft,
    EyeFrameRoundedCornerBottomRight,
    EyeFrameRoundedCornerRight,
    EyeFrameSquare,
} from 'assets/svg/SvgIcons';
import CustomIconButton from 'components/Button/CustomIconButton';
import { getQrcodeEyeRadiusButtonIconProperties, getQrcodeEyeRadiusButtonProperties } from 'constants/fieldProperties';
import { useTranslation } from 'react-i18next';


/**
 * Custom QRcode eye radius options
 * @returns 
 */
function QRCodeEyeRadius({
    qrCodeTemplate,
    whichEye = "left",
    index = 0,
    updateEyeRadius
}) {
    const { t } = useTranslation();

    return (
        <>
            <Grid item xs={12} md={12} sx={{ paddingTop: "10px" }}>
                <InputLabel>{t('qrcode.settings.eyes.eyeFrameShape')}</InputLabel>
                <Grid container gap={2}>
                    <Grid item>
                        <CustomIconButton
                            {...getQrcodeEyeRadiusButtonProperties()}
                            keyValue={`eye-frame-shape-square`}
                            onClick={(event) => updateEyeRadius(whichEye, true, "square")}
                            isFocused={qrCodeTemplate.eyeRadius[index].outer === "square"}
                            icon={<EyeFrameSquare {...getQrcodeEyeRadiusButtonIconProperties()} />}
                        />
                    </Grid>
                    <Grid item>
                        <CustomIconButton
                            {...getQrcodeEyeRadiusButtonProperties()}
                            keyValue={`eye-frame-shape-curved`}
                            onClick={(event) => updateEyeRadius(whichEye, true, "curved")}
                            isFocused={qrCodeTemplate.eyeRadius[index].outer === "curved"}
                            icon={<EyeFrameCurved {...getQrcodeEyeRadiusButtonIconProperties()} />}
                        />
                    </Grid>
                    <Grid item>
                        <CustomIconButton
                            {...getQrcodeEyeRadiusButtonProperties()}
                            keyValue={`eye-frame-shape-rounded`}
                            onClick={(event) => updateEyeRadius(whichEye, true, "rounded")}
                            isFocused={qrCodeTemplate.eyeRadius[index].outer === "rounded"}
                            icon={<EyeFrameRounded {...getQrcodeEyeRadiusButtonIconProperties()} />}
                        />
                    </Grid>
                    <Grid item>
                        <CustomIconButton
                            {...getQrcodeEyeRadiusButtonProperties()}
                            keyValue={`eye-frame-shape-circle`}
                            onClick={(event) => updateEyeRadius(whichEye, true, "circle")}
                            isFocused={qrCodeTemplate.eyeRadius[index].outer === "circle"}
                            icon={<EyeFrameCircle {...getQrcodeEyeRadiusButtonIconProperties()} />}
                        />
                    </Grid>
                    <Grid item>
                        <CustomIconButton
                            {...getQrcodeEyeRadiusButtonProperties()}
                            keyValue={`eye-frame-shape-leafLeft`}
                            onClick={(event) => updateEyeRadius(whichEye, true, "leafLeft")}
                            isFocused={qrCodeTemplate.eyeRadius[index].outer === "leafLeft"}
                            icon={<EyeFrameLeaf {...getQrcodeEyeRadiusButtonIconProperties()} />}
                        />
                    </Grid>
                    <Grid item>
                        <CustomIconButton
                            {...getQrcodeEyeRadiusButtonProperties()}
                            keyValue={`eye-frame-shape-leafRight`}
                            onClick={(event) => updateEyeRadius(whichEye, true, "leafRight")}
                            isFocused={qrCodeTemplate.eyeRadius[index].outer === "leafRight"}
                            icon={<EyeFrameLeafRight {...getQrcodeEyeRadiusButtonIconProperties()} />}
                        />
                    </Grid>
                    <Grid item>
                        <CustomIconButton
                            {...getQrcodeEyeRadiusButtonProperties()}
                            keyValue={`eye-frame-shape-leafTopRight`}
                            onClick={(event) => updateEyeRadius(whichEye, true, "leafTopRight")}
                            isFocused={qrCodeTemplate.eyeRadius[index].outer === "leafTopRight"}
                            icon={<EyeFrameCircle {...getQrcodeEyeRadiusButtonIconProperties()} />}
                        />
                    </Grid>
                    <Grid item>
                        <CustomIconButton
                            {...getQrcodeEyeRadiusButtonProperties()}
                            keyValue={`eye-frame-shape-eyeLeft`}
                            onClick={(event) => updateEyeRadius(whichEye, true, "eyeLeft")}
                            isFocused={qrCodeTemplate.eyeRadius[index].outer === "eyeLeft"}
                            icon={<EyeFrameComic {...getQrcodeEyeRadiusButtonIconProperties()} />}
                        />
                    </Grid>
                    <Grid item>
                        <CustomIconButton
                            {...getQrcodeEyeRadiusButtonProperties()}
                            keyValue={`eye-frame-shape-gridTopLeft`}
                            onClick={(event) => updateEyeRadius(whichEye, true, "gridTopLeft")}
                            isFocused={qrCodeTemplate.eyeRadius[index].outer === "gridTopLeft"}
                            icon={<EyeFrameRoundedCorner {...getQrcodeEyeRadiusButtonIconProperties()} />}
                        />
                    </Grid>
                    <Grid item>
                        <CustomIconButton
                            {...getQrcodeEyeRadiusButtonProperties()}
                            keyValue={`eye-frame-shape-gridTopRight`}
                            onClick={(event) => updateEyeRadius(whichEye, true, "gridTopRight")}
                            isFocused={qrCodeTemplate.eyeRadius[index].outer === "gridTopRight"}
                            icon={<EyeFrameRoundedCornerRight {...getQrcodeEyeRadiusButtonIconProperties()} />}
                        />
                    </Grid>
                    <Grid item>
                        <CustomIconButton
                            {...getQrcodeEyeRadiusButtonProperties()}
                            keyValue={`eye-frame-shape-gridBottomLeft`}
                            onClick={(event) => updateEyeRadius(whichEye, true, "gridBottomLeft")}
                            isFocused={qrCodeTemplate.eyeRadius[index].outer === "gridBottomLeft"}
                            icon={<EyeFrameRoundedCornerBottomLeft {...getQrcodeEyeRadiusButtonIconProperties()} />}
                        />
                    </Grid>
                    <Grid item>
                        <CustomIconButton
                            {...getQrcodeEyeRadiusButtonProperties()}
                            keyValue={`eye-frame-shape-gridBottomRight`}
                            onClick={(event) => updateEyeRadius(whichEye, true, "gridBottomRight")}
                            isFocused={qrCodeTemplate.eyeRadius[index].outer === "gridBottomRight"}
                            icon={<EyeFrameRoundedCornerBottomRight {...getQrcodeEyeRadiusButtonIconProperties()} />}
                        />
                    </Grid>
                </Grid>
            </Grid>



            <Grid item xs={12} md={12} sx={{ paddingTop: "10px" }}>
                <InputLabel>{t('qrcode.settings.eyes.eyeBallShape')}</InputLabel>
                <Grid container gap={2}>
                    <Grid item>
                        <CustomIconButton
                            {...getQrcodeEyeRadiusButtonProperties()}
                            keyValue={`eye-ball-shape-square`}
                            onClick={(event) => updateEyeRadius(whichEye, false, "square")}
                            isFocused={qrCodeTemplate.eyeRadius[index].inner === "square"}
                            icon={<EyeBallSquare  {...getQrcodeEyeRadiusButtonIconProperties()} />}
                        />
                    </Grid>
                    {/* <Grid item>
                            <CustomIconButton
                                {...getQrcodeEyeRadiusButtonProperties()}
                                keyValue={`eye-ball-shape-curved`}
                                onClick={(event) => updateEyeRadius(whichEye, false, "curved")}
                                isFocused={qrCodeTemplate.eyeRadius[index].inner === "curved"}
                                icon={<EyeBallCurved  {...getQrcodeEyeRadiusButtonIconProperties()}/>}
                            />
                        </Grid>
                    */}
                    <Grid item>
                        <CustomIconButton
                            {...getQrcodeEyeRadiusButtonProperties()}
                            keyValue={`eye-ball-shape-rounded`}
                            onClick={(event) => updateEyeRadius(whichEye, false, "rounded")}
                            isFocused={qrCodeTemplate.eyeRadius[index].inner === "rounded"}
                            icon={<EyeBallRounded  {...getQrcodeEyeRadiusButtonIconProperties()} />}
                        />
                    </Grid>
                    <Grid item>
                        <CustomIconButton
                            {...getQrcodeEyeRadiusButtonProperties()}
                            keyValue={`eye-ball-shape-circle`}
                            onClick={(event) => updateEyeRadius(whichEye, false, "circle")}
                            isFocused={qrCodeTemplate.eyeRadius[index].inner === "circle"}
                            icon={<EyeBallCircle  {...getQrcodeEyeRadiusButtonIconProperties()} />}
                        />
                    </Grid>
                    <Grid item>
                        <CustomIconButton
                            {...getQrcodeEyeRadiusButtonProperties()}
                            keyValue={`eye-frame-shape-eyeLeft`}
                            onClick={(event) => updateEyeRadius(whichEye, false, "eyeLeft")}
                            isFocused={qrCodeTemplate.eyeRadius[index].inner === "eyeLeft"}
                            icon={<EyeBallDropEye  {...getQrcodeEyeRadiusButtonIconProperties()} />}
                        />
                    </Grid>
                    <Grid item>
                        <CustomIconButton
                            {...getQrcodeEyeRadiusButtonProperties()}
                            keyValue={`eye-frame-shape-eyeRight`}
                            onClick={(event) => updateEyeRadius(whichEye, false, "eyeRight")}
                            isFocused={qrCodeTemplate.eyeRadius[index].inner === "eyeRight"}
                            icon={<EyeBallDropEyeRight  {...getQrcodeEyeRadiusButtonIconProperties()} />}
                        />
                    </Grid>
                    <Grid item>
                        <CustomIconButton
                            {...getQrcodeEyeRadiusButtonProperties()}
                            keyValue={`eye-frame-shape-gridTopLeft`}
                            onClick={(event) => updateEyeRadius(whichEye, false, "gridTopLeft")}
                            isFocused={qrCodeTemplate.eyeRadius[index].inner === "gridTopLeft"}
                            icon={<EyeBallSquareCorner  {...getQrcodeEyeRadiusButtonIconProperties()} />}
                        />
                    </Grid>
                    <Grid item>
                        <CustomIconButton
                            {...getQrcodeEyeRadiusButtonProperties()}
                            keyValue={`eye-frame-shape-gridTopRight`}
                            onClick={(event) => updateEyeRadius(whichEye, false, "gridTopRight")}
                            isFocused={qrCodeTemplate.eyeRadius[index].inner === "gridTopRight"}
                            icon={<EyeBallSquareCornerRight  {...getQrcodeEyeRadiusButtonIconProperties()} />}
                        />
                    </Grid>
                    <Grid item>
                        <CustomIconButton
                            {...getQrcodeEyeRadiusButtonProperties()}
                            keyValue={`eye-frame-shape-gridBottomLeft`}
                            onClick={(event) => updateEyeRadius(whichEye, false, "gridBottomLeft")}
                            isFocused={qrCodeTemplate.eyeRadius[index].inner === "gridBottomLeft"}
                            icon={<EyeBallSquareCornerBottomLeft  {...getQrcodeEyeRadiusButtonIconProperties()} />}
                        />
                    </Grid>
                    <Grid item>
                        <CustomIconButton
                            {...getQrcodeEyeRadiusButtonProperties()}
                            keyValue={`eye-frame-shape-gridBottomRight`}
                            onClick={(event) => updateEyeRadius(whichEye, false, "gridBottomRight")}
                            isFocused={qrCodeTemplate.eyeRadius[index].inner === "gridBottomRight"}
                            icon={<EyeBallSquareCornerBottomRight  {...getQrcodeEyeRadiusButtonIconProperties()} />}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>


    );
}
export default QRCodeEyeRadius;
