import { Button, Grid } from '@mui/material';
import feedbackApi from 'api/feedback';
import CustomBreadcrumbMultiParent from 'components/Breadcrumb/CustomBreadcrumbMultiParent';
import CustomRefreshButton from 'components/Button/CustomRefreshButton';
import CustomDialog from "components/Dialogs/CustomDialog";
import CustomGrid from 'components/Grid/CustomGrid';
import ListPageSkeleton from 'components/Loading/ListPageSkeleton';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';
import CustomPageableTable from "components/Table/CustomPageableTable";
import CustomTitle from 'components/Title/CustomTitle';
import { getDefaultSortDirectionData } from 'constants/sortDirection';
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { mainAreaTable, mainTag, mainTagBreadcrumb, mainTagSearch, mainTagTitle, pageAddButton } from 'themes/defaultThemes';
import { hasRoleAdmin } from 'utils/auth';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';
import { prepareTablePaginationSortParams } from 'utils/functions';
import { getLanguageFromURL } from 'utils/language';
import { actionFormatter, dateFormatter, fieldFormatter, idFormatterLinkWithParent } from "utils/table";
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The FeedbackListComponent, that allow the user to view the organization/outlet feedbackForms list into a table.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function FeedbackListComponent() {
   const { t } = useTranslation();
   const { organizationID } = useParams();
   const { outletID } = useParams();
   const navigate = useNavigate();

   let path = `/${getLanguageFromURL()}`
   if (organizationID !== undefined) path += `/organization/${organizationID}`;
   if (outletID !== undefined) path += `/outlet/${outletID}`;
   path += `/feedback`;

   //change document title
   useDocumentTitle(`Usee  ${organizationID !== undefined ? " | " + t('org.pageTitle') : ""} ${outletID !== undefined ? " | " + t('outlet.pageTitle') : ""} | ${t('feedback.pageTitle')}`);

   /**
    * @type {object}
    * @property {object} userAuth the authenticated user infromation
    * @property {array} printers the printers (pageable)
    * @property {object} organization tha organization basic details (id and name)
    * @property {object} pagination includes all pageable details (page, size, sort, direction)
    */
   const [data, setData] = useState({
      userAuth: null,
      organization: {
         id: organizationID,
         name: ""
      },
      outlet: {
         id: outletID,
         name: ""
      },
      organizations: [],
      outlets: [],
      feedbackForms: [],
      pagination: {
         count: -1,
         size: 15,
         page: 0,
         ...getDefaultSortDirectionData
      }
   });

   // to perform initial request
   const [isFirstTime, setIsFirstTime] = useState(true);
   // if true the menu item is clicked and a redirect to page should perform
   const [isLoaded, setIsLoaded] = useState(false);
   // for search
   const [search, setSearch] = useState({
      organizationID: organizationID,
      outletIDs: outletID ? [outletID] : [],
      name: ""
   });

   // pop dialog for delete
   const [deleteDialog, setDeleteDialog] = useState({
      isOpen: false,
      id: "",
      name: ""
   });

   // snackbar details
   const [alertBox, setAlertBox] = useState({
      isOpen: false,
      message: "",
      backgroundColor: "#a71313"
   });

   useEffect(() => {
      getFeedbackForms();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      getFeedbackForms();
      setIsFirstTime(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      const identifier = setTimeout(() => {
         if (!isFirstTime) getFeedbackForms();
      }, 700);

      return () => {
         clearTimeout(identifier);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [search, data.pagination.size, data.pagination.page, data.pagination.sort, data.pagination.direction])


   /**
    * The rest endpoint to get the feedbackForms list.
    */
   function getFeedbackForms() {
      feedbackApi.fetchAll(prepareTablePaginationSortParams(data.pagination), search).then((r) => {
         setData({
            ...data,
            userAuth: r.data.returnobject.userAuth,
            feedbackForms: r.data.returnobject.feedbackForms,
            organization: r.data.returnobject.organization,
            organizations: r.data.returnobject.organizations,
            outlet: r.data.returnobject.outlet,
            outlets: r.data.returnobject.outlets,
            pagination: {
               ...data.pagination,
               count: r.data.returnobject.feedbackForms.totalPages,
               size: r.data.returnobject.feedbackForms.size,
               page: r.data.returnobject.feedbackForms.number
            }
         });
         setIsLoaded(true);
      }).catch((e) => {
         // console.log(e);
      })
   }

   /**
    * Function that triggers the removal of the selected feedback form.
    * 
    * @property {*} event
    * @property {string} userID The id of the selected user to be removed.
    */
   function deleteFeedbackForm(event, feedbackFormID, name) {
      handleDialogState(true, feedbackFormID, name);
   }

   /**
    * Gets called to remove the selected user
    */
   function deleteFF() {
      feedbackApi.deleteById(deleteDialog.id).then((r) => {
         handleDialogState(false);
         setAlertBox({
            isOpen: true,
            message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
            backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
         });
         getFeedbackForms();
      }).catch((e) => {
         // console.log(e);
      })
   }

   /**
    * Callback that handles the delete dialog isOpen state.
    * 
    * @property {boolean} isOpen The state of the dialog open status.
    * @property {string} feedbackFormID The id of the selected feedback form to be removed.
    * @property {string} name The name of the selected feedback form to be removed.
    */
   function handleDialogState(isOpen, feedbackFormID = "", name = "") {
      setDeleteDialog({
         ...deleteDialog,
         isOpen: isOpen,
         name: name,
         id: feedbackFormID
      });
   }

   /**
    * Function that handles the snackbar open or close data.
    * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
    */
   function handleSnackbarState(isOpen) {
      setAlertBox({
         ...alertBox,
         isOpen: isOpen
      });
   }

   /**
    * Function that handles the pagination information.
    * @property {int} page the page to be redirected
    */
   function handlePaginationChange(page) {
      formValChangeWithParentElementWithNameAndValue("pagination.page", page, data, setData);
   }

   /**
    * Function that handles the pagination information.
    * @property {int} size the size the new numberOfRows to be requested
    */
   function handleRowsPerPageChange(size) {
      let pagination = data.pagination;
      pagination.size = size;
      pagination.page = 0;
      formValChangeWithParentElementWithNameAndValue("pagination", pagination, data, setData);
   }

   /**
    * Function that handles the sort information.
    * @property {int} size the size the new numberOfRows to be requested
    */
   function handleColumnSortChange(changedColumn, direction) {
      let pagination = data.pagination;
      pagination.sort = changedColumn;
      pagination.direction = direction;
      formValChangeWithParentElementWithNameAndValue("pagination", pagination, data, setData);
   }

   // ===============================================================================RENDER
   if (!isLoaded) {
      return <CustomGrid role="main" id="feedback-list" sx={mainTag()}>
         <ListPageSkeleton />
      </CustomGrid>
   } else {
      let breadcrumbs = [];
      if (outletID !== undefined && data.outlet !== null)
         breadcrumbs.push(
            { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
            { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
            { name: t('outlet.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet` },
            { name: data.outlet.name, url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet/${outletID}` },
         );
      else if (organizationID !== undefined && data.organization !== null)
         breadcrumbs.push(
            { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
            { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
         );

      /**
       * datatable columns.
       */
      const table_columns = [
         {
            name: "id",
            label: t('label.id'),
            options: {
               filter: false,
               sort: false,
               empty: false,
               display: data.userAuth !== null && hasRoleAdmin(data.userAuth.roles) ? true : false,
               customBodyRender: (value, tableMeta) => {
                  return idFormatterLinkWithParent(value, tableMeta.tableData[tableMeta.rowIndex], path);
               }
            }
         },
         {
            name: "name",
            label: t('label.name'),
            options: {
               filter: true,
               filterType: 'textField',
               customFilterListRender: v => `${t('label.name')}: ${v}`,
               sort: true,
               empty: false,
               customBodyRender: (value) => {
                  return value?.en;
               }
            }
         },
         {
            name: "attachedOutletIDs",
            label: t('feedback.attachedOutlets'),
            options: {
               filter: false,
               sort: true,
               empty: false,
               customBodyRender: (value) => {
                  return fieldFormatter(value?.length);
               }
            }
         },
         {
            name: "dateCreated",
            label: t('label.dateCreated'),
            options: {
               filter: false,
               sort: true,
               empty: false,
               customBodyRender: (value, tableMeta) => {
                  return dateFormatter(value, tableMeta.tableData[tableMeta.rowIndex]);
               }
            }
         },
         {
            name: "lastActivity",
            label: t('label.lastActivity'),
            options: {
               filter: false,
               sort: true,
               empty: false,
               customBodyRender: (value, tableMeta) => {
                  return dateFormatter(value, tableMeta.tableData[tableMeta.rowIndex]);
               }
            }
         },
         {
            name: "",
            label: t('actions.label'),
            options: {
               filter: false,
               sort: false,
               empty: true,
               customBodyRender: (value, tableMeta) => {
                  return actionFormatter(value,
                     tableMeta.tableData[tableMeta.rowIndex],
                     tableMeta.rowIndex,
                     0,
                     null,
                     null,
                     null,
                     1,
                     path,
                     data.showLabel ? t('actions.edit') : undefined,
                     (event, rowIndex, fullName) => deleteFeedbackForm(event, rowIndex, fullName),
                     data.showLabel ? t('actions.delete') : undefined,
                     path
                  )
               },
               // customHeadRender: (columnMeta, updateDirection) => (
               //    <TableCell style={{ textAlign: 'center', backgroundColor: "#54b1d2", color: "#fff" }}>
               //       {columnMeta.label}
               //    </TableCell>
               // ),
               setCellProps: () => ({ className: "click action" })
            }
         }
      ];

      return (
         <CustomGrid role="main" id="feedback-list" sx={mainTag()}>
            {breadcrumbs.length > 0 &&
               <CustomGrid sx={mainTagBreadcrumb()}>
                  <CustomBreadcrumbMultiParent
                     parents={breadcrumbs}
                     instanceName={t('feedback.pageTitle')}
                  />
               </CustomGrid>
            }

            <Grid container spacing={2} sx={{ alignItems: "center" }}>
               <Grid item xs={"auto"}>
                  <CustomRefreshButton onClick={getFeedbackForms} marginTop="-1%" />
               </Grid>

               <Grid item xs={11} sm={3}>
                  <CustomGrid sx={mainTagTitle()}>
                     <CustomTitle
                        title={`${t('feedback.title')}`}
                        subtitle={""}
                     />
                  </CustomGrid>
               </Grid>

               <Grid item xs={12} sm={8}>
                  <CustomGrid sx={mainTagSearch()} direction="column" container={false} spacing={2}>
                     {/* <CustomTextField
                        type="search"
                        name="name"
                        id="name"
                        variant="filled"
                        label={t('actions.search') + " by " + t('label.name')}
                        defaultValue={search.name}
                        InputProps={{
                           endAdornment: (
                              <InputAdornment position="end" >
                                 <CustomSearchButton onClick={getFeedbackForms} />
                              </InputAdornment>
                           ),
                        }}
                        required={false}
                        sx={mainTagSearchItem()}
                        onChange={(event) => formValChangeWithParentElementSelect(event, search, setSearch, t('table.valueReuired'))}
                     /> */}

                     <Button variant="text"
                        onClick={() => navigate(`${path}/add`)}
                        sx={pageAddButton()}
                     >
                        {t('actions.create') + " " + t('feedback.title')}
                     </Button>
                  </CustomGrid>
               </Grid>
            </Grid>

            <CustomGrid sx={mainAreaTable()} container={false}>
               <CustomPageableTable
                  instances={data.feedbackForms}
                  emptyMessage={t('table.noData') + t('actions.tableCreateNewLink') + t('feedback.title')}
                  table_columns={table_columns}
                  keyField="dateCreated"
                  addPageUrl={`${path}/add`}
                  defaultSorted={{ name: data.pagination.sort, direction: data.pagination.direction }}
                  pagination={data.pagination}
                  handlePaginationChange={handlePaginationChange}
                  handleRowsPerPageChange={handleRowsPerPageChange}
                  handleColumnSortChange={handleColumnSortChange}
                  filter={true}
                  onFilterChange={(event) => (event !== null) ? formValChangeWithParentElementWithNameAndValue(event.field, event.value, search, setSearch) : setSearch({
                     ...search,
                     name: ""
                  })}
               />
            </CustomGrid>

            {deleteDialog.isOpen &&
               <CustomDialog
                  isOpen={deleteDialog.isOpen}
                  id="delete"
                  title={t('actions.deleteModal.title')}
                  actionLabel={t("actions.deleteModal.message")}
                  cancelLabel={t("actions.cancel")}
                  message={`${t('actions.deleteModal.notice')} ${t('actions.deleteModal.confirm')} ${t('feedback.pageTitle')}: `}
                  boldMessage={<span style={{ fontWeight: "bold" }}>{deleteDialog.name}</span>}
                  action={deleteFF}
                  handleOpen={handleDialogState}
                  actionColor="#e91e1e"
               />
            }

            {alertBox.isOpen &&
               <CustomSnackbar
                  isOpen={alertBox.isOpen}
                  autoHideDuration={3000}
                  message={alertBox.message}
                  backgroundColor={alertBox.backgroundColor}
                  handleSnackbarOpen={handleSnackbarState} />
            }
         </CustomGrid>
      );
   }
}

export default FeedbackListComponent;