import { PhotoSizeSelectActual } from "@mui/icons-material";
import { FontAwesomeIcon, faSprayCanSparkles } from "assets/fontAwesome/FontAwesomeIcons";
import {
    AllOutIcon,
    PaymentIcon,
    ShoppingCartIcon
} from 'assets/mui/MuiIcons';
import { getOrderButtonIcon } from "themes/defaultThemes";

export function getRequestFilterTypeIcon(type) {
    if (type === "ALL") {
        return <AllOutIcon {...getOrderButtonIcon()} />
    } else if (type === "PAYMENT") {
        return <PaymentIcon {...getOrderButtonIcon()} />
    } else if (type === "CLEAN_TABLE") {
        return <FontAwesomeIcon icon={faSprayCanSparkles} {...getOrderButtonIcon()} />
    } else if (type === "ORDER") {
        return <ShoppingCartIcon {...getOrderButtonIcon()} />
    } else if (type === "OTHER") {
        return <PaymentIcon {...getOrderButtonIcon()} />
    } else if (type === "CUSTOM") {
        return <PhotoSizeSelectActual {...getOrderButtonIcon()} />
    }
    return null;
}
