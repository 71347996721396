import AnalyticsIcon from '@mui/icons-material/Analytics';
import ArticleIcon from '@mui/icons-material/Article';
import BusinessIcon from '@mui/icons-material/Business';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import GroupsIcon from '@mui/icons-material/Groups';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import QuizIcon from '@mui/icons-material/Quiz';
import ReviewsIcon from '@mui/icons-material/Reviews';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import StoreIcon from '@mui/icons-material/Store';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import { getLanguageFromURL } from '../utils/language';

export const getHelpMenuItems = () => {
    return [
        {
            label: "analytics",
            sublabel: "analytics",
            imgSrc: <AnalyticsIcon />,
            link: "/" + getLanguageFromURL() + "/help/analytics",
            action: false
        },
        {
            label: "organization",
            sublabel: "organization",
            imgSrc: <BusinessIcon />,
            link: "/" + getLanguageFromURL() + "/help/organization",
            action: false
        },
        {
            label: "outlet",
            sublabel: "outlet",
            imgSrc: <StoreIcon />,
            link: "/" + getLanguageFromURL() + "/help/outlet",
            action: false
        },
        {
            label: "menu",
            sublabel: "menu",
            imgSrc: <ArticleIcon />,
            link: "/" + getLanguageFromURL() + "/help/menu",
            action: false,
            children: [
                {
                    label: "itemsAndModifier",
                    imgSrc: <FeaturedPlayListIcon />,
                    link: "/" + getLanguageFromURL() + "/help/items-and-modifier",
                    action: false
                },
            ]
        },
        {
            label: "equipment",
            sublabel: "equipment",
            imgSrc: <TableRestaurantIcon />,
            link: "/" + getLanguageFromURL() + "/help/equipment",
            action: false
        },
        {
            label: "order",
            sublabel: "order",
            imgSrc: <ShoppingBasketIcon />,
            link: "/" + getLanguageFromURL() + "/help/order",
            action: false
        },
        {
            label: "feedback",
            sublabel: "feedback",
            imgSrc: <ReviewsIcon />,
            link: "/" + getLanguageFromURL() + "/help/feedback",
            action: false
        },
        {
            label: "loyalty",
            sublabel: "loyalty",
            imgSrc: <LoyaltyIcon />,
            link: "/" + getLanguageFromURL() + "/help/loyalty",
            action: false
        },
        {
            label: "user",
            sublabel: "user",
            imgSrc: <GroupsIcon />,
            action: false
        },
        {
            label: "faq",
            sublabel: "faq",
            imgSrc: <QuizIcon />,
            action: true
        }
    ];
}