/**
 * Updates an object field with a value if the value is valid.
 *
 * @param {object} data - The object to update.
 * @param {string} fieldName - The field name to update.
 * @param {any} value - The value to assign to the field.
 * @param {string} [extraNestedField] - An optional nested field to check within the value.
 * @returns {object|boolean} - The updated data object or false if the nested field is invalid.
 *
 * @example
 * const obj = { name: "John" };
 * updateFieldIfValid(obj, "age", 30); // { name: "John", age: 30 }
 * updateFieldIfValid(obj, "age", null); // { name: "John" }
 * updateFieldIfValid(obj, "profile", { city: "New York" }, "city"); // { name: "John", profile: { city: "New York" } }
 * updateFieldIfValid(obj, "profile", { city: "" }, "city"); // false
 *
 * @version 1.0.2
 * @author [Gina Chatzimarkaki]
 */
const updateFieldIfValid = (data, fieldName, value, extraNestedField) => {
    // Check if the value is valid
    const isValidValue = (val) => val !== null && val !== "" && val !== undefined;

    if (extraNestedField === undefined) {
        // If extraNestedField is not provided, treat value as a simple type
        if (isValidValue(value)) {
            data[fieldName] = value;
        }
    } else {
        // If extraNestedField is provided, treat value as a nested object
        if (value && typeof value === 'object' && isValidValue(value[extraNestedField])) {
            data[fieldName] = value;
        } else {
            return false;
        }
    }

    return data; // Return the updated object
};

export default updateFieldIfValid;
