import { Add as AddIcon, SyncAlt } from '@mui/icons-material';
import { Fab, Grid, InputAdornment } from '@mui/material';
import { Stack } from '@mui/system';
import posApi from "api/pos";
import CustomBreadcrumbMultiParent from "components/Breadcrumb/CustomBreadcrumbMultiParent";
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomRefreshButton from 'components/Button/CustomRefreshButton';
import CustomSearchButton from 'components/Button/CustomSearchButton';
import CustomAddPosCard from 'components/Card/pos/CustomAddPosCard';
import CustomPosCard from 'components/Card/pos/CustomPosCard';
import CustomDialog from 'components/Dialogs/CustomDialog';
import AddEditPosDialog from 'components/Dialogs/pos/AddEditPosDialog';
import CustomGrid from 'components/Grid/CustomGrid';
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomObjectSelect from 'components/Select/CustomObjectSelect';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';
import CustomTextField from 'components/Text/CustomTextField';
import CustomTitle from "components/Title/CustomTitle";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { fabStyle, mainArea, mainAreaBody, mainTag, mainTagBreadcrumb, mainTagSearch, mainTagSearchItem, mainTagSearchObjectSelect, mainTagTitle } from "themes/defaultThemes";
import { hasRoleAdmin } from 'utils/auth';
import { formValChangeWithParentElementSelect } from 'utils/form-validation';
import { getLanguageFromURL } from "utils/language";
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The Pos List component to view the pos devices of the Organization/Outlet.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function PosListComponent() {
    const { organizationID } = useParams();
    const { outletID } = useParams();
    const { t } = useTranslation();

    //change document title
    useDocumentTitle(`Usee  ${organizationID !== undefined ? " | " + t('org.pageTitle') : ""} ${outletID !== undefined ? " | " + t('outlet.pageTitle') : ""} | ${t('pos.title')}`);
    /* eslint-disable no-unused-vars */
    let path = `/${getLanguageFromURL()}`
    if (organizationID !== undefined) path += `/organization/${organizationID}`;
    if (outletID !== undefined) path += `/outlet/${outletID}`;
    path += `/pos`;
    /* eslint-enable no-unused-vars */

    /**
     * @type {object}
     * @property {object} userAuth the authenticated user information
     * @property {array} outletPoses the POS devices details (pageable)
     * @property {object} organization tha organization basic details (id and name)
     * @property {object} pagination includes all pageable details (page, size, sort, direction)
     */
    const [data, setData] = useState({
        userAuth: null,
        organization: {
            id: organizationID,
            name: ""
        },
        outlet: {
            id: outletID,
            name: ""
        },
        organizations: [],
        outlets: [],
        outletPoses: [],
        providers: [],
        types: []
    });

    // if true the menu item is clicked and a redirect to page should perform
    const [isLoaded, setIsLoaded] = useState(false);
    // for search
    const [search, setSearch] = useState({
        organizationID: organizationID,
        outletID: outletID,
        field: "friendlyName",
        terminalId: "",
        friendlyName: "",
        provider: [],
        type: []
    });

    // pop dialog for delete
    const [deleteDialog, setDeleteDialog] = useState({
        isOpen: false,
        posID: "",
        friendlyName: ""
    });
    // pop dialog for add/edit
    const [dialog, setDialog] = useState({
        isOpen: false,
        isAdd: true,
        posID: "",
        friendlyName: ""
    });
    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313"
    });

    useEffect(() => {
        getPoses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);


    /**
     * The rest endpoint to get the POS devices list.
     */
    function getPoses() {
        posApi.fetchAll(search).then((r) => {
            Promise.resolve()
                .then(() => {
                    setData({
                        ...data,
                        userAuth: r.data.returnobject.userAuth,
                        organization: r.data.returnobject.organization,
                        organizations: r.data.returnobject.organizations,
                        outlet: r.data.returnobject.outlet,
                        outlets: r.data.returnobject.outlets,
                        outletPoses: r.data.returnobject.outletPoses,
                        providers: r.data.returnobject.providers,
                        types: r.data.returnobject.types,
                    });
                })
                .then(() => {
                    let organizationID = r.data.returnobject.organization !== null ? r.data.returnobject.organization.id : null;
                    let outletID = r.data.returnobject.outlet !== null ? r.data.returnobject.outlet.id : null;
                    if ((search.organizationID !== null && hasRoleAdmin(r.data.returnobject.userAuth.roles)) || !hasRoleAdmin(r.data.returnobject.userAuth.roles))
                        if (organizationID !== search.organizationID || (outletID !== search.outletID))
                            setSearch({ ...search, organizationID: organizationID, outletID: outletID !== null ? outletID : (r.data.returnobject.outlets.size === 1) ? r.data.returnobject.outlets.outlets[0].id : null });
                })
                .then(() => {
                    if (r.data.returnobject.outlet !== null)
                        setIsLoaded(true);
                })
        }).catch((e) => {
            // console.log(e);
        })
    }

    /**
     * The rest endpoint to sync the POS devices list.
     */
    function syncPoses() {
        posApi.syncDevices().then((r) => {
            Promise.resolve()
                .then(() => {
                    getPoses();
                })
        }).catch((e) => {
            // console.log(e);
        })
    }

    /**
     * Function that triggers the creation of a POS device
     */
    function addOutletPos(outletPos) {
        outletPos.organizationID = search.organizationID;
        outletPos.outletID = search.outletID;

        posApi.create(outletPos).then((r) => {
            Promise.resolve()
                .then(() => {
                    getPoses();
                })
                .then(() => {
                    setAlertBox({
                        isOpen: true,
                        message: r.data.message,
                        backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                    });
                })
                .then(() => {
                    setDialog({
                        isOpen: false,
                        posID: "",
                        friendlyName: "",
                        isAdd: true//add or 1 for edit
                    });
                })

        }).catch((e) => {
            // console.log(e)
        });
    }

    /**
     * Function that triggers the modification of a POS device
     */
    function updateOutletPos(outletPos) {
        posApi.update(outletPos).then((r) => {
            Promise.resolve()
                .then(() => {
                    getPoses();
                })
                .then(() => {
                    setAlertBox({
                        isOpen: true,
                        message: r.data.message,
                        backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                    });
                })
                .then(() => {
                    setDialog({
                        isOpen: false,
                        posID: "",
                        friendlyName: "",
                        isAdd: true//add or 1 for edit
                    });
                })
        }).catch((e) => {
            // console.log(e)
        });
    }

    /**
     * Function that handles the delete modal open or close data.
     * @property {boolean} isOpen The state of the dialog open status.
     * @property {string} posID The id of the selected pos device to be removed.
     */
    function handleDialogState(isOpen, posID = "", friendlyName = "", isAdd = true) {
        setDialog({
            ...dialog,
            isOpen: isOpen,
            posID: posID,
            friendlyName: friendlyName,
            isAdd: isAdd
        });
    }
    /**
     * Function that handles the delete modal open or close data.
     * 
     * @property {boolean} isOpen The state of the dialog open status.
     * @property {string} posID The id of the selected pos device to be removed.
     * @property {string} friendlyName The friendly name of the selected pos device to be removed.
     */
    function handleDeleteDialogState(isOpen, posID = "", friendlyName = "") {
        setDeleteDialog({
            ...deleteDialog,
            isOpen: isOpen,
            posID: posID,
            friendlyName: friendlyName
        });
    }

    /**
     * Gets called to remove the selected pos device
     */
    function deleteP() {
        posApi.deleteById(deleteDialog.posID).then((r) => {
            handleDeleteDialogState(false);
            setAlertBox({
                isOpen: true,
                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
            getPoses();
        }).catch((e) => {
            // console.log(e);
        })
    }

    /**
     * 
     * Function that triggers the removal of the selected pos device.
     * @property {*} event
     * @property {string} posID The id of the selected pos device to be removed.
     */
    function deleteOutletPos(event, posID, printNodeId = "", friendlyName = "") {
        handleDeleteDialogState(true, posID, printNodeId, friendlyName);
    }

    /**
     * Function that triggers the multi select input value change.
     * @param {*} event 
     *//* eslint-disable no-unused-vars */
    function onSelectSearchChange(event) {
        let field = event.target.value;
        setSearch({
            ...search,
            field: field,
            terminalId: "",
            friendlyName: "",
            provider: [],
            type: [],
            [field]: ""
        });
    }/* eslint-enable no-unused-vars */

    /**
     * Function that handles the snackbar open or close data.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }


    // ===============================================================================RENDER

    if (!isLoaded) {
        return <CustomGrid role="main" id="pos-list" sx={mainTag()}>
            <LoadingSkeleton lines={9} />
        </CustomGrid>
    } else {
        let breadcrumbs = [];
        if (outletID !== undefined && data.outlet !== null)
            breadcrumbs.push(
                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                { name: t('outlet.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet` },
                { name: data.outlet.name, url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet/${outletID}` },
                // { name: t('pos.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet/${outletID}/pos` }
            );
        else if (organizationID !== undefined && data.organization !== null)
            breadcrumbs.push(
                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                // { name: t('pos.pageTitle'), url: `/${getLanguageFromURL()}/organization/${outletID}/pos` }
            );

        return (
            <CustomGrid container direction="row" id="printers-list" sx={mainTag()}>
                {/* SELECT FOR OUTLET ORGANIZATION id  */}
                {(organizationID === undefined && search.organizationID === undefined && hasRoleAdmin(data.userAuth.roles)) &&
                    <>
                        <CustomObjectSelect
                            name="organizationID"
                            required={true}
                            defaultValue={search.organizationID}
                            labelID="organizationID" label={t('org.pageTitle')}
                            options={[{ id: "", name: "all" }, ...data.organizations]}
                            onChange={(event) => setSearch({ ...search, organizationID: event.target.value })}
                            sx={{ ...mainTagSearchItem(), ...mainTagSearchObjectSelect() }}
                            field="name"
                        />

                        {data.outlets !== null && data.outlets.length > 0 &&
                            <Grid item xs={12} sm={2}>
                                <CustomObjectSelect
                                    name="outletID"
                                    required={true}
                                    isMultiple={false}
                                    defaultValue={search.outletID}
                                    labelID="organizationID" label={t('outlet.pageTitle')}
                                    options={data.outlets}
                                    onChange={(event) => setSearch({ ...search, outletID: outletID })}
                                    sx={mainTagSearchItem()}
                                    field="name"
                                />
                            </Grid>
                        }
                    </>
                }

                {/* BREADCRUMB, TITLE, SEARCH */}
                {(search.organizationID !== null && search.organizationID !== undefined) &&
                    <>
                        {breadcrumbs.length > 0 &&
                            <CustomGrid sx={mainTagBreadcrumb()}>
                                <CustomBreadcrumbMultiParent
                                    parents={breadcrumbs}
                                    instanceName={t('pos.pageTitle')}
                                />
                            </CustomGrid>
                        }
                        <Grid container spacing={2} sx={{ alignItems: "center" }}>
                            <Grid item xs={"auto"}>
                                <CustomRefreshButton onClick={getPoses} marginTop="-1%" />
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <CustomGrid sx={mainTagTitle()}>
                                    <CustomTitle
                                        title={t('pos.title')}
                                        subtitle={t('pos.subtitle')}
                                    />
                                </CustomGrid>
                                {/*TODO: on mobile button filter search -> oepn swippable drawer */}
                            </Grid>

                            <Grid item xs={12} sm={8}>
                                <Stack direction="row" sx={mainTagSearch()}>
                                    <CustomGrid sx={mainTagSearch()} direction="column" container={false} spacing={2}>
                                        {(hasRoleAdmin(data.userAuth.roles) && organizationID === undefined) &&
                                            <Grid item xs={12} sm={2}>
                                                <CustomObjectSelect
                                                    name="organizationID"
                                                    required={true}
                                                    defaultValue={search.organizationID}
                                                    labelID="organizationID" label={t('org.pageTitle')}
                                                    options={[{ id: "", name: "all" }, ...data.organizations]}
                                                    onChange={(event) => setSearch({ ...search, organizationID: event.target.value })}
                                                    sx={{ ...mainTagSearchItem(), ...mainTagSearchObjectSelect() }}
                                                    field="name"
                                                />
                                            </Grid>
                                        }

                                        {search.organizationID !== null && data.outlets !== null && data.outlets.size > 1 && organizationID === undefined &&
                                            <Grid item xs={12} sm={2}>
                                                <CustomObjectSelect
                                                    name="outletID"
                                                    required={true}
                                                    isMultiple={true}
                                                    defaultValue={search.outletIDs}
                                                    labelID="organizationID" label={t('outlet.pageTitle')}
                                                    options={data.outlets}
                                                    onChange={(event) => setSearch({ ...search, outletIDs: [event.target.value] })}
                                                    sx={mainTagSearchItem()}
                                                    field="name"
                                                />
                                            </Grid>
                                        }

                                        {/* <Grid item xs={12} sm={1}> */}
                                        <CustomIconButton
                                            tooltipTitle={t('pos.syncTooltip')}
                                            keyValue={`btn-sync`}
                                            onClick={() => syncPoses()}
                                            icon={<SyncAlt />}
                                            iconSx={{
                                                ":hover": {
                                                    color: "#2596be"
                                                }
                                            }}
                                            sx={{
                                                margin: "0px",
                                                fontSize: "8px",
                                                fontWeight: "900",
                                                color: "#2596be",
                                                mr: "20px",
                                                ":hover": {
                                                    backgroundColor: "#000"
                                                }
                                            }}
                                            variant='outlined'
                                            backgroundColor="#dcf1f7"
                                        />
                                        {/* </Grid> */}

                                        {/* <Grid item xs={12} sm={6}> */}
                                        <CustomTextField
                                            type="search"
                                            name={search.field}
                                            id={search.field}
                                            variant="filled"
                                            label={t('actions.search') + " by " + t(`pos.${search.field}`)}
                                            defaultValue={search[search.field] !== "" ? search[search.field] : ""}
                                            value={search[search.field] !== "" ? search[search.field] : ""}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end" >
                                                        <CustomSearchButton onClick={getPoses} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            required={false}
                                            sx={mainTagSearchItem()}
                                            onChange={(event) => { formValChangeWithParentElementSelect(event, search, setSearch, t('table.valueReuired')) }}
                                        />
                                        {/* </Grid> */}
                                    </CustomGrid>
                                </Stack>
                            </Grid>
                        </Grid>

                        <CustomGrid sx={mainArea()} container={false}>
                            {data.outletPoses.length === 0 &&
                                <CustomGrid sx={mainAreaBody()} container={false}>
                                    {`${t('pos.noPoses')} ${t('actions.tableCreateNewLink')} ${t('pos.title')} `}
                                </CustomGrid>
                            }

                            {data.outletPoses.length > 0 &&
                                <Stack direction="row" sx={{ padding: "10px", marginRight: "50px", alignItems: "center" }}>
                                    <CustomGrid container={true} gap={2} width={200}>
                                        {data.outletPoses !== null && data.outletPoses.map((outletPos) => (
                                            <CustomPosCard
                                                outletPos={outletPos}
                                                deleteOutletPos={deleteOutletPos}
                                                updateOutletPos={(updatedPOS) => handleDialogState(true, updatedPOS.id, updatedPOS.friendlyName, false)}
                                                onDialog={false}
                                            />
                                        ))}
                                        <CustomAddPosCard onClick={() => handleDialogState(true)} />
                                    </CustomGrid>
                                </Stack>
                            }
                        </CustomGrid>

                        <Fab color="primary"
                            aria-label="add"
                            sx={fabStyle()}
                            onClick={() => handleDialogState(true)}
                        >
                            <AddIcon />
                        </Fab>
                    </>
                }

                {alertBox.isOpen &&
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={3000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        handleSnackbarOpen={handleSnackbarState} />
                }

                {dialog.isOpen &&
                    <AddEditPosDialog
                        existingPos={!dialog.isAdd ? data.outletPoses?.find(outletPos => outletPos.id === dialog.posID) : undefined}
                        isOpen={dialog.isOpen}
                        isAdd={dialog.isAdd}
                        handleOpen={handleDialogState}
                        addOutletPos={addOutletPos}
                        updateOutletPos={updateOutletPos}
                        typeOptions={data.types}
                        providerOptions={data.providers}
                        search={search}
                    />
                }

                {deleteDialog.isOpen &&
                    <CustomDialog
                        isOpen={deleteDialog.isOpen}
                        id="delete"
                        title={t('actions.deleteModal.title')}
                        actionLabel={t("actions.deleteModal.message")}
                        cancelLabel={t("actions.cancel")}
                        message={`${t('actions.deleteModal.notice')} ${t('actions.deleteModal.confirm')} ${t('pos.title')}: `}
                        boldMessage={<span style={{ fontWeight: "bold" }}>
                            {deleteDialog.friendlyName !== "" ? `"${deleteDialog.friendlyName}"` : ""
                                `with id "${deleteDialog.posID}"`}
                        </span>}
                        action={deleteP}
                        handleOpen={handleDeleteDialogState}
                        actionColor="#e91e1e"
                    />
                }
            </CustomGrid>
        );
    }
}

export default PosListComponent;