import { request } from 'constants/alias';
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from 'utils/functions';
import updateFieldIfValid from 'utils/functions/updateFieldIfValid';

/**
 * The rest request which attempts to get the recipients list from the database.
 * @param {object} pageable includes all pageable details (page, size, sort, direction)
 * @param {*} search The filters for search.
 * 
 * @returns an object with an attribute containing the recipients list
 */
export async function fetchAll(pageable, search) {
    let data = {};
    updateFieldIfValid(data, "organizationID", search.organizationID);
    updateFieldIfValid(data, "outletID", search.outletID);
    updateFieldIfValid(data, "name", search.name);
    updateFieldIfValid(data, "phone", search.phone);
    updateFieldIfValid(data, "email", search.email);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}marketing/recipient`, pageable !== undefined ? { ...pageable, ...data } : data));
    else return await request.get(includeParamsToUrl(`${API}marketing/recipient`, pageable !== undefined ? pageable : {}));
}

export async function fetchAllList(search) {
    let data = {};
    updateFieldIfValid(data, "organizationID", search.organizationID);
    updateFieldIfValid(data, "outletID", search.outletID);
    updateFieldIfValid(data, "name", search.name);
    updateFieldIfValid(data, "phone", search.phone);
    updateFieldIfValid(data, "email", search.email);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}marketing/recipient`, data));
    else return await request.get(includeParamsToUrl(`${API}marketing/recipient`));
}

/**
 * The rest request which return required information to be presented on add new recipient page.
 * @returns an object with an attribute containing the requested information to be presented on the recipient page
 */
export async function fetchNew(organizationID, outletID) {
    let data = {};
    updateFieldIfValid(data, "organizationID", organizationID);
    updateFieldIfValid(data, "outletID", outletID);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}marketing/recipient/add`, data));
    else return await request.get(includeParamsToUrl(`${API}marketing/recipient/add`));
}

/**
 * The rest request which attempts to get an existing recipient information.
 * @param {*} recipientID The id of the recipient to be fetched
 * @returns an object with an attribute containing the current infromation of the recipient
 */
export async function fetchOne(organizationID, outletID, recipientID) {
    let data = {};
    updateFieldIfValid(data, "organizationID", organizationID);
    updateFieldIfValid(data, "outletID", outletID);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}marketing/recipient/${recipientID}`, data));
    else return await request.get(includeParamsToUrl(`${API}marketing/recipient/${recipientID}`));
}

/**
 * The rest request which attempts to create a new recipient.
 * @param {*} data A JSON object which contains new recipient infromation
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function create(data) {
    return await request.post(`${API}marketing/recipient`, data);
}

/**
 * The rest request which attempts to update an existing recipient.
 * @param {*} data A JSON object which contains the updated infromation of the recipient
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function update(data) {
    return await request.put(`${API}marketing/recipient`, data);
}

/**
 * The rest request to delete a specific recipient.
 * @param {*} id The id of the recipient to be deleted
 * > Note: Can only be used for non-real accounts that have not be used yet anywhere
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function deleteById(id) {
    return await request.delete(`${API}marketing/recipient/${id}`);
}

/**
 * The rest request which attempts to get the recipients list to be imported from json.
 * 
 * @returns an object with an attribute containing the recipients list in json format
 */
export async function importCsv(organizationID, recipients) {
    let data = {
        organizationID: organizationID,
        recipients: recipients
    };

    return await request.post(`${API}marketing/recipient/import/csv`, data);
}

/**
 * The rest request to query a specific recipients list.
 * @param {*} query The query of the recipients
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function fetchQuery(query) {
    let data = {
        query: query
    };
    return await request.post(`${API}marketing/recipient/query`, data);
}

const recipientApi = {
    fetchAll,
    fetchAllList,
    fetchNew,
    fetchOne,
    create,
    update,
    deleteById,
    importCsv,
    fetchQuery
}

export default recipientApi;