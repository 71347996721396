import { request } from 'constants/alias';
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from 'utils/functions';
import updateFieldIfValid from 'utils/functions/updateFieldIfValid';

/**
 * The rest request which attempts to get the section forms list from the database.
 * 
 * @param {object} pageable includes all pageable details (page, size, sort, direction)
 * @param {*} search The filters for search.
 * 
 * @returns an object with an attribute containing the section forms list
 */
export async function fetchAll(pageable, search) {
    let data = {};
    updateFieldIfValid(data, "organizationID", search.organizationID);
    updateFieldIfValid(data, "outletID", search.outletID);
    updateFieldIfValid(data, "name", search.name);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}section`, pageable !== undefined ? { ...pageable, ...data } : data));
    else return await request.get(includeParamsToUrl(`${API}section`, pageable !== undefined ? pageable : {}));
}

/**
 * The rest request which return required information to be presented on add new section form page.
 * 
 * @returns an object with an attribute containing the requested information to be presented on    section form page
 */
export async function fetchNew(organizationID, outletID) {
    let data = {};
    updateFieldIfValid(data, "organizationID", organizationID);
    updateFieldIfValid(data, "outletID", outletID);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}section/add`, data));
    else return await request.get(includeParamsToUrl(`${API}section/add`));
}

/**
 * The rest request which attempts to get an existing section form information.
 * 
 * @param {*} organizationID The id of the section form form organization id
 * @param {*} sectionID The id of the section form to be fetched
 * 
 * @returns an object with an attribute containing the current infromation of the section
 */
export async function fetchOne(organizationID, outletID, sectionID) {
    let data = {};
    updateFieldIfValid(data, "organizationID", organizationID);
    updateFieldIfValid(data, "outletID", outletID);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}section/${sectionID}`, data));
    else return await request.get(includeParamsToUrl(`${API}section/${sectionID}`));
}

/**
 * The rest request which attempts to create a new section form.
 * 
 * @param {*} data A JSON object which contains new section form infromation
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function create(data) {
    return await request.post(`${API}section`, data);
}

/**
 * The rest request which attempts to update an existing section form.
 * 
 * @param {*} data A JSON object which contains the updated infromation of the section form
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function update(data) {
    return await request.put(`${API}section`, data);
}

/**
 * The rest request which attempts to update an existing section form.
 * 
 * @param {*} data A JSON object which contains the updated infromation of the section form
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function updateMySections(data) {
    return await request.put(`${API}user/section`, data);
}

/**
 * The rest request which attempts to update an existing section name.
 * 
 * @param {*} name A JSON object which contains the updated name translations
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function updateName(id, name) {
    let data = {};
    updateFieldIfValid(data, "name", name);

    return await request.put(`${API}section/${id}/name`, data);
}


/**
 * The rest request to delete a specific section form.
 * @param {*} id The id of the section form to be deleted
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function deleteById(id) {
    return await request.delete(`${API}section/${id}`);
}

/**
 * The rest request which attempts to enable an existing section.
 * @param {*} sectionID The id of the section to be enabled
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function activate(sectionID) {
    return await request.post(`${API}section/${sectionID}/activate`);
}

/**
* The rest request which attempts to disable an existing section.
* @param {*} sectionID The id of the section to be disabled
* 
* @returns an object refering to the success or failure of the request
*/
export async function disable(sectionID) {
    return await request.post(`${API}section/${sectionID}/disable`);
}

const sectionApi = {
    fetchAll,
    fetchNew,
    fetchOne,
    create,
    update,
    updateMySections,
    updateName,
    deleteById,
    activate,
    disable
}

export default sectionApi;