import { Fab, Grid, Tooltip } from '@mui/material';
import recipientGroupApi from "api/recipientGroup";
import { AddIcon } from 'assets/svg/SvgIcons';
import CustomBreadcrumbMultiParent from 'components/Breadcrumb/CustomBreadcrumbMultiParent';
import CustomRefreshButton from 'components/Button/CustomRefreshButton';
import CustomDialog from "components/Dialogs/CustomDialog";
import CustomGrid from 'components/Grid/CustomGrid';
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';
import CustomPageableTable from 'components/Table/CustomPageableTable';
import CustomTitle from 'components/Title/CustomTitle';
import { getDateFormatter } from 'constants/calendar';
import { getDefaultSortDirection } from 'constants/sortDirection';
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { fabStyle, fabStyleIcon, fabTooltipStyle, mainAreaTable, mainTag, mainTagBreadcrumb, mainTagTitle } from 'themes/defaultThemes';
import { hasRoleAdmin } from 'utils/auth';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';
import { prepareTablePaginationSortParams } from 'utils/functions';
import { getLanguageFromURL } from 'utils/language';
import { actionFormatter, dateFormatter, idFormatterLinkWithParent } from "utils/table";
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The RecipientGroupListComponent, that allow the recipient to view the recipient groups list into a table.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function RecipientGroupListComponent() {
    const { t } = useTranslation();
    const { organizationID } = useParams();
    const { outletID } = useParams();
    const navigate = useNavigate();
    let path = `/${getLanguageFromURL()}`
    if (organizationID !== undefined) path += `/organization/${organizationID}`;
    if (outletID !== undefined) path += `/outlet/${outletID}`;
    path += `/marketing/recipient/group`;

    //change document title
    useDocumentTitle(`Usee ${organizationID !== undefined ? " | " + t('org.pageTitle') : ""} ${outletID !== undefined ? " | " + t('outlet.pageTitle') : ""} | ${t('marketing.recipient.group.pageTitle')}`);

    /**
     * @type {object}
     * 
    * @property {object} userAuth the authenticated recipient infromation
    * @property {array} recipientGroups the recipientGroups list
    * @property {object} outlet tha outlet basic details (id and name, isActive)
    * @property {object} organization tha organization basic details (id and name)
    * @property {object} pagination includes all pageable details (page, size, sort, direction)
     */
    const [data, setData] = useState({
        userAuth: null,
        recipientGroups: [],
        showLabel: false,
        organization: {
            id: organizationID,
            name: ""
        },
        outlet: {
            id: outletID,
            name: ""
        },
        pagination: {
            count: -1,
            size: 10,
            page: 0,
            ...getDefaultSortDirection
        }
    });

    // to perform initial request
    const [isFirstTime, setIsFirstTime] = useState(true);
    // if true the the content is loaded
    const [isLoaded, setIsLoaded] = useState(false);
    // for search
    const [search, setSearch] = useState({
        organizationID: organizationID,
        outletID: outletID !== undefined ? outletID : undefined,
        name: "",
        email: "",
        phone: ""
    });

    // pop dialog for delete
    const [dialog, setDialog] = useState({
        isOpen: false,
        recipientID: "",
        fullName: ""
    });
    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313"
    });

    useEffect(() => {
        getRecipientGroups();
        setIsFirstTime(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const identifier = setTimeout(() => {
            if (!isFirstTime) getRecipientGroups();
        }, 700);

        return () => {
            clearTimeout(identifier);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.pagination.size, data.pagination.page, data.pagination.sort, data.pagination.direction])

    useEffect(() => {
        getRecipientGroups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])

    /**
     * The rest endpoint to get the recipient groups list.
     * @property {number} page the list page number
     */
    function getRecipientGroups(page) {
        recipientGroupApi.fetchAll(prepareTablePaginationSortParams(data.pagination), search).then((r) => {
            setData({
                ...data,
                recipientGroups: r.data.returnobject.recipientGroups,
                userAuth: r.data.returnobject.userAuth,
                organization: r.data.returnobject.organization,
                outlet: r.data.returnobject.outlet,
                pagination: {
                    ...data.pagination,
                    count: r.data.returnobject.recipientGroups.totalPages,
                    size: r.data.returnobject.recipientGroups.size,
                    page: page !== undefined ? page : r.data.returnobject.recipientGroups.number
                }
            });
            setIsLoaded(true);
        }).catch((e) => {
            // console.log(e);
        })
    }

    /**
     * 
     * Function that triggers the removal of the selected recipient.
     * @property {*} event
     * @property {string} recipientID The id of the selected recipient to be removed.
     */
    function deleteRecipient(event, recipientID, fullName) {
        handleDialogState(true, recipientID, fullName);
    }

    /**
     * Function that handles the delete modal open or close state.
     * @property {boolean} isOpen The state of the dialog open status.
     * @property {string} recipientID The id of the selected recipient to be removed.
     */
    function handleDialogState(isOpen, recipientID = "", fullName = "") {
        setDialog({
            ...dialog,
            isOpen: isOpen,
            recipientID: recipientID,
            fullName: fullName
        });
    }

    /**
     * Gets called to remove the selected recipient
     */
    function deleteR() {
        recipientGroupApi.deleteById(dialog.recipientID).then((r) => {
            handleDialogState(false);
            setAlertBox({
                isOpen: true,
                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
            // setRedirect((r.data.code === "SUCCESS" ? true : false));
            getRecipientGroups();
        }).catch((e) => {
            // console.log(e);
        })
    }

    /**
     * Function that handles the snackbar open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }

    /**
     * Function that handles the pagination information.
     * @property {*} event
     * @property {int} page the page to be redirected
     */
    function handlePaginationChange(page) {
        formValChangeWithParentElementWithNameAndValue("pagination.page", page, data, setData);
    }

    /**
     * Function that handles the pagination information.
     * @property {*} event
     * @property {int} size the size the new numberOfRows to be requested
     */
    function handleRowsPerPageChange(size) {
        formValChangeWithParentElementWithNameAndValue("pagination.size", size, data, setData);
    }
    /**
     * Function that handles the sort information.
     * @property {*} event
     * @property {int} size the size the new numberOfRows to be requested
     */
    function handleColumnSortChange(changedColumn, direction) {
        let pagination = data.pagination;
        pagination.sort = changedColumn;
        pagination.direction = direction;
        formValChangeWithParentElementWithNameAndValue("pagination", pagination, data, setData);
    }


    // ===============================================================================RENDER

    if (!isLoaded) {
        return <LoadingSkeleton lines={9} />;
    } else {
        /**
         * datatable columns.
         */
        const table_columns = [
            {
                name: "id",
                label: t('label.id'),
                options: {
                    filter: false,
                    sort: false,
                    empty: false,
                    display: data.userAuth !== null && hasRoleAdmin(data.userAuth.roles) ? true : false,
                    customBodyRender: (value, tableMeta) => {
                        return idFormatterLinkWithParent(value, tableMeta.tableData[tableMeta.rowIndex], path);
                    }
                }
            },
            {
                name: "name",
                label: t('label.name'),
                options: {
                    filter: true,
                    filterType: 'textField',
                    customFilterListRender: v => `${t('label.name')}: ${v}`,
                    sort: true,
                    // empty: false,
                    display: true,
                    customBodyRender: (value, tableMeta) => {
                        return value;
                    }
                }
            },
            {
                name: "dateCreated",
                label: t('label.dateCreated'),
                options: {
                    filter: false,
                    sort: true,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return dateFormatter(value, tableMeta.tableData[tableMeta.rowIndex], null, getDateFormatter());
                    }
                }
            },
            {
                name: "",
                label: t('actions.label'),
                options: {
                    filter: false,
                    sort: false,
                    empty: true,
                    customBodyRender: (value, tableMeta) => {
                        return actionFormatter(value,
                            tableMeta.tableData[tableMeta.rowIndex],
                            tableMeta.rowIndex,
                            0,
                            null,
                            null,
                            null,
                            1,
                            path,
                            data.showLabel ? t('actions.edit') : undefined,
                            (event, id, fullName) => deleteRecipient(event, id, fullName),
                            data.showLabel ? t('actions.delete') : undefined
                        )
                    },
                    setCellProps: () => ({ className: "click action" })
                }
            }
        ];

        return (
            <CustomGrid role="main" id="recipient-group-list" sx={mainTag()}>
                {outletID !== undefined && organizationID !== undefined &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomBreadcrumbMultiParent
                            parents={[
                                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                                { name: t('outlet.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet` },
                                { name: data.outlet.name, url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet/${outletID}` }
                            ]}
                            instanceName={t('marketing.recipient.group.pageTitle')}
                        />
                    </CustomGrid>
                }
                {
                    outletID === undefined && organizationID !== undefined &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` }
                            ]}
                            instanceName={t('marketing.recipient.group.pageTitle')}
                        />
                    </CustomGrid>
                }

                <Grid container spacing={2} sx={{ alignItems: "center" }}>
                    <Grid item xs={"auto"}>
                        <CustomRefreshButton onClick={getRecipientGroups} marginTop="-1%" />
                    </Grid>

                    <Grid item xs={11} sm={3}>
                        <CustomGrid sx={mainTagTitle()}>
                            <CustomTitle
                                title={`${t('marketing.recipient.group.pageTitle')}`}
                                subtitle={t('marketing.recipient.group.subtitle')}
                            />
                        </CustomGrid>
                    </Grid>
                </Grid>

                <CustomGrid sx={mainAreaTable()} container={false}>
                    <CustomPageableTable
                        instances={data.recipientGroups}
                        emptyMessage={t('table.noData') + t('actions.tableCreateNewLink') + t('marketing.recipient.group.title')}
                        table_columns={table_columns}
                        addPageUrl={`${path}/add`}
                        keyField="name"
                        pagination={data.pagination}
                        defaultSorted={{ name: data.pagination.sort, direction: data.pagination.direction }}
                        handlePaginationChange={handlePaginationChange}
                        handleRowsPerPageChange={handleRowsPerPageChange}
                        handleColumnSortChange={handleColumnSortChange}
                        filter={true}
                        onFilterChange={(event) => (event !== null) ? formValChangeWithParentElementWithNameAndValue(event.field, event.value, search, setSearch) : setSearch({
                            ...search,
                            name: ""
                        })}
                    />
                </CustomGrid>

                <Fab color="primary"
                    aria-label="add"
                    sx={fabStyle()}
                    onClick={() => navigate(`${path}/add`)}
                >
                    <Tooltip arrow title={t('actions.add') + " " + t('marketing.recipient.group.pageTitle')} sx={fabTooltipStyle()}>
                        <AddIcon {...fabStyleIcon()} />
                    </Tooltip>
                </Fab>

                {dialog.isOpen &&
                    <CustomDialog
                        isOpen={dialog.isOpen}
                        id="delete"
                        title={t('actions.deleteModal.title')}
                        actionLabel={t("actions.deleteModal.message")}
                        cancelLabel={t("actions.cancel")}
                        message={`${t('actions.deleteModal.notice')} ${t('actions.deleteModal.confirm')} ${t('marketing.recipient.group.pageTitle')}: `}
                        boldMessage={<span style={{ fontWeight: "bold" }}>{dialog.fullName}</span>}
                        action={deleteR}
                        handleOpen={handleDialogState}
                        actionColor="#e91e1e"
                    />
                }

                {alertBox.isOpen &&
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={3000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        handleSnackbarOpen={handleSnackbarState} />
                }
            </CustomGrid>
        );
    }
}

export default RecipientGroupListComponent;