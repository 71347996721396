export function calculateLoyaltyPointsEarn(cart) {
    let itemsTotalLoyaltyPointsEarned = cart?.items?.reduce((total, item) => {
        let itemLoyaltyPointsEarned = item.loyaltyPointsEarned;
        if (itemLoyaltyPointsEarned === undefined || itemLoyaltyPointsEarned === NaN) { itemLoyaltyPointsEarned = 0 }

        return total + itemLoyaltyPointsEarned;
    }, 0);

    if (itemsTotalLoyaltyPointsEarned === NaN) { itemsTotalLoyaltyPointsEarned = 0 }
    return itemsTotalLoyaltyPointsEarned
}

export function calculateQuantitiesSum(cart) {
    let quantitiesSum = cart?.items?.reduce((total, item) => {
        let itemQuantity = item?.quantity;
        if (itemQuantity === undefined || itemQuantity === NaN) { itemQuantity = 0 }

        return total + itemQuantity;
    }, 0);

    if (quantitiesSum === NaN) { quantitiesSum = 0 }
    return quantitiesSum;
}

export function calculateTax(cart) {
    if (cart?.taxPercent === 0)
        return 0;
    // Calculate itemsTotalAmount
    let itemsTotalAmount = cart?.items?.reduce((total, item) => {
        let itemTotalPrice = item.price * item.quantity;

        // Calculate total price of modifiers for this item
        let modifiersPrice = item?.modifierListIds?.flatMap(modifierList =>
            modifierList.modifiers.map(modifier => ({
                id: modifier.id,
                price: modifier.price
            }))
        )?.reduce((modTotal, modifier) => modTotal + (modifier.price * item.quantity), 0);

        return total + itemTotalPrice + modifiersPrice;
    }, 0);

    // Calculate tax if applicable
    let tax = itemsTotalAmount * cart?.taxPercent / 100;

    return tax;
}

export function calculateTotalAmount(cart) {
    // Calculate itemsTotalAmount
    let itemsTotalAmount = cart?.items?.reduce((total, item) => {
        let itemTotalPrice = item.price * item.quantity;

        // Calculate total price of modifiers for this item
        let modifiersPrice = item?.modifierListIds?.flatMap(modifierList =>
            modifierList.modifiers.map(modifier => ({
                id: modifier.id,
                price: modifier.price
            }))
        )?.reduce((modTotal, modifier) => modTotal + (modifier.price * item.quantity), 0);

        return total + itemTotalPrice + modifiersPrice;
    }, 0);

    // Calculate tax if applicable
    let tax = itemsTotalAmount * cart?.taxPercent / 100;

    // Calculate discount if applicable
    let discount = cart?.discount
        ? (itemsTotalAmount + tax) * cart?.discount?.discountPercentage
        : 0;
    if (discount === NaN) { discount = 0 }

    // Apply coupon if applicable
    let couponValue = cart?.coupon ? cart.coupon.value : 0;

    // Calculate totalAmount
    let totalAmount = itemsTotalAmount + tax;
    return totalAmount;
}

export default class cartFunctions {
    static calculateLoyaltyPointsEarn(cart) { calculateLoyaltyPointsEarn(cart); }
    static calculateQuantitiesSum(cart) { calculateQuantitiesSum(cart); }
    static calculateTax(cart) { calculateTax(cart); }
    static calculateTotalAmount(cart) { calculateTotalAmount(cart); }
}