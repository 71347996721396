import { request } from 'constants/alias';
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from 'utils/functions';
import updateFieldIfValid from 'utils/functions/updateFieldIfValid';

/**
 * The rest request which attempts to get the outlets list from the database.
 * 
 * @param {*} search The filters for search.
 * @param {object} pageable includes all pageable details (page, size, sort, direction)
 * 
 * @returns an object with an attribute containing the outlets list
 */
export async function fetchAll(search, pageable) {
   let data = {};
   updateFieldIfValid(data, "organizationID", search.organizationID);
   updateFieldIfValid(data, "name", search.name, "en");
   updateFieldIfValid(data, "url", search.url);

   if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}outlet`, pageable !== undefined ? { ...pageable, ...data } : data));
   else return await request.get(includeParamsToUrl(`${API}outlet`, pageable !== undefined ? pageable : {}));
}

/**
 * The rest request which attempts to get the outlets list from the database.
 * 
 * @param {*} organizationID The id of the outlet to be fetched
 * @param {object} pageable includes all pageable details (page, size, sort, direction)
 * 
 * @returns an object with an attribute containing the outlets list
 */
export async function fetchAllByOrganizationID(orgID, pageable) {
   let config = {
      baseURL: process.env.REACT_APP_AXIOSBASE,
      params: pageable !== undefined ? pageable : {}
   }

   return await request.get(`${API}outlet/${orgID}/outlet`, config);
}


/**
 * The rest request which return required information to be presented on add new outlet page.
 * 
 * @param {*} organizationID The id of the outlet to be fetched
 * @returns an object with an attribute containing the requested information to be presented on the outlet page
 */
export async function fetchNew(organizationID) {
   let data = {};
   updateFieldIfValid(data, "organizationID", organizationID);

   if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}outlet/add`, data));
   else return await request.get(includeParamsToUrl(`${API}outlet/add`));
}

/**
 * The rest request which attempts to get an existing outlet information.
 * 
 * @param {*} organizationID The id of the outlet to be fetched
 * @param {*} outletID The id of the outlet to be fetched
 * 
 * @returns an object with an attribute containing the current infromation of the outlet
 */
export async function fetchOne(organizationID, outletID) {
   let data = {};
   updateFieldIfValid(data, "organizationID", organizationID);

   if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}outlet/${outletID}`, data));
   else return await request.get(includeParamsToUrl(`${API}outlet/${outletID}`));
}

/**
 * The rest request which attempts to get an existing outlet settings.
 * 
 * @param {*} organizationID The id of the organization to be fetched
 * @param {*} outletID The id of the outlet to be fetched
 * 
 * @returns an object with an attribute containing the current settings of the outlet
 */
export async function fetchOneSettings(organizationID, outletID) {
   let data = {};
   updateFieldIfValid(data, "organizationID", organizationID);

   if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}outlet/${outletID}/settings`, data));
   else return await request.get(includeParamsToUrl(`${API}outlet/${outletID}/settings`));
}


/**
 * The rest request which attempts to get generate waitlist qrcode.
 * 
 * @param {*} organizationID The id of the organization to be fetched
 * @param {*} outletID The id of the outlet to be fetched
 * 
 * @returns an object with an attribute containing the newly generated waitlist qrcode
 */
export async function generateWaitlistQrcode(organizationID, outletID) {
   let data = {};
   updateFieldIfValid(data, "organizationID", organizationID);

   if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}outlet/${outletID}/waitlist/generate`, data));
   else return await request.get(includeParamsToUrl(`${API}outlet/${outletID}/waitlist/generate`));
}

/**
 * The rest request which attempts to create a new outlet.
 * 
 * @param {*} data A JSON object which contains new outlet infromation
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function create(data) {
   return await request.post(`${API}outlet`, data);
}

/**
 * The rest request which attempts to update an existing outlet.
 * 
 * @param {*} data A JSON object which contains the updated infromation of the outlet
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function update(data) {
   return await request.put(`${API}outlet`, data);
}

/**
 * The rest request which attempts to enable an existing outlet.
 * 
 * @param {*} orgID The id of the outlet to be enabled
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function activate(orgID) {
   return await request.post(`${API}outlet/${orgID}/activate`);
}

/**
 * The rest request which attempts to disable an existing outlet.
 * 
 * @param {*} orgID The id of the outlet to be disabled
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function disable(orgID) {
   return await request.post(`${API}outlet/${orgID}/disable`);
}

/**
 * The rest request to delete a specific outlet.
 * 
 * @param {*} id The id of the outlet to be deleted
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function deleteById(id) {
   return await request.delete(`${API}outlet/${id}`);
}

const outletApi = {
   fetchAll,
   fetchAllByOrganizationID,
   fetchNew,
   fetchOne,
   fetchOneSettings,
   create,
   update,
   activate,
   disable,
   deleteById,
   generateWaitlistQrcode
}

export default outletApi;