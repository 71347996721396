export const getDefaultSortDirection = {
    sort: 'dateCreated',
    direction: 'desc'
};

export const getDefaultSortDirectionData = {
    sort: 'dateCreated',
    direction: 'desc'
};

export const getDefaultUserSortDirection = {
    sort: 'username',
    direction: 'asc'
};

export const getDefaultSubscriptionPlanSortDirection = {
    sort: 'name',
    direction: 'asc'
};

export const getDefaultAnalyticsSortDirection = {
    sort: 'productRevenue',
    direction: 'desc'
}

export const getDefaultOrderSortDirection = {
    sort: 'dateCreated',
    direction: 'desc'
};
export const getDefaultInventorySortDirection = {
    sort: 'name',
    direction: 'desc'
};

export const getDefaultRecipientSortDirection = {
    sort: 'dateCreated',
    direction: 'asc'
};

export const getDefaultRecipientForGroupSortDirection = {
    sort: 'firstName',
    direction: 'asc'
};

export const getDefaultSmsSortDirection = {
    sort: 'dateCreated',
    direction: 'desc'
};

export const getDefaultEmailSortDirection = {
    sort: 'dateCreated',
    direction: 'desc'
};