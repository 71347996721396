import { Grid } from '@mui/material';
import customerApi from "api/customer";
import CustomBreadcrumbMultiParent from 'components/Breadcrumb/CustomBreadcrumbMultiParent';
import CustomRefreshButton from 'components/Button/CustomRefreshButton';
import CustomGrid from 'components/Grid/CustomGrid';
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';
import CustomPageableTable from "components/Table/CustomPageableTable";
import CustomTitle from 'components/Title/CustomTitle';
import { getDefaultUserSortDirection } from 'constants/sortDirection';
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { mainAreaTable, mainTag, mainTagBreadcrumb, mainTagTitle } from 'themes/defaultThemes';
import { hasRoleAdmin } from 'utils/auth';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';
import { prepareTablePaginationSortParams } from 'utils/functions';
import { getLanguageFromURL } from 'utils/language';
import { iconFormatter, idFormatterLinkWithParent, nanFormatter } from "utils/table";
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The CustomerListComponentAdmin, that allow the customer to view the customers list into a table.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomerListComponentAdmin() {
    const { t } = useTranslation();
    const { organizationID } = useParams();
    const { outletID } = useParams();
    let path = `/${getLanguageFromURL()}`
    if (organizationID !== undefined) path += `/organization/${organizationID}`;
    if (outletID !== undefined) path += `/outlet/${outletID}`;
    path += `/customer`;

    //change document title
    useDocumentTitle(`Usee  ${organizationID !== undefined ? " | " + t('org.pageTitle') : ""} ${outletID !== undefined ? " | " + t('outlet.pageTitle') : ""} | ${t('customer.pageTitle')}`);

    /**
     * @type {object}
     * 
    * @property {object} userAuth the authenticated customer infromation
    * @property {array} customers the customers list
    * @property {object} organization tha organization basic details (id and name)
    * @property {object} pagination includes all pageable details (page, size, sort, direction)
     */
    const [data, setData] = useState({
        userAuth: null,
        customers: [],
        organization: {
            id: organizationID,
            name: ""
        },
        pagination: {
            count: -1,
            size: 10,
            page: 0,
            ...getDefaultUserSortDirection
        }
    });

    // to perform initial request
    const [isFirstTime, setIsFirstTime] = useState(true);
    // if true the menu item is clicked and a redirect to page should perform
    const [isLoaded, setIsLoaded] = useState(false);
    // for search
    const [search, setSearch] = useState({
        organizationID: organizationID,
        outletID: outletID !== undefined ? outletID : undefined,
        field: "username",
        username: "",
        email: "",
        phone: "",
        showOrderRecentlty: false,
        showReturning: false
    });

    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313"
    });

    const [setSearchValue] = useState('');

    useEffect(() => {
        getCustomers();
        setIsFirstTime(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const identifier = setTimeout(() => {
            if (!isFirstTime) getCustomers();
        }, 700);

        return () => {
            clearTimeout(identifier);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, data.pagination.size, data.pagination.page, data.pagination.sort, data.pagination.direction])


    /**
     * The rest endpoint to get the customers list.
     */
    function getCustomers() {
        customerApi.fetchAll(prepareTablePaginationSortParams(data.pagination), search).then((r) => {
            setData({
                ...data,
                customers: r.data.returnobject.customers,
                userAuth: r.data.returnobject.userAuth,
                organization: r.data.returnobject.organization,
                pagination: {
                    ...data.pagination,
                    count: r.data.returnobject.customers.totalPages,
                    size: r.data.returnobject.customers.size,
                    page: r.data.returnobject.customers.number
                }
            });
            setIsLoaded(true);
        }).catch((e) => {
            // console.log(e);
        })
    }

    /**
     * Gets called to activate the selected subscription plan
     * 
     * @param {*} event
     * @param {string} id the id of the subscription plan
     */
    function enableCustomer(event, id) {
        customerApi.enable(id).then((r) => {
            setAlertBox({
                isOpen: true,
                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
            // setRedirect((r.data.code === "SUCCESS" ? true : false));
            getCustomers();
        }).catch((e) => {
            // console.log(e);
        })
    }

    /**
     * Gets called to activate the selected subscription plan
     * @param {*} event
     * @param {string} id the id of the subscription plan 
     */
    function disableCustomer(event, id) {
        customerApi.disable(id).then((r) => {
            setAlertBox({
                isOpen: true,
                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
            // setRedirect((r.data.code === "SUCCESS" ? true : false));
            getCustomers();
        }).catch((e) => {
            // console.log(e);
        })
    }

    /**
     * Function that triggers the multi select input value change.
     * @param {*} event 
     *//* eslint-disable no-unused-vars */
    function onSelectSearchChange(event) {
        let field = event.target.value;
        setSearchValue("");
        setSearch({
            ...search,
            field: field,
            username: "",
            email: "",
            phone: "",
            [field]: ""
        });
    }
    /* eslint-enable no-unused-vars */

    /**
     * Function that handles the snackbar open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }

    /**
     * Function that handles the pagination information.
     * @property {int} page the page to be redirected
     */
    function handlePaginationChange(page) {
        formValChangeWithParentElementWithNameAndValue("pagination.page", page, data, setData);
    }

    /**
     * Function that handles the pagination information.
     * @property {int} size the size the new numberOfRows to be requested
     */
    function handleRowsPerPageChange(size) {
        let pagination = data.pagination;
        pagination.size = size;
        pagination.page = 0;
        formValChangeWithParentElementWithNameAndValue("pagination", pagination, data, setData);
    }
    /**
     * Function that handles the sort information.
     * @property {int} size the size the new numberOfRows to be requested
     */
    function handleColumnSortChange(changedColumn, direction) {
        let pagination = data.pagination;
        pagination.sort = changedColumn;
        pagination.direction = direction;
        formValChangeWithParentElementWithNameAndValue("pagination", pagination, data, setData);
    }


    // ===============================================================================RENDER

    if (!isLoaded) {
        return <LoadingSkeleton lines={9} />;
    } else {
        /**
         * datatable columns.
         */
        const table_columns = [
            {
                name: "enabled",
                label: t('label.isEnabled'),
                options: {
                    filter: false,
                    sort: false,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return iconFormatter(value,
                            tableMeta.tableData[tableMeta.rowIndex],
                            tableMeta.rowIndex,
                            {
                                enable: (event, id) => enableCustomer(event, id),
                                disable: (event, id) => disableCustomer(event, id),
                                positionOfId: 1
                            });
                    }
                }
            },
            {
                name: "id",
                label: t('label.id'),
                options: {
                    filter: false,
                    sort: false,
                    empty: false,
                    display: data.userAuth !== null && hasRoleAdmin(data.userAuth.roles) ? true : false,
                    customBodyRender: (value, tableMeta) => {
                        return idFormatterLinkWithParent(value, tableMeta.tableData[tableMeta.rowIndex], path);
                    }
                }
            },
            {
                name: "username",
                label: t('label.username'),
                options: {
                    filter: true,
                    filterType: 'textField',
                    customFilterListRender: v => `${t('label.username')}: ${v}`,
                    sort: true,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return value;
                    }
                }
            },
            {
                name: "firstName",
                label: t('label.firstName'),
                options: {
                    filter: false,
                    sort: true,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return nanFormatter(value, tableMeta.tableData[tableMeta.rowIndex], tableMeta.rowIndex, { emptyMessage: t("label.na") });
                    }
                }
            },
            {
                name: "lastName",
                label: t('label.lastName'),
                options: {
                    filter: false,
                    sort: true,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return value;
                    }
                }
            },
            {
                name: "phone",
                label: t('label.phone'),
                options: {
                    filter: true,
                    filterType: 'textField',
                    customFilterListRender: v => `${t('label.phone')}: ${v}`,
                    sort: true,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return value;
                    }
                }
            },
            {
                name: "email",
                label: t('label.email'),
                options: {
                    filter: true,
                    filterType: 'textField',
                    customFilterListRender: v => `${t('label.email')}: ${v}`,
                    sort: true,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return value;
                    }
                }
            }
        ];

        return (
            <CustomGrid role="main" id="customer-list" sx={mainTag()}>
                {outletID !== undefined && organizationID !== undefined &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomBreadcrumbMultiParent
                            parents={[
                                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                                { name: t('outlet.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet` },
                                { name: data.outlet.name, url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet/${outletID}` }
                            ]}
                            instanceName={t('employee.title')}
                        />
                    </CustomGrid>
                }
                {
                    outletID === undefined && organizationID !== undefined &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` }
                            ]}
                            instanceName={t('employee.title')}
                        />
                    </CustomGrid>
                }

                <Grid container spacing={2} sx={{ alignItems: "center" }}>
                    <Grid item xs={"auto"}>
                        <CustomRefreshButton onClick={getCustomers} marginTop="-1%" />
                    </Grid>

                    <Grid item xs={11} sm={3}>
                        <CustomGrid sx={mainTagTitle()}>
                            <CustomTitle
                                title={`${t('customer.title')}`}
                                subtitle={t('customer.subtitle')}
                            />
                        </CustomGrid>
                    </Grid>
                </Grid>

                <CustomGrid sx={mainAreaTable()} container={false}>
                    <CustomPageableTable
                        instances={data.customers}
                        emptyMessage={t('table.noData')}
                        table_columns={table_columns}
                        addPageUrl=""
                        keyField="username"
                        defaultSorted={{ name: data.pagination.sort, direction: data.pagination.direction }}
                        pagination={data.pagination}
                        handlePaginationChange={handlePaginationChange}
                        handleRowsPerPageChange={handleRowsPerPageChange}
                        handleColumnSortChange={handleColumnSortChange}
                        filter={true}
                        onFilterChange={(event) => (event !== null) ? formValChangeWithParentElementWithNameAndValue(event.field, event.value, search, setSearch) : setSearch({
                            ...search,
                            username: "",
                            email: "",
                            phone: ""
                        })}
                    />
                </CustomGrid>

                {alertBox.isOpen &&
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={3000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        handleSnackbarOpen={handleSnackbarState} />
                }
            </CustomGrid>
        );
    }
}

export default CustomerListComponentAdmin;