import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

/**
 * The LoadingSkeleton, that display a box with animated skeletons untils a page is ready to render.
 * @property {int} lines the number of skeleton rows to be shown
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function LoadingSkeleton({ lines }) {
    /**
     * Function that trigers the creation of the loading skeleton rows
     */
    function createSkeletons() {
        let skeletonRows = [];
        for (let i = 0; i < Math.floor(lines / 2); i++) {
            skeletonRows.push(
                [
                    <Skeleton key={"s" + i + "a"} />
                    ,
                    <Skeleton animation="wave" key={"s" + i + "b"} />
                ]
            )
        }
        return skeletonRows;
    }

    return (
        <Box sx={{ width: "100%" }}>
            {createSkeletons()}
            {lines % 2 === 1 &&
                <Skeleton key={"s" + lines} />
            }
        </Box>
    );
}

export default LoadingSkeleton;