import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

/**
 * The PdaSkeleton, that display a box with animated skeletons untils a PDA page is ready to render.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function PdaSkeleton({ isMobile }) {

    return (
        <Box sx={{ width: "100%", padding: `20px ${isMobile ? "0" : "40px"}` }}>
            {/* Skeleton for tabs */}
            <Skeleton variant="rounded" height={80} sx={{ borderRadius: "20px" }} />

            {/* Skeleton for euipments */}
            <Grid container gap={2} columnSpacing={1} sx={{ marginTop: "40px", p: 3 }}>
                <Grid item sx={"auto"}>
                    <Skeleton variant="rounded" width={80} height={80} sx={{ borderRadius: "20px" }} />
                </Grid>
                <Grid item sx={"auto"}>
                    <Skeleton variant="rounded" width={80} height={80} sx={{ borderRadius: "20px" }} />
                </Grid>
                <Grid item sx={"auto"}>
                    <Skeleton variant="rounded" width={80} height={80} sx={{ borderRadius: "20px" }} />
                </Grid>
                <Grid item sx={"auto"}>
                    <Skeleton variant="rounded" width={80} height={80} sx={{ borderRadius: "20px" }} />
                </Grid>
                <Grid item sx={"auto"}>
                    <Skeleton variant="rounded" width={80} height={80} sx={{ borderRadius: "20px" }} />
                </Grid>
                <Grid item sx={"auto"}>
                    <Skeleton variant="rounded" width={80} height={80} sx={{ borderRadius: "20px" }} />
                </Grid>
                <Grid item sx={"auto"}>
                    <Skeleton variant="rounded" width={80} height={80} sx={{ borderRadius: "20px" }} />
                </Grid>
                <Grid item sx={"auto"}>
                    <Skeleton variant="rounded" width={80} height={80} sx={{ borderRadius: "20px" }} />
                </Grid>
                <Grid item sx={"auto"}>
                    <Skeleton variant="rounded" width={80} height={80} sx={{ borderRadius: "20px" }} />
                </Grid>
                <Grid item sx={"auto"}>
                    <Skeleton variant="rounded" width={80} height={80} sx={{ borderRadius: "20px" }} />
                </Grid>
                <Grid item sx={"auto"}>
                    <Skeleton variant="rounded" width={80} height={80} sx={{ borderRadius: "20px" }} />
                </Grid>
                <Grid item sx={"auto"}>
                    <Skeleton variant="rounded" width={80} height={80} sx={{ borderRadius: "20px" }} />
                </Grid>
                <Grid item sx={"auto"}>
                    <Skeleton variant="rounded" width={80} height={80} sx={{ borderRadius: "20px" }} />
                </Grid>
                <Grid item sx={"auto"}>
                    <Skeleton variant="rounded" width={80} height={80} sx={{ borderRadius: "20px" }} />
                </Grid>
                <Grid item sx={"auto"}>
                    <Skeleton variant="rounded" width={80} height={80} sx={{ borderRadius: "20px" }} />
                </Grid>
            </Grid>
        </Box>
    );
}

export default PdaSkeleton;