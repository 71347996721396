import { Add as AddIcon, CloseRounded as CloseRoundedIcon, Delete as DeleteIcon, Print as PrintIcon } from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';
import {
    Button,
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    Stack,
    Switch,
} from '@mui/material';
import { Box } from '@mui/system';
import printerApi from 'api/printer';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomPrinterCard from 'components/Card/printer/CustomPrinterCard';
import CustomCheckbox from 'components/Checkbox/CustomCheckbox';
import CustomObjectSelect from 'components/Select/CustomObjectSelect';
import CustomSelect from 'components/Select/CustomSelect';
import CustomSelectMultilingual from 'components/Select/CustomSelectMultilingual';
import CustomSelectObjectWithChipAndIcon from 'components/Select/CustomSelectObjectWithChipAndIcon';
import CustomTabs from 'components/Tab/CustomTabs';
import CustomTextField from 'components/Text/CustomTextField';
import { getDefaultPrinterObject, getDefaultPrinterTicketObject } from 'constants/defaultObjects';
import { getPrinterNodeId } from 'constants/fieldProperties';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addCardActionAreaButtonStyle, addWorkingHoursButtonIconStyle, cardItemButtonGroupStackButton, cardItemButtonGroupStackButtonIcon, deleteButtonTicketStyle, mainTagSearchItemOrderBox, mainTagSearchItemOrderBoxLegend, mainTagSearchItemOrderContainer, printerSectionButtonAll, textFieldError, ticketGridStyle } from 'themes/defaultThemes';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';
import { handleKeyDownNumberTextField, validateIPAddress, validateInput, validateInputNumber, validatePort, validatePrintNodeIdInput } from 'utils/functions';

/**
 * The AddEditPrinterDialog, that display a customized dialog to add/edit a printer.
 *
 * @property {object} existingPrinter the object containing details for printer
 * @property {boolean} isOpen If the values is `true`, the modal should be open and visible. Default value is false.
 * @property {boolean} isAdd If the values is `true`, the modal refering to creation of a new printer.
 * @property {function} handleOpen the function that handles the dialog open state
 * @property {function} addPrinter the function that handles the creation of a  printer
 * @property {function} updatePrinter the function that handles the modification of a  printer
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function AddEditPrinterDialog({
    existingPrinter,
    isOpen = false,
    isAdd = true,
    handleOpen,
    addPrinter,
    updatePrinter,
    availableSectionOptions = [],
    equipmentOptions = [],
    // updateAvailableSectionOptions,
    // employeeOptions = [],
    orderTicketFieldOptions = [],
    receiptTicketFieldOptions = [],
    requestTicketFieldOptions = [],
    waitlistTicketFieldOptions = [],
    printNotificationActions = [],
    itemCategories = [],
    search,
    setAlertBox
}) {
    const { t } = useTranslation();
    const [ticketTabValue, setTicketTabValue] = useState(printNotificationActions.length > 0 ? printNotificationActions[0] : null);
    const [printer, setPrinter] = useState(isAdd ? getDefaultPrinterObject() : existingPrinter);
    const [tabValue, setTabValue] = useState("details");

    // @property {boolean} if the values is `true`, the modal should be open and visible.
    const [open, setOpen] = useState(isOpen);
    /**
     * @type {object}
     * @property {object} userAuth the authenticated user infromation
     * @property {object} organization tha organization basic details (id and name)
     * @property {object} outlet tha outlet basic details (id and name)
     */
    const [data, setData] = useState({
        userAuth: null,
        organization: null,
        outlet: null,
        equipmentOptions: [],
        employeeOptions: [],
        availableSectionOptions: [],
        printNotificationActions: [],
        orderTicketFieldOptions: [],
        requestTicketFieldOptions: [],
        receiptTicketFieldOptions: [],
        waitlistTicketFieldOptions: [],
        itemCategories: [],
        langOptions: [],
        paperSizeOptions: [],
        fontSizes: [],
        models: []
    });

    const [isError, setIsError] = useState({
        printNodeId: "",
        printerPort: "",
        printerIP: ""
    });

    /**
     * The rest endpoint to get the printers list.
     */
    function getPrinterNew() {
        printerApi.fetchNew(search?.organizationID, search?.outletID).then((r) => {
            Promise.resolve()
                .then(() => {
                    setData({
                        ...data,
                        userAuth: r.data.returnobject.userAuth,
                        organization: r.data.returnobject.organization,
                        outlet: r.data.returnobject.outlet,
                        orderTicketFieldOptions: r.data.returnobject.orderTicketFieldOptions,
                        requestTicketFieldOptions: r.data.returnobject.requestTicketFieldOptions,
                        receiptTicketFieldOptions: r.data.returnobject.receiptTicketFieldOptions,
                        waitlistTicketFieldOptions: r.data.returnobject.waitlistTicketFieldOptions,
                        printNotificationActions: r.data.returnobject.printNotificationActions,
                        langOptions: r.data.returnobject.langOptions,
                        itemCategories: r.data.returnobject.itemCategories,
                        paperSizeOptions: r.data.returnobject.paperSizeOptions,
                        fontSizes: r.data.returnobject.fontSizes,
                        models: r.data.returnobject.models
                    });
                })
        }).catch((e) => {
            // console.log(e);
        })
    }

    useEffect(() => {
        if (!isAdd)
            setPrinter(existingPrinter)
    }, [existingPrinter]);

    useEffect(() => {
        getPrinterNew();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Function that handles the visualize modal open or close state.
     * @property {boolean} isOpened If the values is `true`, the modal should be open and visible.
     */
    function handleDialogState(isOpened) {
        setOpen(isOpened);
        handleOpen(isOpened);
    }

    function handleAction() {
        if (isAdd) {
            if (printer.type === "PrintNode") {
                if (validatePrintNodeIdInput(printer.printNodeId)) {
                    printerApi.exist(printer.printNodeId).then((r) => {
                        if (r.data.code !== "INVALID") {
                            setOpen(false);
                            handleOpen(false);
                            addPrinter(printer)
                        } else {
                            setIsError({ printNodeId: r.data.message })
                        }
                    }).catch((e) => {
                        // console.log(e)
                    });
                } else {
                    setIsError({
                        ...isError,
                        printNodeId: t('printer.printNodeIdErrror')
                    });
                    setTabValue("details");
                }
            } else {
                if (validateIPAddress(printer.printerIP) && validatePort(printer.printerPort)) {
                    setOpen(false);
                    handleOpen(false);
                    addPrinter(printer)
                } else {
                    setIsError({
                        ...isError,
                        printerIP: validateIPAddress(printer.printerIP) ? "" : t('printer.invalidIP'),
                        printerPort: validatePort(printer.printerPort) ? "" : t('printer.invalidPort')
                    });
                    setTabValue("details");
                }

            }
        } else {
            updatePrinter(printer);
        }
    }

    /**
     * Function that triggers the removal of an outlet section when clicking x button.
     * @param {*} idToDelete 
     */
    function removeId(event, idToDelete, field) {
        let ids = printer[field];
        const index = ids.indexOf(idToDelete);
        // only splice array when item is found
        if (index > -1) ids.splice(index, 1); // 2nd parameter means remove one item only
        if (field === "outletSections")
            setPrinter({
                ...printer,
                [field]: ids
            });
        else
            setPrinter({
                ...printer,
                [field]: ids
            });
    }

    /**
     * Update a specific ticket configuration for a given action.
     * 
     * @param {string} action - The action to update the ticket configuration.
     * @param {number} index - The index of the ticket configuration to be update.
     * @param {string} field - The ticket configuration field to be updated.
     * @param {string|number} value - iThe ticket configuration field value.
     */
    function handleTicketFieldChange(action, index, field, value) {
        const tickets = printer.tickets.map((ticket) =>
            ticket.action === action
                ? {
                    ...ticket,
                    // Use map to update the specific field
                    showFields: ticket.showFields.map((showField, i) =>
                        i === index
                            ? {
                                ...showField,
                                [field]: value,
                            }
                            : showField
                    ),
                }
                : ticket

        );
        formValChangeWithParentElementWithNameAndValue("tickets", tickets, printer, setPrinter);
    }

    /**
     * Update a specific ticket configuration for a given action.
     * Note: for checkboxes only
     * 
     * @param {string} action - The action to update the ticket configuration.
     * @param {number} index - The index of the ticket configuration to be update.
     * @param {string} field - The ticket configuration field to be shown.
     * @param {boolean} value - if true include the value to field.
     */
    function handleTicketCheckboxFieldChange(action, index, field, value) {
        const tickets = printer.tickets.map((ticket) =>
            ticket.action === action
                ? {
                    ...ticket,
                    // Use map to update the specific field
                    // we use a ternary operator to check whether the value is truthy (i.e., true). If it is, we include it in the array using the spread operator ([...showField[field], value]). Otherwise, we remove it from the array using the filter() method (showField[field].filter(val => val !== value)).
                    showFields: ticket.showFields.map((showField, i) =>
                        i === index
                            ? {
                                ...showField,
                                showFields: value ? [...showField["showFields"], field] : showField["showFields"].filter(val => val !== field),
                            }
                            : showField
                    ),
                }
                : ticket
        );
        formValChangeWithParentElementWithNameAndValue("tickets", tickets, printer, setPrinter);
    }

    function getDefaultPrinterTicketFieldOptions(action) {
        if (action.includes("ORDER")) return orderTicketFieldOptions;
        else if (action.includes("REQUEST")) return requestTicketFieldOptions;
        else if (action.includes("RECEIPT")) return receiptTicketFieldOptions;
        else if (action.includes("WAITLIST")) return waitlistTicketFieldOptions;
    }

    /**
     * Adds a new ticket configuration for a given action.
     * 
     * @param {string} action - The action to add the new ticket configuration.
     */
    function handleAddTicketConfigurationForAction(action) {
        const matchingTicket = printer.tickets.find((ticket) => ticket.action === action);
        if (matchingTicket) {
            const updatedTickets = printer.tickets.map((ticket) =>
                ticket.action === action
                    ? {
                        ...ticket,
                        // Use map to update the specific field
                        showFields: [...ticket.showFields, getDefaultPrinterTicketObject(action, getDefaultPrinterTicketFieldOptions(action))]
                    }
                    : ticket
            );
            formValChangeWithParentElementWithNameAndValue("tickets", updatedTickets, printer, setPrinter);
        } else {
            formValChangeWithParentElementWithNameAndValue("tickets", [...printer.tickets, getDefaultPrinterTicketObject(action, getDefaultPrinterTicketFieldOptions(action))], printer, setPrinter);
        }
    }

    /**
     * Removes a specific ticket configuration for a given action.
     * 
     * @param {string} action - The action to remove the ticket configuration from.
     * @param {number} index - The index of the ticket configuration to remove.
     */
    function handleRemovalTicketConfigurationForAction(action, showFieldsIndex) {
        const tickets = printer.tickets.filter((ticket) => {
            if (ticket.action === action) {
                //if action contained only the ticket to be removed -> remove ticket for action
                if (ticket.showFields.length === 1 && showFieldsIndex === 0) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        }).map((ticket) => {
            if (ticket.action === action) {
                return {
                    ...ticket,
                    // Filter out the specific showFields index
                    showFields: ticket.showFields.filter((_, index) => index !== showFieldsIndex)
                };
            } else {
                return ticket;
            }
        });

        formValChangeWithParentElementWithNameAndValue("tickets", tickets, printer, setPrinter);
    }

    function sendTestPrint(printNotificationActions, ticketConfigOfAction, action, actionInstanceIndex) {
        let testPrinter = {
            id: printer.id,
            organizationID: printer.organizationID,
            outletID: printer.outletID,
            printNodeId: printer.printNodeId,
            printerIP: printer.printerIP,
            printerPort: printer.printerPort,
            type: printer.type,
            paperSize: printer.paperSize,
            model: printer.model,
            baseFontSize: printer.baseFontSize,
            primaryFontSize: printer.primaryFontSize,
            secondaryFontSize: printer.secondaryFontSize,
            tableFontSize: printer.tableFontSize,
            tableNoteFontSize: printer.tableNoteFontSize,
            itemCategories: printer.itemCategories,
            action: action,
            ticket: ticketConfigOfAction,
        };
        printerApi.testPrint(testPrinter).then((r) => {
            setAlertBox({
                isOpen: true,
                message: r.data.message,
                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
            });
        }).catch((e) => {
            // console.log(e)
        });
    }

    // ========================== Equipments

    const [selectedSections, setSelectedSections] = useState([]);

    useEffect(() => {
        formValChangeWithParentElementWithNameAndValue("outletSections", selectedSections, printer, setPrinter);
    }, [selectedSections]);

    function handleSectionChange(event) {
        console.log(event.target)
        const selectedSections = event.target.value;
        setSelectedSections(selectedSections);
    }

    function handleIncludedEquipmentsChange(event) {
        const includedEquipmentIds = event.target.value;
        formValChangeWithParentElementWithNameAndValue("includedEquipmentIds", includedEquipmentIds, printer, setPrinter);
    }

    function handleExcludedEquipmentsChange(event) {
        const excludedEquipmentIds = event.target.value;

        // Determine which sections should be removed
        const sectionsToRemove = new Set();
        const equipmentsToRemove = new Set();
        excludedEquipmentIds.forEach(excludedEquipmentId => {
            availableSectionOptions?.forEach(section => {
                if (section.equipments.length === 1 && section?.equipments?.includes(excludedEquipmentId)) {
                    sectionsToRemove.add(section.id);
                    equipmentsToRemove.add(excludedEquipmentId);
                }
            });
        });
        // Remove these sections from the printer
        const updatedOutletSections = printer.outletSections?.filter(
            sectionId => !sectionsToRemove.has(sectionId)
        );
        setPrinter(prevState => ({
            ...prevState,
            outletSections: updatedOutletSections
        }));

        const updatedEquipmentIds = excludedEquipmentIds?.filter(
            equipmentID => !equipmentsToRemove.has(equipmentID)
        );
        // Update the excluded equipment IDs
        setPrinter(prevState => ({
            ...prevState,
            excludedEquipmentIds: updatedEquipmentIds
        }));
    }

    const isExcludedEquipmentsDisabled = printer.outletSections.length === 0;


    // ================================= model

    function handleModelChange(event) {
        const model = event.target.value;
        setPrinter(prevState => ({
            ...prevState,
            model: model
        }));
    }


    function handleFieldChange(event, field) {
        const newValue = event.target.value;
        setPrinter(prevState => ({
            ...prevState,
            [field]: newValue
        }));
    }

    // ======================================================= RENDER
    return (
        <>
            <Dialog
                open={open}
                onClose={() => { handleDialogState(false) }}
                aria-describedby="alert-dialog-description"
                className="dialog-title"
            >
                <DialogTitle className="dialog-headers">
                    {isAdd ? `${t('actions.add')} ${t('printer.title')}` : `${t('actions.edit')} ${t('printer.title')} '${(printer.friendlyName !== "" && printer.friendlyName !== null) ? printer.friendlyName : printer.printNodeId}'`}
                    <IconButton onClick={() => { handleDialogState(false) }} sx={{ float: "right" }}>
                        <CloseRoundedIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ background: "#fff", minWidth: "500px" }}>
                    <TabContext value={tabValue}>
                        <CustomTabs
                            initValue={tabValue}
                            instances={[
                                { name: t('printer.tabs.details'), value: "details" },
                                { name: t('printer.tabs.ticketConfiguration.label'), value: "ticketConfiguration" },
                                { name: t('printer.tabs.style.label'), value: "style" },
                            ]}
                            setValue={setTabValue}
                            sx={{ marginBottom: "20px", padding: 0 }}
                            scrollButtons={true}
                        />
                        <Divider />

                        <TabPanel value={"details"} sx={{ paddingTop: "0px" }}>
                            <Stack direction="row" sx={{ justifyContent: "center", marginTop: "20px", marginBottom: "20px" }}>
                                <CustomPrinterCard
                                    printer={printer}
                                    sx={{ marginRight: 0 }}
                                    onDialog={true}
                                    equipmentOptions={equipmentOptions}
                                    availableSectionOptions={availableSectionOptions}
                                    itemCategories={itemCategories} />
                            </Stack>

                            <Grid container gap={1}>
                                <Grid item xs={12} sx={{ paddingBottom: "20px" }}>
                                    {/* <CustomTextField type="number" step="1" min={0} name="min"
                              defaultValue={printer.organizationID}
                              inputAdornmentLabel={'loyalty.category.targetPoints'}
                              required={true}
                              sx={{ border: "none", width: "97.5%" }}
                              onChange={(event) => { console.log(loyaltyCategory.targetPoints); formValChangeWithParentElementWithNameAndValue("targetPoints", event.target.value, printer, setPrinter) }}
                           /> */}
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container gap={3}>
                                        <Grid item xs={"auto"}>
                                            <FormControlLabel control={<Switch defaultChecked={printer.active} onChange={() => formValChangeWithParentElementWithNameAndValue("active", !printer.active, printer, setPrinter)} />} sx={{ marginBottom: "20px" }} label={t('printer.isActive')} />
                                        </Grid>

                                        <Grid item xs={"auto"}>
                                            <InputLabel>{t('printer.paperSize.label')}</InputLabel>
                                            <ButtonGroup variant="outlined">
                                                {data.paperSizeOptions?.map((paperSizeOption) => (
                                                    <>
                                                        <CustomIconButton
                                                            label={t(`printer.paperSize.${printer.paperSize}`)}
                                                            onClick={() => formValChangeWithParentElementWithNameAndValue("paperSize", paperSizeOption, printer, setPrinter)}
                                                            isFocused={printer.paperSize === paperSizeOption}
                                                            sx={{ textTransform: "inherit" }}
                                                            backgroundColor={"#47a6d2"}
                                                            variant={"outlined"}
                                                        />
                                                    </>
                                                ))}
                                            </ButtonGroup>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <InputLabel required={false} htmlFor="friendlyName">{t('printer.friendlyName')}</InputLabel>
                                    <CustomTextField
                                        type="text"
                                        name="friendlyName"
                                        id="friendlyName"
                                        value={printer.friendlyName}
                                        onChange={(event) => {
                                            let value = event.target.value;
                                            if (!validateInput(value, 20)) value = printer.friendlyName;
                                            formValChangeWithParentElementWithNameAndValue("friendlyName", value, printer, setPrinter)
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <InputLabel>{t('printer.type.label')}</InputLabel>
                                    <ButtonGroup variant="outlined">
                                        <CustomIconButton
                                            label={t('printer.type.printNode')}
                                            onClick={() => formValChangeWithParentElementWithNameAndValue("type", "PrintNode", printer, setPrinter)}
                                            isFocused={printer.type === "PrintNode"}
                                            sx={{ textTransform: "inherit" }}
                                            backgroundColor={"#47a6d2"}
                                            variant={"outlined"}
                                        />
                                        {/* <CustomIconButton
                                 label={t('printer.type.ip')}
                                 onClick={() => formValChangeWithParentElementWithNameAndValue("type", "IP", printer, setPrinter)}
                                 isFocused={printer.type === "IP"}
                                 backgroundColor={"#47a6d2"}
                                 variant={"outlined"}
                              /> */}
                                    </ButtonGroup>
                                </Grid>

                                {/* {printer.type === "IP" &&
                           <Grid item xs={7.8}>
                              <Grid container gap={1}>
                                 <Grid item xs={12} sm={7.5}>
                                    <InputLabel required={true} htmlFor="printerIP">{t('printer.ip')}</InputLabel>
                                    <CustomTextField
                                       type="text"
                                       name="printerIP"
                                       id="printerIP"
                                       required={true}
                                       value={printer.printerIP}
                                       onChange={(event) => {
                                          if (!validateIPAddress(event.target.value)) {
                                             setIsError({
                                                ...isError,
                                                printerIP: t('printer.invalidIP'),
                                             });
                                          } else {
                                             setIsError({
                                                ...isError,
                                                printerIP: "",
                                             });
                                          }
                                          formValChangeWithParentElementWithNameAndValue("printerIP", event.target.value, printer, setPrinter)
                                       }}
                                       helperText={validateIPAddress(isError.printerIP)}
                                       error={isError.printerIP.length > 0}
                                       sx={textFieldError()}
                                    />
                                 </Grid>
                                 <Grid item xs={12} sm={4}>
                                    <InputLabel required={true} htmlFor="printerPort">{t('printer.port')}</InputLabel>
                                    <CustomTextField
                                       type="number"
                                       name="printerPort"
                                       id="printerPort"
                                       required={true}
                                       value={printer.printerPort}
                                       onChange={(event) => {
                                          if (!validatePort(event.target.value)) {
                                             setIsError({
                                                ...isError,
                                                printerPort: t('printer.invalidPort'),
                                             });
                                          } else {
                                             setIsError({
                                                ...isError,
                                                printerPort: "",
                                             });
                                          }
                                          formValChangeWithParentElementWithNameAndValue("printerPort", event.target.value, printer, setPrinter)
                                       }}
                                       helperText={validatePort(isError.printerPort)}
                                       error={isError.printerPort.length > 0}
                                       sx={textFieldError()}
                                    />
                                 </Grid>
                              </Grid>
                           </Grid>
                        } */}


                                {printer.type === "PrintNode" &&
                                    <Grid item xs={7.8}>
                                        <InputLabel required={true} htmlFor="printNodeId">{t('printer.printNodeId')}</InputLabel>
                                        <CustomTextField
                                            type="number"
                                            disabled={!isAdd}
                                            step={getPrinterNodeId().step}
                                            min={getPrinterNodeId().min}
                                            max={getPrinterNodeId().max}
                                            name="printNodeId"
                                            id="printNodeId"
                                            required={true}
                                            value={printer.printNodeId}
                                            onChange={(event) => {
                                                let value = validateInputNumber(event.target.value, 8, getPrinterNodeId().min, getPrinterNodeId().max, false, true, "printNodeId");
                                                if (value === undefined) value = printer.printNodeId;
                                                formValChangeWithParentElementWithNameAndValue("printNodeId", value, printer, setPrinter)
                                            }}
                                            helperText={isError.printNodeId}
                                            error={isError.printNodeId.length > 0 ? true : false}
                                            sx={textFieldError()}
                                        // onKeyDown={handleKeyDownNumberTextField}
                                        />
                                    </Grid>
                                }

                                <Grid item xs={12}>
                                    <InputLabel>{t('printer.model.label')}</InputLabel>
                                    <CustomSelect
                                        name="model"
                                        required={true}
                                        defaultValue={printer.model}
                                        options={data.models}
                                        onChange={handleModelChange}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <InputLabel required={false} htmlFor="outletSections">{t('printer.outletSections')}</InputLabel>
                                    <Grid container>
                                        <Grid item xs={10} md={10}>
                                            {/* <Autocomplete
                                    name="outletSections"
                                    multiple={true}
                                    defaultValue={printer.outletSections}
                                    getOptionLabel={(option) => option}
                                    options={availableSectionOptions}
                                    fullWidth
                                    // freeSolo// allow user to enter new option
                                    onChange={(event, newValue) => {
                                       // check if user entered a new option
                                       const newOption = newValue?.trim();
                                       const options = availableSectionOptions.includes(newOption)
                                          ? availableSectionOptions
                                          : [...availableSectionOptions, newOption];
                                       updateAvailableSectionOptions(options);
                                       formValChangeWithParentElementWithNameAndValue("outletSections", newValue, printer, setPrinter);
                                    }}
                                    renderInput={(params) => (
                                       <TextField
                                          {...params}
                                          type="text"
                                             onChange={(event) => {
                                                const newOption = event.target.value.trim();
                                                const options = availableSectionOptions.includes(newOption)
                                                   ? availableSectionOptions
                                                   : [...availableSectionOptions, newOption];
                                                updateAvailableSectionOptions(options);
                                                formValChangeWithParentElementWithNameAndValue("outletSection", event.target.value, printer, setPrinter);
                                       }}
                                       />
                                    )}
                                 /> */}
                                            <CustomObjectSelect
                                                name="outletSections"
                                                required={true}
                                                isMultiple={true}
                                                value={printer.outletSections}
                                                options={availableSectionOptions}
                                                onChange={handleSectionChange}
                                                field="name"
                                                idField="id"
                                                onDelete={(event, idToDelete) => removeId(event, idToDelete, "outletSections")}
                                            />
                                        </Grid>

                                        <Grid item xs={2} md={2}>
                                            <CustomIconButton
                                                id="btn-all-outlet-sections"
                                                variant="outlined"
                                                keyValue="all"
                                                onClick={() => formValChangeWithParentElementWithNameAndValue("outletSections", availableSectionOptions?.map((section) => section.id), printer, setPrinter)}
                                                label={t('actions.all')}
                                                sx={printerSectionButtonAll()} />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <InputLabel required={false} htmlFor="includedEquipmentIds">{t('printer.includedEquipmentIds')}</InputLabel>
                                    <CustomSelectObjectWithChipAndIcon
                                        name="includedEquipmentIds"
                                        required={true}
                                        defaultValue={printer.includedEquipmentIds}
                                        labelID="includedEquipmentIds"
                                        options={equipmentOptions.filter((equipment) => {
                                            const equipmentId = equipment.id;
                                            const isEquipmentInSection = printer?.outletSections?.some((sectionId) => {
                                                const section = availableSectionOptions?.find((section) => section.id === sectionId);
                                                if (section) {
                                                    return section.equipments.includes(equipmentId);
                                                }
                                                return false;
                                            });

                                            return !isEquipmentInSection;
                                        })}
                                        onChange={handleIncludedEquipmentsChange}
                                        field="label"
                                        onDelete={(event, idToDelete) => removeId(event, idToDelete, "includedEquipmentIds")}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <InputLabel required={false} htmlFor="excludedEquipmentIds">{t('printer.excludedEquipmentIds')}</InputLabel>
                                    <CustomSelectObjectWithChipAndIcon
                                        name="excludedEquipmentIds"
                                        required={true}
                                        defaultValue={printer.excludedEquipmentIds}
                                        labelID="excludedEquipmentIds"
                                        options={equipmentOptions.filter((equipment) => {
                                            const equipmentId = equipment.id;
                                            const isEquipmentInSection = printer.outletSections?.some((sectionId) => {
                                                const section = availableSectionOptions?.find((section) => section.id === sectionId);
                                                if (section) {
                                                    return section.equipments.includes(equipmentId);
                                                }
                                                return false;
                                            });

                                            return isEquipmentInSection;
                                        })}
                                        disabled={isExcludedEquipmentsDisabled}
                                        onChange={handleExcludedEquipmentsChange}
                                        field="label"
                                        onDelete={(event, idToDelete) => removeId(event, idToDelete, "excludedEquipmentIds")}
                                    />
                                </Grid>

                                {/* <Stack direction="column" sx={{ paddingBottom: "10px" }}>
                                    <InputLabel required={false} htmlFor="employees">{t('printer.employees')}</InputLabel>
                                    <CustomSelectObjectWithChipAndIcon
                                        name="employeeIds"
                                        required={true}
                                        defaultValue={printer.employeeIds}
                                        labelID="employees"
                                        options={employeeOptions}
                                        onChange={(event) => formValChangeWithParentElementWithNameAndValue("employeeIds", event.target.value, printer, setPrinter)}
                                        field="name"
                                        onDelete={(event, idToDelete) => removeId(event, idToDelete, "employeeIds")}
                                    />
                                    </Stack> */}

                                <Grid item xs={12}>
                                    <InputLabel required={false} htmlFor="itemCategories">{t('itemCategories.label')}</InputLabel>
                                    <CustomSelectMultilingual
                                        id="itemCategories"
                                        isMultiple={true}
                                        name="itemCategories"
                                        translationPath="itemCategories"
                                        required={true}
                                        value={printer.itemCategories}
                                        options={itemCategories}
                                        onChange={(event) => formValChangeWithParentElementWithNameAndValue("itemCategories", event.target.value, printer, setPrinter)}
                                    />
                                </Grid>
                            </Grid>
                        </TabPanel>

                        <TabPanel value={"ticketConfiguration"} sx={{ paddingTop: "0px" }}>
                            <TabContext value={ticketTabValue}>
                                <CustomTabs
                                    initValue={ticketTabValue}
                                    instances={printNotificationActions.map((action) => ({ name: t(`printer.tabs.ticketConfiguration.${action}`), value: action }))}
                                    setValue={setTicketTabValue}
                                    scrollButtons={true}
                                    sx={{ marginBottom: "20px", margin: 0 }}
                                    centered={false}
                                />

                                {printNotificationActions.map((action) => (
                                    <>
                                        {action.includes("ORDER") &&
                                            <TabPanel value={action} sx={{ paddingTop: "0px" }}>
                                                <Grid container spacing={2} sx={{ marginTop: '5px' }}>
                                                    {printer.tickets.find((ticket) => ticket.action === action) && (
                                                        <Grid item xs={12} md={12}>
                                                            {printer.tickets?.find((ticket) => ticket.action === action).showFields.map((ticketConfigOfAction, actionInstanceIndex) => (
                                                                <>
                                                                    <Grid container spacing={2} sx={ticketGridStyle()}>

                                                                        <Grid item xs={12} md={12} sx={deleteButtonTicketStyle()}>
                                                                            <CustomIconButton
                                                                                keyValue={`btn-delete-${action}-${actionInstanceIndex}`}
                                                                                onClick={(event) => handleRemovalTicketConfigurationForAction(action, actionInstanceIndex)}
                                                                                icon={<DeleteIcon style={cardItemButtonGroupStackButtonIcon()} />}
                                                                                // className="icon-wrapper tr link"
                                                                                iconSx={{ marginRight: "0px!important" }}
                                                                                sx={{
                                                                                    backgroundColor: "red",
                                                                                    ":hover": {
                                                                                        backgroundColor: "darkred",
                                                                                    },
                                                                                    ...cardItemButtonGroupStackButton(),
                                                                                    marginTop: "-30px"
                                                                                }}
                                                                            >
                                                                                {t('actions.testPrint')}
                                                                            </CustomIconButton>
                                                                            <CustomIconButton
                                                                                keyValue={`btn-testPrint-${action}-${actionInstanceIndex}`}
                                                                                onClick={(event) => sendTestPrint(printNotificationActions, ticketConfigOfAction, action, actionInstanceIndex)}
                                                                                icon={<PrintIcon style={cardItemButtonGroupStackButtonIcon()} />}
                                                                                iconSx={{ marginRight: "0px!important" }}
                                                                                sx={{
                                                                                    backgroundColor: "purple",
                                                                                    ":hover": {
                                                                                        backgroundColor: "darkred",
                                                                                    },
                                                                                    ...cardItemButtonGroupStackButton(),
                                                                                    marginTop: "-30px"
                                                                                }}
                                                                            >
                                                                                {t('actions.testPrint')}
                                                                            </CustomIconButton>
                                                                        </Grid>

                                                                        <Grid item xs={12} md={12}>
                                                                            <InputLabel required={false} htmlFor="startText">{t('printer.tabs.ticketConfiguration.startText')}</InputLabel>
                                                                            <CustomTextField
                                                                                type="text"
                                                                                name="startText"
                                                                                id="startText"
                                                                                defaultValue={ticketConfigOfAction.startText}
                                                                                onChange={(event) => handleTicketFieldChange(action, actionInstanceIndex, "startText", event.target.value)}
                                                                            />
                                                                        </Grid>

                                                                        <Grid item xs={12} md={12}>
                                                                            <InputLabel required={false} htmlFor="endText">{t('printer.tabs.ticketConfiguration.endText')}</InputLabel>
                                                                            <CustomTextField
                                                                                type="text"
                                                                                name="endText"
                                                                                id="endText"
                                                                                defaultValue={ticketConfigOfAction.endText}
                                                                                onChange={(event) => handleTicketFieldChange(action, actionInstanceIndex, "endText", event.target.value)}
                                                                            />
                                                                        </Grid>

                                                                        <Grid item xs={12} md={6} sx={{ marginBottom: "10px" }}>
                                                                            <CustomTextField
                                                                                type="number"
                                                                                step="1"
                                                                                min={0}
                                                                                max={9}
                                                                                name="replicas"
                                                                                id="replicas"
                                                                                variant="filled"
                                                                                inputAdornmentLabel="printer.tabs.ticketConfiguration.replicas"
                                                                                required={false}
                                                                                value={ticketConfigOfAction.replicas}
                                                                                onChange={(event) => { let value = validateInputNumber(event.target.value, 1); if (value === undefined) value = printer.tickets.find((ticket) => ticket.action === action).showFields[actionInstanceIndex].replicas; handleTicketFieldChange(action, actionInstanceIndex, "replicas", value) }}
                                                                                sx={{ border: "none", paddingTop: "25px" }}
                                                                                onKeyDown={handleKeyDownNumberTextField}
                                                                            />
                                                                        </Grid>

                                                                        <Grid item xs={12} md={6}>
                                                                            <InputLabel required={true} htmlFor="lang">{t('languages.label')}</InputLabel>
                                                                            <CustomSelectMultilingual
                                                                                id="lang"
                                                                                translationPath="languages"
                                                                                name="lang"
                                                                                required={true}
                                                                                value={ticketConfigOfAction.lang}
                                                                                options={data.langOptions}
                                                                                onChange={(event) => handleTicketFieldChange(action, actionInstanceIndex, "lang", event.target.value)}
                                                                            />
                                                                        </Grid>

                                                                        <Grid item xs={12} md={12} sx={{ marginTop: "5px" }}>
                                                                            <Box component="fieldset" sx={mainTagSearchItemOrderBox()}>
                                                                                <legend style={mainTagSearchItemOrderBoxLegend()}>{t('printer.tabs.ticketConfiguration.field.labels')}</legend>
                                                                                <Grid container spacing={3} sx={{ ...mainTagSearchItemOrderContainer(), margin: "5px" }}>
                                                                                    {data.orderTicketFieldOptions.map((field, index) =>
                                                                                        <Grid item xs={12} md={6} sx={{ paddingTop: "0!important" }}>
                                                                                            <CustomCheckbox
                                                                                                name={`ticket[${index}].showFields[${field}]`}
                                                                                                onChange={(event) => handleTicketCheckboxFieldChange(action, actionInstanceIndex, field, event.target.checked)}
                                                                                                selected={ticketConfigOfAction.showFields.includes(field)}
                                                                                                label={t(`printer.tabs.ticketConfiguration.field.${field}`)}
                                                                                            />
                                                                                        </Grid>
                                                                                    )}
                                                                                </Grid>
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                </>
                                                            ))}
                                                        </Grid>
                                                    )}

                                                    <Grid item xs={12} md={12} sx={{ marginBottom: "10px" }}>
                                                        <CustomIconButton
                                                            sx={addCardActionAreaButtonStyle()}
                                                            backgroundColor="transparent"
                                                            icon={<AddIcon sx={addWorkingHoursButtonIconStyle()} />}
                                                            onClick={() => handleAddTicketConfigurationForAction(action)}>
                                                            {t('actions.add')}
                                                        </CustomIconButton>
                                                    </Grid>
                                                </Grid>
                                                <Divider />
                                            </TabPanel>
                                        }

                                        {action.includes("REQUEST") &&
                                            <TabPanel value={action} sx={{ paddingTop: "0px" }}>
                                                <Grid container spacing={2} sx={{ marginTop: '5px' }}>
                                                    {printer.tickets.find((ticket) => ticket.action === action) && (
                                                        <Grid item xs={12} md={12}>
                                                            {printer.tickets.find((ticket) => ticket.action === action).showFields.map((ticketConfigOfAction, actionInstanceIndex) => (
                                                                <>
                                                                    <Grid container spacing={2} sx={ticketGridStyle()}>
                                                                        <Grid item xs={12} md={12} sx={deleteButtonTicketStyle()}>
                                                                            <CustomIconButton
                                                                                keyValue={`btn-delete-${action}-${actionInstanceIndex}`}
                                                                                onClick={(event) => handleRemovalTicketConfigurationForAction(action, actionInstanceIndex)}
                                                                                icon={<DeleteIcon style={cardItemButtonGroupStackButtonIcon()} />}
                                                                                // className="icon-wrapper tr link"
                                                                                iconSx={{ marginRight: "0px!important" }}
                                                                                sx={{
                                                                                    backgroundColor: "red",
                                                                                    ":hover": {
                                                                                        backgroundColor: "darkred",
                                                                                    },
                                                                                    ...cardItemButtonGroupStackButton(),
                                                                                    marginTop: "-30px"
                                                                                }}
                                                                            >
                                                                                {t('actions.delete')}
                                                                            </CustomIconButton>
                                                                        </Grid>

                                                                        <Grid item xs={12} md={12}>
                                                                            <InputLabel required={false} htmlFor="startText">{t('printer.tabs.ticketConfiguration.startText')}</InputLabel>
                                                                            <CustomTextField
                                                                                type="text"
                                                                                name="startText"
                                                                                id="startText"
                                                                                defaultValue={ticketConfigOfAction.startText}
                                                                                onChange={(event) => handleTicketFieldChange(action, actionInstanceIndex, "startText", event.target.value)}
                                                                            />
                                                                        </Grid>

                                                                        <Grid item xs={12} md={12}>
                                                                            <InputLabel required={false} htmlFor="endText">{t('printer.tabs.ticketConfiguration.endText')}</InputLabel>
                                                                            <CustomTextField
                                                                                type="text"
                                                                                name="endText"
                                                                                id="endText"
                                                                                defaultValue={ticketConfigOfAction.endText}
                                                                                onChange={(event) => handleTicketFieldChange(action, actionInstanceIndex, "endText", event.target.value)}
                                                                            />
                                                                        </Grid>

                                                                        <Grid item xs={12} md={6} sx={{ marginBottom: "10px" }}>
                                                                            <CustomTextField
                                                                                type="number"
                                                                                step="1"
                                                                                min={0}
                                                                                max={9}
                                                                                name="replicas"
                                                                                id="replicas"
                                                                                variant="filled"
                                                                                inputAdornmentLabel="printer.tabs.ticketConfiguration.replicas"
                                                                                required={false}
                                                                                value={ticketConfigOfAction.replicas}
                                                                                onChange={(event) => { let value = validateInputNumber(event.target.value, 1); if (value === undefined) value = printer.tickets.find((ticket) => ticket.action === action).showFields[actionInstanceIndex].replicas; handleTicketFieldChange(action, actionInstanceIndex, "replicas", value) }}
                                                                                sx={{ border: "none", paddingTop: "25px" }}
                                                                                onKeyDown={handleKeyDownNumberTextField}
                                                                            />
                                                                        </Grid>

                                                                        <Grid item xs={12} md={6}>
                                                                            <InputLabel required={true} htmlFor="lang">{t('languages.label')}</InputLabel>
                                                                            <CustomSelectMultilingual
                                                                                id="lang"
                                                                                translationPath="languages"
                                                                                name="lang"
                                                                                required={true}
                                                                                value={ticketConfigOfAction.lang}
                                                                                options={data.langOptions}
                                                                                onChange={(event) => handleTicketFieldChange(action, actionInstanceIndex, "lang", event.target.value)}
                                                                            />
                                                                        </Grid>

                                                                        <Grid item xs={12} md={12} sx={{ marginTop: "5px" }}>
                                                                            <Box component="fieldset" sx={mainTagSearchItemOrderBox()}>
                                                                                <legend style={mainTagSearchItemOrderBoxLegend()}>{t('printer.tabs.ticketConfiguration.field.labels')}</legend>
                                                                                <Grid container spacing={3} sx={{ ...mainTagSearchItemOrderContainer(), margin: "5px" }}>
                                                                                    {data.requestTicketFieldOptions?.map((field, index) =>
                                                                                        <Grid item xs={12} md={6} sx={{ paddingTop: "0!important" }}>
                                                                                            <CustomCheckbox
                                                                                                name={`ticket[${index}].showFields[${field}]`}
                                                                                                onChange={(event) => handleTicketCheckboxFieldChange(action, actionInstanceIndex, field, event.target.checked)}
                                                                                                selected={ticketConfigOfAction.showFields.includes(field)}
                                                                                                label={t(`printer.tabs.ticketConfiguration.field.${field}`)}
                                                                                            />
                                                                                        </Grid>
                                                                                    )}
                                                                                </Grid>
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                </>
                                                            ))}
                                                        </Grid>
                                                    )}

                                                    <Grid item xs={12} md={12} sx={{ marginBottom: "10px" }}>
                                                        <CustomIconButton
                                                            sx={addCardActionAreaButtonStyle()}
                                                            backgroundColor="transparent"
                                                            icon={<AddIcon sx={addWorkingHoursButtonIconStyle()} />}
                                                            onClick={() => handleAddTicketConfigurationForAction(action)}>
                                                            {t('actions.add')}
                                                        </CustomIconButton>
                                                    </Grid>
                                                </Grid>
                                                <Divider />
                                            </TabPanel>
                                        }

                                        {action.includes("RECEIPT") &&
                                            <TabPanel value={action} sx={{ paddingTop: "0px" }}>
                                                <Grid container spacing={2} sx={{ marginTop: '5px' }}>
                                                    {printer.tickets.find((ticket) => ticket.action === action) && (
                                                        <Grid item xs={12} md={12}>
                                                            {printer.tickets.find((ticket) => ticket.action === action).showFields.map((ticketConfigOfAction, actionInstanceIndex) => (
                                                                <>
                                                                    <Grid container spacing={2} sx={ticketGridStyle()}>
                                                                        <Grid item xs={12} md={12} sx={deleteButtonTicketStyle()}>
                                                                            <CustomIconButton
                                                                                keyValue={`btn-delete-${action}-${actionInstanceIndex}`}
                                                                                onClick={(event) => handleRemovalTicketConfigurationForAction(action, actionInstanceIndex)}
                                                                                icon={<DeleteIcon style={cardItemButtonGroupStackButtonIcon()} />}
                                                                                // className="icon-wrapper tr link"
                                                                                iconSx={{ marginRight: "0px!important" }}
                                                                                sx={{
                                                                                    backgroundColor: "red",
                                                                                    ":hover": {
                                                                                        backgroundColor: "darkred",
                                                                                    },
                                                                                    ...cardItemButtonGroupStackButton(),
                                                                                    marginTop: "-30px"
                                                                                }}
                                                                            >
                                                                                {t('actions.delete')}
                                                                            </CustomIconButton>
                                                                        </Grid>

                                                                        <Grid item xs={12} md={12}>
                                                                            <InputLabel required={false} htmlFor="startText">{t('printer.tabs.ticketConfiguration.startText')}</InputLabel>
                                                                            <CustomTextField
                                                                                type="text"
                                                                                name="startText"
                                                                                id="startText"
                                                                                defaultValue={ticketConfigOfAction.startText}
                                                                                onChange={(event) => handleTicketFieldChange(action, actionInstanceIndex, "startText", event.target.value)}
                                                                            />
                                                                        </Grid>

                                                                        <Grid item xs={12} md={12}>
                                                                            <InputLabel required={false} htmlFor="endText">{t('printer.tabs.ticketConfiguration.endText')}</InputLabel>
                                                                            <CustomTextField
                                                                                type="text"
                                                                                name="endText"
                                                                                id="endText"
                                                                                defaultValue={ticketConfigOfAction.endText}
                                                                                onChange={(event) => handleTicketFieldChange(action, actionInstanceIndex, "endText", event.target.value)}
                                                                            />
                                                                        </Grid>

                                                                        <Grid item xs={12} md={6} sx={{ marginBottom: "10px" }}>
                                                                            <CustomTextField
                                                                                type="number"
                                                                                step="1"
                                                                                min={0}
                                                                                max={9}
                                                                                name="replicas"
                                                                                id="replicas"
                                                                                variant="filled"
                                                                                inputAdornmentLabel="printer.tabs.ticketConfiguration.replicas"
                                                                                required={false}
                                                                                value={ticketConfigOfAction.replicas}
                                                                                onChange={(event) => { let value = validateInputNumber(event.target.value, 1); if (value === undefined) value = printer.tickets.find((ticket) => ticket.action === action).showFields[actionInstanceIndex].replicas; handleTicketFieldChange(action, actionInstanceIndex, "replicas", value) }}
                                                                                sx={{ border: "none", paddingTop: "25px" }}
                                                                                onKeyDown={handleKeyDownNumberTextField}
                                                                            />
                                                                        </Grid>

                                                                        <Grid item xs={12} md={6}>
                                                                            <InputLabel required={true} htmlFor="lang">{t('languages.label')}</InputLabel>
                                                                            <CustomSelectMultilingual
                                                                                id="lang"
                                                                                translationPath="languages"
                                                                                name="lang"
                                                                                required={true}
                                                                                value={ticketConfigOfAction.lang}
                                                                                options={data.langOptions}
                                                                                onChange={(event) => handleTicketFieldChange(action, actionInstanceIndex, "lang", event.target.value)}
                                                                            />
                                                                        </Grid>

                                                                        <Grid item xs={12} md={12} sx={{ marginTop: "5px" }}>
                                                                            <Box component="fieldset" sx={mainTagSearchItemOrderBox()}>
                                                                                <legend style={mainTagSearchItemOrderBoxLegend()}>{t('printer.tabs.ticketConfiguration.field.labels')}</legend>
                                                                                <Grid container spacing={3} sx={{ ...mainTagSearchItemOrderContainer(), margin: "5px" }}>
                                                                                    {data.receiptTicketFieldOptions?.map((field, index) =>
                                                                                        <Grid item xs={12} md={6} sx={{ paddingTop: "0!important" }}>
                                                                                            <CustomCheckbox
                                                                                                name={`ticket[${index}].showFields[${field}]`}
                                                                                                onChange={(event) => handleTicketCheckboxFieldChange(action, actionInstanceIndex, field, event.target.checked)}
                                                                                                selected={ticketConfigOfAction.showFields.includes(field)}
                                                                                                label={t(`printer.tabs.ticketConfiguration.field.${field}`)}
                                                                                            />
                                                                                        </Grid>
                                                                                    )}
                                                                                </Grid>
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                </>
                                                            ))}
                                                        </Grid>
                                                    )}

                                                    <Grid item xs={12} md={12} sx={{ marginBottom: "10px" }}>
                                                        <CustomIconButton
                                                            sx={addCardActionAreaButtonStyle()}
                                                            backgroundColor="transparent"
                                                            icon={<AddIcon sx={addWorkingHoursButtonIconStyle()} />}
                                                            onClick={() => handleAddTicketConfigurationForAction(action)}>
                                                            {t('actions.add')}
                                                        </CustomIconButton>
                                                    </Grid>
                                                </Grid>
                                                <Divider />
                                            </TabPanel>
                                        }

                                        {action.includes("WAITLIST") &&
                                            <TabPanel value={action} sx={{ paddingTop: "0px" }}>
                                                <Grid container spacing={2} sx={{ marginTop: '5px' }}>
                                                    {printer.tickets.find((ticket) => ticket.action === action) && (
                                                        <Grid item xs={12} md={12}>
                                                            {printer.tickets.find((ticket) => ticket.action === action).showFields.map((ticketConfigOfAction, actionInstanceIndex) => (
                                                                <>
                                                                    <Grid container spacing={2} sx={ticketGridStyle()}>
                                                                        <Grid item xs={12} md={12} sx={deleteButtonTicketStyle()}>
                                                                            <CustomIconButton
                                                                                keyValue={`btn-delete-${action}-${actionInstanceIndex}`}
                                                                                onClick={(event) => handleRemovalTicketConfigurationForAction(action, actionInstanceIndex)}
                                                                                icon={<DeleteIcon style={cardItemButtonGroupStackButtonIcon()} />}
                                                                                // className="icon-wrapper tr link"
                                                                                iconSx={{ marginRight: "0px!important" }}
                                                                                sx={{
                                                                                    backgroundColor: "red",
                                                                                    ":hover": {
                                                                                        backgroundColor: "darkred",
                                                                                    },
                                                                                    ...cardItemButtonGroupStackButton(),
                                                                                    marginTop: "-30px"
                                                                                }}
                                                                            >
                                                                                {t('actions.delete')}
                                                                            </CustomIconButton>
                                                                        </Grid>

                                                                        <Grid item xs={12} md={12}>
                                                                            <InputLabel required={false} htmlFor="startText">{t('printer.tabs.ticketConfiguration.startText')}</InputLabel>
                                                                            <CustomTextField
                                                                                type="text"
                                                                                name="startText"
                                                                                id="startText"
                                                                                defaultValue={ticketConfigOfAction.startText}
                                                                                onChange={(event) => handleTicketFieldChange(action, actionInstanceIndex, "startText", event.target.value)}
                                                                            />
                                                                        </Grid>

                                                                        <Grid item xs={12} md={12}>
                                                                            <InputLabel required={false} htmlFor="endText">{t('printer.tabs.ticketConfiguration.endText')}</InputLabel>
                                                                            <CustomTextField
                                                                                type="text"
                                                                                name="endText"
                                                                                id="endText"
                                                                                defaultValue={ticketConfigOfAction.endText}
                                                                                onChange={(event) => handleTicketFieldChange(action, actionInstanceIndex, "endText", event.target.value)}
                                                                            />
                                                                        </Grid>

                                                                        <Grid item xs={12} md={6} sx={{ marginBottom: "10px" }}>
                                                                            <CustomTextField
                                                                                type="number"
                                                                                step="1"
                                                                                min={0}
                                                                                max={9}
                                                                                name="replicas"
                                                                                id="replicas"
                                                                                variant="filled"
                                                                                inputAdornmentLabel="printer.tabs.ticketConfiguration.replicas"
                                                                                required={false}
                                                                                value={ticketConfigOfAction.replicas}
                                                                                onChange={(event) => { let value = validateInputNumber(event.target.value, 1); if (value === undefined) value = printer.tickets.find((ticket) => ticket.action === action).showFields[actionInstanceIndex].replicas; handleTicketFieldChange(action, actionInstanceIndex, "replicas", value) }}
                                                                                sx={{ border: "none", paddingTop: "25px" }}
                                                                                onKeyDown={handleKeyDownNumberTextField}
                                                                            />
                                                                        </Grid>

                                                                        <Grid item xs={12} md={6}>
                                                                            <InputLabel required={true} htmlFor="lang">{t('languages.label')}</InputLabel>
                                                                            <CustomSelectMultilingual
                                                                                id="lang"
                                                                                translationPath="languages"
                                                                                name="lang"
                                                                                required={true}
                                                                                value={ticketConfigOfAction.lang}
                                                                                options={data.langOptions}
                                                                                onChange={(event) => handleTicketFieldChange(action, actionInstanceIndex, "lang", event.target.value)}
                                                                            />
                                                                        </Grid>

                                                                        <Grid item xs={12} md={12} sx={{ marginTop: "5px" }}>
                                                                            <Box component="fieldset" sx={mainTagSearchItemOrderBox()}>
                                                                                <legend style={mainTagSearchItemOrderBoxLegend()}>{t('printer.tabs.ticketConfiguration.field.labels')}</legend>
                                                                                <Grid container spacing={3} sx={{ ...mainTagSearchItemOrderContainer(), margin: "5px" }}>
                                                                                    {data.waitlistTicketFieldOptions?.map((field, index) =>
                                                                                        <Grid item xs={12} md={6} sx={{ paddingTop: "0!important" }}>
                                                                                            <CustomCheckbox
                                                                                                name={`ticket[${index}].showFields[${field}]`}
                                                                                                onChange={(event) => handleTicketCheckboxFieldChange(action, actionInstanceIndex, field, event.target.checked)}
                                                                                                selected={ticketConfigOfAction.showFields.includes(field)}
                                                                                                label={t(`printer.tabs.ticketConfiguration.field.${field}`)}
                                                                                            />
                                                                                        </Grid>
                                                                                    )}
                                                                                </Grid>
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                </>
                                                            ))}
                                                        </Grid>
                                                    )}

                                                    <Grid item xs={12} md={12} sx={{ marginBottom: "10px" }}>
                                                        <CustomIconButton
                                                            sx={addCardActionAreaButtonStyle()}
                                                            backgroundColor="transparent"
                                                            icon={<AddIcon sx={addWorkingHoursButtonIconStyle()} />}
                                                            onClick={() => handleAddTicketConfigurationForAction(action)}>
                                                            {t('actions.add')}
                                                        </CustomIconButton>
                                                    </Grid>
                                                </Grid>
                                                <Divider />
                                            </TabPanel>
                                        }
                                    </>
                                ))}
                            </TabContext>
                        </TabPanel>


                        <TabPanel value={"style"} sx={{ paddingTop: "0px" }}>
                            <Grid container gap={1}>
                                <Grid item xs={5.5}>
                                    <InputLabel>{t('printer.tabs.style.baseFontSize')}</InputLabel>
                                    <CustomSelect
                                        name="baseFontSize"
                                        required={true}
                                        value={printer.baseFontSize}
                                        options={data.fontSizes}
                                        onChange={(event) => handleFieldChange(event, "baseFontSize")}
                                    />
                                </Grid>

                                <Grid item xs={5.5}>
                                    <InputLabel>{t('printer.tabs.style.primaryFontSize')}</InputLabel>
                                    <CustomSelect
                                        name="primaryFontSize"
                                        required={true}
                                        value={printer.primaryFontSize}
                                        options={data.fontSizes}
                                        onChange={(event) => handleFieldChange(event, "primaryFontSize")}
                                    />
                                </Grid>
                                <Grid item xs={5.5}>
                                    <InputLabel>{t('printer.tabs.style.secondaryFontSize')}</InputLabel>
                                    <CustomSelect
                                        name="secondaryFontSize"
                                        required={true}
                                        value={printer.secondaryFontSize}
                                        options={data.fontSizes}
                                        onChange={(event) => handleFieldChange(event, "secondaryFontSize")}
                                    />
                                </Grid>
                                <Grid item xs={5.5}>
                                    <InputLabel>{t('printer.tabs.style.tableFontSize')}</InputLabel>
                                    <CustomSelect
                                        name="tableFontSize"
                                        required={true}
                                        value={printer.tableFontSize}
                                        options={data.fontSizes}
                                        onChange={(event) => handleFieldChange(event, "tableFontSize")}
                                    />
                                </Grid>
                                <Grid item xs={5.5}>
                                    <InputLabel>{t('printer.tabs.style.tableNoteFontSize')}</InputLabel>
                                    <CustomSelect
                                        name="tableNoteFontSize"
                                        required={true}
                                        value={printer.tableNoteFontSize}
                                        options={data.fontSizes}
                                        onChange={(event) => handleFieldChange(event, "tableNoteFontSize")}
                                    />
                                </Grid>
                            </Grid>
                        </TabPanel>
                    </TabContext>
                </DialogContent>

                <DialogActions className="dialog-actions">
                    <Button className="dialog-cancel-btn" onClick={() => { handleDialogState(false) }}>{t("actions.cancel")}</Button>
                    <Button className="dialog-action-btn" onClick={() => { handleAction() }}>{isAdd ? t('actions.create') : t('actions.save')}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default AddEditPrinterDialog;