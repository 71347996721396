import { createTheme, ThemeProvider } from '@mui/material';
import { MuiChipsInput } from 'mui-chips-input';

/**
 * The Custom Chip Email Input, that display customized {@link MuiChipsInput}.
 * @property {string} label the button text value
 * @property {boolean} shownLabel if the label should be shown
 * @property {string} color the control button color
 * @property {object} sx the
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomChipEmailInput({ id, value, field, required = false, className = "", label = "", color = "default", rowIndex, onChange, sx = {
    background: "#dcf1f7",
    color: "#54b1d2",
    margin: "5px",
} }) {

    function getMuiTheme(sx) {
        return createTheme({
            components: {
                MuiChip: {
                    styleOverrides: {
                        root: { ...sx }
                    }
                },
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            width: "100%"
                        }
                    }
                }
            }
        })
    }
    console.log(value)

    return (
        <ThemeProvider key={label + "-theme-" + rowIndex} theme={getMuiTheme(sx)}>
            <MuiChipsInput value={value?.map(obj => obj.email)} required={required} onChange={(event) => onChange(event, field)} label={label} sx={{ paddingRight: "20px", paddingBottom: "10px" }} />
        </ThemeProvider>
    );
}

export default CustomChipEmailInput;