import CustomChip from "components/Chip/CustomChip";
import CustomTypography from "components/Typography/CustomTypography";
import { replaceLastTwoChars } from "./functions";

export function getItems(menus, filterBy) {
    // Initialize an empty array to hold all matching items
    let items = [];

    // Iterate over each menu in the menus array
    menus.forEach(menu => {
        // Iterate over each parentCategory in the current menu
        menu.parentCategories.forEach(parentCategory => {
            // Iterate over each category in the current parentCategory
            parentCategory.categories.forEach(category => {
                // If filterBy is provided and not empty, filter the items by name
                if (filterBy && filterBy?.trim() !== '') {
                    // Convert filterBy to lowercase for case-insensitive comparison
                    const filterByLower = filterBy.toLowerCase();
                    // Filter items where the name matches filterBy, ignoring case, and add them to the items array
                    items = items.concat(category?.items?.filter(item => item.nameEN.toLowerCase().includes(filterByLower)));
                } else {
                    // If filterBy is not provided, add all items to the items array
                    items = items.concat(category.items);
                }
            });
        });
    });

    // Return the final array of items
    return items;
}


export function prepareText(cartItem, viewdModifierList) {
    let modifierListText = '';
    modifierListText = cartItem.modifierListIds?.find((modifierList) => modifierList.modifierListId === viewdModifierList?.id)?.modifiers?.map((modifier) => modifier.name);
    let modifiersText = modifierListText?.slice(0, -1)?.join(', ') + (modifierListText?.length > 1 ? ', ' : '') + modifierListText?.slice(-1);
    if (modifiersText === "" || modifiersText === null) {
        modifiersText = prepareTextChip(modifiersText, viewdModifierList);
    }
    return modifiersText;
}

export function prepareTextChip(t, menuConfiguration, modifiersText, viewdModifierList) {
    let chipText = t('item.requiredChoice');
    if (!viewdModifierList.allowMultipleSelection)
        chipText = t('item.requiredChoice');
    else if (viewdModifierList.allowMultipleSelection && viewdModifierList.minModifiers > 0)
        chipText = t('item.requiredChoice');
    else if (viewdModifierList.allowMultipleSelection && (viewdModifierList.minModifiers === 0 || viewdModifierList.minModifiers === null))
        chipText = t('item.optionalChoice');
    modifiersText = <>
        <CustomChip
            label={chipText}
            sx={{
                background: replaceLastTwoChars(menuConfiguration?.chipTextColor, "1c"),
                color: menuConfiguration?.chipTextColor ? menuConfiguration.chipTextColor : "47a6d21",
                fontSize: `calc(${menuConfiguration?.itemHeaderFontSize} - 6px)`,
                fontWeight: "bold",
                padding: "2px 5px"
            }} />
        {/* {chipText === t('item.requiredChoice') &&
            <CustomChip
                label={
                    <>
                        <CustomTypography
                            variant="span"
                            text={`${t('item.min')}: `}
                            sx={{ fontWeight: "bold", fontSize: `calc(${menuConfiguration?.itemHeaderFontSize} - 6px)` }}
                        />
                        <CustomTypography
                            variant="span"
                            text={viewdModifierList?.minModifiers !== null ? viewdModifierList.minModifiers : 0}
                            sx={{ fontSize: `calc(${menuConfiguration?.itemHeaderFontSize} - 6px)` }}
                        />
                    </>
                }
                sx={{
                    background: replaceLastTwoChars(menuConfiguration.chipTextColor, "1c"),
                    color: menuConfiguration?.chipTextColor ? menuConfiguration.chipTextColor : "47a6d21"
                }}
            />
        } */}
        {viewdModifierList.allowMultipleSelection && viewdModifierList?.maxModifiers > 0 &&
            <CustomChip
                label={
                    <>
                        <CustomTypography
                            variant="span"
                            text={`${t('item.max')}: `}
                            sx={{ fontWeight: "bold", fontSize: `calc(${menuConfiguration?.itemHeaderFontSize} - 6px)` }}
                        />
                        <CustomTypography
                            variant="span"
                            text={viewdModifierList?.maxModifiers !== null ? viewdModifierList.maxModifiers : 0}
                            sx={{ fontSize: `calc(${menuConfiguration?.itemHeaderFontSize} - 6px)` }}
                        />
                    </>
                }
                sx={{
                    background: replaceLastTwoChars(menuConfiguration?.chipTextColor, "1c"),
                    color: menuConfiguration?.chipTextColor ? menuConfiguration.chipTextColor : "47a6d21"
                }}
            />
        }
    </>
    return modifiersText;
}

export function handleMultipleSelectionChange(modifierListId, modifierId, modifiers, item, setCartItem) {
    setCartItem(prevState => {
        let prevModifierPrice = 0;
        let newModifierPrice = modifiers.find(modifier => modifier.id === modifierId).price;

        // Find the previous modifier price
        const currentModifierList = prevState?.modifierListIds?.find(ml => ml.modifierListId === modifierListId);
        if (currentModifierList) {
            currentModifierList.modifiers.forEach(modifier => {
                if (modifier.id === modifierId) {
                    prevModifierPrice = modifier.price;
                }
            });
        }

        // Update the state with the new modifier
        const newModifierListIds = prevState?.modifierListIds?.map(modifierList => {
            if (modifierList.modifierListId === modifierListId) {
                const isExistingModifier = modifierList.modifiers.some(mod => mod.id === modifierId);

                // Check if maxModifiers is 1 and a new modifier is selected
                if (modifierList.maxModifiers === 1) {
                    if (isExistingModifier) {
                        // Replace the existing modifier with the new one
                        return {
                            ...modifierList,
                            modifiers: [{
                                id: modifierId,
                                name: modifiers.find(modifier => modifier.id === modifierId).name,
                                nameEn: modifiers.find(modifier => modifier.id === modifierId).nameEn,
                                price: newModifierPrice,
                                maxModifiers: modifierList.maxModifiers,
                                minModifiers: modifierList.minModifiers,
                            }]
                        };
                    } else {
                        // Only keep the new modifier
                        return {
                            ...modifierList,
                            modifiers: [{
                                id: modifierId,
                                name: modifiers.find(modifier => modifier.id === modifierId).name,
                                nameEn: modifiers.find(modifier => modifier.id === modifierId).nameEn,
                                price: newModifierPrice,
                                maxModifiers: modifierList.maxModifiers,
                                minModifiers: modifierList.minModifiers
                            }]
                        };
                    }
                } else {
                    let validateMultipleChoiceRules = (modifierList.modifiers.length + (isExistingModifier ? (-1) : 1)) <= (item.modifierLists.find((modifierListObj) => modifierListObj.id === modifierListId)?.maxModifiers);

                    if (validateMultipleChoiceRules === undefined || validateMultipleChoiceRules) {
                        return {
                            ...modifierList,
                            modifiers: isExistingModifier ?
                                modifierList.modifiers.filter(mod => mod.id !== modifierId) :
                                [...modifierList.modifiers, {
                                    id: modifierId,
                                    name: modifiers.find(modifier => modifier.id === modifierId).name,
                                    nameEn: modifiers.find(modifier => modifier.id === modifierId).nameEn,
                                    price: newModifierPrice,
                                    maxModifiers: modifierList.maxModifiers,
                                    minModifiers: modifierList.minModifiers
                                }]
                        };
                    } else {
                        return modifierList;
                    }
                }
            } else {
                return modifierList;
            }
        });

        // Update total price
        const updatedTotalPrice = prevState.totalPrice - prevModifierPrice + newModifierPrice;
        return {
            ...prevState,
            modifierListIds: newModifierListIds,
            totalPrice: updatedTotalPrice
        };
    });
}

export function handleSingleSelectionChange(modifierListId, modifierId, item, setCartItem) {
    let prevModifierPrice = 0;
    let newModifierPrice = item.modifierLists.find(modifierList => modifierList.id === modifierListId).modifiers.find(modifier => modifier.id === modifierId).price;

    setCartItem(prevState => {
        // Find the previous modifier price
        const currentModifierList = prevState.modifierListIds.find(ml => ml.modifierListId === modifierListId);
        if (currentModifierList && currentModifierList.modifiers.length > 0) {
            const currentModifier = currentModifierList.modifiers[0];
            prevModifierPrice = currentModifier.price;
        }

        // Update the state with the new modifier
        const newModifierListIds = prevState.modifierListIds.map(modifierList =>
            modifierList.modifierListId === modifierListId ? {
                ...modifierList,
                modifiers: [{
                    id: modifierId,
                    name: item.modifierLists.find(modifierList => modifierList.id === modifierListId).modifiers.find(modifier => modifier.id === modifierId).name,
                    nameEn: item.modifierLists.find(modifierList => modifierList.id === modifierListId).modifiers.find(modifier => modifier.id === modifierId).nameEn,
                    price: newModifierPrice,
                }]
            } : modifierList
        );

        // Update total price
        const updatedTotalPrice = prevState.totalPrice - prevModifierPrice + newModifierPrice;

        return {
            ...prevState,
            modifierListIds: newModifierListIds,
            totalPrice: updatedTotalPrice
        };
    });
}

export function transformToFinalFormat(itemsJson = [], includePhotos = true, organizationID = "") {
    return itemsJson?.map((item) => {
        let newItem = item;
        if (includePhotos) {
            newItem.photos = item.photos?.map((photo) =>
                photo
            );
            newItem.modifierLists = newItem.modifierLists.map((modifierList) => ({
                ...modifierList,
                modifiers: modifierList.modifiers.map((modifier) => ({
                    ...modifier,
                    photos: modifier.photos.map((photo) =>
                        photo
                        // !== ""? `${getItemPhoto(organizationID, modifier.id, photo.imageSrc)}` : null
                    ),
                })),
            }));
        } else {
            newItem.photos = null;
            newItem.modifierLists = newItem.modifierLists.map((modifierList) => ({
                ...modifierList,
                modifiers: modifierList.modifiers.map((modifier) => ({
                    ...modifier,
                    photos: null
                })),
            }));
        }
        return newItem;
    });
}

/**
 * Callback that handles the counter increment.
 * 
 * @param {*} counter the old counter value
 */
export function handleIncrement(counter, setCartItem, cartItem) {
    setCartItem({ ...cartItem, quantity: counter + 1 });
}

/**
 * Callback that handles the counter decrement.
 * 
 * @param {*} counter the old counter value
 */
export function handleDecrement(counter, setCartItem, cartItem) {
    setCartItem({ ...cartItem, quantity: counter - 1 });
}
