import { Fab, FormControlLabel, Grid, InputLabel, Switch } from '@mui/material';
import emailCampaignApi from 'api/emailCampaign';
import { SendIcon } from 'assets/mui/MuiIcons';
import CustomAddEditBreadcrumbMultiParent from 'components/Breadcrumb/CustomAddEditBreadcrumbMultiParent';
import CustomDateTimePicker from 'components/DatePicker/CustomDateTimePicker';
import CustomGrid from 'components/Grid/CustomGrid';
import LoadingSkeleton from 'components/Loading/LoadingSkeleton';
import CustomObjectSelect from 'components/Select/CustomObjectSelect';
import CustomListTable from 'components/Table/CustomListTable';
import CustomTextField from 'components/Text/CustomTextField';
import CustomTitleBoldSubtitle from 'components/Title/CustomTitleBoldSubtitle';
import { getDefaultEmailCampaignObject } from 'constants/defaultObjects';
import { getDefaultRecipientForGroupSortDirection } from 'constants/sortDirection';
import { recipient_table_columns_email_campaign } from 'constants/table/recipientTable';
import { useEffect, useRef, useState } from 'react';
import EmailEditor from 'react-email-editor';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { fabStyle, mainArea, mainTag, mainTagBreadcrumb, mainTagSearchItem, mainTagTitle, textFieldError, } from 'themes/defaultThemes';
import { hasRoleAdminOrDirectorOrManager } from 'utils/auth';
import { formIsValid, formValChangeWithParentElementWithNameAndValue, onControlChange } from 'utils/form-validation';
import { getLanguageFromURL } from "utils/language";
import useDocumentTitle from "utils/useDocumentTitle";
// Import the JSON translations
import enTranslations from 'constants/languages/emailEditor/en.json';
import elTranslations from 'constants/languages/emailEditor/el.json';
// email editor
import 'assets/scss/campaign.scss'; // Import custom style for editor
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';

/**
 * The EmailCampaignAddViewComponent, that presents the email campaign main component.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function EmailCampaignAddViewComponent() {
    const { t } = useTranslation();
    const { organizationID } = useParams();
    const { outletID } = useParams();
    const { emailCampaignID } = useParams();
    const navigate = useNavigate();

    let path = `/${getLanguageFromURL()}`
    if (organizationID !== undefined) path += `/organization/${organizationID}`;
    if (outletID !== undefined) path += `/outlet/${outletID}`;
    path += `/marketing/campaign/email`;

    // to render DOM based on rest api call status, if true the rest api call completed
    const [redirect, setRedirect] = useState(false);
    // if true the menu item is clicked and a redirect to page should perform
    const [isLoaded, setIsLoaded] = useState(false);

    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313"
    });

    /**
     * @type {object}
     * @property {string} error holdes the error message of a failed rest api call
     * @property {object} userAuth the authenticated recipient infromation
     * @property {boolean} isAdd to define if requested an edit or an add of a recipient group
     * @property {string} emailCampaignID tha recipient group id to get recipient current information for edit recipient page
     * @property {object} email an empty object if add or the selected email campaign to be editted
     * @property {object} isError list of indication of empty form required field 
     *                           after submit to present error message to the
     *                           fields/attributes with true value
     */
    const [data, setData] = useState({
        error: null,
        userAuth: null,
        isAdd: emailCampaignID === undefined,
        emailCampaignID: emailCampaignID,
        email: null,
        recipientGroupIDs: [],
        recipientsGroups: [],
        recipients: [],
        emailTemplates: [],
        organization: {
            id: organizationID,
            name: ""
        },
        outlet: {
            id: outletID,
            name: ""
        },
        // error messages per field
        isError: {
            subject: "",
        }
    });

    // Calculate the minimum date-time (5 minutes from now)
    const minDateTime = new Date();
    minDateTime.setMinutes(minDateTime.getMinutes() + 5);

    //change document title
    useDocumentTitle(`Usee  ${organizationID !== undefined ? " | " + t('org.pageTitle') : ""} ${outletID !== undefined ? " | " + t('outlet.pageTitle') : ""} | ${t('marketing.campaign.email.title')}`);

    useEffect(() => {
        getEmail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * The rest endpoint to get the email default (add) or current (edit) information.
     */
    function getEmail() {
        if (data.isAdd) {
            emailCampaignApi.fetchNew(organizationID, outletID).then((r) => {
                setData({
                    ...data,
                    userAuth: r.data.returnobject.userAuth,
                    email: {
                        organizationID: organizationID,
                        outletIds: outletID === undefined ? undefined : [outletID],
                        ...getDefaultEmailCampaignObject()
                    },
                    organization: r.data.returnobject.organization,
                    outlet: r.data.returnobject.outlet,
                    recipientsGroups: r.data.returnobject.recipientsGroups,
                    emailTemplates: r.data.returnobject.emailTemplates
                });
                setIsLoaded(true);
            }).catch((e) => {
                // console.log(e);
            })
        } else {
            emailCampaignApi.fetchOne(organizationID, outletID, emailCampaignID).then((r) => {
                setData({
                    ...data,
                    userAuth: r.data.returnobject.userAuth,
                    email: r.data.returnobject.email,
                    organization: r.data.returnobject.organization,
                    outlet: r.data.returnobject.outlet,
                    recipientsGroups: r.data.returnobject.recipientsGroups,
                    recipients: r.data.returnobject.recipients
                });
                reloadDesign(r.data.returnobject.email?.designJSON, false)
                setIsLoaded(true);
            }).catch((e) => {
                // console.log(e);
            })
        }
    }

    /**
     * Function that triggers form validation and print out if the form is valid or not.
     * @returns true if form is Valid
     */
    function formValidation() {
        let isError = createErrorMessages();
        if (formIsValid(isError)) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Function that create error messages for each required field that are not filled out.
     * @returns object containing the error messages for each form field
     */
    function createErrorMessages() {
        let isError = { ...data.isError };
        isError.subject = data.email.subject.length < 1 ? t('table.valueReuired') : "";

        setData({
            ...data,
            isError
        });
        return isError;
    }
    /**
     * Gets called when the email clicks on the save button, and triggers 
     * the creation of the new email.
     */
    function addEmail() {
        if (formValidation()) {
            let email = data.email;
            email.recipientsGroupIds = data.email.recipientsGroupIds;

            const unlayer = emailEditorRef.current?.editor;
            unlayer?.exportHtml((data) => {
                const { design, html } = data;
                email.designJSON = design;
                email.body = html;

                emailCampaignApi.create(email).then((r) => {
                    Promise.resolve()
                        .then(() => {
                            setAlertBox({
                                isOpen: true,
                                message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                                backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                            });
                        })
                        .then(() => {
                            if (r.data.code === "SUCCESS")
                                setRedirect(true);
                        })
                }).catch((e) => {
                    // console.log(e)
                });
            })
        }
    }


    // ------ EMAIL
    const emailEditorRef = useRef(null);
    const [preview, setPreview] = useState(false);
    const [locale] = useState(getLanguageFromURL()); // Default locale
    const [translations, setTranslations] = useState(enTranslations); // Default translations

    // Load the appropriate translations based on the locale
    useEffect(() => {
        if (locale === 'en') {
            setTranslations(enTranslations);
        } else if (locale === 'el') {
            setTranslations(elTranslations);
        }
    }, [locale]);

    const onDesignLoad = (data) => {
        // console.log('onDesignLoad', data);
    };

    // Toggle preview mode
    /* eslint-disable no-unused-vars */
    const togglePreview = () => {
        const unlayer = emailEditorRef.current?.editor;

        if (preview) {
            unlayer?.hidePreview();
            setPreview(false);
        } else {
            unlayer?.showPreview('desktop');
            setPreview(true);
        }
    };
    /* eslint-enable no-unused-vars */

    // Load a sample design when the editor is ready
    useEffect(() => {
        reloadDesign()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function reloadDesign(designJSON, init = true) {
        const unlayer = emailEditorRef.current?.editor;
        if (unlayer) {
            unlayer.addEventListener('design:loaded', (data) => {
                // console.log('Design loaded:', data);
            });

            if (!data.isAdd)
                unlayer.loadDesign(data.email.designJSON);

            if (!init)
                unlayer.loadDesign(designJSON);


            return () => {
                unlayer.removeEventListener('design:loaded', onDesignLoad);
            };
        }
    }

    /**
     * Function that handles the snackbar open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }

    // ===============================================================================RENDER
    if (redirect) {
        navigate(`${path}`);
    } else if ((!isLoaded && !data.isAdd) || data.email == null) {
        return (
            <LoadingSkeleton lines={9} />
        );
    } else {
        const { isError } = data;

        return (
            <CustomGrid role="main" id="test" sx={mainTag()}>
                {(outletID !== undefined && organizationID !== undefined && hasRoleAdminOrDirectorOrManager(data.userAuth?.roles)) &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                                { name: t('outlet.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet` },
                                { name: data.outlet.name, url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet/${outletID}` },
                                { name: t('marketing.campaign.email.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet/${outletID}/marketing/campaign/email` }
                            ]}
                            instanceName={""}
                        />
                    </CustomGrid>
                }

                {(outletID === undefined && organizationID !== undefined && hasRoleAdminOrDirectorOrManager(data.userAuth?.roles)) &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            editLabel={'actions.view'}
                            parents={[
                                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                                { name: t('marketing.campaign.email.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/marketing/campaign/email` }
                            ]}
                            instanceName={""}
                        />
                    </CustomGrid>
                }

                {(outletID === undefined && organizationID === undefined && hasRoleAdminOrDirectorOrManager(data.userAuth?.roles)) &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            editLabel={'actions.view'}
                            parents={[
                                { name: t('marketing.campaign.email.pageTitle'), url: `/${getLanguageFromURL()}/marketing/campaign/email` }
                            ]}
                            instanceName={""}
                        />
                    </CustomGrid>
                }

                <CustomGrid sx={mainTagTitle()}>
                    <CustomTitleBoldSubtitle
                        title={`${t('marketing.campaign.email.title')} | ${data.isAdd ? t('actions.add') : data.email.sent ? t('actions.view') : t('actions.edit')}`}
                        subtitleBeforeText={data.isAdd ? t('actions.tableCreateNewLink') + t('marketing.campaign.email.title') : t('marketing.campaign.email.title') + " "}
                        subtitleboldText={""}
                        subtitleAfterText={""}
                    />
                </CustomGrid>

                <CustomGrid sx={mainArea()} container={true} gap={2}>
                    <Grid item xs={12} md={12}>
                        <CustomTextField
                            type="text"
                            name="subject"
                            InputProps={{
                                startAdornment: <span> {`${t('email.subject')}: `}</span>,
                            }}
                            disabled={data.email.sent}
                            defaultValue={data.email.subject}
                            onChange={(event) => formValChangeWithParentElementWithNameAndValue("email.subject", event.target.value, data, setData)}
                            error={isError.subject.length > 0 ? true : false}
                            helperText={isError.subject}
                            sx={textFieldError()}

                        />
                    </Grid>

                    {(!data.email.sent && data.emailTemplates?.length > 0) &&
                        <Grid item xs={12} sm={5.9}>
                            <InputLabel required={true} htmlFor="template">{t('marketing.template.title')}</InputLabel>
                            <CustomObjectSelect
                                name="email.template"
                                required={true}
                                // labelID="recipientsIds"
                                // label={t('marketing.recipient.group.pageTitle')}
                                options={data.emailTemplates}
                                isMultiple={false}
                                onChange={(event) => {
                                    formValChangeWithParentElementWithNameAndValue("email.body", data.emailTemplates?.find((emailTemplate) => emailTemplate.id === event.target.value)?.bodyHTML, data, setData)
                                    reloadDesign(data.emailTemplates?.find((emailTemplate) => emailTemplate.id === event.target.value)?.designJSON, false)
                                }}
                                sx={{ ...mainTagSearchItem(), width: "100%" }}
                                field="name"
                            />
                        </Grid>
                    }

                    {!data.email.sent &&
                        <Grid item xs={12} sm={5.9}>
                            <InputLabel required={true} htmlFor="to">{t('marketing.campaign.email.to')}</InputLabel>
                            <CustomObjectSelect
                                name="recipientsGroupIds"
                                required={true}
                                value={data.email.recipientsGroupIds}
                                // labelID="recipientsIds"
                                // label={t('marketing.recipient.group.pageTitle')}
                                options={data.recipientsGroups}
                                isMultiple={true}
                                disabled={data.email.sent}
                                onChange={(event) => formValChangeWithParentElementWithNameAndValue("email.recipientsGroupIds", event.target.value, data, setData)}
                                sx={{ ...mainTagSearchItem(), width: "100%" }}
                                field="name"
                            />
                        </Grid>
                    }

                    <Grid item xs={12} sm={12}>
                        <FormControlLabel
                            control={<Switch
                                defaultChecked={data.email.scheduled}
                                onChange={(event) => onControlChange(event, data, setData, "email", "scheduled")}
                                disabled={data.email.sent}
                            />}
                            label={t('marketing.campaign.email.scheduled')}
                        />

                        {data.email.scheduled &&
                            <>
                                <InputLabel required={false} htmlFor="scheduledDateTime">{t('marketing.campaign.email.scheduledDateTime')}</InputLabel>
                                <CustomDateTimePicker
                                    name="email.scheduledDateTime"
                                    inputFormat="DD/MM/YYYY HH:MM"
                                    value={data.email.email}
                                    onChange={(newValue) => {
                                        formValChangeWithParentElementWithNameAndValue("email.email", newValue, data, setData);
                                    }}
                                    disabled={data.email.sent}
                                    sx={{ width: "99.5%" }}
                                    minDateTime={minDateTime} // Restrict to 5 minutes after now
                                />
                            </>
                        }
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <EmailEditor
                            ref={emailEditorRef}
                            options={{
                                version: 'latest',
                                appearance: {
                                    theme: 'modern_light',
                                    panels: {
                                        tools: {
                                            dock: 'left',
                                            collapsible: true
                                        }
                                    }
                                },
                                displayMode: "email",
                                translations: {
                                    "en-US": translations
                                },
                            }}
                            style={{
                                minHeight: "400px",
                                width: "auto"
                                // height: "90%"
                            }}
                        />
                    </Grid>

                    {data.email.sent &&
                        <Grid item xs={12} sm={12}>
                            <InputLabel required={true} htmlFor="to">{t('marketing.campaign.email.to')}</InputLabel>
                            <CustomListTable
                                instances={data.recipients}
                                emptyMessage={t('marketing.recipient.empty')}
                                table_columns={recipient_table_columns_email_campaign(data, t)}
                                keyField="id"
                                defaultSorted={getDefaultRecipientForGroupSortDirection}
                                filter={true}
                            />
                        </Grid>
                    }
                </CustomGrid>

                {(data.isAdd && data.email.recipientsGroupIds?.length > 0 && !data.email.sent) &&
                    <Fab color="primary"
                        aria-label="add"
                        sx={fabStyle()}
                        onClick={addEmail}
                    >
                        <SendIcon />
                    </Fab>
                }

                {alertBox.isOpen &&
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={3000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        handleSnackbarOpen={handleSnackbarState} />
                }
            </CustomGrid>
        );
    }
}

export default EmailCampaignAddViewComponent;