import { Breadcrumbs, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { breadcrumbsLinkStyle, breadcrumbsStyle, breadcrumbsTextStyle } from 'themes/defaultThemes';

/**
 * The CustomAddEditBreadcrumb, that display an add/edit page custom breadcrumb.
 * @property {boolean} isAdd If the values is `true`, the page is about creating a new instance.
 *                              Otherwise referring to the update of a instance details.
 * @property {string} parentUrl the parent page url
 * @property {string} parentName the parent page name
 * @property {string} instanceName the instance name to be editted if the {@link isAdd} is false
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomAddEditBreadcrumb({ isAdd = true, parentUrl, parentName, instanceName }) {
    const { t } = useTranslation();

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href={parentUrl} sx={breadcrumbsLinkStyle()}>
            {parentName}
        </Link>,
        <Typography key="3" color="text.primary" sx={breadcrumbsTextStyle()}>
            {isAdd ? t('actions.create') : (instanceName === undefined ? t('actions.edit') : instanceName)}
        </Typography>
    ];

    return (
        <Breadcrumbs separator="›" aria-label="breadcrumb" sx={breadcrumbsStyle()}>
            {breadcrumbs}
        </Breadcrumbs>
    );
}

export default CustomAddEditBreadcrumb;