import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

function RatingBoolean({ question, onChange }) {
    const onUpdate = (newValue) => {
        onChange({
            ...question,
            answer: newValue,
        });
    };

    const getValue = (val) => {
        if (question.answer === val) {
            return true;
        }
        return false;
    };

    return (
        <>
            <RadioGroup row aria-labelledby={question.question.en} name={question.id}>
                {question.booleanQuestionStyle === "Like_Dislike" && (
                    <>
                        <FormControlLabel
                            checked={getValue(true)}
                            onChange={(e) => onUpdate(true)}
                            control={<Radio />}
                            label='Like'
                        />
                        <FormControlLabel
                            checked={getValue(false)}
                            onChange={(e) => onUpdate(false)}
                            control={<Radio />}
                            label='Dislike'
                        />
                    </>
                )}
                {question.booleanQuestionStyle === "Yes_No" && (
                    <>
                        <FormControlLabel
                            checked={getValue(true)}
                            onChange={(e) => onUpdate(true)}
                            control={<Radio />}
                            label='Yes'
                        />
                        <FormControlLabel
                            checked={getValue(false)}
                            onChange={(e) => onUpdate(false)}
                            control={<Radio />}
                            label='No'
                        />
                    </>
                )}
                {question.booleanQuestionStyle === "Agree_Disagree" && (
                    <>
                        <FormControlLabel
                            checked={getValue(true)}
                            onChange={(e) => onUpdate(true)}
                            control={<Radio />}
                            label='Agree'
                        />
                        <FormControlLabel
                            checked={getValue(false)}
                            onChange={(e) => onUpdate(false)}
                            control={<Radio />}
                            label='Disagree'
                        />
                    </>
                )}
                {question.booleanQuestionStyle === "True_False" && (
                    <>
                        <FormControlLabel
                            checked={getValue(true)}
                            onChange={(e) => onUpdate(true)}
                            control={<Radio />}
                            label='True'
                        />
                        <FormControlLabel
                            checked={getValue(false)}
                            onChange={(e) => onUpdate(false)}
                            control={<Radio />}
                            label='False'
                        />
                    </>
                )}
            </RadioGroup>
        </>
    );
}
export default RatingBoolean;