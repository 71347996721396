import { CloseRounded as CloseRoundedIcon } from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputLabel,
    Stack,
} from '@mui/material';
import posApi from 'api/pos';
import CustomPosCard from 'components/Card/pos/CustomPosCard';
import CustomSelect from 'components/Select/CustomSelect';
import CustomTextField from 'components/Text/CustomTextField';
import { getDefaultPosObject } from 'constants/defaultObjects';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { textFieldError } from 'themes/defaultThemes';
import { formIsValid, formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';
import { validateInput } from 'utils/functions';

/**
 * The AddEditPosDialog, that display a customized dialog to add/edit a POS device.
 *
 * @property {object} existingPos the object containing details for POS device
 * @property {boolean} isOpen If the values is `true`, the modal should be open and visible. Default value is false.
 * @property {boolean} isAdd If the values is `true`, the modal refering to creation of a new POS device.
 * @property {function} handleOpen the function that handles the dialog open state
 * @property {function} addLoyaltyCategory the function that handles the creation of a  POS device
 * @property {function} updateLoyaltyCategory the function that handles the modification of a  POS device
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function AddEditPosDialog({
    existingPos,
    isOpen = false,
    isAdd = true,
    handleOpen,
    addOutletPos,
    updateOutletPos,
    typeOptions = [],
    providerOptions = [],
    search
}) {
    const { t } = useTranslation();
    const [outletPos, setOutletPos] = useState(isAdd ? getDefaultPosObject() : existingPos);

    // @property {boolean} if the values is `true`, the modal should be open and visible.
    const [open, setOpen] = useState(isOpen);

    /**
     * @type {object}
     * @property {object} userAuth the authenticated user infromation
     * @property {object} organization tha organization basic details (id and name)
     * @property {object} outlet tha outlet basic details (id and name)
     */
    const [data, setData] = useState({
        userAuth: null,
        organization: null,
        outlet: null,
        typeOptions: [],
        providerOptions: [],
    });

    const [isError, setIsError] = useState({
        friendlyName: "",
        terminalId: "",
    });

    /**
     * The rest endpoint to get the printers list.
     */
    function getPosNew() {
        posApi.fetchNew(search?.organizationID, search?.outletID).then((r) => {
            Promise.resolve()
                .then(() => {
                    setData({
                        ...data,
                        userAuth: r.data.returnobject.userAuth,
                        organization: r.data.returnobject.organization,
                        outlet: r.data.returnobject.outlet,
                        typeOptions: r.data.returnobject.types,
                        providerOptions: r.data.returnobject.providers
                    });
                })
        }).catch((e) => {
            // console.log(e);
        })
    }

    useEffect(() => {
        if (!isAdd)
            setOutletPos(existingPos)
    }, [existingPos]);

    useEffect(() => {
        formValidation()
    }, [outletPos])
    
    useEffect(() => {
        getPosNew();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Function that triggers form validation and print out if the form is valid or not.
     * @returns true if form is Valid
     */
    function formValidation() {
        let isErrorTmp = createErrorMessages();
        if (formIsValid(isErrorTmp)) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Function that create error messages for each required field that are not filled out.
     * @returns object containing the error messages for each form field
     */
    function createErrorMessages() {
        let isErrorTmp = { ...isError };
        isErrorTmp.friendlyName = outletPos.friendlyName?.length < 1 ? t('table.valueReuired') : "";
        isErrorTmp.terminalId = outletPos.terminalId?.length < 1 ? t('table.valueReuired') : "";

        setIsError(isErrorTmp);
        return isErrorTmp;
    }


    /**
     * Function that handles the visualize modal open or close state.
     * @property {boolean} isOpened If the values is `true`, the modal should be open and visible.
     */
    function handleDialogState(isOpened) {
        setOpen(isOpened);
        handleOpen(isOpened);
    }

    function handleAction() {
        if (formValidation()) {
            if (isAdd) {
                posApi.exist(outletPos.friendlyName).then((r) => {
                    if (r.data.code !== "INVALID") {
                        setOpen(false);
                        handleOpen(false);
                        addOutletPos(outletPos)
                    } else {
                        setIsError({ friendlyName: r.data.message })
                    }
                }).catch((e) => {
                    // console.log(e)
                });
            } else {
                updateOutletPos(outletPos);
            }
        }
    }

    // ================================= extra
    function handleTypeChange(event) {
        const type = event.target.value;
        setOutletPos(prevState => ({
            ...prevState,
            type: type
        }));
    }

    function handleProviderChange(event) {
        const provider = event.target.value;
        setOutletPos(prevState => ({
            ...prevState,
            provider: provider
        }));
    }

    // ======================================================= RENDER
    return (
        <>
            <Dialog
                open={open}
                onClose={() => { handleDialogState(false) }}
                aria-describedby="add-edit-pos-dialog-description"
                className="dialog-title"
            >
                <DialogTitle className="dialog-headers">
                    {isAdd ? `${t('actions.add')} ${t('pos.title')}` : `${t('actions.edit')} ${t('pos.title')} '${(outletPos.friendlyName !== "" && outletPos.friendlyName !== null) ? outletPos.friendlyName : ""}'`}
                    <IconButton onClick={() => { handleDialogState(false) }} sx={{ float: "right" }}>
                        <CloseRoundedIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ background: "#fff", minWidth: "500px" }}>
                    <Stack direction="row" sx={{ justifyContent: "center", marginTop: "20px", marginBottom: "20px" }}>
                        <CustomPosCard
                            outletPos={outletPos}
                            sx={{ marginRight: 0 }}
                            onDialog={true}
                            typeOptions={typeOptions}
                            providerOptions={providerOptions}
                        />
                    </Stack>

                    <Grid container gap={1}>
                        <Grid item xs={12}>
                            <InputLabel required={false} htmlFor="friendlyName">{t('pos.friendlyName')}</InputLabel>
                            <CustomTextField
                                type="text"
                                name="friendlyName"
                                id="friendlyName"
                                value={outletPos.friendlyName}
                                onChange={(event) => {
                                    let value = event.target.value;
                                    if (!validateInput(value, 20)) value = outletPos.friendlyName;
                                    formValChangeWithParentElementWithNameAndValue("friendlyName", value, outletPos, setOutletPos)
                                }}
                                error={isError.friendlyName.length > 0 ? true : false}
                                helperText={isError.friendlyName}
                                sx={textFieldError()}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel required={false} htmlFor="terminalId">{t('pos.terminalId')}</InputLabel>
                            <CustomTextField
                                type="text"
                                name="terminalId"
                                id="terminalId"
                                value={outletPos.terminalId}
                                onChange={(event) => {
                                    let value = event.target.value;
                                    if (!validateInput(value, 20)) value = outletPos.terminalId;
                                    formValChangeWithParentElementWithNameAndValue("terminalId", value, outletPos, setOutletPos)
                                }}
                                error={isError.terminalId.length > 0 ? true : false}
                                helperText={isError.terminalId}
                                sx={textFieldError()}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel>{t('pos.type.label')}</InputLabel>
                            <CustomSelect
                                name="type"
                                required={true}
                                isMultiple={false}
                                value={outletPos.type}
                                options={data.typeOptions}
                                onChange={handleTypeChange}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel>{t('pos.provider.label')}</InputLabel>
                            <CustomSelect
                                name="provider"
                                required={true}
                                isMultiple={false}
                                value={outletPos.provider}
                                options={data.providerOptions}
                                onChange={handleProviderChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions className="dialog-actions">
                    <Button className="dialog-cancel-btn" onClick={() => { handleDialogState(false) }}>{t("actions.cancel")}</Button>
                    <Button className="dialog-action-btn" onClick={() => { handleAction() }}>{isAdd ? t('actions.create') : t('actions.save')}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default AddEditPosDialog;