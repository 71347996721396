import { useMediaQuery } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import lightTableHeadColor from 'assets/scss/app.scss';
import { getDefaultSortDirection } from 'constants/sortDirection';
import MUIDataTable from "mui-datatables";
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { linkStyle, mainAreaBody, lumanosimoTitleStylePlain, lumanosimoTitleStyle } from 'themes/defaultThemes';

/**
 * The CustomPageableTableWithFilters, that display a table if the provided array is not empty. Otherwise it 
 * presents a corresponding empty table message. The table also has pageable functionality.
 *
 * @property {string} id The table id
 * @property {string} classAppend extra classes for the table div element
 * @property {object} instances the list of items to be presented on a table if any
 * @property {boolean} showEmptyMessage to specify if the empty list message should be shown
 * @property {string} emptyMessage The message to presented if the @link(instances) is empty
 * @property {string} addPageUrl The url of the add instance page if a reference should be included
 * @property {array} table_columns The datatable columns configuration
 * @property {string} keyField Tells react-bootstrap-table2 which column is unique.
 * @property {object} rowEvents Custom events on row
 * @property {array} defaultSorted accept an object array which allow you to define the default sort columns when first render.
 * @property {string} classes The table classes
 * @property {object} pagination includes all pageable details (page, size, sort, direction)
 * @property {object} options the table options
 * 
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomPageableTableWithFilters({
    id = "",
    classAppend = "",
    instances,
    showEmptyMessage = true,
    emptyMessage,
    addPageUrl = "",
    table_columns,
    defaultSorted = getDefaultSortDirection(),
    handlePaginationChange,
    handleRowsPerPageChange,
    handleColumnSortChange,
    onRowSelectionChange = undefined,
    download = false,
    filterType,
    filter = true

}) {
    const isMobile = useMediaQuery('(max-width:468px)');
    const [denseTable, setDenseTable] = useState(true);
    const { t } = useTranslation();
    const [options, setOptions] = useState({
        tableId: id,
        serverSide: true,
        caseSensitive: true,
        textLabels: t('table.labels'),

        filterType: filterType,
        // confirmFilters: false,
        filter: filter,
        filterArrayFullMatch: false,

        expandableRows: false,
        expandableRowsHeader: false,
        expandableRowsOnClick: false,

        viewColumns: true,
        fixedHeader: false,
        fixedSelectColumn: false,
        tableBodyMaxHeight: "100%",
        tableBodyHeight: "auto",
        resizableColumns: false,

        pagination: true,
        jumpToPage: !isMobile,

        download: download,
        print: false,
        rowHover: false,
        search: false,

        selectableRows: onRowSelectionChange ? "none" : "multiple",
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: onRowSelectionChange === undefined,
        selectableRowsOnClick: onRowSelectionChange !== undefined,
        selectToolbarPlacement: "above", //select toolbar will appear above default toolbar when a row is selected


        sort: true,
        sortFilterList: true,
        sortOrder: defaultSorted,

        // page: instances.number,
        rowsSelected: [],
        rowsPerPageOptions: [1, 10, 15, 50, 100],
        // rowsPerPage: instances.size,
        responsive: isMobile ? "vertical" : "vertical",
        onChangePage: (pageIndex) => handlePaginationChange(pageIndex),
        onChangeRowsPerPage: (numberOfRows) => handleRowsPerPageChange(numberOfRows),
        onColumnSortChange: (changedColumn, direction) => handleColumnSortChange(changedColumn, direction),

        count: instances.length,

        setTableProps: () => {
            return {
                // padding: denseTable ? '5px' : '10px',
                // material ui v5 only
                size: denseTable ? 'small' : 'medium',
            };
        },
    });

    function getMuiTheme() {
        return createTheme({
            components: {
                // MUIDataTableSelectCell: {
                //     styleOverrides: {
                //         headerCell: {
                //             backgroundColor: "#54b1d2",
                //             color: "#fff"
                //         }
                //     }
                // },
                MUIDataTableHeadCell: {
                    styleOverrides: {
                        root: {
                            verticalAlign: "middle",
                            textAlign: "center",
                            height: "40px",
                            whiteSpace: "nowrap",
                            textAlign: "center",
                            fontWeight: "600",
                            color: "grey",
                            ...lumanosimoTitleStylePlain()
                        },
                        data: {
                            color: "initial",
                            ...lumanosimoTitleStyle()
                        },
                        sortAction: {
                            color: lightTableHeadColor
                        },
                        //         sortActive: {
                        //             color: "#$",
                        //             ":hover": {
                        //                 textDecoration: "underline"
                        //             }
                        //         },
                        contentWrapper: {
                            justifyContent: "center"
                        },
                    }
                },
                MUIDataTableBody: {
                    styleOverrides: {
                        emptyTitle: {
                            margin: "20px 0",
                        }
                    }
                },
                MUIDataTableToolbar: {
                    styleOverrides: {
                        filterPaper: {
                            width: "450px",
                            maxWidth: "80%"
                        }
                    }
                },
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            verticalAlign: "middle",
                            textAlign: "center !important",
                        }
                    }
                }
            }
        })
    }

    return (
        <>
            {showEmptyMessage && (instances === null || instances?.length === 0) &&
                <div className="body" style={mainAreaBody()}>
                    {emptyMessage} {addPageUrl !== "" && <a className="link" style={linkStyle()} href={addPageUrl}>{t('actions.clickHere')}</a>}
                </div>
            }

            {(instances !== null && instances?.length > 0) &&
                <div className={`table-wrapper ${classAppend}`}>
                    <ThemeProvider theme={getMuiTheme()}>
                        {/* <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={denseTable}
                                        onChange={() => setDenseTable(!denseTable)}
                                        color="primary"
                                    />
                                }
                                label={t('table.denseTable')}
                            />
                        </FormGroup> */}
                        <MUIDataTable
                            id={id}
                            data={instances}
                            columns={table_columns}
                            options={options}
                            scrollable
                        />
                    </ThemeProvider>
                </div>
            }
        </>
    );
}

export default CustomPageableTableWithFilters;