import { Add as AddIcon, Clear as ClearIcon, GTranslate as GTranslateIcon, Settings as SettingsIcon } from "@mui/icons-material";
import { Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import { CheckIcon, DeleteIcon } from "assets/mui/MuiIcons";
import CustomIconButton from "components/Button/CustomIconButton";
import { useTranslation } from "react-i18next";
import { getSectionToolbarSectionIconStyle } from "themes/floor";
import { getLanguageFromURL } from "utils/language";

/**
 * The FloorSectionsToolbar, that display the floor planning sections toolbar.
 * 
 * @returnsa a custom grid
 * 
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
const FloorSectionsToolbar = ({
    sections,
    selectedSection,
    setSelectedSection,
    setSelectedObject,
    addSectionHandler,
    saveClickHandler,
    editSectionNameChip,
    setEditSectionNameChip,
    nameChangeHandler,
    setDeleteSectionDialog, 
    onTranslationSelect
}) => {
    let lang = getLanguageFromURL();
    const { t } = useTranslation();

    return (
        <Grid section>
            <Grid
                container
                justifyContent='space-between'
                alignItems='center'
                sx={{
                    p: "1rem",
                    border: "1px solid #d8d8d8",
                    background: "#fff",
                }}
            >
                <Grid section sx={{ flex: 1 }}>
                    <Grid container alignItems='center' wrap='nowrap'>
                        {sections?.map((section) => (
                            <Grid
                                section
                                key={section.id}
                                sx={{
                                    px: "0.8em",
                                    backgroundColor:
                                        selectedSection?.id === section.id ? "#f4f4f6" : "#fff",
                                    borderRadius: "50px",
                                    display: "flex",
                                    gap: "10px",
                                }}
                            >
                                {selectedSection?.id === section.id &&
                                    editSectionNameChip.active ? (
                                    <TextField
                                        variant='standard'
                                        size='small'
                                        sx={{
                                            py: "0.3em",
                                            ".MuiInput-input": {
                                                fontSize: "10px",
                                                pb: "1px",
                                            },
                                        }}
                                        value={editSectionNameChip.name[lang]}
                                        onChange={(e) =>
                                            setEditSectionNameChip((n) => {
                                                return {
                                                    ...n,
                                                    name: e.target.value,
                                                };
                                            })
                                        }
                                        InputProps={{
                                            startAdornment: (
                                                <CustomIconButton
                                                    onClick={() => onTranslationSelect(selectedSection?.id, "section")}
                                                    icon={<GTranslateIcon style={{ marginRight: "0px", fontSize: "15px" }} />}
                                                    className="icon-wrapper tr link"
                                                    iconSx={{ marginRight: "0px!important" }}
                                                    sx={{ backgroundColor: "white", borderRadius: "50px", padding: "5px", width: "auto", minWidth: "25px", marginBottom: "5px", marginRight: "5px"}}
                                                />
                                            )
                                        }}
                                    />

                                ) : (
                                    <Typography
                                        variant='body2'
                                        sx={{
                                            py: "0.6em",
                                            fontSize: "11px",
                                            color: "#4a4c50",
                                            cursor:
                                                section.id === selectedSection?.id
                                                    ? "default"
                                                    : "pointer",
                                        }}
                                        onClick={() => {
                                            if (section.id === selectedSection?.id) return;
                                            // setSections((r) =>
                                            //     r.map((ro) =>
                                            //         ro.id === selectedSection.id ? selectedSection : ro
                                            //     )
                                            // );
                                            setSelectedObject(null);
                                            setSelectedSection(section);
                                        }}
                                    >
                                        {section.name[lang]}
                                    </Typography>
                                )}
                                {selectedSection?.id === section.id && (
                                    <div
                                        style={{
                                            paddingLeft: "10px",
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <IconButton
                                            sx={{ p: 0, background: "transparent" }}
                                            disableRipple
                                            onClick={() => {
                                                if (editSectionNameChip?.active) {
                                                    setEditSectionNameChip({
                                                        active: false,
                                                        name: "",
                                                    });
                                                } else {
                                                    setEditSectionNameChip({
                                                        active: true,
                                                        name: selectedSection.name,
                                                    });
                                                }
                                            }}
                                        >
                                            {editSectionNameChip?.active ? (
                                                <ClearIcon
                                                    style={{
                                                        py: "0.6em",
                                                        paddingRight: "10px",
                                                        fontSize: "0.8rem",
                                                        fill: "rgb(153, 153, 153)",
                                                    }}
                                                />
                                            ) : (
                                                <SettingsIcon
                                                    style={{
                                                        py: "0.6em",
                                                        paddingRight: "10px",
                                                        fontSize: "0.8rem",
                                                        fill: "rgb(153, 153, 153)",
                                                    }}
                                                />
                                            )}
                                        </IconButton>

                                        <div
                                            style={{
                                                borderLeft: "1px solid #d8d8d8",

                                                height: "100%",
                                            }}
                                        />

                                        <IconButton
                                            sx={{ p: 0, background: "transparent" }}
                                            disableRipple
                                            onClick={() => {
                                                if (editSectionNameChip?.active) {
                                                    nameChangeHandler(section?.id, editSectionNameChip.name);
                                                } else {
                                                    setDeleteSectionDialog({
                                                        active: true,
                                                        room: section,
                                                    });
                                                }
                                            }}
                                        >
                                            {editSectionNameChip?.active ? (
                                                <CheckIcon style={getSectionToolbarSectionIconStyle()} />
                                            ) : (
                                                <DeleteIcon style={getSectionToolbarSectionIconStyle()} />
                                            )}
                                        </IconButton>
                                    </div>
                                )}
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

                {/* add, save section */}
                <Grid section>
                    <Grid container sx={{ gap: "20px" }}>
                        <Grid section>
                            <Button
                                variant='text'
                                size='small'
                                sx={{
                                    fontSize: "12px",
                                    px: "10px",
                                    color: "#54b1d2",
                                    textTransform: "none",
                                }}
                                startIcon={<AddIcon />}
                                onClick={addSectionHandler}
                            >
                                {t('display.floorPlanning.addSection')}
                            </Button>
                        </Grid>

                        <Grid section>
                            <Button
                                variant='contained'
                                size='small'
                                sx={{
                                    fontSize: "12px",
                                    px: "15px",
                                    background: "#54b1d2",
                                    color: "#fff",
                                    textTransform: "none",
                                    borderRadius: "5px",
                                    ":hover": {
                                        background: "#54b1d2",
                                    },
                                }}
                                onClick={saveClickHandler}
                            >
                                {t('display.floorPlanning.saveSection')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default FloorSectionsToolbar;