import { Settings as SettingsIcon } from "@mui/icons-material";
import ReplayIcon from "@mui/icons-material/Replay";
import { Box, Grid } from "@mui/material";
import kitchenDisplayApi from "api/kitchenDisplay";
import orderApi from "api/order";
import CustomButton from "components/Button/CustomButton";
import CustomIconButton from "components/Button/CustomIconButton";
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomSnackbar from "components/Snackbar/CustomSnackbar";
import CustomTypography from "components/Typography/CustomTypography";
import { getDefaultKitchenDisplaySettings } from "constants/defaultObjects";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { mainTag } from "themes/defaultThemes";
import { dateTimeFormatter } from "utils/functions";
import { getLanguageFromURL } from "utils/language";
import useDocumentTitle from "utils/useDocumentTitle";
import OrderCard from "../orderCard";
import "./scroll.css";

/**
 * The UserListComponent, that allow the user to view the users list into a table.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function KitchenDisplayComponent() {
    const { t } = useTranslation();
    const { organizationID } = useParams();
    const { outletID } = useParams();
    const navigate = useNavigate();

    /**
     * @type {object}
     *
     * @property {object} userAuth the authenticated user infromation
     * @property {object} alertBox holds snackbar infromation and style
     * @property {array} orders the orders list
     * @property {object} outlet tha outlet basic details (id and name, isActive)
     * @property {object} organization tha organization basic details (id and name)
     * @property {object} pagination includes all pageable details (page, size, sort, direction)
     */
    const [data, setData] = useState({
        userAuth: null,
        orders: null,
        showLabel: false,
        organization: {
            id: organizationID,
            name: "",
        },
        outlet: {
            id: outletID,
            name: "",
        },
        organizations: [],
        outlets: [],
        statuses: [],
        paymentMethods: [],
        currency: "€",
    });
    const [minimizeOrders, setMinimizeOrder] = useState(false);
    const [displaySettings, setDisplaySettings] = useState(
        getDefaultKitchenDisplaySettings()
    );
    const [refetchTime, setRefetchTime] = useState(
        displaySettings.refreshTimeInSeconds
            ? displaySettings.refreshTimeInSeconds
            : 30
    );
    const [refreshInterval, setRefreshInterval] = useState();
    //change document title
    useDocumentTitle(`Usee  | ${t("display.kitchen.label")}`);

    const [search] = useState({
        organizationID: organizationID,
        outletID: outletID,
        // field: "username",
        orderNumber: "",
        // statuses: ["PENDING, WORKING, DONE"],
        statuses: ["WORKING", "PENDING"],
        equipmentID: "",
    });
    // if true the menu item is clicked and a redirect to page should perform
    const [isLoaded, setIsLoaded] = useState(false);

    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313",
    });

    //fetch setting here (we won't stop loading and will used default setting until setting is fetch)
    useEffect(() => {
        getKitchenDisplaySettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initializeRefreshInterval = () => {
        const intervalId = setInterval(
            () => {
                getOrders();
                setRefetchTime(
                    displaySettings.refreshTimeInSeconds
                        ? displaySettings.refreshTimeInSeconds
                        : 30
                );
            },
            displaySettings.refreshTimeInSeconds
                ? displaySettings.refreshTimeInSeconds * 1000
                : 30 * 1000
        ); // Call API every 5 seconds
        setRefreshInterval(intervalId);
        return intervalId;
    };

    useEffect(() => {
        if (isLoaded) {
            let intervalId = initializeRefreshInterval();
            return () => {
                clearInterval(intervalId);
            };
        }
        return () => { };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoaded]);

    useEffect(() => {
        if (isLoaded) {
            const intervalId = setInterval(() => {
                if (refetchTime > 0) {
                    setRefetchTime((r) => r - 1);
                }
            }, 1000);
            return () => clearInterval(intervalId);
        }
        return () => { };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoaded]);

    /**
     * The rest endpoint to get the orders list.
     * @property {number} page the list page number
     */
    function getOrders(page) {
        orderApi
            .fetchAllKitchen({
                ...search,
                dateFrom: dateTimeFormatter(new Date((new Date()).getTime() - 4 * 60 * 60 * 1000), "yyyy-MM-DDTHH:mm:ss.SSSZ")
            })
            .then((r) => {
                setIsLoaded(true);
                setData({
                    ...data,
                    userAuth: r.data.returnobject.userAuth,
                    organization: r.data.returnobject.organization,
                    outlet: r.data.returnobject.outlet,
                    orders: r.data.returnobject.orders,
                    paymentMethods: r.data.returnobject.paymentMethods,
                    statuses: r.data.returnobject.statuses,
                    // organizations: r.data.returnobject.organizations,
                    // outlets: r.data.returnobject.outlets,
                    currency: r.data.returnobject.currency,
                });
                // if (r.data.message) {
                //   setAlertBox({
                //     isOpen: true,
                //     message: r.data.message,
                //     backgroundColor: "#4caf50",
                //   });
                // }
            })
            .catch((e) => {
                // console.log(e);
                setAlertBox({
                    isOpen: true,
                    message: "Fail to fetch orders",
                    backgroundColor: "#ef5350",
                });
            });
    }

    function getKitchenDisplaySettings() {
        // organizationID, outletID
        kitchenDisplayApi
            .fetchOne(search)
            .then((r) => {
                const s = r.data.returnobject.displaySettings;
                setDisplaySettings(s);
                setRefetchTime(s.refreshTimeInSeconds ? s.refreshTimeInSeconds : 30);
                getOrders();
            })
            .catch((e) => {
                console.log(e);
                setAlertBox({
                    isOpen: true,
                    message: "Fail to fetch display settings",
                    backgroundColor: "#ef5350",
                });
            });
    }

    /**
     * Function that handles the snackbar open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen,
        });
    }

    // ===============================================================================RENDER

    const showRightSide =
        !displaySettings.autoAcceptOrders &&
        !displaySettings.showPendingAndWorkingOrdersTogether &&
        (displaySettings.autoAcceptOrders === false ||
            (displaySettings.autoAcceptOrders === true &&
                displaySettings.autoAcceptPendingTimeInSeconds !== 0));

    //console.log(data);

    const getFilterData = (status) =>
        data.orders?.filter((x) => (showRightSide ? x.status === status : x));
    const orderComponent = (status) => (
        <Grid
            container
            spacing={2}
            //direction={minimizeOrders ? "row" : "column"}
            //flexWrap='nowrap'
            sx={{
                //height: "100%",
                maxHeight: "100%",
                overflowY: "auto",
                overflowX: "hidden",
                pb: "50px",
            }}
        >
            {getFilterData(status).map((item, i) => (
                <Grid
                    item
                    key={i}
                    md={
                        minimizeOrders
                            ? showRightSide
                                ? status === "PENDING"
                                    ? 6
                                    : 4
                                : 3
                            : showRightSide
                                ? status === "PENDING"
                                    ? 12
                                    : 6
                                : 4
                    }
                    xs={12}
                    sx={{ display: "flex" }}
                >
                    <OrderCard
                        order={item}
                        setting={displaySettings}
                        minimize={minimizeOrders}
                        currency={data.currency}
                        updateOrderItemStatus={(orderId, itemId, done, index) => {
                            setData((d) => {
                                return {
                                    ...d,
                                    orders: d.orders.map((o) => {
                                        if (o.id === orderId) {
                                            o.cart = o.cart.map((c, i) => {
                                                if (c.id === itemId && i === index) {
                                                    c.done = done;
                                                }
                                                return c;
                                            });
                                        }
                                        return o;
                                    }),
                                };
                            });
                        }}
                        updateOrderItemQuantity={(orderId, items) => {
                            setData((d) => {
                                return {
                                    ...d,
                                    orders: d.orders.map((o) => {
                                        if (o.id === orderId) {
                                            o.cart = o.cart.map((c) => {
                                                let selectedItem = items.find((x) => x.id === c.id);
                                                if (selectedItem) {
                                                    c.unavailableQuantity = selectedItem.quantity;
                                                    c.unavailable =
                                                        selectedItem.quantity > 0 ? true : false;
                                                }
                                                return c;
                                            });
                                        }
                                        return o;
                                    }),
                                };
                            });
                        }}
                        cancelOrder={(orderId) => {
                            setData((d) => {
                                return {
                                    ...d,
                                    orders: d.orders.map((o) => {
                                        if (o.id === orderId) {
                                            o.status = "CANCELED";
                                        }
                                        return o;
                                    }),
                                };
                            });
                        }}
                        onAssignTimer={(orderId, time) => {
                            setData((d) => {
                                return {
                                    ...d,
                                    orders: d.orders.map((o) => {
                                        if (o.id === orderId) {
                                            o.status = "WORKING";
                                            o.processingTimeInMinutes = time;
                                            o.actionsHistory =
                                                o.actionsHistory && o.actionsHistory.length > 0
                                                    ? [
                                                        ...o.actionsHistory,
                                                        {
                                                            dateTime: new Date().toString(),
                                                            status: "WORKING",
                                                        },
                                                    ]
                                                    : o.actionsHistory;
                                        }
                                        return o;
                                    }),
                                };
                            });
                        }}
                        onCompleteOrder={(orderId) => {
                            setData((d) => {
                                return {
                                    ...d,
                                    orders: d.orders.map((o) => {
                                        if (o.id === orderId) {
                                            o.status = "DONE";
                                        }
                                        return o;
                                    }),
                                };
                            });
                        }}
                        setAlertBox={setAlertBox}
                    />
                </Grid>
            ))}
        </Grid>
    );

    const MemoizedBody = useMemo(
        () => (
            <>
                {/* title to the to columns. */}
                <Grid item sx={{ width: "100%", pt: "15px" }}>
                    <Grid
                        container
                        sx={{
                            background: "#fff",
                            boxShadow: `0px 0px 15px 0px #dcdcdc`,
                            borderRadius: "5px",
                            p: "0px 5px",
                        }}
                    >
                        <Grid
                            item
                            sx={{
                                width: { md: showRightSide ? "40%" : "100%", xs: "100%" },
                                py: "10px",
                                ml: "-10.5px",
                                borderRight: {
                                    md: showRightSide ? "2px solid rgba(0, 0, 0, 0.12)" : 0,
                                    xs: 0,
                                },
                                pr: {
                                    md: showRightSide ? "5px" : 0,
                                    xs: 0,
                                },
                            }}
                        >
                            <CustomTypography
                                text={
                                    displaySettings.autoAcceptOrders &&
                                        displaySettings.autoAcceptPendingTimeInSeconds === 0
                                        ? `${t("orders.workingTitle")} (${getFilterData("WORKING")?.length
                                        })`
                                        : showRightSide
                                            ? `${t("orders.pendingTitle")} (${getFilterData("PENDING").length
                                            })`
                                            : `${t("orders.pendingWorking")} (${data.orders?.length})`
                                }
                                sx={{ fontWeight: "bold" }}
                            />
                        </Grid>
                        {showRightSide && (
                            <Grid
                                item
                                sx={{
                                    width: { md: "60%", xs: "0%" },
                                    display: { md: "unset", xs: "none" },

                                    py: "10px",
                                }}
                            >
                                <CustomTypography
                                    text={`${t("orders.workingTitle")} (${getFilterData("WORKING").length
                                        })`}
                                    sx={{ fontWeight: "bold" }}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                {/* orders */}
                {data.orders && (
                    <Grid
                        item
                        sx={{ width: "100%", pt: "16px", flex: 1, maxHeight: "100%" }}
                    >
                        <Grid
                            container
                            direction={{ md: "row", xs: "column" }}
                            spacing={2}
                            sx={{ height: "100%" }}
                        >
                            {getFilterData("PENDING").length > 0 ? (
                                <Grid
                                    item
                                    sx={{
                                        width: {
                                            md: showRightSide ? "40%" : "100%",
                                            xs: "100%",
                                        },
                                        height: "100%",
                                        maxHeight: "100%",
                                        borderRight: {
                                            md: showRightSide ? "2px solid rgba(0, 0, 0, 0.12)" : 0,
                                            xs: 0,
                                        },
                                        pr: {
                                            md: showRightSide ? "5px" : 0,
                                            xs: 0,
                                        },
                                    }}
                                >
                                    {orderComponent("PENDING")}
                                </Grid>
                            ) : (
                                <Grid
                                    item
                                    sx={{
                                        width: { md: "40%", xs: "100%" },
                                        height: "100%",
                                        maxHeight: "100%",
                                        mt: { md: 0, xs: "-10px" },
                                        borderRight: {
                                            md: "2px solid rgba(0, 0, 0, 0.12)",
                                            xs: 0,
                                        },
                                    }}
                                >
                                    <CustomTypography
                                        text={t("orders.pendingEmpty")}
                                        sx={{ fontWeight: "600" }}
                                    />
                                </Grid>
                            )}
                            {showRightSide && (
                                <Grid
                                    item
                                    sx={{
                                        width: "100%",
                                        display: { md: "none", xs: "block" },
                                    }}
                                >
                                    <Grid
                                        container
                                        justifyContent='center'
                                        sx={{
                                            background: "#fff",
                                            boxShadow: `0px 0px 15px 0px #dcdcdc`,
                                            borderRadius: "5px",
                                            p: "10px 5px",
                                        }}
                                    >
                                        <CustomTypography
                                            text={`${t("orders.workingTitle")} (${getFilterData("WORKING").length
                                                })`}
                                            sx={{ fontWeight: "bold" }}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                            {showRightSide &&
                                (getFilterData("WORKING").length > 0 ? (
                                    <Grid
                                        item
                                        sx={{
                                            width: { md: "60%", xs: "100%" },
                                            height: "100%",
                                            maxHeight: "100%",
                                        }}
                                    >
                                        {orderComponent("WORKING")}
                                    </Grid>
                                ) : (
                                    <Grid
                                        item
                                        sx={{
                                            width: { md: "60%", xs: "100%" },
                                            height: "100%",
                                            maxHeight: "100%",
                                            mt: { md: 0, xs: "-10px" },
                                        }}
                                    >
                                        <CustomTypography
                                            text={t("orders.workingEmpty")}
                                            sx={{ fontWeight: "600" }}
                                        />
                                    </Grid>
                                ))}
                        </Grid>
                    </Grid>
                )}
            </>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data.orders, minimizeOrders]
    );


    if (!isLoaded) {
        return <LoadingSkeleton lines={9} />;
    } else {
        return (
            <Box
                sx={{
                    ...mainTag(),

                    top: "9px",
                    paddingBottom: 0,
                    height: { md: "calc(100vh - 78px)", xs: "unset" },
                    maxHeight: { md: "calc(100vh - 78px)", xs: "unset" },
                    overflow: { md: "hidden", xs: "hidden" },
                    boxSizing: "border-box",
                    display: "flex",
                    flexDirection: "column",
                }}
                id='main'
            >
                {alertBox.isOpen && (
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={3000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        handleSnackbarOpen={handleSnackbarState}
                    />
                )}
                {/* header */}
                <Grid item sx={{ width: "100%" }}>
                    <Grid
                        container
                        justifyContent='flex-end'
                        alignItems='center'
                        spacing={2}
                    >
                        <Grid item>
                            <CustomButton
                                onClick={() => {
                                    getOrders();
                                    setRefetchTime(
                                        displaySettings.refreshTimeInSeconds
                                            ? displaySettings.refreshTimeInSeconds
                                            : 30
                                    );
                                    clearInterval(refreshInterval);
                                    initializeRefreshInterval();
                                }}
                                sx={{
                                    fontSize: "12px",
                                    px: "20px",
                                    textTransform: "none",
                                    "&:hover": {
                                        backgroundColor: "#2596be !important",
                                        color: "white",
                                    },
                                }}
                                startIcon={<ReplayIcon />}
                                variant='contained'
                                label={
                                    refetchTime > 0
                                        ? refetchTime > 3600
                                            ? new Date(refetchTime * 1000).toISOString().substr(11, 8)
                                            : refetchTime > 60
                                                ? new Date(refetchTime * 1000).toISOString().substr(14, 5)
                                                : new Date(refetchTime * 1000).toISOString().substr(17, 2)
                                        : "00"
                                }
                            />
                        </Grid>
                        {/* toggle card button */}
                        <Grid item>
                            <CustomButton
                                variant='contained'
                                label={
                                    minimizeOrders
                                        ? t("orders.maximizeOrders")
                                        : t("orders.minimizeOrders")
                                }
                                sx={{
                                    fontSize: "12px",
                                    px: "20px",
                                    textTransform: "none",
                                    "&:hover": {
                                        backgroundColor: "#2596be !important",
                                        color: "white",
                                    },
                                }}
                                onClick={() => setMinimizeOrder((m) => !m)}
                            />
                        </Grid>
                        {/* past order Icon */}
                        <Grid item>
                            <CustomButton
                                variant='contained'
                                label={t("orders.pastOrders")}
                                sx={{
                                    fontSize: "12px",
                                    px: "20px",
                                    textTransform: "none",
                                    "&:hover": {
                                        backgroundColor: "#2596be !important",
                                        color: "white",
                                    },
                                }}
                                onClick={() =>
                                    navigate(
                                        `/${getLanguageFromURL()}/display/kitchen/pastorders`
                                    )
                                }
                            />
                        </Grid>
                        {/* setting Icon */}
                        <Grid item>
                            <CustomIconButton
                                onClick={() =>
                                    navigate(`/${getLanguageFromURL()}/display/kitchen/settings`)
                                }
                                marginTop='-1%'
                                icon={<SettingsIcon />}
                                sx={{ borderRadius: "20px", minWidth: "40px" }}
                                iconSx={{ margin: 0 }}
                                tooltipTitle={t("menu.settings")}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {MemoizedBody}
            </Box>
        );
    }
}

export default KitchenDisplayComponent;
