import CustomControlButton from "components/Button/CustomControlButton";
import { MenuBook as MenuBookIcon, EventRepeat as EventRepeatIcon, Close as CloseIcon, Done as DoneIcon } from "@mui/icons-material"
import CustomChip from "components/Chip/CustomChip";
import { dateTimeFormatter, days_between } from "./functions";
import { Grid, SvgIcon, Typography } from "@mui/material";
import CustomGrid from "components/Grid/CustomGrid";
import CustomEditButton from "components/Button/CustomEditButton";
import CustomDeleteButton from "components/Button/CustomDeleteButton";
import CustomIconButton from "components/Button/CustomIconButton";
import { getOrderButtonIcon, mainTagSearchItemOrderStatusIcon, orderButton, viewButton } from "themes/defaultThemes";
import { getDateFormatter } from "constants/calendar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHands } from "@fortawesome/free-solid-svg-icons";
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { VisibilityIcon } from "assets/mui/MuiIcons";

/**
 * This function will format the roles to the required format.
 * 
 * @param {*} cell the cell of the table of the row that being formatted
 * @param {*} rowIndex  the row index of the table to be formatted
 * @param {*} formatExtraData parse extra data
 */
export function rolesFormatter(cell, rowIndex, formatExtraData) {
    if (cell !== null) {
        if (cell?.length === 0) {
            return <span>{formatExtraData.emptyMessage}</span>
        } else if (cell?.length > 0) {
            let object = [];
            for (let i = 0; i < cell.length; i++) {
                object.push(<CustomChip label={cell[i]} rowIndex={rowIndex} />);
            }
            return object;
        }
    }

    return (
        <span>{formatExtraData.emptyMessage}</span>
    );
}

/**
 * This function will format the date to the required format.
 * 
 * @param {*} cell the cell of the table of the row that being formatted
 * @param {*} row the row of the table to be formatted
 * @param {*} field the row field name
 */
export function dateFormatter(cell, row, field, formatter = "DD/MM/yyyy - HH:mm:ss", t, chipSx) {
    if (cell) {
        return <div>
            <span>{dateTimeFormatter(cell, formatter)}</span>
            {field === "dueDate" &&
                <CustomChip label={`${days_between(cell)}  ${t('label.days')}`} sx={chipSx} />
            }
        </div>
    }

    return (
        cell
    );
}

/**
 * This function will format the date to the required format.
 * 
 * @param {*} cell the cell of the table of the row that being formatted
 * @param {*} row the row of the table to be formatted
 * @param {*} rowIndex  the row index of the table to be formatted
 * @param {*} formatExtraData parse extra data
 */
export function nanFormatter(cell, row, rowIndex, formatExtraData = { emptyMessage: 'N/A' }) {
    if (cell === null || cell === "") {
        return formatExtraData.emptyMessage;
    } else {
        return (
            cell
        );
    }
}

/**
 * This function will format the control field to the required format.
 * 
 * @param {*} cell the cell of the table of the row that being formatted
 * @param {*} row the row of the table to be formatted
 * @param {*} rowIndex  the row index of the table to be formatted
 * @param {*} formatExtraData parse onclick functions for enable/disable a control field
 */
export function iconFormatter(cell, row, rowIndex, formatExtraData) {
    return <CustomControlButton shownLabel={false} onClick={(event) => cell ? formatExtraData.disable(event, row[formatExtraData.positionOfId]) : formatExtraData.enable(event, row[formatExtraData.positionOfId])} isEnabled={cell} />;
}


/**
 * This function will format the identifier (id) to the required format.
 * 
 * @param {*} cell the cell of the table of the row that being formatted
 * @param {*} row the row of the table to be formatted
 */
export function idFormatterLinkWithParentAndNameLabel(cell, row, href) {
    if (cell) {
        return <a className="link" href={href + '/' + cell.id}>{cell.name}</a>
    } else {
        return ""
    }
}

/**
 * This function will format the equipment details to the required format.
 * 
 * @param {*} cell the cell of the table of the row that being formatted
 * @param {*} href the  link to redirect to
 */
export function idFormatterLinkWithParentAndNameLabelEquipment(cell, href) {
    if (cell) {
        return <a className="link" href={href + '/' + cell.id}>{cell.label}</a>
    } else {
        return ""
    }
}

/**
 * This function will format the identifier (id) to the required format.
 * 
 * @param {*} cell the cell of the table of the row that being formatted
 * @param {*} row the row of the table to be formatted
 */
export function idFormatterLinkWithParentAndNameLabelMultilingual(cell, row, href, lang = "en", positionName) {
    if (cell) {
        return <a className="link" href={href + '/' + cell}>{row[positionName][lang]}</a>
    } else {
        return ""
    }
}

/**
 * This function will format the identifier (id) to the required format.
 * 
 * @param {*} cell the cell of the table of the row that being formatted
 * @param {*} row the row of the table to be formatted
 */
export function idFormatterLinkWithParent(cell, row, href, afterHref = "") {
    if (cell) {
        return <a className="link" href={href + '/' + cell + afterHref}>{cell}</a>
    } else {
        return ""
    }
}

/**
 * This function will format the name to the required format.
 * 
 * @param {*} cell the cell of the table of the row that being formatted
 * @param {*} row the row of the table to be formatted
 */
export function nameFormatterLinkWithParentMultilingual(cell, row, href, afterHref = "", lang = "en", positionOfId) {
    if (cell) {
        return <a className="link" href={href + '/' + row[positionOfId] + afterHref}>{cell[lang]}</a>
    } else {
        return ""
    }
}



/**
 * This function will format the name to the required format.
 * 
 * @param {*} cell the cell of the table of the row that being formatted
 * @param {*} row the row of the table to be formatted
 */
export function nameFormatterLinkWithParent(cell, row, href, afterHref = "", positionOfId) {
    if (cell) {
        return <a className="link" href={href + '/' + row[positionOfId] + afterHref}>{cell}</a>
    } else {
        return ""
    }
}

/**
 * This function will format the status to the required format.
 * 
 * @param {*} cell the cell of the table of the row that being formatted
 * @param {*} row the row of the table to be formatted
 * @param {*} rowIndex  the row index of the table to be formatted
 * @param {*} formatExtraData parse extra data
 */
export function statusFormatter(cell, row, rowIndex, formatExtraData) {
    if (cell !== null) {
        return <CustomChip label={cell} rowIndex={rowIndex} />;
    }

    return (
        <span>{formatExtraData.emptyMessage}</span>
    );
}


/**
 * This function will format the type to the required format.
 * 
 * @param {*} cell the cell of the table of the row that being formatted
 * @param {*} row the row of the table to be formatted
 * @param {*} formatExtraData the not find message
 */
export function typeFormatter(cell, row, formatExtraData) {
    if (cell !== null) {
        return <CustomChip label={cell} />;
    }

    return (
        <span>{formatExtraData.message}</span>
    );
}

/**
 * This function will format the user roles to the required format.
 * 
 * @param {*} value the value to be formatted
 * @param {*} sx style properties
 * @param {*} extraText the extra end text for label
 */
export function fieldFormatter(value, sx, extraText = "") {
    return value === "" || value === null || value === undefined ? "N/A" : <CustomChip label={value + extraText} sx={sx} />;
}

/**
 * This function will format a colored chip for boolean fields.
 * 
 * @param {*} value the value to be formatted
 * @param {*} sx style properties
 * @param {*} extraText the extra end text for label
 */
export function dotFieldFormatter(value) {
    let style = {
        background: value ? "green" : "red",
        color: "#54b1d2",
        height: "25px"
    }
    return <CustomChip label={value} sx={style} />;
}

/**
 * This function will format the customer username to the required format.
 * 
 * @param {*} cell the cell of the table of the row that being formatted
 * @param {*} row the row of the table to be formatted
 */
export function customerUsernameFormatterLink(cell, row, href) {
    if (cell) {
        return <a className="link" href={href + '/' + cell?.id}>{cell?.username}</a>
    } else {
        return ""
    }
}

/**
 * This function will format username to the required format.
 * 
 * @param {*} cell the cell of the table of the row that being formatted
 * @param {*} row the row of the table to be formatted
 * @param {*} rowIndex the row index of the table to be formatted
 */
export function itemNameFormatter(value, row, positionDescription, href) {
    if (row[positionDescription] !== null && row[positionDescription] !== "") return <><a href={href} style={{ fontSize: "14px" }}>{value}</a><br></br><p style={{ fontSize: "10px" }}>{row[positionDescription].en}</p></>;
    else return <a href={href} style={{ fontSize: "14px" }}>{value}</a>;
}

/**
 * This function will format username to the required format.
 * 
 * @param {*} cell the cell of the table of the row that being formatted
 * @param {*} row the row of the table to be formatted
 * @param {*} rowIndex the row index of the table to be formatted
 */
export function emailFormatter(cell, row, rowIndex) {
    return <input type="email" id={"email-" + rowIndex} name={"email-" + rowIndex} defaultValue={cell} />
}

/**
 * This function will format the organization emails to the required format.
 * 
 * @param {*} cell the cell of the table of the row that being formatted
 * @param {*} row the row of the table to be formatted
 */
// eslint-disable-next-line no-unused-vars
function emailsFormatter(cell, row) {
    if (row.emails !== null) {
        if (row.emails.length === 0) {
            return <span>N/A</span>
        } else if (row.emails.length > 0) {
            let object = [];
            for (let i = 0; i < row.emails.length; i++) {
                let email = (row.emails.length - 1 !== i) ? row.emails[i] + ", " : row.emails[i];
                object.push(<span key={"tr-" + i}>{email}</span>);
            }
            return object;
        }
    }

    return (
        <span>N/A</span>
    );
}

/**
 * This function will format the organization telephones to the required format.
 * 
 * @param {*} cell the cell of the table of the row that being formatted
 * @param {*} row the row of the table to be formatted
 */
// eslint-disable-next-line no-unused-vars
function telephonesFormatter(cell, row) {
    if (row.telephones !== null) {
        if (row.telephones.length === 0) {
            return <span>N/A</span>
        } else if (row.telephones.length > 0) {
            let object = [];
            for (let i = 0; i < row.telephones.length; i++) {
                let telephone = (row.telephones.length - 1 !== i) ? row.telephones[i] + ", " : row.telephones[i];
                object.push(<span key={"tr-" + i}>{telephone}</span>);
            }
            return object;
        }
    }

    return (
        <span>N/A</span>
    );
}

export function recurrenceFormatter(cell, row, recurrenceLabelStart, recurrenceLabelEnd, startDateLabel, endDateLabel, positionOfRecurrenceDaysNumber, positionOfStartDate, positionOfEndDate) {
    if (!cell) return <EventRepeatIcon style={{ color: "red" }} />
    else return <>
        <div style={{ display: "flex" }}>
            <EventRepeatIcon style={{ color: "green" }} />
            <Typography variant="subtitle2" sx={{ fontWeight: 600, paddingRight: "10px", paddingLeft: "10px" }}>{`${recurrenceLabelStart}:`}</Typography>
            <Typography variant="subtitle2">{`${row[positionOfRecurrenceDaysNumber]} ${recurrenceLabelEnd}`}</Typography>
        </div>
        <div style={{ display: "flex", paddingLeft: "35px" }}>
            <Typography variant="subtitle2" sx={{ fontWeight: 600, paddingRight: "10px" }}>{startDateLabel}</Typography>
            <Typography variant="subtitle2">{dateTimeFormatter(row[positionOfStartDate], getDateFormatter())}</Typography>
        </div>
        {(row[positionOfEndDate] !== null && row[positionOfEndDate] !== "") &&
            <div style={{ display: "flex", paddingLeft: "35px" }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 600, paddingRight: "10px" }}>{endDateLabel}</Typography>
                <Typography variant="subtitle2">{dateTimeFormatter(row[positionOfEndDate], getDateFormatter())}</Typography>
            </div>
        }
    </>
}

/**
 * This function will format the actions to the required format.
 * 
 * @param {*} cell the cell of the table of the row that being formatted
 * @param {*} row the row of the table to be formatted
 * @param {*} rowIndex  the row index of the table to be formatted
 */
export function actionFormatter(cell, row, rowIndex, positionOfId, positionOfLabel, positionOfFirstName, positionOfLastName, positionOfName, editLink, editLabel, deleteOnClick, deleteLabel, isMultilingualName = false, isLabel = false, isName = false) {
    let fullName = "";
    if (isLabel) {
        if (row[positionOfLabel] !== undefined) fullName += ` ${row[positionOfLabel]}`;
    } else if (isName) {
        if (row[positionOfName] !== undefined) fullName += ` ${row[positionOfName]}`;
    } else if (!isMultilingualName) {
        if (row[positionOfFirstName] !== undefined) fullName += ` ${row[positionOfFirstName]}`;
        if (row[positionOfLastName] !== undefined) fullName += ` ${row[positionOfLastName]}`;
    } else if (isMultilingualName) {
        if (row[positionOfName] !== undefined) fullName += ` ${row[positionOfName]?.en}`;
    }

    return (
        <CustomGrid directioon="column" spacing={2} rowSpacing={2} sx={{ justifyContent: "center" }}>
            <CustomEditButton key={`edit-${rowIndex}`} link={`${editLink}/${row[positionOfId]}`} label={editLabel} sx={{ width: "20px" }} />
            {deleteOnClick && <CustomDeleteButton key={`delete-${rowIndex}`} onClick={(event) => deleteOnClick(event, row[positionOfId], fullName)} label={deleteLabel} />}
        </CustomGrid>
    );
}

/**
 * This function will format the actions to the required format.
 * 
 * @param {*} cell the cell of the table of the row that being formatted
 * @param {*} row the row of the table to be formatted
 * @param {*} rowIndex  the row index of the table to be formatted
 */
export function actionFormatterLoyalty(cell, row, rowIndex, positionOfId, positionOfName, editOnClick, editLabel, deleteOnClick, deleteLabel) {

    return (
        <CustomGrid directioon="column" spacing={2} rowSpacing={2} sx={{ justifyContent: "center" }}>
            <CustomEditButton key={`edit-${rowIndex}`} onClick={(event) => editOnClick(event, row[positionOfId], row[positionOfName])} label={editLabel} sx={{ width: "20px" }} />
            <CustomDeleteButton key={`delete-${rowIndex}`} onClick={(event) => deleteOnClick(event, row[positionOfId], row[positionOfName])} label={deleteLabel} />
        </CustomGrid>
    );
}

/**
 * This function will format the actions to the required format.
 * 
 * @param {*} cell the cell of the table of the row that being formatted
 * @param {*} row the row of the table to be formatted
 * @param {*} rowIndex  the row index of the table to be formatted
 */
export function actionFormatterEquipment(cell, row, rowIndex, positionOfId, positionOfLabel, editPath, editLabel, deleteOnClick, deleteLabel, exportQR, exportQRLabel, tooltipTitle) {

    return (
        <CustomGrid directioon="column" spacing={2} rowSpacing={2} sx={{ justifyContent: "center" }}>
            <CustomEditButton key={`edit-${rowIndex}`} onClick={() => window.location.href = `${editPath}/${row[positionOfId]}`} label={editLabel} sx={{ width: "20px" }} />
            <CustomDeleteButton key={`delete-${rowIndex}`} onClick={(event) => deleteOnClick(event, row[positionOfId], row[positionOfLabel])} label={deleteLabel} />
            <CustomIconButton
                key={`export-qr-${rowIndex}`}
                tooltipTitle={tooltipTitle}
                label={exportQRLabel}
                variant='outlined'
                backgroundColor="#dcf1f7"
                onClick={(event) => exportQR(event, row[positionOfId], row[positionOfLabel])}
                icon={<SvgIcon component={QrCode2Icon} width={60} height={60} style={{ background: "transparent", color: "#54b1d2" }} />} />
        </CustomGrid>
    );
}

/**
 * This function will format the actions to the required format (CMS).
 * 
 * @param {*} cell the cell of the table of the row that being formatted
 * @param {*} row the row of the table to be formatted
 * @param {*} rowIndex  the row index of the table to be formatted
 */
export function actionFormatterMenuCMS(cell, row, rowIndex, positionOfId, positionOfName, editLink, editLabel, deleteOnClick, deleteLabel, cmsLabel, positionOfSettings) {
    return (
        <CustomGrid directioon="column" spacing={2} rowSpacing={2} sx={{ justifyContent: "right" }}>
            {(positionOfSettings && row[positionOfSettings]?.allowParentCategories && row[positionOfSettings]?.showAdvancedCMS) && <CustomIconButton key={`view-${rowIndex}`} link={`${editLink}/${row[positionOfId]}/cms`} label={cmsLabel} sx={{ width: "20px", ...viewButton() }} icon={<MenuBookIcon />} />}
            <CustomEditButton key={`edit-${rowIndex}`} link={`${editLink}/${row[positionOfId]}`} label={editLabel} sx={{ width: "20px" }} />
            <CustomDeleteButton key={`delete-${rowIndex}`} onClick={(event) => deleteOnClick(event, row[positionOfId], row[positionOfName].en)} label={deleteLabel} />
            {/* <CustomDeleteButton key={`duplicate-${rowIndex}`} onClick={(event) => deleteOnClick(event, row[positionOfId], row[positionOfName].en)} label={deleteLabel} /> */}
        </CustomGrid>
    );
}

/**
 * This function will format the actions to the required format.
 * 
 * @param {*} cell the cell of the table of the row that being formatted
 * @param {*} row the row of the table to be formatted
 * @param {*} rowIndex  the row index of the table to be formatted
 */
export function actionFormatterCampaign(cell, row, rowIndex, positionOfId, editLink, editLabel, viewLabel, deleteOnClick, deleteLabel, showLabel) {
    return (
        <CustomGrid directioon="column" spacing={2} rowSpacing={2} sx={{ justifyContent: "center" }}>
            {viewLabel && <CustomIconButton key={`view-${rowIndex}`} link={`${editLink}/${row[positionOfId]}`}
                tooltipTitle={viewLabel}
                label={showLabel ? viewLabel : undefined}
                variant='outlined'
                backgroundColor="#dcf1f7"
                icon={<VisibilityIcon />}
                sx={{ width: "20px" }}
                iconSx={{ color: "#56b1cc !important", mr: "initial" }}
            />
            }

            {editLabel && <CustomEditButton key={`edit-${rowIndex}`} link={`${editLink}/${row[positionOfId]}`} label={showLabel ? editLabel : undefined} sx={{ width: "20px" }} />}
            {deleteOnClick && <CustomDeleteButton key={`delete-${rowIndex}`} onClick={(event) => deleteOnClick(event, row[positionOfId], '')} label={showLabel ? deleteLabel : undefined} />}
        </CustomGrid>
    );
}

/**
 * This function will format the date to the required format.
 * 
 * @param {*} cell the cell of the table of the row that being formatted
 * @param {*} row the row of the table to be formatted
 * @param {*} rowIndex  the row index of the table to be formatted
 */
export function orderActionFormatter(t, cell, row, rowIndex, orderIdIndex, orderId, idIndex, doneIndex, buttons, isOrder = false, isModifier = false, backgroundColor = "#e8eeef") {
    return (
        <Grid directioon="column" gap={2} rowSpacing={2} sx={{ justifyContent: "center", width: "100%" }}>
            {buttons.map((button) =>
                <CustomIconButton
                    tooltipTitle={t(`order.actions.statusCaps.${button.key}`)}
                    key={`${button.key}-${rowIndex}`}
                    onClick={(event) => button.onClick(event, isOrder ? row[orderIdIndex] : row.orderID, (!isOrder && !isModifier) ? row.id : undefined)}
                    icon={button.icon}
                    backgroundColor={backgroundColor}
                    variant="outlined"
                    disabled={button.disabled}
                    iconSx={mainTagSearchItemOrderStatusIcon()}
                    sx={{ ...orderButton(), width: "auto", margin: "3px" }}
                    isFocused={(isOrder ? row[doneIndex] : row.done) === button.key}
                />
            )}
        </Grid>
    );
}
/**
 * This function will format the date to the required format.
 * 
 * @param {*} cell the cell of the table of the row that being formatted
 * @param {*} row the row of the table to be formatted
 * @param {*} rowIndex  the row index of the table to be formatted
 */
export function requestActionFormatter(cell, row, rowIndex, positionOfId, buttons, backgroundColor = "#e8eeef") {
    return (
        <Grid directioon="column" gap={2} rowSpacing={2} sx={{ justifyContent: "center", width: "100%" }}>
            {buttons?.map((button) =>
                <CustomIconButton
                    key={`${button.key}-${rowIndex}`}
                    onClick={(event) => button.onClick(event, row[positionOfId])}
                    icon={button.icon}
                    backgroundColor={backgroundColor}
                    variant="outlined"
                    disabled={button.disabled}
                    iconSx={mainTagSearchItemOrderStatusIcon()}
                    sx={{ ...orderButton(), width: "auto", margin: "3px" }}
                    isFocused={cell === button.key}
                />
            )}
        </Grid>
    );
}

export function orderActionCellButtonsFormatter(changeOrderStatus, order, statusIndex) {
    let buttons = [];
    let disabled = order[statusIndex] !== "PENDING";
    buttons.push({
        key: "WORKING",
        onClick: ((event, orderID) => changeOrderStatus(event, orderID, "WORKING")),
        icon: <FontAwesomeIcon icon={faHands} />,
        iconSx: { color: disabled ? "rgba(0, 0, 0, 0.26)" : "cornflowerblue" },
        disabled: disabled
    })

    disabled = order[statusIndex] !== "WORKING";
    buttons.push({
        key: "DONE",
        onClick: ((event, orderID) => changeOrderStatus(event, orderID, "DONE")),
        icon: <DoneIcon sx={{ color: disabled ? "rgba(0, 0, 0, 0.26)" : "green" }} />,
        disabled: disabled
    })

    disabled = order[statusIndex] !== "PENDING" && order[statusIndex] !== "DONE";
    buttons.push({
        key: "CANCELED",
        onClick: ((event, orderID) => changeOrderStatus(event, orderID, "CANCELED")),
        icon: <CloseIcon sx={{ color: disabled ? "rgba(0, 0, 0, 0.26)" : "red" }} {...getOrderButtonIcon()} />,
        disabled: disabled
    })
    return buttons;
}

export function orderActionCellButtonsFormatterEdit(changeOrderStatus, order) {
    let buttons = [];
    let disabled = order.status !== "PENDING";
    buttons.push({
        key: "WORKING",
        onClick: ((event, orderID) => changeOrderStatus(event, orderID, "WORKING")),
        icon: <FontAwesomeIcon icon={faHands} />,
        iconSx: { color: disabled ? "rgba(0, 0, 0, 0.26)" : "cornflowerblue" },
        disabled: disabled
    })

    disabled = order.status !== "WORKING";
    buttons.push({
        key: "DONE",
        onClick: ((event, orderID) => changeOrderStatus(event, orderID, "DONE")),
        icon: <DoneIcon sx={{ color: disabled ? "rgba(0, 0, 0, 0.26)" : "green" }} />,
        disabled: disabled
    })

    disabled = order.status !== "PENDING" && order.status !== "DONE";
    buttons.push({
        key: "CANCELED",
        onClick: ((event, orderID) => changeOrderStatus(event, orderID, "CANCELED")),
        icon: <CloseIcon sx={{ color: disabled ? "rgba(0, 0, 0, 0.26)" : "red" }} {...getOrderButtonIcon()} />,
        disabled: disabled
    })
    return buttons;
}

export function orderActionCellItemButtonsFormatter(changeItemStatus, itemDone, index) {
    let buttons = [];
    buttons.push({
        key: "true",
        onClick: ((event, orderID, modifierID) => changeItemStatus(event, orderID, modifierID, "true", index)),
        icon: <DoneIcon sx={{ color: itemDone ? "rgba(0, 0, 0, 0.26)" : "green" }} {...getOrderButtonIcon()} />,
        disabled: itemDone
    })
    buttons.push({
        key: "false",
        onClick: ((event, orderID, modifierID) => changeItemStatus(event, orderID, modifierID, "false", index)),
        icon: <CloseIcon sx={{ color: !itemDone ? "rgba(0, 0, 0, 0.26)" : "red" }} {...getOrderButtonIcon()} />,
        disabled: !itemDone
    })
    return buttons;
}

export function requestActionCellItemButtonsFormatter(changRequestStatus, requestDone) {
    let buttons = [];
    buttons.push(
        {
            key: "DONE",
            onClick: ((event, requestID) => changRequestStatus(event, requestID, "DONE")),
            icon: <DoneIcon sx={{ color: requestDone ? "rgba(0, 0, 0, 0.26)" : "green" }} {...getOrderButtonIcon()} />,
            disabled: requestDone === "DONE"
        },
        {
            key: "PENDING",
            onClick: ((event, requestID) => changRequestStatus(event, requestID, "PENDING")),
            icon: <CloseIcon sx={{ color: requestDone ? "rgba(0, 0, 0, 0.26)" : "red" }} {...getOrderButtonIcon()} />,
            disabled: requestDone === "PENDING"
        })
    return buttons;
}

export function fixItemInArray(array, item) {
    if (array.includes(item)) {
        return array.filter((i) => i !== item);
    } else {
        return [...array, item];
    }
}

export default class functions {
    static rolesFormatter(cell, row, rowIndex, formatExtraData) { return rolesFormatter(cell, row, rowIndex, formatExtraData); };
    static dateFormatter(cell, row,) { return dateFormatter(cell, row); };
    static nanFormatter(cell, row, rowIndex, formatExtraData) { return nanFormatter(cell, row, rowIndex, formatExtraData); };
    static iconFormatter(cell, row, rowIndex, formatExtraData) { return iconFormatter(cell, row, rowIndex, formatExtraData); };
    static idFormatterLinkWithParentAndNameLabelMultilingual(cell, row, href, lang) { return idFormatterLinkWithParentAndNameLabelMultilingual(cell, row, href, lang); };
    static idFormatterLinkWithParentAndNameLabel(cell, row, href) { return idFormatterLinkWithParentAndNameLabel(cell, row, href); };
    static idFormatterLinkWithParent(cell, row, href) { return idFormatterLinkWithParent(cell, row, href); };
    static nameFormatterLinkWithParentMultilingual(cell, row, href, afterHref, lang) { return nameFormatterLinkWithParentMultilingual(cell, row, href, afterHref, lang); };
    static statusFormatter(cell, row, rowIndex, formatExtraData) { return statusFormatter(cell, row, rowIndex, formatExtraData); };
    static typeFormatter(cell, row, formatExtraData) { return typeFormatter(cell, row, formatExtraData); };
    static fieldFormatter(cell, row) { return fieldFormatter(cell, row); };
    static customerUsernameFormatterLink(cell, row, href) { return customerUsernameFormatterLink(cell, row, href); }
    static emailFormatter(cell, row, rowIndex) { return emailFormatter(cell, row, rowIndex); };
    static recurrenceFormatter(cell, row) { return recurrenceFormatter(cell, row); };
    static actionFormatter(cell, row, rowIndex, formatExtraData, editLink, editLabel, deleteOnClick, deleteLabel) { return actionFormatter(cell, row, rowIndex, formatExtraData, editLink, editLabel, deleteOnClick, deleteLabel); };
    static actionFormatterMenuCMS(cell, row, rowIndex, editLink, editLabel, deleteOnClick, deleteLabel, cmsLabel) { return actionFormatterMenuCMS(cell, row, rowIndex, editLink, editLabel, deleteOnClick, deleteLabel, cmsLabel); };
    static orderActionFormatter(cell, row, rowIndex, buttons, isOrder, disabled, backgroundColor) { return orderActionFormatter(cell, row, rowIndex, buttons, isOrder, disabled, backgroundColor); };
    static itemNameFormatter(value, description, href) { return itemNameFormatter(value, description, href); };
    static nameFormatterLinkWithParent(cell, row, href, afterHref) { return nameFormatterLinkWithParent(cell, row, href, afterHref); }
    static actionFormatterLoyalty(cell, row, rowIndex, editOnClick, editLabel, deleteOnClick, deleteLabel) { return actionFormatterLoyalty(cell, row, rowIndex, editOnClick, editLabel, deleteOnClick, deleteLabel); }
    static orderActionCellButtonsFormatter(changeOrderStatus, orderStatus) { return orderActionCellButtonsFormatter(changeOrderStatus, orderStatus); }
    static orderActionCellItemButtonsFormatter(changeItemStatus, itemDone) { return orderActionCellItemButtonsFormatter(changeItemStatus, itemDone); }
    static actionFormatterEquipment(cell, row, rowIndex, editOnClick, editLabel, deleteOnClick, deleteLabel, exportQR, exportQRLabel, tooltipTitle) { actionFormatterEquipment(cell, row, rowIndex, editOnClick, editLabel, deleteOnClick, deleteLabel, exportQR, exportQRLabel, tooltipTitle) }
    static requestActionFormatter(cell, row, rowIndex, buttons, backgroundColor) { requestActionFormatter(cell, row, rowIndex, buttons, backgroundColor); }
    static requestActionCellItemButtonsFormatter(changRequestStatus, requestDone) { requestActionCellItemButtonsFormatter(changRequestStatus, requestDone); }
}