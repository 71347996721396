import { Breadcrumbs, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { breadcrumbsLinkStyle, breadcrumbsStyle, breadcrumbsTextStyle } from 'themes/defaultThemes';

/**
 * The CustomAddEditBreadcrumbMultiParent, that display an add/edit page custom breadcrumb.
 * @property {boolean} isAdd If the values is `true`, the page is about creating a new instance.
 *                              Otherwise referring to the update of a instance details.
 * @property {array} parents the parents details (url and name)
 * @property {string} instanceName the instance name to be editted if the {@link isAdd} is false
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomAddEditBreadcrumbMultiParent({ isAdd = true, parents, instanceName, editLabel = 'actions.edit' }) {
    const { t } = useTranslation();

    const breadcrumbs = [];
    parents.map((parent, index) =>
        breadcrumbs.push(
            <Link underline="hover" key={index} color="inherit" href={parent.url} sx={breadcrumbsLinkStyle()}>
                {parent.name}
            </Link>
        )
    )

    breadcrumbs.push(
        <Typography key={parents.length} color="text.primary" sx={breadcrumbsTextStyle()}>
            {isAdd ? t('actions.create') : (instanceName === undefined ? t(`${editLabel}`) : instanceName)}
        </Typography>
    )

    return (
        <Breadcrumbs separator="›" aria-label="breadcrumb" sx={breadcrumbsStyle()}>
            {breadcrumbs}
        </Breadcrumbs>
    );
}

export default CustomAddEditBreadcrumbMultiParent;