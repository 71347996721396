import AddIcon from "@mui/icons-material/Add";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import MenuIcon from "@mui/icons-material/Menu";
import {
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Tooltip
} from "@mui/material";
import CustomTitle from "components/Title/CustomTitle";
import { useTranslation } from "react-i18next";
import { findNameMultilingual } from "utils/functions";
import { mainArea } from "themes/defaultThemes";
export default function Header({
    loading,
    showParent,
    setShowParent,
    onAddClick,
    saveClickHandler,
    showLeftSider,
    showRightSider,
    toggleLeftSideBar,
    toggleRightSideBar,
    showItemsImages,
    toggleItemImages,
    showFullScreen,
    setShowFullScreen,
    menuNames = null
}) {
    const { t } = useTranslation();

    return (
        <Grid
            container
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
            sx={{
                ...mainArea(),
                marginTop: "-5px",
                paddingBottom: "20px",
                marginLeft: "0.5px",
                borderRadius: 0,
            }}
        >
            <Grid item sx={{ flex: 1 }}>
                <Grid container direction="row">
                    <Grid item xs={12} sm={12}>
                        {!showFullScreen &&
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} sx={{ textAlign: "left", marginBottom: "20px" }}>
                                    <CustomTitle
                                        title={`${menuNames !== null ? findNameMultilingual(menuNames, localStorage.getItem("i18nextLng")) : ""} - ${showParent ? t('menuCategories.parentCategories') : t('menuCategories.plain')}`}
                                        subtitle={""}
                                    />
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                    <Grid item sx={{ flex: 1 }}>
                        <Grid container sx={{ flex: 1 }} >
                            <Grid item>
                                <Tooltip
                                    title={
                                        showParent
                                            ? showLeftSider
                                                ? t('menus.cms.hideCategories')
                                                : t('menus.cms.showCategories')
                                            : showLeftSider
                                                ? t('menus.cms.hideItems')
                                                : t('menus.cms.showItems')
                                    }
                                >
                                    <IconButton
                                        onClick={() => toggleLeftSideBar()}
                                        sx={{ p: 0, background: "transparent" }}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip
                                    title={showFullScreen ? t('menus.cms.hideFullScreen') : t('menus.cms.fullscreen')}
                                >
                                    <IconButton
                                        onClick={() => setShowFullScreen((s) => !s)}
                                        sx={{ p: 0, background: "transparent" }}
                                    >
                                        {showFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant='text'
                                    size='small'
                                    sx={{
                                        fontSize: "12px",
                                        px: "10px",
                                        color: "#47a6d2",
                                        textTransform: "none",
                                    }}
                                    onClick={toggleItemImages}
                                >
                                    {showItemsImages
                                        ? showParent
                                            ? t('menus.cms.hideCategoriesImages')
                                            : t('menus.cms.hideItemImages')
                                        : showParent
                                            ? t('menus.cms.showCategoriesImages')
                                            : t('menus.cms.showItemImages')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* add, save room */}
                    <Grid item>
                        <Grid container alignItems='center' sx={{ gap: "20px" }}>
                            <Grid item>
                                <Button
                                    variant='outlined'
                                    size='small'
                                    sx={{
                                        borderRadius: "2px",
                                        fontSize: "12px",
                                        px: "10px",
                                        color: "#47a6d2",
                                        borderColor: "#47a6d2",
                                        textTransform: "none",
                                        fontWeight: "500",
                                        height: "30px"
                                    }}
                                    onClick={() => setShowParent((f) => !f)}
                                >
                                    {showParent ? t('menus.cms.goToCategories') : t('menus.cms.goToParentCategories')}
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button
                                    variant='text'
                                    size='small'
                                    sx={{
                                        fontSize: "12px",
                                        px: "10px",
                                        color: "#47a6d2",
                                        textTransform: "none",
                                    }}
                                    startIcon={<AddIcon />}
                                    onClick={onAddClick}
                                >
                                    {showParent ? t('menus.cms.addNewParentCategory') : t('menus.cms.addNewCategory')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant='contained'
                                    size='small'
                                    sx={{
                                        fontSize: "12px",
                                        px: "15px",
                                        background: "#47a6d2",
                                        color: "#fff",
                                        textTransform: "none",
                                        borderRadius: "2px",
                                        ":hover": {
                                            background: "#47a6d2",
                                        },
                                    }}
                                    startIcon={
                                        loading?.active &&
                                        loading?.action === "save" && (
                                            <CircularProgress size='0.8rem' sx={{ color: "#fff" }} />
                                        )
                                    }
                                    disabled={loading?.active && loading?.action === "save"}
                                    onClick={saveClickHandler}
                                >
                                    {t('actions.save')}
                                </Button>
                            </Grid>
                            <Grid item sx={{ paddingRight: "10px" }}>
                                <Tooltip
                                    title={
                                        showParent
                                            ? showRightSider
                                                ? t('menus.cms.hideParentCategories')
                                                : t('menus.cms.showParentCategories')
                                            : showRightSider
                                                ? t('menus.cms.hideCategories')
                                                : t('menus.cms.showCategories')
                                    }
                                >
                                    <IconButton
                                        onClick={() => toggleRightSideBar()}
                                        sx={{ p: 0, background: "transparent" }}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
