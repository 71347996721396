import {
    Add as AddIcon,
    Article as ArticleIcon,
    Business as BusinessIcon,
    Delete as DeleteIcon,
    Email as EmailIcon,
    FeaturedPlayList as FeaturedPlayListIcon,
    Groups as GroupsIcon,
    Save as SaveIcon,
    Sms as SmsIcon,
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon
} from '@mui/icons-material';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import { Button, CardMedia, Fab, FormControlLabel, Grid, InputLabel, Stack, SvgIcon, Switch } from "@mui/material";
import organizationApi from "api/organization";
import en from "assets/images/flags/en.png";
import CustomAddEditBreadcrumb from "components/Breadcrumb/CustomAddEditBreadcrumb";
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomCard from 'components/Card/CustomCard';
// import CustomDateTimePicker from 'components/DatePicker/CustomDateTimePicker';
import { TabContext, TabPanel } from '@mui/lab';
import vatApi from 'api/vat';
import { ReceiptIcon } from 'assets/mui/MuiIcons';
import CustomImageCropper from 'components/Assets/CustomImageCropper';
import CustomTranslationsDialog from "components/Dialogs/CustomTranslationsDialog";
import CustomFieldset from 'components/Fieldset/CustomFieldset';
import CustomGrid from 'components/Grid/CustomGrid';
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomObjectSelect from 'components/Select/CustomObjectSelect';
import CustomSelectWithChip from "components/Select/CustomSelectWithChip";
import CustomSelectWithChipMultilingual from 'components/Select/CustomSelectWithChipMultilingual';
import CustomSnackbar from "components/Snackbar/CustomSnackbar";
import CustomTabs from 'components/Tab/CustomTabs';
import CustomFlagTextField from 'components/Text/CustomFlagTextField';
import CustomTextField from 'components/Text/CustomTextField';
import CustomTextFieldWithIcons from 'components/Text/CustomTextFieldWithIcons';
import CustomAddEditTitle from "components/Title/CustomAddEditTitle";
import CustomTypography from 'components/Typography/CustomTypography';
import { getDefaultOrganization } from 'constants/defaultObjects';
import { MuiChipsInput } from "mui-chips-input";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import { cardItemButtonGroupStackButton, cardItemButtonGroupStackButtonIcon, fabStyle, formSaveButton, logoStyle, mainArea, mainTag, mainTagBreadcrumb, mainTagTitle, organizationGridStyle, organizationSettingsHeaderStyle, textFieldError } from 'themes/defaultThemes';
import { hasRoleAdmin, hasRoleAdminOrDirectorOrManager } from "utils/auth";
import { formIsValid, formValChangeWithParentElement, formValChangeWithParentElementObjectValues, formValChangeWithParentElementWithNameAndValue, formValChangeWithParents, onControlChange, onSelectChange } from "utils/form-validation";
import { validateVatNumber } from 'utils/functions';
import { getLanguageFromURL } from "utils/language";
import { getSocialFontAwesomeIcon } from 'utils/social';
import { emailFormatter } from 'utils/table';
import useDocumentTitle from "utils/useDocumentTitle";
import { getOrganizationButtonIconProperties, getOrganizationButtonProperties } from 'constants/fieldProperties';
import { RectangularShape, SquareShape } from 'assets/svg/SvgIcons';

/**
 * The OrganizationAddEditComponent that allow the ADMIN to create a 
 * new organization or edit an existing one.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function OrganizationAddEditComponent({ hasDirectorRole = false }) {
    const { t } = useTranslation();
    const { organizationID } = useParams();
    const navigate = useNavigate();

    /**
     * @type {object}
     * @property {*} icon the legend fa icon
     * @property {object} userAuth the authenticated user infromation
     * @property {boolean} isAdd to define if requested an edit or an add of the knowledge base category
     * @property {string} organizationID the organization id to get the current information for edit organization page
     * @property {object} organization the organization details
     * @property {array} socialProfileTypeOptions the different social profile type options
     * @property {array} notificationActionOptions the different notification action options (plain or email ones)
     * @property {array} tagOptions the different tag options
     * @property {array} outlets the organization's outlet basic details (id, name)
     * @property {object} isError list of indication of empty form required field 
     *                           after submit to present error message to the
     *                           fields/attributes with true value
     */
    const [data, setData] = useState({
        icon: AddIcon,
        userAuth: null,
        isAdd: organizationID === "add" || organizationID === undefined,
        organization: null,
        socialProfileTypeOptions: [],
        notificationActionOptions: [],
        emailNotificationActionOptions: [],
        tagOptions: [],
        outlets: [],
        subscriptionPlans: [],
        countries: [],
        actionsValue: [],//for notification action value field,
        employeesCounter: 0,
        outletsCounter: 0,
        menusCounter: 0,
        itemsOrModifiersCounter: 0,
        equipmentsCounter: 0,
        printersCounter: 0,
        counters: {},
        //api keu
        shownSecretIcon: VisibilityOffIcon,
        secretShown: false,
        // error messages per field
        isError: {
            name: "",
            vatNumber: "",
            planId: ""
        }
    });

    const [tabValue, setTabValue] = useState("details");
    // const [ticketTabValue, setTicketTabValue] = React.useState(printNotificationActions.length > 0 ? printNotificationActions[0] : null);

    // isLoaded to render DOM based on rest api call status, if true the rest api call completed
    const [isLoaded, setIsLoaded] = useState(false);
    const [redirect] = useState(false);
    // pop dialog for delete
    const [dialog, setDialog] = useState({
        isOpen: false,
        id: "",
        field: "",
        translations: {}
    });
    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313"
    });

    useEffect(() => {
        getOrganization();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    //change document title
    useDocumentTitle(`Usee | ${t('org.pageTitle')} | ${data.isAdd ? t('actions.add') : t('actions.edit')}`);

    /**
     * The rest endpoint to get the organization.
     */
    function getOrganization() {
        if (hasDirectorRole) {
            organizationApi.fetchInfo(organizationID).then((r) => {
                setData({
                    ...data,
                    organization: r.data.returnobject.organization,
                    userAuth: r.data.returnobject.userAuth,
                    outlets: r.data.returnobject.outlets,
                    socialProfileTypeOptions: r.data.returnobject.socialProfileTypeOptions,
                    emailNotificationActionOptions: r.data.returnobject.emailNotificationActionOptions,
                    notificationActionOptions: r.data.returnobject.notificationActionOptions,
                    tagOptions: r.data.returnobject.tagOptions,
                    subscriptionPlans: r.data.returnobject.subscriptionPlans,
                    employeesCounter: r.data.returnobject.employeesCounter,
                    outletsCounter: r.data.returnobject.outletsCounter,
                    menusCounter: r.data.returnobject.menusCounter,
                    modifierListsCounter: r.data.returnobject.modifierListsCounter,
                    itemsOrModifiersCounter: r.data.returnobject.itemsOrModifiersCounter,
                    equipmentsCounter: r.data.returnobject.equipmentsCounter,
                    printersCounter: r.data.returnobject.printersCounter,
                    countries: r.data.returnobject.countryList,
                    counters: r.data.returnobject.outletCounters,
                    isAdd: false
                });
                setIsLoaded(true);
            }).catch((e) => {
                // console.log(e);
            });
        } else {
            if (data.isAdd) {
                organizationApi.fetchNew(organizationID).then((r) => {
                    setData({
                        ...data,
                        userAuth: r.data.returnobject.userAuth,
                        organization: getDefaultOrganization(),
                        socialProfileTypeOptions: r.data.returnobject.socialProfileTypeOptions,
                        emailNotificationActionOptions: r.data.returnobject.emailNotificationActionOptions,
                        notificationActionOptions: r.data.returnobject.notificationActionOptions,
                        tagOptions: r.data.returnobject.tagOptions,
                        outlets: r.data.returnobject.outlets,
                        subscriptionPlans: r.data.returnobject.subscriptionPlans,
                        countries: r.data.returnobject.countryList,
                        counters: r.data.returnobject.outletCounters,
                        isAdd: organizationID === "add" || (organizationID === undefined && hasRoleAdmin(r.data.returnobject.userAuth.roles))
                    });
                    setIsLoaded(true);
                }).catch((e) => {
                    // console.log(e);
                });
            } else {
                organizationApi.fetchOne(organizationID).then((r) => {
                    setData({
                        ...data,
                        organization: r.data.returnobject.organization,
                        userAuth: r.data.returnobject.userAuth,
                        outlets: r.data.returnobject.outlets,
                        socialProfileTypeOptions: r.data.returnobject.socialProfileTypeOptions,
                        emailNotificationActionOptions: r.data.returnobject.emailNotificationActionOptions,
                        notificationActionOptions: r.data.returnobject.notificationActionOptions,
                        tagOptions: r.data.returnobject.tagOptions,
                        subscriptionPlans: r.data.returnobject.subscriptionPlans,
                        employeesCounter: r.data.returnobject.employeesCounter,
                        outletsCounter: r.data.returnobject.outletsCounter,
                        menusCounter: r.data.returnobject.menusCounter,
                        modifierListsCounter: r.data.returnobject.modifierListsCounter,
                        itemsOrModifiersCounter: r.data.returnobject.itemsOrModifiersCounter,
                        equipmentsCounter: r.data.returnobject.equipmentsCounter,
                        printersCounter: r.data.returnobject.printersCounter,
                        countries: r.data.returnobject.countryList,
                        counters: r.data.returnobject.outletCounters
                    });
                    setIsLoaded(true);
                }).catch((e) => {
                    // console.log(e);
                });
            }
        }
    }

    /**
     * Gets called when the user clicks on the save button, and triggers 
     * the creating of the organization.
     */
    function addOrganization() {
        if (formValidation()) {
            organizationApi.create(data.organization).then((r) => {
                setAlertBox({
                    isOpen: true,
                    message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                    backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                });
                if (r.data.code === "SUCCESS")
                    formValChangeWithParentElementWithNameAndValue("organization.id", r.data.returnobject, data, setData)
                formValChangeWithParentElementWithNameAndValue("isAdd", false, data, setData)
                navigate(`/${getLanguageFromURL()}/organization/${r.data.returnobject}`)
                // setRedirect((r.data.code === "SUCCESS" ? true : false));
            }).catch((e) => {
                // console.log(e)
            });
        }
    }

    /**
     * Function that triggers the modification of the organization
     */
    function editOrganization() {
        if (formValidation()) {
            organizationApi.update(data.organization).then((r) => {
                setAlertBox({
                    isOpen: true,
                    message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                    backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                });
                // setRedirect((r.data.code === "SUCCESS" ? true : false));
            }).catch((e) => {
                // console.log(e)
            });
        }
    }

    /**
     * Function that triggers the removal of a tag.
     * @param {*} tagToDelete 
     */
    function removeTag(event, tagToDelete) {
        let tags = data.organization.tags;
        const index = tags.indexOf(tagToDelete);
        // only splice array when item is found
        if (index > -1) tags.splice(index, 1); // 2nd parameter means remove one item only
        setData({
            ...data,
            organization: {
                ...data.organization,
                tags: tags
            }
        });
    }

    /**
     * Function that triggers the removal of a notification action.
     * @param {*} actionToDelete 
     *//* eslint-disable no-unused-vars */
    function removeAction(event, actionToDelete) {
        let actionsValue = data.actionsValue;
        const index = actionsValue.indexOf(actionToDelete);
        // only splice array when item is found
        if (index > -1) actionsValue.splice(index, 1); // 2nd parameter means remove one item only
        setData({
            ...data,
            actionsValue: actionsValue
        });
    }
    /* eslint-enable no-unused-vars */

    /**
     * Function that triggers the removal of an email notification action.
     * @param {*} event 
     * @param {number} rowIndex the row index of the table to be formatted
     * @param {string} actionToDelete the selected email notification action to be removed
     */
    function removeElementAction(event, rowIndex, actionToDelete) {
        let actionsElement = document.getElementsByName(`actionsValue-${rowIndex}`)[0];
        let emailActions = actionsElement.value.split(",");
        const index = emailActions.indexOf(actionToDelete);
        // only splice array when item is found
        if (index > -1) emailActions.splice(index, 1); // 2nd parameter means remove one item only
        actionsElement.value = emailActions.join();
    }

    /**
     * Function that triggers form validation.
     * @returns true if form is Valid
     */
    function formValidation() {
        let isError = createErrorMessages();
        if (formIsValid(isError)) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Function that create error messages for each required field that are not filled out.
     * @returns the object with the error messages for each form field
     */
    function createErrorMessages() {
        let isError = { ...data.isError };
        isError.name = data.organization.name.en.length < 1 ? t('form.requiredField') : "";
        if (data.organization.vatNumber.length < 1)
            isError.vatNumber = t('form.requiredField');
        else if (validateVatNumber(data.organization.vatNumber) === null)
            isError.vatNumber = t('error.vatNumberFormatError');
        // isError.planId = data.organization.planId.length < 1 ? t('form.requiredField') : "";
        setData({
            ...data,
            isError
        });
        return isError;
    }

    /**
     * Function that handles the snackbar open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }

    /**
     * Function that triggers the select input value change.
     *
     * @param {event} selectedOptions the selected options after the change takes place.
     * @property {string} field The field to be changed
     */
    function onTelephonesChange(selectedOptions, field) {
        const objectName = field.split(".")[0];
        let name = field.split(".")[1];
        setData({
            ...data,
            [objectName]: {
                ...data[objectName],
                [name]: selectedOptions
            }
        });
    }

    /**
     * Function that add an email to the organization
     *
     * @param {type} event 
     *//* eslint-disable no-unused-vars */
    function addEmail(event) {
        let emailKey = document.getElementById('emailKey');
        let actionsValue = document.getElementById('actionsValue');
        if (emailKey.value === "") {
            setData({
                ...data,
                alertBox: { isOpen: true, message: t('actions.error.addFailed'), backgroundColor: "#a71313" }
            });
        } else {
            let emails = data.organization.emails;
            //value: strings seperated with comma
            if (emails !== null) emails.push({ email: emailKey.value, actions: actionsValue.value.split(",") });
            else emails = [{ email: emailKey.value, actions: actionsValue.value.split(",") }];
            emailKey.value = '';
            actionsValue.value = '';

            setData({
                ...data,
                organization: {
                    ...data.organization,
                    emails: emails
                }
            });
        }
    }
    /* eslint-enable no-unused-vars */

    /**
     * Function that edit an email of the organization
     *
     * @param {type} event 
     */
    function editEmail(event, rowIndex) {
        let emails = data.organization.emails;
        emails[rowIndex] = { [document.getElementById("email-" + rowIndex).value]: document.getElementById("actionsValue-" + rowIndex).value.split(",") };
        setData({
            ...data,
            organization: {
                ...data.organization,
                emails: emails
            }
        });
    }

    /**
     * Function that removes an email of the organization
     *
     * @param {type} event 
     */
    function removeEmailItem(event, rowIndex) {
        let emails = data.organization.emails;
        if (rowIndex === 0) {
            emails.shift();
        } else {
            emails.splice(rowIndex - 1, rowIndex)
        }
        setData({
            ...data,
            organization: {
                ...data.organization,
                emails: emails
            }
        });
    };


    /**
     * Function that handles the delete modal open or close state.
     * @property {boolean} isOpen If the values is `true`, the dialog should be open and visible.
     * @property {string} field The field of the selected text translations to be removed.
     */
    function handleDialogState(isOpen, field = "") {
        setDialog({
            ...dialog,
            isOpen: isOpen,
            field: field,
            translations: data.organization[field]
        });
    }

    /**
     * This function will trigger the organization field translations configuration
     */
    function configureOrganizationTranslations(translations, field) {
        document.querySelectorAll(`[name="organization.${field}.en"]`)[0].value = translations.en;
        setData({
            ...data,
            organization: {
                ...data.organization,
                [field]: translations
            }
        });
        setDialog({
            ...dialog,
            isOpen: false,
            field: "",
            translations: {}
        });
    }


    /**
     * This function will format username to the required format.
     * 
     * @param {*} cell the cell of the table of the row that being formatted
     * @param {*} row the row of the table to be formatted
     * @param {*} rowIndex the row index of the table to be formatted
     * @param {object} formatExtraData extra data
     */
    function actionValuesFormatter(cell, row, rowIndex, formatExtraData) {
        return <CustomSelectWithChip id={"actionsValue-" + rowIndex} name={"actionsValue-" + rowIndex} required={false}
            // labelID={"actionsValue-select-" + rowIndex} label={t('label.notificationActions')}
            sx={{ minHeight: "50px" }}
            options={formatExtraData.notificationActionOptions} defaultValue={row.actions} onDelete={(event, actionToDelete) => formatExtraData.removeAction(event, rowIndex, actionToDelete)}
        />
    }

    /**
     * Function to trigger the remove of the admin broker.
     * @property {*} event
     * @property {string} field The field of the selected organization field.
     */
    function configureTranslations(event, field) {
        handleDialogState(true, field);
    }

    // ===================================== SOCIAL PROFILES
    function checkVatNumber(event) {
        let { value } = event.target;
        if (validateVatNumber(value) !== null) {
            let { countryCode, number } = validateVatNumber(value);

            vatApi.fetch(countryCode, number).then((r) => {
                if (r.data.code === "INVALID")
                    setData({
                        ...data,
                        isError: {
                            ...data.isError,
                            vatNumber: r.data.message
                        }
                    })
                else {
                    let addressDetails = (({ address, city, postalCode, country, name }) => ({ address, city, postalCode, country, officialVatName: name }))(r.data.returnobject.vatAddress);

                    setData({
                        ...data,
                        isError: {
                            ...data.isError,
                            vatNumber: ""
                        },
                        organization: {
                            ...data.organization,
                            addressDetails: addressDetails,
                            vatNumber: value.toUpperCase()
                        }
                    })
                }
                setAlertBox({
                    isOpen: true,
                    message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                    backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                });
                // setRedirect((r.data.code === "SUCCESS" ? true : false));
            }).catch((e) => {
                // console.log(e)
            });
        } else {
            setData({
                ...data,
                isError: {
                    ...data.isError,
                    vatNumber: t("error.vatNumberFormatError")
                }
            })
        }
    }

    // ===================================== SOCIAL PROFILES

    function handleSocialProfileChange(index, field, value) {
        let newSocialProfiles = data.organization.socialProfiles;
        newSocialProfiles[index].url = value;
        formValChangeWithParentElementObjectValues("organization", { socialProfiles: newSocialProfiles }, data, setData);
    }

    // ===================================== LOGO
    /**
     * Callback that handles file upload change.
     * @param {string} fileBase64 the new base64 image 
     */
    function handleFileChange(fileBase64) {
        formValChangeWithParentElementWithNameAndValue("organization.logo", fileBase64, data, setData, "");
    }

    //======================================= INTEGRATIONS
    /**
     * Password toggle handler
     */
    function togglePassword() {
        // When the handler is invoked
        // inverse the boolean state of passwordShown and icon to be shown
        setData({
            ...data,
            shownSecretIcon: !data.secretShown ? VisibilityIcon : VisibilityOffIcon,
            secretShown: !data.secretShown
        });
    }

    // ===============================================================================RENDER

    if (redirect) {
        window.location.href = `/${getLanguageFromURL()}/organization`;
    } else if (!isLoaded) {
        return <LoadingSkeleton lines={9} />
    } else {
        /* eslint-disable no-unused-vars */
        const { isError } = data;
        let defaultTagsSelected = [];
        let defaultOutletsSelected = [];
        if (data.organization !== null) {
            defaultTagsSelected = data.organization.tags;
            defaultOutletsSelected = data.organization.outletIds;
        }

        /**
         * datatable columns for emails.
         */
        const table_columns_emails = [
            {
                name: "email",
                label: t('label.email'),
                options: {
                    filter: false,
                    sort: false,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return (
                            emailFormatter(null, tableMeta.tableData[tableMeta.rowIndex], tableMeta.rowIndex)
                        );
                    }
                }
            },
            {
                name: "actions",
                label: t('label.notificationActions'),
                options: {
                    filter: false,
                    sort: false,
                    empty: false,
                    customBodyRender: (value, tableMeta) => {
                        return (
                            actionValuesFormatter(null, tableMeta.tableData[tableMeta.rowIndex], tableMeta.rowIndex, {
                                notificationActionOptions: data.notificationActionOptions,
                                removeAction: ((event, rowIndex, actionToDelete) => removeElementAction(event, rowIndex, actionToDelete))
                            })
                        );
                    }
                },
            },
            {
                name: "",
                label: t('actions.label'),
                options: {
                    filter: false,
                    sort: false,
                    empty: true,
                    classes: "click action",
                    customBodyRender: (value, tableMeta, rowIndex) => {
                        return (
                            actionValuesFormatter(null, tableMeta.tableData[tableMeta.rowIndex], tableMeta.rowIndex, {
                                extraKey: "email-", edit: ((event, rowIndex) => editEmail(event, rowIndex)),
                                remove: ((event, rowIndex) => removeEmailItem(event, rowIndex)),
                                action: "delete"
                            })
                        );
                    },
                    setCellProps: () => ({ className: "click action" })
                }
            }
        ];
        /* eslint-enable no-unused-vars */

        return (
            // ORGANIZATION ADD - EDIT
            <CustomGrid role="main" id="organization-add-edit" sx={mainTag()}>
                <CustomGrid sx={mainTagBreadcrumb()}>
                    <CustomAddEditBreadcrumb
                        isAdd={data.isAdd}
                        parentName={t('org.pageTitle')}
                        parentUrl={`/${getLanguageFromURL()}/organization`}
                        instanceName={(data.organization !== null) ? data.organization.name.en : ""}
                    />
                </CustomGrid>

                <CustomGrid sx={mainTagTitle()}>
                    <CustomAddEditTitle
                        isAdd={data.isAdd}
                        title={t('org.pageTitle')}
                        subtitle={t('org.pageTitle')}
                        instanceName={(data.organization !== null) ? data.organization.name.en : ""}
                    />
                </CustomGrid>

                {(!data.isAdd && hasRoleAdminOrDirectorOrManager(data.userAuth.roles)) &&
                    <Stack direction="row" spacing={0} style={{ marginTop: "20px", placeContent: "center" }}>
                        <Grid container gap={2} sx={{ textAlign: "center", placeContent: "center" }}>
                            <CustomCard label={t('outlet.title')} icon={BusinessIcon} totalNumber={data.outletsCounter !== undefined ? data.outletsCounter : 0} link={`/${getLanguageFromURL()}/organization/${data.organization.id}/outlet`} />
                            <CustomCard label={t('employee.title')} icon={GroupsIcon} totalNumber={data.employeesCounter !== undefined ? data.employeesCounter : 0} link={`/${getLanguageFromURL()}/organization/${data.organization.id}/employee`} />
                            <CustomCard label={t('menus.title')} icon={ArticleIcon} totalNumber={data.menusCounter !== undefined ? data.menusCounter : 0} link={`/${getLanguageFromURL()}/organization/${data.organization.id}/menu`} />
                            <CustomCard label={t('itemOrModifier.title')} icon={FeaturedPlayListIcon} totalNumber={data.itemsOrModifiersCounter !== undefined ? data.itemsOrModifiersCounter : 0} link={`/${getLanguageFromURL()}/organization/${data.organization.id}/item-or-modifier`} />
                            <CustomCard label={t('modifierList.title')} icon={FeaturedPlayListIcon} totalNumber={data.modifierListsCounter !== undefined ? data.modifierListsCounter : 0} link={`/${getLanguageFromURL()}/organization/${data.organization.id}/modifier-list`} />
                            <CustomCard label={t('equipment.title')} icon={TableRestaurantIcon} totalNumber={data.equipmentsCounter !== undefined ? data.equipmentsCounter : 0} />
                            {/* <CustomCard label={t('printer.title')} icon={PrintIcon} totalNumber={data.printersCounter !== undefined ? data.printersCounter : 0} link={`/${getLanguageFromURL()}/organization/${data.organization.id}/printer`} /> */}
                            <CustomCard label={t('label.smss')} icon={SmsIcon} totalNumber={parseInt(data.organization?.smsCounter) + parseInt((data.counters?.smsCounter !== undefined ? data.counters.sms : 0))} />
                            <CustomCard label={t('label.emails')} icon={EmailIcon} totalNumber={parseInt(data.organization?.emailCounter) + parseInt(data.counters?.email !== undefined ? data.counters?.email : 0)} />
                            <CustomCard label={t('label.ticketCounter')} icon={ReceiptIcon} totalNumber={data.counters?.ticket !== undefined ? data.counters.ticket : 0} />
                            <CustomCard label={t('label.receiptTicketCounter')} icon={ReceiptIcon} totalNumber={data.counters?.receiptTicket !== undefined ? data.counters.receiptTicket : 0} />
                        </Grid>
                    </Stack>
                }

                <CustomGrid sx={{ ...mainArea(), backgroundColor: "transparent" }} id="main-area" container={false}>
                    <form id="organization" onSubmit={(e) => { return false; }}>
                        {data.isAdd &&
                            <input type="hidden" />
                        }
                        {!data.isAdd && data.organization !== null &&
                            <input defaultValue={data.organization.id} name="id" type="hidden" id="id" />
                        }
                        <TabContext value={tabValue}>
                            <CustomTabs
                                initValue={tabValue}
                                instances={[
                                    { name: t('org.tabs.details'), value: "details" },
                                    { name: t('label.address'), value: "address" },
                                    { name: t('org.tabs.socialProfiles.label'), value: "socialProfiles" },
                                    { name: t('org.tabs.integrations.label'), value: "integrations" },
                                    { name: t('org.tabs.settings.label'), value: "settings" },
                                    { name: t('org.tabs.vat.label'), value: "vat" }
                                ]}
                                setValue={setTabValue}
                                sx={{ marginBottom: "50px", padding: 0 }}
                                scrollButtons="auto"
                            />

                            <TabPanel value={"details"} sx={{ paddingTop: "0px" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={true} htmlFor="name">{t('label.name')}</InputLabel>
                                        <CustomFlagTextField
                                            country="en"
                                            flag={en}
                                            placeholder=""
                                            name="organization.name.en"
                                            id="name"
                                            onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                            defaultValue={data.organization.name.en}
                                            onTranslationSelect={configureTranslations}
                                            field="name"
                                            helperText={isError.name}
                                            error={isError.name.length > 0 ? true : false}
                                            sx={textFieldError()}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={true} htmlFor="telephones">{t('label.telephone')}</InputLabel>
                                        <MuiChipsInput
                                            id="telephones"
                                            value={data.organization?.telephones}
                                            required={true}
                                            onChange={(event) => onTelephonesChange(event, "organization.telephones")}
                                            style={{ width: "100%" }} />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="email">{t('label.email')}</InputLabel>
                                        <CustomTextField
                                            type="text"
                                            name="organization.email"
                                            id="email"
                                            required={true}
                                            defaultValue={data.organization.email}
                                            onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                            sx={textFieldError()}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="tags">{t('label.tags')}</InputLabel>
                                        <CustomSelectWithChipMultilingual
                                            id="tags"
                                            name="organization.tags"
                                            translationPath="org.tags"
                                            required={true}
                                            defaultValue={defaultTagsSelected}
                                            options={data.tagOptions}
                                            onChange={(event) => onSelectChange(event, data, setData)}
                                            onDelete={removeTag}
                                        />
                                    </Grid>

                                    {data.userAuth !== null && hasRoleAdmin(data.userAuth.roles) &&
                                        <>
                                            <Grid item xs={12} md={3}>
                                                <CustomTextField type="number" name="organization.smsCounter" id="smsCounter"
                                                    required={true}
                                                    inputAdornmentLabel="label.smsCounter"
                                                    defaultValue={data.organization?.smsCounter}
                                                    onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={3}>
                                                <CustomTextField type="number" name="organization.emailCounter" id="emailCounter"
                                                    required={true}
                                                    inputAdornmentLabel="label.emailCounter"
                                                    defaultValue={data.organization?.emailCounter}
                                                    onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={12}>
                                                <FormControlLabel control={<Switch defaultChecked={data.organization.active} onChange={(event) => onControlChange(event, data, setData, "organization", "active")} />} label={t(data.organization.active ? 'label.isActive' : 'label.isNotActive')} />
                                            </Grid>
                                        </>
                                    }



                                    {/* <div className="form-item required">
                                        <InputLabel required={false} htmlFor="planId">{t('label.subscriptionPlan')}</InputLabel>
                                        <CustomObjectSelect id="planId" name="organization.planId" required={true}
                                            defaultValue={data.organization !== null ? data.organization.planId : ""}
                                            className={isError.planId.length > 0 ? "is-invalid form-control-select" : "form-control-select"}
                                            options={data.subscriptionPlans}
                                            onChange={(event) => onSelectChange(event, data, setData)}
                                        />
                                        {isError.planId.length > 0 && (
                                            <ul className="parsley-errors-list filled" id="parsley-id-33" aria-hidden="false" ><li className="parsley-required">{isError.planId}</li></ul>
                                        )}
                                    </div>

                                    {!data.isAdd && data.organization !== null &&
                                        <>
                                            <div className="form-item">
                                                <InputLabel required={false} htmlFor="subscriptionExpirationDate">{t('label.subscriptionExpirationDate')}</InputLabel>
                                                <CustomDateTimePicker
                                                    name="organization.subscriptionExpirationDate"
                                                    inputFormat="DD/MM/YYYY HH:MM"
                                                    value={data.organization.subscriptionExpirationDate}
                                                    onChange={(newValue) => {
                                                        setData({ ...data, organization: { ...data.organization, subscriptionExpirationDate: newValue } });
                                                    }}
                                                    sx={{ width: "99.5%" }}
                                                />
                                            </div>

                                            
                                        </>
                                    } */}

                                </Grid>

                                {/*
                                    <fieldset id="emails-wrapper" className="fieldset-wrapper collapsable collapsed">
                                        <legend onClick={collapsable}>&nbsp;<SvgIcon component={data.icon} aria-hidden="true" style={{ fontSize: "medium", verticalAlign: "sub" }} />&nbsp;{`${t('actions.add')} ${t('label.email')}`}&nbsp;</legend>

                                        <div className="table">
                                            <div className="form-item form-item-2get">
                                                <InputLabel required={false} htmlFor="emailKey">{t('label.email')}</InputLabel>
                                                <CustomTextField type="text" name="emailKey" id="emailKey" />
                                            </div>

                                            <div className="form-item">
                                                <InputLabel required={false} htmlFor="actionsValue">{t('label.notificationActions')}</InputLabel>
                                                <CustomSelectWithChip id="actionsValue" name="actionsValue" required={false}
                                                    options={data.emailNotificationActionOptions}
                                                    onChange={(event) => onSelectChange(event, data, setData)}
                                                    onDelete={removeAction}
                                                />
                                            </div>

                                            <div className="form-item">
                                                <button id="btn-add-actions" type="button" className="form-action-button" onClick={addEmail}>{t('actions.add')}</button>
                                            </div>
                                        </div>

                                        <CustomTable
                                            instances={data.organization.emails}
                                            showEmptyMessage={true}
                                            emptyMessage={t("org.emailsEmpty")}
                                            table_columns={table_columns_emails}
                                            keyField="email"
                                        />
                                    </fieldset>
                                */}
                            </TabPanel>

                            <TabPanel value={"address"} sx={{ paddingTop: "0px" }} >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={3}>
                                        <InputLabel required={false} htmlFor="address">{t('label.address')}</InputLabel>
                                        <CustomTextField
                                            type="text"
                                            name="organization.addressDetails.address"
                                            placeholder="Ermou 5"
                                            id="address"
                                            value={data.organization?.addressDetails.address}
                                            onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))} />
                                    </Grid>

                                    <Grid item xs={12} sm={3}>
                                        <InputLabel required={false} htmlFor="city">{t('label.city')}</InputLabel>
                                        <CustomTextField
                                            type="text"
                                            name="organization.addressDetails.city"
                                            id="city"
                                            value={data.organization?.addressDetails.city}
                                            onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))} />
                                    </Grid>

                                    <Grid item xs={12} sm={3}>
                                        <InputLabel required={false} htmlFor="postalCode">{t('label.postalCode')}</InputLabel>
                                        <CustomTextField
                                            type="text"
                                            name="organization.addressDetails.postalCode"
                                            id="postalCode"
                                            value={data.organization?.addressDetails.postalCode}
                                            onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))} />
                                    </Grid>

                                    <Grid item xs={12} sm={3}>
                                        <InputLabel required={false} htmlFor="country">{t('label.country')}</InputLabel>
                                        <CustomObjectSelect
                                            name="organization.addressDetails.country.id"
                                            value={data.organization.addressDetails.country !== null ? data.organization.addressDetails.country.id : data.countries.find((country) => country.alpha2 === "GR").id}
                                            options={data.countries}
                                            onChange={(event) => onSelectChange(event, data, setData)}
                                        />
                                    </Grid>
                                </Grid>
                            </TabPanel>

                            <TabPanel value={"socialProfiles"} sx={{ paddingTop: "0px" }}>
                                <Grid container spacing={2}>
                                    {data.organization.socialProfiles.map((socialProfileType, index) =>
                                        <Grid item xs={12} md={6}>
                                            <InputLabel required={false} htmlFor={socialProfileType.name}>{t(`socialProfile.${socialProfileType.name}`)}</InputLabel>
                                            <CustomTextFieldWithIcons
                                                type="text"
                                                defaultValue={socialProfileType.url}
                                                endIcon={getSocialFontAwesomeIcon(t(`socialProfile.${socialProfileType.name}`), socialProfileType.url)}
                                                onChange={(event) => handleSocialProfileChange(index, socialProfileType.name, event.target.value)}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </TabPanel>

                            <TabPanel value={"settings"} sx={{ paddingTop: "0px" }}>
                                <Grid container spacing={2} sx={organizationGridStyle()}>
                                    <Grid item xs={12} md={6}>
                                        {(data.organization.logo !== null && data.organization.logo !== "") &&
                                            <Stack direction="row" key="stack-logo">
                                                <CustomIconButton
                                                    keyValue={`btn-delete-logo`}
                                                    onClick={() => { formValChangeWithParentElementWithNameAndValue("organization.logo", "", data, setData); }}
                                                    icon={<DeleteIcon style={cardItemButtonGroupStackButtonIcon()} />}
                                                    iconSx={{ marginRight: "0px!important" }}
                                                    sx={{
                                                        backgroundColor: "red",
                                                        ...cardItemButtonGroupStackButton()
                                                    }}
                                                >
                                                    {t('actions.delete')}
                                                </CustomIconButton>
                                            </Stack>
                                        }

                                        {(data.organization.logo !== null && data.organization.logo !== "") &&
                                            <CardMedia
                                                component="img"
                                                image={data.organization.logo.includes("base64") ?
                                                    data.organization.logo
                                                    :
                                                    `https://storage.usee.gr/usee/organization/${data.organization.id}/${data.organization.logo}`}
                                                alt={"logo"}
                                                sx={logoStyle()}
                                            />
                                        }
                                        <Button>
                                            {/* Choose File */}
                                            <CustomImageCropper storeCroppedImage={handleFileChange} />
                                        </Button>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="itemPhotoStyle">{t('menus.settings.itemPhotoStyle.label')}</InputLabel>

                                        <Grid container gap={2}>
                                            <Grid item>
                                                <CustomIconButton
                                                    {...getOrganizationButtonProperties()}
                                                    keyValue={`logo-square`}
                                                    onClick={() => formValChangeWithParentElementWithNameAndValue("organization.logoType", "SQUARE", data, setData)}
                                                    isFocused={data.organization.logoType === "SQUARE"}
                                                    icon={<SquareShape {...getOrganizationButtonIconProperties()} />}
                                                    iconSx={{ marginRight: 0 }}
                                                />
                                            </Grid>

                                            <Grid item>
                                                <CustomIconButton
                                                    {...getOrganizationButtonProperties()}
                                                    keyValue={`logo-rectangular`}
                                                    onClick={() => formValChangeWithParentElementWithNameAndValue("organization.logoType", "ROUND", data, setData)}
                                                    isFocused={data.organization.logoType === "ROUND"}
                                                    icon={<RectangularShape {...getOrganizationButtonIconProperties()} />}
                                                    iconSx={{ marginRight: 0 }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2} sx={{ ...organizationGridStyle(), marginTop: "20px" }}>
                                    <CustomTypography variant="body2" text={t('order.title')} sx={{ ...organizationSettingsHeaderStyle(), marginBottom: "5px" }} />
                                    <Grid item xs={12} md={12}>
                                        <CustomTextField type="number" name="organization.monthsPassedWithoutOrderToMarkAsReturning" id="monthsPassedWithoutOrderToMarkAsReturning"
                                            required={true}
                                            startAdormentWidth="900px"
                                            inputAdornmentLabel="org.monthsPassedWithoutOrderToMarkAsReturning"
                                            value={data.organization?.monthsPassedWithoutOrderToMarkAsReturning}
                                            onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                            inputAdornmentAfterLabel={t('org.monthsPassedWithoutOrderToMarkAsReturningAfterLabel')}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <CustomTextField type="number" name="organization.daysPassedFromLastOrderToMarkAsRecent" id="daysPassedFromLastOrderToMarkAsRecent"
                                            required={true}
                                            startAdormentWidth="900px"
                                            inputAdornmentLabel="org.daysPassedFromLastOrderToMarkAsRecent"
                                            value={data.organization?.daysPassedFromLastOrderToMarkAsRecent}
                                            onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                            inputAdornmentAfterLabel={t('org.daysPassedFromLastOrderToMarkAsRecentAfterLabel')}
                                        />
                                    </Grid>
                                </Grid>
                            </TabPanel>

                            <TabPanel value={"integrations"} sx={{ paddingTop: "0px" }}>
                                <Grid item xs={12} md={12} sx={{ margin: "20px 0" }}>
                                    <CustomFieldset
                                        label={t('outlet.tabs.integrations.vivaWalletConfiguration.label')}
                                        children={<>
                                            <Grid container spacing={2} sx={{ marginTop: '5px', marginLeft: "5px" }}>
                                                <Grid item xs={12} md={5.8}>
                                                    <InputLabel required={false} htmlFor="appSourceCode">{t('outlet.tabs.integrations.vivaWalletConfiguration.appSourceCode')}</InputLabel>
                                                    <CustomTextField
                                                        type="text"
                                                        name="organization.vivaWalletConfiguration.appSourceCode"
                                                        id="appSourceCode"
                                                        defaultValue={data.organization?.vivaWalletConfiguration.appSourceCode}
                                                        onChange={(event) => formValChangeWithParents(event, data, setData, t('table.valueReuired'))}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={5.8}>
                                                    <InputLabel required={false} htmlFor="clientId">{t('outlet.tabs.integrations.vivaWalletConfiguration.clientId')}</InputLabel>
                                                    <CustomTextField
                                                        type="text"
                                                        name="organization.vivaWalletConfiguration.clientId"
                                                        id="clientId"
                                                        defaultValue={data.organization?.vivaWalletConfiguration.clientId}
                                                        onChange={(event) => formValChangeWithParents(event, data, setData, t('table.valueReuired'))}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={5.8}>
                                                    <InputLabel required={false} htmlFor="clientSecret">{t('outlet.tabs.integrations.vivaWalletConfiguration.clientSecret')}</InputLabel>
                                                    <CustomTextFieldWithIcons
                                                        type={data.secretShown ? "text" : "password"}
                                                        name="organization.vivaWalletConfiguration.clientSecret"
                                                        id="clientSecret"
                                                        autoComplete="off"
                                                        defaultValue={data.organization?.vivaWalletConfiguration.clientSecret}
                                                        endIcon={<SvgIcon component={data.shownSecretIcon} id="visibility-icon" onClick={togglePassword} style={{ cursor: "pointer" }} />}
                                                        onChange={(event) => formValChangeWithParents(event, data, setData, t('table.valueReuired'))}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={5.8}>
                                                    <InputLabel required={false} htmlFor="merchantId">{t('outlet.tabs.integrations.vivaWalletConfiguration.merchantId')}</InputLabel>
                                                    <CustomTextField
                                                        type="text"
                                                        name="organization.vivaWalletConfiguration.merchantId"
                                                        id="merchantId"
                                                        defaultValue={data.organization?.vivaWalletConfiguration.merchantId}
                                                        onChange={(event) => formValChangeWithParents(event, data, setData, t('table.valueReuired'))}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={5.8}>
                                                    <InputLabel required={false} htmlFor="apiKey">{t('outlet.tabs.integrations.vivaWalletConfiguration.apiKey')}</InputLabel>
                                                    <CustomTextField
                                                        type="text"
                                                        name="organization.vivaWalletConfiguration.apiKey"
                                                        id="apiKey"
                                                        defaultValue={data.organization?.vivaWalletConfiguration.apiKey}
                                                        onChange={(event) => formValChangeWithParents(event, data, setData, t('table.valueReuired'))}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </>}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12} sx={{ margin: "20px 0" }}>
                                    <CustomFieldset
                                        label={t('integrations.oxygenConfiguration.label')}
                                        children={<>
                                            <Grid container spacing={2} sx={{ marginTop: '5px', marginLeft: "5px" }}>
                                                <Grid item xs={12} md={5.8}>
                                                    <InputLabel required={false} htmlFor="token">{t('outlet.tabs.integrations.oxygenConfiguration.token')}</InputLabel>
                                                    <CustomTextField
                                                        type="text"
                                                        name="organization.oxygenConfiguration.token"
                                                        id="token"
                                                        value={data.organization.oxygenConfiguration?.token}
                                                        onChange={(event) => formValChangeWithParents(event, data, setData, t('table.valueReuired'))}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </>}
                                    />
                                </Grid>
                            </TabPanel>

                            <TabPanel value={"vat"} sx={{ paddingTop: "0px" }}>
                                <Grid container spacing={2} sx={organizationGridStyle()}>
                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={true} htmlFor="vatNumber">{t('label.vatNumber')}</InputLabel>
                                        <CustomTextField
                                            type="text"
                                            name="organization.vatNumber"
                                            id="vatNumber"
                                            required
                                            defaultValue={data.organization.vatNumber}
                                            error={isError.vatNumber.length > 0 ? true : false}
                                            onChange={(event) => { checkVatNumber(event); }}
                                            helperText={isError.vatNumber}
                                            sx={textFieldError()}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>

                                    </Grid>
                                </Grid>
                            </TabPanel>
                        </TabContext>

                        <Grid container spacing={2}>
                            {/* <Grid item>
                                <CustomIconButton
                                    variant="outlined"
                                    keyValue="save"
                                    onClick={(data.isAdd && hasRoleAdmin(data.userAuth.roles)) ? addOrganization : editOrganization}
                                    label={t('actions.save')}
                                    sx={{ ...formSaveButton(), marginLeft: "20px" }}
                                />
                            </Grid> */}

                            {data.isAdd &&
                                <Grid item>
                                    <CustomIconButton
                                        variant="outlined"
                                        keyValue="cancel"
                                        link={`/${getLanguageFromURL()}/organization`}
                                        label={t('actions.back')}
                                        sx={formSaveButton()}
                                        color={"error"}
                                    />
                                </Grid>
                            }

                            {!data.isAdd &&
                                <Grid item>
                                    <CustomIconButton
                                        variant="outlined"
                                        keyValue="cancel"
                                        // onClick={getOrganization}
                                        link={`/${getLanguageFromURL()}/organization`}
                                        label={t('actions.cancel')}
                                        sx={formSaveButton()}
                                        color={"error"}
                                    />
                                </Grid>
                            }
                        </Grid>
                    </form>

                    <Fab
                        color="primary"
                        aria-label="add"
                        sx={fabStyle()}
                        onClick={(data.isAdd && hasRoleAdmin(data.userAuth.roles)) ? addOrganization : editOrganization}
                    >
                        <SaveIcon />
                    </Fab>
                </CustomGrid>

                {dialog.isOpen &&
                    <CustomTranslationsDialog
                        isOpen={dialog.isOpen}
                        id="translations"
                        title={t('actions.translationModal.title')}
                        actionLabel={t('actions.save')}
                        action={configureOrganizationTranslations}
                        handleOpen={handleDialogState}
                        translations={data.organization[dialog.field]}
                        field={dialog.field} />
                }

                {alertBox.isOpen &&
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={3000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        handleSnackbarOpen={handleSnackbarState} />
                }
            </CustomGrid>
        );
    }
}

export default OrganizationAddEditComponent;