import {
    AttachmentTwoTone as AttachmentTwoToneIcon,
    MenuRounded as MenuRoundedIcon,
    MoodTwoTone as MoodTwoToneIcon
} from '@mui/icons-material';
import { Box, Button, Chip, ClickAwayListener, Divider, Grid, IconButton, Popper, Typography, styled } from '@mui/material';
import { useTheme } from '@mui/styles';
import chatApi from 'api/chat';
import MainCard from 'components/Card/MainCard';
import CustomDialogBasedOnScreenChildren from 'components/Dialogs/CustomDialogBasedOnScreenChildren';
import ChatDrawer from 'components/Drawer/chat/ChatDrawer';
import CustomGrid from 'components/Grid/CustomGrid';
import AddChatChannelDialogContent from 'components/Grid/chat/AddChatChannelDialogContent';
import ChartHistory from 'components/Grid/chat/ChartHistory';
import EmptyChatChannelsOrUnselected from 'components/Grid/chat/EmptyChatChannelsOrUnselected';
import ChatUserAvatar from 'components/Grid/chat/user/ChatUserAvatar';
import { getDefaultChatChannelMessage } from 'constants/defaultObjects';
import { drawerWidth, gridSpacing } from 'constants/theme';
import EmojiPicker, { SkinTones } from 'emoji-picker-react';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { mainTagChat } from 'themes/defaultThemes';
import { sortedFontOptions } from 'utils/fonts';
import { formValChangeWithParentElementWithNameAndValue } from 'utils/form-validation';
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The ChatComponent, that presents a chat component.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function ChatComponent() {
    const theme = useTheme();
    const { t } = useTranslation();
    // drawer content element
    const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
        flexGrow: 1,
        paddingLeft: open ? theme.spacing(3) : 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter
        }),
        marginLeft: `-${drawerWidth}px`,
        [theme.breakpoints.down('lg')]: {
            paddingLeft: 0,
            marginLeft: 0
        },
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.shorter
            }),
            marginLeft: 0
        })
    }));

    useDocumentTitle(`Usee | Test`);

    /**
    * @type {object}
    * @property {*} icon the legend fa icon
    * @property {object} userAuth the authenticated user infromation
    * @property {object} organization the organization basic details (id and name)
    * @property {object} outlet tha outlet basic details (id and name)
    * @property {array} equipments tha outlet equipments basic details (id and name)
    * @property {array} mySections tha outlet sections related to the logged-in user basic details (id and name)
    */
    const [data, setData] = useState({
        userAuth: null,
        employees: [],
        chatChannels: {},
        currency: "€",
        isFirstTime: true,
        pagination: {
            count: -1,
            size: 15,
            page: 0,
            //    ...getDefaultOrder
        }
    });

    // for search
    const [search, setSearch] = useState({
        organizationID: "",
        outletID: "",
        name: "",
        isMine: false
    });

    function updateTab(tabValue) {
        setSearch({
            ...search,
            isMine: tabValue === "all" ? false : true
        })
    }

    function filterChatsByName(name) {
        setSearch({
            ...search,
            name: name
        })
    }

    useEffect(() => {
        const fetchDataInterval = setInterval(() => {
            getChat(); // Fetch data
        }, 10000); // 10 seconds interval

        // Clear interval on component unmount to avoid memory leaks
        return () => clearInterval(fetchDataInterval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // close sidebar when widow size below 'md' breakpoint
    // useEffect(() => {
    //     setOpenChatDrawer(!matchDownSM);
    // }, [matchDownSM]);

    useEffect(() => {
        getChat();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);


    /**
    * The rest endpoint to get the pickup display settings.
    */
    async function getChat() {
        chatApi.fetchAll(data.page, search, data.userAuth?.id).then((r) => {
            if (r.data.code === "SUCCESS") {
                setData({
                    ...data,
                    userAuth: r.data.returnobject.userAuth,
                    employees: r.data.returnobject.employees,
                    chatChannels: r.data.returnobject.chatChannels,
                    pagination: {
                        ...data.pagination,
                        count: r.data.returnobject.chatChannels.totalPages,
                        size: r.data.returnobject.chatChannels.size,
                        page: r.data.returnobject.chatChannels.number
                    }
                });
            };
        }).catch((e) => {
        });
    }

    /**
    * The rest endpoint to get the pickup display settings.
    */
    function getSelectedChatChannel(id) {
        chatApi.fetchOne(id).then((r) => {
            if (r.data.code === "SUCCESS") {
                setSelectedChatChannelDetails(r.data.returnobject);
                setSelectedChatChannel(r.data.returnobject.id);
            };
        }).catch((e) => {
        });
    }


    /**
    * The rest endpoint to create a new chat channel.
    */
    async function addChatChannel(chatChannel) {
        chatApi.create(chatChannel).then((r) => {
            if (r.data.code === "SUCCESS") {
                setOpenChatChannelDialog(false);
                setSelectedChatChannel(r.date.returnobject.id);
                setSelectedChatChannelDetails(r.data.returnobject);
                getChat();
            };
        }).catch((e) => {
        });
    }

    // toggle sidebar
    const [openChatDrawer, setOpenChatDrawer] = useState(true);
    const handleDrawerOpen = () => {
        setOpenChatDrawer((prevState) => !prevState);
    };


    const [openChatChannelDialog, setOpenChatChannelDialog] = useState(false);
    const [selectedChatChannel, setSelectedChatChannel] = useState(null);
    const [selectedChatChannelDetails, setSelectedChatChannelDetails] = useState(null);

    // handle new message form
    const [message, setMessage] = useState(getDefaultChatChannelMessage(data.userAuth));

    function handleOnSend() {
        chatApi.createMessage(selectedChatChannel, message).then((r) => {
            if (r.data.code === "SUCCESS") {
                setOpenChatChannelDialog(false);
                setSelectedChatChannel(r.date.returnobject.id);
                setSelectedChatChannelDetails(r.date.returnobject);
                setMessage(getDefaultChatChannelMessage(data.userAuth));
                editorRef.current.editor.setContents("");
            };
        }).catch((e) => {
        });
    };

    // handle emoji
    const onEmojiClick = (emojiObject) => {
        setMessage({ ...message, message: message.message + emojiObject.emoji });
    };

    const [anchorElEmoji, setAnchorElEmoji] = useState(); /** No single type can cater for all elements */
    const handleOnEmojiButtonClick = (event) => {
        setAnchorElEmoji(anchorElEmoji ? null : event?.currentTarget);
    };

    const emojiOpen = Boolean(anchorElEmoji);
    const emojiId = emojiOpen ? 'simple-popper' : undefined;
    const handleCloseEmoji = () => {
        setAnchorElEmoji(null);
    };


    // scroll
    const scrollRef = useRef();
    const editorRef = useRef(null);
    // const dispatch = useDispatch();
    //change document title

    /* eslint-disable no-unused-vars */
    // Scroll to bottom function
    const scrollToBottom = () => {
        if (scrollRef.current) {
            console.log(scrollRef.current)
            console.log(scrollRef.current._container)
            scrollRef.current._container.scrollTop = scrollRef.current._container?.scrollHeight;
        }
    };
    /* eslint-enable no-unused-vars */

    useLayoutEffect(() => {
        if (scrollRef?.current) {
            scrollRef.current.scrollIntoView();
        }
    });

    // ===============================================================================RENDER
    return (
        <CustomGrid role="main" id="test" sx={mainTagChat()}>
            <Box sx={{ display: 'flex', width: "100%" }}>
                <ChatDrawer
                    openChatDrawer={openChatDrawer}
                    handleDrawerOpen={handleDrawerOpen}
                    setOpenChatChannelDialog={setOpenChatChannelDialog}
                    user={data.userAuth}
                    setSelectedChatChannel={getSelectedChatChannel}
                    chatChannels={data.chatChannels}
                    setTab={updateTab}
                    selectedChatChannel={selectedChatChannel}
                    filterChatsByName={filterChatsByName}
                />

                {((data.chatChannels?.content?.length > 0 && selectedChatChannel === null) || data.chatChannels?.content?.length === 0) &&
                    <EmptyChatChannelsOrUnselected
                        channels={data.chatChannels?.content}
                        setOpenChatChannelDialog={setOpenChatChannelDialog}
                        setOpenChatDrawer={setOpenChatDrawer}
                        selectedChatChannel={selectedChatChannel}
                    />
                }

                {selectedChatChannel !== null &&
                    <Main theme={theme} open={openChatDrawer}>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12} zeroMinWidth sx={{ display: 'flex' }}>
                                <MainCard sx={{ width: "100%" }}>
                                    <Grid container spacing={gridSpacing}>
                                        <Grid item xs={12}>
                                            <Grid container alignItems="center" spacing={0.5}>
                                                <Grid item>
                                                    <IconButton onClick={handleDrawerOpen} size="large" aria-label="click to menu collapse">
                                                        <MenuRoundedIcon />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item>
                                                    <Grid container spacing={2} alignItems="center" sx={{ flexWrap: 'nowrap' }}>
                                                        <Grid item>
                                                            <ChatUserAvatar user={data.userAuth} />
                                                        </Grid>
                                                        <Grid item sm zeroMinWidth>
                                                            <Grid container spacing={0} alignItems="center">
                                                                <Grid item xs={12}>
                                                                    <Typography variant="h4" component="div">
                                                                        {data.userAuth?.fullName}{' '}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {/* <Typography variant="subtitle2">Last anwer {user?.lastMessage}</Typography> */}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item sm zeroMinWidth />
                                            </Grid>
                                            <Divider sx={{ mt: "10px" }} />
                                        </Grid>
                                        <PerfectScrollbar
                                            style={{ width: '100%', height: 'calc(100vh - 500px)', overflowX: 'hidden', minHeight: 525, display: "flex" }}
                                        >
                                            <Box sx={{ py: 3, pl: 4, pr: 1 }}>
                                                <ChartHistory
                                                    theme={theme}
                                                    handleDrawerOpen={handleDrawerOpen}
                                                    user={data.userAuth}
                                                    data={selectedChatChannelDetails?.messageHistory}
                                                />
                                                {/* @ts-ignore */}
                                                <span ref={scrollRef} />
                                            </Box>
                                        </PerfectScrollbar>

                                        <Grid item xs={12}>
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid item>
                                                    <IconButton size="large" aria-label="attachment file">
                                                        <AttachmentTwoToneIcon />
                                                    </IconButton>

                                                    <IconButton
                                                        ref={anchorElEmoji}
                                                        aria-describedby={emojiId}
                                                        onClick={handleOnEmojiButtonClick}
                                                        size="large"
                                                        aria-label="emoji"
                                                    >
                                                        <MoodTwoToneIcon />
                                                    </IconButton>

                                                    <Popper
                                                        placement="bottom"
                                                        id={emojiId}
                                                        open={emojiOpen}
                                                        anchorEl={anchorElEmoji}
                                                        disablePortal
                                                        sx={{ display: "absolute", inset: "initial", transform: "initial!important" }}
                                                        modifiers={[
                                                            {
                                                                name: 'offset',
                                                                options: {
                                                                    offset: [-20, 20]
                                                                }
                                                            }
                                                        ]}
                                                    >
                                                        <ClickAwayListener onClickAway={handleCloseEmoji}>
                                                            <MainCard elevation={8} content={false}>
                                                                <EmojiPicker onEmojiClick={onEmojiClick} defaultSkinTone={SkinTones.DARK} autoFocusSearch={false} />
                                                            </MainCard>
                                                        </ClickAwayListener>
                                                    </Popper>
                                                </Grid>

                                                <Grid item xs={12} sm zeroMinWidth>
                                                    {/* <OutlinedInput
                                                        id="message-send"
                                                        fullWidth
                                                        value={message}
                                                        onChange={(e) => setMessage(e.target.value)}
                                                        onKeyPress={handleEnter}
                                                        placeholder="Type a Message"
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    disableRipple
                                                                    color="primary"
                                                                    onClick={handleOnSend}
                                                                    aria-label="send message"
                                                                >
                                                                    <SendTwoToneIcon />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                        aria-describedby="search-helper-text"
                                                        inputProps={{ 'aria-label': 'weight' }}
                                                    /> */}
                                                    {/* messagebox */}

                                                    <Grid container sx={{ p: { md: "20px", xs: "12px" } }}>
                                                        <SunEditor
                                                            mode='classic'
                                                            showPathLabel={false}
                                                            autoFocus
                                                            setDefaultStyle='font-family: Inter; font-size: 14px; border-radius:6px;'
                                                            setOptions={{
                                                                height: "60px",
                                                                katex: "window.katex",
                                                                resizeEnable: true,
                                                                resizingBar: false,
                                                                font: sortedFontOptions,
                                                                buttonList: [
                                                                    [
                                                                        "undo",
                                                                        "redo"
                                                                    ],
                                                                    [
                                                                        "font",
                                                                        "fontSize",
                                                                        "formatBlock",
                                                                    ],
                                                                    [
                                                                        "bold",
                                                                        "underline",
                                                                        "italic",
                                                                        "strike",
                                                                        "subscript",
                                                                        "superscript"
                                                                    ],
                                                                    [
                                                                        "fontColor",
                                                                        "hiliteColor"
                                                                    ],
                                                                    [
                                                                        "align",
                                                                        "list",
                                                                        "lineHeight"
                                                                    ],
                                                                    [
                                                                        "outdent",
                                                                        "indent"
                                                                    ],
                                                                    [
                                                                        "table",
                                                                        "horizontalRule",
                                                                        "link",
                                                                    ],
                                                                    ["removeFormat"]
                                                                ],
                                                                defaultTag: "div",
                                                            }}
                                                            className={"editor"}
                                                            placeholder='Message'
                                                            defaultValue={message.message}
                                                            ref={editorRef}
                                                            onChange={(content) => setMessage({ ...message, message: content })}
                                                        />
                                                        {/* https://github.com/mkhstar/suneditor-react/issues/94#issuecomment-939015497 */}
                                                        <Grid
                                                            container
                                                            justifyContent='space-between'
                                                            sx={{ mt: "8px" }}
                                                            gap={"4px"}
                                                        >
                                                            <Grid item flex={1}>
                                                                <Grid container gap={2} alignItems='center'>
                                                                    <Grid item>
                                                                        {/* <input
                                                                                multiple
                                                                                max={5}
                                                                                // accept='image/*'
                                                                                style={{ display: "none" }}
                                                                                id='attachments'
                                                                                type='file'
                                                                                onChange={(e) => {
                                                                                    if (
                                                                                    (!e.target.files && !e.target.files[0]) ||
                                                                                    [...attachments, ...e.target.files].length > 5
                                                                                    ) {
                                                                                    return;
                                                                                    }
                                                                                    // setAttachments((r) => [...r, ...e.target.files]);
                                                                                }}
                                                                            /> 
                                                                        */}
                                                                    </Grid>

                                                                    {message?.attachments?.map((x, i) => (
                                                                        <Grid item key={i}>
                                                                            <Chip
                                                                                label={x.name}
                                                                                variant='outlined'
                                                                                onDelete={() =>
                                                                                    formValChangeWithParentElementWithNameAndValue("attachments", message.attachments.filter((ai, ind) => ind !== i), message, setMessage)
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                    ))}
                                                                    {message?.photos?.map((x, i) => (
                                                                        <Grid item key={i}>
                                                                            <Chip
                                                                                label={x.name}
                                                                                variant='outlined'
                                                                                onDelete={() =>
                                                                                    formValChangeWithParentElementWithNameAndValue("photos", message.photos.filter((ai, ind) => ind !== i), message, setMessage)
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                    ))}
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item>
                                                                <Button
                                                                    sx={{
                                                                        fontSize: "18px",
                                                                        textTransform: "none",
                                                                        padding: 0,
                                                                        fontWeight: "bold",
                                                                        background: "transparent",
                                                                        "&:hover": {
                                                                            background: "transparent",
                                                                        },
                                                                    }}
                                                                    disableRipple
                                                                    disableElevation
                                                                    disableFocusRipple
                                                                    startIcon={
                                                                        <svg
                                                                            xmlns='http://www.w3.org/2000/svg'
                                                                            width='28'
                                                                            height='28'
                                                                            fill='none'
                                                                            viewBox='0 0 28 28'
                                                                        >
                                                                            <path
                                                                                fill={
                                                                                    (message?.message?.length === 0 ||
                                                                                        message?.message === "<p><br></p>") &&
                                                                                        message?.attachments?.length === 0
                                                                                        ? "rgba(0, 0, 0, 0.26)"
                                                                                        : "#1976d2"
                                                                                }
                                                                                fillRule='evenodd'
                                                                                d='M12.606 17.287l4.267 6.922c.187.303.478.3.596.283a.593.593 0 00.504-.432l5.335-18.02a.609.609 0 00-.155-.612.597.597 0 00-.597-.154l-18.032 5.28a.595.595 0 00-.433.505.601.601 0 00.281.608l7.001 4.379 6.186-6.251a.876.876 0 011.245 1.23l-6.198 6.262zm4.772 8.962a2.33 2.33 0 01-1.995-1.122l-4.523-7.34-7.415-4.637a2.338 2.338 0 01-1.088-2.329 2.334 2.334 0 011.674-1.946l18.032-5.28a2.358 2.358 0 012.922 2.942L19.65 24.555a2.33 2.33 0 01-1.949 1.672c-.11.014-.216.022-.323.022z'
                                                                                clipRule='evenodd'
                                                                            ></path>
                                                                        </svg>
                                                                    }
                                                                    disabled={
                                                                        (message?.attachments?.length === 0 &&
                                                                            message?.photos?.length === 0 &&
                                                                            message?.message === "<p><br></p>")
                                                                    }
                                                                    onClick={handleOnSend}
                                                                >
                                                                    {t('actions.send')}
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </MainCard>
                            </Grid>
                        </Grid>
                    </Main>
                }
            </Box>

            {openChatChannelDialog &&
                <CustomDialogBasedOnScreenChildren
                    isOpen={openChatChannelDialog}
                    id="add-dialog"
                    handleOpen={() => setOpenChatChannelDialog(true)}
                    handleClose={() => setOpenChatChannelDialog(false)}
                    children={
                        <AddChatChannelDialogContent
                            addChatChannel={addChatChannel}
                        />
                    }
                />
            }
        </CustomGrid>
    )
}

export default ChatComponent;