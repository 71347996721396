import { getThemeColor } from "constants/theme";
import { getTheme } from "utils/theme";

export const getCardProperties = () => {
    return {
        border: "1px dashed lightgrey",
        ":hover": { backgroundColor: "#ebf5f9" },
        width: "320px",
        height: "320px",
        p: 0,
        m: 0,
        flexWrap: 'wrap',
        borderRadius: "5px",
        margin: "20px",
        boxShadow: 2,
        overflow: "visible"
    };
}

export const getCardActionAreaProperties = () => {
    return {
        display: "grid",
        height: "100%",
        width: "100%"
    };
}

export const getButtonProperties = () => {
    return {
        fontSize: "40px",
        width: "80px",
        height: "80px",
        color: "lightgrey",
        borderRadius: "50px",
        border: "1px dashed lightgrey",
        marginTop: "10%",
        // ":hover": {
        //     backgroundColor: "#ebf5f9",
        //     color: "white"
        // }
    };
}

export const getStackButtonsProperties = () => {
    return {
        right: 30,
        top: 0,
        position: "relative",
        justifyContent: "right",
        zIndex: 10
    };
}

export const getStackProperties = () => {
    return {
        marginTop: "-20px",
        zIndex: -1,
        placeItems: "center",
        placeContent: "center"
    };
}


export const getFrameGrid = () => {
    return {
        width: "100px",
        height: "100px"
    };
}

export const getPatternGrid = () => {
    return {
        width: "100px",
        height: "100px"
    };
}

export const getEyeShapeGrid = () => {
    return {
        width: "30px",
        height: "40px"
    };
}

export const noneCardButton = () => {
    return {
        fontSize: "40px",
        width: "80px",
        height: "80px",
        color: "black",
        border: "none",
        backgroundColor: "transparent"
    };
}

// export const noneCardButton = () => {
//     return {
//        fontSize: "40px",
//        width: "80px",
//        height: "80px",
//        color: "lightgrey",
//        border: "none",
//        backgroundColor: "transparent"
//     };
//  }


export const getCardPropertiesExport = (background) => {
    return {
        border: background ? "1px dashed lightgrey" : "none",
        background: background ? background : "transparent",
        // ":hover": { backgroundColor: "#ebf5f9" },
        // width: "auto",
        // height: "320px",
        p: 0,
        m: 0,
        flexWrap: 'wrap',
        borderRadius: "5px",
        margin: "20px",
        boxShadow: 2,
        overflow: "visible",
        placeItems: "center",
        placeContent: "center"
    };
}

export const getCardPropertiesExportRender = () => {
    return {
        background: "transparent",
        p: 0,
        m: 0,
        flexWrap: 'wrap',
        overflow: "visible",
        placeItems: "center",
        placeContent: "center",
        width: "fit-content",
        flexDirection: 'column',
        textAlign: 'center',
    };
}


export const getOrderEquipmentAdd = () => {
    return {
        p: 0,
        m: 0,
        flexWrap: 'wrap',
        borderRadius: "5px",
        margin: "20px",
        overflow: "visible",
        placeItems: "center",
        placeContent: "center"
    };
}

export const qrcodeTemplateButton = () => {
    return {
        height: "320px",
        width: "90%",
        maxWidth: "90%",
        color: getThemeColor()[getTheme()]["addButtonBackgroundColor"],
        fontWeight: "600",
        fontSize: "13px",
        textTransform: "initial",
        padding: "15px",
        ":hover": {
            backgroundColor: "#54b1d273",
            color: getThemeColor()[getTheme()]["addButtonFontColor"],
        }
    }
}