import { request } from 'constants/alias';
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from 'utils/functions';
import updateFieldIfValid from 'utils/functions/updateFieldIfValid';

/**
 * The rest request which attempts to get the sms campaigns list from the database.
 * @param {*} search The filters for search.
 * 
 * @returns an object with an attribute containing the sms campaigns list
 */
export async function fetchAll(search) {
    let data = {};
    updateFieldIfValid(data, "organizationID", search.organizationID);
    updateFieldIfValid(data, "outletID", search.outletID);
    updateFieldIfValid(data, "sms", search.sms);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}marketing/campaign/sms`, data));
    else return await request.get(includeParamsToUrl(`${API}marketing/campaign/sms`));
}

/**
 * The rest request which return required information to be presented on add new sms campaign page.
 * 
 * @param {*} organizationID The id of sms campaign to be fetched
 * @returns an object with an attribute containing the requested information to be presented on the sms campaign page
 */
export async function fetchNew(organizationID) {
    let data = {};
    updateFieldIfValid(data, "organizationID", organizationID);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}marketing/campaign/sms/add`, data));
    else return await request.get(includeParamsToUrl(`${API}marketing/campaign/sms/add`));
}

/**
 * The rest request which attempts to get an existing sms campaign form information.
 * 
 * @param {*} organizationID The id of the sms campaign form form organization id
 * @param {*} smsCampaignID The id of the sms campaign form to be fetched
 * 
 * @returns an object with an attribute containing the current infromation of the sms campaign
 */
export async function fetchOne(organizationID, outletID, smsCampaignID) {
    let data = {};
    updateFieldIfValid(data, "organizationID", organizationID);
    updateFieldIfValid(data, "outletID", outletID);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}marketing/campaign/sms/${smsCampaignID}`, data));
    else return await request.get(includeParamsToUrl(`${API}marketing/campaign/sms/${smsCampaignID}`));
}

/**
 * The rest request which attempts to create a new sms campaign.
 * @param {*} data A JSON object which contains new sms campaign infromation
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function create(data) {
    return await request.post(`${API}marketing/campaign/sms`, data);
}

/**
 * The rest request which attempts to update an existing sms campaign.
 * @param {*} data A JSON object which contains the updated infromation of the sms campaign
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function update(data) {
    return await request.put(`${API}marketing/campaign/sms`, data);
}

/**
 * The rest request to delete a specific sms campaign.
 * @param {*} id The id of the sms campaign to be deleted
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function deleteById(id) {
    return await request.delete(`${API}marketing/campaign/sms/${id}`);
}

const smsCampaignApi = {
    fetchAll,
    fetchNew,
    fetchOne,
    create,
    update,
    deleteById,
}

export default smsCampaignApi;