/**
 * Updates an object field with a list if the list is valid.
 *
 * A valid list must:
 * - Be an array.
 * - Have a length greater than 0.
 *
 * @param {object} data - The object to update.
 * @param {string} fieldName - The field name to update.
 * @param {array} value - The value to assign to the field if it's a valid list.
 * @returns {object} - The updated data object.
 *
 * @example
 * const obj = { tags: [] };
 * updateFieldIfValidList(obj, "tags", ["React", "JavaScript"]); // { tags: ["React", "JavaScript"] }
 * updateFieldIfValidList(obj, "tags", []); // { tags: [] }
 * 
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
const updateFieldIfValidList = (data, fieldName, value) => {
    // Check if the value is a valid list
    const isValidList = (value) => Array.isArray(value) && value.length > 0;

    if (isValidList(value)) {
        data[fieldName] = value; // Update the field if the value is a valid list
    }

    return data; // Return the updated object
};

export default updateFieldIfValidList;
