import {
    Add as AddIcon,
    Delete as DeleteIcon,
    Save as SaveIcon
} from '@mui/icons-material';
import { Fab, FormControlLabel, FormHelperText, Grid, InputLabel, Switch } from "@mui/material";
import equipmentApi from "api/equipment";
import en from "assets/images/flags/en.png";
import CustomAddEditBreadcrumbMultiParent from "components/Breadcrumb/CustomAddEditBreadcrumbMultiParent";
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomTranslationsAreaDialog from 'components/Dialogs/CustomTranslationsAreaDialog';
import CustomCollapsableFieldset from 'components/Fieldset/CustomCollapsableFieldset';
import CustomGrid from 'components/Grid/CustomGrid';
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomSelect from 'components/Select/CustomSelect';
import CustomSelectWithChip from "components/Select/CustomSelectWithChip";
import CustomSnackbar from "components/Snackbar/CustomSnackbar";
import CustomFlagTextArea from 'components/Text/CustomFlagTextArea';
import CustomTextField from 'components/Text/CustomTextField';
import CustomTitleBoldSubtitle from "components/Title/CustomTitleBoldSubtitle";
import { getEquipmentBulkAddDefaultObject } from 'constants/defaultObjects';
import { getEquipmentCapacity } from 'constants/fieldProperties';
import { getThemeColor } from 'constants/theme';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { addCardActionAreaButtonStyle, addWorkingHoursButtonIconStyle, cardItemButtonGroupStackButton, cardItemButtonGroupStackButtonIcon, fabStyle, formSaveButton, mainArea, mainTag, mainTagBreadcrumb, mainTagTitle, selectError, textFieldError } from 'themes/defaultThemes';
import { formIsValid, formValChangeWithParentElement, formValChangeWithParentElementObjectValues, formValChangeWithParentElementWithNameAndValue, onControlChange, onSelectChange } from "utils/form-validation";
import { validateInputNumber } from 'utils/functions';
import { getLanguageFromURL } from "utils/language";
import { getTheme } from "utils/theme";
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The EquipmentBulkAddComponent that triggers the bulk creation of a
 * new equipment or more.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function EquipmentBulkAddComponent() {
    const { t } = useTranslation();
    const { organizationID } = useParams();
    const { outletID } = useParams();
    const { equipmentID } = useParams();
    const navigate = useNavigate();
    let path = `/${getLanguageFromURL()}`
    if (organizationID !== undefined) path += `/organization/${organizationID}`;
    if (outletID !== undefined) path += `/outlet/${outletID}`;
    path += `/equipment`;

    /**
     * @type {object}
     * @property {boolean} isAdd to define if requested an edit or an add of a user
     * @property {boolean} redirect if a redirect should be performed
     * @property {string} error holdes the error message of a failed rest api call
     * @property {boolean} isLoaded to render DOM based on rest api call status, if true the rest api call completed
     * @property {object} userAuth the authenticated user infromation
     * @property {object} equipment an empty object if add or the selected user to be editted 
     * @property {object} outlet tha outlet basic details (id and name, isActive)
     * @property {object} organization tha organization basic details (id and name, isActive)
     * @property {*} icon the legend fa icon
     * @property {array} equipmentTypeOptions the different equipment types
     * @property {array} tagOptions a list of the different tags
     * @property {array} detailOptions a list of the different details
     * @property {object} alertBox holds snackbar infromation and style
     * @property {object} isError list of indication of empty form required field 
     *                           after submit to present error message to the
     *                           fields/attributes with true value
     */
    const [data, setData] = useState({
        error: null,
        userAuth: null,
        isAdd: equipmentID === undefined,
        equipment: null,
        organization: {
            id: organizationID,
            name: ""
        },
        outlet: {
            id: outletID,
            name: ""
        },
        icon: AddIcon,
        equipmentTypeOptions: [],
        tagOptions: [],
        detailOptions: [],
        alertBox: {
            isAOpen: false,
            message: "",
            backgroundColor: "#a71313"
        },
        // error messages per field
        isError: {
            type: "",
            labelBegginingText: "",
            startNumber: 0,
            endNumber: 0,
            capacity: ""
        },
    });

    const [isLoaded, setIsLoaded] = useState(false);
    // if true the menu item is clicked and a redirect to page should perform
    const [redirect, setRedirect] = useState(false);
    // popup dialog for delete
    const [dialog, setDialog] = useState({
        isOpen: false,
        message: t("label.configureTranslations"),
        id: "",
        field: "",
        translations: {}
    });
    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313"
    });


    //change document title    
    useDocumentTitle(`Usee  ${organizationID !== undefined ? " | " + t('org.pageTitle') : ""} ${outletID !== undefined ? " | " + t('equipment.pageTitle') : ""} | ${data.isAdd ? t('actions.add') : t('actions.edit')}`);

    useEffect(() => {
        getEquipment();
    }, []);

    /**
   * The rest endpoint to get the equipment default (add) or current (edit) information.
   */
    function getEquipment() {
        equipmentApi.fetchNew(organizationID, outletID).then((r) => {
            setData({
                ...data,
                userAuth: r.data.returnobject.userAuth,
                equipment: {
                    outletID: r.data.returnobject.outlet.id,
                    organizationID: r.data.returnobject.organization.id,
                    ...getEquipmentBulkAddDefaultObject()
                },
                equipmentTypeOptions: r.data.returnobject.equipmentTypeOptions,
                tagOptions: r.data.returnobject.tagOptions,
                detailOptions: r.data.returnobject.detailOptions,
                organization: r.data.returnobject.organization,
                outlet: r.data.returnobject.outlet
            });
            setIsLoaded(true);
        }).catch((e) => {
            // console.log(e);
            // setData({
            //     error: e,
            //     isLoaded: true
            // });
        })
    }

    /**
     * Gets called when the equipment clicks on the save button, and triggers 
     * the creation of the new equipment.
     */
    function addEquipment() {
        if (formValidation()) {
            equipmentApi.bulkAdd(data.equipment).then((r) => {
                setAlertBox({
                    isOpen: true,
                    message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                    backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                });
                setRedirect(r.data.code === "SUCCESS" ? true : false);
            }).catch((e) => {
                // console.log(e)
            });
        }
    }

    /**
     * Function that handles the delete modal open or close state.
     * @property {boolean} isOpen If the values is `true`, the dialog should be open and visible.
     * @property {string} field The field of the selected text translations to be removed.
     */
    function handleDialogState(isOpen, field = "") {
        setDialog({
            ...dialog,
            isOpen: isOpen,
            field: field,
            translations: data.item[field]
        });
    }

    /**
     * This function will trigger the equipment field translations configuration
     */
    function configureEquipmentTranslations(translations, field) {
        document.querySelectorAll(`[name="equipment.${field}.en"]`)[0].value = translations.en;
        setData({
            ...data,
            equipment: {
                ...data.equipment,
                [field]: translations
            }
        });
        setDialog({
            ...dialog,
            isOpen: false,
            field: "",
            translations: {}
        });
    }

    /**
     * Function to trigger the remove of the equipment.
     * @property {*} event
     * @property {string} field The field of the selected equipment field.
     */
    function configureTranslations(event, field) {
        handleDialogState(true, field);
    }

    /**
     * Function that triggers the removal of a tag.
     * @param {*} tagToDelete 
     */
    function removeTag(event, tagToDelete) {
        let tags = data.equipment.tags;
        const index = tags.indexOf(tagToDelete);
        // only splice array when item is found
        if (index > -1) tags.splice(index, 1); // 2nd parameter means remove one item only
        setData({
            ...data,
            equipment: {
                ...data.equipment,
                tags: tags
            }
        });
    }

    /**
     * Function that triggers form validation and print out if the form is valid or not.
     * @returns true if form is Valid
     */
    function formValidation() {
        let isError = createErrorMessages();
        if (formIsValid(isError)) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Function that create error messages for each required field that are not filled out.
     * @returns object containing the error messages for each form field
     */
    function createErrorMessages() {
        let isError = { ...data.isError };
        isError.labelBegginingText = data.equipment.labelBegginingText.length < 1 ? t('table.valueReuired') : "";
        isError.type = data.equipment.type.length < 1 ? t('table.valueReuired') : "";
        isError.startNumber = data.equipment.startNumber < 0 ? t('table.valueReuired') : "";

        if (data.equipment.endNumber < 0)
            isError.endNumber = t('table.valueReuired')
        else {
            if (data.equipment.endNumber < data.equipment.startNumber)
                isError.endNumber = t('equipment.maxLessThanMinErrorMessage')
        }

        setData({
            ...data,
            isError
        });
        return isError;
    }

    /**
     * Function that handles the snackbar open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }

    function handleDetailsChange(index, field, value) {
        console.log(field)
        let newDetails = data.equipment.details;
        newDetails[index][field] = value;
        formValChangeWithParentElementObjectValues("equipment", { details: newDetails }, data, setData);
    }

    function handleAddDetail() {
        let newDetails = data.equipment.details;
        newDetails.push({ key: "", value: "" });
        formValChangeWithParentElementObjectValues("equipment", { details: newDetails }, data, setData);
    }

    function removeDetail(index) {
        let newDetails = data.equipment.details;
        newDetails.splice(index, 1);
        formValChangeWithParentElementObjectValues("equipment", { details: newDetails }, data, setData);
    }

    // ===============================================================================RENDER

    if (redirect) {
        navigate(path);
    } else if ((!isLoaded && !data.isAdd) || data.equipment === null) {
        return (
            <LoadingSkeleton lines={9} />
        );
    } else {
        const { isError } = data;
        let defaultTypeSelected = "";
        let defaultTagsSelected = [];
        if (data.equipment !== null) {
            defaultTypeSelected = data.equipment.type;
            defaultTagsSelected = data.equipment.tags;
        }

        return (
            <CustomGrid role="main" id={"equipment-bulk-add"} sx={mainTag()}>

                {outletID !== undefined && organizationID !== undefined &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                                { name: t('outlet.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet` },
                                { name: data.outlet.name, url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet/${outletID}` },
                                { name: t('equipment.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet/${outletID}/equipment` },
                            ]}
                            instanceName={(data.equipment !== null) ? data.equipment.label : ""}
                        />
                    </CustomGrid>
                }
                {outletID === undefined && organizationID !== undefined &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                                { name: t('equipment.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/equipment` }
                            ]}
                            instanceName={(data.equipment !== null) ? data.equipment.label : ""}
                        />
                    </CustomGrid>
                }


                {outletID === undefined && organizationID === undefined &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('equipment.pageTitle'), url: `/${getLanguageFromURL()}/equipment` }
                            ]}
                            instanceName={(data.equipment !== null) ? data.equipment.label : ""}
                        />
                    </CustomGrid>
                }

                <CustomGrid sx={mainTagTitle()}>
                    <CustomTitleBoldSubtitle
                        title={`${t('equipment.pageTitle')} | ${t('actions.add')}`}
                        subtitleBeforeText={t('actions.tableCreateNewLinkBulk') + t('equipment.title')}
                        subtitleboldText={!data.isAdd && data.equipment !== null && data.equipment !== undefined && data.equipment.name}
                        subtitleAfterText={!data.isAdd ? " profile page" : ""}
                    />
                </CustomGrid>

                <CustomGrid sx={mainArea()} container={false}>
                    <form id="equipment" onSubmit={(e) => { return false; }}>
                        <input type="hidden" name="outlet.id" id="outletID" defaultValue={data.outlet.id} />
                        {data.isAdd &&
                            <input type="hidden" />
                        }

                        {!data.isAdd &&
                            <input type="hidden" name="id" id="id" defaultValue={data.equipmentID} />
                        }

                        <Grid container spacing={2}>
                            <Grid item xs={6} md={3}>
                                <InputLabel required={true} htmlFor="type">{t('label.type')}</InputLabel>
                                <CustomSelect
                                    name="equipment.type"
                                    required={true}
                                    value={defaultTypeSelected}
                                    options={data.equipmentTypeOptions}
                                    onChange={(event) => onSelectChange(event, data, setData)}
                                    error={isError.type.length > 0}
                                />
                                {isError.type.length > 0 &&
                                    <FormHelperText sx={selectError()}>
                                        {isError.type}
                                    </FormHelperText>
                                }
                            </Grid>

                            <Grid item xs={6} md={3}>
                                <InputLabel required={false} htmlFor="capacity">{t('equipment.capacity')}</InputLabel>
                                <CustomTextField
                                    type="number"
                                    step={getEquipmentCapacity().step}
                                    min={getEquipmentCapacity().min}
                                    max={getEquipmentCapacity().max}
                                    name="equipment.capacity"
                                    id="capacity"
                                    variant="outlined"
                                    value={data.equipment.capacity}
                                    required={false}
                                    onChange={(event) => {
                                        let value = validateInputNumber(event.target.value, getEquipmentCapacity().length, getEquipmentCapacity().min, getEquipmentCapacity().max, getEquipmentCapacity()().isFloat);
                                        if (value === undefined) value = data.equipment.capacity;
                                        formValChangeWithParentElementWithNameAndValue("equipment.capacity", parseInt(value), data, setData)
                                    }}
                                    helperText={isError.capacity}
                                    sx={textFieldError()}
                                    error={isError.capacity.length > 0 ? true : false}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <InputLabel required={false} htmlFor="tags">{t('label.tags')}</InputLabel>
                                <CustomSelectWithChip id="tags" name="equipment.tags" required={true}
                                    defaultValue={defaultTagsSelected}
                                    options={data.tagOptions} onChange={(event) => onSelectChange(event, data, setData)}
                                    onDelete={removeTag}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <InputLabel required={false} htmlFor="floor">{t('label.floor')}</InputLabel>
                                <CustomTextField
                                    type="text"
                                    name="user.floor"
                                    id="floor"
                                    defaultValue={!data.isAdd ? data.equipment.floor : ""}
                                    onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <InputLabel required={false} htmlFor="description">{t('label.description')}</InputLabel>
                                <CustomFlagTextArea
                                    country="en"
                                    flag={en}
                                    placeholder=""
                                    name="equipment.description.en"
                                    id="description"
                                    onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                    defaultValue={data.equipment !== null && data.equipment.description !== null ? data.equipment.description.en : ""}
                                    onTranslationSelect={configureTranslations}
                                    field="description"
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <InputLabel required={false} htmlFor="labelBegginingText">{t('equipment.labelBegginingText')}</InputLabel>
                                <CustomTextField
                                    type="text"
                                    name="equipment.labelBegginingText"
                                    id="labelBegginingText"
                                    placeholder={t('label.labelPlaceholder')}
                                    required={true}
                                    disabled={!data.isAdd} defaultValue={!data.isAdd ? data.equipment.label : ""}
                                    onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                    helperText={isError.labelBegginingText}
                                    sx={textFieldError()}
                                    error={isError.labelBegginingText.length > 0 ? true : false}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2} sx={{ marginTop: "10px" }}>
                                    <Grid item xs={12} md={6}>
                                        <CustomTextField
                                            type="number"
                                            step="1"
                                            min={0}
                                            name="equipment.startNumber"
                                            id="startNumber"
                                            variant="outlined"
                                            value={data.equipment.startNumber}
                                            inputAdornmentLabel="equipment.startNumber"
                                            required={false}
                                            onChange={(event) => {
                                                let value = parseInt(validateInputNumber(event.target.value, 4, 0));
                                                if (value === undefined) value = data.equipment.startNumber;
                                                Promise.resolve()
                                                    .then(() => {
                                                        formValChangeWithParentElementWithNameAndValue("equipment.startNumber", value, data, setData)
                                                    })
                                                    .then(() => {
                                                        if (value > data.equipment.endNumber)
                                                            formValChangeWithParentElementWithNameAndValue("equipment.endNumber", value, data, setData)
                                                    })
                                            }}
                                            // helperText={isError.startNumber}
                                            sx={textFieldError()}
                                            error={isError.startNumber.length > 0 ? true : false}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <CustomTextField
                                            type="number"
                                            step="1"
                                            min={0}
                                            name="equipment.endNumber"
                                            id="endNumber"
                                            variant="outlined"
                                            value={data.equipment.endNumber}
                                            inputAdornmentLabel="equipment.endNumber"
                                            required={false}
                                            onChange={(event) => {
                                                let value = parseInt(validateInputNumber(event.target.value, 4, data.equipment.startNumbe));
                                                if (value === undefined) value = data.equipment.endNumber;
                                                formValChangeWithParentElementWithNameAndValue("equipment.endNumber", value, data, setData)
                                            }}
                                            // helperText={data.isError.endNumber}
                                            sx={textFieldError()}
                                            error={isError.endNumber.length > 0 ? true : false}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>



                            <Grid item xs={12} md={12} sx={{ margin: "20px 0" }}>
                                <CustomCollapsableFieldset
                                    label={t('label.detail')}
                                    isOpen={true}
                                    children={<>
                                        <Grid container spacing={2} sx={{ marginTop: '5px', marginLeft: "10px" }}>
                                            <Grid item xs={12} md={12}>
                                                {data.equipment.details.map((detail, index) => (
                                                    <Grid container spacing={2} key={index} sx={{ marginTop: 0, alignItems: "center" }}>
                                                        <Grid item xs={12} md={5}>
                                                            <InputLabel required={false} htmlFor="key">{t('label.key')}</InputLabel>
                                                            <CustomSelect
                                                                name="key"
                                                                required={true}
                                                                id="key"
                                                                value={detail.key}
                                                                options={data.detailOptions}
                                                                onChange={(event) => handleDetailsChange(index, "key", event.target.value)}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12} md={6}>
                                                            <InputLabel required={false} htmlFor="value">{t('label.value')}</InputLabel>
                                                            <CustomTextField
                                                                type="text"
                                                                name="value"
                                                                id="value"
                                                                value={detail.value}
                                                                onChange={(event) => handleDetailsChange(index, "value", event.target.value)}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12} md={1}>
                                                            <CustomIconButton
                                                                keyValue={`btn-delete-${index}`}
                                                                onClick={removeDetail}
                                                                icon={<DeleteIcon style={cardItemButtonGroupStackButtonIcon()} />}
                                                                className="icon-wrapper tr link"
                                                                iconSx={{ marginRight: "0px!important" }}
                                                                sx={{
                                                                    backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColor"],
                                                                    ":hover": {
                                                                        backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColorHover"],
                                                                    },
                                                                    ...cardItemButtonGroupStackButton()
                                                                }}
                                                            >
                                                                {t('actions.delete')}
                                                            </CustomIconButton>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                            </Grid>

                                            <Grid item xs={12} md={12} sx={{ marginBottom: "10px" }}>
                                                <CustomIconButton
                                                    sx={addCardActionAreaButtonStyle()}
                                                    backgroundColor="transparent"
                                                    icon={<AddIcon sx={addWorkingHoursButtonIconStyle()} />}
                                                    onClick={handleAddDetail}>
                                                    Add
                                                </CustomIconButton>
                                            </Grid>
                                        </Grid>
                                    </>
                                    }
                                />
                            </Grid>


                            <FormControlLabel control={<Switch defaultChecked={data.equipment.active} onChange={(event) => onControlChange(event, data, setData, "equipment", "active")} />} label={t(data.equipment.active ? 'label.isActive' : 'label.isNotActive')} />


                            <Grid container spacing={2}>
                                {/* <Grid item>
                                    <CustomIconButton
                                        variant="outlined"
                                        keyValue="save"
                                        onClick={addEquipment}
                                        label={t('actions.save')}
                                        sx={{ ...formSaveButton(), marginLeft: "20px" }}
                                    />
                                </Grid> */}

                                {data.isAdd &&
                                    <Grid item>
                                        <CustomIconButton
                                            variant="outlined"
                                            keyValue="cancel"
                                            link={path}
                                            label={t('actions.back')}
                                            sx={formSaveButton()}
                                            color="error"
                                        />
                                    </Grid>
                                }

                                {!data.isAdd &&
                                    <Grid item>
                                        <CustomIconButton
                                            variant="outlined"
                                            keyValue="cancel"
                                            onClick={getEquipment}
                                            label={t('actions.cancel')}
                                            sx={formSaveButton()}
                                            color="error"
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </form>

                    <Fab
                        color="primary"
                        aria-label="add"
                        sx={fabStyle()}
                        onClick={addEquipment}
                    >
                        <SaveIcon />
                    </Fab>
                </CustomGrid>

                {dialog.isOpen &&
                    <CustomTranslationsAreaDialog
                        isOpen={dialog.isOpen}
                        id="translations"
                        title={t('actions.translationModal.title')}
                        actionLabel={t('actions.save')}
                        action={configureEquipmentTranslations}
                        handleOpen={handleDialogState}
                        translations={data.equipment[dialog.field]}
                        field={dialog.field} />
                }

                {alertBox.isOpen &&
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={3000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        handleSnackbarOpen={handleSnackbarState} />
                }
            </CustomGrid>
        );
    }
}

export default EquipmentBulkAddComponent;