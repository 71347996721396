
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Tooltip } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getThemeColor } from 'constants/theme';
import { useTranslation } from 'react-i18next';
import { getTheme } from "utils/theme";

/**
 * The Custom Delete Button, that display an control button for table actions section.
 * @property {string} label the button text value
 * @property {boolean} shownLabel if the label should be shown
 * @property {function} onClick a button on click action
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomDeleteButton({ label, shownLabel = true, onClick, keyStr = "d-btn" }) {
    const { t } = useTranslation();

    function getMuiTheme() {
        return createTheme({
            components: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            backgroundColor: getThemeColor()[getTheme()]["deleteButtonBackgroundColor"],
                            margin: "0px",
                            // marginLeft: "10px"
                        },
                        ":hover": {
                            backgroundColor: `${getThemeColor()[getTheme()]["deleteButtonBackgroundColorHover"]} !important`,
                        },
                        startIcon: {
                            margin: "0px",
                            padding: "0px"
                        }
                    }
                }
            }
        })
    }

    return (
        <ThemeProvider theme={getMuiTheme}>
            <Tooltip arrow title={t('actions.delete')}>
                <Button key={keyStr} variant="contained" startIcon={<DeleteIcon />} onClick={onClick}>{shownLabel ? label : ""}</Button>
            </Tooltip>
        </ThemeProvider>
    );
}

export default CustomDeleteButton;