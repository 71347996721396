import { getDateFormatter } from "constants/calendar";
import { hasRoleAdmin } from "utils/auth";
import { dateFormatter, dotFieldFormatter, nanFormatter } from "utils/table";

export function recipient_table_columns(data, t) {
    return [
        {
            name: "active",
            label: t('label.isActive'),
            options: {
                filter: false,
                sort: false,
                empty: false,
                customBodyRender: (value, tableMeta) => {
                    return dotFieldFormatter(value);
                }
            }
        },
        {
            name: "id",
            label: t('label.id'),
            options: {
                viewColumns: data.userAuth !== null && hasRoleAdmin(data.userAuth.roles),
                filter: false,
                sort: false,
                empty: false,
                display: false,
                customBodyRender: (value, tableMeta) => {
                    return value;
                }
            }
        },
        {
            name: "firstName",
            label: t('label.firstName'),
            options: {
                filter: false,
                sort: true,
                empty: false,
                customBodyRender: (value, tableMeta) => {
                    return nanFormatter(value, tableMeta.tableData[tableMeta.rowIndex], tableMeta.rowIndex, { emptyMessage: "-" });
                }
            }
        },
        {
            name: "fatherName",
            label: t('label.fatherName'),
            options: {
                filter: false,
                sort: true,
                empty: false,
                customBodyRender: (value, tableMeta) => {
                    return nanFormatter(value, tableMeta.tableData[tableMeta.rowIndex], tableMeta.rowIndex, { emptyMessage: "-" });
                }
            }
        },
        {
            name: "lastName",
            label: t('label.lastName'),
            options: {
                filter: false,
                sort: true,
                empty: false,
                customBodyRender: (value, tableMeta) => {
                    return nanFormatter(value, tableMeta.tableData[tableMeta.rowIndex], tableMeta.rowIndex, { emptyMessage: "-" });
                }
            }
        },
        {
            name: "phone",
            label: t('label.phone'),
            options: {
                filter: true,
                filterType: 'textField',
                customFilterListRender: v => `${t('label.phone')}: ${v}`,
                sort: true,
                empty: false,
                customBodyRender: (value, tableMeta) => {
                    return nanFormatter(value, tableMeta.tableData[tableMeta.rowIndex], tableMeta.rowIndex, { emptyMessage: "-" });
                }
            }
        },
        {
            name: "email",
            label: t('label.email'),
            options: {
                filter: true,
                filterType: 'textField',
                customFilterListRender: v => `${t('label.email')}: ${v}`,
                sort: true,
                empty: false,
                customBodyRender: (value, tableMeta) => {
                    return nanFormatter(value, tableMeta.tableData[tableMeta.rowIndex], tableMeta.rowIndex, { emptyMessage: "-" });
                }
            }
        },
        {
            name: "age",
            label: t('marketing.recipient.age'),
            options: {
                filter: true,
                filterType: 'textField',
                customFilterListRender: v => `${t('marketing.recipient.age')}: ${v}`,
                sort: true,
                empty: false,
                customBodyRender: (value, tableMeta) => {
                    return nanFormatter(value, tableMeta.tableData[tableMeta.rowIndex], tableMeta.rowIndex, { emptyMessage: "-" });
                }
            }
        },
        {
            name: "name",
            label: t('label.name'),
            options: {
                display: false,
                filter: true,
                filterType: 'textField',
                customFilterListRender: v => `${t('label.name')}: ${v}`,
                sort: false,
                empty: true,
                viewColumns: false,
                customBodyRender: (value, tableMeta) => {
                    return tableMeta.tableData[tableMeta.rowIndex][2] +
                        // " "+ tableMeta.tableData[tableMeta.rowIndex][3]+
                        " " + tableMeta.tableData[tableMeta.rowIndex][4];
                }
            }
        },
        {
            name: "birthdate",
            label: t('marketing.recipient.birthdate'),
            options: {
                filter: false,
                sort: true,
                empty: false,
                customBodyRender: (value, tableMeta) => {
                    return dateFormatter(value, tableMeta.tableData[tableMeta.rowIndex], null, getDateFormatter());
                }
            }
        },
    ]
};



export function recipient_table_columns_sms_campaign(data, t) {
    return [
        {
            name: "active",
            label: t('label.isActive'),
            options: {
                filter: false,
                sort: false,
                empty: false,
                customBodyRender: (value, tableMeta) => {
                    return dotFieldFormatter(value);
                }
            }
        },
        {
            name: "id",
            label: t('label.id'),
            options: {
                viewColumns: data.userAuth !== null && hasRoleAdmin(data.userAuth.roles),
                filter: false,
                sort: false,
                empty: false,
                display: false,
                customBodyRender: (value, tableMeta) => {
                    return value;
                }
            }
        },
        {
            name: "firstName",
            label: t('label.firstName'),
            options: {
                filter: false,
                sort: true,
                empty: false,
                customBodyRender: (value, tableMeta) => {
                    return nanFormatter(value, tableMeta.tableData[tableMeta.rowIndex], tableMeta.rowIndex, { emptyMessage: "-" });
                }
            }
        },
        {
            name: "fatherName",
            label: t('label.fatherName'),
            options: {
                filter: false,
                sort: true,
                empty: false,
                customBodyRender: (value, tableMeta) => {
                    return nanFormatter(value, tableMeta.tableData[tableMeta.rowIndex], tableMeta.rowIndex, { emptyMessage: "-" });
                }
            }
        },
        {
            name: "lastName",
            label: t('label.lastName'),
            options: {
                filter: false,
                sort: true,
                empty: false,
                customBodyRender: (value, tableMeta) => {
                    return nanFormatter(value, tableMeta.tableData[tableMeta.rowIndex], tableMeta.rowIndex, { emptyMessage: "-" });
                }
            }
        },
        {
            name: "phone",
            label: t('label.phone'),
            options: {
                filter: true,
                filterType: 'textField',
                customFilterListRender: v => `${t('label.phone')}: ${v}`,
                sort: true,
                empty: false,
                customBodyRender: (value, tableMeta) => {
                    return nanFormatter(value, tableMeta.tableData[tableMeta.rowIndex], tableMeta.rowIndex, { emptyMessage: "-" });
                }
            }
        },
        {
            name: "name",
            label: t('label.name'),
            options: {
                display: false,
                filter: true,
                filterType: 'textField',
                customFilterListRender: v => `${t('label.name')}: ${v}`,
                sort: false,
                empty: true,
                viewColumns: false,
                customBodyRender: (value, tableMeta) => {
                    return value;
                }
            }
        },
    ]
};



export function recipient_table_columns_email_campaign(data, t) {
    return [
        {
            name: "active",
            label: t('label.isActive'),
            options: {
                filter: false,
                sort: false,
                empty: false,
                customBodyRender: (value, tableMeta) => {
                    return dotFieldFormatter(value);
                }
            }
        },
        {
            name: "id",
            label: t('label.id'),
            options: {
                viewColumns: data.userAuth !== null && hasRoleAdmin(data.userAuth.roles),
                filter: false,
                sort: false,
                empty: false,
                display: false,
                customBodyRender: (value, tableMeta) => {
                    return value;
                }
            }
        },
        {
            name: "firstName",
            label: t('label.firstName'),
            options: {
                filter: false,
                sort: true,
                empty: false,
                customBodyRender: (value, tableMeta) => {
                    return nanFormatter(value, tableMeta.tableData[tableMeta.rowIndex], tableMeta.rowIndex, { emptyMessage: "-" });
                }
            }
        },
        {
            name: "fatherName",
            label: t('label.fatherName'),
            options: {
                filter: false,
                sort: true,
                empty: false,
                customBodyRender: (value, tableMeta) => {
                    return nanFormatter(value, tableMeta.tableData[tableMeta.rowIndex], tableMeta.rowIndex, { emptyMessage: "-" });
                }
            }
        },
        {
            name: "lastName",
            label: t('label.lastName'),
            options: {
                filter: false,
                sort: true,
                empty: false,
                customBodyRender: (value, tableMeta) => {
                    return nanFormatter(value, tableMeta.tableData[tableMeta.rowIndex], tableMeta.rowIndex, { emptyMessage: "-" });
                }
            }
        },
        {
            name: "email",
            label: t('label.email'),
            options: {
                filter: true,
                filterType: 'textField',
                customFilterListRender: v => `${t('label.email')}: ${v}`,
                sort: true,
                empty: false,
                customBodyRender: (value, tableMeta) => {
                    return nanFormatter(value, tableMeta.tableData[tableMeta.rowIndex], tableMeta.rowIndex, { emptyMessage: "-" });
                }
            }
        },
        {
            name: "name",
            label: t('label.name'),
            options: {
                display: false,
                filter: true,
                filterType: 'textField',
                customFilterListRender: v => `${t('label.name')}: ${v}`,
                sort: false,
                empty: true,
                viewColumns: false,
                customBodyRender: (value, tableMeta) => {
                    return value;
                }
            }
        },
    ]
};