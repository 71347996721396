import { getLangOptions } from "constants/langOptions";
import { AuthConsumer } from "context/AuthContext";
import AnalyticsComponent from "pages/analytics/analytics.component";
import EmployeeStatsComponent from "pages/analytics/stats.component";
import ChatComponent from "pages/chat/chat-component";
import CustomerEmployeeViewComponent from "pages/customers/customer-employee-view-page.component";
import CustomerListComponentAdmin from "pages/customers/customer-list-admin.component";
import CustomerListComponent from "pages/customers/customer-list.component";
import KitchenDisplaySettingsComponent from "pages/displays/kitchen/kitchen-display-settings";
import KitchenDisplayComponent from "pages/displays/kitchen/kitchen-display.component";
import KitchenDisplayPastOrders from "pages/displays/past-orders";
import PDADisplayComponent from "pages/displays/pda-display.component";
import PickupOrderDisplaySettingsComponent from "pages/displays/pick-up/pickup-order-display-settings.component";
import PickupOrderDisplayComponent from "pages/displays/pick-up/pickup-order-display.component";
import FloorEditorComponent from "pages/displays/table/floor-editor.components";
import WaitlistDisplayComponent from "pages/displays/waitlist/waitlist-display.component";
import EmployeeAddEditComponent from "pages/employee/employee-add-edit.component";
import EmployeeListComponent from "pages/employee/employee-list.component";
import EquipmentAddEditComponent from "pages/equipment/equipment-add-edit.component";
import EquipmentBulkAddComponent from "pages/equipment/equipment-bulk-add.component";
import EquipmentListComponent from "pages/equipment/equipment-list.component";
import FeedbackFormAddEditComponent from "pages/feedback/feedback-add-edit.comonent";
import FeedbackListComponent from "pages/feedback/feedback-list.component";
import HelpListComponent from "pages/help/help-list.component";
import ItemAddEditComponent from "pages/item/item-add-edit.component";
import ItemInventoryComponent from "pages/item/item-inventory.component";
import ItemListComponent from "pages/item/item-list.component";
import ModifierListAddEditComponent from "pages/item/modifier-list-add-edit.component";
import ModifierListListComponent from "pages/item/modifier-list-list.component";
import ItemOfferAddEditComponent from "pages/item/offer/offer-add-edit.component";
import ItemOfferListComponent from "pages/item/offer/offer-list.component";
import LoyaltySettingsTableComponent from "pages/loyalty/loyalty-settings-table.component";
import LoyaltySettingsComponent from "pages/loyalty/loyalty-settings.component";
import EmailCampaignAddViewComponent from "pages/marketing/campaign/email/email-add-campaign-component";
import EmailCampaignListComponent from "pages/marketing/campaign/email/email-campaign-list.component";
import SmsCampaignComponent from "pages/marketing/campaign/sms/sms-add-campaign-component";
// import SmsCampaignComponent from "pages/marketing/campaign/sms-campaign-component";
import SmsCampaignListComponent from "pages/marketing/campaign/sms/sms-campaign-list.component";
import RecipientGroupAddEditComponent from "pages/marketing/recipient/group/recipient-group-add-edit.component";
import RecipientGroupListComponent from "pages/marketing/recipient/group/recipient-group-list.component";
import RecipientAddEditComponent from "pages/marketing/recipient/recipient-add-edit.component";
import RecipientListComponent from "pages/marketing/recipient/recipient-list.component";
import EmailTemplateListComponent from "pages/marketing/template/email-template-list-component";
// import QRcodeSettingsComponent from "pages/marketing/qrcode/qrcode-settings.component";
import MenuAddEditComponent from "pages/menu/menu-add-edit.component";
import MenuCategoriesComponent from "pages/menu/menu-categories.component";
import MenuCMSComponent from "pages/menu/menu-cms.component";
import MenuListComponent from "pages/menu/menu-list.component";
import MenuSettingsComponent from "pages/menu/menu-settings.component";
import OrderAddComponent from "pages/order/order-add.component";
import OrderEditPdaComponent from "pages/order/order-edit-pda.component";
import OrderEditComponent from "pages/order/order-edit.component";
import OrderListComponent from "pages/order/order-list.component";
import OrganizationAddEditComponent from "pages/organizations/organization-add-edit.component";
import OrganizationListComponent from "pages/organizations/organization-list.component";
import OutletAddEditComponent from "pages/outlet/outlet-add-edit.component";
import OutletListComponent from "pages/outlet/outlet-list.component";
import OutletSettingsComponent from "pages/outlet/outlet-settings.component";
import FailPaymentComponent from "pages/payment/fail-payment.component";
import SuccessPaymentComponent from "pages/payment/success-payment.component";
import PosListComponent from "pages/pos/pos-list.component";
import PrinterListComponent from "pages/printer/printer-list.component";
import RatingComponent from "pages/rating/rating.component";
import CustomerRequestListComponent from "pages/request/customer-request-list.component";
import MySectionComponent from "pages/section/my-section.comonent";
import SectionAddEditComponent from "pages/section/section-add-edit.comonent";
import SectionListComponent from "pages/section/section-list.component";
import SubscriptionAddEditComponent from "pages/subscriptions/subscription-add-edit.component";
import SubscriptionListComponent from "pages/subscriptions/subscription-list.component";
import SubscriptionOrganizationComponent from "pages/subscriptions/subscription-organization.component";
import SubscriptionPlanAddEditComponent from "pages/subscriptions/subscription-plan-add-edit.component";
import SubscriptionPlanListComponent from "pages/subscriptions/subscription-plan-list.component";
// import TestComponent from "pages/test/test.component";
import UserAddEditComponent from "pages/user/user-add-edit.component";
import UserListComponent from "pages/user/user-list.component";
import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import {
    hasAnyRole,
    hasRoleAdmin,
    hasRoleAdminOrDirectorOrManager,
    hasRoleDirector,
    hasRoleFloorSupervisor,
    hasRoleForMySections,
    hasRoleForPDA,
    hasRoleForRequest,
    hasRoleMenu,
    hasRoleMessaging,
    hasRoleRating_Viewer
} from "utils/auth";

/**
 * The Auth Routes.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function AuthRoutes() {
    const location = useLocation();
    let mylang = localStorage.getItem("i18nextLng").substring(0, 2);
    if (mylang === null || mylang === undefined) {
        mylang = "en";
        localStorage.setItem("i18nextLng", "en");
    }
    let theme = localStorage.getItem("theme");
    if (theme !== undefined && theme !== "light" && theme !== "dark" && theme !== "") {
        localStorage.setItem("theme", "light");
    }

    // Check if the path starts with /en/ or /gr/
    const path = location.pathname;
    if (!path.startsWith("/en") && !path.startsWith("/el")) {
        // If the path doesn't start with /en/ or /gr/, redirect to the same path with /en/ in front
        return <Navigate to={`${mylang}${path}${location.search}`} replace />;
    }

    return (
        <React.Fragment>
            <AuthConsumer>
                {({ user }) => (
                    <Routes>
                        {getLangOptions().map((lang, i) => (
                            <>
                                {hasAnyRole(user.roles) && (
                                    <>
                                        {hasRoleRating_Viewer(user.roles) && (
                                            <>
                                                <Route
                                                    key='menu-rating'
                                                    path={`/${lang}/rating`}
                                                    element={<RatingComponent />}
                                                />
                                            </>
                                        )}

                                        {hasRoleForMySections(user.roles) &&
                                            <Route
                                                key='menu-tables'
                                                path={`/${lang}/section`}
                                                element={<SectionListComponent />}
                                            />
                                        }


                                        {/* PDA */}
                                        {hasRoleForPDA(user.roles) &&
                                            <>
                                                <Route
                                                    key='menu-pda-order-edit'
                                                    caseSensitive
                                                    path={`/${lang}/pda/order/:id`}
                                                    element={<OrderEditPdaComponent />}
                                                />
                                                <Route
                                                    key='menu-pda'
                                                    caseSensitive
                                                    path={`/${lang}/pda`}
                                                    element={<PDADisplayComponent />}
                                                />
                                                <Route
                                                    key='menu-mysections'
                                                    path={`/${lang}/mysections`}
                                                    element={<MySectionComponent />}
                                                />
                                                <Route
                                                    key='menu-employee-stats'
                                                    caseSensitive
                                                    path={`/${lang}/stats`}
                                                    element={<EmployeeStatsComponent />}
                                                />
                                            </>
                                        }


                                        {/* REQUEST */}
                                        {hasRoleForRequest(user.roles) &&
                                            <Route
                                                key='menu-request'
                                                caseSensitive
                                                path={`/${lang}/request`}
                                                element={<CustomerRequestListComponent />}
                                            />
                                        }

                                        {hasRoleAdmin(user.roles) && (
                                            <>
                                                {/* USER */}
                                                <Route
                                                    key='menu-user-list'
                                                    caseSensitive
                                                    path={`/${lang}/user`}
                                                    element={<UserListComponent />}
                                                />
                                                <Route
                                                    key='menu-user-add'
                                                    caseSensitive
                                                    path={`/${lang}/user/add`}
                                                    element={<UserAddEditComponent />}
                                                />

                                                {/* ORGANIZATION */}
                                                <Route
                                                    key='menu-organization-view'
                                                    caseSensitive
                                                    path={`/${lang}/organization`}
                                                    element={<OrganizationListComponent />}
                                                />
                                                <Route
                                                    key='menu-organization-add'
                                                    caseSensitive
                                                    path={`/${lang}/organization/add`}
                                                    element={<OrganizationAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-organization-edit'
                                                    path={`/${lang}/organization/:organizationID`}
                                                    element={<OrganizationAddEditComponent />}
                                                />
                                            </>
                                        )}

                                        {/* SUBSCRIPTION */}
                                        <Route key="menu-subscription-add" caseSensitive path={`/${lang}/subscription/add`} element={<SubscriptionAddEditComponent />} />
                                        <Route key="menu-subscription-edit" path={`/${lang}/subscription/:subscriptionID`} element={<SubscriptionAddEditComponent />} />
                                        <Route key="menu-subscription-list" caseSensitive path={`/${lang}/subscription`} element={hasRoleAdmin(user.roles) ? <SubscriptionListComponent /> : <SubscriptionOrganizationComponent />} />

                                        {/* SUBSCRIPTION PLAN */}
                                        <Route
                                            key='menu-subscription-plan-add'
                                            caseSensitive
                                            path={`/${lang}/subscription-plan/add`}
                                            element={<SubscriptionPlanAddEditComponent />}
                                        />
                                        <Route
                                            key='menu-subscription-plan-edit'
                                            path={`/${lang}/subscription-plan/:subscriptionPlanID`}
                                            element={<SubscriptionPlanAddEditComponent />}
                                        />
                                        <Route
                                            key='menu-subscription-plan-list'
                                            caseSensitive
                                            path={`/${lang}/subscription-plan`}
                                            element={<SubscriptionPlanListComponent />}
                                        />

                                        {hasRoleMenu(user.roles) && (
                                            <>
                                                {/* ITEM */}
                                                <Route
                                                    key='menu-item-add'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/item-or-modifier/add`}
                                                    element={<ItemAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-item-edit'
                                                    path={`/${lang}/organization/:organizationID/item-or-modifier/:itemID`}
                                                    element={<ItemAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-item-list'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/item-or-modifier`}
                                                    element={<ItemListComponent />}
                                                />
                                                {/* ITEM */}
                                                <Route
                                                    key='menu-item-add-plain'
                                                    caseSensitive
                                                    path={`/${lang}/item-or-modifier/add`}
                                                    element={<ItemAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-item-edit-plain'
                                                    path={`/${lang}/item-or-modifier/:itemID`}
                                                    element={<ItemAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-item-list-plain'
                                                    caseSensitive
                                                    path={`/${lang}/item-or-modifier`}
                                                    element={<ItemListComponent />}
                                                />

                                                {/* MODIFIER LIST */}
                                                <Route
                                                    key='menu-modfier-list-add'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/modifier-list/add`}
                                                    element={<ModifierListAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-modfier-list-edit'
                                                    path={`/${lang}/organization/:organizationID/modifier-list/:modifierListID`}
                                                    element={<ModifierListAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-modfier-list-list'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/modifier-list`}
                                                    element={<ModifierListListComponent />}
                                                />

                                                {/* MODIFIER LIST */}
                                                <Route
                                                    key='menu-modfier-list-add'
                                                    caseSensitive
                                                    path={`/${lang}/modifier-list/add`}
                                                    element={<ModifierListAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-modfier-list-edit'
                                                    path={`/${lang}/modifier-list/:modifierListID`}
                                                    element={<ModifierListAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-modfier-list-list'
                                                    caseSensitive
                                                    path={`/${lang}/modifier-list`}
                                                    element={<ModifierListListComponent />}
                                                />

                                                {/* MENUS */}
                                                <Route
                                                    key='menu-menu-list-all'
                                                    caseSensitive
                                                    path={`/${lang}/menu`}
                                                    element={<MenuListComponent />}
                                                />
                                                <Route
                                                    key='menu-menu-add'
                                                    caseSensitive
                                                    path={`/${lang}/menu/add`}
                                                    element={<MenuAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-menu-edit'
                                                    caseSensitive
                                                    path={`/${lang}/menu/:menuID`}
                                                    element={<MenuAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-menu-cms'
                                                    caseSensitive
                                                    path={`/${lang}/menu/:menuID/cms`}
                                                    element={<MenuCMSComponent />}
                                                />
                                                <Route
                                                    key='menu-menu-settings'
                                                    caseSensitive
                                                    path={`/${lang}/menu/:menuID/settings`}
                                                    element={<MenuSettingsComponent />}
                                                />
                                                <Route
                                                    key='menu-menu-categories'
                                                    caseSensitive
                                                    path={`/${lang}/menu/:menuID/categories`}
                                                    element={<MenuCategoriesComponent />}
                                                />
                                                <Route
                                                    key='menu-menu-list-org'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/menu`}
                                                    element={<MenuListComponent />}
                                                />
                                                <Route
                                                    key='menu-menu-add-org'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/menu/add`}
                                                    element={<MenuAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-menu-edit-org'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/menu/:menuID`}
                                                    element={<MenuAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-menu-settings-org'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/menu/:menuID/settings`}
                                                    element={<MenuSettingsComponent />}
                                                />
                                                <Route
                                                    key='menu-menu-categories-org'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/menu/:menuID/categories`}
                                                    element={<MenuCategoriesComponent />}
                                                />
                                                <Route
                                                    key='menu-menu-org-cms-org'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/menu/:menuID/cms`}
                                                    element={<MenuCMSComponent />}
                                                />
                                                {/* <Route
                          key='menu-menu-settings'
                          caseSensitive
                          path={`/${lang}/organization/:organizationID/menu/settings`}
                          element={<MenuSettingsComponent />}
                        /> */}

                                                {/* OFFERS */}
                                                <Route
                                                    key='menu-offer-add'
                                                    caseSensitive
                                                    path={`/${lang}/offer/add`}
                                                    element={<ItemOfferAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-offer-edit'
                                                    path={`/${lang}/offer/:offerID`}
                                                    element={<ItemOfferAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-offer-list'
                                                    caseSensitive
                                                    path={`/${lang}/offer`}
                                                    element={<ItemOfferListComponent />}
                                                />

                                                {/* INVENTORY */}
                                                <Route
                                                    key='menu-item-inventory'
                                                    caseSensitive
                                                    path={`/${lang}/inventory`}
                                                    element={<ItemInventoryComponent />}
                                                />
                                                <Route
                                                    key='menu-inventory-org'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/inventory`}
                                                    element={<ItemInventoryComponent />}
                                                />

                                            </>
                                        )}

                                        {hasRoleDirector(user.roles) && (
                                            <>
                                                {/* ORGANIZATION */}
                                                <Route
                                                    key='menu-organization-edit-dir'
                                                    path={`/${lang}/organization`}
                                                    element={<OrganizationAddEditComponent hasDirectorRole={true} />}
                                                />
                                            </>
                                        )}

                                        {hasRoleAdminOrDirectorOrManager(user.roles) && (
                                            <>
                                                {/* EQUIPMENT */}
                                                <Route
                                                    key='menu-equipment-bulk-add-outlet'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/outlet/:outletID/equipment/bulk-add`}
                                                    element={<EquipmentBulkAddComponent />}
                                                />
                                                <Route
                                                    key='menu-equipment-add-outlet'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/outlet/:outletID/equipment/add`}
                                                    element={<EquipmentAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-equipment-edit-outlet'
                                                    path={`/${lang}/organization/:organizationID/outlet/:outletID/equipment/:equipmentID`}
                                                    element={<EquipmentAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-equipment-list-outlet'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/outlet/:outletID/equipment`}
                                                    element={<EquipmentListComponent />}
                                                />
                                                <Route
                                                    key='menu-equipment-bulk-add'
                                                    caseSensitive
                                                    path={`/${lang}/equipment/bulk-add`}
                                                    element={<EquipmentBulkAddComponent />}
                                                />
                                                <Route
                                                    key='menu-equipment-add'
                                                    caseSensitive
                                                    path={`/${lang}/equipment/add`}
                                                    element={<EquipmentAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-equipment-edit'
                                                    path={`/${lang}/equipment/:equipmentID`}
                                                    element={<EquipmentAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-equipment-list'
                                                    caseSensitive
                                                    path={`/${lang}/equipment`}
                                                    element={<EquipmentListComponent />}
                                                />

                                                {/* EMPLOYEE */}
                                                <Route
                                                    key='menu-employee-add'
                                                    caseSensitive
                                                    path={`/${lang}/employee/add`}
                                                    element={<EmployeeAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-employee-edit'
                                                    path={`/${lang}/employee/:userID`}
                                                    element={<EmployeeAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-employee-list'
                                                    caseSensitive
                                                    path={`/${lang}/employee`}
                                                    element={<EmployeeListComponent />}
                                                />
                                                <Route
                                                    key='menu-employee-org-add'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/employee/add`}
                                                    element={<EmployeeAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-employee-org-edit'
                                                    path={`/${lang}/organization/:organizationID/employee/:userID`}
                                                    element={<EmployeeAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-employee-org-list'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/employee`}
                                                    element={<EmployeeListComponent />}
                                                />
                                                <Route
                                                    key='menu-employee-out-add'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/outlet/:outletID/employee/add`}
                                                    element={<EmployeeAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-employee-out-edit'
                                                    path={`/${lang}/organization/:organizationID/outlet/:outletID/employee/:userID`}
                                                    element={<EmployeeAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-employee-out-list'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/outlet/:outletID/employee`}
                                                    element={<EmployeeListComponent />}
                                                />

                                                {/* OUTLET */}
                                                <Route
                                                    key='menu-outlet-add'
                                                    caseSensitive
                                                    path={`/${lang}/outlet/add`}
                                                    element={<OutletAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-outlet-edit'
                                                    path={`/${lang}/outlet/:outletID`}
                                                    element={<OutletAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-outlet-list-admin'
                                                    caseSensitive
                                                    path={`/${lang}/outlet`}
                                                    element={<OutletListComponent />}
                                                />
                                                <Route
                                                    key='menu-outlet-list'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/outlet`}
                                                    element={<OutletListComponent />}
                                                />
                                                <Route
                                                    key='menu-outlet-settings'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/outlet/:outletID/settings`}
                                                    element={<OutletSettingsComponent />}
                                                />
                                                <Route
                                                    key='menu-outlet-add'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/outlet/add`}
                                                    element={<OutletAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-outlet-edit'
                                                    path={`/${lang}/organization/:organizationID/outlet/:outletID`}
                                                    element={<OutletAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-outlet-settings'
                                                    caseSensitive
                                                    path={`/${lang}/outlet/:outletID/settings`}
                                                    element={<OutletSettingsComponent />}
                                                />

                                                {/* REQUEST */}
                                                <Route
                                                    key='menu-request-org'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/outlet/:outletID/request`}
                                                    element={<CustomerRequestListComponent />}
                                                />

                                                {/* CUSTOMER */}
                                                <Route
                                                    key='customer'
                                                    caseSensitive
                                                    path={`/${lang}/customer`}
                                                    element={hasRoleAdmin(user.roles) ? <CustomerListComponentAdmin /> : <CustomerListComponent />}
                                                />
                                                <Route
                                                    key='customer-view'
                                                    caseSensitive
                                                    path={`/${lang}/customer/:id`}
                                                    element={<CustomerEmployeeViewComponent />}
                                                />
                                                <Route
                                                    key='customer-org'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/customer`}
                                                    element={hasRoleAdmin(user.roles) ? <CustomerListComponentAdmin /> : <CustomerListComponent />}
                                                />
                                                <Route
                                                    key='customer-view-org'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/customer/:id`}
                                                    element={<CustomerEmployeeViewComponent />}
                                                />
                                                <Route
                                                    key='customer-view-outlet'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/outlet/:outletID/customer/:id`}
                                                    element={<CustomerEmployeeViewComponent />}
                                                />


                                                {/* LOYALTY */}
                                                <Route key="menu-loyalty-category-org" caseSensitive path={`/${lang}/organization/:organizationID/loyalty/category`} element={<LoyaltySettingsTableComponent />} />
                                                <Route key="menu-loyalty-category" caseSensitive path={`/${lang}/loyalty/category`} element={<LoyaltySettingsTableComponent />} />
                                                <Route key="menu-loyalty-category-settings-org" caseSensitive path={`/${lang}/organization/:organizationID/loyalty/category/settings`} element={<LoyaltySettingsComponent />} />
                                                <Route key="menu-loyalty-category-settings" caseSensitive path={`/${lang}/loyalty/category/settings`} element={<LoyaltySettingsComponent />} />
                                                <Route key="menu-loyalty" caseSensitive path={`/${lang}/loyalty`} element={<Navigate to={`/${lang}/loyalty/category`} replace />} />

                                                {/* QR codes */}
                                                {/* <Route key="menu-qrcode-settings-equipment" caseSensitive path={`/${lang}/organization/:organizationID/outlet/:outletID/equipment/:equipmentID/qrcode/settings`} element={<QRcodeSettingsComponent />} />
                                                    <Route key="menu-qrcode-settings-outlet" caseSensitive path={`/${lang}/organization/:organizationID/outlet/:outletID/qrcode/settings`} element={<QRcodeSettingsComponent />} />
                                                    <Route key="menu-qrcode-settings-organization" caseSensitive path={`/${lang}/organization/:organizationID/qrcode/settings`} element={<QRcodeSettingsComponent />} /> */}
                                                {/* <Route key="menu-qrcode-settings" caseSensitive path={`/${lang}/qrcode/settings`} element={<QRcodeSettingsComponent />} /> */}

                                                {/* Printers */}
                                                <Route key="menu-printer-outlet" caseSensitive path={`/${lang}/organization/:organizationID/outlet/:outletID/printer`} element={<PrinterListComponent />} />
                                                <Route key="menu-printer-organization" caseSensitive path={`/${lang}/organization/:organizationID/printer`} element={<PrinterListComponent />} />
                                                <Route key="menu-printer" caseSensitive path={`/${lang}/printer`} element={<PrinterListComponent />} />

                                                {/* POS */}
                                                <Route key="menu-pos-outlet" caseSensitive path={`/${lang}/organization/:organizationID/outlet/:outletID/pos`} element={<PosListComponent />} />
                                                <Route key="menu-pos-organization" caseSensitive path={`/${lang}/organization/:organizationID/pos`} element={<PosListComponent />} />
                                                <Route key="menu-pos" caseSensitive path={`/${lang}/pos`} element={<PosListComponent />} />

                                                {/* ORDERS */}
                                                <Route
                                                    key='menu-order-edit'
                                                    caseSensitive
                                                    path={`/${lang}/order/:id`}
                                                    element={<OrderEditComponent />}
                                                />
                                                <Route
                                                    key='menu-order-add'
                                                    caseSensitive
                                                    path={`/${lang}/order/add`}
                                                    element={<OrderAddComponent />}
                                                />
                                                <Route
                                                    key='menu-order-list'
                                                    caseSensitive
                                                    path={`/${lang}/order`}
                                                    element={<OrderListComponent />}
                                                />

                                                {/* FEEDBACK */}
                                                <Route
                                                    key='menu-feedback-add'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/outlet/:outletID/feedback/add`}
                                                    element={<FeedbackFormAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-feedback-edit'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/outlet/:outletID/feedback/:feedbackID`}
                                                    element={<FeedbackFormAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-feedback-list'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/outlet/feedback`}
                                                    element={<FeedbackListComponent />}
                                                />
                                                <Route
                                                    key='menu-feedback-add'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/feedback/add`}
                                                    element={<FeedbackFormAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-feedback-edit'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/feedback/:feedbackID`}
                                                    element={<FeedbackFormAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-feedback-list'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/outlet/feedback`}
                                                    element={<FeedbackListComponent />}
                                                />
                                                <Route
                                                    key='menu-feedback-add'
                                                    caseSensitive
                                                    path={`/${lang}/feedback/add`}
                                                    element={<FeedbackFormAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-feedback-edit'
                                                    caseSensitive
                                                    path={`/${lang}/feedback/:feedbackID`}
                                                    element={<FeedbackFormAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-feedback-list'
                                                    caseSensitive
                                                    path={`/${lang}/feedback`}
                                                    element={<FeedbackListComponent />}
                                                />

                                                {/* SECTION */}
                                                <Route
                                                    key='menu-feedback-outlet-add'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/outlet/:outletID/section/add`}
                                                    element={<SectionAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-section-outlet-edit'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/outlet/:outletID/section/:sectionID`}
                                                    element={<SectionAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-section-outlet-list'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/outlet/:outletID/section`}
                                                    element={<SectionListComponent />}
                                                />
                                                <Route
                                                    key='menu-section-organization-add'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/section/add`}
                                                    element={<SectionAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-section-organization-edit'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/section/:sectionID`}
                                                    element={<SectionAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-section-organization-list'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/outlet/section`}
                                                    element={<SectionListComponent />}
                                                />
                                                <Route
                                                    key='menu-section-add'
                                                    caseSensitive
                                                    path={`/${lang}/section/add`}
                                                    element={<SectionAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-section-edit'
                                                    caseSensitive
                                                    path={`/${lang}/section/:sectionID`}
                                                    element={<SectionAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-section-list'
                                                    caseSensitive
                                                    path={`/${lang}/section`}
                                                    element={<SectionListComponent />}
                                                />

                                                {/* Analytics */}
                                                <Route
                                                    key='menu-analytics'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/outlet/:outletID/analytics`}
                                                    element={<AnalyticsComponent />}
                                                />
                                                <Route
                                                    key='menu-analytics'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/analytics`}
                                                    element={<AnalyticsComponent />}
                                                />
                                                <Route
                                                    key='menu-analytics'
                                                    caseSensitive
                                                    path={`/${lang}/analytics`}
                                                    element={<AnalyticsComponent />}
                                                />

                                                {/* Floor planing */}
                                                <Route
                                                    key='menu-floor-editor'
                                                    path={`/${lang}/floor-planning`}
                                                    element={<FloorEditorComponent />}
                                                />
                                            </>
                                        )}


                                        {hasRoleMessaging(user.roles) && (
                                            <>
                                                {/* EMAIL TEMPLATE */}
                                                <Route
                                                    key='menu-email-template-org'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/outlet/:outletID/marketing/email/template`}
                                                    element={<EmailTemplateListComponent />}
                                                />
                                                <Route
                                                    key='menu-email-template'
                                                    caseSensitive
                                                    path={`/${lang}/marketing/email/template`}
                                                    element={<EmailTemplateListComponent />}
                                                />

                                                {/* RECIPIENT */}
                                                <Route
                                                    key='menu-recipient-add-org'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/outlet/:outletID/marketing/recipient/add`}
                                                    element={<RecipientAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-recipient-add-org'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/outlet/:outletID/marketing/recipient/:recipientID`}
                                                    element={<RecipientAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-recipient-list-org'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/outlet/:outletID/marketing/recipient`}
                                                    element={<RecipientListComponent />}
                                                />
                                                <Route
                                                    key='menu-recipient-add'
                                                    caseSensitive
                                                    path={`/${lang}/marketing/recipient/add`}
                                                    element={<RecipientAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-recipient-edit'
                                                    path={`/${lang}/marketing/recipient/:recipientID`}
                                                    element={<RecipientAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-recipient-list'
                                                    caseSensitive
                                                    path={`/${lang}/marketing/recipient`}
                                                    element={<RecipientListComponent />}
                                                />

                                                {/* RECIPIENT GROUP*/}
                                                <Route
                                                    key='menu-recipient-add-group-org'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/outlet/:outletID/marketing/recipient/group/add`}
                                                    element={<RecipientGroupAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-recipient-group-add-org'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/outlet/:outletID/marketing/recipient/group/:recipientGroupID`}
                                                    element={<RecipientGroupAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-recipient-group-list-org'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/outlet/:outletID/marketing/recipient/group`}
                                                    element={<RecipientGroupListComponent />}
                                                />
                                                <Route
                                                    key='menu-recipient-group-add'
                                                    caseSensitive
                                                    path={`/${lang}/marketing/recipient/group/add`}
                                                    element={<RecipientGroupAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-recipient-group-edit'
                                                    path={`/${lang}/marketing/recipient/group/:recipientGroupID`}
                                                    element={<RecipientGroupAddEditComponent />}
                                                />
                                                <Route
                                                    key='menu-recipient-group-list'
                                                    caseSensitive
                                                    path={`/${lang}/marketing/recipient/group`}
                                                    element={<RecipientGroupListComponent />}
                                                />

                                                {/* SMS CAMPAIGN */}
                                                <Route
                                                    key='menu-sms-campaign-org'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/outlet/:outletID/marketing/campaign/sms`}
                                                    element={<SmsCampaignListComponent />}
                                                />
                                                <Route
                                                    key='menu-sms-campaign-add-org'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/outlet/:outletID/marketing/campaign/sms/add`}
                                                    element={<SmsCampaignComponent />}
                                                />
                                                <Route
                                                    key='menu-sms-campaign-view-org'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/outlet/:outletID/marketing/campaign/sms/:smsCampaignID`}
                                                    element={<SmsCampaignComponent />}
                                                />
                                                <Route
                                                    key='menu-sms-campaign-add'
                                                    caseSensitive
                                                    path={`/${lang}/marketing/campaign/sms/add`}
                                                    element={<SmsCampaignComponent />}
                                                />
                                                <Route
                                                    key='menu-sms-campaign-view'
                                                    caseSensitive
                                                    path={`/${lang}/marketing/campaign/sms/:smsCampaignID`}
                                                    element={<SmsCampaignComponent />}
                                                />
                                                <Route
                                                    key='menu-sms-campaign'
                                                    caseSensitive
                                                    path={`/${lang}/marketing/campaign/sms`}
                                                    element={<SmsCampaignListComponent />}
                                                />

                                                {/* EMAIL CAMPAIGN */}
                                                <Route
                                                    key='menu-email-campaign-org'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/outlet/:outletID/marketing/campaign/email`}
                                                    element={<EmailCampaignListComponent />}
                                                />
                                                <Route
                                                    key='menu-email-campaign-add-org'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/outlet/:outletID/marketing/campaign/email/add`}
                                                    element={<EmailCampaignAddViewComponent />}
                                                />
                                                <Route
                                                    key='menu-email-campaign-view-org'
                                                    caseSensitive
                                                    path={`/${lang}/organization/:organizationID/outlet/:outletID/marketing/campaign/email/:emailCampaignID`}
                                                    element={<EmailCampaignAddViewComponent />}
                                                />
                                                <Route
                                                    key='menu-email-campaign-add'
                                                    caseSensitive
                                                    path={`/${lang}/marketing/campaign/email/add`}
                                                    element={<EmailCampaignAddViewComponent />}
                                                />
                                                <Route
                                                    key='menu-email-campaign-view'
                                                    caseSensitive
                                                    path={`/${lang}/marketing/campaign/email/:emailCampaignID`}
                                                    element={<EmailCampaignAddViewComponent />}
                                                />
                                                <Route
                                                    key='menu-email-campaign'
                                                    caseSensitive
                                                    path={`/${lang}/marketing/campaign/email`}
                                                    element={<EmailCampaignListComponent />}
                                                />
                                            </>
                                        )}

                                        {hasRoleFloorSupervisor(user.roles) && (
                                            <>
                                                <Route
                                                    key='menu-waitlist'
                                                    path={`/${lang}/waitlist`}
                                                    element={<WaitlistDisplayComponent />}
                                                />
                                            </>
                                        )}

                                        {/* Public Displays */}
                                        <Route key="menu-display-pickup-order-settings" caseSensitive path={`/${lang}/display/pickup/settings`} element={<PickupOrderDisplaySettingsComponent />} />
                                        <Route key="menu-display-pickup-order" caseSensitive path={`/${lang}/display/pickup`} element={<PickupOrderDisplayComponent />} />
                                        <Route key="menu-display-kitchen-settings" caseSensitive path={`/${lang}/display/kitchen/settings`} element={<KitchenDisplaySettingsComponent />} />
                                        <Route key="menu-display-kitchen" caseSensitive path={`/${lang}/display/kitchen`} element={<KitchenDisplayComponent />} />
                                        <Route
                                            key='menu-display-kitchen-past-orders'
                                            caseSensitive
                                            path={`/${lang}/display/kitchen/pastorders`}
                                            element={<KitchenDisplayPastOrders />}
                                        />

                                        {/* USER */}
                                        <Route
                                            key='menu-user-edit'
                                            path={`/${lang}/user/:userID`}
                                            element={<UserAddEditComponent />}
                                        />

                                        {/* HELP */}
                                        <Route
                                            key='menu-help-list'
                                            caseSensitive
                                            path={`/${lang}/help`}
                                            element={<HelpListComponent />}
                                        />

                                        {/* CHAT */}
                                        <Route
                                            key='menu-chat'
                                            caseSensitive
                                            path={`/${lang}/chat`}
                                            element={<ChatComponent />}
                                        />

                                        {/* Dashboard */}
                                        {/* <Route key="menu-dashboard" caseSensitive path={`/${lang}`} element={<CustomFullPageDialog isOpen={true} element={<PickupOrderDisplayComponent />} fontSize={"20px"} id="display"></CustomFullPageDialog>} /> */}
                                        <Route
                                            key='menu-dashboard'
                                            caseSensitive
                                            path={`/${lang}`}
                                            element={<></>}
                                        />

                                        {/* VIVA  */}
                                        <Route key="viva-wallet-fail-payment" caseSensitive path={`/${lang}/vivaWalletFailurePaymentPage`} element={<FailPaymentComponent />} />
                                        <Route key="viva-wallet-success-payment" caseSensitive path={`/${lang}/vivaWalletSuccessPaymentPage`} element={<SuccessPaymentComponent />} />


                                        {/* <Route key="test-lang" caseSensitive path={lang + "/test"} element={<TestComponent />} /> */}
                                    </>
                                )}
                            </>
                        ))}
                    </Routes>
                )}
            </AuthConsumer>
        </React.Fragment>
    );
}

export default AuthRoutes;
