import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import anime from 'animejs';
import { CheckIcon } from 'assets/mui/MuiIcons';
import { useEffect, useRef, useState } from 'react';

function AnimatedButton({ handleClick }) {
    const [loading, setLoading] = useState(false);
    const [completed, setCompleted] = useState(false);
    const buttonRef = useRef(null);
    const checkIconRef = useRef(null);

    useEffect(() => {
        if (loading) {
            const animation = anime.timeline({
                targets: buttonRef.current,
                easing: 'easeInOutQuad',
                complete: () => setCompleted(true),
            });

            animation
                .add({
                    width: [anime.stagger('200px', { from: 'center' }), anime.stagger('100%')],
                    backgroundColor: '#47a6d2',
                    borderRadius: ['0%', '50%'],
                    duration: 1500,
                })
                .add({
                    targets: checkIconRef.current,
                    opacity: [0, 1],
                    scale: [0.5, 1],
                    duration: 3500,
                }, '-=500') // start this animation 500ms before the previous ends
                .add({
                    width: [anime.stagger('100%'), '200px'],
                    backgroundColor: '#47a6d2',
                    borderRadius: ['50%', '5px'],
                    duration: 1500,
                    complete: () => setLoading(false),
                })
                .add({
                    targets: checkIconRef.current,
                    opacity: [1, 0],
                    scale: [1, 0.5],
                    duration: 500,
                }) // start this animation 1000ms after the previous ends
                .add({
                    targets: buttonRef.current,
                    easing: 'easeInOutQuad',
                    complete: () => setCompleted(false),
                }, '+=1000');

            return () => animation.pause();
        }
    }, [loading]);

    const handleClickAminated = () => {
        setLoading(true);
        handleClick();
    };

    return (
        <div style={{ textAlign: 'center', padding: '42px 20px 20px 20px' }}>
            <Button
                variant="contained"
                ref={buttonRef}
                onClick={handleClickAminated}
                disabled={loading}
                endIcon={completed && <CheckIcon ref={checkIconRef} sx={{ color: "white", width: "20px", height: "20px", marginLeft: 0 }} />}
                sx={{
                    backgroundColor: '#47a6d2',
                    width: '200px',
                    height: '50px',
                    transition: 'all 0.3s ease',
                    overflow: 'hidden',
                    borderRadius: "5px"
                }}
            >
                {!completed ? 'Submit' : ''}
            </Button>

            {loading && !completed && (
                <LinearProgress
                    sx={{
                        width: '100%',
                        marginTop: '10px',
                    }}
                />
            )}
        </div>
    );
}

export default AnimatedButton;