import { Check as CheckIcon, Clear as ClearIcon, Delete as DeleteIcon, Settings as SettingsIcon } from "@mui/icons-material";
import {
    Alert, Box, Button, Dialog,
    DialogContent, Grid, IconButton, Slider, Snackbar, TextField, Typography
} from '@mui/material';
import { AddIcon } from 'assets/mui/MuiIcons';
import CustomGrid from 'components/Grid/CustomGrid';
import { useEffect, useRef, useState } from 'react';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { useTranslation } from 'react-i18next';
import { mainTagFloorPlanning } from 'themes/defaultThemes';
import useImage from "use-image";
import useDocumentTitle from "utils/useDocumentTitle";
import { v4 as uuidv4 } from "uuid";
import dataURLtoFile from "utils/asset/dataURLtoFile";
import RenderFloorShape from "components/Grid/floor/RenderFloorShape";
import FloorPlannerDrawer from "components/Drawer/floor/FloorPlannerDrawer";
import { Image, Stage } from "react-konva";
import { Layer } from "recharts";
import { shapeOptions, tablesOptions } from "constants/floor/floorOptions";
import DropFloorItem from "components/Table/draggable/floor/DropFloorItem";
import FloorSectionsToolbar from "components/Grid/floor/FloorSectionsToolbar";
import floorPlanningApi from "api/floor";
import { useParams } from "react-router-dom";
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import FloorPlanningSkeleton from "components/Loading/FloorPlanningSkeleton";
import sectionApi from "api/section";
import CustomSnackbar from "components/Snackbar/CustomSnackbar";
import CustomTranslationsDialog from "components/Dialogs/CustomTranslationsDialog";
import { getSectionDefaultObject, getTranslationDefaultObject } from "constants/defaultObjects";
import { formValChangeWithParentElementObjectValues } from "utils/form-validation";
import FloorSectionsBody from "components/Grid/floor/FloorSectionsBody";

/**
 * The FloorEditorComponent, that presents a floor editor component.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function FloorEditorComponent() {
    const { t } = useTranslation();
    const { organizationID } = useParams();
    const { outletID } = useParams();

    const [showFullScreen, setShowFullScreen] = useState(false);
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    //change document title
    useDocumentTitle(`Usee | ${t('display.floorPlanning.label')}`);

    const [data, setData] = useState({
        userAuth: null,
        organization: {
            id: organizationID,
            name: ""
        },
        outlet: {
            id: outletID,
            name: ""
        },
        sections: []
    });

    const [isLoaded, setIsLoaded] = useState(false);

    // popup dialog for creation
    const [dialogSection, setDialogSection] = useState({
        isOpen: false,
        message: t("label.configureTranslations"),
        isAdd: false,
        id: "",
        field: "",
        translations: {}
    });

    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313"
    });

    const defaultSection = {
        id: "",//uuidv4(),
        index: 0,
        name: "Main Room",
        background: "",
        backgroundImageSize: "",
        objects: [
            // {
            //   id: uuidv4(),
            //   type: "TABLE",
            //    shape: "square",//circle
            //   index: 1,
            //   name: "1",
            //   borderColor: "#4a4c50",
            //   minCover: 1,
            //   maxCover: 20,
            //   availableOnline: true,
            //   canvas:{
            //     rotation: "",
            //     size: {
            //width:0,
            //height:0,
            // },
            //     position: {
            //   x: 0,
            //   y: 0
            // }
            //   }
            // },
        ],
    };
    const [selectedSection, setSelectedSection] = useState(null);
    const [selectedObject, setSelectedObject] = useState(null);


    const sidebarWidth = "30%";

    /**
     * The rest endpoint to get the user default (add) or current (edit) information.
     */
    function getSettings(updateSelectedSection = false) {
        floorPlanningApi.fetchSettings(organizationID, outletID).then((r) => {
            setData({
                ...data,
                userAuth: r.data.returnobject.userAuth,
                organization: r.data.returnobject.organization,
                outlet: r.data.returnobject.outlet,
                sections: r.data.returnobject.sections,
                equipments: r.data.returnobject.equipments,
            });
            updateSelectedSection && setSelectedSection(r.data.returnobject.sections?.length > 0 ? r.data.returnobject.sections[0] : null)
            setIsLoaded(true);
        }).catch((e) => {
            // console.log(e);
        })
    }


    useEffect(() => {
        getSettings(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /**
     * Callback that modified field translations
     * @param {*} sectionID 
     * @param {*} name 
     */
    function nameChangeHandler(sectionID, name) {
        sectionApi.updateName(sectionID, name).then((r) => {
            if (r.data.code === "SUCCESS") {
                setSelectedSection((r) => {
                    return { ...r, name: name };
                });
                setEditSectionNameChip({
                    active: false,
                    name: "",
                });
                setAlertBox({
                    isOpen: true,
                    message: t("display.floorPlanning.updateSectionName.success"),
                    backgroundColor: "#177910"
                });
                getSettings()
            } else {
                setAlertBox({
                    isOpen: true,
                    message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                    backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                });
            }
        }).catch((e) => {
            // console.log(e);
        })
    }


    /**
     * Function to trigger the multilingual field dialog.
     * 
     * @property {string} id of the instance
     * @property {string} field The field of the selected outlet field.
     */
    function configureTranslations(id, field, isAdd = false) {
        handleTranslationsDialogState(true, field, id, isAdd);
    }

    /**
     * 
     * @param {*} dialogSection 
     */
    function configureSectionTranslations(translations, field) {
        let section = getSectionDefaultObject(translations);

        sectionApi.create(section).then((r) => {
            Promise.resolve()
                .then(() => {
                    setAlertBox({
                        isOpen: true,
                        message: "message" in r.data ?
                            r.data.message?.includes("english") ? t("section.emptyEnName")
                                : r.data.message : t("errorPages.somethingWentWrong"),
                        backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                    });
                })
                .then(() => {
                    if (r.data.code === "SUCCESS") {
                        handleTranslationsDialogState(false, "section", null, null);
                        getSettings();
                        setSelectedSection(r.data.returnobject);
                        setSelectedObject(null);
                    }
                })
        }).catch((e) => {
            // console.log(e)
        });
    }


    /**
     * Function that handles the delete modal open or close state.
     * @property {boolean} isOpen If the values is `true`, the dialog should be open and visible.
     * @property {string} field The field of the selected text translations to be removed.
     */
    function handleTranslationsDialogState(isOpen, field = "", id = null, isAdd = false) {
        if (field === "section") {
            setDialogSection({
                ...dialogSection,
                id: id,
                isAdd: isAdd,
                isOpen: isOpen,
                field: field,
                translations: isAdd ? getTranslationDefaultObject() : selectedSection?.name
            });
        }
    }

    /**
     * 
     */
    function addSectionHandler() {
        handleTranslationsDialogState(true, "section", null, true);
        setSelectedObject(null);
        // setSelectedSection(newSection);

        // const newSection = {
        //     id: uuidv4(),
        //     index:
        //         rooms[rooms.length - 1].index > 0
        //             ? rooms[rooms.length - 1].index + 1
        //             : 1,
        //     name: `New Room ${rooms[rooms.length - 1].index > 0
        //         ? rooms[rooms.length - 1].index + 1
        //         : 1
        //         }`,
        //     background: "",
        //     backgroundImageSize: "",
        //     objects: [],

        // };
    }


    const shapeRef = useRef();
    const trRef = useRef();
    const nameRef = useRef(null);
    const nameRefShape = useRef(null);
    const [nameTextWidth, setNameTextWidth] = useState(0);

    const minMaxCover = useRef(null);
    const minMaxRefShape = useRef(null);





    const cropperRef = useRef();
    const layerRef = useRef(null);
    const containerRef = useRef(null);

    const [showToast, setShowToast] = useState({
        active: false,
        message: "",
        severity: "success",
    });
    const [showError, setShowError] = useState({
        active: false,
        message: "",
        action: "",
    });
    const [stage, setStage] = useState({
        scale: 1,
        x: 0,
        y: 0,
    });
    const [dimensions, setDimensions] = useState({
        width: 0,
        height: 0,
    });

    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [rooms, setRooms] = useState([defaultSection]);
    const backgroundCanvas = useImage(selectedSection?.background);

    const [zoom, setZoom] = useState(1);
    const [cropper, setCropper] = useState();
    const [showCropper, setShowCropper] = useState({
        active: false,
        file: null,
        fileName: "",
        type: "",
        orignalFile: null,
    });
    const [deleteDialog, setDeleteDialog] = useState({
        active: false,
        object: null,
    });
    const [deleteSectionDialog, setDeleteSectionDialog] = useState({
        active: false,
        room: null,
    });
    const [editSectionNameChip, setEditSectionNameChip] = useState({
        active: false,
        name: "",
    });


    useEffect(() => {
        if (
            containerRef.current?.offsetHeight &&
            containerRef.current?.offsetWidth
        ) {
            setDimensions({
                width: containerRef.current?.offsetWidth,
                height: containerRef.current?.offsetHeight,
            });
        }
    }, []);

    useEffect(() => {
        function handleResize() {
            setDimensions({
                width: containerRef.current?.offsetWidth,
                height: containerRef.current?.offsetHeight,
            });
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);


    const saveClickHandler = () => {
        console.log(rooms);
    };


    const addTableHandler = (type, shape, offset) => {
        // event.preventDefault();
        // const shape = event.dataTransfer.getData("shape");
        // const type = event.dataTransfer.getData("type");

        //  if (!data) return;
        if (!shape) return;
        if (!type) return;
        const draggedElement = document.getElementById(shape);
        const dropContainer = containerRef.current;
        const dropContainerRect = dropContainer.getBoundingClientRect();
        const droppedElementRect = draggedElement.getBoundingClientRect();

        const maxLeft = 0;
        const maxRight = dropContainerRect.width;
        const maxtop = 0;
        const maxBottom = dropContainerRect.height;

        //const offsetX = event.clientX - dropContainerRect.left;
        //const offsetY = event.clientY - dropContainerRect.top;
        const offsetX = offset.x - dropContainerRect.left;
        const offsetY = offset.y - dropContainerRect.top;

        //console.log(offsetX, droppedElementRect.width / 2);

        let droppedElementPosition = {
            x: offsetX - droppedElementRect.width / 2,
            y: offsetY - droppedElementRect.height / 2,
        };

        if (shape && shape.includes("circle")) {
            droppedElementPosition.x =
                droppedElementPosition.x + droppedElementRect.width / 2;
            droppedElementPosition.y =
                droppedElementPosition.y + droppedElementRect.height / 2;
        }
        if (
            droppedElementPosition.x > maxRight ||
            droppedElementPosition.x < maxLeft ||
            droppedElementPosition.y > maxBottom ||
            droppedElementPosition.y < maxtop
        ) {
            return;
        }

        let roomId = uuidv4();
        setSelectedSection((s) => {
            return {
                ...s,
                objects: [
                    ...s.objects,
                    {
                        id: roomId,
                        type: type,
                        shape: shape,
                        index:
                            selectedSection.objects.length > 0
                                ? selectedSection.objects[selectedSection.objects.length - 1].index +
                                1
                                : 1,
                        name: `${selectedSection.objects.length > 0
                            ? selectedSection.objects[selectedSection.objects.length - 1].index +
                            1
                            : 1
                            }`,
                        borderColor: "#4a4c50",
                        fill: type === "SHAPE" ? "#afc1ec" : "#fff",
                        minCover: 1,
                        maxCover: 20,
                        availableOnline: true,
                        canvas: {
                            rotation: 0,
                            size: {
                                width: draggedElement.offsetWidth,
                                height: draggedElement.offsetHeight,
                            },
                            position: {
                                left: droppedElementPosition.x,
                                top: droppedElementPosition.y,
                            },
                        },
                    },
                ],
            };
        });

        setSelectedObject(roomId);
    };

    const checkDeselect = (e) => {
        // deselect when clicked on empty area
        const clickedOnEmpty = e.target === e.target.getStage();
        if (clickedOnEmpty) {
            setSelectedObject(null);
        }
    };

    const handleWheel = (e) => {
        e.evt.preventDefault();

        const scaleBy = 1.02;
        const stage = e.target.getStage();
        const oldScale = stage.scaleX();
        const mousePointTo = {
            x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
            y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
        };

        const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

        setStage({
            scale: newScale,
            x: (stage.getPointerPosition().x / newScale - mousePointTo.x) * newScale,
            y: (stage.getPointerPosition().y / newScale - mousePointTo.y) * newScale,
        });
    };

    const fileChangeHandler = (e) => {
        const maximumSize = 5 * 1024 * 1024;
        if (!e.target.files && !e.target.files[0]) {
            return;
        }
        let file = e.target.files[0];
        const fn = e.target.files[0].name;
        const type = e.target.files[0].type;

        if (file.size > maximumSize) {
            setShowToast({
                active: true,
                message: "File size cannot be greater than 5mb",
                severity: "error",
            });
            return;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setShowCropper({
                active: true,
                file: reader.result,
                fileName: fn,
                type: type,
                orignalFile: file,
            });
        };
        reader.readAsDataURL(e.target.files[0]);
    };

    const getCropData = async () => {
        if (typeof cropper !== "undefined") {
            let obj = dataURLtoFile(
                cropper.getCroppedCanvas().toDataURL(showCropper.type),
                showCropper.fileName
            );
            // compressing file
            // if (obj.size > 300000) {
            //   let compressObjBlob = await compressAccurately(obj, 300);
            //   let compressObjFile = new File([compressObjBlob], showCropper.fileName, {
            //     type: showCropper.type,
            //   });
            //   obj = compressObjFile;
            // }

            setSelectedSection((d) => {
                return {
                    ...d,
                    background: obj,
                    backgroundImageSize: obj.size,
                    backgroundCanvas: cropper.getCroppedCanvas(),
                };
            });
            setShowCropper({
                active: false,
                file: null,
                fileName: "",
                type: "",
                orignalFile: null,
            });
            setZoom(1);
            setCropper();
        }
    };

    const coverCroper = (
        <Dialog
            maxWidth='lg'
            fullWidth
            open={showCropper.active}
            onClose={() => {
                setShowCropper({
                    active: false,
                    file: null,
                    fileName: "",
                    orignalFile: null,
                });
            }}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogContent>
                {/* <Cropper
                    style={{ height: 390, width: "100%", color: "rgb(18,136,73)" }}
                    aspectRatio={3 / 2} //means 39% height as compare to width
                    guides={false}
                    src={showCropper.file}
                    //   ref={(cropper) => {
                    //     this.cropper = cropper;
                    //   }}
                    ref={cropperRef}
                    viewMode={1}
                    dragMode='move'
                    cropBoxMovable={false}
                    zoomTo={zoom}
                    responsive={true}
                    //this is for reposition stuff
                    // ready={(cro) => {
                    //   if (showCropper.dimensions) {
                    //     cropperRef.current.cropper.setCropBoxData({
                    //       width: showCropper.dimensions.width,
                    //       height: showCropper.dimensions.height,
                    //       top: showCropper.dimensions.cropBoxTop,
                    //       left: showCropper.dimensions.cropBoxLeft,
                    //     });
                    //     cropperRef.current.cropper.setCanvasData({
                    //       top: showCropper.dimensions.top,
                    //       left: showCropper.dimensions.left,
                    //     });
                    //   }
                    // }}
                    onInitialized={(instance) => {
                        setCropper(instance);
                    }}
                /> */}

                <div style={{ marginTop: "1em" }}>
                    <Slider
                        value={zoom}
                        //min={1}
                        max={3}
                        step={0.1}
                        aria-labelledby='Zoom'
                        onChange={(e, zoom) => setZoom(zoom)}
                        sx={{ color: "rgb(18,136,73)" }}
                    />
                </div>

                <div style={{ marginTop: "1em" }}>
                    <Grid container spacing={2} justifyContent='flex-end'>
                        <Grid item>
                            <Button
                                variant='outlined'
                                sx={{
                                    borderColor: "rgb(18,136,73)",
                                    color: "rgb(18,136,73)",
                                    textTransform: "none",
                                }}
                                onClick={() =>
                                    setShowCropper({
                                        active: false,
                                        file: null,
                                        fileName: "",
                                        orignalFile: null,
                                    })
                                }
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant='contained'
                                sx={{
                                    background: "rgb(18,136,73)",
                                    borderColor: "rgb(18,136,73)",
                                    color: "#fff",
                                    textTransform: "none",
                                }}
                                onClick={getCropData}
                            // disabled={
                            //   loading.active && loading.action === "cover"
                            // }
                            >
                                Upload
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
        </Dialog>
    );

    const renderDeleteObjectDialog = (
        <Dialog
            maxWidth='sm'
            fullWidth
            open={deleteDialog.active}
            onClose={() => {
                setDeleteDialog({
                    active: false,
                    object: null,
                });
            }}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogContent>
                <Typography variant='h5' sx={{ color: "#34353A", fontWeight: 700 }}>
                    Delete {deleteDialog.object?.type?.toLowerCase()}{" "}
                    {deleteDialog?.object?.name}
                </Typography>
                <Typography variant='body1' sx={{ mt: "20px", color: "#4a4c50" }}>
                    Are you sure you want to delete this{" "}
                    {deleteDialog.object?.type?.toLowerCase()} ?
                </Typography>
                <Grid
                    container
                    spacing={2}
                    justifyContent='flex-end'
                    sx={{ mt: "28px" }}
                >
                    <Grid item>
                        <Button
                            variant='text'
                            sx={{
                                borderColor: "transparent",
                                fontWeight: 600,
                                color: "#4A4c50",
                            }}
                            onClick={() =>
                                setDeleteDialog({
                                    active: false,
                                    object: null,
                                })
                            }
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant='outlined'
                            sx={{
                                borderColor: "red",
                                color: "red",
                                fontWeight: 600,
                            }}
                            onClick={() => {
                                setSelectedObject(null);
                                setSelectedSection((r) => {
                                    return {
                                        ...r,
                                        objects: r.objects.filter(
                                            (o) => o.id !== deleteDialog.object?.id
                                        ),
                                    };
                                });

                                setDeleteDialog({
                                    active: false,
                                    object: null,
                                });
                            }}
                        // disabled={

                        //   loading.active && loading.action === "cover"
                        // }
                        >
                            Delete
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
    const renderDeleteRoomDialog = (
        <Dialog
            maxWidth='sm'
            fullWidth
            open={deleteSectionDialog.active}
            onClose={() => {
                setDeleteSectionDialog({
                    active: false,
                    room: null,
                });
            }}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogContent>
                <Typography variant='h5' sx={{ color: "#34353A", fontWeight: 700 }}>
                    Delete "{deleteSectionDialog.room?.name}"?{" "}
                </Typography>
                <Typography variant='body1' sx={{ mt: "20px", color: "#4a4c50" }}>
                    Are you sure you want to delete the selected room?
                </Typography>
                <Grid
                    container
                    spacing={2}
                    justifyContent='flex-end'
                    sx={{ mt: "28px" }}
                >
                    <Grid item>
                        <Button
                            variant='text'
                            sx={{
                                borderColor: "transparent",
                                fontWeight: 600,
                                color: "#4A4c50",
                            }}
                            onClick={() =>
                                setDeleteSectionDialog({
                                    active: false,
                                    room: null,
                                })
                            }
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant='outlined'
                            sx={{
                                borderColor: "red",
                                color: "red",
                                fontWeight: 600,
                            }}
                            onClick={() => {
                                setSelectedObject(null);
                                let newRooms = rooms.filter(
                                    (ro) => ro.id !== deleteSectionDialog.room?.id
                                );
                                setRooms(newRooms[0] ? newRooms : [defaultSection]);
                                setSelectedSection(newRooms[0] ? newRooms[0] : defaultSection);
                                setDeleteSectionDialog({
                                    active: false,
                                    room: null,
                                });
                            }}
                        // disabled={
                        //   loading.active && loading.action === "cover"
                        // }
                        >
                            Delete
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );

    const handleToastClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShowToast({
            active: false,
            message: "",
            severity: "success",
        });
    };

    //----snackbar

    /**
     * Function that handles the snackbar open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }
    // ===============================================================================RENDER

    if (!isLoaded) {
        return (//Note: not add Custom Grid around
            <FloorPlanningSkeleton isMobile={isMobile} />
        )
    } else {
        return (
            <CustomGrid role="main" id="floor-planning-settings" sx={mainTagFloorPlanning()}>
                <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
                    <Grid
                        container sx={{ minHeight: "94.1vh", background: "#F4F4F6" }}>
                        {coverCroper}
                        {renderDeleteObjectDialog}
                        {renderDeleteRoomDialog}

                        <Grid item>
                            <FloorPlannerDrawer
                                sidebarWidth={sidebarWidth}
                                selectedSection={selectedSection}
                                setSelectedSection={setSelectedSection}
                                selectedObject={selectedObject}
                                setSelectedObject={setSelectedObject}
                                sidebarOpen={sidebarOpen}
                                nameChangeHandler={nameChangeHandler}
                                fileChangeHandler={fileChangeHandler}
                                setDeleteDialog={setDeleteDialog}
                            />
                        </Grid>

                        <Grid item sx={{ flex: 1, ml: `calc(${sidebarWidth} - 4px)`, }} >
                            <Grid container direction='column' sx={{ height: "100%" }}>
                                {/* for sections  */}
                                <FloorSectionsToolbar
                                    sections={data.sections}
                                    selectedSection={selectedSection}
                                    setSelectedSection={setSelectedSection}
                                    setSelectedObject={setSelectedObject}
                                    addSectionHandler={addSectionHandler}
                                    saveClickHandler={saveClickHandler}
                                    editSectionNameChip={editSectionNameChip}
                                    setEditSectionNameChip={setEditSectionNameChip}
                                    nameChangeHandler={nameChangeHandler}
                                    setDeleteSectionDialog={setDeleteSectionDialog}
                                    onTranslationSelect={configureTranslations}
                                />

                                {/* for floor area  */}
                                <FloorSectionsBody />
                            </Grid>
                        </Grid>

                        <Snackbar
                            anchorOrigin={{ vertical: "top", horizontal: "right" }}
                            open={showToast.active}
                            autoHideDuration={6000}
                            onClose={handleToastClose}
                        >
                            <Alert
                                onClose={handleToastClose}
                                severity={showToast.severity}
                                sx={{ width: "100%" }}
                            >
                                {showToast.active}
                            </Alert>
                        </Snackbar>




                        {dialogSection.isOpen &&
                            <CustomTranslationsDialog
                                isOpen={dialogSection.isOpen}
                                id="translations"
                                title={`${t(dialogSection.isAdd ? 'actions.create' : 'actions.update')} ${t('section.title')}`}
                                actionLabel={t('actions.save')}
                                action={configureSectionTranslations}
                                handleOpen={handleTranslationsDialogState}
                                translations={dialogSection.translations}
                                field={dialogSection.field} />
                        }

                        {alertBox.isOpen &&
                            <CustomSnackbar
                                isOpen={alertBox.isOpen}
                                autoHideDuration={3000}
                                message={alertBox.message}
                                backgroundColor={alertBox.backgroundColor}
                                handleSnackbarOpen={handleSnackbarState} />
                        }
                    </Grid>
                </DndProvider>
            </CustomGrid>
        );
    }
}

export default FloorEditorComponent;