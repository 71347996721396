import { createTheme, ThemeProvider } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { useState } from 'react';

/**
 * A custom Snackbar for the different type of alerts after rest API calls.
 * 
 * @property {boolean} isOpen If `true`, the component is shown.
 * @property {number | null} autoHideDuration  The number of milliseconds to wait before automatically calling the `onClose` function. `onClose` should then set the state of the `open` prop to hide the Snackbar. This behavior is disabled by default with the `null` value.
 * @property {SxProps<Theme>} sx The system prop that allows defining system overrides as well as additional CSS styles.
 * @property {string} message The message to display.
 * @property {string} key When displaying multiple consecutive Snackbars from a parent rendering a single <Snackbar/>, add the key prop to ensure independent treatment of each message. e.g. <Snackbar key={message} />, otherwise, the message may update-in-place and features such as autoHideDuration may be canceled.
 * @property {Partial<SnackbarClasses>} classes Override or extend the styles applied to the component.
 * @property {string} backgroundColor the background color  of the snackbar
 * @property {function} handleSnackbarOpen function that handles the snackbar open state
 * @property {SnackbarOrigin} anchorOrigin The anchor of the `Snackbar`. On smaller screens, the component grows to occupy all the available width, the horizontal alignment is ignored. @default { vertical: "bottom", horizontal: "left" }
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomSnackbar({ isOpen = false, autoHideDuration = 2000, anchorOrigin = { vertical: "bottom", horizontal: "right" }, message = "", backgroundColor = "#177910", sx, classes, key, handleSnackbarOpen, action }) {
    const [open, setOpen] = useState({ isOpen });
    /**
     * Function that handles the visualize modal open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleState(isOpen) {
        setOpen(isOpen);
        handleSnackbarOpen(isOpen);
    }

    function getMuiTheme() {
        return createTheme({
            components: {
                MuiSnackbarContent: {
                    styleOverrides: {
                        root: {
                            color: "#fff",
                            fontSize: 13,
                            fontWeight: 500,
                            borderRadius: "5px",
                            marginTop: "10px",
                            justifyContent: "center",
                            backgroundColor: `${backgroundColor}!important`
                        }
                    }
                },
                MuiSnackbar: {
                    styleOverrides: {
                        root: {
                            right: "5%!important",
                            left: "5%!important",
                            zIndex: "1000000000"
                        }
                    }
                }
            }
        })
    }

    return (
        <ThemeProvider theme={getMuiTheme()}>
            <Snackbar
                open={isOpen}
                autoHideDuration={autoHideDuration}
                onClose={() => { handleState(false) }}
                anchorOrigin={anchorOrigin}
                classes={classes}
                action={action}
                message={message}>
            </Snackbar>
        </ThemeProvider>
    );
}

export default CustomSnackbar;