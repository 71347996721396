import { request } from 'constants/alias';
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from 'utils/functions';
import updateFieldIfValid from 'utils/functions/updateFieldIfValid';

/**
 * The rest request which attempts to get an existing organization information.
 * @param {*} organizationID The id of the organization to be fetched
 * @param {*} outletID The id of the outlet to be fetched
 * 
 * @returns an object with an attribute containing the current infromation of the organization
 */
export async function fetchOne(search) {
    let data = {};
    updateFieldIfValid(data, "organizationID", search.organizationID);
    updateFieldIfValid(data, "outletID", search.outletID);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}loyalty/configuration`, data));
    else return await request.get(includeParamsToUrl(`${API}loyalty/configuration`));
}

/**
 * The rest request which attempts to update an existing loyalty category.
 * @param {*} data A JSON object which contains the updated infromation of the loyalty category
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function update(data) {
    return await request.put(`${API}loyalty/configuration`, data);
}

const loyaltyConfigurationApi = {
    fetchOne,
    update
}

export default loyaltyConfigurationApi;