
import EditIcon from '@mui/icons-material/Edit';
import { Button, Tooltip } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getThemeColor } from 'constants/theme';
import { useTranslation } from 'react-i18next';
import { getTheme } from "utils/theme";

/**
 * The Custom Edit Button, that display an edit button for table actions section.
 * @property {string} link the button href
 * @property {string} label the button text value
 * @property {boolean} shownLabel if the label should be shown
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomEditButton({ link, label, shownLabel = true, sx, onClick }) {
    const { t } = useTranslation();

    function getMuiTheme() {
        return createTheme({
            components: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            backgroundColor: getThemeColor()[getTheme()]["editButtonBackgroundColor"],
                            margin: "0px",
                            ...sx
                        },
                        ":hover": {
                            backgroundColor: `${getThemeColor()[getTheme()]["editButtonBackgroundColorHover"]} !important`,
                        },
                        startIcon: {
                            margin: "0px",
                            padding: "0px"
                        }
                    },
                    ":hover": {
                        backgroundColor: `${getThemeColor()[getTheme()]["editButtonBackgroundColorHover"]} !important`,
                    },
                }
            }
        })
    }

    return (
        <ThemeProvider theme={getMuiTheme()}>
            <Tooltip arrow title={t('actions.edit')}>
                <Button variant="contained" startIcon={<EditIcon />} href={link} onClick={onClick}>{shownLabel ? label : ""}</Button>
            </Tooltip>
        </ThemeProvider>
    );
}

export default CustomEditButton;