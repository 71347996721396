import {
    CancelOutlined,
    EmailOutlined,
    RestartAlt,
    Save as SaveIcon,
    SmsOutlined
} from '@mui/icons-material';
import { Box, Button, ButtonGroup, Fab, FormControlLabel, Grid, InputLabel, Switch } from "@mui/material";
import { MuiConfig } from "@react-awesome-query-builder/mui";
import { Utils as QbUtils } from "@react-awesome-query-builder/ui";
import recipientApi from 'api/recipient';
import recipientGroupApi from "api/recipientGroup";
import CustomAddEditBreadcrumbMultiParent from 'components/Breadcrumb/CustomAddEditBreadcrumbMultiParent';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomGrid from 'components/Grid/CustomGrid';
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import DemoQueryBuilder from 'components/Query/DemoQueryBuilder';
import CustomSnackbar from "components/Snackbar/CustomSnackbar";
import CustomListTable from 'components/Table/CustomListTable';
import CustomTextField from 'components/Text/CustomTextField';
import CustomTitleBoldSubtitle from "components/Title/CustomTitleBoldSubtitle";
import { getDefaultRecipientsGroupObject } from 'constants/defaultObjects';
import { getDefaultRecipientForGroupSortDirection } from 'constants/sortDirection';
import { recipient_table_columns } from 'constants/table/recipientTable';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import 'react-phone-input-2/lib/material.css';
import { useNavigate, useParams } from "react-router-dom";
import { fabStyle, formSaveButton, mainArea, mainTag, mainTagBreadcrumb, mainTagTitle, textFieldError } from 'themes/defaultThemes';
import { hasRoleAdmin, hasRoleAdminOrDirectorOrManager } from "utils/auth";
import { formIsValid, formValChangeWithParentElement, formValChangeWithParentElementWithNameAndValue, onControlChange } from "utils/form-validation";
import { getLanguageFromURL } from "utils/language";
import useDocumentTitle from "utils/useDocumentTitle";

const InitialConfig = MuiConfig;

const settings = {
    ...InitialConfig.settings,
    showNot: false,//Show NOT together with AND/OR?
    showLock: false,//Show "Lock" switch for rules and groups to make them read-only ("admin mode")
    showErrorMessage: true,//how error message in QueryBuilder if validateValue() in field config returns false
    canLeaveEmptyGroup: true,// leave empty group after deletion of rules
    canLeaveEmptyCase: true,//automatically remove empty case
    shouldCreateEmptyGroup: false,//automatically add 1 empty rule into new group
};
/* eslint-disable no-unused-vars */
function transformToCaseInsensitive(query) {
    if (!query) return query;

    const traverse = (obj) => {
        if (typeof obj !== "object" || obj === null) return obj;

        // Handle $eq (convert to case-insensitive regex)
        if (obj["$eq"] && typeof obj["$eq"] === "string") {
            return { $regex: obj["$eq"], $options: "i" };
        }

        // Handle $regex (add case-insensitive flag)
        if (obj["$regex"] && typeof obj["$regex"] === "string") {
            return { $regex: obj["$regex"], $options: "i" }; // Ensure $options includes 'i'
        }

        // Recurse for nested objects or arrays
        if (Array.isArray(obj)) {
            return obj.map(traverse);
        }

        return Object.fromEntries(
            Object.entries(obj).map(([key, value]) => [key, traverse(value)])
        );
    };

    return traverse(query);
}
/* eslint-enable no-unused-vars */

const config1 = {
    ...InitialConfig,
    fields: {
        firstName: {
            label: "First Name",
            type: "text",
            operators: ["is_empty", "is_not_empty", "equal", "not_equal", "like", "not_like"]
        },
        lastName: {
            label: "Last Name",
            type: "text",
            operators: ["is_empty", "is_not_empty", "equal", "not_equal", "like", "not_like"]
        },
        // fatherName: {
        //     label: "Father Name",
        //     type: "text",
        //     operators: ["is_empty", "is_not_empty", "equal", "not_equal", "like", "not_like"]
        // },
        phone: {
            label: "Phone",
            type: "text",
            operators: ["is_empty", "is_not_empty", "equal", "not_equal", "like", "not_like"]
        },
        email: {
            label: "Email",
            type: "text",
            operators: ["is_empty", "is_not_empty", "equal", "not_equal", "like", "not_like"]
        },
        // lastActivity: {
        //     label: "Last Activity",
        //     type: "datetime",
        //     valueFormat: "YYYY-MM-DD HH:mm:ss",
        //     operators: ["greater", "less", "between"]
        // },
        // dateCreated: {
        //     label: "Date Created",
        //     type: "datetime",
        //     valueFormat: "YYYY-MM-DD HH:mm:ss",
        //     operators: ["greater", "less", "between"]
        // },
    },
    settings: settings,
};

const fields = {
    firstName: {
        label: "First Name",
        type: "text",
        operators: ["is_empty", "is_not_empty", "equal", "not_equal", "like", "not_like"]
    },
    lastName: {
        label: "Last Name",
        type: "text",
        operators: ["is_empty", "is_not_empty", "equal", "not_equal", "like", "not_like"]
    },
    // fatherName: {
    //     label: "Father Name",
    //     type: "text",
    //     operators: ["is_empty", "is_not_empty", "equal", "not_equal", "like", "not_like"]
    // },
    phone: {
        label: "Phone",
        type: "text",
        operators: ["is_empty", "is_not_empty", "equal", "not_equal", "like", "not_like"]
    },
    email: {
        label: "Email",
        type: "text",
        operators: ["is_empty", "is_not_empty", "equal", "not_equal", "like", "not_like"]
    },
    // lastActivity: {
    //     label: "Last Activity",
    //     type: "datetime",
    //     valueFormat: "YYYY-MM-DD HH:mm:ss",
    //     operators: ["greater", "less", "between"]
    // },
    // dateCreated: {
    //     label: "Date Created",
    //     type: "datetime",
    //     valueFormat: "YYYY-MM-DD HH:mm:ss",
    //     operators: ["greater", "less", "between"]
    // },
};
const queryValue = { id: QbUtils.uuid(), type: "group" };

/**
 * The RecipientGroupAddEditComponent that triggers the creation of a 
 * new recipient grop or the modification of an existing one.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function RecipientGroupAddEditComponent() {
    const { t } = useTranslation();
    const { organizationID } = useParams();
    const { outletID } = useParams();
    const { recipientGroupID } = useParams();
    const navigate = useNavigate();

    let path = `/${getLanguageFromURL()}`
    if (organizationID !== undefined) path += `/organization/${organizationID}`;
    if (outletID !== undefined) path += `/outlet/${outletID}`;
    path += `/marketing/recipient/group`;

    // to render DOM based on rest api call status, if true the rest api call completed
    const [redirect, setRedirect] = useState(false);
    // if true the menu item is clicked and a redirect to page should perform
    const [isLoaded, setIsLoaded] = useState(false);

    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313"
    });

    /**
     * @type {object}
     * @property {boolean} isAdd to define if requested an edit or an add of a user group
     * @property {boolean} redirect if a redirect should be performed
     * @property {string} error holdes the error message of a failed rest api call
     * @property {object} userAuth the authenticated user infromation
     * @property {string} recipientGroupID tha recipient group id to get recipient current information for edit recipient page
     * @property {object} recipient an empty object if add or the selected recipient to be editted
     * @property {*} icon the legend fa icon
     * @property {array} roles the different roles a recipient group can have
     * @property {array} countries a list of the different countries
     * @property {object} isError list of indication of empty form required field 
     *                           after submit to present error message to the
     *                           fields/attributes with true value
     */
    const [data, setData] = useState({
        error: null,
        userAuth: null,
        isAdd: recipientGroupID === undefined,
        recipientGroupID: recipientGroupID,
        recipientsGroup: null,
        organization: {
            id: organizationID,
            name: ""
        },
        outlet: {
            id: outletID,
            name: ""
        },
        recipients: [],
        outlets: [],
        // error messages per field
        isError: {
            name: "",
        }
    });

    //change document title
    useDocumentTitle(`Usee  ${organizationID !== undefined ? " | " + t('org.pageTitle') : ""} ${outletID !== undefined ? " | " + t('outlet.pageTitle') : ""} | ${t('page.users')}`);

    useEffect(() => {
        getRecipientGroup();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * The rest endpoint to get the recipientsGroup default (add) or current (edit) information.
     */
    function getRecipientGroup() {
        if (data.isAdd) {
            recipientGroupApi.fetchNew(organizationID, outletID).then((r) => {
                Promise.resolve()
                    .then(() => {
                        setData({
                            ...data,
                            userAuth: r.data.returnobject.userAuth,
                            recipientsGroup: {
                                organizationID: organizationID,
                                outletID: outletID === undefined ? undefined : outletID,
                                ...getDefaultRecipientsGroupObject()
                            },
                            outlets: r.data.returnobject.outlets,
                            organization: r.data.returnobject.organization,
                            outlet: r.data.returnobject.outlet,
                        });
                    })
                    .then(() => {
                        setIsLoaded(true);
                    })
                    .then(() => {
                        // fetchRecipientsData();
                    })
            }).catch((e) => {
                // console.log(e);
            })
        } else {
            recipientGroupApi.fetchOne(organizationID, outletID, recipientGroupID).then((r) => {
                Promise.resolve()
                    .then(() => {
                        setData({
                            ...data,
                            userAuth: r.data.returnobject.userAuth,
                            recipientsGroup: r.data.returnobject.recipientsGroup,
                            outlets: r.data.returnobject.outlets,
                            organization: r.data.returnobject.organization,
                            outlet: r.data.returnobject.outlet,
                        });
                    })
                    .then(() => {
                        setQueryTree(QbUtils.loadTree(r.data.returnobject.recipientsGroup?.queryTree))
                    })
                    .then(() => {
                        setIsLoaded(true);
                    })
            }).catch((e) => {
                // console.log(e);
            })
        }
    }

    /**
     * Gets called when the recipientsGroup clicks on the save button, and triggers 
     * the creation of the new recipientsGroup.
     */
    function addRecipientGroup() {
        if (formValidation()) {
            let dataTmp = data.recipientsGroup;
            dataTmp.queryTree = queryTree;
            dataTmp.recipientIDs = dataTmp.autoUpdate ? null : data.recipients.map((recipient) => recipient.id);
            dataTmp.query = QbUtils.mongodbFormat(queryTree, config);

            recipientGroupApi.create(dataTmp).then((r) => {
                Promise.resolve()
                    .then(() => {
                        setAlertBox({
                            isOpen: true,
                            message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                            backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                        });
                    })
                    .then(() => {
                        if (r.data.code === "SUCCESS")
                            setRedirect(true);
                    })
            }).catch((e) => {
                // console.log(e)
            });
        }
    }

    /**
     * Gets called when the recipientsGroup clicks on the save button, and triggers 
     * the edit of the selected recipientsGroup.
     */
    function editRecipient() {
        if (formValidation()) {
            let dataTmp = data.recipientsGroup;
            dataTmp.queryTree = queryTree;
            dataTmp.recipientIDs = dataTmp.autoUpdate ? null : data.recipients.map((recipient) => recipient.id);
            dataTmp.query = QbUtils.mongodbFormat(queryTree, config);

            recipientGroupApi.update(dataTmp).then((r) => {
                setAlertBox({
                    isOpen: true,
                    message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                    backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                });

                setRedirect((hasRoleAdmin(data.userAuth.roles) && r.data.code === "SUCCESS" ? true : false));
            }).catch((e) => {
                // console.log(e)
            });
        }
    }

    /**
     * Function that triggers form validation and print out if the form is valid or not.
     * @returns true if form is Valid
     */
    function formValidation() {
        let isError = createErrorMessages();
        if (formIsValid(isError)) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Function that create error messages for each required field that are not filled out.
     * @returns object containing the error messages for each form field
     */
    function createErrorMessages() {
        let isError = { ...data.isError };
        isError.name = data.recipientsGroup.name?.length < 1 ? t('table.valueReuired') : "";

        setData({
            ...data,
            isError
        });
        return isError;
    }

    /**
     * Function that handles the snackbar open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }

    const [id] = useState(useParams().id);
    const [queryTree, setQueryTree] = useState(QbUtils.loadTree(queryValue));
    const [isValidTree, setIsValidTree] = useState(id === null ? false : true);
    const [config, setConfig] = useState(config1);

    useEffect(() => {
        if (isValidTree) {
            fetchRecipientsData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryTree]);

    const fetchRecipientsData = async () => {
        const mongodbQuery = JSON.stringify(QbUtils.mongodbFormat(queryTree, config))
        recipientApi.fetchQuery(mongodbQuery).then((r) => {
            if (r.data.code === "SUCCESS")
                setData({
                    ...data,
                    recipients: r.data.returnobject
                })
        }).catch((e) => {
            // console.log(e)
        });
    };

    const handleQueryBuilderChange = (immutableTree, isValidTree) => {
        setQueryTree(immutableTree);
        setIsValidTree(isValidTree)
        setConfig(config);
        formValChangeWithParentElementWithNameAndValue("recipientsGroup.query", JSON.stringify(QbUtils.mongodbFormat(immutableTree, config)), data, setData);
    };

    // ===============================================================================RENDER

    if (redirect) {
        navigate(`${path}`);
    } else if ((!isLoaded && !data.isAdd) || data.recipientsGroup == null) {
        return (
            <LoadingSkeleton lines={9} />
        );
    } else {
        const { isError } = data;
        return (
            <CustomGrid role="main" id={"recipient-" + data.isAdd ? "add" : "edit"} sx={mainTag()}>
                {(outletID !== undefined && organizationID !== undefined && hasRoleAdminOrDirectorOrManager(data.userAuth?.roles)) &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                                { name: t('outlet.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet` },
                                { name: data.outlet.name, url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet/${outletID}` },
                                { name: t('marketing.recipient.group.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet/${outletID}/marketing/recipient` }
                            ]}
                            instanceName={(data.recipientsGroup !== null) ? data.recipientsGroup.firstName : ""}
                        />
                    </CustomGrid>
                }

                {(outletID === undefined && organizationID !== undefined && hasRoleAdminOrDirectorOrManager(data.userAuth?.roles)) &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                                { name: t('marketing.recipient.group.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/marketing/recipient` }
                            ]}
                            instanceName={(data.recipientsGroup !== null) ? data.recipientsGroup.firstName : ""}
                        />
                    </CustomGrid>
                }

                {(outletID === undefined && organizationID === undefined && hasRoleAdminOrDirectorOrManager(data.userAuth?.roles)) &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('marketing.recipient.group.pageTitle'), url: path }
                            ]}
                            instanceName={(data.recipientsGroup !== null) ? data.recipientsGroup.firstName : ""}
                        />
                    </CustomGrid>
                }

                <CustomGrid sx={mainTagTitle()}>
                    <CustomTitleBoldSubtitle
                        title={`${t('marketing.recipient.group.pageTitle')} | ${data.isAdd ? t('actions.add') : t('actions.edit')}`}
                        subtitleBeforeText={data.isAdd ? t('actions.tableCreateNewLink') + t('marketing.recipient.group.pageTitle') : t('marketing.recipient.group.pageTitle') + " "}
                        subtitleboldText={!data.isAdd && data.recipientsGroup !== null && data.recipientsGroup !== undefined && data.recipientsGroup.firstName}
                        subtitleAfterText={!data.isAdd ? " profile page" : ""}
                    />
                </CustomGrid>

                <CustomGrid sx={mainArea()} container={false}>
                    <form id="recipient" onSubmit={(e) => { return false; }}>

                        {data.isAdd &&
                            <input type="hidden" />
                        }

                        {!data.isAdd &&
                            <input type="hidden" name="id" id="id" defaultValue={data.recipientGroupID} />
                        }

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={4}>
                                <InputLabel required={true} htmlFor="name">{t('label.name')}</InputLabel>
                                <CustomTextField
                                    type="text"
                                    name="recipientsGroup.name"
                                    id="name"
                                    required={true}
                                    error={isError.name.length > 0 ? true : false}
                                    defaultValue={data.recipientsGroup.name}
                                    onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                    helperText={isError.name}
                                    sx={textFieldError()}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} lg={4.5}>
                                <InputLabel required={true} htmlFor="name">{t('marketing.recipient.group.userGroupType.ALL')}</InputLabel>
                                <ButtonGroup variant="outlined">
                                    <CustomIconButton
                                        variant="outlined"
                                        label={t('marketing.recipient.group.userGroupType.ALL')}
                                        isFocused={data.recipientsGroup.userGroupType === "ALL"}
                                        onClick={() => formValChangeWithParentElementWithNameAndValue("recipientsGroup.userGroupType", "ALL", data, setData)}
                                        backgroundColor={data.recipientsGroup.userGroupType === "ALL" ? "#47a6d2" : "initial"}
                                        sx={{ height: "55px", color: data.recipientsGroup.userGroupType === "ALL" ? "white" : "#47a6d2", textTransform: "initial", fontSize: "13px", fontFamily: "auto", letterSpacing: "2px" }}
                                    />
                                    <CustomIconButton
                                        variant="outlined"
                                        tooltipTitle={t('marketing.recipient.group.userGroupType.EMAIL')}
                                        icon={<EmailOutlined />}
                                        isFocused={data.recipientsGroup.userGroupType === "EMAIL"}
                                        onClick={() => formValChangeWithParentElementWithNameAndValue("recipientsGroup.userGroupType", "EMAIL", data, setData)}
                                        backgroundColor={data.recipientsGroup.userGroupType === "EMAIL" ? "#47a6d2" : "initial"}
                                        sx={{ height: "55px", color: data.recipientsGroup.userGroupType === "EMAIL" ? "white" : "#47a6d2", textTransform: "initial", fontSize: "13px", fontFamily: "auto", letterSpacing: "2px" }}
                                    />
                                    <CustomIconButton
                                        variant="outlined"
                                        tooltipTitle={t('marketing.recipient.group.userGroupType.SMS')}
                                        icon={<SmsOutlined />}
                                        isFocused={data.recipientsGroup.userGroupType === "SMS"}
                                        onClick={() => formValChangeWithParentElementWithNameAndValue("recipientsGroup.userGroupType", "SMS", data, setData)}
                                        backgroundColor={data.recipientsGroup.userGroupType === "SMS" ? "#47a6d2" : "initial"}
                                        sx={{ height: "55px", color: data.recipientsGroup.userGroupType === "SMS" ? "white" : "#47a6d2", textTransform: "initial", fontSize: "13px", fontFamily: "auto", letterSpacing: "2px" }}
                                    />
                                    <CustomIconButton
                                        variant="outlined"
                                        tooltipTitle={t('marketing.recipient.group.userGroupType.NONE')}
                                        icon={<CancelOutlined />}
                                        isFocused={data.recipientsGroup.userGroupType === "NONE"}
                                        onClick={() => formValChangeWithParentElementWithNameAndValue("recipientsGroup.userGroupType", "NONE", data, setData)}
                                        backgroundColor={data.recipientsGroup.userGroupType === "NONE" ? "#47a6d2" : "initial"}
                                        sx={{ height: "55px", color: data.recipientsGroup.userGroupType === "NONE" ? "white" : "#47a6d2", textTransform: "initial", fontSize: "13px", fontFamily: "auto", letterSpacing: "2px" }}
                                    />
                                </ButtonGroup>
                            </Grid>

                            <Grid item xs={12} sm={12} lg={3.5} sx={{ textAlignLast: "right" }}>
                                <FormControlLabel
                                    control={<Switch
                                        defaultChecked={data.recipientsGroup.autoUpdate}
                                        onChange={(event) => onControlChange(event, data, setData, "recipientsGroup", "autoUpdate")}
                                    />}
                                    label={t('marketing.recipient.group.autoUpdate')}
                                />
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <Box sx={{ padding: "10px", border: "1px solid #ccc", borderRadius: "4px" }}>
                                    <DemoQueryBuilder
                                        fields={fields}
                                        queryTree={queryTree}
                                        handleQueryBuilderChange={handleQueryBuilderChange}
                                    />
                                    <Button
                                        startIcon={<RestartAlt />}
                                        variant="contained"
                                        onClick={() => setQueryTree(QbUtils.loadTree({ id: QbUtils.uuid(), type: "group" }))}
                                        sx={{ textTransform: "none", ml: "15px", backgroundColor: "#cf5757" }}
                                    >
                                        {t('marketing.campaign.reset')}
                                    </Button>
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={12} sx={{ mt: "20px" }}>
                                <CustomListTable
                                    instances={data.recipients}
                                    emptyMessage={t('marketing.recipient.empty')}
                                    table_columns={recipient_table_columns(data, t)}
                                    keyField="id"
                                    defaultSorted={getDefaultRecipientForGroupSortDirection}
                                    filter={true}
                                />
                            </Grid>
                        </Grid>


                        <Grid container spacing={2}>
                            {/* <Grid item>
                        <CustomIconButton
                           variant="outlined"
                           keyValue="save"
                           onClick={data.isAdd ? addRecipientGroup : editRecipient}
                           label={t('actions.save')}
                           sx={{ ...formSaveButton(), marginLeft: "20px" }}
                        />
                     </Grid> */}

                            {(data.isAdd && data.userAuth.id !== recipientGroupID) &&
                                <Grid item>
                                    <CustomIconButton
                                        variant="outlined"
                                        keyValue="cancel"
                                        link={path}
                                        label={t('actions.back')}
                                        sx={formSaveButton()}
                                        color="error"
                                    />
                                </Grid>
                            }

                            {(!data.isAdd && data.userAuth.id !== recipientGroupID) &&
                                <Grid item>
                                    <CustomIconButton
                                        variant="outlined"
                                        keyValue="cancel"
                                        onClick={getRecipientGroup}
                                        label={t('actions.cancel')}
                                        sx={formSaveButton()}
                                        color="error"
                                    />
                                </Grid>
                            }
                        </Grid>
                    </form>

                    {data.recipientsGroup.name?.length > 0 &&
                        <Fab color="primary"
                            aria-label="add"
                            sx={fabStyle()}
                            onClick={data.isAdd ? addRecipientGroup : editRecipient}
                        >
                            <SaveIcon />
                        </Fab>
                    }
                </CustomGrid>

                {alertBox.isOpen &&
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={3000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        handleSnackbarOpen={handleSnackbarState} />
                }
            </CustomGrid>
        );
    }
}

export default RecipientGroupAddEditComponent;