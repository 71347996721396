
import { BottomNavigation, BottomNavigationAction, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

/**
 * The Custom page footer.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function CustomFooter() {

    const { t } = useTranslation();
    return (
        <BottomNavigation
            showLabels={true}
            sx={{
                background: "transparent",
                alignItems: "center"
            }}
        >
            {/* <div className="copyright">&copy; <span >{new Date().getFullYear()}</span> Usee | </div> */}
            <BottomNavigationAction label={`${t('aboutLabel')}`} href={t('aboutURLPath')} sx={{ height: "fit-content", padding: 0 }} />
            <Divider orientation="vertical" />
            <BottomNavigationAction label={`${t('termsOfUseLabel')}`} href={t('aboutURLPath') + t('termsOfUseURLPath')} sx={{ height: "fit-content", padding: 0 }} />
            <Divider orientation="vertical" />
            <BottomNavigationAction label={`${t('privacyPolicyLabel')}`} href={t('aboutURLPath') + t('privacyPolicyURLPath')} sx={{ height: "fit-content", padding: 0 }} />
        </BottomNavigation>
    );
}

export default CustomFooter;