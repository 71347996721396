import { request } from 'constants/alias';
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from 'utils/functions';
import updateFieldIfBooleanValid from 'utils/functions/updateFieldIfBooleanValid';
import updateFieldIfValidList from 'utils/functions/updateFieldIfNonEmptyArray';
import updateFieldIfValid from 'utils/functions/updateFieldIfValid';

/**
 * The rest request which attempts to get the items list from the database.
 * @param {object} pageable includes all pageable details (page, size, sort, direction)
 * @param {*} search The filters for search.
 * 
 * @returns an object with an attribute containing the items list
 */
export async function fetchAll(pageable, search) {
    let data = {};
    if (search.isModifier !== "All") {
        updateFieldIfBooleanValid(data, "isModifier", search.isModifier);
    }
    updateFieldIfValid(data, "organizationID", search.organizationID);
    updateFieldIfValid(data, "name", search.name, "en");
    updateFieldIfValid(data, "itemVatType", search.itemVatType);
    updateFieldIfValid(data, "provider", search.provider);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}item`, pageable !== undefined ? { ...pageable, ...data } : data));
    else return await request.get(includeParamsToUrl(`${API}item`, pageable !== undefined ? pageable : {}));
}

/**
 * The rest request which attempts to get the items list for CMS from the database.
 * @param {*} search The filters for search.
 * 
 * @returns an object with an attribute containing the items list
 */
export async function fetchAllCMS(organizationID, isModifier) {
    let data = {};
    updateFieldIfValid(data, "organizationID", organizationID);
    updateFieldIfBooleanValid(data, "isModifier", isModifier);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}item`, data));
    else return await request.get(includeParamsToUrl(`${API}item`, {}));
}

/**
 * The rest request which attempts to get the equipments list from the database.
 * @param {*} orgID The id of the item/modifier organization to be fetched
 * @param {object} pageable includes all pageable details (page, size, sort, direction)
 * 
 * @returns an object with an attribute containing the outlets list
 */
export async function fetchAllByOrganizationID(orgID, pageable) {
    let config = {
        baseURL: process.env.REACT_APP_AXIOSBASE,
        params: pageable !== undefined ? pageable : {}
    }

    return await request.get(`${API}organization/${orgID}/item`, config);
}

/**
 * The rest request which return required information to be presented on add new item page.
 * @param {*} organizationID The id of the equipment organization id
 * @returns an object with an attribute containing the requested information to be presented on the item page
 */
export async function fetchNew(organizationID) {
    let data = {};
    updateFieldIfValid(data, "organizationID", organizationID);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}item/add`, data));
    else return await request.get(includeParamsToUrl(`${API}item/add`));
}

/**
 * The rest request which attempts to get an existing item information.
 * @param {*} organizationID The id of the equipment organization id
 * @param {*} itemID The id of the item to be fetched
 * 
 * @returns an object with an attribute containing the current infromation of the item
 */
export async function fetchOne(organizationID, itemID) {
    let data = {};
    updateFieldIfValid(data, "organizationID", organizationID);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}item/${itemID}`, data));
    else return await request.get(includeParamsToUrl(`${API}item/${itemID}`));
}

/**
 * The rest request which attempts to create a new item.
 * @param {*} data A JSON object which contains new item infromation
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function create(data) {
    return await request.post(`${API}item`, data);
}

/**
 * The rest request which attempts to update an existing item.
 * @param {*} data A JSON object which contains the updated infromation of the item
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function update(data) {
    return await request.put(`${API}item`, data);
}

/**
 * The rest request which attempts to enable an existing item.
 * @param {*} itemID The id of the item to be enabled
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function enabled(itemID) {
    return await request.post(`${API}item/${itemID}/enabled`);
}

/**
 * The rest request which attempts to disable an existing item.
 * @param {*} itemID The id of the item to be disabled
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function disable(itemID) {
    return await request.post(`${API}item/${itemID}/disable`);
}

/**
 * The rest request to delete a specific item.
 * @param {*} id The id of the item to be deleted
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function deleteById(id) {
    return await request.delete(`${API}item/${id}`);
}

/**
 * The rest request which attempts to get the items list for attach dialog from the database.
 * @param {*} search The filters for search.
 * 
 * @returns an object with an attribute containing the items/modifiers list
 */
export async function fetchListDialog(organizationID, isModifier, itemCategories) {
    let data = {};
    updateFieldIfValid(data, "organizationID", organizationID);
    updateFieldIfBooleanValid(data, "isModifier", isModifier);
    updateFieldIfValidList(data, "itemCategories", itemCategories);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}item/list`, data));
    else return await request.get(includeParamsToUrl(`${API}item/list`, {}));
}

/**
 * The rest request which attempts to get the items list to be exported as json.
 * 
 * @returns an object with an attribute containing the items list in json format
 */
export async function exportJson(organizationID, iids) {
    let data = {
        organizationID: organizationID,
        iids: iids
    };

    return await request.post(`${API}item/export/json`, data);
}

/**
 * The rest request which attempts to get the items list to be imported from json.
 * 
 * @returns an object with an attribute containing the items list in json format
 */
export async function importJson(organizationID, items) {
    let data = {
        organizationID: organizationID,
        items: items
    };

    return await request.post(`${API}item/import/json`, data);
}


/**
 * The rest request which attempts to get the items list to be imported from json.
 * 
 * @returns an object with an attribute containing the items list in json format
 */
export async function importCsv(organizationID, items) {
    let data = {
        organizationID: organizationID,
        items: items
    };

    return await request.post(`${API}item/import/csv`, data);
}

/**
 * The rest request which attempts to get the items list for CMS from the database.
 * @param {*} search The filters for search.
 * 
 * @returns an object with an attribute containing the items list
 */
export async function fetchAllIngredients(organizationID, itemCategories) {
    let data = {};
    updateFieldIfValid(data, "organizationID", organizationID);
    updateFieldIfValidList(data, "itemCategories", itemCategories);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}item/list/simplified`, data));
    else return await request.get(includeParamsToUrl(`${API}item/list/simplified`, {}));
}

const itemApi = {
    fetchAll,
    fetchAllCMS,
    fetchAllIngredients,
    fetchListDialog,
    fetchAllByOrganizationID,
    fetchNew,
    fetchOne,
    create,
    update,
    enabled,
    disable,
    deleteById,
    exportJson,
    importJson,
    importCsv
}

export default itemApi;