import {
    Save as SaveIcon
} from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';
import { Fab, FormControlLabel, Grid, InputLabel, Switch } from "@mui/material";
import recipientApi from "api/recipient";
import CustomAddEditBreadcrumbMultiParent from 'components/Breadcrumb/CustomAddEditBreadcrumbMultiParent';
import CustomIconButton from 'components/Button/CustomIconButton';
import CustomDatePicker from 'components/DatePicker/CustomDatePicker';
import CustomGrid from 'components/Grid/CustomGrid';
import LoadingSkeleton from "components/Loading/LoadingSkeleton";
import CustomSnackbar from "components/Snackbar/CustomSnackbar";
import CustomTextField from 'components/Text/CustomTextField';
import CustomTitleBoldSubtitle from "components/Title/CustomTitleBoldSubtitle";
import { getDefaultRecipientObject } from 'constants/defaultObjects';
import { getRecipientAge } from 'constants/fieldProperties';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/material.css';
import { useNavigate, useParams } from "react-router-dom";
import { fabStyle, formSaveButton, mainArea, mainTag, mainTagBreadcrumb, mainTagTitle, textFieldError } from 'themes/defaultThemes';
import { hasRoleAdmin, hasRoleAdminOrDirectorOrManager } from "utils/auth";
import { formIsValid, formValChangeWithParentElement, formValChangeWithParentElementWithNameAndValue, onControlChange } from "utils/form-validation";
import { validateInputNumber } from "utils/functions";
import { getLanguageFromURL } from "utils/language";
import useDocumentTitle from "utils/useDocumentTitle";

/**
 * The RecipientAddEditComponent that triggers the creation of a 
 * new recipient or the modification of an existing one.
 *
 * @version 1.0.1
 * @author [Gina Chatzimarkaki]
 */
function RecipientAddEditComponent() {
    const { t } = useTranslation();
    const { organizationID } = useParams();
    const { outletID } = useParams();
    const { recipientID } = useParams();
    const navigate = useNavigate();

    let path = `/${getLanguageFromURL()}`
    if (organizationID !== undefined) path += `/organization/${organizationID}`;
    if (outletID !== undefined) path += `/outlet/${outletID}`;
    path += `/marketing/recipient`;

    // to render DOM based on rest api call status, if true the rest api call completed
    const [redirect, setRedirect] = useState(false);
    // if true the menu item is clicked and a redirect to page should perform
    const [isLoaded, setIsLoaded] = useState(false);

    // snackbar details
    const [alertBox, setAlertBox] = useState({
        isOpen: false,
        message: "",
        backgroundColor: "#a71313"
    });

    // eslint-disable-next-line no-unused-vars
    const [tabValue, setTabValue] = React.useState("personalDetails");

    /**
     * @type {object}
     * @property {boolean} isAdd to define if requested an edit or an add of a recipient
     * @property {boolean} redirect if a redirect should be performed
     * @property {string} error holdes the error message of a failed rest api call
     * @property {object} userAuth the authenticated recipient infromation
     * @property {string} recipientID tha recipient id to get recipient current information for edit recipient page
     * @property {object} recipient an empty object if add or the selected recipient to be editted
     * @property {*} icon the legend fa icon
     * @property {array} roles the different roles a recipient can have
     * @property {array} countries a list of the different countries
     * @property {object} isError list of indication of empty form required field 
     *                           after submit to present error message to the
     *                           fields/attributes with true value
     */
    const [data, setData] = useState({
        error: null,
        userAuth: null,
        isAdd: recipientID === undefined,
        recipientID: recipientID,
        recipient: null,
        organization: {
            id: organizationID,
            name: ""
        },
        outlet: {
            id: outletID,
            name: ""
        },
        outletIds: [],
        outlets: [],
        // error messages per field
        isError: {
            firstName: "",
            lastName: "",
            // fatherName: "",
            email: "",
            phone: "",
        }
    });

    //change document title
    useDocumentTitle(`Usee  ${organizationID !== undefined ? " | " + t('org.pageTitle') : ""} ${outletID !== undefined ? " | " + t('outlet.pageTitle') : ""} | ${t('page.users')}`);

    useEffect(() => {
        getRecipient();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * The rest endpoint to get the recipient default (add) or current (edit) information.
     */
    function getRecipient() {
        if (data.isAdd) {
            recipientApi.fetchNew(organizationID, outletID).then((r) => {
                setData({
                    ...data,
                    userAuth: r.data.returnobject.userAuth,
                    recipient: {
                        organizationID: organizationID,
                        outletID: outletID === undefined ? undefined : outletID,
                        ...getDefaultRecipientObject()
                    },
                    outlets: r.data.returnobject.outlets,
                    organization: r.data.returnobject.organization,
                    outlet: r.data.returnobject.outlet,
                });
                setIsLoaded(true);
            }).catch((e) => {
                // console.log(e);
            })
        } else {
            recipientApi.fetchOne(organizationID, outletID, recipientID).then((r) => {
                setData({
                    ...data,
                    userAuth: r.data.returnobject.userAuth,
                    recipient: r.data.returnobject.recipient,
                    outlets: r.data.returnobject.outlets,
                    organization: r.data.returnobject.organization,
                    outlet: r.data.returnobject.outlet,
                });
                setIsLoaded(true);
            }).catch((e) => {
                // console.log(e);
            })
        }
    }

    /**
     * Gets called when the recipient clicks on the save button, and triggers 
     * the creation of the new recipient.
     */
    function addRecipient() {
        if (formValidation()) {
            recipientApi.create(data.recipient).then((r) => {
                Promise.resolve()
                    .then(() => {
                        setAlertBox({
                            isOpen: true,
                            message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                            backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                        });
                    })
                    .then(() => {
                        if (r.data.code === "SUCCESS")
                            setRedirect(true);
                    })
            }).catch((e) => {
                // console.log(e)
            });
        }
    }

    /**
     * Gets called when the recipient clicks on the save button, and triggers 
     * the edit of the selected recipient.
     */
    function editRecipient() {
        if (formValidation()) {
            recipientApi.update(data.recipient).then((r) => {
                setAlertBox({
                    isOpen: true,
                    message: "message" in r.data ? r.data.message : t("errorPages.somethingWentWrong"),
                    backgroundColor: (r.data.code === "SUCCESS") ? "#177910" : "#a71313"
                });

                setRedirect((hasRoleAdmin(data.userAuth.roles) && r.data.code === "SUCCESS" ? true : false));
            }).catch((e) => {
                // console.log(e)
            });
        }
    }

    /**
     * Function that triggers the phone value change.
     * @param {string} newPhone the update phone value
     */
    function onPhoneChange(newPhone) {
        setData({
            ...data,
            recipient: {
                ...data.recipient,
                phone: newPhone
            }
        });
    }

    /**
     * Function that triggers form validation and print out if the form is valid or not.
     * @returns true if form is Valid
     */
    function formValidation() {
        let isError = createErrorMessages();
        if (formIsValid(isError)) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Function that create error messages for each required field that are not filled out.
     * @returns object containing the error messages for each form field
     */
    function createErrorMessages() {
        let isError = { ...data.isError };
        isError.firstName = data.recipient.firstName.length < 1 ? t('table.valueReuired') : "";
        isError.lastName = data.recipient.lastName.length < 1 ? t('table.valueReuired') : "";
        // isError.email = (data.recipient.email.length < 1 || !isValidEmail(data.recipient.email)) ? t('table.valueReuired') : "";
        // isError.phone = (data.recipient?.phone?.length < 10 || !validatePhoneNumber(data.recipient?.phone)) ? t('table.valueReuired') : "";

        setData({
            ...data,
            isError
        });
        return isError;
    }

    /**
     * Function that handles the snackbar open or close state.
     * @property {boolean} isOpen If the values is `true`, the modal should be open and visible.
     */
    function handleSnackbarState(isOpen) {
        setAlertBox({
            ...alertBox,
            isOpen: isOpen
        });
    }

    // ===============================================================================RENDER

    if (redirect) {
        navigate(`${path}`);
    } else if ((!isLoaded && !data.isAdd) || data.recipient == null) {
        return (
            <LoadingSkeleton lines={9} />
        );
    } else {
        const { isError } = data;

        return (
            <CustomGrid role="main" id={"recipient-" + data.isAdd ? "add" : "edit"} sx={mainTag()}>
                {(outletID !== undefined && organizationID !== undefined && hasRoleAdminOrDirectorOrManager(data.userAuth?.roles)) &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                                { name: t('outlet.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet` },
                                { name: data.outlet.name, url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet/${outletID}` },
                                { name: t('marketing.recipient.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/outlet/${outletID}/marketing/recipient` }
                            ]}
                            instanceName={(data.recipient !== null) ? data.recipient.firstName : ""}
                        />
                    </CustomGrid>
                }

                {(outletID === undefined && organizationID !== undefined && hasRoleAdminOrDirectorOrManager(data.userAuth?.roles)) &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('org.pageTitle'), url: `/${getLanguageFromURL()}/organization` },
                                { name: data.organization.name, url: `/${getLanguageFromURL()}/organization/${organizationID}` },
                                { name: t('marketing.recipient.pageTitle'), url: `/${getLanguageFromURL()}/organization/${organizationID}/marketing/recipient` }
                            ]}
                            instanceName={(data.recipient !== null) ? data.recipient.firstName : ""}
                        />
                    </CustomGrid>
                }

                {(outletID === undefined && organizationID === undefined && hasRoleAdminOrDirectorOrManager(data.userAuth?.roles)) &&
                    <CustomGrid sx={mainTagBreadcrumb()}>
                        <CustomAddEditBreadcrumbMultiParent
                            isAdd={data.isAdd}
                            parents={[
                                { name: t('marketing.recipient.pageTitle'), url: `/${getLanguageFromURL()}/marketing/recipient` }
                            ]}
                            instanceName={(data.recipient !== null) ? data.recipient.firstName : ""}
                        />
                    </CustomGrid>
                }

                <CustomGrid sx={mainTagTitle()}>
                    <CustomTitleBoldSubtitle
                        title={`${t('marketing.recipient.pageTitle')} | ${data.isAdd ? t('actions.add') : t('actions.edit')}`}
                        subtitleBeforeText={data.isAdd ? t('actions.tableCreateNewLink') + t('marketing.recipient.pageTitle') : t('marketing.recipient.pageTitle') + " "}
                        subtitleboldText={!data.isAdd && data.recipient !== null && data.recipient !== undefined && data.recipient.firstName}
                        subtitleAfterText={!data.isAdd ? " profile page" : ""}
                    />
                </CustomGrid>

                <CustomGrid sx={mainArea()} container={false}>
                    <form id="recipient" onSubmit={(e) => { return false; }}>
                        <TabContext value={tabValue}>
                            {/* <CustomTabs
                                initValue={tabValue}
                                instances={[
                                { name: t('recipient.personalDetails'), value: "personalDetails" },
                                ]}
                                setValue={setTabValue}
                                sx={{ marginBottom: "20px" }}
                            /> */}

                            <TabPanel value={"personalDetails"} sx={{ paddingTop: "0px" }} >
                                {data.isAdd &&
                                    <input type="hidden" />
                                }

                                {!data.isAdd &&
                                    <input type="hidden" name="id" id="id" defaultValue={data.recipientID} />
                                }
                                <Grid container spacing={2}>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={true} htmlFor="firstName">{t('label.firstName')}</InputLabel>
                                        <CustomTextField
                                            type="text"
                                            name="recipient.firstName"
                                            id="firstName"
                                            required={true}
                                            value={data.recipient.firstName}
                                            error={isError.firstName.length > 0 ? true : false}
                                            onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                            helperText={isError.firstName}
                                            sx={textFieldError()}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={true} htmlFor="lastName">{t('label.lastName')}</InputLabel>
                                        <CustomTextField
                                            type="text"
                                            name="recipient.lastName"
                                            id="lastName"
                                            required={true}
                                            value={data.recipient.lastName}
                                            error={isError.lastName.length > 0 ? true : false}
                                            onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                            helperText={isError.lastName}
                                            sx={textFieldError()}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={false} htmlFor="fatherName">{t('label.fatherName')}</InputLabel>
                                        <CustomTextField
                                            type="text"
                                            name="recipient.fatherName"
                                            id="fatherName"
                                            required={true}
                                            value={data.recipient.fatherName}
                                            // error={isError.fatherName.length > 0 ? true : false}
                                            // helperText={isError.fatherName}
                                            sx={textFieldError()}
                                            onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={true} htmlFor="email">{t('label.email')}</InputLabel>
                                        <CustomTextField
                                            type="email"
                                            name="recipient.email"
                                            id="email"
                                            placeholder="name@company.com"
                                            required={true}
                                            value={data.recipient.email}
                                            error={isError.email.length > 0 ? true : false}
                                            helperText={isError.email}
                                            sx={textFieldError()}
                                            onChange={(event) => formValChangeWithParentElement(event, data, setData, t('table.valueReuired'))}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <InputLabel required={true} htmlFor="phone">{t('label.phone')}</InputLabel>
                                        <PhoneInput
                                            value={!data.isAdd ? data.recipient.phone : ""}
                                            name="recipient.phone"
                                            id="phone"
                                            placeholder="Add your phone number" required={true}
                                            className={isError.phone.length > 0 ? "is-invalid" : ""}
                                            onChange={onPhoneChange}
                                            sx={{ width: "100%", }}
                                            country="gr"
                                            inputStyle={{ paddingLeft: "70px", width: "100%" }}
                                        />
                                        {/* {isError.phone.length > 0 &&
                                 <FormHelperText sx={selectError()}>
                                    {isError.phone}
                                 </FormHelperText>
                              } */}
                                    </Grid>

                                    <Grid item xs={6} md={3}>
                                        <InputLabel required={false} htmlFor="age">{t('marketing.recipient.age')}</InputLabel>
                                        <CustomTextField
                                            type="number"
                                            name="age"
                                            id="age"
                                            step={getRecipientAge().step}
                                            min={getRecipientAge().min}
                                            max={getRecipientAge().max}
                                            value={data.recipient.age}
                                            required={true}
                                            onChange={(event) => {
                                                let age = validateInputNumber(event.target.value, getRecipientAge().length, getRecipientAge().min, getRecipientAge().max, getRecipientAge().isFloat);
                                                if (age === undefined) age = data.recipient.age;
                                                formValChangeWithParentElementWithNameAndValue("recipient.age", age, data, setData)
                                            }}
                                            error={(data.isError.age > 0)}
                                        />
                                    </Grid>

                                    <Grid item xs={6} md={3}>
                                        <InputLabel required={false} htmlFor="age">{t('marketing.recipient.birthdate')}</InputLabel>
                                        <CustomDatePicker
                                            name="recipient.birthdate"
                                            inputFormat="DD/MM/YYYY"
                                            value={data.recipient.birthdate}
                                            onChange={(newValue) => {
                                                formValChangeWithParentElementWithNameAndValue("recipient.birthdate", newValue, data, setData)
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <FormControlLabel control={<Switch defaultChecked={data.recipient.active} onChange={(event) => onControlChange(event, data, setData, "recipient", "active")} />} label={t(data.recipient.active ? 'label.isEnabled' : 'label.isDisabled')} />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                        </TabContext>

                        <Grid container spacing={2}>
                            {/* <Grid item>
                        <CustomIconButton
                           variant="outlined"
                           keyValue="save"
                           onClick={data.isAdd ? addRecipient : editRecipient}
                           label={t('actions.save')}
                           sx={{ ...formSaveButton(), marginLeft: "20px" }}
                        />
                     </Grid> */}

                            {(data.isAdd && data.userAuth.id !== recipientID) &&
                                <Grid item>
                                    <CustomIconButton
                                        variant="outlined"
                                        keyValue="cancel"
                                        link={path}
                                        label={t('actions.back')}
                                        sx={formSaveButton()}
                                        color="error"
                                    />
                                </Grid>
                            }

                            {(!data.isAdd && data.userAuth.id !== recipientID) &&
                                <Grid item>
                                    <CustomIconButton
                                        variant="outlined"
                                        keyValue="cancel"
                                        onClick={getRecipient}
                                        label={t('actions.cancel')}
                                        sx={formSaveButton()}
                                        color="error"
                                    />
                                </Grid>
                            }
                        </Grid>
                    </form>

                    <Fab color="primary"
                        aria-label="add"
                        sx={fabStyle()}
                        onClick={data.isAdd ? addRecipient : editRecipient}
                    >
                        <SaveIcon />
                    </Fab>
                </CustomGrid>

                {alertBox.isOpen &&
                    <CustomSnackbar
                        isOpen={alertBox.isOpen}
                        autoHideDuration={3000}
                        message={alertBox.message}
                        backgroundColor={alertBox.backgroundColor}
                        handleSnackbarOpen={handleSnackbarState} />
                }
            </CustomGrid>
        );
    }
}

export default RecipientAddEditComponent;