import { request } from 'constants/alias';
import { API } from "constants/config";
import { includeParamsToUrl, objectIsEmpty } from 'utils/functions';
import updateFieldIfValid from 'utils/functions/updateFieldIfValid';

/**
 * The rest request which attempts to create a new settings.
 * @param {*} data A JSON object which contains new settings infromation
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function fetchInfo(search) {
    let data = {};
    updateFieldIfValid(data, "organizationID", search.organizationID);
    updateFieldIfValid(data, "outletID", search.outletID);

    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}display/kitchen`, data));
    else return await request.get(includeParamsToUrl(`${API}display/kitchen`));
}

/**
 * The rest request which attempts to fetch  settings.
 * @param {*} data A JSON object which contains a specific outlet settings infromation
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function fetchOne(search) {
    let data = {};
    updateFieldIfValid(data, "organizationID", search.organizationID);
    updateFieldIfValid(data, "outletID", search.outletID);
    
    if (!objectIsEmpty(data)) return await request.get(includeParamsToUrl(`${API}display/kitchen/settings`, data));
    else return await request.get(includeParamsToUrl(`${API}display/kitchen/settings`));
}

/**
 * The rest request which attempts to create a new settings.
 * @param {*} data A JSON object which contains new settings infromation
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function create(data) {
    return await request.post(`${API}display/kitchen/settings`, data);
}

/**
 * The rest request which attempts to update an existing settings.
 * @param {*} data A JSON object which contains the updated infromation of the settings
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function update(data) {
    return await request.put(`${API}display/kitchen/settings`, data);
}

/**
 * The rest request to delete a specific settings.
 * @param {*} id The id of the settings to be deleted
 * 
 * NOTE: only for outlet permit
 * 
 * @returns an object refering to the success or failure of the request
 */
export async function deleteById(id) {
    return await request.delete(`${API}display/kitchen/settings/${id}`);
}

const kitchenDisplayApi = {
    fetchOne,
    fetchInfo,
    create,
    update,
    deleteById
}

export default kitchenDisplayApi;